import React, { Component } from 'react';
import $ from 'jquery'

class ChangeSought extends Component{
    constructor(props){
        super(props)
        this.state={
            lineItems:[
                {name: "Analytical Procedure", name_slug:"analytical-procedure"}
                ,{name:"Equipment Modification", name_slug:"equipment-modification"},
                {name:"Design", name_slug:"design"},
                {name:"Product Specification", name_slug:"product-specification"},
                {name:"Instrument Modification", name_slug:"instrument-modification"},
                {name:"Change in Material of Construction", name_slug:"change-in-material-of-construction"},
                {name:"Raw Material Source", name_slug:"raw-material-source"},
                {name:"Piping Modification", name_slug:"piping-modification"},
                {name:"Packing Material", name_slug:"packing-material"},
                {name:"New Product Introduction", name_slug:"new-product-information"},
                {name:"Process/ Process Variables", name_slug:"process-process-variable"},
                {name:"Others", name_slug:"others"},
                {name:"Procedure (SOP, WI)", name_slug:"procedure-sop-wi"},
                {name:"Software", name_slug:"software"},
                {name:"Alternatives Considered?", name_slug:"alternatives-considered"},
            ]
        }
    }

    componentDidUpdate(){
        if(this.props.accessType == 'create'){

        }else{
            /*if(this.props.thisData && this.props.thisData.change_sought.length > 0){
                this.props.thisData.change_sought.map((change_sought,indx)=>{
                    document.getElementById(change_sought).checked = true;
                })
            }*/
        }
    }
    
    render(){
        var access_type = "view"
        var change_sought = []
        if(this.props.thisData){
            access_type = this.props.accessType
            change_sought = this.props.thisData.change_sought
            
        }
       var changeSoughtContent = this.state.lineItems.map((lineitem,key)=>{
            return(
                <div className='col-lg-4 col-md-4 col-sm-4 col-6 mb-3' key={key}>
                    <div className='form-check'>
                        {access_type=='create' || access_type== 'update'?
                            <input className="form-check-input changesought"
                                type="checkbox"
                                id={lineitem.name_slug}
                                checked={change_sought.includes(lineitem.name_slug)}
                                onChange={(e) => this.props.onChangeHandler(e,'change_sought')}
                                value={lineitem.name_slug} />
                            :
                            <input className="form-check-input changesought"
                                type="checkbox"
                                id={lineitem.name_slug}
                                disabled={!change_sought.includes(lineitem.name_slug)}
                                checked={change_sought.includes(lineitem.name_slug)}
                                value={lineitem.name_slug} />
                        }
                        <label className="form-check-label" htmlFor={lineitem.name_slug} >
                            {lineitem.name}
                        </label>
                    </div>
                </div>
            )

        })
        return(
            <div className='card mt-3 mb-3'>
                <div className='card-body'>
                    <h5 className="card-title mb-3"> <b>Change Sought for *</b></h5>
                    <div className='col-lg-9 col-sm-12 col-12 row'>
                        {changeSoughtContent}
                    </div>
                    <div className='col-lg-3'></div>
                </div>
            </div>
        )
    }
}

export default ChangeSought;