import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import $ from 'jquery'
import { API_HOST } from "../../Settings"
import axios from 'axios';
import notify from '../CommonComponents/Notify';
import { Link } from 'react-router-dom';
import Logo from "../../images/logo.svg"

class ResetPassword extends Component {
    constructor(props){
        super(props)
        this.state = {
            email:"",
            show_change_password: true,
            token: this.props.match.params.token,
            new_password: '',
            confirm_password: '',
            new_password_eye_show: false,
            confirm_password_eye_show: false,
            has_lower: false,
            has_upper: false,
            has_number: false,
            has_8_chars: false
        }
    }

    componentDidMount(){        
        $('body').addClass('body-background-image')
        this.getAllData()

    }

    getAllData =()=>{
        axios.get(`${API_HOST}/reset/password/${this.state.token}/`)
        .then((response) =>{
            if(response.status===200){
                this.setState({"email": response.data.email, "show_change_password": true})
                $('.loader').fadeOut('slow');
            }
        })
        .catch(function (error_response) {
            notify(error_response.response.data.reason, 'warning')
            setTimeout(() => {
                window.location.href = '/'
            }, 4000); 
            
        });
    }

    isValid = ()=>{
        var new_password = this.state.new_password
        var confirm_password = this.state.confirm_password
        if(new_password && confirm_password){
            if(new_password==confirm_password){
                if( new_password.length>=8 &&
                    new_password.match(/[a-z]/g) &&
                    new_password.match(/[A-Z]/g) &&
                    new_password.match(/[0-9]/g)
                 ){
                        return true
                }
            }
        }
        return false
    }

    handlePasswordChange = (event, password_type)=>{
        if(password_type=='new_password'){
            var value = event.target.value
            var has_lower = value.match(/[a-z]/g)
            var has_upper = value.match(/[A-Z]/g)
            var has_number = value.match(/[0-9]/g)
            var has_8_chars = false
            if(value && value.length>=8){
                has_8_chars = true
            }
            this.setState({'new_password': value, "has_lower": has_lower,
                "has_upper": has_upper, "has_8_chars": has_8_chars,
                "has_number": has_number
            })
        }else if(password_type=='confirm_password'){
            this.setState({'confirm_password': event.target.value})
        }
    }

    handleShowHide =(event, password_type)=>{
        if(password_type=='new_password'){
            this.setState({'new_password_eye_show': !this.state.new_password_eye_show})
        }else if(password_type=='confirm_password'){
            this.setState({'confirm_password_eye_show': !this.state.confirm_password_eye_show})
        }
    }

    handleSetPassword = (event) =>{
          event.preventDefault();
          if(this.isValid()){
            $('.loader').fadeIn();
            const form_data = {"new_password": this.state.new_password,
                'confirm_password': this.state.confirm_password
            }
            axios.post(`${API_HOST}/reset/password/${this.state.token}/`,form_data)
            .then((response) =>{
                if(response.status===200){
                    notify(response.data.reason, 'success')
                    setTimeout(() => {
                        window.location.href='/login'
                    }, 4000);   
                }
            })
            .catch(function (error_response) {
                notify(error_response.response.data.reason, 'warning')
                if(['Token not found', 'Token expired.'].includes(error_response.response.data.reason)){
                    setTimeout(()=>{
                        window.location.href='/forgot/password/'
                    }, 5000)
                }else{
                    $(".loader").fadeOut('slow')
                    var error_msg = error_response.response.data.reason
                    document.getElementById("error_msg").innerText=error_msg;
                }
            });
        }else{
            var error_msg = 'Please enter valid password'
            document.getElementById("error_msg").innerText=error_msg;
            setTimeout(() => {
                document.getElementById("error_msg").innerText = '';
            }, 10000);
        }   
    }

    render(){
        return (
            <div className="container-fluid background-transparent">
                <div className="loader"><div className="spin-loader"></div></div>
                <div className="row pt-5 pb-5 p-4">
                    <div className="col-lg-7 col-md-6 col-12 d-flex justify-content-center align-items-center">
                        <img className='company-logo display-none' src={Logo} />
                    </div>
                    <div className="col-lg-5 col-md-6 col-12">
                        <div className="login-form">
                            <div className='signup-block pe-4 ps-4 pb-4 mt-1'>
                                <div className='text-black text-center fontWeight-bold font-24'>Reset Password</div>
                                <div className='mt-4 login-details'>
                                    <p id="error_msg" className="text-danger"></p>
                                    <div className='mb-3'>
                                        <div className='secoundary-text font-16'>Email</div>                                    
                                        <div className='position-relative w-100 login-text-box'>
                                            <input  className='ps-3 border-0 w-100 font-14' type="email" id="email"
                                                value={this.state.email} disabled={true} />
                                        </div>
                                    </div>
                                    <div className='mb-3'>
                                        <div className='secoundary-text font-16'>New Password</div> 
                                        <div className="d-flex justify-content-between login-text-box">                               
                                            <div className='position-relative   border-0'>
                                            
                                                <input  className='ps-3 border-0 font-16'
                                                    type={this.state.new_password_eye_show? 'text': 'password'}
                                                    name="new_password" id="new_password" 
                                                    onChange = {(e)=>{this.handlePasswordChange(e, 'new_password')}}
                                                    value={this.state.new_password} />
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center"
                                                style={{"marginRight":"10px"}}
                                                onClick={(e)=>{this.handleShowHide(e, 'new_password')}}>
                                                <i className={this.state.new_password_eye_show?"fa fa-eye": 'fa fa-eye-slash'}>
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mb-3'>
                                        <div className='secoundary-text font-16'>Confirm Password</div> 
                                        <div className="d-flex justify-content-between login-text-box">                               
                                            <div className='position-relative   border-0'>
                                            
                                                <input  className='ps-3 border-0 font-16'
                                                    type={this.state.confirm_password_eye_show? 'text': 'password'}
                                                    name="confirm_password" id="confirm_password" 
                                                    onChange = {(e)=>{this.handlePasswordChange(e, 'confirm_password')}}
                                                    value={this.state.confirm_password} />
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center"
                                                style={{"marginRight":"10px"}}
                                                onClick={(e)=>{this.handleShowHide(e, 'confirm_password')}}>
                                                <i  className={this.state.confirm_password_eye_show?"fa fa-eye": 'fa fa-eye-slash'}>
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <h6 className="font-14"><b>Password must contain the following:</b></h6>
                                        <span className="font-14">
                                            <i className={this.state.has_lower ? "fa fa-check": "fa fa-times"} style={{"color": this.state.has_lower ?'green':'red'}} aria-hidden="true"></i>
                                                &nbsp; A <b>lowercase</b> letter
                                        </span>
                                        <br/>
                                        <span className="font-14">
                                            <i className={this.state.has_upper ? "fa fa-check": "fa fa-times"} style={{"color": this.state.has_upper ?'green':'red'}} aria-hidden="true"></i>
                                                &nbsp; A <b>capital (uppercase)</b> letter
                                        </span>
                                        <br/>
                                        <span className="font-14">
                                            <i className={this.state.has_number ? "fa fa-check": "fa fa-times"} style={{"color": this.state.has_number ?'green':'red'}} aria-hidden="true"></i>
                                                &nbsp; A <b>number</b>
                                        </span>
                                        <br/>
                                        <span className="font-14">
                                            <i className={this.state.has_8_chars ? "fa fa-check": "fa fa-times"} style={{"color": this.state.has_8_chars ?'green':'red'}} aria-hidden="true">
                                            </i>
                                            &nbsp; Minimum <b>8 characters</b>
                                        </span>
                                        <br/>
                                    </div>
                                    {this.state.show_change_password?
                                        <div className='mt-5 w-100'>
                                            <button className='black-btn w-100' style={{height:"37px"}} type="submit"
                                                onClick={(e)=>this.handleSetPassword(e)} >
                                                    Set Password
                                            </button>
                                        </div>:null
                                    }
                                    <div className="text-align-right" style={{"marginBottom": "30px","marginTop": "20px"}}>
                                        <Link to="/login/" style={{"backgroundColor":"white", "color":"black"}}>Sign In</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ResetPassword;