import React, { Component, createRef } from "react";
// import { EnergyManagementActionPlanTabelRowName } from "../CommonComponents/MiscellaneousObject";
import ApproveButton from "../../ConsentComponents/ApproveButton";
import AskButton from "../../ConsentComponents/AskButton";
import Comment from "../../ConsentComponents/Comment";
import RejectButton from "../../ConsentComponents/RejectButton";
import Sign from "../../ConsentComponents/Sign";
import EnergyManagementActionPlanTabelRow from "./EnergyManagementActionPlanTabelRow";
import Signed from "../../ConsentComponents/Signed";
import { getEnergyManagementActionPlanTabelRowName } from '../../CommonComponents/MiscellaneousObject';
import $ from 'jquery'
import axios from 'axios';
import notify from "../../CommonComponents/Notify";
import { API_HOST } from "../../../Settings";
import RejectComponent from "../../CommonComponents/RejectComponent";
import Attachment from '../../ConsentComponents/Attachment';
import ViewAttachments from '../../ConsentComponents/ViewAttachments';
import { FaPlusCircle } from "react-icons/fa";





class ReviewAndCommentByHeadElectricalDepartment extends Component {
    constructor(props){
        super(props)
        this.commentRef = createRef();
        this.handleOfComment = this.handleOfComment.bind(this)
        this.state = {
            is_approved:false,
            is_rejected:false,
            WorkflowData:this.props.WorkflowData,
            WorkflowComponent:this.props.WorkflowComponent,
            DocumentNumber:this.props.DocumentNumber,
            WorkflowSignatures:this.props.WorkflowComponent ? this.props.WorkflowComponent.workflow_signatures:[],
            enable_signing: false,
            enable_approval: false,
            EnergyManagementData:{
                EnergyManagementActionRow:getEnergyManagementActionPlanTabelRowName(),
                classification:'',
                single_line_diagram:'',
                other_point:'',
                significant_energy_user:'',
                company_level:'',
                scope_of_improvement:'',
                payback_period:'',
                legal_requirement:''
            },
            isDataSubmited:false,
            show_plant_moc_opinion_card: false,
            plant_moc_opinion: "",
            unique_stage_id: '4',
            unique_comp_id: '2',
            unique_combine: 's4c2',
            stage_status:this.props.stage_status,
            ask_for_more_history:[],
            current_component_name: "ReviewAndCommentByHeadElectricalDepartment",
            userPermissions: {},
            attachments:[
                {
                    attachment: "",
                    filename: "",
                    file_size: 0,
                    signed_url:"",
                    attachment_filename: ""
                }
            ],
            WorkflowAttachments:[],
        }
    }

    componentDidMount(){
        this.getUserPrivileges()
        if(this.props.DocumentNumber && this.props.isCollapased==false){
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/energy/management/action/${this.props.DocumentNumber}/data`,)
            .then(response => {
                if (response.status === 200 && response.data.success) {
                    var engergy_mngmt_data = response.data.engergy_mngmt_data
                    var eng_mang_action_data = this.state.EnergyManagementData.EnergyManagementActionRow
                    eng_mang_action_data.rows.map((action_desired, index) => {
                        engergy_mngmt_data.EnergyManagementActionRow && engergy_mngmt_data.EnergyManagementActionRow.rows.map((row, indx) => {
                            if (action_desired.name_slug == row.name_slug) {
                                eng_mang_action_data['rows'][index]['action_by'] = row.action_by
                                eng_mang_action_data['rows'][index]['target_date'] = row.target_date
                                eng_mang_action_data['rows'][index]['completion_date'] = row.completion_date
                            }
                            
                        })                        
                    })
                    engergy_mngmt_data['EnergyManagementActionRow'] = eng_mang_action_data   
                    this.setState({
                        EnergyManagementData:engergy_mngmt_data,
                        isDataSubmited:response.data.is_submited
                    },()=>this.getFormData())
                    $('.card-loader').fadeOut('slow')

                }
            }).catch(response => {
                // ErrorHandling(response)
            });
        }
        this.getFormData()
    }
    handleUpdateAttachmentList = (attachment_obj, idx)=>{
        var attachments_list = this.state.attachments
        attachments_list[idx] = attachment_obj;
        this.setState({attachments: attachments_list})

    };
    handleAddAttachmentButton = (e) =>{
        let attachment_obj = {
            attachment: "",
            filename: "",
            file_size: 0,
            signed_url:"",
            attachment_filename: ""
        }
        var attachments_list = this.state.attachments
        attachments_list.push(attachment_obj);
        this.setState({attachments: attachments_list})
    };
    handleDeleteAttachmentButton = (e, idx) =>{
        var attachments_list = this.state.attachments
        attachments_list.splice(idx, 1);
        this.setState({attachments: attachments_list})
    }

    attachmentSubmitHandler = (e, attachment_obj, idx) => {
        e.preventDefault()
        var attachment_data = {
            model:"workflow_attachments",
            data:{
                    workflow_definition_id:this.state.WorkflowData.workflow_definition_id,
                    stage_id:this.state.WorkflowData.id,
                    component_id:this.state.WorkflowComponent.id,
                    file_name:attachment_obj.attachment_filename
                }
        }
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
        axios.post(`${API_HOST}/workflow/moc-request/request/${this.props.DocumentNumber}/form/data/save/`, attachment_data)
        .then(response => {
            if (response.status === 200) {
                notify('Attachment saved successfully','success')
                var attachment_data = response.data.data.workflow_attachments
                var attachment_obj = {
                    attachment_filename:attachment_data.filename,
                    signed_url:attachment_data.singed_url
                }
                let attachments = this.state.WorkflowAttachments
                attachments.push(attachment_data)
                this.setState({WorkflowAttachments: attachments})
                this.handleUpdateAttachmentList(attachment_obj, idx)
            }

        }).catch(response => {
            // ErrorHandling(response)
        });
        $('.card-loader').fadeOut('slow')
    }

    removeWfAttachment =(event, id_of_wf_attachment, component_id)=>{
        event.preventDefault()
        var user_resp = window.confirm("Are you sure, you want to delete the attachment?")
        if(user_resp){
            $('.card-loader').fadeIn('')
            var attachment_data = {
                workflow_definition_id:this.state.WorkflowData.workflow_definition_id,
                stage_id:this.state.WorkflowData.id,
                component_id:this.state.WorkflowComponent.id,
                wf_attachment_id:id_of_wf_attachment
            }
            axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
            axios.post(`${API_HOST}/workflow/moc-request/request/${this.props.DocumentNumber}/attachment/delete/`, attachment_data)
            .then(response => {
                if (response.status === 200) {
                    notify('Attachment deleted successfully','success')
                    this.getFormData()
                }

            }).catch(response => {
                // ErrorHandling(response)
                $('.card-loader').fadeOut('slow')
            });
        }
        
    }

    handleSaveAttachmentButton = (event, attachment_obj, idx) =>  {
      event.preventDefault();
      if(attachment_obj.attachment){
        $('.loader').fadeIn()
        let form_data_django = new FormData();
        form_data_django.append('attachment', attachment_obj.attachment);
        form_data_django.append('size', attachment_obj.file_size);
        let url = `${API_HOST}/upload/file/in/azure/blob/storage`;
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')
        axios.post(url,form_data_django,
          ).then(response=>{
              if(response.data.success){
                  // notify("File successfully uploaded",'information')
                $('.loader').fadeOut('slow')
                  var attachment_obj = {
                      attachment_filename:response.data.filename,
                      signed_url:response.data.singed_url
                  }
                  this.attachmentSubmitHandler(event, attachment_obj, idx)
              }else{
                  notify(response.data.message,'error')
                  $('.card-loader').fadeOut('slow')
              }

          }).catch(error_xlsx => {

          })

      }else{
          alert("Invalid files selected")
    }
};

    handleOfComment(e, stop_loader, method){
        var response_isvalid = this.commentRef.current.isValid()
        if(!response_isvalid.status){
            if(method == "approve"){
                if(response_isvalid.custom_error_code!=1){
                    // if custom_error_code==1  we can process approval without comment saving
                    notify(response_isvalid.reason,'warning')
                    return false
                }
            }else{
                if(response_isvalid.custom_error_code==1){
                    return false
                }
                if(response_isvalid.custom_error_code==2){
                    // if custom_error_code==1  we can process approval without comment saving
                    notify(response_isvalid.reason,'warning')
                    return false
                }
            }
        }else{
            this.commentRef.current.commentSubmitHandler(e, stop_loader)
            return true
        }
        return true
    }

    getUserPrivileges = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        let privileges = JSON.stringify(['can_approve', 'can_reject', 'can_ask_for_more_information', 'can_sign', 'can_comment', 'can_create', 'can_review','can_attach'])
        let component = this.state.current_component_name
        let params = {"document_number": this.state.DocumentNumber, "from_new": true, "request_type": "moc_request"}
        axios.get(`${API_HOST}/user/privileges/${component}/${privileges}/`, {params: params})
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    userPermissions:response.data.data,
                })

            }
        }).catch(response => {
            // ErrorHandling(response)
        });
    }

    handlePlantLeadSubmit = (event, status_value)=>{
        event.preventDefault()
        var messgae = ''
        if(status_value=='reject'){
            messgae = "Are you sure, you want reject this request?"
        }
        else if(status_value=='approve'){
            messgae = 'Are you sure, you want approve this request?'
        }
        if(window.confirm(messgae)){
            var wf_action_obj = this.state.WorkflowComponent.workflow_actions.filter((wf_action, indx)=>{
                if(status_value=='approve'){
                    return wf_action.action.name_slug == 'approved'
                }else if(status_value=='reject'){
                    return wf_action.action.name_slug == 'rejected'
                }
                
            })
            var wf_action_log_obj = this.state.WorkflowComponent.workflow_action_logs.filter((wf_action_log, indx)=>{
                
                 return wf_action_log.status == 'Rejected'
            })
            $('.card-loader').fadeIn()
            var form_data = {            
                workflow_action_log_id:wf_action_log_obj[0].id,
                action_id:wf_action_obj[0].action.id 
            }        
            axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
            axios.post(`${API_HOST}/workflow/moc/request/request/${this.state.DocumentNumber}/plant-moc-lead-opinion/action/save/`, form_data)
            .then(response => {
                if (response.status === 200){
                    notify('Action completed successfully','success')
                    window.location.reload();
                }else{notify('Somethig wrong ayyappa', 'warning')}
            })
            .catch(response => {
            })
            $('.loader').fadeOut('slow')
        }
    }

    getFormData = () =>{
        if(this.props.isCollapased==false){
            axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
            axios.get(`${API_HOST}/workflow/moc/request/${this.props.DocumentNumber}/stage/${this.props.StageName}/?component_name=${this.props.ComponentName}`, )
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        WorkflowData:response.data.data.workflow_stage_info,
                        WorkflowComponent:response.data.data.workflow_stage_info.components[0],
                        WorkflowSignatures:response.data.data.workflow_stage_info.components[0].workflow_signatures,
                        enable_signing:response.data.data.workflow_stage_info.components[0].enable_signing,
                        enable_approval:response.data.data.workflow_stage_info.components[0].enable_approval,
                        is_approved: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                            response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Approved" ? true : false,
                        is_rejected: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                            response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false,
                        show_plant_moc_opinion_card: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                            response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false,
                        plant_moc_opinion: response.data.data.workflow_stage_info.components[0].wf_plant_moc_lead_review[0] ?
                            response.data.data.workflow_stage_info.components[0].wf_plant_moc_lead_review[0].action.name : "",
                        ask_for_more_history:response.data.data.ask_for_more_history,
                        WorkflowAttachments: response.data.data.workflow_stage_info.components[0].workflow_attachments,
                        // userComponentPermissions: response.data.data.workflow_stage_info.components[0].user_component_permissions,

                    })
                    // this.renderDynamicComponent()
                }

            }).catch(response => {
                // ErrorHandling(response)
            });
            $('.card-loader').fadeOut('slow')
        }else{
            let current_stage_info = this.props.WorkflowData
            if(current_stage_info){
                let is_approved = current_stage_info.components[0].workflow_action_logs[0] && 
                    current_stage_info.components[0].workflow_action_logs[0].status == "Approved" ? true : false
                let show_plant_moc_opinion_card = current_stage_info.components[0].workflow_action_logs[0] && 
                    current_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false
                let plant_moc_opinion =  current_stage_info.components[0].wf_plant_moc_lead_review[0] ?
                                current_stage_info.components[0].wf_plant_moc_lead_review[0].action.name : ""
                this.setState({is_approved:is_approved, show_plant_moc_opinion_card: show_plant_moc_opinion_card, plant_moc_opinion: plant_moc_opinion,
                    // userComponentPermissions: current_stage_info.components[0].user_component_permissions
                })
            }
        }
    }

    onChangeHandler = (e) =>{
        e.preventDefault()
        var energy_management_data = {...this.state.EnergyManagementData}
        energy_management_data[e.target.name] = e.target.value
        this.setState({
            EnergyManagementData:energy_management_data
        })
    }
    isValid=(state)=>{
        var response_isvalid = {"status":true, "reason":""}
        var name_regexp = new RegExp("<(?:\"[^\"]*\"['\"]*|'[^']*'['\"]*|[^'\">])+>");
        if(((state.classification&&name_regexp.test(state.classification)) || (state.company_level&&name_regexp.test(state.company_level)) || (state.legal_requirement&&name_regexp.test(state.legal_requirement)) || (state.other_point&&name_regexp.test(state.other_point)) || (state.payback_period&&name_regexp.test(state.payback_period)) || (state.single_line_diagram&&name_regexp.test(state.single_line_diagram)) || (state.significant_energy_user&&name_regexp.test(state.significant_energy_user)) || (state.scope_of_improvement&&name_regexp.test(state.scope_of_improvement)))){
            return {"status": false, "reason": "Please enter valid information in input fields.", 'id': "significant_energy_user"}
        }
        var engergy_mngmt_data = state.EnergyManagementActionRow.rows
        for (var i = 0; i<engergy_mngmt_data.length; i++) {
            if(document.getElementById(engergy_mngmt_data[i].name_slug+'-actionBy').value&&name_regexp.test(document.getElementById(engergy_mngmt_data[i].name_slug+'-actionBy').value)){
                return {"status": false, "reason": "Please enter valid information in input fields.", 'id': "expected-improvement-from-base-level-benefits-actionBy"}
            }
        }
        if (!response_isvalid['status']){
            return response_isvalid
        }
        return {"status":true, "reason":""}
    }
    onSubmitHandler = (e) =>{
        e.preventDefault() 
        
        var msg = "Are you sure, you want submit the data?"
        if(window.confirm(msg)){
            var response_isvalid = this.isValid(this.state.EnergyManagementData)
            if(!response_isvalid.status){
                notify(response_isvalid.reason,'warning')
                setTimeout(function () {
                    document.querySelector('#'+response_isvalid.id).scrollIntoView({
                        behavior: 'smooth', block: "center", inline: "nearest" 
                    })
                }, 100)
                return false
            }
            $('.loader').fadeIn()
            var energy_management_action_data = this.state.EnergyManagementData.EnergyManagementActionRow.rows.map((action_row,indx)=>{
                var actionBy = document.getElementById(action_row.name_slug+'-actionBy').value
                var targetDate = document.getElementById(action_row.name_slug+'-targetDate').value
                var completionDate = document.getElementById(action_row.name_slug+'-completionDate').value
                action_row.action_by = actionBy
                action_row.target_date = targetDate
                action_row.completion_date = completionDate
                return action_row
            })
            var engergy_mngmt_data = this.state.EnergyManagementData
            engergy_mngmt_data.EnergyManagementActionRow.rows = energy_management_action_data
            engergy_mngmt_data['DocumentNumber'] = this.state.DocumentNumber            
            const auth_token = localStorage.getItem("token")
                axios.defaults.headers.post['Authorization'] =auth_token      
                axios.post(`${API_HOST}/save/energy/management/action/data`, engergy_mngmt_data)
                .then(response => {
                    if (response.status === 200 && response.data.success) {   
                        notify('Data saved successfully!','success')
                        $('.loader').fadeOut('slow') 
                        this.setState({
                            isDataSubmited:true
                        })
                    }else{
                        notify('Somethig went wrong!','error')
                    }
                }).catch(response => {
                    // ErrorHandling(response)
                });
        }
    }


    render() {
        var access_type = "view"
        var ask_for_more_history_filtered = []
        if(this.state){
            if(this.state.hasOwnProperty('ask_for_more_history')){
                ask_for_more_history_filtered = this.state.ask_for_more_history.filter((each, index)=>{
                    return each.status.name_slug=="open"
                })
            }
        }
        if(this.state.ask_more_stage_name_slug != "" && !(['execution-hod-review', 'hod-of-safety-review', 'hod-of-qa-review', 'closure-by-moc-lead'].includes(this.state.ask_more_stage_name_slug))){
            if(ask_for_more_history_filtered.length>0){
                access_type  ="update"
            }
        }
        if(this.state.userPermissions.can_attach && !this.state.is_approved && !this.state.is_rejected){
            access_type = 'create'
        }
        var form_submition_info = {"form_submition_required":true, "is_required_forms_data_submitted": this.state.isDataSubmited}
        return (
            <div className='card mb-3' key={this.state.unique_combine} id="ReviewAndCommentByHeadElectricalDepartment">
                <div className='p-4'>
                    <div className='heading-Text font-16'>
                        Review and Comment by Head Electrical Department
                    </div>
                    {false ?
                        <div>
                            state Plant moc opinion {this.state.plant_moc_opinion}
                            <br/>
                            props isCollapsed {this.props.isCollapased ? 'YES': 'NO'}
                            <br/>
                            state isCollapsed {this.state.isCollapased ? 'YES': 'NO'}
                            <br/>
                            props is_approved {this.props.is_approved ? 'YES': 'NO'}
                            <br/>
                            state is_approved {this.state.is_approved ? 'YES': 'NO'}
                            <br/>
                            workflow_action_logs &nbsp;
                            {this.state.WorkflowComponent.workflow_action_logs.map((row, index)=>{
                                return('id:' + row['id']+' -- status:' + row['status'] +', ')
                            })}
                            <br/>
                            wf_plant_moc_lead_review &nbsp;
                            {this.state.WorkflowComponent.wf_plant_moc_lead_review.map((row, index)=>{
                                return ('id:'+row['id']+' -- status:'+ row['action']['name'] +', ')
                            })}
                        </div>
                        :null
                    }
                    <div className="row mt-3">
                        <div className='col-12 pb-3 d-flex'>
                            <div className="col-6">
                                <label className="form-label" htmlFor="classification">
                                    1) Any impact of these Changes on Earthing / lighting / fire protection/
                                        Hazardous Area classification:
                                </label>
                            </div>
                            <div className="col-6">
                                <input className='form-control' name="classification" 
                                id="classification" type="text" 
                                onChange={e => this.onChangeHandler(e)}
                                value={this.state.EnergyManagementData.classification}
                                disabled={this.state.isDataSubmited || !this.state.userPermissions.can_create  ? true: false}
                                />
                            </div>
                        </div>
                        <div className='col-12 pb-3 d-flex'>
                            <div className="col-6">
                                <label className="form-label" htmlFor="single_line_diagram">2) Any Changes on single line diagram:</label>
                            </div>
                            <div className="col-6">
                                <input className='form-control' name="single_line_diagram" 
                                    id="single_line_diagram" type="text" onChange={e => this.onChangeHandler(e)}
                                    value={this.state.EnergyManagementData.single_line_diagram}
                                    disabled={this.state.isDataSubmited || !this.state.userPermissions.can_create  ? true: false}
                                 />
                            </div>
                        </div>
                        <div className='col-12 pb-3 d-flex'>
                            <div className="col-6">
                                <label className="form-label" htmlFor="other_point">3) Any other point:</label>
                            </div>
                            <div className="col-6">
                                <input className='form-control' name="other_point" 
                                id="other_point" type="text" onChange={e => this.onChangeHandler(e)}
                                value={this.state.EnergyManagementData.other_point}
                                disabled={this.state.isDataSubmited || !this.state.userPermissions.can_create  ? true: false}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <h6><p><b>Energy Management Action Plan:</b></p></h6>
                        <div className='col-12 pb-3 d-flex'>
                            <div className="col-6">
                                <label className="form-label" htmlFor="significant_energy_user">Name of Significant Energy User:</label>
                            </div>
                            <div className="col-6">
                                <input className='form-control' name="significant_energy_user" 
                                id="significant_energy_user" type="text" 
                                onChange={e => this.onChangeHandler(e)}
                                value={this.state.EnergyManagementData.significant_energy_user}
                                disabled={this.state.isDataSubmited || !this.state.userPermissions.can_create  ? true: false}
                                />
                            </div>
                        </div>
                        <div className='col-12 pb-3 d-flex'>
                            <div className="col-6">
                                <label className="form-label" htmlFor="overriding_factor"><b>Overriding Factor:</b></label>
                            </div>
                            <div className="col-6">
                                <label className="form-label" htmlFor="overriding_factor"><b>Please Justify if Applicable</b></label>
                            </div>
                        </div>
                        <div className='col-12 pb-3 d-flex'>
                            <div className="col-6">
                                <label className="form-label" htmlFor="company_level">A. Consumption overall company Level</label>
                            </div>
                            <div className="col-6">
                                <input className='form-control' name="company_level"
                                 id="company_level" type="text" onChange={e => this.onChangeHandler(e)}
                                 value={this.state.EnergyManagementData.company_level}
                                 disabled={this.state.isDataSubmited || !this.state.userPermissions.can_create  ? true: false}
                                 />
                            </div>
                        </div>
                        <div className='col-12 pb-3 d-flex'>
                            <div className="col-6">
                                <label className="form-label" htmlFor="scope_of_improvement">B. Scope of Improvement</label>
                            </div>
                            <div className="col-6">
                                <input className='form-control' name="scope_of_improvement" 
                                id="scope_of_improvement" type="text" onChange={e => this.onChangeHandler(e)}
                                value={this.state.EnergyManagementData.scope_of_improvement}
                                disabled={this.state.isDataSubmited || !this.state.userPermissions.can_create  ? true: false}
                                />
                            </div>
                        </div>
                        <div className='col-12 pb-3 d-flex'>
                            <div className="col-6">
                                <label className="form-label" htmlFor="payback_period">C. Payback Period</label>
                            </div>
                            <div className="col-6">
                                <input className='form-control' name="payback_period" id="payback_period"
                                 type="text" onChange={e => this.onChangeHandler(e)}
                                 value={this.state.EnergyManagementData.payback_period}
                                 disabled={this.state.isDataSubmited || !this.state.userPermissions.can_create  ? true: false}
                                 />
                            </div>
                        </div>
                        <div className='col-12 pb-3 d-flex'>
                            <div className="col-6">
                                <label className="form-label" htmlFor="legal_requirement">D. Legal Requirement</label>
                            </div>
                            <div className="col-6">
                                <input className='form-control' name="legal_requirement"
                                 id="legal_requirement" type="text" onChange={e => this.onChangeHandler(e)}
                                 value={this.state.EnergyManagementData.legal_requirement}
                                 disabled={this.state.isDataSubmited || !this.state.userPermissions.can_create ? true: false}
                                 />
                            </div>
                        </div>
                    </div>

                    <div className="overflow-x-scroll">
                        <table className='table table-bordered'>
                            <thead className='vertical-align-middle'>
                                <tr>
                                    <th width="5%"><b>#</b> </th>
                                    <th className='text-center' width="25%"><b>Action Desired</b></th>
                                    <th className='text-center' width="20%"><b>Action By</b></th>
                                    <th className='text-center' width="25%"> <b>Target Date</b></th>    
                                    <th  className='text-center' width="25%">Completion Date</th>
                                </tr>
                            </thead>
                            <tbody className='vertical-align-middle'>                        
                                <EnergyManagementActionPlanTabelRow isDataSubmited={this.state.isDataSubmited} EnergyManagementActionRow={this.state.EnergyManagementData.EnergyManagementActionRow} DataSubmited={this.state.isDataSubmited} userPermissions={this.state.userPermissions.can_create}  />

                            </tbody>
                            <tfoot>
                                <tr>
                                {this.state.isDataSubmited?null:
                                <td colSpan="5">
                                    <div className='row mt-3'>
                                        <div className='col-12 text-right'>
                                            {this.state.userPermissions.can_create ?
                                            <button type="button" onClick={this.onSubmitHandler}
                                                className="btn btn-success submit-engery-managment-data" >
                                                    Submit
                                            </button>
                                            : null}
                                        </div>
                                    </div>
                                    </td>
                                }
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    &nbsp;
                    <div>
                        <p><b>Attachments</b></p>
                        { ask_for_more_history_filtered.length>0 || this.state.WorkflowAttachments.length > 0 ?
                            <ViewAttachments removeWfAttachment={this.removeWfAttachment}
                                key={this.state.unique_combine+'va'}
                                enable_attach_delete={['create'].includes(access_type)}
                                WorkflowAttachments={this.state.WorkflowAttachments} />
                        :((this.state.is_approved === true || this.state.is_rejected === true)? 'No Attachments' : '') }
                    </div>
                    {this.state.userPermissions.can_attach && !(this.state.is_approved  || this.state.is_rejected) && !['approved', 'rejected'].includes(this.state.stage_status)?
                    <div>
                        <div className='heading-Text font-12 mb-3 d-flex'>
                        Upload Attachments
                            <div className="plus-circle"><FaPlusCircle onClick={this.handleAddAttachmentButton}/></div>
                        </div>
                        {this.state.attachments.map((each, index)=>{
                            return <Attachment AttachmentIndex={index}
                                    AttachmentObj={each}
                                    key={this.state.unique_combine+'attach'+index}
                                    handleUpdateAttachmentList={this.handleUpdateAttachmentList}
                                    handleDeleteAttachmentButton={this.handleDeleteAttachmentButton}
                                    handleSaveAttachmentButton={this.handleSaveAttachmentButton}
                                    />
                        })}
                    </div>:null}
                    <div className='mt-4 row mb-3'>
                        <Comment is_rejected={this.state.is_rejected}
                            unique_combine={this.state.unique_combine}
                            stage_status={this.state.stage_status}
                            PlantMOCOpinion={this.state.plant_moc_opinion}
                            isRejected={this.state.show_plant_moc_opinion_card}
                            is_approved={this.state.is_approved}
                            WorkflowData={this.state.WorkflowData}
                            WorkflowComponent={this.state.WorkflowComponent}
                            DocumentNumber={this.state.DocumentNumber}
                            RequestType="moc-request"
                            ref={this.commentRef}
                            userPermissions={this.state.userPermissions}/>
                        <div className="col-lg-2 col-md-6 col-sm-4 col-4"></div>
                        {this.state.WorkflowSignatures.length > 0 ?
                           <Signed SignatureData={this.state.WorkflowSignatures[0]}/> : ""
                        }
                        {this.state.show_plant_moc_opinion_card ?
                           <RejectComponent
                               CurrentStageStatus={this.state.stage_status}
                               isRejected={this.state.show_plant_moc_opinion_card}
                               WorkflowData={this.state.WorkflowData}
                               WorkflowComponent={this.state.WorkflowComponent}
                               DocumentNumber={this.state.DocumentNumber}
                               current_component_name={this.state.current_component_name}
                               userPermissions={this.state.userPermissions}
                               RequestType="moc-request"
                           />  : null
                        }
                    </div>

                    {(this.props.isCollapased || (this.state.show_plant_moc_opinion_card && this.state.plant_moc_opinion != 'Rejected'))
                        ? '':
                        <div  className='d-flex w-100 justify-content-end align-items-end tri-buttons mt-4'>
                            <div className='pe-2'>
                                <AskButton 
                                    IsApproved={this.state.is_approved}
                                    stage_status={this.state.stage_status}
                                    key={this.state.unique_combine+'askbtn'}
                                    WorkflowData={this.state.WorkflowData} 
                                    WorkflowComponent={this.state.WorkflowComponent}  
                                    DocumentNumber={this.state.DocumentNumber}
                                    current_component_name={this.state.current_component_name}
                                    ask_for_more_history={this.state.ask_for_more_history}
                                    RequestType="moc-request"
                                    userPermissions={this.state.userPermissions}
                                    handleOfComment = {this.handleOfComment}
                                    />
                            </div>
                            <div className='pe-2'>
                                <RejectButton
                                    stage_status={this.state.stage_status}
                                    PlantMOCOpinion={this.state.plant_moc_opinion}
                                    IsApproved={this.state.is_approved}
                                    WorkflowData={this.state.WorkflowData} 
                                    WorkflowComponent={this.state.WorkflowComponent}  
                                    DocumentNumber={this.state.DocumentNumber}
                                    key={this.state.unique_combine+'rejbtn'}
                                    unique_combine = {this.state.unique_combine}
                                    ask_for_more_history={this.state.ask_for_more_history}
                                    current_component_name={this.state.current_component_name}
                                    RequestType="moc-request"
                                    userPermissions={this.state.userPermissions} 
                                    handleOfComment = {this.handleOfComment}/>
                            </div>
                            <div>
                                <ApproveButton
                                    stage_status={this.state.stage_status}
                                    key={this.state.unique_combine+'_approve'}
                                    IsApproved={this.state.is_approved}
                                    WorkflowData={this.state.WorkflowData}
                                    WorkflowComponent={this.state.WorkflowComponent}
                                    DocumentNumber={this.state.DocumentNumber}
                                    key={this.state.unique_combine+'aprbtn'}
                                    unique_combine = {this.state.unique_combine}
                                    current_component_name={this.state.current_component_name}
                                    ask_for_more_history={this.state.ask_for_more_history}
                                    RequestType="moc-request"
                                    form_submition_info={form_submition_info}
                                    handleOfComment = {this.handleOfComment}
                                    userPermissions={this.state.userPermissions}/>
                            </div>
                        </div>
                    }
                    <div className='mt-2'>
                        <Sign IsSignatures={this.state.WorkflowSignatures.length > 0?true:false} 
                        IsApproved={this.state.is_approved}
                        WorkflowData={this.state.WorkflowData} WorkflowComponent={this.state.WorkflowComponent}  
                        DocumentNumber={this.state.DocumentNumber} RequestType="moc-request" 
                        userPermissions={this.state.userPermissions}
                        current_component_name={this.state.current_component_name}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default ReviewAndCommentByHeadElectricalDepartment;