import React, { Component } from 'react';
import Toggle from '../../CommonComponents/Toggle';

class CategoryofRiskLevelBySiteEHSHeadTabelRow extends Component{
    render(){
        var tr_content = this.props.CategoryRiskLevelRows.rows.map((row,key) => {
            return(
                <tr key={key}>
                    <td>
                        {this.props.DataSubmited || !this.props.userPermissions  ?
                        <input className="form-check-input" type="checkbox" id={row.name_slug} name={row.name_slug} checked={row.is_checked} disabled={['view'].includes(this.props.accessType)?true:false}/>  
                        :
                        <input className="form-check-input" type="checkbox" id={row.name_slug} name={row.name_slug} />
                        }
                         &nbsp;&nbsp;{row.name}
                    </td>
                    <td>
                        <Toggle status={row.legal_compliance} index={key} requestFrom="RiskLevel" changeActionStatus={this.props.changeActionStatus} accessType={this.props.accessType}/>
                    </td>
                    <td>{this.props.DataSubmited || !this.props.userPermissions  ?
                        <input className='form-control' name="list_down" id={"listdown_"+key} type="text" value={row.list_down} readOnly={row.legal_compliance?false:true}/>:
                        <input className='form-control' name="list_down" id={"listdown_"+key} type="text" readOnly={row.legal_compliance?false:true}/>}
                    </td>
                    <td>{this.props.DataSubmited || !this.props.userPermissions  ?
                        <input className='form-control' name="plan_compliance" id={"plancompliance_"+key} type="text" value={row.plan_compliance} readOnly={row.legal_compliance?false:true}/>:
                        <input className='form-control' name="plan_compliance" id={"plancompliance_"+key} type="text" readOnly={row.legal_compliance?false:true}/>}
                    </td>
                </tr>        
            )
        })
        return(
            tr_content
        )
    }
}

export default CategoryofRiskLevelBySiteEHSHeadTabelRow;