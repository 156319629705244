import React, { Component, createRef } from "react";
import ApproveButton from '../../ConsentComponents/ApproveButton';
import AskButton from '../../ConsentComponents/AskButton';
import Comment from '../../ConsentComponents/Comment';
import RejectButton from '../../ConsentComponents/RejectButton';
import Sign from '../../ConsentComponents/Sign';
import axios from 'axios';
import { API_HOST } from '../../../Settings';
import $ from 'jquery';
import Signed from "../../ConsentComponents/Signed";
import RejectComponent from "../../CommonComponents/RejectComponent";
import notify from "../../CommonComponents/Notify";
import Attachment from '../../ConsentComponents/Attachment';
import ViewAttachments from '../../ConsentComponents/ViewAttachments';
import { FaPlusCircle } from "react-icons/fa";


class ApprovalByManufacturingHead extends Component {
    constructor(props) {
        super(props)
        this.commentRef = createRef();
        this.handleOfComment = this.handleOfComment.bind(this)
        this.state = {
            is_approved: false,
            is_rejected: false,
            WorkflowData: this.props.WorkflowData,
            WorkflowComponent: this.props.WorkflowComponent,
            DocumentNumber: this.props.DocumentNumber,
            WorkflowSignatures: this.props.WorkflowComponent ? this.props.WorkflowComponent.workflow_signatures : [],
            enable_signing: false,
            enable_approval: false,
            show_plant_moc_opinion_card: false,
            plant_moc_opinion: "",
            unique_stage_id: '7',
            unique_comp_id: '8',
            unique_combine: 's7c8',
            stage_status: this.props.stage_status,
            ask_for_more_history: [],
            current_component_name: "ApprovalByManufacturingHead",
            userPermissions: {},
            currentStageData: this.props.currentStageData,
            unSelectedConcernedDepartmentsData: this.props.unSelectedConcernedDepartmentsData,
            userRoles: this.props.userRoles,
            departmentNames: {
                'ApprovalByRegulatoryDomestic': 'Head Regulatory Department Domestic (R&D)',
                'ApprovalByRegulatoryInternational': 'Head Regulatory Department International (R&D)',
                'ApprovalByIBDHead': 'Head International Business Division Department (IBD)',
                'ApprovalByDFHead': 'Head Domestic Formulation Business Department (DF)'

            },
            selectedDepartments: [],
            attachments:[
                {
                    attachment: "",
                    filename: "",
                    file_size: 0,
                    signed_url:"",
                    attachment_filename: ""
                }
            ],
            WorkflowAttachments:[],
        }

    }



    checkIsApproved = (is_approved) => {
        this.setState({
            is_approved: is_approved
        })
    }

    componentDidMount() {
        this.getUserPrivileges()
        if (this.props.isCollapased == false && this.props.DocumentNumber) {
            axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
            axios.get(`${API_HOST}/workflow/moc/request/${this.props.DocumentNumber}/stage/${this.props.StageName}/?component_name=${this.props.ComponentName}`,)
                .then(response => {
                    if (response.status === 200) {
                        this.setState({
                            WorkflowData: response.data.data.workflow_stage_info,
                            WorkflowComponent: response.data.data.workflow_stage_info.components[0],
                            WorkflowSignatures: response.data.data.workflow_stage_info.components[0].workflow_signatures,
                            enable_signing: response.data.data.workflow_stage_info.components[0].enable_signing,
                            enable_approval: response.data.data.workflow_stage_info.components[0].enable_approval,
                            is_approved: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] &&
                                response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Approved" ? true : false,
                            is_rejected: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] &&
                                response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false,
                            show_plant_moc_opinion_card: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] &&
                                response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false,
                            plant_moc_opinion: response.data.data.workflow_stage_info.components[0].wf_plant_moc_lead_review[0] ?
                                response.data.data.workflow_stage_info.components[0].wf_plant_moc_lead_review[0].action.name : "",
                            ask_for_more_history: response.data.data.ask_for_more_history,
                            // userComponentPermissions: response.data.data.workflow_stage_info.components[0].user_component_permissions
                        })
                        // this.renderDynamicComponent()
                    }

                }).catch(response => {
                    // ErrorHandling(response)
                });
            $('.card-loader').fadeOut('slow')
        } else {
            let current_stage_info = this.props.WorkflowComponent
            if (current_stage_info) {
                let is_approved = this.props.WorkflowComponent.workflow_action_logs.length > 0 &&
                    this.props.WorkflowComponent.workflow_action_logs[0].status == "Approved" ? true : false
                let workflow_signatures = this.props.WorkflowComponent.workflow_signatures
                this.setState({
                    is_approved: is_approved, WorkflowSignatures: workflow_signatures,
                    // userComponentPermissions: current_stage_info.components[0].user_component_permissions
                })
            }
        }
        this.getAllData()  
    }
    getAllData=()=>{
        $('.card-loader').fadeIn()
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/workflow/moc/request/${this.props.DocumentNumber}/stage/${this.props.StageName}/?component_name=${this.props.ComponentName}`, )
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    WorkflowData:response.data.data.workflow_stage_info,
                    WorkflowComponent:response.data.data.workflow_stage_info.components[0],
                    WorkflowSignatures:response.data.data.workflow_stage_info.components[0].workflow_signatures,
                    enable_signing:response.data.data.workflow_stage_info.components[0].enable_signing,
                    enable_approval:response.data.data.workflow_stage_info.components[0].enable_approval,
                    is_approved: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                        response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Approved" ? true : false,
                    WorkflowAttachments: response.data.data.workflow_stage_info.components[0].workflow_attachments,
                    is_rejected: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                        response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false,
                    show_plant_moc_opinion_card: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                        response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false,
                    plant_moc_opinion: response.data.data.workflow_stage_info.components[0].wf_plant_moc_lead_review[0] ?
                        response.data.data.workflow_stage_info.components[0].wf_plant_moc_lead_review[0].action.name : "",
                    ask_for_more_history:response.data.data.ask_for_more_history,
                    // userComponentPermissions: response.data.data.workflow_stage_info.components[0].user_component_permissions
                })
                // this.renderDynamicComponent()
            }

        }).catch(response => {
            // ErrorHandling(response)
        });
        $('.card-loader').fadeOut('slow')
    }
    handleUpdateAttachmentList = (attachment_obj, idx)=>{
        var attachments_list = this.state.attachments
        attachments_list[idx] = attachment_obj;
        this.setState({attachments: attachments_list})

    };
    handleAddAttachmentButton = (e) =>{
        let attachment_obj = {
            attachment: "",
            filename: "",
            file_size: 0,
            signed_url:"",
            attachment_filename: ""
        }
        var attachments_list = this.state.attachments
        attachments_list.push(attachment_obj);
        this.setState({attachments: attachments_list})
    };
    handleDeleteAttachmentButton = (e, idx) =>{
        var attachments_list = this.state.attachments
        attachments_list.splice(idx, 1);
        this.setState({attachments: attachments_list})
    }

    attachmentSubmitHandler = (e, attachment_obj, idx) => {
        e.preventDefault()
        var attachment_data = {
            model:"workflow_attachments",
            data:{
                    workflow_definition_id:this.state.WorkflowData.workflow_definition_id,
                    stage_id:this.state.WorkflowData.id,
                    component_id:this.state.WorkflowComponent.id,
                    file_name:attachment_obj.attachment_filename
                }
        }
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
        axios.post(`${API_HOST}/workflow/moc-request/request/${this.props.DocumentNumber}/form/data/save/`, attachment_data)
        .then(response => {
            if (response.status === 200) {
                notify('Attachment saved successfully','success')
                var attachment_data = response.data.data.workflow_attachments
                var attachment_obj = {
                    attachment_filename:attachment_data.filename,
                    signed_url:attachment_data.singed_url
                }
                let attachments = this.state.WorkflowAttachments
                attachments.push(attachment_data)
                this.setState({WorkflowAttachments: attachments})
                this.handleUpdateAttachmentList(attachment_obj, idx)
            }

        }).catch(response => {
            // ErrorHandling(response)
        });
        $('.card-loader').fadeOut('slow')
    }

    removeWfAttachment =(event, id_of_wf_attachment, component_id)=>{
        event.preventDefault()
        var user_resp = window.confirm("Are you sure, you want to delete the attachment?")
        if(user_resp){
            $('.card-loader').fadeIn('')
            var attachment_data = {
                workflow_definition_id:this.state.WorkflowData.workflow_definition_id,
                stage_id:this.state.WorkflowData.id,
                component_id:this.state.WorkflowComponent.id,
                wf_attachment_id:id_of_wf_attachment
            }
            axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
            axios.post(`${API_HOST}/workflow/moc-request/request/${this.props.DocumentNumber}/attachment/delete/`, attachment_data)
            .then(response => {
                if (response.status === 200) {
                    notify('Attachment deleted successfully','success')
                    this.getAllData()
                }

            }).catch(response => {
                // ErrorHandling(response)
                $('.card-loader').fadeOut('slow')
            });
        }
        
    }

    handleSaveAttachmentButton = (event, attachment_obj, idx) =>  {
      event.preventDefault();
      if(attachment_obj.attachment){
        $('.loader').fadeIn()
        let form_data_django = new FormData();
        form_data_django.append('attachment', attachment_obj.attachment);
        form_data_django.append('size', attachment_obj.file_size);
        let url = `${API_HOST}/upload/file/in/azure/blob/storage`;
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')
        axios.post(url,form_data_django,
          ).then(response=>{
              if(response.data.success){
                  // notify("File successfully uploaded",'information')
                $('.loader').fadeOut('slow')
                  var attachment_obj = {
                      attachment_filename:response.data.filename,
                      signed_url:response.data.singed_url
                  }
                  this.attachmentSubmitHandler(event, attachment_obj, idx)
              }else{
                  notify(response.data.message,'error')
                  $('.card-loader').fadeOut('slow')
              }

          }).catch(error_xlsx => {

          })

      }else{
          alert("Invalid files selected")
    }
};

    handleDepartmentSelect = (department) => {
        const { selectedDepartments } = this.state;
        const updatedDepartments = selectedDepartments.includes(department)
            ? selectedDepartments.filter(dep => dep !== department)
            : [...selectedDepartments, department];
        this.setState({ selectedDepartments: updatedDepartments });
    };



    handleSubmit = () => {
        const { DocumentNumber, selectedDepartments } = this.state;
        if (selectedDepartments.length === 0) {
            notify('Please Select Departments', 'warning');
            return;
        }
        const message = 'Are you sure you want to add these components?';
        if (window.confirm(message)) {
            axios.post(`${API_HOST}/workflow/moc/request/${DocumentNumber}/update/`,
                { departments: selectedDepartments },
                { headers: { 'Authorization': localStorage.getItem('token') } }
            )
                .then(response => {
                    if (response.data.status === 'success') {
                        notify('Departments updated successfully!', 'success');
                        setTimeout(() => {
                            window.location.reload();
                            localStorage.setItem('current_component_name', selectedDepartments.join(', '));
                        }, 500);
                    } else {
                        console.error(response.data.reason);
                    }
                })
                .catch(error => console.error('Error:', error));
        }
    };

    handleOfComment(e, stop_loader, method) {
        var response_isvalid = this.commentRef.current.isValid()
        if (!response_isvalid.status) {
            if (method == "approve") {
                if (response_isvalid.custom_error_code != 1) {
                    // if custom_error_code==1  we can process approval without comment saving
                    notify(response_isvalid.reason, 'warning')
                    return false
                }
            } else {
                if (response_isvalid.custom_error_code == 1) {
                    return false
                }
                if (response_isvalid.custom_error_code == 2) {
                    // if custom_error_code==1  we can process approval without comment saving
                    notify(response_isvalid.reason, 'warning')
                    return false
                }
            }
        } else {
            this.commentRef.current.commentSubmitHandler(e, stop_loader)
            return true
        }
        return true
    }

    getUserPrivileges = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        let privileges = JSON.stringify(['can_approve', 'can_reject', 'can_ask_for_more_information', 'can_sign', 'can_comment', 'can_review','can_attach'])
        let component = this.state.current_component_name
        let params = { "document_number": this.state.DocumentNumber, "from_new": true, "request_type": "moc_request" }
        axios.get(`${API_HOST}/user/privileges/${component}/${privileges}/`, { params: params })
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        userPermissions: response.data.data,
                    })

                }
            }).catch(response => {
                // ErrorHandling(response)
            });
    }
    render() {
        var access_type = "view"
        var ask_for_more_history_filtered = []
        if(this.state){
            if(this.state.hasOwnProperty('ask_for_more_history')){
                ask_for_more_history_filtered = this.state.ask_for_more_history.filter((each, index)=>{
                    return each.status.name_slug=="open"
                })
            }
        }
        if(this.state.ask_more_stage_name_slug != "" && !(['execution-hod-review', 'hod-of-safety-review', 'hod-of-qa-review', 'closure-by-moc-lead'].includes(this.state.ask_more_stage_name_slug))){
            if(ask_for_more_history_filtered.length>0){
                access_type  ="update"
            }
        }
        if(this.state.userPermissions.can_attach && !this.state.is_approved && !this.state.is_rejected){
            access_type = 'create'
        }
        return(
            <div className='card mb-3' key={this.state.unique_combine} id="ApprovalByManufacturingHead">
                <div className='p-4'>
                    <div className='heading-Text font-16'>Approval by Manufacturing Head</div>
                    <div className=''>
                        {this.state.WorkflowSignatures.length === 0 && this.state.currentStageData && this.state.currentStageData.name_slug === 'plant-moc-lead-qa-production-designer' &&
                            <div className={this.state.unSelectedConcernedDepartmentsData.length > 0 && (this.state.userRoles.ApprovalByManufacturingHead) ? 'plant-moc-production-designer' : ''}>
                                <div className='row'>
                                    {this.state.unSelectedConcernedDepartmentsData.map(department => {
                                        if (this.state.userRoles.ApprovalByManufacturingHead) {
                                            if (this.state.currentStageData && this.state.currentStageData.name_slug === 'plant-moc-lead-qa-production-designer') {
                                                return (
                                                    <div className='col-6' key={department}>
                                                        <div className='form-check mb-3'>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id={department}
                                                                name='un-selected-concerned-departments'
                                                                value={department}
                                                                onChange={() => this.handleDepartmentSelect(department)}
                                                            />
                                                            <label className="form-check-label" htmlFor={department}>
                                                                {this.state.departmentNames[department]}
                                                            </label>
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return null;
                                            }
                                        } else {
                                            return null;
                                        }
                                    })}
                                    {this.state.unSelectedConcernedDepartmentsData.length > 0 &&
                                        (this.state.userRoles.ApprovalByManufacturingHead) &&
                                        (
                                            <div className='row'>
                                                <div className='p-2'>
                                                    <div className="d-flex gap-2 d-md-flex justify-content-md-end">
                                                        {this.state.userRoles.ApprovalByManufacturingHead ? (
                                                            <button className="btn btn-dark" type="submit" id="submit" onClick={this.handleSubmit}>Submit</button>
                                                        ) : (
                                                            <button className="btn btn-dark disabled" type="submit" id="submit">Submit</button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    {false ?
                        <div>
                            state Plant MoC opinion {this.state.plant_moc_opinion}
                            <br />
                            props isCollapsed {this.props.isCollapased ? 'YES' : 'NO'}
                            <br />
                            state isCollapsed {this.state.isCollapased ? 'YES' : 'NO'}
                            <br />
                            props is_approved {this.props.is_approved ? 'YES' : 'NO'}
                            <br />
                            state is_approved {this.state.is_approved ? 'YES' : 'NO'}
                            <br />
                            workflow_action_logs &nbsp;
                            {this.state.WorkflowComponent.workflow_action_logs.map((row, index) => {
                                return ('id:' + row['id'] + ' -- status:' + row['status'] + ', ')
                            })}
                            <br />
                            wf_plant_moc_lead_review &nbsp;
                            {this.state.WorkflowComponent.wf_plant_moc_lead_review.map((row, index) => {
                                return ('id:' + row['id'] + ' -- status:' + row['action']['name'] + ', ')
                            })}
                        </div>
                        : null
                    }
                    &nbsp;
                    <div>
                        <p><b>Attachments</b></p>
                        { ask_for_more_history_filtered.length>0 || this.state.WorkflowAttachments.length > 0 ?
                            <ViewAttachments removeWfAttachment={this.removeWfAttachment}
                                key={this.state.unique_combine+'va'}
                                enable_attach_delete={['create'].includes(access_type)}
                                WorkflowAttachments={this.state.WorkflowAttachments} />
                      
                        :((this.state.is_approved === true || this.state.is_rejected === true)? 'No Attachments' : '') }
                    </div>
                    {this.state.userPermissions.can_attach && !(this.state.is_approved || this.state.is_rejected) && !['approved', 'rejected'].includes(this.state.stage_status)?
                    <div>
                        <div className='heading-Text font-12 mb-3 d-flex'>
                        Upload Attachments
                            <div className="plus-circle"><FaPlusCircle onClick={this.handleAddAttachmentButton}/></div>
                        </div>
                        {this.state.attachments.map((each, index)=>{
                            return <Attachment AttachmentIndex={index}
                                    AttachmentObj={each}
                                    key={this.state.unique_combine+'attach'+index}
                                    handleUpdateAttachmentList={this.handleUpdateAttachmentList}
                                    handleDeleteAttachmentButton={this.handleDeleteAttachmentButton}
                                    handleSaveAttachmentButton={this.handleSaveAttachmentButton}
                                    />
                        })}
                    </div>:null}
                    <div className='mt-4 row mb-3'>
                        <Comment is_rejected={this.state.is_rejected}
                            stage_status={this.state.stage_status}
                            unique_combine={this.state.unique_combine}
                            PlantMOCOpinion={this.state.plant_moc_opinion}
                            isRejected={this.state.show_plant_moc_opinion_card}
                            is_approved={this.state.is_approved}
                            WorkflowData={this.state.WorkflowData}
                            WorkflowComponent={this.state.WorkflowComponent}
                            DocumentNumber={this.state.DocumentNumber}
                            RequestType="moc-request"
                            ref={this.commentRef}
                            userPermissions={this.state.userPermissions} />
                        <div className="col-lg-2 col-md-6 col-sm-4 col-4"></div>
                        {this.state.WorkflowSignatures.length > 0 ?
                            <Signed SignatureData={this.state.WorkflowSignatures[0]} /> : ""
                        }
                        {this.state.show_plant_moc_opinion_card ?
                            <RejectComponent
                                CurrentStageStatus={this.state.stage_status}
                                isRejected={this.state.show_plant_moc_opinion_card}
                                WorkflowData={this.state.WorkflowData}
                                WorkflowComponent={this.state.WorkflowComponent}
                                DocumentNumber={this.state.DocumentNumber}
                                userPermissions={this.state.userPermissions}
                                RequestType="moc-request"
                            /> : null
                        }
                    </div>
                    {(this.props.isCollapased || (this.state.show_plant_moc_opinion_card && this.state.plant_moc_opinion != 'Rejected'))
                        ? '' :
                        <div className='d-flex w-100 justify-content-end align-items-end tri-buttons mt-4'>
                            <div className='pe-2'>
                                <AskButton
                                    IsApproved={this.state.is_approved}
                                    stage_status={this.state.stage_status}
                                    key={this.state.unique_combine + 'askbtn'}
                                    WorkflowData={this.state.WorkflowData}
                                    WorkflowComponent={this.state.WorkflowComponent}
                                    DocumentNumber={this.state.DocumentNumber}
                                    current_component_name={this.state.current_component_name}
                                    ask_for_more_history={this.state.ask_for_more_history}
                                    RequestType="moc-request"
                                    userPermissions={this.state.userPermissions}
                                    handleOfComment={this.handleOfComment} />
                            </div>
                            <div className='pe-2'>
                                <RejectButton
                                    stage_status={this.state.stage_status}
                                    PlantMOCOpinion={this.state.plant_moc_opinion}
                                    IsApproved={this.state.is_approved}
                                    WorkflowData={this.state.WorkflowData}
                                    WorkflowComponent={this.state.WorkflowComponent}
                                    DocumentNumber={this.state.DocumentNumber}
                                    key={this.state.unique_combine + 'aprbtn'}
                                    unique_combine={this.state.unique_combine}
                                    current_component_name={this.state.current_component_name}
                                    ask_for_more_history={this.state.ask_for_more_history}
                                    RequestType="moc-request"
                                    userPermissions={this.state.userPermissions}
                                    handleOfComment={this.handleOfComment} />
                            </div>
                            <div>
                                <ApproveButton
                                    stage_status={this.state.stage_status}
                                    key={this.state.unique_combine + '_approve'}
                                    IsApproved={this.state.is_approved}
                                    WorkflowData={this.state.WorkflowData}
                                    WorkflowComponent={this.state.WorkflowComponent}
                                    DocumentNumber={this.state.DocumentNumber}
                                    key={this.state.unique_combine + 'aprbtn'}
                                    unique_combine={this.state.unique_combine}
                                    current_component_name={this.state.current_component_name}
                                    ask_for_more_history={this.state.ask_for_more_history}
                                    RequestType="moc-request"
                                    handleOfComment={this.handleOfComment}
                                    userPermissions={this.state.userPermissions}
                                />
                            </div>
                        </div>
                    }
                    <div className='mt-2'>
                        <Sign IsSignatures={this.state.WorkflowSignatures.length > 0?true:false} 
                        IsApproved={this.state.is_approved}
                        WorkflowData={this.state.WorkflowData} WorkflowComponent={this.state.WorkflowComponent}  
                        DocumentNumber={this.state.DocumentNumber} RequestType="moc-request"
                        userPermissions={this.state.userPermissions}
                        current_component_name={this.state.current_component_name}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default ApprovalByManufacturingHead;