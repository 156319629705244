import React, { Component } from 'react';
import StageComponents from './AllStageComponents';
import notify from '../CommonComponents/Notify';
import $ from 'jquery'
import { API_HOST } from '../../Settings.js'
import axios from 'axios';
import Signed from '../ConsentComponents/Signed.js';

class SelectCrossFunctionalTeams extends Component {
    constructor(props){
        super(props)
        this.state = {
            selectedDepartmentComponents:[],
            defaultSelectedDepartmentComponents:["ReviewAndCommentByHeadSafetyDepartment","ReviewAndCommentByHeadQADepartment"],
            checkedDepartments:{},
            stage_status:this.props.stage_status,
            current_component_name: "SelectCrossFunctionalTeams",
            userPermissions: {},
            WorkflowSignatures:[],
        }
    }
    
    static getDerivedStateFromProps(props, state) {
        if(props.checkedDepartments !== state.checkedDepartments){
            //Change in props
            return{
                selectedDepartmentComponents: props.ChangeSoughtComponent?props.ChangeSoughtComponent:state.selectedDepartmentComponents,
                checkedDepartments:props.checkedDepartments?props.checkedDepartments:state.checkedDepartments
            };
        }
        return null; // No change to state
    }

    componentDidMount(){
        this.getUserPrivileges()
        if(this.props.isCollapased==false){            
            axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
            axios.get(`${API_HOST}/workflow/moc/request/${this.props.DocumentNumber}/stage/${this.props.StageName}/`, )
            .then(response => {            
                if (response.status === 200) {
                    var checked_department = {}
                    var selectedDepartmentComponents = []
                    if(response.data.data.workflow_stage_info.hasOwnProperty('selected_cfts_react_components') && response.data.data.workflow_stage_info.selected_cfts_react_components.length > 0){
                        selectedDepartmentComponents = response.data.data.workflow_stage_info.selected_cfts_react_components
                    }else{
                        selectedDepartmentComponents = response.data.data.workflow_stage_info.change_sought_selected_react_components
                    }
                    selectedDepartmentComponents.map((component_name,indx)=>{   
                        checked_department[component_name] = true             
                    }) 
                    this.setState({
                        workflowData:response.data.data.workflow_stage_info,
                        checkedDepartments:checked_department,
                        selectedDepartmentComponents:selectedDepartmentComponents,
                        is_approved: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                            response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Approved" ? true : false,
                        WorkflowSignatures:response.data.data.workflow_stage_info.components[0].workflow_signatures,
                        })
                    // this.renderDynamicComponent()
                }

            }).catch(response => {
                // ErrorHandling(response)
            });
            $('.card-loader').fadeOut('slow')
        }
        
    }

    getUserPrivileges = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        let privileges = JSON.stringify(['can_approve'])
        let component = this.state.current_component_name
        let params = {"document_number": this.props.DocumentNumber, "from_new": true, "request_type": "moc_request"}
        axios.get(`${API_HOST}/user/privileges/${component}/${privileges}/`, {params: params})
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    userPermissions:response.data.data,
                })

            }
        }).catch(response => {
            // ErrorHandling(response)
        });
    }

    onChangeHandler = (e) =>{
        // e.preventDefault()
        var checked_department = this.state.checkedDepartments
        var selected_dep = this.state.selectedDepartmentComponents
        if(e.currentTarget.checked){
            checked_department[e.currentTarget.name] = true
            selected_dep.push(e.currentTarget.name)
        }else{
            checked_department[e.currentTarget.name] = false
            var arr = selected_dep.filter(item => item !== e.currentTarget.name)
            selected_dep = arr
        }

        this.setState({
            checkedDepartments:checked_department,
            selectedDepartmentComponents:selected_dep
        })
    }

    onSubmitHandler = (e) =>{
        e.preventDefault()
        var selected_crf_department = this.state.selectedDepartmentComponents
        var default_crf_department = this.state.defaultSelectedDepartmentComponents
        var all_crf_department = selected_crf_department.concat(default_crf_department)
        var msg = "Are you sure, you want to submit selected approving authorities?"
        if (window.confirm(msg)) {
            const auth_token = localStorage.getItem("token")
            axios.defaults.headers.post['Authorization'] = auth_token
            axios.post(`${API_HOST}/workflow/moc/request/${this.props.DocumentNumber}/stage/plant-moc-lead-review/submit/`, {departments:all_crf_department})
                .then(response => {
                    if (response.status === 200) {
                        notify('Successfully submited selected approving authorities.','success')
                        setTimeout(() => {
                            window.location.reload()
                        }, 1000)
                    } 
                }).catch(response => {
                    // ErrorHandling(response)
                });
        }
    }
    
    render() {
        return(
                <div className = "card mb-3" id="SelectCrossFunctionalTeams">
                    <div className = "card-body" >
                        <h5 className = "card-title mb-3" > <b>Cross Functional Teams</b></h5 >
                        <p> Select approving authorities </p> 
                        <div className = 'row' >
                            <div className = 'col-lg-7 col-md-12 col-12' >
                                <div className = 'row mb-3' >
                                    <div className = 'col-6' >                                    
                                        <div className = 'form-check' >
                                            <input className = "form-check-input" type="checkbox" 
                                            id="headProduction" name='ReviewandCommentByHeadProductionDepartment' 
                                            checked={this.state.checkedDepartments['ReviewandCommentByHeadProductionDepartment']?true:false }
                                            value="ReviewandCommentByHeadProductionDepartment" onChange={e => this.onChangeHandler(e)}
                                            disabled={!this.state.userPermissions.can_approve || this.state.is_approved ? true : false}
                                             />
                                            <label className="form-check-label"  htmlFor="headProduction" >
                                                Head Production Department 
                                            </label> 
                                        </div> 
                                    </div> 
                                    <div className='col-6' >
                                        <div className='form-check' >
                                            < input className="form-check-input" type="checkbox" 
                                                id="headEngineering" name='ReviewAndCommentByHeadEngineeringDepartment'
                                                checked={this.state.checkedDepartments.ReviewAndCommentByHeadEngineeringDepartment?true:false}  
                                                value="ReviewAndCommentByHeadEngineeringDepartment" 
                                                onChange={e => this.onChangeHandler(e)}
                                                disabled={this.state.is_approved || !this.state.userPermissions.can_approve? true : false}/>
                                            <label className="form-check-label" htmlFor="headEngineering" >
                                                Head Engineering Department 
                                            </label> 
                                        </div> 
                                    </div> 
                                </div> 
                                <div className='row mb-3' >
                                    <div className='col-6' >
                                        < div className='form-check' >
                                            <input className="form-check-input" type="checkbox" 
                                            id="headElectrical" name='ReviewAndCommentByHeadElectricalDepartment' 
                                            checked={this.state.checkedDepartments.ReviewAndCommentByHeadElectricalDepartment?true:false}  
                                            value="ReviewAndCommentByHeadElectricalDepartment" 
                                            onChange={e => this.onChangeHandler(e)} 
                                            disabled={this.state.is_approved || !this.state.userPermissions.can_approve ? true : false}/>
                                            <label className="form-check-label" htmlFor="headElectrical" >
                                                Head Electrical Department 
                                            </label>
                                        </div> 
                                    </div> 
                                    <div className='col-6' >
                                        <div className='form-check' >
                                            <input className="form-check-input"  type="checkbox"  
                                            id="headInstrument"  name='ReviewAndCommentByHeadInstrumentDepartment' 
                                            checked={this.state.checkedDepartments.ReviewAndCommentByHeadInstrumentDepartment?true:false}  
                                            value="ReviewAndCommentByHeadInstrumentDepartment" 
                                            onChange={e => this.onChangeHandler(e)} 
                                            disabled={this.state.is_approved || !this.state.userPermissions.can_approve ? true : false}/>
                                            <label className="form-check-label" htmlFor="headInstrument" >
                                                Head Instrument Department 
                                            </label> 
                                        </div> 
                                    </div> 
                                </div> 
                                <div className='row mb-3' >
                                    <div className='col-6' >
                                        <div className='form-check' >
                                            <input className="form-check-input" type="checkbox" 
                                            id="headMechanical" name='ReviewAndCommentByHeadMechanicalDepartment' 
                                            value="ReviewAndCommentByHeadMechanicalDepartment" 
                                            checked={this.state.checkedDepartments.ReviewAndCommentByHeadMechanicalDepartment?true:false}  
                                            onChange={e => this.onChangeHandler(e)} 
                                            disabled={this.state.is_approved || !this.state.userPermissions.can_approve ? true : false}/>
                                            <label className="form-check-label" htmlFor="headMechanical" >
                                                Head Mechanical Department 
                                            </label> 
                                        </div> 
                                    </div>
                                <div className='col-6' >
                                    <div className='form-check' >
                                        <input className="form-check-input" type="checkbox" 
                                        id="headStorePurchase" name='ReviewAndCommentByHeadStoresAndPurchaseDepartment'
                                         value="ReviewAndCommentByHeadStoresAndPurchaseDepartment" 
                                         checked={this.state.checkedDepartments.ReviewAndCommentByHeadStoresAndPurchaseDepartment?true:false}  
                                         onChange={e => this.onChangeHandler(e)} 
                                         disabled={this.state.is_approved || !this.state.userPermissions.can_approve ? true : false}/>
                                        <label className="form-check-label" htmlFor="headStorePurchase" >
                                            Head Stores & Purchase Department 
                                        </label>
                                    </div>
                                </div> 
                            </div> 
                            <div className='row mb-3' >
                                <div className='col-6' >
                                    <div className='form-check' >
                                        <input className="form-check-input" type="checkbox" 
                                        id="headProjects" name='ReviewAndCommentByHeadProjectsDepartment' 
                                        value="ReviewAndCommentByHeadProjectsDepartment" 
                                        checked={this.state.checkedDepartments.ReviewAndCommentByHeadProjectsDepartment?true:false}  
                                        onChange={e => this.onChangeHandler(e)} 
                                        disabled={this.state.is_approved || !this.state.userPermissions.can_approve ? true : false}/>
                                        <label className="form-check-label" htmlFor="headProjects" >
                                            Head Projects 
                                        </label> 
                                    </div> 
                                </div> 
                                <div className='col-6' >
                                    <div className='form-check' >
                                        <input className="form-check-input" type="checkbox" 
                                        id="headProcess" name='ReviewAndCommentByHeadPDDepartment' 
                                        value="ReviewAndCommentByHeadPDDepartment" 
                                        checked={this.state.checkedDepartments.ReviewAndCommentByHeadPDDepartment?true:false}  
                                        onChange={e => this.onChangeHandler(e)} 
                                        disabled={this.state.is_approved || !this.state.userPermissions.can_approve ? true : false}/>
                                        <label className="form-check-label" htmlFor="headProcess" >
                                            Head Process Development 
                                        </label> 
                                    </div> 
                                </div> 
                            </div> 
                            <div className='row mb-3' >
                                <div className='col-6' >
                                    <div className='form-check' >
                                        <input className="form-check-input"  type="checkbox"  
                                        id="headEnvironment" name='ReviewAndCommentByHeadEnvironmentDepartment'
                                         value="ReviewAndCommentByHeadEnvironmentDepartment" 
                                         checked={this.state.checkedDepartments.ReviewAndCommentByHeadEnvironmentDepartment?true:false}  
                                         onChange={e => this.onChangeHandler(e)} 
                                         disabled={this.state.is_approved || !this.state.userPermissions.can_approve ? true : false}/>
                                        <label className="form-check-label" htmlFor="headEnvironment" >
                                            Head Environment Department
                                        </label> 
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div className='col-lg-5 col-md-12 col-12' >
                            <div className='row mb-3' >
                                <div className='col-6' >
                                    <div className='form-check' >
                                        <input className="form-check-input" type="checkbox" id="headEHS" name='ReviewAndCommentByHeadSafetyDepartment' checked value="ReviewAndCommentByHeadSafetyDepartment" disabled />
                                        <label className="form-check-label" htmlFor="headEHS" >
                                            Head EHS Department 
                                        </label> 
                                    </div> 
                                </div> 
                            <div className='col-6' >
                                <div className='form-check' >
                                    <input className="form-check-input" type="checkbox" id="headQuality" name='ReviewAndCommentByHeadQADepartment' checked value="ReviewAndCommentByHeadQADepartment" disabled />
                                    <label className="form-check-label" htmlFor="headQuality" >
                                        Head Quality Assurance
                                    </label> 
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                {(['approved', 'rejected'].includes(this.state.stage_status) || this.state.is_approved) ? (
                        <div className='card-body'>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                {this.state.WorkflowSignatures.length > 0 ? <Signed SignatureData={this.state.WorkflowSignatures[0]} /> : ""}
                            </div>
                        </div>
                    ) : (
                        <div className='row'>
                            <div className='col-12 text-right'>
                                {this.state.userPermissions.can_approve ? (
                                    <button type="button" className="btn btn-primary submit-cft-data" onClick={this.onSubmitHandler}>
                                        Submit
                                    </button>
                                ) : null}
                            </div>
                        </div>
                    )}

                    </div>
                </div>
        )
    }
}

export default SelectCrossFunctionalTeams;