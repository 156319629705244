import React, { Component } from 'react';
import $ from 'jquery'
import { API_HOST } from '../../Settings.js'
import axios from 'axios';
import notify from '../CommonComponents/Notify';
import '../../Styles/brioStyle.css';
import Select from 'react-select';
import { FaArrowLeft, FaTimes, FaPlusCircle, FaTrash } from 'react-icons/fa';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { MultiSelect } from "react-multi-select-component";

class UserCreation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user_data: localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : {},
            showPassword: false,
            user_access_rows: [],
            users_data: [],
            selected_user: {},
            users_list: [],
            site_name: '',
            plant_name: '',
            department_name: '',
            designation: '',
            designation_data_ui: [],
            desgination_data: [],
            selected_user_roles__ids_to_remove: [],
            selected_user_roles_ids_ui: [],
            roles_data: [],
            roles_data_ui: [],
            user_roles_data: [],
            selected_roles_ids: [],
            selected_roles_ids_ui: [],
            sites_data: [],
            sites_data_ui: [],
            selected_site: {},
            showPassword: false,
            plants_of_location: [],
            plants_of_location_ui: [],
            plants_selected_ids: [],
            plants_selected_ids_ui: [],
            possible_location_selected_roles: [],

            departments_data: [],
            departments_data_ui: [],
            department_selected_ids: [],
            department_selected_ids_ui: [],

            user_roles_list: [],

            rows: [{
                selected_site: {},
                plants_selected_ids_ui: [],
                department_selected_ids_ui: [],
                selected_roles_ids_ui: []
            }],
            errors: [],
            validationStatus: {
                designation: false,
                site: false,
                department: false,
            },
        }
    }
    componentDidMount() {
        if ((this.state.user_data && this.state.user_data.hasOwnProperty('is_admin') &&
            this.state.user_data.is_admin)) {
            this.getAllData()
        } else {
            notify("You are not allowed", 'warning')
            setTimeout(() => {
                $('.loader').fadeOut('slow')
                window.location.href = '/'
            }, 3000)
        }
    }

    getAllData = () => {
        this.getUsersData()
        this.getRolesList()
        this.getAllSitesList()
        this.getAllDepartments()
        this.getAllDesignations()
    }
    getUsersData = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/all/users/list/`,)
            .then(response => {
                if (response.status === 200) {
                    var users_data = JSON.parse(response.data.users_data)
                    var current_user = users_data.filter((row, indx) => {
                        if (row.email == this.state.user_data.email) {
                            return row.is_admin
                        } else {
                            return false
                        }
                    })
                    if (current_user.length == 0) {
                        notify("You are not allowed", 'warning')
                        setTimeout(() => {
                            $('.loader').fadeOut('slow')
                            window.location.href = '/'
                        }, 3000)
                    }
                    var users_list = users_data.map((row, index) => {
                        return { "email": row.email, "first_name": row.first_name, "last_name": row.last_name }
                    })
                    this.setState({ "users_data": users_data, "users_list": users_list })
                    $('.loader').fadeOut('slow')
                }
            }).catch(error_response => {

            })
    }
    getRolesList = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/admin/workflow/roles/list/`,)
            .then(response => {
                if (response.status === 200) {
                    var roles_data = response.data.data.roles_data
                    var roles_data_ui = roles_data.map((item, index) => {
                        return { label: item.name, value: item.id }
                    })
                    this.setState({ "roles_data": roles_data, "roles_data_ui": roles_data_ui })
                    $('.loader').fadeOut('slow')
                }
            }).catch(error_response => {

            })
    }

    getAllSitesList = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/admin/get/all/sites/list/`,)
            .then(response => {
                if (response.status === 200) {
                    var sites_data = response.data.data.sites_data
                    var sites_data_ui = sites_data.map((item, index) => {
                        return { label: item.name, value: item.name_slug }
                    })
                    this.setState({ "sites_data": sites_data, "sites_data_ui": sites_data_ui })
                    $('.loader').fadeOut('slow')
                }
            }).catch(error_response => {

            })
    }


    getAllDepartments = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/all/departments/data/`,)
            .then(response => {
                if (response.status === 200) {
                    var departments_data = response.data.departments_data
                    var departments_data_ui = departments_data.map((item, index) => {
                        return { label: item.name, value: item.id }
                    })
                    this.setState({ "departments_data": departments_data, "departments_data_ui": departments_data_ui })
                    $('.loader').fadeOut('slow')
                }
            }).catch(error_response => {

            })
    }
    getAllDesignations = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/designations/`,)
            .then(response => {
                if (response.status === 200) {
                    var designation_data = response.data.data.designation_data
                    var designation_data_ui = designation_data.map((item, index) => {
                        return { label: item.name, value: item.id }
                    })
                    this.setState({ "designation_data": designation_data, "designation_data_ui": designation_data_ui })
                }
            })
    }
    handleUserSelect = (value) => {
        this.setState({
            "selected_user": value,
            "selected_site": {},
            "plants_of_location": [],
            "plants_of_location_ui": [],
            "plants_selected_ids": [],
            "plants_selected_ids_ui": [],
            "possible_location_selected_roles": [],
            "department_selected_ids_ui": [],
            "selected_roles_ids_ui": [],
            "user_roles_list": [],
        })

        this.fetchUserData(value)

    }
    handleSiteChange = (value) => {
        debugger;
        this.setState({
            site_name: value,
            validationStatus: { ...this.state.validationStatus, site: false }
        })
        this.setState({ plant_name: '' }, () => {
        });
        this.getPlantsOfLocation(value)
    }
    handleDesignation = (value) => {
        this.setState({
            designation: value,
            validationStatus: { ...this.state.validationStatus, designation: false }
        })
    }
    handlePlantChange = (value) => {
        this.setState({ plant_name: value }, () => {
        });
    }
    handleDepartmentChange = (value) => {
        this.setState({
            department_name: value,
            validationStatus: { ...this.state.validationStatus, department: false }
        })
    }
    fetchUserData = (selected_user) => {
        debugger;
        this.getUserRoles(selected_user)
    }
    getUserRoles = (user_obj) => {
        $('.loader').fadeIn()
        debugger;
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/admin/get/user/roles/assigned/?email=${user_obj}`,)
            .then(response => {
                if (response.status === 200) {
                    if ((response.data.data.user_roles_list).length > 0) {
                        this.setState({
                            "user_access_rows": response.data.data.user_roles_list,
                            "selected_user_roles__ids_to_remove": [],
                            "selected_user_roles_ids_ui": []
                        })

                    }
                    else {
                        this.setState({
                            "user_access_rows": [],
                            "selected_user_roles__ids_to_remove": [],
                            "selected_user_roles_ids_ui": []
                        })

                        notify("User doesn't have any roles", 'warning')
                    }
                }
                $('.loader').fadeOut('slow')
            }).catch(error_response => {

            })
    }
    handleMultiSelect = (event) => {
        var selected_user_roles_ids_ui = []
        var selected_user_roles__ids_to_remove = []
        if (event.target.checked) {
            selected_user_roles_ids_ui = this.state.user_access_rows.map((item, index) => {
                return { label: item.id, value: item.id }
            })
            selected_user_roles__ids_to_remove = selected_user_roles_ids_ui.map((item, index) => {
                return item.value
            })
        }

        this.setState({
            "selected_user_roles_ids_ui": selected_user_roles_ids_ui,
            "selected_user_roles__ids_to_remove": selected_user_roles__ids_to_remove
        })
    }


    handleMultiSelectUserRole = (value, index) => {
        var selected_roles_ids = value.map((item, index) => {
            return item.value
        })
        const rows = [...this.state.rows];
        rows[index].selected_roles_ids_ui = value;
        this.setState({ rows: rows });
        this.setState({
            "selected_roles_ids_ui": value,
            "selected_roles_ids": selected_roles_ids
        })
    }

    handleMultiSelectUserDept = (value, index) => {
        var department_selected_ids = value.map((item, index) => {
            return item.value
        })
        const rows = [...this.state.rows];
        rows[index].department_selected_ids_ui = value;
        this.setState({ rows: rows });
        this.setState({
            "department_selected_ids": department_selected_ids,
            "department_selected_ids_ui": value
        })

    }

    handleMultiSelectPlants = (value, index) => {
        var plants_selected_ids = value.map((item, index) => {
            return item.value
        })
        const rows = [...this.state.rows];
        rows[index].plants_selected_ids_ui = value;
        this.setState({ rows: rows });
        this.setState({
            "plants_selected_ids_ui": value,
            "plants_selected_ids": plants_selected_ids
        })
    }

    togglePasswordVisibility = () => {
        this.setState(prevState => ({
            showPassword: !prevState.showPassword
        }));
    }

    handleUserAccessControlSelected = (event, value) => {
        var selected_user_roles__ids_to_remove = this.state.selected_user_roles__ids_to_remove
        if (event.target.checked) {
            selected_user_roles__ids_to_remove.push(value)
        } else {
            let index = selected_user_roles__ids_to_remove.indexOf(value)
            if (index > -1) {
                selected_user_roles__ids_to_remove.splice(index, 1)
            }
        }
        var selected_user_roles_ids_ui = selected_user_roles__ids_to_remove.map((item, index) => {
            return { value: item, label: item }
        })
        this.setState({
            "selected_user_roles__ids_to_remove": selected_user_roles__ids_to_remove,
            "selected_user_roles_ids_ui": selected_user_roles_ids_ui
        })

    }

    handleSiteSelect = (value, index) => {
        const rows = [...this.state.rows];
        rows[index].selected_site = value;
        this.setState({ rows: rows });
        this.setState({
            "selected_site": value
        })
        this.getPlantsOfLocation(value.value)
    }

    getPlantsOfLocation = (site_name_slug) => {
        $('.loader').fadeIn()
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/admin/get/plants/of/location/?site_name_slug=${site_name_slug}`,)
            .then(response => {
                if (response.status === 200) {
                    var plants_of_location = response.data.data.plants_of_location
                    var plants_of_location_ui = plants_of_location.map((item, index) => {
                        return { label: item.plant.name, value: item.plant.id }
                    })
                    this.setState({
                        "plant_name": "",
                        "plants_of_location": plants_of_location,
                        "plants_of_location_ui": plants_of_location_ui,
                        "plants_selected_ids_ui": [],
                        'plants_selected_ids': [],
                        'department_selected_ids': [],
                        'department_selected_ids_ui': [],
                        "selected_roles_ids": [],
                        'selected_roles_ids_ui': [],

                    })
                }
                $('.loader').fadeOut('slow')
            }).catch(error_response => {

            })
    }

    componentDidUpdate() {
        const urlParams = new URLSearchParams(window.location.search);
        const workflow = urlParams.get('workflow');
        if (workflow) {
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    }



    handleChange = (e, state_obj) => {
        const inputElement = e.currentTarget;

        if (inputElement.value === "") {
            inputElement.classList.add("error");
            inputElement.closest('.login-text-box').style.border = 'none';
        } else {
            inputElement.classList.remove("error");
            inputElement.closest('.login-text-box').style.border = '1px solid #C6D9EB';
        }
    }
    assignUserRole = () => {
        const errors = this.validateFields();
        const isValid = errors.every(error => Object.keys(error).length === 0);

        if (isValid) {
            this.setState({
                rows: [
                    ...this.state.rows,
                    {
                        selected_site: '',
                        plants_selected_ids_ui: [],
                        department_selected_ids_ui: [],
                        selected_roles_ids_ui: []
                    }
                ]
            });
        }
    }
    deleteUserRole = (index) => {
        const rows = [...this.state.rows];
        rows.splice(index, 1);
        this.setState({ rows: rows });
    }



    isValid = () => {
        var name_regexp = new RegExp("<(?:\"[^\"]*\"['\"]*|'[^']*'['\"]*|[^'\">])+>");
        if (!document.querySelector("#first_name").value || !document.querySelector("#user_email").value || !document.querySelector("#user_password").value) {
            var scrollDiv = ""
            if (!document.querySelector("#first_name").value) {
                const invoiceIncludeField = document.querySelector('#first_name');
                invoiceIncludeField.closest('.login-text-box').style.border = 'none';
                document.querySelector('#first_name').classList.add("error")
                scrollDiv = "first_name"
            }
            if (!document.querySelector("#user_email").value) {
                const invoicExcludeField = document.querySelector('#user_email');
                invoicExcludeField.closest('.login-text-box').style.border = 'none';
                document.querySelector('#user_email').classList.add("error")
                scrollDiv = "user_email"
            }

            if (!document.querySelector("#user_password").value) {
                const invoiceNumberField = document.querySelector('#user_password');
                invoiceNumberField.closest('.login-text-box').style.border = 'none';
                document.querySelector('#user_password').classList.add("error")
                scrollDiv = "user_password"
            }
            return { "status": false, "reason": "Please fill required field", "id": scrollDiv }
        }
        if ((document.querySelector("#first_name").value && name_regexp.test(document.querySelector("#first_name").value))) {
            document.querySelector('#first_name').classList.add("error")
            var scrollDiv = "first_name"
            return { "status": false, "reason": "Please provide valid information in input fields.", "id": scrollDiv }
        }
        if ((document.querySelector("#user_email").value && name_regexp.test(document.querySelector("#user_email").value))) {
            document.querySelector('#user_email').classList.add("error")
            var scrollDiv = "user_email"
            return { "status": false, "reason": "Please provide valid information in input fields.", "id": scrollDiv }
        }
        return { "status": true, "reason": "" }
    }
    validateFields = () => {
        const { rows } = this.state;
        const errors = rows.map(row => {
            const error = {};
            if (!row.selected_site || !row.selected_site.value) {
                error.selected_site = true;
            }
            if (!row.selected_roles_ids_ui || row.selected_roles_ids_ui.length === 0) {
                error.selected_roles_ids_ui = true;
            }
            return error;
        });
        this.setState({ errors: errors });
        return errors;
    };

    submitUserData = (e) => {
        e.preventDefault()
        debugger;
        const validationStatus = {
            designation: !this.state.designation,
            site: !this.state.site_name,
            department: !this.state.department_name
        };
        this.setState({ validationStatus });

        // const source_user = this.state.selectedUser['value'];
        if (!validationStatus.designation && !validationStatus.site && !validationStatus.department) {
            const user_roles = this.state.rows.map(row => ({
                selected_site: row.selected_site?.value || null,
                plants_selected_ids: row.plants_selected_ids_ui.map(plant => plant.value),
                department_selected_ids: row.department_selected_ids_ui.map(dept => dept.value),
                selected_roles_ids: row.selected_roles_ids_ui.map(role => role.value)
            }));
            const assigned_user_roles = this.state.selected_user_roles__ids_to_remove
            const first_name = document.getElementById("first_name").value
            const last_name = document.getElementById("last_name").value
            const user_password = document.getElementById("user_password").value
            const user_email = document.getElementById("user_email").value
            const employee_number = document.getElementById('employee_number').value
            const site_name = this.state.site_name
            const plant_name = this.state.plant_name
            const designation = this.state.designation
            const department_name = this.state.department_name
            var userData = {}
            userData['assigned_user_roles'] = assigned_user_roles
            userData['user_roles'] = user_roles
            userData["first_name"] = first_name
            userData["last_name"] = last_name
            userData["user_password"] = user_password
            userData["user_email"] = user_email
            userData["employee_number"] = employee_number
            userData['site_name'] = site_name
            userData['plant_name'] = plant_name
            userData['designation'] = designation
            userData['department_name'] = department_name
            console.log(userData)
            var response_isvalid = this.isValid()
            if (!response_isvalid.status) {
                notify(response_isvalid.reason, 'warning')
                setTimeout(function () {
                    document.querySelector('#' + response_isvalid.id).scrollIntoView({
                        behavior: 'smooth', block: "center", inline: "nearest"
                    })
                }, 100)
                return false
            }
            else {
                $(".loader").css("display", "flex");
                const auth_token = localStorage.getItem("token")
                const requestData = {
                    userData: userData
                };
                axios.defaults.headers.post['Authorization'] = auth_token
                axios.post(`${API_HOST}/user`, requestData)
                    .then(response => {
                        if (response.status === 200 && response.data.success) {
                            notify(response.data.message, 'success');
                            setTimeout(() => {
                                window.location.href = '/settings/';
                            }, 2000)
                        } else {
                            notify(response.data.message, 'error');
                        }
                        $('.loader').fadeOut('slow');
                    }).catch(response => {
                        $('.loader').fadeOut('slow')
                        // ErrorHandling(response)
                    });
            }
        }
    }

    render() {
        const { isChecked, showPassword, users, selectedUser, errors } = this.state;

        let users_options = this.state.users_list.map((each_user, indx) => {
            return (
                { value: each_user.email, label: each_user.first_name + ' ' + each_user.last_name }
            )
        })
        var user_roles_data_ui_for_add_uac = this.state.user_roles_data.map((item, index) => {
            return { label: item.roles_name, value: item.roles_id }
        })
        var selected_uac_ids_to_remove = []

        var table_rows = this.state.user_access_rows.map((item, index) => {
            selected_uac_ids_to_remove.push({ value: item.id, label: item.id })

            return <tr key={"uac_" + index}>
                <td>

                    <div >
                        <input type="checkbox" className=""
                            checked={this.state.selected_user_roles__ids_to_remove.includes(item.id)}
                            onChange={event => this.handleUserAccessControlSelected(event, item.id)} />
                    </div>
                </td>
                <td style={{ "textAlign": "right" }}>{index + 1}</td>
                <td  >{item.location.name}</td>
                <td  >{item.site.name}</td>
                <td  >{item.plant.name ? item.plant.name : '-'}</td>
                <td  >{item.department.name ? item.department.name : '-'}</td>
                <td colSpan="2" >{item.wf_role.name}</td>
            </tr>

        })
        const dynamicRows = this.state.rows.map((row, index) => (
            <tr key={index}>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>
                    <div>
                        <Select
                            id={`add_usr_access_site_id_${index}`}
                            name={`add_usr_access_site_id_${index}`}
                            options={this.state.sites_data_ui}
                            onChange={value => this.handleSiteSelect(value, index)}
                            value={row.selected_site.hasOwnProperty('value') ? row.selected_site : 'Select'}
                            isMulti={false}
                            className={errors[index] && errors[index].selected_site ? 'error-border' : ''}
                        />
                        {errors[index] && errors[index].selected_site && (
                            <div className="error-message">Site cannot be empty</div>
                        )}
                    </div>
                </td>
                <td style={{ maxWidth: '200px' }}>
                    <div>
                        <MultiSelect
                            id={`usr_sel_loc_comb_id_${index}`}
                            name={`usr_sel_loc_comb_id_${index}`}
                            options={this.state.plants_of_location_ui}
                            onChange={selected => this.handleMultiSelectPlants(selected, index)}
                            value={row.plants_selected_ids_ui}
                            labelledBy="Select"
                        />
                    </div>
                </td>
                <td style={{ maxWidth: '200px' }}>
                    <div>
                        <MultiSelect
                            style={{ maxWidth: '100%' }}
                            id={`usr_dept_id_${index}`}
                            name={`usr_dept_id_${index}`}
                            options={this.state.departments_data_ui}
                            onChange={selected => this.handleMultiSelectUserDept(selected, index)}
                            value={row.department_selected_ids_ui}
                            labelledBy="Select"
                        />
                    </div>
                </td>
                <td colSpan="2">
                    <div className="d-flex justify-content-between">
                        <div style={{ width: '200px' }}>
                            <MultiSelect
                                id={`usr_sel_id_${index}`}
                                name={`usr_sel_id_${index}`}
                                options={this.state.roles_data_ui}
                                onChange={selected => this.handleMultiSelectUserRole(selected, index)}
                                value={row.selected_roles_ids_ui}
                                labelledBy="Select"
                                className={errors[index] && errors[index].selected_roles_ids_ui ? 'error-border' : ''}
                            />
                            {errors[index] && errors[index].selected_roles_ids_ui && (
                                <div className="error-message">Roles cannot be empty</div>
                            )}
                        </div>
                        <div>
                            {index === 0 && (
                                <div className="plus-circle-user p-2"><FaPlusCircle className="text-primary" onClick={this.assignUserRole} /></div>
                            )}
                        </div>
                        {index > 0 && (
                            <div className="trash-icon" style={{ marginLeft: '10px', padding: '6px 5px' }}>
                                <FaTrash className="text-danger" onClick={() => this.deleteUserRole(index)} />
                            </div>
                        )}
                    </div>
                </td>
            </tr>
        ))
        return (
            <div className='container' style={{ "paddingBottom": "250px", "paddingTop": "25px" }}>
                <div className='col-12'>
                    <div className="card user-card pb-3">
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-sm-12 col-12 d-flex justify-content-between'>
                                    <h5 className="user-card-title" > <span className="mr-3 arrow-left" style={{ fontSize: '1.0rem', marginRight: '17px' }} onClick={() => { window.location.href = '/settings/' }}><FaArrowLeft /></span><b>New User</b></h5>
                                </div>
                            </div>
                            <div className="row mb-3 mt-4">
                                <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                    <div className='form-label-text font-12'>First Name <span style={{ color: 'red', fontSize: '15px' }}>*</span></div>
                                    <div className='login-text-box'>
                                        <input className='ps-3 border-0 w-100 font-14 form-control' name="first_name" id="first_name" type="text" onChange={(e) => this.handleChange(e, '')} />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                    <div className='form-label-text font-12'>Last Name</div>
                                    <div className='login-text-box'>
                                        <input className='ps-3 border-0 w-100 font-14 form-control' name="last_name" id="last_name" type="text" onChange={(e) => this.handleChange(e, '')} />
                                    </div>
                                </div>



                                <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                    <div className='form-label-text font-12'>E-mail <span style={{ color: 'red', fontSize: '15px' }}>*</span></div>
                                    <div className='login-text-box'>
                                        <input className='ps-3 border-0 w-100 font-14 form-control' name="user_email" id="user_email" type="text" onChange={(e) => this.handleChange(e, '')} />
                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                    <div className='form-label-text font-12'>Password <span style={{ color: 'red', fontSize: '15px' }}>*</span></div>
                                    <div className='login-text-box position-relative'>
                                        <input
                                            className='ps-3 border-0 w-100 font-14 form-control'
                                            name="user_password"
                                            id="user_password"
                                            type={this.state.showPassword ? 'text' : 'password'}
                                            onChange={(e) => this.handleChange(e, '')}
                                        />
                                        <span
                                            className='password-toggle-icon'
                                            style={{ cursor: 'pointer', position: 'absolute', transform: 'translateY(-50%)', top: '50%', right: '21px' }}
                                            onClick={this.togglePasswordVisibility}
                                        >
                                            {this.state.showPassword ? <FaEyeSlash /> : <FaEye />}
                                        </span>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                    <div className='form-label-text font-12'>Employee Number</div>
                                    <div className='login-text-box'>
                                        <input className='ps-3 border-0 w-100 font-14 form-control' name="employe_number" id="employee_number" type="number" onChange={(e) => this.handleChange(e, '')} />
                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                    <div className={`form-label-text font-12 ${this.state.validationStatus.designation ? 'error-text' : ''}`}>
                                        Designation <span style={{ color: 'red', fontSize: '15px' }}>*</span>
                                    </div>
                                    <div>
                                        <select
                                            id="designation"
                                            onChange={e => this.handleDesignation(e.target.value)}
                                            name="designation"
                                            className={`form-control ${this.state.validationStatus.designation ? 'error-border' : ''}`}
                                        >
                                            <option value="">Select</option>
                                            {this.state.designation_data_ui.map(option => (
                                                <option key={option.value} value={option.value}>{option.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-2'>
                                    <div className={`form-label-text font-12 ${this.state.validationStatus.site ? 'error-text' : ''}`}>
                                        Site <span style={{ color: 'red', fontSize: '15px' }}>*</span>
                                    </div>
                                    <div>
                                        <select
                                            id="site"
                                            onChange={e => this.handleSiteChange(e.target.value)}
                                            name="site"
                                            className={`form-control ${this.state.validationStatus.site ? 'error-border' : ''}`}
                                        >
                                            <option value="">Select</option>
                                            {this.state.sites_data_ui.map(option => (
                                                <option key={option.value} value={option.value}>{option.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                    <div className='form-label-text font-12'>Plant</div>
                                    <div>
                                        <select
                                            id="plant"
                                            onChange={e => this.handlePlantChange(e.target.value)}
                                            name="plant"
                                            className="form-control"
                                        >
                                            <option value="">Select</option>
                                            {this.state.plants_of_location_ui.map(option => (
                                                <option key={option.value} value={option.value}>{option.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                    <div className={`form-label-text font-12 ${this.state.validationStatus.department ? 'error-text' : ''}`}>
                                        Department <span style={{ color: 'red', fontSize: '15px' }}>*</span>
                                    </div>
                                    <div>
                                        <select
                                            id="department"
                                            onChange={e => this.handleDepartmentChange(e.target.value)}
                                            name="department"
                                            className={`form-control ${this.state.validationStatus.department ? 'error-border' : ''}`}
                                        >
                                            <option value="">Select</option>
                                            {this.state.departments_data_ui.map(option => (
                                                <option key={option.value} value={option.value}>{option.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                    <div className='form-label-text font-12'>Source User</div>
                                    <div>
                                        <select
                                            id="user"
                                            onChange={e => this.handleUserSelect(e.target.value)}
                                            name="user"
                                            className="form-control"
                                        >
                                            <option value="">Select</option>
                                            {users_options.map(option => (
                                                <option key={option.value} value={option.value}>{option.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <div id='vendor-button-container' className="d-flex d-md-flex justify-content-md-end">
                                    <button className="cancel-button" type="button" onClick={() => { window.location.href = '/settings/' }}>Cancel</button>
                                    <button className="submit-button" type="button" onClick={(e) => { this.submitUserData(e) }}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                &nbsp;
                <div className='col-12'>
                    <div className="card user-card pb-3">
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-lg-2 col-md-2 col-sm-6 col-4 mt-1'>
                                    <h5 className="card-title-user mb-3"> <b>User Roles</b></h5>
                                </div>
                                <div style={{ "marginTop": "20px" }}>
                                    <div className="row mb-3">
                                        <div className="">
                                            {this.state.selected_user_roles__ids_to_remove.length > 0 ?
                                                <div className="d-flex justify-content-left" style={{ "marginBottom": "2px" }}>
                                                    <div>
                                                        <span> {this.state.selected_user_roles__ids_to_remove.length} role(s) selected</span>
                                                    </div>
                                                </div>
                                                : null
                                            }
                                            <table className="table table-hover" id="user_roles_table">
                                                <thead>
                                                    <tr style={{ "borderTop": "1px solid #dee2e6" }}>
                                                        <th style={{ "width": "2%" }} >
                                                            {this.state.user_access_rows.length > 0 ?
                                                                <input type="checkbox" onChange={event => this.handleMultiSelect(event)} />
                                                                : <input type="checkbox" disabled={true} />
                                                            }
                                                        </th>
                                                        <th style={{ "width": "2%" }}>#</th>
                                                        <th style={{ "width": "16%" }}>Location</th>
                                                        <th style={{ "width": "16%" }}>Site</th>
                                                        <th style={{ "width": "16%" }}>Plant</th>
                                                        <th style={{ "width": "18%" }}>Department</th>
                                                        <th colSpan="2" style={{ "width": "20%" }} >Workflow Role</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {table_rows}
                                                    {dynamicRows}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
export default UserCreation;

