import PlantMocLead from "./PlantMocLead";
import AssigneeReview from "./AssigneeReview.js";
import SubTaskPlantMOCLeadReview from "./SubTaskPlantMOCLeadReview.js";

const SubTaskStageComponents = {
    "PlantMOCLead": PlantMocLead,
    "AssigneeReview": AssigneeReview,
    "SubTaskPlantMOCLeadReview": SubTaskPlantMOCLeadReview,
}

export default SubTaskStageComponents;