import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Profilepic from "../../images/ProfilePic.svg"
import $ from 'jquery'
import { API_HOST } from "../../Settings"
import axios from 'axios';
import notify from '../CommonComponents/Notify';
import { Link } from 'react-router-dom';

class ChangePassword extends Component {
    constructor() {
        super();
        this.state ={
            successMessage:false,
            user_data:{}
        }
    }
    onClickShowSuccessMessage = () =>{
        this.setState({
            successMessage:true
        })
    }
    componentDidMount(){
        document.querySelector(".notification-Block")?.classList.add("d-none")
        document.querySelector(".user-profile")?.classList.add("d-none")
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/change-password/`, )
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    user_data: response.data.user_data
                })
                $('.loader').fadeOut('slow');

            }
        }).catch(response => {
            // ErrorHandling(response)
        });
    }
    submitChangePasswordRequest = (e) => {
        e.preventDefault()
        const current_password = document.getElementById("current_password").value
        const new_password = document.getElementById("new_password").value
        const confirm_password = document.getElementById("confirm_password").value
        var userData = this.state
        userData["current_password"] = current_password
        userData["new_password"] = new_password
        userData["confirm_password"] = confirm_password
        if(current_password === "" || new_password === "" || confirm_password == ""){
            notify("Please fill all required fields.",'error')
        }else if(new_password !== confirm_password){
            notify("New password and Confirm password are not same.",'error')
        }else{
            $(".loader").css("display", "flex");
            const auth_token = localStorage.getItem("token")
            axios.defaults.headers.post['Authorization'] =auth_token      
            axios.post(`${API_HOST}/change-password/`, userData)
            .then(response => {
                if (response.status === 200 && response.data.success) {
                    notify(response.data.message,'success')
                    localStorage.clear()
                }else{
                    notify(response.data.message,'error')
                }
                $('.loader').fadeOut('slow')
            }).catch(response => {
                $('.loader').fadeOut('slow')
                // ErrorHandling(response)
            });

        }
        
    }
    render() {
        return(
            // <div className='change-password '>
            //    {this.state.successMessage ?  <div className='d-flex success-messgage'>
            //         <div className='black-tickmark'>&#x2713;</div>
            //         <div className='secoundary-text ps-1'>Password changed successfully.</div>
            //     </div> : null}
            //     <div className='signup-block pe-5 ps-5 pb-5'>
            //                 <div className='text-black text-center fontWeight-bold font-18'>Change Password</div>
            //                 <div className='mt-4 login-details'>
            //                     <div>
            //                         <div className='secoundary-text font-12'>Current Password</div>                                    
            //                         <div className='position-relative w-100 login-text-box'>
            //                             <input className='ps-3 border-0 w-100 font-14' type="password" placeholder='' />
            //                         </div>
            //                     </div>
            //                     <div className='mt-2'>
            //                         <div className='secoundary-text font-12'>New Password</div>
            //                         <div className='position-relative w-100 login-text-box'>
            //                             <input className='ps-3 border-0 w-100 font-14' type="password" placeholder='' />
            //                         </div>
            //                     </div>
            //                     <div className='mt-2'>
            //                         <div className='secoundary-text font-12'>Confirm Password</div>
            //                         <div className='position-relative w-100 login-text-box'>
            //                             <input className='ps-3 border-0 secoundary-text w-100 font-14' type="password" placeholder='********' />
            //                         </div>
            //                     </div>
            //                 </div>                                
            //                 <div className='text-center mt-4'>
            //                     <button className='cancel-btn mt-2'>Cancel</button>
            //                     <button className='cancel-btn save-btn mt-2' onClick={this.onClickShowSuccessMessage}>Save</button>
            //                 </div>
            //     </div>
            // </div>

               <div className='edit-user-profile'>            
                <div className='signup-block pt-2 pb-4 position-relative'>
                {this.state.successMessage ?  <div className='d-flex success-messgage position-absolute' style={{top:"-22px",left:"16%"}}>
                    <div className='black-tickmark'>&#x2713;</div>
                    <div className='secoundary-text ps-1'>Password changed successfully.</div>
                </div> : null}
                            <div className='text-black text-center fontWeight-bold font-18'>Change Password</div>
                            <div className='text-center mt-2'>
                               <img className='profile-img' src={Profilepic} />
                           </div>
                            <div className='mt-2 login-details'>
                                <div>
                                    <div className='secoundary-text font-12'>First Name</div>
                                    <div className='position-relative w-100 login-text-box'>
                                        <input className='ps-3 border-0 w-100 font-14' name="first_name" id="first_name" type="text" placeholder='First Name' value={this.state.user_data.first_name} disabled/>
                                    </div>
                                </div>
                                <div className='mt-2'>
                                    <div className='secoundary-text font-12'>Last Name</div>
                                    <div className='position-relative w-100 login-text-box'>
                                        <input className='ps-3 border-0 w-100 font-14' type="text" name="last_name" id="last_name" placeholder='Last Name' value={this.state.user_data.last_name} disabled/>
                                    </div>
                                </div>
                                <div className='mt-2'>
                                    <div className='secoundary-text font-12'>Email</div>
                                    <div className='position-relative w-100 login-text-box'>
                                        <input className='ps-3 border-0 secoundary-text w-100 font-14' name="email" id="email" type="email" placeholder='Email' value={this.state.user_data.email} disabled/>
                                    </div>
                                </div>
                                <div className='mt-2'>
                                    <div className='secoundary-text font-12'>Current Password</div>
                                    <div className='position-relative w-100 login-text-box'>
                                        <input className='ps-3 border-0 secoundary-text w-100 font-14' name="current_password" id="current_password" type="password" placeholder='' />
                                    </div>
                                </div>
                                <div className='mt-2'>
                                    <div className='secoundary-text font-12'>New Password</div>
                                    <div className='position-relative w-100 login-text-box'>
                                        <input className='ps-3 border-0 secoundary-text w-100 font-14' name="new_password" id="new_password" type="password" placeholder='' />
                                    </div>
                                </div>
                                <div className='mt-2'>
                                    <div className='secoundary-text font-12'>Confirm Password</div>
                                    <div className='position-relative w-100 login-text-box'>
                                        <input className='ps-3 border-0 secoundary-text w-100 font-14' name="confirm_password" id="confirm_password"type="password" placeholder='' />
                                    </div>
                                </div>
                            </div>
                            <div className='text-center mt-3'>
                                <button className='cancel-btn mt-2 chng-pwd-cancel-btn me-2'><Link to="/dashboard">Cancel</Link></button>
                                <button className='cancel-btn mt-2 save-btn' onClick={(e)=>{this.submitChangePasswordRequest(e)}}>Save</button>
                            </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ChangePassword);