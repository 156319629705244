import { Component } from "react";


class UserListLeftStatus extends Component {
    constructor(props) {
        super(props)
        this.state = {
            status: [
                { status: "Active" },
                { status: "Inactive" },
            ],
            selectedStatus: "Active",
        };
    }
    handleStatusChange = (event) => {
        const value = event.target.value;
        this.setState({ selectedStatus: value }, () => {
            this.props.clickTabHandler(event); // Call clickTabHandler after state is updated
        });
    };
    render() {
        var changeStatus = this.state.status.map((status, key) => {
            var label_name;
            if (status.status === "Active") {
                label_name = "Active"
            } else if (status.status === "Inactive") {
                label_name = "Inactive"
            }
            return (
                <div key={key} className="d-flex align-items-center dashboard-left-status filter-input-align">
                    <input className="check-box"
                        name="status"
                        type="checkbox"
                        id={status.status}
                        checked={this.state.selectedStatus === status.status}
                        onChange={this.handleStatusChange}
                        value={status.status} />
                    <label className="ps-1 secoundary-text" htmlFor={label_name} >
                        {label_name}
                    </label>
                </div>
            )

        })

        return (
            <div className="mt-5 pt-3">
                <div className="ms-3 filter-input-checkbox">
                    <div className="ps-3 status-border-bottom">Filter</div>
                    {changeStatus}
                  
                </div>
            </div>
        )
    }
}

export default UserListLeftStatus