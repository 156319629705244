import { Component } from "react";


class DashboardLeftStatus extends Component {
    constructor(props){
        super(props)
    }
    render() {
        var changeStatus = this.props.thisData.status.map((status,key)=>{
            var label_name;
            if (status.status === "opened") {
                label_name = "Open"+" ("+status.count+")";
            } else if (status.status === "rejected") {
                label_name = "Rejected"+" ("+status.count+")";
            } else {
                label_name = "Closed"+" ("+status.count+")";
            }
            return(
                <div key={key} className="d-flex align-items-center dashboard-left-status">
                    <input className="check-box" name="check"
                            type="checkbox"
                            id={status.status}
                            onClick={e => this.props.clickTabHandler(e)}
                            value={status.status} />
                    <label className="ps-1 secoundary-text" htmlFor={label_name} >
                        {label_name}
                    </label>
                </div>
            )

        })
        return(
            <div className="mt-2">
                <div className="ms-3 dashboard-left-status">
                    <div className="ps-3 status-border-bottom">STATUS</div>
                    {changeStatus}
                </div>
            </div>
        )
    }
}

export default DashboardLeftStatus