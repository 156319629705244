import React, { Component } from 'react';
import SearchIcon from '../../images/search.svg';
import Popper from 'popper.js';
import { Dropdown, MenuItem, Button, FormControl, Form, Pagination, Col } from "react-bootstrap";
import { FaPlus,FaUserCog, } from "react-icons/fa";
class UserPageHeader extends Component {
    render() {
        const { currentPage } = this.props;
        return (
            <div className='row pt-2'>
                <div className='col-lg-2 col-md-2 col-12'>
                </div>
                <div className='col-lg-4 col-md-4 col-sm-6 col-12 pt-3'style={{marginLeft:'-22px'}}>
                    <div className='Search-bar-box'>
                        <img className='search-bar-img' src={SearchIcon} alt='search icon' />
                        <input
                            className='search-bar-text'
                            id='search_text'
                            type="text"
                            placeholder='Search'
                            onChange={(e) => this.props.clickTabHandler(e)}
                        />
                    </div>
                    <div className='ms-2'>
                        {/* <button className='search-btn'>SEARCH</button> */}
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 text-align-right pt-3">
                    <div className='d-flex justify-content-end'>
                    {currentPage === 'user' && (
                            <a href="/roles/">
                                <button className='btn  initiator-btn ms-4 p-0'>
                                    <span className='me-2'>User Roles</span>
                                    <span ><FaUserCog size={17}/></span>
                                </button>
                            </a>
                        )}
                        {currentPage === 'user' && (
                            <a href="/user">
                                <button className='btn  initiator-btn ms-4 p-0'>
                                    <span className='me-2'> New User</span>
                                    <span><FaPlus /></span>
                                </button>
                            </a>
                        )}
                        
                    </div>
                </div>
            </div>
        )
    }
}
export default UserPageHeader;
