import React, { Component } from 'react';
import './TaskPopup.css';
import { FaRegCheckCircle } from 'react-icons/fa';
import { BiTrash } from 'react-icons/bi';
import { BsPersonCircle } from 'react-icons/bs';
import { FiPlus } from 'react-icons/fi';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { API_HOST } from '../../../Settings';
import notify from '../../CommonComponents/Notify';
import $ from 'jquery';

class TaskPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            taskName: '',
            assignee: '',
            description: '',
            tasks: [],
            assigneeList: [],
        };
        this.newTaskRef = React.createRef();
    }
    componentDidMount() {
        $('.card-loader').fadeIn();
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token');
        axios.get(`${API_HOST}/assignees/?document_number=${this.props.document_number}`)
            .then(response => {
                if (response.status === 200) {
                    this.setState({ "assigneeList": response.data.users_data })
                }
            })

    }

    togglePopup = () => {
        this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleAssigneeChange = (selectedOption) => {
        // `selectedOption` is an object with `id` and `label` (name)
        this.setState({ assignee: selectedOption.id });  // Set the assignee id
    };


    handleTaskInputChange = (index, event) => {
        const { name, value } = event.target;
        const tasks = [...this.state.tasks];
        tasks[index][name] = value;
        this.setState({ tasks });
    };

    handleDateChange = (date) => {
        this.setState({ dueDate: date });
    };

    formatDueDate = (date) => {
        if (!date) return "No due date";
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Date(date).toLocaleDateString('en-GB', options);
    };

    addTask = () => {
        const { tasks } = this.state;
        const emptyTaskIndex = tasks.findIndex(task => task.subtaskName === '');

        if (emptyTaskIndex !== -1) {
            const emptyTaskRef = this[`taskRef${emptyTaskIndex}`];
            if (emptyTaskRef && emptyTaskRef.current) {
                emptyTaskRef.current.focus();

                setTimeout(() => {
                    emptyTaskRef.current.blur();
                    setTimeout(() => {
                        emptyTaskRef.current.focus();
                    }, 200);
                }, 200);
            }
            return;
        }

        const newTask = { subtaskName: '', assignee: this.state.assignee };

        this.setState(
            (prevState) => ({
                tasks: [...prevState.tasks, newTask],
            }),
            () => {
                const newIndex = this.state.tasks.length - 1;
                const newTaskRef = this[`taskRef${newIndex}`];
                if (newTaskRef && newTaskRef.current) {
                    newTaskRef.current.focus();

                    setTimeout(() => {
                        newTaskRef.current.blur();
                        setTimeout(() => {
                            newTaskRef.current.focus();
                        }, 200);
                    }, 200);
                }
            }
        );
    };

    deleteTask = (index) => {
        this.setState((prevState) => {
            const tasks = [...prevState.tasks];
            tasks.splice(index, 1);
            return { tasks };
        });
    };

    submitData = async () => {
        var id = this.props.document_number;
        var taskData = {}
        taskData["task_name"] = this.state.taskName
        taskData["description"] = this.state.description
        taskData["assignee"] = this.state.assignee
        taskData["id"] = id
        taskData["comments"] = null
        taskData["attachments"] = null
        taskData["parent_id"] = null
        taskData["tasks"] = this.state.tasks
        const userData = localStorage.getItem("user_data");
        const requestData = {
            taskData: taskData,
            userData: userData
        };
        // try {
        //     const response = await axios.post('/create/execution/tasks/', {
        //         taskName,
        //         description,
        //         assignee,
        //         id

        //     });
        //     console.log('Data submitted successfully:', response.data);
        //     this.togglePopup();
        // } catch (error) {
        //     console.error('Error submitting data:', error);
        //     this.togglePopup();
        // }
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')
        axios.post(`${API_HOST}/create/execution/tasks/`, requestData)
            .then(response => {
                if (response.status === 200) {
                    notify(response.data.message, 'success')
                    setTimeout(() => {
                        $('.loader').fadeOut('slow')
                        window.location.reload();
                    }, 1000)
                }

            }).catch(response => {
                // ErrorHandling(response)
            });
        
        
    };

    render() {
        const { assigneeList, assignee } = this.state;
        const selectedAssignee = assigneeList.find(option => option.id === assignee);
        var roles = JSON.parse(localStorage.getItem('roles'))
        var initiator = false
        var disable = false
        if(roles.includes('initiator'))
        {
            initiator = true
        }
        return (
            <div>
                {(this.props.userPermissions.can_reject && this.props.is_rejected === false) &&
                    (this.props.userPermissions.can_approve && this.props.is_approved === false) ? (
                    <div className='pe-2'>
                        <button className="btn btn-success btn-sm" onClick={this.togglePopup}>Add Task</button>
                    </div>
                ) : null}
                 {(this.props.component_name === 'ExecutionofApprovedMOC' && this.props.is_rejected === false  && initiator === true) &&
                    (this.props.userPermissions.can_approve && this.props.is_approved === false  && initiator === true) &&!(this.props.isTaskSubmited)  ? (
                            <div className='pe-2'>
                                <button className="btn btn-success btn-sm" onClick={this.togglePopup}>Add Task</button>
                            </div>
                ) :null}
                {this.state.isOpen && this.props.userPermissions.can_approve && (
                    <div className="popup">
                        <div className="popup-content">
                            <div className="popup-header">
                                <label className="task-main-heading">
                                    Create a Task <span style={{ color: 'gray' }}>({this.props.document_number})</span>
                                </label>
                                <span className="close-btn" onClick={this.togglePopup}>
                                    &times;
                                </span>
                            </div>

                            <div className="popup-body">
                                <div>
                                    <input
                                        type="text"
                                        name="taskName"
                                        className="task-name-input"
                                        value={this.state.taskName}
                                        onChange={this.handleChange}
                                        placeholder="Write a task name"
                                    />
                                </div>


                                <div className="assignee-container">
                                    <div className="assignee-label">Assignee:</div>
                                    <div>
                                        <div>
                                            {/* <BsPersonCircle className="assignee-icon" /> */}
                                            <Select
                                                name="assignee"
                                                className="assignee-dropdown"
                                                value={selectedAssignee}
                                                onChange={this.handleAssigneeChange}
                                                options={assigneeList}
                                                placeholder="Select assignee"
                                            />
                                        </div>
                                    </div>
                                </div>



                                <div className="description-container">
                                    <label className="description-label">Description:</label>
                                    <textarea
                                        name="description"
                                        className="description-input"
                                        value={this.state.description}
                                        onChange={this.handleChange}
                                        placeholder="Enter a task description"
                                    />
                                </div>

                                <div>
                                    <button className="add-task-button" onClick={this.addTask}>
                                        <span>
                                            <FiPlus className="add-task-icon" />
                                        </span>
                                        <span className="add-task-text">
                                            Add subtask
                                        </span>
                                    </button>
                                </div>

                                {this.state.tasks.length > 0 && (
                                    <div className="overflow-x-scroll mt-3">
                                        <table className="table table-bordered">
                                            <thead className="vertical-align-middle">
                                                <tr>
                                                    <th width="50%"><b>SubTask</b></th>
                                                    <th className="text-center"><b>Action</b></th>
                                                </tr>
                                            </thead>
                                            <tbody className="vertical-align-middle">
                                                {this.state.tasks.map((task, index) => (
                                                    <tr key={index}>
                                                        <td width="90%">
                                                            <div className="icon-text-wrapper">
                                                                <FaRegCheckCircle className="task-icon" />
                                                                <input
                                                                    type="text"
                                                                    name="subtaskName"
                                                                    placeholder="Write subtask name here"
                                                                    className="hidden-input"
                                                                    value={task.subtaskName}
                                                                    onChange={(e) => this.handleTaskInputChange(index, e)}
                                                                    ref={this[`taskRef${index}`] = this[`taskRef${index}`] || React.createRef()}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className="text-center">
                                                            <BiTrash
                                                                className="delete-task-icon"
                                                                onClick={() => this.deleteTask(index)}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>

                            <div className="popup-footer">
                                <button className="task-submit-btn" onClick={this.submitData}>
                                    Create
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default TaskPopup;
