import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import $ from 'jquery'
import { API_HOST } from "../../Settings"
import axios from 'axios';
import notify from '../CommonComponents/Notify';
import { Link } from 'react-router-dom';
import Logo from "../../images/logo.svg"
import EmailImage from "../../images/message.svg"

class ForgotPassword extends Component {
    constructor(props){
        super(props)
        this.state = {
            email:"",
            show_recover_btn: true
        }
    }

    componentDidMount(){
        $('.loader').fadeOut('slow')
        $('body').addClass('body-background-image')
    }

    handleEmail = (event)=>{
        event.preventDefault()
        this.setState({"email": event.target.value})
    }

    isValid = ()=>{
        var email = this.state.email
        var pattern = /([A-Za-z0-9]+[.-_])*[A-Za-z0-9]+@[A-Za-z0-9-]+(\.[A-Z|a-z]{2,})+/
        if(email){
            if(email.match(pattern)){
                return true
            }
        }
        return false
    }

    handleRecover = (event) =>{
          event.preventDefault();
          const email =  this.state.email
          if(email && this.isValid()){
            $('.loader').fadeIn();
            const form_data = {"email": email}
            axios.post(API_HOST+'/forgot/password/',form_data)
            .then((response) =>{
                if(response.status===200){
                    notify(response.data.reason, 'success')
                    setTimeout(() => {
                        this.setState({"show_recover_btn": false})
                        $('.loader').fadeOut('slow')
                    }, 4000);   
                }
            })
            .catch(function (error_response) {
                $(".loader").fadeOut('slow')
                notify(error_response.response.data.reason, 'warning')
                var error_msg = error_response.response.data.reason
                document.getElementById("error_msg").innerText=error_msg;
                setTimeout(() => {
                    document.getElementById("error_msg").innerText = '';
                }, 10000);
            });
        }else{
            var error_msg = 'Please enter valid email'
            document.getElementById("error_msg").innerText=error_msg;
            setTimeout(() => {
                document.getElementById("error_msg").innerText = '';
            }, 10000);
        }   
    }

    render(){
        return (
            <div className="container-fluid background-transparent">
                <div className="loader"><div className="spin-loader"></div></div>
                <div className="row pt-5 pb-5 p-4">
                    <div className="col-lg-7 col-md-6 col-12 d-flex justify-content-center align-items-center">
                        <img className='company-logo display-none' src={Logo} />
                    </div>
                    <div className="col-lg-5 col-md-6 col-12">
                        <div className="login-form">
                            
                            <div className='signup-block pe-5 ps-5 pb-5 mt-2'>
                                <div className='text-black text-center fontWeight-bold font-24'>Recover Email</div>
                                <div className='mt-4 login-details'>
                                <p id="error_msg" className="text-danger"></p>
                                    <div className='mb-3'>
                                        <div className='secoundary-text font-12'>Email</div>                                    
                                        <div className='position-relative w-100 login-text-box'>
                                            <img className='position-relative' src={EmailImage} alt='search icon'/>
                                            <input className='ps-3 border-0 w-100 font-14' type="email" id="email"
                                                onChange={(e)=>this.handleEmail(e)} value={this.state.email} />
                                        </div>
                                    </div>
                                    {this.state.show_recover_btn?
                                        <div className='mt-5 w-100'>
                                            <button className='black-btn w-100' style={{height:"37px"}} type="submit"
                                                onClick={(e)=>this.handleRecover(e)} >
                                                    Recover
                                            </button>
                                        </div>
                                        :
                                        <span id="mail_sent_message" style={{"color": "#da4f41e8"}}>
                                            We sent reset link to your email, please check inbox
                                        </span>
                                    }
                                    <div className="text-align-right" style={{"marginBottom": "30px","marginTop": "20px"}}>
                                        <Link to="/login/" style={{"backgroundColor":"white", "color":"black"}}>Sign In</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ForgotPassword;