import React from 'react';
import { Route } from 'react-router-dom';
import TopNav from './Pages//Navbar/TopNav';
// import ErrorBoundary from './ErrorBoundary';
// import Cookies from "universal-cookie";
// const cookies = new Cookies();

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        localStorage.getItem('token')
            ? <TopNav><Component/></TopNav> // Render TopNav here
            : props.history.push('/login')
    )} />
);

export default PrivateRoute;
