import React, { Component } from 'react';
import SearchIcon from '../../images/search.svg'


class SearchBar extends Component {
    render() {
        return(
            <div className='d-flex col-lg-3 col-md-3 col-3 justify-content-center navTabs'>
                <div className='Search-bar-box'>
                    <img className='search-bar-img' src={SearchIcon} alt='search icon'/>
                    <input className='search-bar-text' type="text" placeholder='SEARCH' />
                </div>
                <div className='ms-2'>
                    {/* <button className='search-btn'>SEARCH</button> */}
                </div>
            </div>
        )
    }
}

export default SearchBar;