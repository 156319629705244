import React, { Component, createRef } from "react";
import Attachment from "../../ConsentComponents/Attachment";
import { FaPlusCircle } from "react-icons/fa";
import ApproveButton from "../../ConsentComponents/ApproveButton";
import RejectButton from "../../ConsentComponents/RejectButton";
import Sign from "../../ConsentComponents/Sign";
import Comment from "../../ConsentComponents/Comment";
import axios from 'axios';
import { API_HOST } from '../../../Settings';
import $ from 'jquery';
import notify from '../../CommonComponents/Notify';
import Signed from "../../ConsentComponents/Signed";
import AskButton from "../../ConsentComponents/AskButton";
import ViewAttachments from "../../ConsentComponents/ViewAttachments";
import RejectComponent from "../../CommonComponents/RejectComponent";


class SubmitForClosureByInitiator extends Component {
    constructor(props){
        super(props)
        this.commentRef = createRef();
        this.handleOfComment = this.handleOfComment.bind(this)
        this.state = {
            attachments:[
                {
                    attachment: "",
                    filename: "",
                    file_size: 0,
                    signed_url:"",
                    attachment_filename: ""
                }
            ],
            is_approved:false,
            WorkflowData:this.props.WorkflowData,
            WorkflowComponent:this.props.WorkflowComponent,
            DocumentNumber:this.props.DocumentNumber,
            WorkflowSignatures: this.props.WorkflowData ? this.props.WorkflowData.components[0].workflow_signatures:[],
            enable_signing: false,
            enable_approval: false,
            WorkflowAttachments: this.props.WorkflowComponent?this.props.WorkflowComponent.workflow_attachments:[],
            show_plant_moc_opinion_card: false,
            current_stage_status: "",
            plant_moc_opinion: "",
            userPermissions: {},
            unique_stage_id: '7',
            unique_comp_id: '1',
            unique_combine: 'mans7c1',
            current_component_name: "SubmitForClosureByInitiator",
            ask_for_more_history:[],
            ask_more_stage_name_slug: ""

        }
    }

    static getDerivedStateFromProps(props, state) {
        if(props.WorkflowComponent){
            if(props.WorkflowComponent.workflow_attachments !== state.WorkflowAttachments){
                //Change in props
                return{
                    WorkflowAttachments: props.WorkflowComponent?props.WorkflowComponent.workflow_attachments:state.WorkflowAttachments
                };
            }
        }
        return null; // No change to state
    }

    componentDidMount(){
        this.getUserPrivileges()
        if(this.props.isCollapased==false){
            this.getAllData()
            // $('.card-loader').fadeOut('slow')
        }else{
            let current_stage_info = this.props.WorkflowData
            let is_approved = current_stage_info.components[0].workflow_action_logs[0] && 
                current_stage_info.components[0].workflow_action_logs[0].status == "Approved" ? true : false
            let show_plant_moc_opinion_card = current_stage_info.components[0].workflow_action_logs[0] && 
                current_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false
            let plant_moc_opinion =  current_stage_info.components[0].wf_plant_moc_lead_review[0] ?
                            current_stage_info.components[0].wf_plant_moc_lead_review[0].action.name : ""
            this.setState({is_approved:is_approved, show_plant_moc_opinion_card: show_plant_moc_opinion_card, plant_moc_opinion: plant_moc_opinion,
                // userComponentPermissions: current_stage_info.components[0].user_component_permissions
            })
        }
        $('.card-loader').fadeOut('slow')
    }


    getAllData=()=>{
        $('.card-loader').fadeIn()
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/workflow/manpower/request/${this.props.DocumentNumber}/stage/${this.props.StageName}/`, )
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    WorkflowData:response.data.data.workflow_stage_info,
                    WorkflowComponent:response.data.data.workflow_stage_info.components[0],
                    WorkflowSignatures:response.data.data.workflow_stage_info.components[0].workflow_signatures,
                    enable_signing:response.data.data.workflow_stage_info.components[0].enable_signing,
                    enable_approval:response.data.data.workflow_stage_info.components[0].enable_approval,
                    is_approved: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                        response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Approved" ? true : false,
                    WorkflowAttachments: response.data.data.workflow_stage_info.components[0].workflow_attachments,
                    show_plant_moc_opinion_card: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                        response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false,
                    plant_moc_opinion: response.data.data.workflow_stage_info.components[0].wf_plant_moc_lead_review[0] ?
                        response.data.data.workflow_stage_info.components[0].wf_plant_moc_lead_review[0].action.name : "",
                    current_stage_status: response.data.data.current_stage_status,
                    // userComponentPermissions: response.data.data.workflow_stage_info.components[0].user_component_permissions,
                    ask_for_more_history:response.data.data.ask_for_more_history,
                })
                // this.renderDynamicComponent()
                var ask_for_more_history_filtered = response.data.data.ask_for_more_history.filter(
                            (each, index)=>{
                                return each.status.name_slug=="open"
                        })
                if (ask_for_more_history_filtered.length > 0){
                    this.setState({
                        ask_more_stage_name_slug:ask_for_more_history_filtered[0].stage.name_slug
                    })
                    // console.log(ask_for_more_history_react_component)
                }
            }

        }).catch(response => {
            // ErrorHandling(response)
        });
        $('.card-loader').fadeOut('slow')
    }

    getUserPrivileges = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        let privileges = JSON.stringify(['can_approve', 'can_reject', 'can_ask_for_more_information', 'can_sign', 'can_comment', 'can_attach', 'can_review'])
        let component = this.state.current_component_name
        let params = {"document_number": this.state.DocumentNumber, "from_new": true, "request_type": "manpower_request"}
        axios.get(`${API_HOST}/user/privileges/${component}/${privileges}/`, {params: params})
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    userPermissions:response.data.data,
                })

            }
        }).catch(response => {
            // ErrorHandling(response)
        });
    }

    handleUpdateAttachmentList = (attachment_obj, idx)=>{
        var attachments_list = this.state.attachments
        attachments_list[idx] = attachment_obj;
        this.setState({attachments: attachments_list})

    };

    handleAddAttachmentButton = (e) =>{
        let attachment_obj = {
            attachment: "",
            filename: "",
            file_size: 0,
            signed_url:"",
            attachment_filename: ""
        }
        var attachments_list = this.state.attachments
        if(attachments_list.length==0){
            attachments_list.push(attachment_obj);
            this.setState({attachments: attachments_list})
        }
    };

    handleDeleteAttachmentButton = (e, idx) =>{
        var attachments_list = this.state.attachments
        attachments_list.splice(idx, 1);
        this.setState({attachments: attachments_list})
    }

    attachmentSubmitHandler = (e, attachment_obj, idx) => {
        e.preventDefault()
        var attachment_data = {
            model:"workflow_attachments",
            data:{
                    workflow_definition_id:this.state.WorkflowData.workflow_definition_id,
                    stage_id:this.state.WorkflowData.id,
                    component_id:this.state.WorkflowComponent.id,
                    file_name:attachment_obj.attachment_filename
                }
        }
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
        axios.post(`${API_HOST}/workflow/manpower-request/request/${this.props.DocumentNumber}/form/data/save/`, attachment_data)
        .then(response => {
            if (response.status === 200) {
                notify('Attachment saved successfully','success')
                var attachment_data = response.data.data.workflow_attachments
                var attachment_obj = {
                    attachment_filename:attachment_data.filename,
                    signed_url:attachment_data.singed_url
                }
                let attachments = this.state.WorkflowAttachments
                attachments.push(attachment_data)
                this.setState({WorkflowAttachments: attachments})
                this.handleUpdateAttachmentList(attachment_obj, idx)
                this.updateStageAfterAskMore()
                // $('.card-loader').fadeOut()
            }

        }).catch(response => {
            // ErrorHandling(response)
        });
        // $('.card-loader').fadeOut('slow')
    }

    removeWfAttachment =(event, id_of_wf_attachment, component_id)=>{
        event.preventDefault()
        var user_resp = window.confirm("Are you sure, you want to delete the attachment?")
        if(user_resp){
            $('.card-loader').fadeIn('')
            var attachment_data = {
                workflow_definition_id:this.state.WorkflowData.workflow_definition_id,
                stage_id:this.state.WorkflowData.id,
                component_id:this.state.WorkflowComponent.id,
                wf_attachment_id:id_of_wf_attachment
            }
            axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
            axios.post(`${API_HOST}/workflow/manpower-request/request/${this.props.DocumentNumber}/attachment/delete/`, attachment_data)
            .then(response => {
                if (response.status === 200) {
                    notify('Attachment deleted successfully','success')
                    this.getAllData()
                }

            }).catch(response => {
                // ErrorHandling(response)
                $('.card-loader').fadeOut('slow')
            });
        }
        
    }

    handleSaveAttachmentButton = (event, attachment_obj, idx) =>  {
      event.preventDefault();
      $('.card-loader').fadeIn()
      if(attachment_obj.attachment){
        let form_data_django = new FormData();
        form_data_django.append('attachment', attachment_obj.attachment);
        form_data_django.append('size', attachment_obj.file_size);
        let url = `${API_HOST}/upload/file/in/azure/blob/storage`;
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')
        axios.post(url,form_data_django,
          ).then(response=>{
              if(response.data.success){
                // $('.card-loader').fadeOut()
                  // notify("File successfully uploaded",'information')
                  var attachment_obj = {
                      attachment_filename:response.data.filename,
                      signed_url:response.data.singed_url
                  }
                  this.attachmentSubmitHandler(event, attachment_obj, idx)
              }else{
                  notify(response.data.message,'error')
                  $('.card-loader').fadeOut('slow')
              }

          }).catch(error_xlsx => {

          })

      }else{
          alert("Invalid files selected")
    }
};
    handleOfComment(e, stop_loader, method){
        var response_isvalid = this.commentRef.current.isValid()
        if(!response_isvalid.status){
            if(method == "approve"){
                if(response_isvalid.custom_error_code!=1){
                    // if custom_error_code==1  we can process approval without comment saving
                    notify(response_isvalid.reason,'warning')
                    return false
                }
            }else{
                if(response_isvalid.custom_error_code==1){
                    return false
                }
                if(response_isvalid.custom_error_code==2){
                    // if custom_error_code==1  we can process approval without comment saving
                    notify(response_isvalid.reason,'warning')
                    return false
                }
            }
        }else{
            this.commentRef.current.commentSubmitHandler(e, stop_loader)
            return true
        }
        return true
    }

    updateStageAfterAskMore = () => {
        $('.card-loader').fadeIn()
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
        axios.post(`${API_HOST}//manpower/${this.props.DocumentNumber}/update/ask-more-info/data/`)
        .then(response => {
            if (response.status === 200) {
                setTimeout(()=>{
                    window.location.reload();
                }, 4000)
            }

        }).catch(response => {
            // ErrorHandling(response)
        });
    }

    render() {
        var update_ask_more = false
        if(this.state.ask_more_stage_name_slug != "" && (['closure-of-moc-by-initiator-hod', 'executors-s-hod-reviews-performance', 'review-and-close'].includes(this.state.ask_more_stage_name_slug))){
            update_ask_more = true
        }

        var access_type='view'
        if(this.state.userPermissions.can_attach && !this.state.is_approved && !update_ask_more){
            access_type = 'create'
        }

        return(
            <div className='card mb-3'>
                <div className='p-4'>
                    <div className='heading-Text font-16 mb-3'>Submit for closure by Initiator</div>
                    <div className="mb-3">
                        <div>
                            {this.state.WorkflowAttachments.length > 0 ?
                                <ViewAttachments removeWfAttachment={this.removeWfAttachment}
                                    key={this.state.unique_combine+'va'}
                                    enable_attach_delete={['create', 'update'].includes(access_type)}
                                    WorkflowAttachments={this.state.WorkflowAttachments} />
                            :""}
                        </div>
                        {this.state.userPermissions.can_attach?(this.state.is_approved && !update_ask_more)?'':
                        <div>
                            <div className='heading-Text font-12 mb-3 d-flex'>
                            Upload Attachments
                                <div className="plus-circle">
                                {update_ask_more && this.state.attachments.length>0 ? "" :
                                    <FaPlusCircle onClick={this.handleAddAttachmentButton}/>
                                }
                                </div>
                            </div>
                            {this.state.attachments.map((each, index)=>{
                                return <Attachment AttachmentIndex={index}
                                        AttachmentObj={each}
                                        key={this.state.unique_combine+'attach'+index}
                                        handleUpdateAttachmentList={this.handleUpdateAttachmentList}
                                        handleDeleteAttachmentButton={this.handleDeleteAttachmentButton}
                                        handleSaveAttachmentButton={this.handleSaveAttachmentButton}
                                        />
                            })}
                        </div>:null}
                        
                    </div>
                    <div className='mt-4 row'>
                        <Comment PlantMOCOpinion={this.state.plant_moc_opinion} isRejected={this.state.show_plant_moc_opinion_card} is_approved={this.state.is_approved} WorkflowData={this.state.WorkflowData} WorkflowComponent={this.state.WorkflowComponent}  
                        DocumentNumber={this.state.DocumentNumber} RequestType="manpower-request" userPermissions={this.state.userPermissions} ref={this.commentRef}/>
                        <div className="col-lg-2 col-md-6 col-sm-4 col-4"></div>
                        {this.state.WorkflowSignatures.length > 0?<Signed SignatureData={this.state.WorkflowSignatures[0]}/>:""}
                        <div>
                            {this.state.show_plant_moc_opinion_card ?
                                <RejectComponent
                                    CurrentStageStatus={this.state.current_stage_status}
                                    isRejected={this.state.show_plant_moc_opinion_card}
                                    WorkflowData={this.state.WorkflowData}
                                    WorkflowComponent={this.state.WorkflowComponent}
                                    DocumentNumber={this.state.DocumentNumber}
                                    userPermissions={this.state.userPermissions}
                                    RequestType="manpower-request"
                                />
                            :null}
                        </div>
                    </div>
                    {(this.props.isCollapased || (this.state.show_plant_moc_opinion_card && this.state.plant_moc_opinion != 'Rejected')) ? '':
                    <div className='d-flex w-100 justify-content-end align-items-end tri-buttons mt-4'>
                        {/*<div
                        <div className='pe-2'>
                            <AskButton 
                                IsApproved={this.state.is_approved}
                                stage_status={this.state.stage_status}
                                key={this.state.unique_combine+'askbtn'}
                                WorkflowData={this.state.WorkflowData} 
                                WorkflowComponent={this.state.WorkflowComponent}  
                                DocumentNumber={this.state.DocumentNumber}
                                current_component_name={this.state.current_component_name}
                                ask_for_more_history={this.state.ask_for_more_history}
                                RequestType="manpower-request"
                                userPermissions={this.state.userPermissions}
                                handleOfComment = {this.handleOfComment}
                            />
                        </div>,
                        <div className='pe-2'>
                            <RejectButton
                                key={this.state.unique_combine+'rejbtn'}
                                PlantMOCOpinion={this.state.plant_moc_opinion}
                                IsApproved={this.state.is_approved}
                                WorkflowData={this.state.WorkflowData} 
                                WorkflowComponent={this.state.WorkflowComponent}  
                                DocumentNumber={this.state.DocumentNumber}
                                RequestType="manpower-request"
                                userPermissions={this.state.userPermissions}
                                current_component_name={this.state.current_component_name}
                                unique_combine = {this.state.unique_combine}
                                ask_for_more_history={this.state.ask_for_more_history}
                                stage_status={this.state.current_stage_status}
                                handleOfComment = {this.handleOfComment}
                            />
                        </div>
                        */}
                        <div>
                            <ApproveButton 
                                key={this.state.unique_combine+'aprbtn'}
                                IsApproved={this.state.is_approved}
                                WorkflowData={this.state.WorkflowData} 
                                WorkflowComponent={this.state.WorkflowComponent}  
                                DocumentNumber={this.state.DocumentNumber}
                                RequestType="manpower-request"
                                userPermissions={this.state.userPermissions}
                                current_component_name={this.state.current_component_name}
                                unique_combine = {this.state.unique_combine}
                                ask_for_more_history={this.state.ask_for_more_history}
                                stage_status={this.state.current_stage_status}
                                handleOfComment = {this.handleOfComment}
                            />
                        </div>
                    </div>}
                    <div className='mt-2'>
                        <Sign IsSignatures={this.state.WorkflowSignatures.length > 0?true:false} 
                            IsApproved={this.state.is_approved}
                            WorkflowData={this.state.WorkflowData} WorkflowComponent={this.state.WorkflowComponent}  
                            DocumentNumber={this.state.DocumentNumber} RequestType="manpower-request"
                            userPermissions={this.state.userPermissions}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default SubmitForClosureByInitiator;