import React, { Component, createRef } from 'react';
import ThreeToggle from '../../CommonComponents/ThreeToggle';
import Toggle from '../../CommonComponents/Toggle';
import ApproveButton from '../../ConsentComponents/ApproveButton';
import AskButton from '../../ConsentComponents/AskButton';
import Comment from '../../ConsentComponents/Comment';
import RejectButton from '../../ConsentComponents/RejectButton';
import Remark from '../../ConsentComponents/Remark';
import Sign from '../../ConsentComponents/Sign';
import axios from 'axios';
import { API_HOST } from '../../../Settings';
import $ from 'jquery';
import Signed from "../../ConsentComponents/Signed";
import RejectComponent from "../../CommonComponents/RejectComponent";
import notify from '../../CommonComponents/Notify';
import Attachment from '../../ConsentComponents/Attachment';
import ViewAttachments from '../../ConsentComponents/ViewAttachments';
import { FaPlusCircle } from "react-icons/fa";

class ApprovalBySiteQAHead extends Component {
    constructor(props){
        super(props)
        this.commentRef = createRef();
        this.handleOfComment = this.handleOfComment.bind(this)
        this.state = {
            is_approved:false,
            is_rejected:false,
            WorkflowData:this.props.WorkflowData,
            WorkflowComponent:this.props.WorkflowComponent,
            DocumentNumber:this.props.DocumentNumber,
            WorkflowSignatures:this.props.WorkflowComponent ? this.props.WorkflowComponent.workflow_signatures:[],
            enable_signing: false,
            enable_approval: false,
            show_plant_moc_opinion_card: false,
            plant_moc_opinion: "",
            unique_stage_id: '7',
            unique_comp_id: '12',
            unique_combine: 's7c12',
            stage_status:this.props.stage_status,
            ask_for_more_history: [],
            current_component_name: "ApprovalBySiteQAHead",
            userComponentPermissions: [],
            change_classification: "",
            isDataSubmited: true,
            userPermissions: {},
            attachments:[
                {
                    attachment: "",
                    filename: "",
                    file_size: 0,
                    signed_url:"",
                    attachment_filename: ""
                }
            ],
            WorkflowAttachments:[],
        }
    }
    checkIsApproved = (is_approved) =>{
        this.setState({
            is_approved:is_approved
        })
    }

    handleOfComment(e, stop_loader, method){
        var response_isvalid = this.commentRef.current.isValid()
        if(!response_isvalid.status){
            if(method == "approve"){
                if(response_isvalid.custom_error_code!=1){
                    // if custom_error_code==1  we can process approval without comment saving
                    notify(response_isvalid.reason,'warning')
                    return false
                }
            }else{
                if(response_isvalid.custom_error_code==1){
                    return false
                }
                if(response_isvalid.custom_error_code==2){
                    // if custom_error_code==1  we can process approval without comment saving
                    notify(response_isvalid.reason,'warning')
                    return false
                }
            }
        }else{
            this.commentRef.current.commentSubmitHandler(e, stop_loader)
            return true
        }
        return true
    }

    componentDidMount(){
        this.getUserPrivileges()
        if(this.props.isCollapased==false && this.props.DocumentNumber){
            axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
            axios.get(`${API_HOST}/workflow/moc/request/${this.props.DocumentNumber}/stage/${this.props.StageName}/?component_name=${this.props.ComponentName}`, )
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        WorkflowData:response.data.data.workflow_stage_info,
                        WorkflowComponent:response.data.data.workflow_stage_info.components[0],
                        WorkflowSignatures:response.data.data.workflow_stage_info.components[0].workflow_signatures,
                        enable_signing:response.data.data.workflow_stage_info.components[0].enable_signing,
                        enable_approval:response.data.data.workflow_stage_info.components[0].enable_approval,
                        is_approved: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                            response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Approved" ? true : false,
                        is_rejected: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                            response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false,
                        show_plant_moc_opinion_card: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                            response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false,
                        plant_moc_opinion: response.data.data.workflow_stage_info.components[0].wf_plant_moc_lead_review[0] ?
                            response.data.data.workflow_stage_info.components[0].wf_plant_moc_lead_review[0].action.name : "",
                        ask_for_more_history:response.data.data.ask_for_more_history,
                        // userComponentPermissions: response.data.data.workflow_stage_info.components[0].user_component_permissions
                    })
                    this.getFormData()
                }

            }).catch(response => {
                // ErrorHandling(response)
            });
            $('.card-loader').fadeOut('slow')
        }else{
            let current_stage_info = this.props.WorkflowComponent
            if(current_stage_info){
                let is_approved = this.props.WorkflowComponent.workflow_action_logs.length > 0 && 
                        this.props.WorkflowComponent.workflow_action_logs[0].status == "Approved" ? true : false
                let workflow_signatures = this.props.WorkflowComponent.workflow_signatures
                this.setState({is_approved:is_approved,WorkflowSignatures:workflow_signatures,
                    // userComponentPermissions: current_stage_info.components[0].user_component_permissions
                })
            }
        }
        this.getAllData()
    }
    getAllData=()=>{
        $('.card-loader').fadeIn()
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/workflow/moc/request/${this.props.DocumentNumber}/stage/${this.props.StageName}/?component_name=${this.props.ComponentName}`, )
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    WorkflowData:response.data.data.workflow_stage_info,
                    WorkflowComponent:response.data.data.workflow_stage_info.components[0],
                    WorkflowSignatures:response.data.data.workflow_stage_info.components[0].workflow_signatures,
                    enable_signing:response.data.data.workflow_stage_info.components[0].enable_signing,
                    enable_approval:response.data.data.workflow_stage_info.components[0].enable_approval,
                    is_approved: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                        response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Approved" ? true : false,
                    WorkflowAttachments: response.data.data.workflow_stage_info.components[0].workflow_attachments,
                    is_rejected: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                        response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false,
                    show_plant_moc_opinion_card: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                        response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false,
                    plant_moc_opinion: response.data.data.workflow_stage_info.components[0].wf_plant_moc_lead_review[0] ?
                        response.data.data.workflow_stage_info.components[0].wf_plant_moc_lead_review[0].action.name : "",
                    ask_for_more_history:response.data.data.ask_for_more_history,
                    // userComponentPermissions: response.data.data.workflow_stage_info.components[0].user_component_permissions
                })
                // this.renderDynamicComponent()
            }

        }).catch(response => {
            // ErrorHandling(response)
        });
        $('.card-loader').fadeOut('slow')
    }
    handleUpdateAttachmentList = (attachment_obj, idx)=>{
        var attachments_list = this.state.attachments
        attachments_list[idx] = attachment_obj;
        this.setState({attachments: attachments_list})

    };
    handleAddAttachmentButton = (e) =>{
        let attachment_obj = {
            attachment: "",
            filename: "",
            file_size: 0,
            signed_url:"",
            attachment_filename: ""
        }
        var attachments_list = this.state.attachments
        attachments_list.push(attachment_obj);
        this.setState({attachments: attachments_list})
    };
    handleDeleteAttachmentButton = (e, idx) =>{
        var attachments_list = this.state.attachments
        attachments_list.splice(idx, 1);
        this.setState({attachments: attachments_list})
    }

    attachmentSubmitHandler = (e, attachment_obj, idx) => {
        e.preventDefault()
        var attachment_data = {
            model:"workflow_attachments",
            data:{
                    workflow_definition_id:this.state.WorkflowData.workflow_definition_id,
                    stage_id:this.state.WorkflowData.id,
                    component_id:this.state.WorkflowComponent.id,
                    file_name:attachment_obj.attachment_filename
                }
        }
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
        axios.post(`${API_HOST}/workflow/moc-request/request/${this.props.DocumentNumber}/form/data/save/`, attachment_data)
        .then(response => {
            if (response.status === 200) {
                notify('Attachment saved successfully','success')
                var attachment_data = response.data.data.workflow_attachments
                var attachment_obj = {
                    attachment_filename:attachment_data.filename,
                    signed_url:attachment_data.singed_url
                }
                let attachments = this.state.WorkflowAttachments
                attachments.push(attachment_data)
                this.setState({WorkflowAttachments: attachments})
                this.handleUpdateAttachmentList(attachment_obj, idx)
            }

        }).catch(response => {
            // ErrorHandling(response)
        });
        $('.card-loader').fadeOut('slow')
    }

    removeWfAttachment =(event, id_of_wf_attachment, component_id)=>{
        event.preventDefault()
        var user_resp = window.confirm("Are you sure, you want to delete the attachment?")
        if(user_resp){
            $('.card-loader').fadeIn('')
            var attachment_data = {
                workflow_definition_id:this.state.WorkflowData.workflow_definition_id,
                stage_id:this.state.WorkflowData.id,
                component_id:this.state.WorkflowComponent.id,
                wf_attachment_id:id_of_wf_attachment
            }
            axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
            axios.post(`${API_HOST}/workflow/moc-request/request/${this.props.DocumentNumber}/attachment/delete/`, attachment_data)
            .then(response => {
                if (response.status === 200) {
                    notify('Attachment deleted successfully','success')
                    this.getAllData()
                }

            }).catch(response => {
                // ErrorHandling(response)
                $('.card-loader').fadeOut('slow')
            });
        }
        
    }

    handleSaveAttachmentButton = (event, attachment_obj, idx) =>  {
      event.preventDefault();
      if(attachment_obj.attachment){
        $('.loader').fadeIn()
        let form_data_django = new FormData();
        form_data_django.append('attachment', attachment_obj.attachment);
        form_data_django.append('size', attachment_obj.file_size);
        let url = `${API_HOST}/upload/file/in/azure/blob/storage`;
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')
        axios.post(url,form_data_django,
          ).then(response=>{
              if(response.data.success){
                  // notify("File successfully uploaded",'information')
                $('.loader').fadeOut('slow')
                  var attachment_obj = {
                      attachment_filename:response.data.filename,
                      signed_url:response.data.singed_url
                  }
                  this.attachmentSubmitHandler(event, attachment_obj, idx)
              }else{
                  notify(response.data.message,'error')
                  $('.card-loader').fadeOut('slow')
              }

          }).catch(error_xlsx => {

          })

      }else{
          alert("Invalid files selected")
    }
};

    getFormData = ()=>{
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/and/save/moc/stage7/site/qa/head/${this.props.DocumentNumber}/data/`,)
            .then(response => {
                if (response.status === 200 ) {
                    if(response.data.is_submited){
                            this.setState({
                                "isDataSubmited": true,
                                "change_classification":response.data.change_classification
                            })
                    }else{
                        this.setState({"isDataSubmited": false})
                    }
                }
            }).catch(response => {
                // ErrorHandling(response)
            });
    }


    handleChangeClassification = (e, value)=>{
        if(['minor', 'major', 'moderate'].includes(value)){
            this.setState({"change_classification": value})
        }
    }

    isValid = ()=>{
        if(!['minor', 'major', 'moderate'].includes(this.state.change_classification)){
            return {"status":false, "reason":"Please select Change Classification field"}
        }
        return {"status": true}
    }

    onSubmitHandler = (e) =>{
        e.preventDefault()
        var is_valid_response = this.isValid()
        if(!is_valid_response.status){
            notify(is_valid_response.reason, 'warning')
            return false
        }
        var form_data = {}
        var change_classification = this.state.change_classification
        form_data['change_classification'] = change_classification
        if (true) {
            $('.loader').fadeIn()
            const auth_token = localStorage.getItem("token")
            axios.defaults.headers.post['Authorization'] = auth_token
            axios.post(`${API_HOST}/get/and/save/moc/stage7/site/qa/head/${this.state.DocumentNumber}/data/`, form_data)
            .then(response => {
                if (response.status === 200) {
                    notify(response.data.message,'success')
                    $('.loader').fadeOut('slow')
                    this.setState({isDataSubmited:true})
                }else{

                }
            }).catch(response => {

            });
        }
    }

    getUserPrivileges = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        let privileges = JSON.stringify(['can_approve', 'can_reject', 'can_ask_for_more_information', 'can_sign', 'can_comment', 'can_remark', 'can_create', 'can_review','can_attach'])
        let component = this.state.current_component_name
        let params = {"document_number": this.state.DocumentNumber, "from_new": true, "request_type": "moc_request"}
        axios.get(`${API_HOST}/user/privileges/${component}/${privileges}/`, {params: params})
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    userPermissions:response.data.data,
                })

            }
        }).catch(response => {
            // ErrorHandling(response)
        });
    }

    render() {
        var access_type = "view"
        // if(!['approved', 'rejected'].includes(this.state.stage_status)){
        //     access_type = "create"
        // }
        var ask_for_more_history_filtered = []
        if(this.state){
            if(this.state.hasOwnProperty('ask_for_more_history')){
                ask_for_more_history_filtered = this.state.ask_for_more_history.filter((each, index)=>{
                    return each.status.name_slug=="open"
                })
            }
        }
        if(this.state.ask_more_stage_name_slug != "" && !(['execution-hod-review', 'hod-of-safety-review', 'hod-of-qa-review', 'closure-by-moc-lead'].includes(this.state.ask_more_stage_name_slug))){
            if(ask_for_more_history_filtered.length>0){
                access_type  ="update"
            }
        }
        if(this.state.userPermissions.can_attach && !this.state.is_approved && !this.state.is_rejected){
            access_type = 'create'
        }
        var form_submition_info = {"form_submition_required":true, "is_required_forms_data_submitted": this.state.isDataSubmited}
        return (
                <div className='card mb-3' key={this.state.unique_combine} id="ApprovalBySiteQAHead">
                    <div className='p-4'>
                        <div className='heading-Text font-16'>Approval by Site QA Head</div>
                        {false ?
                            <div>
                                state Plant moc opinion {this.state.plant_moc_opinion}
                                <br/>
                                props isCollapsed {this.props.isCollapased ? 'YES': 'NO'}
                                <br/>
                                state isCollapsed {this.state.isCollapased ? 'YES': 'NO'}
                                <br/>
                                props is_approved {this.props.is_approved ? 'YES': 'NO'}
                                <br/>
                                state is_approved {this.state.is_approved ? 'YES': 'NO'}
                                <br/>
                                workflow_action_logs &nbsp;
                                {this.state.WorkflowComponent.workflow_action_logs.map((row, index)=>{
                                    return('id:' + row['id']+' -- status:' + row['status'] +', ')
                                })}
                                <br/>
                                wf_plant_moc_lead_review &nbsp;
                                {this.state.WorkflowComponent.wf_plant_moc_lead_review.map((row, index)=>{
                                    return ('id:'+row['id']+' -- status:'+ row['action']['name'] +', ')
                                })}
                            </div>
                            :null
                        }
                        <div className='mt-4'>
                            <span><b>QA change Classification shall be based on impact to product quality/Registration/Customer Agreement</b></span>
                            <div className='pb-2 pt-2'>Change Classification by QA</div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="change_classification"
                                    id="minor" value="minor"
                                    onChange={(e) => this.handleChangeClassification(e, "minor")}
                                    checked={this.state.change_classification == "minor"?true:false}
                                    disabled={this.state.isDataSubmited || !this.state.userPermissions.can_create ? true:false}
                                />
                                <label className="form-check-label" htmlFor="minor">Minor</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="change_classification"
                                    id="moderate" value="moderate"
                                    onChange={(e) => this.handleChangeClassification(e, "moderate")}
                                    checked={this.state.change_classification == "moderate"?true:false}
                                    disabled={this.state.isDataSubmited || !this.state.userPermissions.can_create ? true:false}/>
                                <label className="form-check-label" htmlFor="moderate">Moderate</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="change_classification"
                                    id="major" value="major"
                                    onChange={(e) => this.handleChangeClassification(e, "major")}
                                    checked={this.state.change_classification == "major"?true:false}
                                    disabled={this.state.isDataSubmited || !this.state.userPermissions.can_create ? true:false}/>
                                <label className="form-check-label" htmlFor="major">Major</label>
                            </div>
                            {!this.state.userPermissions.can_create|| this.state.isDataSubmited||(this.state.show_plant_moc_opinion_card && this.state.plant_moc_opinion != 'Rejected')?"":
                            <div className='row'>
                                <div className='col-12 text-right'>
                                    <button type="button" className="btn btn-primary" onClick={this.onSubmitHandler} >Submit</button>
                                </div>
                            </div>}
                        </div>
                        <div className='mt-4 row mb-3'>
                            <Remark unique_combine={this.state.unique_combine} is_approved={this.state.is_approved} WorkflowData={this.state.WorkflowData} WorkflowComponent={this.state.WorkflowComponent}  
                                DocumentNumber={this.state.DocumentNumber} userPermissions={this.state.userPermissions} RequestType="moc-request"/>
                        </div>
                        &nbsp;
                        <div>
                            <p><b>Attachments</b></p>
                            { ask_for_more_history_filtered.length>0 || this.state.WorkflowAttachments.length > 0 ?
                                <ViewAttachments removeWfAttachment={this.removeWfAttachment}
                                    key={this.state.unique_combine+'va'}
                                    enable_attach_delete={['create'].includes(access_type)}
                                    WorkflowAttachments={this.state.WorkflowAttachments} />
                        
                            :((this.state.is_approved === true || this.state.is_rejected === true) ? 'No Attachments' : '') }
                        </div>
                        {this.state.userPermissions.can_attach && !(this.state.is_approved || this.state.is_rejected) && !['approved', 'rejected'].includes(this.state.stage_status)?
                        <div>
                            <div className='heading-Text font-12 mb-3 d-flex'>
                            Upload Attachments
                                <div className="plus-circle"><FaPlusCircle onClick={this.handleAddAttachmentButton}/></div>
                            </div>
                            {this.state.attachments.map((each, index)=>{
                                return <Attachment AttachmentIndex={index}
                                        AttachmentObj={each}
                                        key={this.state.unique_combine+'attach'+index}
                                        handleUpdateAttachmentList={this.handleUpdateAttachmentList}
                                        handleDeleteAttachmentButton={this.handleDeleteAttachmentButton}
                                        handleSaveAttachmentButton={this.handleSaveAttachmentButton}
                                        />
                            })}
                        </div>:null}
                        <div className='mt-4 row mb-3'>
                            <Comment is_rejected={this.state.is_rejected}
                                stage_status={this.state.stage_status}
                                unique_combine={this.state.unique_combine}
                                PlantMOCOpinion={this.state.plant_moc_opinion}
                                  isRejected={this.state.show_plant_moc_opinion_card}
                                  is_approved={this.state.is_approved}
                                  WorkflowData={this.state.WorkflowData}
                                  WorkflowComponent={this.state.WorkflowComponent}
                                  DocumentNumber={this.state.DocumentNumber}
                                  RequestType="moc-request"
                                  ref={this.commentRef}
                                  userPermissions={this.state.userPermissions}/>
                            <div className="col-lg-2 col-md-6 col-sm-4 col-4"></div>
                            {this.state.WorkflowSignatures.length > 0 ?
                               <Signed SignatureData={this.state.WorkflowSignatures[0]}/> : ""
                            }
                            {this.state.show_plant_moc_opinion_card ?
                               <RejectComponent
                                   CurrentStageStatus={this.state.stage_status}
                                   isRejected={this.state.show_plant_moc_opinion_card}
                                   WorkflowData={this.state.WorkflowData}
                                   WorkflowComponent={this.state.WorkflowComponent}
                                   DocumentNumber={this.state.DocumentNumber}
                                   userPermissions={this.state.userPermissions}
                                   RequestType="moc-request"
                               />  : null
                            }
                        </div>

                        
                        
                        {(this.props.isCollapased || (this.state.show_plant_moc_opinion_card && this.state.plant_moc_opinion != 'Rejected'))
                            ? '':
                            <div  className='d-flex w-100 justify-content-end align-items-end tri-buttons mt-4'>
                                <div className='pe-2'>
                                    <AskButton 
                                        IsApproved={this.state.is_approved}
                                        stage_status={this.state.stage_status}
                                        key={this.state.unique_combine+'askbtn'}
                                        WorkflowData={this.state.WorkflowData} 
                                        WorkflowComponent={this.state.WorkflowComponent}  
                                        DocumentNumber={this.state.DocumentNumber}
                                        current_component_name={this.state.current_component_name}
                                        ask_for_more_history={this.state.ask_for_more_history}
                                        RequestType="moc-request"
                                        userPermissions={this.state.userPermissions} 
                                        handleOfComment = {this.handleOfComment}/>
                                </div>
                                <div className='pe-2'>
                                    <RejectButton
                                        stage_status={this.state.stage_status}
                                        PlantMOCOpinion={this.state.plant_moc_opinion}
                                        IsApproved={this.state.is_approved}
                                        WorkflowData={this.state.WorkflowData} 
                                        WorkflowComponent={this.state.WorkflowComponent}  
                                        DocumentNumber={this.state.DocumentNumber}
                                        key={this.state.unique_combine+'aprbtn'}
                                        unique_combine = {this.state.unique_combine}
                                        current_component_name={this.state.current_component_name}
                                        ask_for_more_history={this.state.ask_for_more_history}
                                        RequestType="moc-request"
                                        userPermissions={this.state.userPermissions}
                                        handleOfComment = {this.handleOfComment}/>
                                </div>
                                <div>
                                    <ApproveButton
                                        stage_status={this.state.stage_status}
                                        key={this.state.unique_combine+'_approve'}
                                        IsApproved={this.state.is_approved}
                                        WorkflowData={this.state.WorkflowData}
                                        WorkflowComponent={this.state.WorkflowComponent}
                                        DocumentNumber={this.state.DocumentNumber}
                                        key={this.state.unique_combine+'aprbtn'}
                                        unique_combine = {this.state.unique_combine}
                                        current_component_name={this.state.current_component_name}
                                        ask_for_more_history={this.state.ask_for_more_history}
                                        RequestType="moc-request"
                                        form_submition_info={form_submition_info}
                                        handleOfComment = {this.handleOfComment}
                                        userPermissions={this.state.userPermissions}
                                    />
                                </div>
                            </div>
                        }
                        <div className='mt-2'>
                            <Sign IsSignatures={this.state.WorkflowSignatures.length > 0?true:false} 
                            IsApproved={this.state.is_approved}
                            WorkflowData={this.state.WorkflowData} WorkflowComponent={this.state.WorkflowComponent}  
                            DocumentNumber={this.state.DocumentNumber} RequestType="moc-request"
                            userPermissions={this.state.userPermissions}
                            current_component_name={this.state.current_component_name}
                            />
                        </div>
                    </div>
                </div>
        )
    }
}

export default ApprovalBySiteQAHead;