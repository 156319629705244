import React, { Component, createRef } from 'react';
import $ from 'jquery'
import { API_HOST } from '../../Settings.js'
import ApproveButton from "../ConsentComponents/ApproveButton";
import Comment from "../ConsentComponents/Comment";
import RejectButton from "../ConsentComponents/RejectButton";
import Sign from "../ConsentComponents/Sign";
import axios from 'axios';
import notify from '../CommonComponents/Notify';
import '../../Styles/userRoles.css';
import { FaArrowLeft } from "react-icons/fa";
import { withRouter } from 'react-router-dom';
import { Modal, Table } from 'react-bootstrap';
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { FaUserCheck } from "react-icons/fa";
import { FaPlusCircle } from "react-icons/fa";
import Attachment from '../ConsentComponents/Attachment.js';
import AskButton from '../ConsentComponents/AskButton.js';
import ViewAttachments from '../ConsentComponents/ViewAttachments.js';
import { TbCurrencyRupee } from "react-icons/tb";
import { FaCommentDots } from "react-icons/fa";
import { FiInfo } from "react-icons/fi";



class InvoiceView extends Component {
    constructor(props) {
        super(props)
        this.comment_ref = createRef();
        this.handleOfComment = this.handleOfComment.bind(this)
        this.state = {
            user_data: localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : {},
            users_data: [],
            selected_user: {},
            users_list: [],
            stages: [],
            stage_name: "approver-1",
            current_component_name: "Approver1",
            is_approved: false,
            is_rejected: false,
            workflow_data: [],
            workflow_component: [],
            workflow_signatures: [],
            DocumentNumber: this.props.match.params.id,
            enable_signing: false,
            enable_approval: false,
            unique_combine: 'w4s1c1',
            stage_status: "pending",
            user_permissions: {},
            prev_stage_user_permissions: {},
            modal_status: false,
            view_comments: [],
            page:'1',
            is_last_stage: false,
            invoiceDetails: null,
            attachments_data: {
                attachment_url: "",
                signed_url: "",
                file_size: "",
                attachment: ''
            },
            attachments: [
                {
                    attachment: "",
                    filename: "",
                    file_size: 0,
                    signed_url: "",
                    attachment_filename: ""
                }
            ],
            deletable_wf_attachments: [],
            invoice_date: "",
            is_first_stage_approved: false,
            has_pending_ask_more_request:false,
            ask_for_more_history: [],
            info_data:[],
            popupData:[],
        }
    }


    componentWillMount() {
        const { id } = this.props.match.params;
        console.log(id, 'idd....', this.props.match.params)
        const userLoginType = localStorage.getItem('user_login_type');
        const token = localStorage.getItem('token');
        const queryParams = new URLSearchParams(window.location.search);
        const page = queryParams.get('page');
        
        if (page) {
            this.setState({ page: parseInt(page, 10) }, () => {
                // Remove 'page' from URL after updating state
                const newUrl = window.location.pathname;
                window.history.replaceState(null, "", newUrl);
            });
        }
        axios.defaults.headers.common['Authorization'] = token;
        const location_path = window.location.pathname
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token');
        console.log(location_path, 'location_path...')
        var params = { "user_login_type": localStorage.getItem("user_login_type") }
        axios.get(`${API_HOST}/${location_path}`, { params: params })
            .then(response => {
                if (response.data.status === 200) {
                    const invoice_details = response.data.invoice_data[0];
                    this.setState({ invoiceDetails: invoice_details, DocumentNumber: invoice_details.id })
                    axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
                    axios.get(`${API_HOST}/workflow/ibd/${invoice_details.invoice_number}/stages`,)
                        .then(response => {
                            if (response.status === 200) {
                                this.setState({
                                    stages: response.data.stages,
                                }, () => { console.log(this.state.stages) })
                                var stage_name = this.state.stage_name
                                var current_component_name = this.state.current_component_name
                                for (let i = 0; i < response.data.stages.length; i++) {
                                    var stageData = response.data.stages[i]
                                    if(stageData.name_slug == 'final-approver' && stageData.status === 'approved' && !stageData.is_current_stage){
                                        stage_name = stageData.name_slug
                                        current_component_name = 'FinalApprover'
                                        break;
                                    }
                                    if((stageData.status === "pending" || stageData.status === 'rejected') && stageData.is_current_stage){
                                        var prev_component_name = ""
                                        if(stageData.name_slug == "approver-1"){var component_name = "Approver1"}else if(stageData.name_slug == "approver-2"){var component_name = "Approver2"}else if(stageData.name_slug == "approver-3"){var component_name = "Approver3"}else if(stageData.name_slug == "approver-4"){var component_name = "Approver4"}else if(stageData.name_slug == "final-approver"){var component_name = "FinalApprover"}
                                        if(stageData.name_slug == "approver-2"){prev_component_name = "Approver1"}else if(stageData.name_slug == "approver-3"){prev_component_name = "Approver2"}else if(stageData.name_slug == "approver-4"){prev_component_name = "Approver3"}
                                        stage_name = stageData.name_slug
                                        current_component_name = component_name
                                        break;
                                    }
                                }
                                this.setState({
                                    stage_name: stage_name,
                                    current_component_name: current_component_name
                                }, () => { console.log(this.state.current_component_name); console.log(this.state.stage_name) })
                                this.getUserPrivileges(current_component_name, "current")
                                if(prev_component_name){
                                    this.getUserPrivileges(prev_component_name, "prev")
                                }

                                axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
                                axios.get(`${API_HOST}/workflow/ibd/${this.state.DocumentNumber}/stage/${stage_name}/?component_name=${current_component_name}`,)
                                    .then(response => {
                                        if (response.status === 200) {
                                            this.setState({
                                                workflow_data: response.data.data.workflow_stage_info,
                                                workflow_component: response.data.data.workflow_stage_info.components[0],
                                                workflow_signatures: response.data.data.workflow_stage_info.components[0].workflow_signatures,
                                                enable_signing: response.data.data.workflow_stage_info.components[0].enable_signing,
                                                enable_approval: response.data.data.workflow_stage_info.components[0].enable_approval,
                                                is_approved: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] &&
                                                    response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Approved" ? true : false,
                                                is_rejected: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] &&
                                                    response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false,
                                                has_pending_ask_more_request:response.data.data.has_pending_ask_more_request,
                                            }, () => {
                                                console.log(this.state.workflow_data);
                                                console.log(this.state.workflow_component);
                                                console.log(this.state.has_pending_ask_more_request);
                                            })
                                        }
                                    }).catch(response => {
                                    });
                            } 
                            else {
                                console.error('Error fetching invoice details. Status:', response.status);

                            }
                        }).catch(response => {
                        });
                }else if (response.data.status === 401) {
                    notify("Invoice not found", "error")
                    this.props.history.push('/invoices')
                }
                else if (response.data.status === 403) {
                    notify("You are not allowed", "warning")
                    this.props.history.push('/invoices')
                }
                 else {
                    console.error('Error fetching vendor details. Status:', response.status);
                }
                $('.loader').fadeOut('slow');
            })
            .catch(error => console.error('Error fetching vendor details:', error));
        $('.card-loader').fadeOut('slow')
    }
    getAskForMoreInfo = () =>{
        debugger;
        var invoice_details = this.state.invoiceDetails;
        var invoice_id = invoice_details.id
        const auth_token = localStorage.getItem("token")
        axios.defaults.headers.post['Authorization'] = auth_token
        axios.get(`${API_HOST}/ask/for/more/history/ibd/${invoice_id}`)
            .then(response => {
            
                    var data = response.data.info_data;
                    this.setState({'info_data':data})
                
               
            })
    }
    getUserPrivileges = (component, method) => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        let privileges = JSON.stringify(['can_approve', 'can_reject', 'can_sign', 'can_comment', 'can_attach', 'can_update', 'can_ask_for_more_information'])
        let params = { "document_number": this.state.DocumentNumber, "from_new": true, "request_type": "ibd_vendor_management" }
        axios.get(`${API_HOST}/user/privileges/${component}/${privileges}/`, { params: params })
            .then(response => {
                if (response.status === 200) {
                    if(method=="current"){
                        this.setState({
                            user_permissions: response.data.data,
                        })
                    }else{
                        this.setState({
                            prev_stage_user_permissions: response.data.data,
                        })
                    }
                    

                }
            }).catch(response => {
                // ErrorHandling(response)
            });
        this.getAskForMoreInfo()
    }
    handleOfComment(e, stop_loader, method) {
        var response_isvalid = this.comment_ref.current.isValid()
        if (!response_isvalid.status) {
            if (method == "approve") {
                if (response_isvalid.custom_error_code != 1) {
                    // if custom_error_code==1  we can process approval without comment saving
                    notify(response_isvalid.reason, 'warning')
                    return false
                }
            } else {
                if (response_isvalid.custom_error_code == 1) {
                    return false
                }
                if (response_isvalid.custom_error_code == 2) {
                    // if custom_error_code==1  we can process approval without comment saving
                    notify(response_isvalid.reason, 'warning')
                    return false
                }
            }
        } else {
            this.comment_ref.current.commentSubmitHandler(e, stop_loader)
            return true
        }
        return true
    }
    handleTypeOfChangeDate = (e) => {
        this.setState({ invoice_date: e.target.value })
    }
    updateInvoiceData = (e) => {
        e.preventDefault()
        var service_description = document.getElementById("service_description").value
        var attachment = this.state.attachments
        console.log(attachment, 'files...selected..')
        if (service_description === "" || attachment === undefined) {
            notify("Please fill required fields.", 'error')
        }
        else {
            $(".loader").css("display", "flex");
            var formData = new FormData();
            this.state.attachments.forEach(file => {
                if (file.filename) {
                    const fileObj = new File([file.attachment], file.attachment.name, { type: file.attachment.type });
                    const size = file.attachment.size;
                    const file_size = (size / (1024 * 1024)).toFixed(2);
                    formData.append('attachments', fileObj);
                    formData.append('file_size', file_size);
                    formData.append('size', size);
                }
            });
            formData.append('invoiceData', JSON.stringify({
                invoiceId: this.state.invoiceDetails.id,
                description: service_description,
                deletable_wf_attachments: this.state.deletable_wf_attachments,
                current_stage: this.state.current_component_name,
            }));
            const auth_token = localStorage.getItem("token")
            const userLoginType = localStorage.getItem('user_login_type');
            let invoiceCreateUrl = `/invoice`;
            axios.post(`${API_HOST}${invoiceCreateUrl}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': auth_token,
                },
            })
                .then(response => {
                    if (response.status === 200 && response.data.success) {
                        notify(response.data.message, 'success');
                        window.location.reload()
                    } else {
                        notify(response.data.message, 'error');
                    }
                    $('.loader').fadeOut('slow');
                }).catch(response => {
                    $('.loader').fadeOut('slow')
                });
        }
    }
    processInvoice = (e) => {
        e.preventDefault()
        $(".loader").css("display", "flex");
        const auth_token = localStorage.getItem("token")
        var invoice_number = this.state.invoiceDetails.id
        var process_data = {
            model:"ibd_process"
        }
        if(window.confirm("Are you sure. you want to process this invoice.")){
            axios.defaults.headers.post['Authorization'] = auth_token
            axios.post(`${API_HOST}/workflow/ibd-vendor-management/request/${invoice_number}/form/data/save/`, process_data)
                .then(response => {
                    if (response.status === 200 && response.data.status) {
                        notify(response.data.message, 'success');
                        window.location.reload()
                    } else {
                        notify(response.data.message, 'error');
                    }
                    $('.loader').fadeOut('slow');
                }).catch(response => {
                    $('.loader').fadeOut('slow')
                });
        }
    }
    handleChange = (e, state_obj) => {
        if (e.currentTarget.value == "") {
            e.currentTarget.classList.add("error")
        }
        if (e.currentTarget.value && e.currentTarget.classList.contains("error")) {
            e.currentTarget.classList.remove("error")
        }
    }
    handleInvoiceClick = () =>{
        window.location.href = `/invoices?page=${this.state.page}&filter=true`;
    }

    handleDownloadClick = (url, filename) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    handleUpdateAttachmentList = (attachment_obj, idx) => {
        var attachments_list = this.state.attachments
        attachments_list[idx] = attachment_obj;
        this.setState({ attachments: attachments_list })

    };

    handleDeleteAttachmentButton = (e, idx) => {
        var attachments_list = this.state.attachments
        attachments_list.splice(idx, 1);
        this.setState({ attachments: attachments_list })
    }


    handleMarkAttachmentAsDelete = (event, id_of_wf_attachment) => {
        event.preventDefault()
        var deletable_wf_attachments = this.state.deletable_wf_attachments
        deletable_wf_attachments.push(id_of_wf_attachment)
        this.setState({ "deletable_wf_attachments": deletable_wf_attachments }, () => console.log(this.state.deletable_wf_attachments))
    }

    handleAddAttachmentButton = (e) => {
        let attachment_obj = {
            attachment: "",
            filename: "",
            file_size: 0,
            signed_url: "",
            attachment_filename: ""
        }
        var attachments_list = this.state.attachments
        attachments_list.push(attachment_obj);
        this.setState({ attachments: attachments_list })
    };

    removeWfAttachment =(event, id_of_wf_attachment)=>{
        event.preventDefault()
        var user_resp = window.confirm("Are you sure, you want to delete the attachment?")
        if(user_resp){
            this.handleMarkAttachmentAsDelete(event, id_of_wf_attachment)
            $(".loader").css("display", "flex");
            var formData = new FormData();
            formData.append('invoiceData', JSON.stringify({
                invoiceId: this.state.invoiceDetails.id,
                deletable_wf_attachments: this.state.deletable_wf_attachments
            }));
            const auth_token = localStorage.getItem("token")
            let invoiceCreateUrl = `/invoice`;
            axios.post(`${API_HOST}${invoiceCreateUrl}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': auth_token,
                },
            })
            .then(response => {
                if (response.status === 200 && response.data.success) {
                    notify('Attachment removed successfully','success')
                    window.location.reload()
                } else {
                    notify(response.data.message, 'error');
                }
                $('.loader').fadeOut('slow');
            }).catch(response => {
                $('.loader').fadeOut('slow')
            });
        }
    }

    handleSaveAttachmentButton = (event) =>  {
        event.preventDefault();
        var attachment = this.state.attachments
        console.log(attachment, 'files...selected..')
        if (attachment === undefined) {
            notify("Please fill required fields.", 'error')
        }
        else {
            $(".loader").css("display", "flex");
            var formData = new FormData();
            this.state.attachments.forEach(file => {
                if (file.filename) {
                    const fileObj = new File([file.attachment], file.attachment.name, { type: file.attachment.type });
                    const size = file.attachment.size;
                    const file_size = (size / (1024 * 1024)).toFixed(2);
                    formData.append('attachments', fileObj);
                    formData.append('file_size', file_size);
                    formData.append('size', size);
                }
            });
            formData.append('invoiceData', JSON.stringify({
                invoiceId: this.state.invoiceDetails.id,
            }));
            const auth_token = localStorage.getItem("token")
            let invoiceCreateUrl = `/invoice`;
            axios.post(`${API_HOST}${invoiceCreateUrl}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': auth_token,
                },
            })
                .then(response => {
                    if (response.status === 200 && response.data.success) {
                        notify('Attachment saved successfully','success')
                        window.location.reload()
                    } else {
                        notify(response.data.message, 'error');
                    }
                    $('.loader').fadeOut('slow');
                }).catch(response => {
                    $('.loader').fadeOut('slow')
                });
        }
    }
    render() {
        const formatCurrency = (amount) => new Intl.NumberFormat('en-IN', { currency: 'INR' }).format(amount);
        const userLoginType = localStorage.getItem('user_login_type');
        const handleClose = () => {
            this.setState({ modal_status: false }, () => console.log(this.state))
        }
       const handleShowAskMore = (key) => {
            debugger;
            const data = this.state.info_data.find(item => key in item)[key];
            this.setState({
                showPopup: true,
                popupData: data,
                currentKey: key,
            });
        };
        const handleCloseModal = () => {
            this.setState({
                showPopup: false,
                popupData: [],
                currentKey: '',
            });
        };
        const handleShow = (e) => {
            var id = parseInt(e.currentTarget.id.split("_")[1]) + 1
            if (id === 1) { var stage_name = "approver-1" } else if (id === 2) { var stage_name = "approver-2" } else if (id === 3) { var stage_name = "approver-3" } else if (id === 4) { var stage_name = "approver-4" }
            if (id === 1) { var current_component_name = "Approver1" } else if (id === 2) { var current_component_name = "Approver2" } else if (id === 3) { var current_component_name = "Approver3" } else if (id === 4) { var current_component_name = "Approver4" }
            axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
            axios.get(`${API_HOST}/workflow/ibd/${this.state.DocumentNumber}/stage/${stage_name}/?component_name=${current_component_name}`,)
                .then(response => {
                    if (response.status === 200) {
                        this.setState({
                            view_comments: response.data.data.workflow_stage_info.components[0].workflow_comments,
                            workflow_signatures: response.data.data.workflow_stage_info.components[0].workflow_signatures,
                            modal_status: true
                        }, () => {
                            console.log(this.state.view_comments);
                            console.log(this.state.workflow_signatures);
                            console.log(this.state);
                        })
                    }
                }).catch(response => {
                });
        }
        var comments = this.state.view_comments && this.state.view_comments.map((comment_data, indx) => {
            return (<div className='mb-2 mt-20' key={this.state.unique_combine + "vc" + indx}>
                <div className='text-black font-12 text-end mb-2'>{comment_data.created_date_formated}</div>
                <div className='brio-form-control' key={this.state.unique_combine + 'vc' + indx} id={this.state.unique_combine + 'vcid' + indx} type="text" readOnly>
                    {comment_data.message}
                </div>
            </div>)
        })
        var signature = this.state.workflow_signatures && this.state.workflow_signatures.map((signatureData, indx) => {
            return (<div className='col-lg-3 col-md-6 col-sm-12 float-right'>
                <div className='card signed-info'>
                    <div className='card-body'>
                        <p><span className="signed-icon"><IoIosCheckmarkCircleOutline /></span> Signed</p>
                        <p className='no-margin'><span className="signed-icon"><FaUserCheck /></span> {signatureData.user_info.first_name} {signatureData.user_info.last_name} </p>
                        <p className='no-margin'>{signatureData.user_info.user_department} Department</p>
                        <p className='no-margin'><i>{signatureData.created_date_formated}</i></p>
                    </div>
                </div>
            </div>)
        })
        var modal_data = <div className="modal show" style={{ display: 'block', position: 'initial' }}>
            <Modal size='lg' show={this.state.modal_status} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Comments</Modal.Title>
                </Modal.Header>
                <Modal.Body>{comments}{signature}</Modal.Body>
            </Modal>
        </div>
        const rowLen = this.state.stages.length;
        var icons = this.state.stages && this.state.stages.map((stageData, indx)=>{
            if(stageData.name_slug === 'final-approver'){
                return
            }
            var stage_class = "round-tab-next-stage"
            var stage_name = ""
            if(stageData.status === "pending" && stageData.is_current_stage){stage_class="round-tab-current-stage"}else if(stageData.status==="approved"){stage_class="round-tab-approved"}
            if(stageData.name_slug === "approver-1"){stage_name = "L1"}else if(stageData.name_slug === "approver-2"){stage_name = "L2"}else if(stageData.name_slug === "approver-3"){stage_name = "L3"}else if(stageData.name_slug === "approver-4"){stage_name = "L4"}
            if(stageData.ask_prev_stage){
                var stage_name = "A"
                stage_class = "round-tab-re-assign"
            }
            var last_val = rowLen === indx + 2?true:false
            return(
                <>
                    <span className={stage_class} id="step_1">{stage_name}</span>
                    {!last_val ? <span class="vertical_dotted_line"></span> : null}

                </>
            )
        })
        var stage_info = this.state.stages && this.state.stages.map((stageData, indx)=>{
            if(stageData.name_slug === 'final-approver'){
                if(stageData.status === "pending" && stageData.is_current_stage){
                    const last_stage = !this.state.is_last_stage?this.setState({is_last_stage: true}, () => console.log(this.state.is_last_stage)):null
                }
                return
            }
            var stage_name = stageData.name
            var stage_status = "Next Stage"
            var stage_class = "next_stage_status float-right"
            var comments = ""
            var signed_date = ""
            var comments_id = "comments_"+indx
            if(stageData.status === "pending" && stageData.is_current_stage){
                stage_status = "Current Stage"
                stage_class="current_stage_status float-right"
            }else if(stageData.status==="approved"){
                stage_status = "Approved"
                stage_class = "approved_stage_status float-right"
                comments = "Comments"
                signed_date = stageData.signed_date
            }else if(stageData.status==="rejected"){
                stage_status = "Rejected"
                stage_class = "rejected_stage_status float-right"
                comments = "Comments"
                signed_date = ""
            }
            if(stageData.name_slug === 'approver-1' &&stageData.status==='approved'){
                const isapprove = !this.state.is_first_stage_approved?this.setState({is_first_stage_approved: true}, ()=> console.log(this.state.is_first_stage_approved)):null
            }
            if(stageData.ask_prev_stage){
                stage_status = 'Ask More Information'
                stage_class = 'reassigned_stage_status float-right'
            }
            return(
                <>
                    <div className='row pb-3 pt-2' style={{ fontSize: '12px' }}>
                        <div class="col-sm-4">
                            <span class='ibd_stage_name float-left'>{stage_name}   
                            &nbsp;&nbsp;
                            {this.state.info_data.some(item => stage_name in item) ? (
                                <a id={comments_id} onClick={(e) => handleShowAskMore(stage_name)} className="wf4-comments">
                                    <FiInfo color={'#5693b9'} fontSize={'20px'} style={{ marginRight: '10px' }} />
                                </a>
                            ) : null}
                            </span>
                        </div>
                        <Modal
                            size='lg'
                            show={this.state.showPopup}
                            onHide={handleCloseModal}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Ask For More Info for {this.state.currentKey}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>User</th>
                                            <th>Request Initiated Time</th>
                                            <th>Request Completed Time</th>
                                            <th>Duration Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.popupData.length > 0 ? (
                                        this.state.popupData.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.user}</td>
                                                <td>{item.request_initiated_time}</td>
                                                <td>{item.request_completed_time}</td>
                                                <td>{item.duration_time}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="4" style={{ textAlign: 'center', color: 'gray' }}>
                                                No data available
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </Table>
                            </Modal.Body>
                        </Modal>

                        <div class="col-sm-2">
                        </div>
                        <div class="col-sm-6">
                            <span className={stage_class}>{stage_status}</span>
                        </div>
                    </div>
                    <div className='row pb-3 pt-2 fixedContainer' style={{ fontSize: '12px' }}>
                        <div class="col-sm-4">
                            <span class='float-left'><a id={comments_id} onClick={(e) => handleShow(e)} class="wf4-comments">{comments}</a></span>
                        </div>
                        <div class="col-sm-2">
                        </div>
                        <div class="col-sm-6">
                            <span class="float-right" style={{ fontSize: '11px' }}>{signed_date}</span>
                        </div>
                    </div>
                </>
            )
        })
        var inv_status = ''
        if(this.state.invoiceDetails && this.state.invoiceDetails.status.toLowerCase() == "processed"){
            inv_status = <button className="processed-status" type="button">Processed</button>
        }else if(this.state.invoiceDetails && this.state.invoiceDetails.status.toLowerCase() == "pending"){
            inv_status = <button className="pending-status" type="button">Pending</button>
        }else{
            inv_status = <button className="rejected-status" type="button">Rejected</button>
        }
        var access_type = "view"
        if((this.state.invoiceDetails && this.state.invoiceDetails.status === 'Pending' && this.state.user_permissions.can_attach && !this.state.has_pending_ask_more_request) || (this.state.invoiceDetails && this.state.invoiceDetails.status === 'Pending' && this.state.prev_stage_user_permissions.can_attach && this.state.has_pending_ask_more_request)){
            access_type = "update"
        }
        return (
            this.state.invoiceDetails && (
                <div className='container' style={{ "paddingTop": "25px" }}>
                    {modal_data}
                    <div className='row d-flex align-items-stretch'>
                        <div className='col-sm-8 d-flex flex-fill'>
                            <div className="card vendor-card pb-3" style={{ 'flex': 1 }}>
                                <div>
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-sm-12 col-12 d-flex'>
                                                <h5 className="vendor-card-title" style={{ marginRight: '10px' }}> <span className="mr-3 arrow-left" style={{ fontSize: '1.0rem', marginRight: '17px' }} onClick={this.handleInvoiceClick}><FaArrowLeft /></span><b>{this.state.invoiceDetails.vendor}</b> <b><span>-</span></b> <span><b>{this.state.invoiceDetails.invoice_number}</b></span></h5>
                                                {inv_status}
                                            </div>

                                            <div className='col-12 d-flex mt-3'>
                                                <div className='col-6' style={{ marginRight: '50px' }}>
                                                    <h5 className='invoice-view-heading'>Type of Vendor</h5>
                                                    <p className='invoice-view-content'>{this.state.invoiceDetails.vendor_type}</p>
                                                </div>
                                                <div className='col-3'>
                                                    <h5 className='invoice-view-heading'>Date of upload</h5>
                                                    {this.state.invoiceDetails ? (
                                                        <p className='invoice-view-content'>{this.state.invoiceDetails.date_of_ibd}</p>
                                                    ) : (
                                                        <p>...</p>
                                                    )}
                                                </div>
                                                <div className='col-3'>
                                                    <h5 className='invoice-view-heading'>Date of shipment</h5>
                                                    {this.state.invoiceDetails ? (
                                                        <p className='invoice-view-content'>{this.state.invoiceDetails.invoice_date}</p>
                                                    ) : (
                                                        <p>...</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='col-12 d-flex mt-3'>
                                                <div className='col-6' style={{ marginRight: '50px' }}>
                                                    <h5 className='invoice-view-heading'>Proposal initiated by</h5>
                                                    <p className='invoice-view-content'>{this.state.invoiceDetails.proposal_initiated_by}</p>
                                                </div>

                                                <div className='col-3'>
                                                    <h5 className='invoice-view-heading'>Invoice value (Excluding GST)</h5>
                                                    <p className='invoice-view-content'><span><TbCurrencyRupee /></span>{formatCurrency(this.state.invoiceDetails.invoice_total_value_exclude_gst)}</p>
                                                </div>

                                                <div className='col-3'>
                                                    <h5 className='invoice-view-heading'>Invoice value (Including GST)</h5>
                                                    <p className='invoice-view-content'><span><TbCurrencyRupee /></span>{formatCurrency(this.state.invoiceDetails.invoice_total_value_include_gst)}</p>
                                                </div>
                                            </div>

                                            <div className='col-12 d-flex mt-3'>
                                                <div style={{ marginRight: '50px' }}>
                                                    <h5 className='invoice-view-heading'>Rallis Invoice number / Shipment reference number / BL number</h5>
                                                    <p className='invoice-view-content'>{this.state.invoiceDetails.reference_number}</p>
                                                </div>
                                            </div>

                                            <div className='col-12 mt-3'>
                                                <h5 className='invoice-view-heading'>Service Description</h5>
                                                {this.state.has_pending_ask_more_request && this.state.prev_stage_user_permissions.can_update ? <div className="form-group">
                                                    <textarea className="form-control input-text-description" style={{ borderRadius: '5px' }} defaultValue={this.state.invoiceDetails.description} id="service_description" rows="3"></textarea>
                                                </div> : <p className='invoice-view-content'>{this.state.invoiceDetails.description}</p>}
                                            </div>

                                            <div className='row mt-4 mb-3' key={"enclosure-att"}>
                                                <div>
                                                    <p class="invoice-view-heading"> <b>Attachments</b></p>
                                                    {this.state.invoiceDetails && this.state.invoiceDetails.attachment_url.length > 0 ?
                                                        <ViewAttachments removeWfAttachment={this.removeWfAttachment}
                                                            key={this.state.unique_combine+'va'}
                                                            enable_attach_delete={['create', 'update'].includes(access_type)}
                                                            WorkflowAttachments={this.state.invoiceDetails.attachment_url} />
                                                    :""}
                                                </div>
                                                {this.state.has_pending_ask_more_request?this.state.prev_stage_user_permissions.can_attach ?
                                                    this.state.invoiceDetails && this.state.invoiceDetails.status === 'Pending'?
                                                        <div className="p-2 m-1">
                                                            <div className='heading-Text font-12 mb-3 d-flex'>
                                                                Upload Attachments
                                                                <div className="plus-circle"><FaPlusCircle onClick={this.handleAddAttachmentButton} /></div>
                                                            </div>
                                                            {this.state.attachments.map((each, index) => {
                                                                return <Attachment AttachmentIndex={index}
                                                                    AttachmentObj={each}
                                                                    key={this.state.unique_combine+'attach'+index}
                                                                    handleUpdateAttachmentList={this.handleUpdateAttachmentList}
                                                                    handleDeleteAttachmentButton={this.handleDeleteAttachmentButton}
                                                                    handleSaveAttachmentButton={this.handleSaveAttachmentButton}
                                                                />
                                                            })
                                                            }
                                                        </div>
                                                        : null
                                                    : null
                                                    :null
                                                }
                                                {!this.state.has_pending_ask_more_request?this.state.user_permissions.can_attach ?
                                                    this.state.invoiceDetails && this.state.invoiceDetails.status === 'Pending'?
                                                        <div className="p-2 m-1">
                                                            <div className='heading-Text font-12 mb-3 d-flex'>
                                                                Upload Attachments
                                                                <div className="plus-circle"><FaPlusCircle onClick={this.handleAddAttachmentButton} /></div>
                                                            </div>
                                                            {this.state.attachments.map((each, index) => {
                                                                return <Attachment AttachmentIndex={index}
                                                                    AttachmentObj={each}
                                                                    key={this.state.unique_combine+'attach'+index}
                                                                    handleUpdateAttachmentList={this.handleUpdateAttachmentList}
                                                                    handleDeleteAttachmentButton={this.handleDeleteAttachmentButton}
                                                                    handleSaveAttachmentButton={this.handleSaveAttachmentButton}
                                                                />
                                                            })
                                                            }
                                                        </div>
                                                        : null
                                                    : null
                                                    :null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-4 d-flex flex-fill' style={{ paddingLeft: '0px' }}>
                            <div className="card vendor-card pb-4" style={{ marginLeft: '0px', 'flex': 1 }}>
                                <div>
                                    <div className="row card-body" style={{ marginLeft: '0px', paddingRight: '0px' }}>
                                        <div class="col-2 row mb-5">
                                            {userLoginType === 'vendor' ? <>
                                                <span className={this.state.is_first_stage_approved ? 'round-tab-approved float-right' : 'round-tab-current-stage float-right'} id="step_1">L1</span>
                                                <span class="vertical_dotted_line"></span>
                                                <span className={this.state.invoiceDetails && this.state.invoiceDetails.status === 'Processed' ? 'round-tab-approved float-right' : 'round-tab-next-stage  float-right'} id="step_2" style={{ paddingLeft: '10px' }}>F</span>
                                            </> : icons}
                                        </div>
                                        <div class="col-10">
                                            {userLoginType === 'vendor' ? <>
                                                <div className='row pb-4 mb-4 pt-2' style={{ fontSize: '12px' }}>
                                                    <div class="col-sm-4">
                                                        <span class='ibd_stage_name float-left'>Approver 1
                                                       </span>
                                                    </div>
                                                    <div class="col-sm-2">
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <span className={this.state.is_first_stage_approved ? 'approved_stage_status float-right' : 'current_stage_status float-right'}>{this.state.is_first_stage_approved ? 'Approved' : 'Current Stage'}</span>
                                                    </div>
                                                </div>
                                                <div className='row pb-4 mb-4 pt-2' style={{ fontSize: '12px' }}>
                                                    <div class="col-sm-4">
                                                        <span class='ibd_stage_name float-left'>Final Approver</span>
                                                    </div>
                                                    <div class="col-sm-2">
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <span className={this.state.invoiceDetails.status === 'Processed' ? 'approved_stage_status float-right' : 'current_stage_status float-right'}>{this.state.invoiceDetails.status === 'Processed' ? 'Processed' : 'Pending'}</span>
                                                    </div>
                                                </div>
                                            </> : stage_info}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        {(userLoginType === 'vendor') || (this.state.invoiceDetails && (this.state.invoiceDetails.status === "Rejected" || this.state.invoiceDetails.status === "Processed")) || (this.state.is_last_stage) || (!this.state.user_permissions.can_approve && !this.state.user_permissions.can_reject && !this.state.user_permissions.can_comment) || this.state.has_pending_ask_more_request? null : <div className='col-12 col-md-12'>
                            <div className="card vendor-card pb-3">
                                <div className="card-body">
                                    <div className='mt-4 row mb-3'>
                                        <Comment is_rejected={this.state.is_rejected}
                                            stage_status={this.state.stage_status}
                                            unique_combine={this.state.unique_combine + 'comment'}
                                            is_approved={this.state.is_approved}
                                            WorkflowData={this.state.workflow_data}
                                            WorkflowComponent={this.state.workflow_component}
                                            DocumentNumber={this.state.DocumentNumber}
                                            RequestType="ibd-vendor-management"
                                            ref={this.comment_ref}
                                            userPermissions={this.state.user_permissions} />
                                    </div>

                                    <div className='d-flex w-100 justify-content-end align-items-end tri-buttons mt-4'>
                                        <div className='pe-2'>
                                            {this.state.current_component_name!=='Approver1'?
                                            <AskButton 
                                                IsApproved={this.state.is_approved}
                                                stage_status={this.state.stage_status}
                                                key={this.state.unique_combine+'askbtn'}
                                                WorkflowData={this.state.workflow_data} 
                                                WorkflowComponent={this.state.workflow_component}  
                                                DocumentNumber={this.state.DocumentNumber}
                                                current_component_name={this.state.current_component_name}
                                                ask_for_more_history={this.state.ask_for_more_history}
                                                RequestType="ibd-vendor-management"
                                                userPermissions={this.state.user_permissions}
                                                handleOfComment = {this.handleOfComment}
                                            />:null}
                                        </div>
                                        <div className='pe-2'>
                                            <RejectButton
                                                stage_status={this.state.stage_status}
                                                IsApproved={this.state.is_approved}
                                                WorkflowData={this.state.workflow_data}
                                                WorkflowComponent={this.state.workflow_component}
                                                DocumentNumber={this.state.DocumentNumber}
                                                key={this.state.unique_combine + 'rjtbtn'}
                                                unique_combine={this.state.unique_combine}
                                                current_component_name={this.state.current_component_name}
                                                ask_for_more_history={this.state.ask_for_more_history}
                                                RequestType="ibd-vendor-management"
                                                userPermissions={this.state.user_permissions}
                                                handleOfComment={this.handleOfComment} />
                                        </div>
                                        <div>
                                            <ApproveButton
                                                stage_status={this.state.stage_status}
                                                IsApproved={this.state.is_approved}
                                                WorkflowData={this.state.workflow_data}
                                                WorkflowComponent={this.state.workflow_component}
                                                DocumentNumber={this.state.DocumentNumber}
                                                key={this.state.unique_combine + 'aprbtn'}
                                                unique_combine={this.state.unique_combine}
                                                current_component_name={this.state.current_component_name}
                                                ask_for_more_history={this.state.ask_for_more_history}
                                                RequestType="ibd-vendor-management"
                                                handleOfComment={this.handleOfComment}
                                                userPermissions={this.state.user_permissions}
                                            />
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                        <Sign IsSignatures={this.state.workflow_signatures.length > 0 ? true : false}
                                            IsApproved={this.state.is_approved} WorkflowData={this.state.workflow_data}
                                            WorkflowComponent={this.state.workflow_component}
                                            DocumentNumber={this.state.DocumentNumber} RequestType="ibd-vendor-management"
                                            userPermissions={this.state.user_permissions}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {userLoginType === 'rallis' && this.state.user_permissions.can_update && this.state.is_last_stage && this.state.invoiceDetails && this.state.invoiceDetails.status === 'Pending' && !this.state.has_pending_ask_more_request ?<div className="card vendor-card m-3">
                            <div className="card-body">
                                <div id='vendor-button-container' className="d-flex d-md-flex justify-content-md-end">
                                    <button className="cancel-button" type="button" onClick={() => { window.location.href = '/invoices' }}>Cancel</button>
                                    <button className="process-button" type="button" onClick={(e) => { this.processInvoice(e) }}>Process</button>
                                </div>
                            </div>
                        </div> : null}
                        {userLoginType === 'rallis' && this.state.invoiceDetails && this.state.has_pending_ask_more_request  && this.state.prev_stage_user_permissions.can_update ? <div className="card vendor-card m-3">
                            <div className="card-body">
                                <div id='vendor-button-container' className="d-flex d-md-flex justify-content-md-end">
                                    <button className="cancel-button" type="button" onClick={() => { window.location.href = '/invoices' }}>Cancel</button>
                                    <button className="submit-button" type="button" onClick={(e) => { this.updateInvoiceData(e) }}>Update</button>
                                </div>
                            </div>
                        </div>:null}
                        {userLoginType === 'rallis' && this.state.invoiceDetails && this.state.invoiceDetails.status === "Processed"?<div class='card vendor-card m-3'><div class='card-body'>{signature}</div></div>:null}
                    </div>
                </div>
            )

        )

    }
}
export default withRouter(InvoiceView);

