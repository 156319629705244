import React, { Component } from 'react';
import SearchIcon from '../../images/search.svg';
import Popper from 'popper.js';
import { Dropdown, MenuItem, Button, FormControl, Form, Pagination, Col } from "react-bootstrap";
import { FaPlus,FaUserClock,FaDownload } from "react-icons/fa";
import $ from 'jquery'
import { API_HOST } from "../../Settings"
import axios from 'axios';
class VendorPageHeader extends Component {
    handleDownloadInvoices = () => {
        $('.loader').fadeIn();
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token');
        axios.post(`${API_HOST}/invoice/download/xlsx/`, {}, { responseType: 'blob' }) 
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.href = url;
            a.download = 'invoices.xlsx';
            document.body.appendChild(a);
            a.click();
    
            window.URL.revokeObjectURL(url);
            $('.loader').fadeOut('slow');
        })
        .catch(error => {
            console.error("Download failed:", error);
            $('.loader').fadeOut();
        });
    }
    render() {
        const { currentPage} = this.props;
        return (
            <div className='row pt-2'>
                <div className='col-lg-2 col-md-2 col-12'>
                </div>
                <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                    <div className='Search-bar-box'>
                        <img className='search-bar-img' src={SearchIcon} alt='search icon' />
                        <input
                            className='search-bar-text'
                            id='search_text'
                            type="text"
                            placeholder='Search'
                            onChange={(e) => this.props.clickTabHandler(e)}
                        />
                    </div>
                    <div className='ms-2'>
                        {/* <button className='search-btn'>SEARCH</button> */}
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 text-align-right ">
                    <div className='d-flex justify-content-end'>
                        {currentPage === 'vendor' && (
                            <a href="/vendor">
                                <button className='btn  initiator-btn ms-4 p-0'>
                                    <span className='me-2'>Vendor</span>
                                    <span><FaPlus /></span>
                                </button>
                            </a>
                        )}
                         {currentPage === 'invoice' && (
                          <a href='/turn/around/time/ibd'>
                                <button className="btn initiator-btn ms-4 p-0" style={{ width: '150px' }}>
                                     <span className="me-2">Turn Around Time</span>
                                        <span>
                                            <FaUserClock size={18} />
                                        </span>
                                 </button>
                        </a>
                         )}
                        {currentPage === 'invoice' && (
                            
                                <button className='btn  initiator-btn ms-4 p-0' onClick={this.handleDownloadInvoices}>
                                    <span className='me-2'>Export Invoices</span>
                                    <span><FaDownload /></span>
                                </button>
                          
                            
                        )}
                        {currentPage === 'invoice' && (
                            <a href="/invoice">
                                <button className='btn  initiator-btn ms-4 p-0'>
                                    <span className='me-2'>Invoice</span>
                                    <span><FaPlus /></span>
                                </button>
                            </a>
                            
                        )}
                    </div>
                </div>
            </div>
        )
    }
}
export default VendorPageHeader;
