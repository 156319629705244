import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import EmailImage from "../../images/message.svg"
import PasswordImage from "../../images/Password.svg"
import AzureImage from "../../images/microsoftIcon.svg"
import Logo from "../../images/logo.svg"
import RallisOffice from "../../images/about-us-mast-v1.jpg"
import "../../Styles/brioStyle.css"
import { API_HOST } from '../../Settings.js'
import notify from '../CommonComponents/Notify';
import $ from 'jquery'
// import Cookies from "universal-cookie";
// const axios = require('axios').default;
import axios from 'axios';
// const cookies = new Cookies();

class VendorSignIn extends Component {
    componentDidMount() {
        $('.loader').fadeOut('slow')
        $('body').addClass('body-background-image')
        if (this.props.location.state ? this.props.location.state.params.message : '') {
            notify(this.props.location.state.params.message, 'information')
        }

    }
    componentWillUnmount() {
        $('body').removeClass('body-background-image')
    }
    loginSubmit = (event) => {
        event.preventDefault();
        const email = document.getElementById("email").value.trim();
        const password = document.getElementById("password").value.trim();
        console.log(email, password)
        if (email && password) {
            $('.loader').fadeIn();
            var Base64 = {
                // private property
                _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",

                // public method for encoding
                encode: function (input) {
                    var output = "";
                    var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
                    var i = 0;

                    input = Base64._utf8_encode(input);

                    while (i < input.length) {

                        chr1 = input.charCodeAt(i++);
                        chr2 = input.charCodeAt(i++);
                        chr3 = input.charCodeAt(i++);

                        enc1 = chr1 >> 2;
                        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                        enc4 = chr3 & 63;

                        if (isNaN(chr2)) {
                            enc3 = enc4 = 64;
                        } else if (isNaN(chr3)) {
                            enc4 = 64;
                        }

                        output = output +
                            Base64._keyStr.charAt(enc1) + Base64._keyStr.charAt(enc2) +
                            Base64._keyStr.charAt(enc3) + Base64._keyStr.charAt(enc4);
                    }

                    return output;
                },

                _utf8_encode: function (string) {
                    string = string.replace(/\r\n/g, "\n");
                    var utftext = "";

                    for (var n = 0; n < string.length; n++) {

                        var c = string.charCodeAt(n);

                        if (c < 128) {
                            utftext += String.fromCharCode(c);
                        }
                        else if ((c > 127) && (c < 2048)) {
                            utftext += String.fromCharCode((c >> 6) | 192);
                            utftext += String.fromCharCode((c & 63) | 128);
                        }
                        else {
                            utftext += String.fromCharCode((c >> 12) | 224);
                            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                            utftext += String.fromCharCode((c & 63) | 128);
                        }
                    }
                    return utftext;
                },
            }
            var encodedEmail = Base64.encode(email);
            var encodedPassword = Base64.encode(password)
            console.log(encodedEmail, encodedPassword, 'encodedPassword encodedPassword')
            const form_data = 'email=' + email + '&password=' + password
            axios({
                method: 'post',
                url: API_HOST + '/vendor/login/',
                data: { "email": encodedEmail, "password": encodedPassword },
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                }
            }).then((response) => {
                if (response.data.success) {
                    debugger;
                    localStorage.setItem('token', response.data.token)
                    localStorage.setItem('user_data', JSON.stringify(response.data.user_data))
                    localStorage.setItem('user_login_type', response.data.user_login_type)
                    axios.defaults.headers.get['Authorization'] = localStorage.getItem('token');
                    window.location.href = '/invoices';

                } else {
                    localStorage.setItem('token', '')
                    notify(response.data.message, 'error')
                }
                $('.loader').fadeOut('slow')

            })
                .catch(function (response) {
                    $(".loader").fadeOut('slow')
                    var error_msg = response.response.data.non_field_errors[0]
                    console.log(error_msg, 'error_msg...')
                });

        } else {
            var error_msg = 'Please enter valid email and password'
            document.getElementById("error_msg").appendChild(document.createTextNode(error_msg));
            setTimeout(() => {
                document.getElementById("error_msg").innerText = '';
            }, 10000);
        }
    }

    render() {
        return (
            <div className="container-fluid background-transparent">
                <div className="loader"><div className="spin-loader"></div></div>
                <div className="row pt-5 pb-5 p-4">
                    <div className="col-lg-7 col-md-6 col-12 d-flex justify-content-center align-items-center">
                        <img className='company-logo display-none' src={Logo} />
                    </div>
                    <div className="col-lg-5 col-md-6 col-12">
                        <form onSubmit={this.loginSubmit} className="login-form">

                            <div className='signup-block pe-5 ps-5 pb-5 mt-2'>
                                <div className='text-black text-center fontWeight-bold font-20'>Vendor Sign In</div>
                                <div className='mt-4 login-details'>
                                    <p id="error_msg" className="text-danger"></p>
                                    <div className='mb-3'>
                                        <div className='secoundary-text font-12'>Email</div>
                                        <div className='position-relative w-100 login-text-box'>
                                            <img className='position-relative' src={EmailImage} alt='search icon' />
                                            <input className='ps-3 border-0 w-100 font-14' type="email" id="email" />
                                        </div>
                                    </div>
                                    <div className='mb-3'>
                                        <div className='secoundary-text font-12'>Password</div>
                                        <div className='position-relative w-100 login-text-box'>
                                            <img className='position-relative' src={PasswordImage} alt='search icon' />
                                            <input className='ps-3 border-0 w-100 font-14' type="password" id="password" />
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center mb-3'>
                                        {/* <div className='secoundary-text font-12 lightgray-color'>
                                    <input className='position-relative' type="checkbox" style={{top:"2px"}}/>
                                    <span className='ps-1'>Remember me</span>
                                </div>
                                <div className='secoundary-text text-right font-12 lightgray-color'>
                                    <u className='cursorPointer'>Forgot password?</u>
                                </div> */}
                                    </div>
                                    <div className='mt-5 w-100'>
                                        <button className='black-btn w-100' style={{ height: "37px" }} type="submit">Sign In</button>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center mt-4'>
                                    <span className='horizantal-line pt-1'></span>
                                    <span className='ps-2 pe-2 secoundary-text'>Or</span>
                                    <span className='horizantal-line pt-1'></span>
                                </div>
                                <div className='text-center mt-3'>
                                    <button className='singin-btn' type='button' onClick={() => window.open(API_HOST + "/azure/ad/authentication/", "_self")}>
                                        <img src={AzureImage} />
                                        <span className='secoundary-text ps-2'>Sign In with Microsoft</span>
                                    </button>
                                </div>
                                <div className='login-vendor'>
                                    <div className="text-align-right" style={{ "marginBottom": "30px", "marginTop": "20px" }}>
                                        <Link to="/login/" style={{ "backgroundColor": "white", "color": "black" }}>Rallis Login</Link>
                                    </div>
                                    <div className="text-align-right" style={{ "marginBottom": "30px", "marginTop": "20px" }}>
                                        <Link to="/forgot/password/" style={{ "backgroundColor": "white", "color": "black" }}>Forgot Password</Link>
                                    </div>

                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(VendorSignIn);