import React, { Component } from 'react';
import SearchIcon from '../../images/search.svg';
import { FaPlus, FaUserClock } from "react-icons/fa";
import notify from '../CommonComponents/Notify';

class PageHeader extends Component {
    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            var search_text = document.querySelector("#search_text").value
            if(search_text.length < 3){
                notify("Minimum three characters are required for search request",'warning')
                return false
               
            }
            this.props.clickTabHandler(event);
        }
    }
    render() {
        var roles = JSON.parse(localStorage.roles)
        return (
            <div className='row pt-2'>
                <div className='col-lg-2 col-md-2 col-12'>
                </div>
                <div className='col-lg-4 col-md-4 col-sm-6 col-12 d-flex align-items-center'>
                        <div className='Search-bar-box'>
                            <img className='search-bar-img' src={SearchIcon} alt='search icon' />
                            <input className='search-bar-text' id='search_text' type='text' placeholder='Search Requests'  onKeyDown={this.handleKeyPress}/>
                        </div>
                        &nbsp;&nbsp;
                        <div className='pl-3'>
                            <button className='search-btn text-white ' style={{ padding: "3px 10px", height: "33px", width: "auto",cursor: "pointer",border:"1px groove",backgroundColor: "rgba(25, 104, 179, .9)"}}onClick={(e) => this.props.clickTabHandler(e)}>Search</button>
                        </div>
                </div>

                {/* <div className='col-lg-6 col-md-6 col-6 col-sm-12 text-align-right'>
                    <a href="/create/request" className="btn btn-dark">MoC REQUEST</a>
                    <a href="/create/manpower/change/request" className="btn btn-dark ms-4">MANPOWER CHANGE REQUEST</a>
                </div> */}

                <div className="col-lg-6 col-md-6 col-sm-6 col-12 text-align-right">
                    {roles.length > 0 && (
                        <div className="d-flex justify-content-end">
                            {/* Render Turn Around Time button for 'site-head' */}
                            {roles.some(role => ["site-head", "manufacturing-head", "plant-moc-lead", "cqal-head"].includes(role)) && (
                                <>
                                    {(this.props.thisData === 'moc' || this.props.thisData === 'manpower') && (
                                        <a href="/turn/around/time">
                                            <button className="btn initiator-btn ms-4 p-0" style={{ width: '150px' }}>
                                                <span className="me-2">Turn Around Time</span>
                                                <span>
                                                    <FaUserClock size={18} />
                                                </span>
                                            </button>
                                        </a>
                                    )}
                                </>
                            )}

                            {/* Render MoC and Manpower buttons for 'initiator' */}
                            {roles.includes("initiator") && (
                                <>
                                    {this.props.thisData === 'moc' && (
                                        <a href="/create/request?workflow=moc">
                                            <button className="btn initiator-btn ms-4 p-0">
                                                <span className="me-2">MoC</span>
                                                <span className="position-relative" style={{ top: "-1px" }}>
                                                    <FaPlus />
                                                </span>
                                            </button>
                                        </a>
                                    )}

                                    {this.props.thisData === 'manpower' && (
                                        <a href="/create/manpower/change/request?workflow=manpower">
                                            <button className="btn initiator-btn ms-4 p-0">
                                                <span className="me-2">Man Power</span>
                                                <span>
                                                    <FaPlus />
                                                </span>
                                            </button>
                                        </a>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </div>                {/* <div class="col-lg-6 col-md-6 col-6 col-sm-12 text-align-right dropdown">
                    <button class="btn btn-secondary" style={{ width: "140px", height: "40px", backgroundColor: "#2E76BA", color: "#ffffff" }} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        + Initiate
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a href="/create/request" className="btn btn-dark">MoC REQUEST</a>
                        <a href="/create/manpower/change/request" className="btn btn-dark ms-4">MANPOWER CHANGE REQUEST</a>
                    </div>
                </div> */}
            </div>
        )
    }
}

export default PageHeader;