import React, { Component } from "react";
import ApproveButton from '../../ConsentComponents/ApproveButton';
import AskButton from '../../ConsentComponents/AskButton';
import Comment from '../../ConsentComponents/Comment';
import RejectButton from '../../ConsentComponents/RejectButton';
import Sign from '../../ConsentComponents/Sign';

class ApprovalByResearchandDevelopmentHead extends Component {
    render() {
        return(
            <div className='card mb-3'>
            <div className='p-4'>
                <div className='heading-Text font-16'>Approval by Research & Development Head</div>
                <div className='mt-4 w-60'>
                    <Comment />
                </div>
                <div className='d-flex w-100 justify-content-end align-items-end tri-buttons mt-4'>
                    <div className='pe-2'><AskButton /></div>
                    <div className='pe-2'><RejectButton /></div>
                    <div><ApproveButton /></div>               
                </div>
                <div className='mt-2'>
                    <Sign />
                </div>
            </div>
        </div>
            )
    }
}

export default ApprovalByResearchandDevelopmentHead;