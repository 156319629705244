import React, { Component } from 'react';
import { MdAttachFile, MdDeleteOutline } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import { MdSave } from "react-icons/md";
import notify from '../CommonComponents/Notify';
import $ from 'jquery'
import { GrAttachment } from "react-icons/gr";


class Attachment extends Component {
	constructor(props){
		super(props)
		this.state ={
			attachment_index: 0,
			// attachment_filename: '',
			attachment_obj: {}
		}
	}

	static getDerivedStateFromProps(props, state) {
	    if(props.AttachmentIndex !== state.attachment_index || props.AttachmentObj != state.attachment_obj){
	        //Change in props
	        return{
	            attachment_obj: props.AttachmentObj?props.AttachmentObj:state.attachment_obj,
	            attachment_index: props.AttachmentIndex?props.AttachmentIndex:state.attachment_index,
	        };
	    }
	    return null; // No change to state
	}

	getFileInputDetails = (event) =>{
	    var div_element = document.getElementById('file-upload');     
	    if (event.target.files && event.target.files[0]) {
			var ext = event.target.files[0].name.split('.').pop().toLowerCase();
			var extension_length = event.target.files[0].name.split(".")
			var file_name_length = event.target.files[0].name.split(".")[0].split("")
			// var file_size = ((event.target.files[0].size) / (1024*1024)).toFixed(2)
			var file_size = event.target.files[0].size
			if($.inArray(ext, ['png', 'jpg', 'pdf', 'doc', 'docx', 'xlsx', 'ppt', 'pptx']) == -1) {
				notify("Invalid extension provided. Allowed extensions are .jpg, .png, .pdf, .doc, .docx, .xlsx, .ppt, .pptx", "warning")
				return false
			}
			if(file_size>15728640){
				notify("Maximum allowed size for file upto 15MB only.", "warning")
				return false
			}
			if(file_name_length.length < 0 || file_name_length.length > 256){
				notify("File name should contains atleast 1 and not more than 256 characters.", "warning")
				return false
			}
			if(extension_length.length>2){
				notify("Double extension file not allowed in attachments.", "warning")
				return false
			}
	        let imgName = event.target.files[0].name;
	        var temp_name = imgName.toLowerCase();
	        var size = event.target.files[0].size
	        var file_size = (size / (1024*1024)).toFixed(2)
	        div_element.setAttribute('data-title',imgName);
	        // if(this.props.requestType == "create"){
	        var attachment_obj = { 
	            attachment:event.target.files[0],
	            filename:temp_name,
	            file_size:file_size,
	            signed_url:"",
	            attachment_filename:""
	        }
	        this.setState({attachment_obj: attachment_obj})
	        this.props.handleUpdateAttachmentList(attachment_obj, this.state.attachment_index)
	        // }
	    }
	};

	render(){
		return(
			<div className='col-12'>
				{this.state.attachment_obj.signed_url == "" ?
				<div className='d-flex mb-3 col-lg-6 col-md-8 col-12'>
					<div className='col-md-6 p-0'>
						<input type="text" readOnly={true} className="form-control upload-input" placeholder="name" value={this.state.attachment_obj.filename?this.state.attachment_obj.filename: ""}/>
					</div>
				    {/*<input type="file"  name="new_batch_file" accept="image/png,image/jpg,.pdf,.doc,.docx,.xlsx,.dwg" 
				    	onChange={event =>this.getFileInputDetails(event)} className="form-0 form-control" id="drag-drop-file-upload-xlsx"  required/>*/}
					<div className="col-md-4 upload-btn-wrapper">
						{this.state.attachment_obj.filename == "" ?
			            <button className="upload-button">
			            	<b className='float-right'><MdAttachFile /></b>
			            </button>
			            :
			            <button className="selected-button">
			            	<b>{this.state.attachment_obj.filename }</b>
			            </button>
			        	}
			            <input className="form-control" id="file-upload" accept="image/png,image/jpg,.pdf,.doc,.docx,.xlsx,.ppt,.pptx" 
			            		name="form-1-url" placeholder="None" type="file" onChange={event =>this.getFileInputDetails(event)} />
			        </div>
			        <div className="col-md-2 delete-button" title="Delete Attachment">
			        	<AiOutlineDelete onClick={e => this.props.handleDeleteAttachmentButton(e, this.state.attachment_index)} />
			        </div>
			        {this.state.attachment_obj.filename == "" ?
			        	null:
				        <div className="col-md-2 save-button" title="Save Attachment">
				        	<MdSave onClick={e => this.props.handleSaveAttachmentButton(e, this.state.attachment_obj, this.state.attachment_index)} />
				        </div>
				    }
			    </div>
			    : ""
			}
			</div>
		)
	}
}

export default Attachment