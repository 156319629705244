import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { API_HOST } from '../../Settings';
import { FaUser } from "react-icons/fa";
import $ from 'jquery';

class NotificationsPage extends Component {
    constructor() {
        super();
        this.state={
            expandNotification:true,
            notifications: [],
        }
    }
    handelExpandNotification =()=>{
        this.setState({
            expandNotification:!this.state.expandNotification,
        })
    }
    getUserNotifications = () => {
        $('.loader').fadeIn();
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/notifications`, )
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    notifications:response.data.notifications,
                }, () => 
                console.log(this.state))
                $('.loader').fadeOut();
            }
        }).catch(response => {
            // ErrorHandling(response)
        });
    }
    componentDidMount(){
        this.getUserNotifications()
        // $('.loader').fadeOut('slow')
        document.querySelector(".notification-Block")?.classList.add("d-none")
        document.querySelector(".user-profile")?.classList.add("d-none")
    }
    render(){
        var view_all_notifications = <div className='notification-Block-row text-center p-3' style={{"color": "lightgray"}}>No notifications found</div>
        const {expandNotification} =this.state;
        if(this.state.notifications && this.state.notifications.length>0){
            view_all_notifications = this.state.notifications.map((notification,key)=>{
                var notification_status =  notification.status
                var notification_status_display = notification_status && notification_status[0].toUpperCase() + notification_status.slice(1)
                return (
                        <div key={'notification_'+key} className='mb-3 butterfly-card notification-Block-row' onClick={this.handelExpandNotification}>
                            
                            <div className='d-flex justify-content-between'>                            
                                <div className='secoundary-text'>
                                    <span className='green-title-color me-2'>
                                        <b>{notification.document_number?"# "+notification.document_number:""} </b>
                                    </span>
                                    <FaUser className='userIcon2'/> <span className='user-icon-text'>{notification.user_name}</span>
                                </div>
                                <div className='font-12 lightgray-color ms-2'>{notification.created_date}</div>
                            </div>
                            <div className='secoundary-text pt-2 font-14'>
                                {notification_status_display}
                            </div>
                        {expandNotification ? <div className='secoundary-text'>
                                {notification.message}
                            </div> :null}
                            {!expandNotification ?  <div className='secoundary-text'>
                                {notification.message}
                            </div> :null}
                        </div>
                )
            })
        }
        return(
            <div className='notification-page mt-4 p-5'>
                <div className='green-title-color fontWeight-bold ps-4 pb-2'><h5 className='border-bottom pb-2'>Notifications</h5></div>
                    {view_all_notifications}
            </div>
        )
    }
}

export default withRouter(NotificationsPage);