// import $ from 'jquery'
import notify from './CommonComponents/Notify';

const ErrorHandling = (Response) => {
    if(Response){
        var error_response = Response.response
        var status_code = error_response.status
        if(status_code >= 400 && status_code <= 600){
            var clear_local_storage = false
            var reason = Response.response.data.reason
            var status = Response.response.data.status
            if(status_code===403){
                notify(reason, 'error')
                setTimeout(function(){ 
                    window.location.href = '/'
                }, 3000);
            }
            else if(status_code === 404){
                // notify('Page Not Found','error')
                notify(reason,'error')
                setTimeout(function(){ 
                    window.location.href = '/'
                }, 3000);
            }else if(status_code === 401){
                notify(reason, "error")
                clear_local_storage = true                
            }else if(status_code >= 500 && status_code <= 600){
                notify('Server encounter error.','error')
                //clear_local_storage = true
            }else{
                notify('Something went wrong, Try again later.','error')  
            }
            setTimeout(function(){
                if(clear_local_storage){
                    localStorage.clear() 
                }                
            },1000)
        }
    }
    return('')
}

export default ErrorHandling
