import React, { Component } from 'react';
import { FiEdit } from "react-icons/fi";
import { FiTrash2 } from "react-icons/fi";
import { FiRefreshCw } from "react-icons/fi";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { API_HOST } from '../../Settings.js'
import axios from 'axios';
import notify from '../CommonComponents/Notify';
import '../../Styles/userRoles.css';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import $ from 'jquery'


class Pagination extends Component {
    render() {
        const { currentPage, totalPages, itemsPerPage, totalItems, onPageChange } = this.props;

        // Calculate the range of items being displayed
        const indexOfLastItem = Math.min(currentPage * itemsPerPage, totalItems);
        const indexOfFirstItem = Math.max(0, indexOfLastItem - itemsPerPage);

        const displayRange = `${indexOfFirstItem + 1} - ${indexOfLastItem} of ${totalItems} records`;


        return (
            <div className="pagination">
                {currentPage > 1 && (
                    <span onClick={() => onPageChange(currentPage - 1)} className="arrow">
                        {'<'}
                    </span>
                )}

                {[...Array(totalPages).keys()].map((index) => (
                    <span
                        key={index}
                        onClick={() => onPageChange(index + 1)}
                        className={`page-number ${currentPage === index + 1 ? 'selected' : ''}`}
                    >
                        {index + 1}
                    </span>
                ))}

                {currentPage < totalPages && (
                    <span onClick={() => onPageChange(currentPage + 1)} className="arrow">
                        {'>'}
                    </span>
                )}

                <span className="page-info">{displayRange}</span>
            </div>
        );
    }

}



// VendorListRequest component
class UserListRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            itemsPerPage: 10, // Adjust as needed
            showModal: false,
            showSecondModal:false,
            deleteUserId: null,
            resetPasswordId:null,
            user_name:'',
            showNewPassword: false,
            showConfirmNewPassword: false,
        };
    }



    onPageChange = (pageNumber) => {
        this.setState({ currentPage: pageNumber });
    };

    handleUpdateUserClick = (userId) => {
        this.props.history.push({
            pathname: `/user/${userId}`,
        });

    };

    deleteUser = (id) => {
        this.setState({ deleteUserId: id });
        this.handleShowModal();
    };
    resetPassword = (id,first_name,last_name) =>{
        this.setState({ resetPasswordId: id,user_name:first_name+" "+last_name });
        this.handleShowSecondModal();
    }
    handlePasswordResetConfirmed = (id) => {
        debugger;
        const new_password = document.getElementById("new_password").value
        const confirm_new_password = document.getElementById("confirm_new_password").value
        var user_Data = {}
        user_Data["new_password"] = new_password
        user_Data["confirm_new_password"] = confirm_new_password
        const requestData = {
            userData:user_Data
        };
        const auth_token = localStorage.getItem("token")
        axios.defaults.headers.post['Authorization'] = auth_token
        axios.post(`${API_HOST}/update/user/password/${id}`, requestData)
        .then(response => {

            if (response.status === 200 && response.data.success) {
                notify(response.data.message, 'success');
                setTimeout(() => {
                    window.location.href = '/settings/';
                }, 2000)
            }
            else {
                notify(response.data.message, 'error');
            }
            $('.loader').fadeOut('slow');
        }).catch(response => {
            $('.loader').fadeOut('slow')
            // ErrorHandling(response)
        });
    }

    handleDeleteUserConfirmed = (id) => {
        this.handleCloseModal();
        const url = `${API_HOST}/delete/user/${id}`;
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token');
        axios.post(url)
            .then(response => {
                if (response.status === 200 && response.data.success) {
                    notify(response.data.message, 'success');
                    setTimeout(() => {
                        window.location.href = '/settings/';
                    }, 2000)
                } else {
                    notify(response.data.message, 'error');
                }
                $('.loader').fadeOut('slow');
            })
            .catch(response => {
                $('.loader').fadeOut('slow');
                // ErrorHandling(response)
            });
    };
    

    handleShowModal = () => {
        this.setState({ showModal: true });
    };

    handleCloseModal = () => {
        this.setState({ showModal: false });
    };

    handleShowSecondModal = () => {
        this.setState({ showSecondModal: true });
    };

    handleCloseSecondModal = () => {
        this.setState({ showSecondModal: false });
    };
    toggleNewPasswordVisibility = () => {
        this.setState(prevState => ({
          showNewPassword: !prevState.showNewPassword
        }));
      }
      
      toggleConfirmNewPasswordVisibility = () => {
        this.setState(prevState => ({
          showConfirmNewPassword: !prevState.showConfirmNewPassword
        }));
      }



    handleCreateUserClick = () => {
        window.location.href = '/user';
    };

    render() {
        debugger
        const { currentPage, itemsPerPage } = this.state;
        const { usersListData } =  this.props.thisData;
        let currentItems;
        if (usersListData) {
          // Calculate pagination
          const indexOfLastItem = currentPage * itemsPerPage;
          const indexOfFirstItem = indexOfLastItem - itemsPerPage;
          currentItems = usersListData.slice(indexOfFirstItem, indexOfLastItem);
        }

        const dashboard_data = currentItems.map((data, index) => (
            <tr key={index} style={{ border: 'none', color: '#898989', fontSize: '14px' }}>
                <td style={{ border: 'none', width: '30%' }}>{data.first_name}</td>
                <td style={{ border: 'none' }}>{data.last_name}</td>
                <td style={{ border: 'none', width: '25%' }}>{data.user_email}</td>
                <td style={{ border: 'none' }}>
                    {data.status ? <button className="active-status" type="button">Active</button> : <button className="Inactive-status" type="button">Inactive</button>}
                </td>
                <td className='m-0 p-0 d-flex' style={{ border: 'none' }}>
                    <button className="reset-icon m-0 p-0" type="button" title='Reset Password' onClick={() => this.resetPassword(data.id,data.first_name,data.last_name)}><FiRefreshCw /></button>
                    <button className="edit-icon m-0 p-0" type="button" onClick={() => this.handleUpdateUserClick(data.id)}><FiEdit /></button>
                    {/* <button className="delete-icon m-0 p-0" type="button" onClick={() => this.deleteUser(data.id)}><FiTrash2 /></button> */}
                </td>
            </tr>
        ));

        return (
            <>
                <div className="user-dashboard-request mt-4" style={{marginLeft:"-14px"}}>
                    <div className='nav-tab w-100'></div>
                    <div className="dashboard-request-block ps-5 mt-3">
                        <div className="row align-items-center user-dashboard-request-list pe-1">
                            {currentItems.length > 0 ?
                                <div className="col-lg-12 col-md-12 col-12" style={{ padding: 0, margin: 0 }}>
                                    <div className="table-container">
                                        <table className="table">
                                            <thead >
                                                <tr >
                                                    <th style={{ border: 'none', textAlign: 'left' }}>First Name</th>
                                                    <th style={{ border: 'none', textAlign: 'left' }}>Last Name</th>
                                                    <th style={{ border: 'none', textAlign: 'left' }}>Email</th>
                                                    <th style={{ border: 'none', textAlign: 'left' }}>Status</th>
                                                </tr>
                                                <tr>
                                                    <td colSpan="5" style={{ border: 'none', marginTop: 0, paddingTop: 0 }}>
                                                        <div className='nav-tab w-100' style={{ margin: 0, padding: 0 }}></div>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody style={{ border: 'none' }}>
                                                {dashboard_data}
                                            </tbody>
                                        </table>
                                    </div>

                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={Math.ceil(usersListData.length / itemsPerPage)}
                                        totalItems={usersListData.length}
                                        itemsPerPage={itemsPerPage}
                                        onPageChange={this.onPageChange}
                                    />

                                </div>
                                : <div style={{ color: 'gray' }}>No records found</div>
                            }
                        </div>
                    </div>



                </div>
                <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCloseModal}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={() => this.handleDeleteUserConfirmed(this.state.deleteUserId)}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Second Modal */}
                <Modal show={this.state.showSecondModal} onHide={this.handleCloseSecondModal} style={{ fontSize: '14px' }}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ fontSize: '16px', fontWeight: 'bold' }}>Reset Password ({this.state.user_name})</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: '10px' }}>
                        <Form>
                        <Form.Group controlId="newPassword">
                            <Form.Label style={{ fontSize: '14px' }}>New Password</Form.Label>
                            <div className="position-relative">
                                <Form.Control
                                    type={this.state.showNewPassword ? 'text' : 'password'}
                                    name="new_password"
                                    id="new_password"
                                    style={{ fontSize: '14px' }}
                                />
                                <span
                                    style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                                    onClick={this.toggleNewPasswordVisibility}
                                >
                                    {this.state.showNewPassword ? <FaEyeSlash /> : <FaEye />}
                                </span>
                         </div>                       
                 </Form.Group>
                        &nbsp;&nbsp;
                        <Form.Group controlId="confimNewPassword">
                            <Form.Label style={{ fontSize: '14px' }}>Confirm New Password</Form.Label>
                            <div className="position-relative">
                            <Form.Control
                                type={this.state.showConfirmNewPassword ? 'text' : 'password'}
                                name="confirm_new_password"
                                id="confirm_new_password"
                                style={{ fontSize: '14px' }}
                            />
                            <span
                                style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                                onClick={this.toggleConfirmNewPasswordVisibility}
                            >
                                {this.state.showConfirmNewPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                            </div>
                        </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer style={{ borderTop: 'none' }}>
                        <Button variant="secondary" onClick={this.handleCloseSecondModal}>
                        Close
                        </Button>
                        <Button variant="primary" onClick={() => this.handlePasswordResetConfirmed(this.state.resetPasswordId)}>
                        Save
                        </Button>
                    </Modal.Footer>
                </Modal>


            </>
        );
    }
}
export default withRouter(UserListRequest);
