import React, { Component } from 'react';
import notify from '../../CommonComponents/Notify';
import $ from 'jquery'
import { API_HOST } from '../../../Settings.js'
import axios from 'axios';
import Signed from '../../ConsentComponents/Signed.js';


class SelectConcernedDepartmentHeads extends Component {
    constructor(props){
        super(props)
        this.state = {
            selectedDepartmentComponents:[],
            defaultSelectedDepartmentComponents:[],
            checkedDepartments:{},
            stage_status:this.props.stage_status,
            current_component_name: "SelectConcernedDepartmentHeads",
            userPermissions:{},
            WorkflowSignatures:[],
        }
    }

    static getDerivedStateFromProps(props, state) {
        if(props.checkedDepartments !== state.checkedDepartments){
            //Change in props
            return{
                selectedDepartmentComponents: props.ChangeSoughtComponent?props.ChangeSoughtComponent:state.selectedDepartmentComponents,
                checkedDepartments:props.checkedDepartments?props.checkedDepartments:state.checkedDepartments
            };
        }
        return null; // No change to state
    }
    
    componentDidMount(){
        this.getUserPrivileges()
        if(this.props.isCollapased===false){  
            axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
            axios.get(`${API_HOST}/workflow/moc/request/${this.props.DocumentNumber}/stage/${this.props.StageName}/`, )
            .then(response => {            
                if (response.status === 200) {
                    var checked_department = {}
                    var selectedDepartmentComponents = []
                    if(response.data.data.workflow_stage_info.hasOwnProperty('selected_concerned_departments')){
                        selectedDepartmentComponents = response.data.data.workflow_stage_info.selected_concerned_departments
                    }else{
                        selectedDepartmentComponents = response.data.data.workflow_stage_info.change_sought_selected_react_components
                    }
                    selectedDepartmentComponents.map((component_name,indx)=>{   
                        checked_department[component_name] = true             
                    })                                        
                    this.setState({
                        workflowData:response.data.data.workflow_stage_info,
                        checkedDepartments:checked_department,
                        selectedDepartmentComponents:selectedDepartmentComponents,
                        is_approved:response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                        response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status === "Approved" ? true : false,
                        WorkflowSignatures:response.data.data.workflow_stage_info.components[0].workflow_signatures,
                    })
                    // this.renderDynamicComponent()
                }

            }).catch(response => {
                // ErrorHandling(response)
            });
            $('.card-loader').fadeOut('slow')
            axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
            axios.get(`${API_HOST}/get/and/save/moc/stage4/qa/head/${this.props.DocumentNumber}/data/`,)
                .then(response => {
                    if (response.status === 200 ) {
                        if(response.data.is_submited){
                            this.setState({
                                "change_classification": response.data.change_classification,
                            })
                        }else{
                            this.setState({"isDataSubmited": false})
                        }
                    }
                }).catch(response => {
                    // ErrorHandling(response)
                });
            }
        
    }

    getUserPrivileges = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        let privileges = JSON.stringify(['can_create'])
        let component = this.state.current_component_name
        let params = {"document_number": this.props.DocumentNumber, "from_new": true, "request_type": "moc_request"}
        axios.get(`${API_HOST}/user/privileges/${component}/${privileges}/`, {params: params})
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    userPermissions:response.data.data,
                })

            }
        }).catch(response => {
            // ErrorHandling(response)
        });
    }

    onChangeHandler = (e) =>{
        // e.preventDefault()
        var checked_department = this.state.checkedDepartments
        var selected_dep = this.state.selectedDepartmentComponents
        if(e.currentTarget.checked){
            checked_department[e.currentTarget.name] = true
            selected_dep.push(e.currentTarget.name)
        }else{
            checked_department[e.currentTarget.name] = false
            var arr = selected_dep.filter(item => item !== e.currentTarget.name)
            selected_dep = arr
        }

        this.setState({
            checkedDepartments:checked_department,
            selectedDepartmentComponents:selected_dep
        })
    }

    onSkipHandler = (e) =>{
        var msg = "Are you sure, you want to skip this stage.?"
        if (window.confirm(msg)) {
            $('.loader').fadeIn()
            const auth_token = localStorage.getItem("token")
            axios.defaults.headers.post['Authorization'] = auth_token

            axios.post(`${API_HOST}/moc/request/${this.props.DocumentNumber}/concerned/department/heads/save/`, {departments:[], method: 'skip'})
                .then(response => {
                    if (response.status === 200) {
                        notify('Successfully skipped Management Approval stage.','success')
                        setTimeout(() => {
                            $('.loader').fadeOut('slow')
                            window.location.reload()
                        }, 4000)
                    } 
                }).catch(response => {
                    // ErrorHandling(response)
                });
        }
    }

    onSubmitHandler = (e) =>{
        e.preventDefault()
        var selected_crf_department = this.state.selectedDepartmentComponents
        var default_crf_department = this.state.defaultSelectedDepartmentComponents
        var all_crf_department = selected_crf_department.concat(default_crf_department)
        if(all_crf_department.length>0){
            var msg = "Are you sure, you want to submit selected approving authorities?"
            if (window.confirm(msg)) {
                $('.loader').fadeIn()
                const auth_token = localStorage.getItem("token")
                axios.defaults.headers.post['Authorization'] = auth_token
                axios.post(`${API_HOST}/moc/request/${this.props.DocumentNumber}/concerned/department/heads/save/`, {departments:all_crf_department})
                    .then(response => {
                        if (response.status === 200 && response.data.status==="success") {
                            notify('Successfully submited concerned department heads selection','success')
                            setTimeout(() => {
                                $('.loader').fadeOut('slow')
                                window.location.reload()
                            }, 4000)
                        }else{
                            notify(response.data.reason, response.data.status)
                            $('.loader').fadeOut('slow')
                        }
                    }).catch(response => {
                        // ErrorHandling(response)
                    });
            }
        }else{
            notify('Select atleast one department head to proceed.','warning')
        }
    }
    
    render() {
        return(
                <div className = "card mb-3" id="SelectConcernedDepartmentHeads">
                    <div className = "card-body" >
                        <h5 className = "card-title mb-3" > <b>Select Concerned Departments Head </b></h5>
                        <div className = 'row' >
                            <div className = 'col-12' >
                                <div className = 'row mb-3' >
                                    <div className = 'col-6' >                                    
                                        <div className = 'form-check' >
                                         <input className = "form-check-input" type="checkbox" 
                                            id="ApprovalBySynthesis" name='ApprovalBySynthesis' 
                                            checked={ this.state.checkedDepartments.ApprovalBySynthesis ? true:false}  
                                            value="ApprovalBySynthesis" onChange={e => this.onChangeHandler(e)}
                                            disabled={this.state.is_approved || !this.state.userPermissions.can_create ? true:false}
                                             />
                                            <label className="form-check-label"  htmlFor="ApprovalBySynthesis" >
                                                Head Synthesis Department (R&D)
                                            </label> 
                                        </div> 
                                    </div> 
                                    <div className='col-6' >
                                        <div className='form-check' >
                                            <input className = "form-check-input" type="checkbox" 
                                                id="ApprovalByFormulationDevelopment" name='ApprovalByFormulationDevelopment' 
                                                checked={this.state.checkedDepartments.ApprovalByFormulationDevelopment?true:false}  
                                                value="ApprovalByFormulationDevelopment" onChange={e => this.onChangeHandler(e)}
                                                disabled={this.state.is_approved || !this.state.userPermissions.can_create ? true:false}
                                                />
                                                <label className="form-check-label"  htmlFor="ApprovalByFormulationDevelopment" >
                                                    Head Formulation & Development Department (R&D)
                                                </label> 
                                        </div> 
                                    </div> 
                                </div> 
                                <div className='row mb-3' >
                                    <div className='col-6' >
                                        < div className='form-check' >
                                            <input className = "form-check-input" type="checkbox" 
                                                id="ApprovalByAnalyticalDevelopment" name='ApprovalByAnalyticalDevelopment' 
                                                checked={this.state.checkedDepartments.ApprovalByAnalyticalDevelopment?true:false}  
                                                value="ApprovalByAnalyticalDevelopment" onChange={e => this.onChangeHandler(e)}
                                                disabled={this.state.is_approved || !this.state.userPermissions.can_create ? true:false}
                                            />
                                            <label className="form-check-label"  htmlFor="ApprovalByAnalyticalDevelopment" >
                                                Head Analytical Development Department (R&D)
                                            </label> 
                                        </div> 
                                    </div> 
                                    <div className='col-6' >
                                        <div className='form-check' >
                                            <input className = "form-check-input" type="checkbox" 
                                                    id="headLegal" name='ApprovalByLegalHead' 
                                                    checked={this.state.checkedDepartments.ApprovalByLegalHead?true:false}  
                                                    value="ApprovalByLegalHead" onChange={e => this.onChangeHandler(e)}
                                                    disabled={this.state.is_approved || !this.state.userPermissions.can_create ? true:false}
                                            />
                                            <label className="form-check-label"  htmlFor="headLegal" >
                                                Head Legal Department
                                            </label>  
                                        </div> 
                                    </div> 
                                </div> 
                                <div className='row mb-3' >
                                    <div className='col-6' >
                                        <div className='form-check' >
                                            <input className = "form-check-input" type="checkbox" 
                                                id="ApprovalByTechnologyTransferServicesHead" name='ApprovalByTechnologyTransferServicesHead' 
                                                checked={this.state.checkedDepartments.ApprovalByTechnologyTransferServicesHead?true:false}  
                                                value="ApprovalByTechnologyTransferServicesHead" onChange={e => this.onChangeHandler(e)}
                                                disabled={this.state.is_approved || !this.state.userPermissions.can_create ? true:false}
                                            />
                                            <label className="form-check-label"  htmlFor="ApprovalByTechnologyTransferServicesHead" >
                                                Head Technology Transfer Services Department (TTS)
                                            </label> 
                                        </div> 
                                    </div>
                                <div className='col-6' >
                                    <div className='form-check' >
                                        <input className = "form-check-input" type="checkbox" 
                                                id="ApprovalByIBDHead" name='ApprovalByIBDHead' 
                                                checked={this.state.checkedDepartments.ApprovalByIBDHead?true:false}  
                                                value="ApprovalByIBDHead" onChange={e => this.onChangeHandler(e)}
                                                disabled={this.state.is_approved || !this.state.userPermissions.can_create ? true:false}
                                        />
                                        <label className="form-check-label"  htmlFor="ApprovalByIBDHead" >
                                            Head International Business Division Department (IBD)
                                        </label>
                                    </div>
                                </div> 
                            </div> 
                            <div className='row mb-3' >                                
                                <div className='col-6' >
                                    <div className='form-check' >
                                        <input className = "form-check-input" type="checkbox" 
                                                id="ApprovalByDFHead" name='ApprovalByDFHead' 
                                                checked={this.state.checkedDepartments.ApprovalByDFHead?true:false}  
                                                value="ApprovalByDFHead" onChange={e => this.onChangeHandler(e)}
                                                disabled={this.state.is_approved || !this.state.userPermissions.can_create ? true:false}
                                        />
                                        <label className="form-check-label"  htmlFor="ApprovalByDFHead" >
                                            Head Domestic Formulation Business Department (DF)
                                        </label> 
                                    </div> 
                                </div> 
                                <div className='col-6' >
                                    <div className='form-check' >
                                        <input className = "form-check-input" type="checkbox" 
                                                id="ApprovalByCQALHead" name='ApprovalByCQALHead' 
                                                checked={this.state.checkedDepartments.ApprovalByCQALHead?true:false}  
                                                value="ApprovalByCQALHead" onChange={e => this.onChangeHandler(e)}
                                                disabled={this.state.is_approved || !this.state.userPermissions.can_create ? true:false}
                                        />
                                        <label className="form-check-label"  htmlFor="ApprovalByCQALHead" >
                                            Head CQAL
                                        </label> 
                                    </div>
                                </div>
                            </div> 
                            <div className='row mb-3' >                                
                                <div className='col-6' >
                                    <div className='form-check' >
                                        <input className = "form-check-input" type="checkbox" 
                                                id="ApprovalBySustainabilityHead" name='ApprovalBySustainabilityHead' 
                                                checked={this.state.checkedDepartments.ApprovalBySustainabilityHead?true:false}  
                                                value="ApprovalBySustainabilityHead" onChange={e => this.onChangeHandler(e)}
                                                disabled={this.state.is_approved || !this.state.userPermissions.can_create ? true:false}
                                        />
                                        <label className="form-check-label"  htmlFor="ApprovalBySustainabilityHead" >
                                            Head Sustainability Department
                                        </label>  
                                    </div> 
                                </div> 
                                <div className='col-6' >
                                    <div className='form-check' >
                                    <input className = "form-check-input" type="checkbox"
                                            id="ApprovalByManufacturingHead" name='ApprovalByManufacturingHead' 
                                            checked={this.state.checkedDepartments.ApprovalByManufacturingHead?true:false}  
                                            value="ApprovalByManufacturingHead" onChange={e => this.onChangeHandler(e)}
                                            disabled={this.state.is_approved || !this.state.userPermissions.can_create ? true:false}
                                        />
                                        <label className="form-check-label"  htmlFor="ApprovalByManufacturingHead" >
                                            Head Manufacturing Department
                                        </label> 
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-3' >
                                <div className='col-6' >
                                    <div className='form-check' >
                                        <input className = "form-check-input" type="checkbox" 
                                            id="ApprovalByRegulatoryDomestic" name='ApprovalByRegulatoryDomestic' 
                                            checked={this.state.checkedDepartments.ApprovalByRegulatoryDomestic?true:false}  
                                            value="ApprovalByRegulatoryDomestic" onChange={e => this.onChangeHandler(e)}
                                            disabled={this.state.is_approved || !this.state.userPermissions.can_create ? true:false}
                                        />
                                        <label className="form-check-label"  htmlFor="ApprovalByRegulatoryDomestic" >
                                            Head Regulatory Department Domestic (R&D)
                                        </label>
                                    </div> 
                                </div>
                                <div className='col-6' >
                                    <div className='form-check' >
                                        <input className = "form-check-input" type="checkbox" 
                                            id="ApprovalByRegulatoryInternational" name='ApprovalByRegulatoryInternational' 
                                            checked={this.state.checkedDepartments.ApprovalByRegulatoryInternational?true:false}  
                                            value="ApprovalByRegulatoryInternational" onChange={e => this.onChangeHandler(e)}
                                            disabled={this.state.is_approved || !this.state.userPermissions.can_create ? true:false}
                                        />
                                        <label className="form-check-label"  htmlFor="ApprovalByRegulatoryInternational" >
                                            Head Regulatory Department International (R&D)
                                        </label>
                                    </div> 
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-6' >
                                    <div className='form-check' >
                                        <input className = "form-check-input" type="checkbox" 
                                                id="ApprovalBySiteQAHead" name='ApprovalBySiteQAHead' 
                                                checked={this.state.checkedDepartments.ApprovalBySiteQAHead?true:false}  
                                                value="ApprovalBySiteQAHead" onChange={e => this.onChangeHandler(e)}
                                                disabled={this.state.is_approved || !this.state.userPermissions.can_create? true:false}
                                        />
                                        <label className="form-check-label"  htmlFor="ApprovalBySiteQAHead" >
                                            Site QA Head
                                        </label>  
                                    </div> 
                                </div> 
                            </div>
                        </div> 
                        {/* <div className='col-5' >
                            <div className='row mb-3' >
                            
                            <div className='col-6' >
                                <div className='form-check' >
 
                                </div>
                            </div>
                        </div> 
                    </div> */}
                </div>
                {(['approved', 'rejected'].includes(this.state.stage_status) || this.state.is_approved) ? (
                        <div className='card-body'>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                {this.state.WorkflowSignatures.length > 0 ? <Signed SignatureData={this.state.WorkflowSignatures[0]} /> : ""}
                            </div>
                        </div>
                    ) : (
                        <div className='row'>
                            <div className='col-12 text-right'>
                                {this.state.userPermissions.can_create && this.state.change_classification === 'minor' ? (
                                    <button type="button" className="btn btn-primary skip-cft-data m-2" onClick={this.onSkipHandler}>
                                        Skip
                                    </button>
                                ) : null}
                                {this.state.userPermissions.can_create ? (
                                    <button type="button" className="btn btn-primary submit-cft-data" onClick={this.onSubmitHandler}>
                                        Submit
                                    </button>
                                ) : null}
                            </div>
                        </div>
                    )}

                    </div>
                </div>
        )
    }
}

export default SelectConcernedDepartmentHeads;