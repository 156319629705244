import { Component } from 'react'
import { withRouter} from 'react-router-dom'
// import Cookies from "universal-cookie";
// const cookies = new Cookies();

class OauthRedirect extends Component {
    /*constructor(props){
        super(props)
    }*/
    componentDidMount(){
        var redirect_data = new URLSearchParams(this.props.location.search)
        if(redirect_data.get('success') === 'login'){
            localStorage.setItem('token',redirect_data.get('token'))
            localStorage.setItem('user_data',redirect_data.get('user_data'))
            localStorage.setItem('roles', redirect_data.get('roles'))
            this.props.history.push('/')
        }else{
            this.props.history.push({
                pathname:'/login',
                state:{params:{
                    message:'You cannot perform this action, Please contact your admin.'
                }}
            })
        }
        
    }
    render() {
        var style_div = {'fontSize':'17px','fontStyle':'italic','padding':'13px'}
        return (
            <div id="loading" style={style_div}>
                    Loading.........
            </div>
        )
    }
}
export default withRouter(OauthRedirect)