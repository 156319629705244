import React, { Component, createRef } from "react";
// import { EnergyManagementActionPlanTabelRowName } from "../CommonComponents/MiscellaneousObject";
import ApproveButton from "../ConsentComponents/ApproveButton";
import AskButton from "../ConsentComponents/AskButton";
import Comment from "../ConsentComponents/Comment";
import Sign from "../ConsentComponents/Sign";
import Signed from "../ConsentComponents/Signed";
import $ from 'jquery'
import notify from "../CommonComponents/Notify";
import axios from 'axios';
import { API_HOST } from "../../Settings";




class SubTaskPlantMOCLeadReview extends Component {
    constructor(props){
        super(props)   
        this.commentRef = createRef(); 
        this.handleOfComment = this.handleOfComment.bind(this)
        this.state = {
            WorkflowAttachments: this.props.WorkflowComponent?this.props.WorkflowComponent.workflow_attachments:[],
            unique_combine: 'st2c1',
            is_approved:false,
            stage_status:this.props.stage_status,
            DocumentNumber:this.props.DocumentNumber,
            WorkflowSignatures:this.props.WorkflowComponent ? this.props.WorkflowComponent.workflow_signatures:[],
            userComponentPermissions:[],
            ask_for_more_history: [],
            userPermissions: {},
            current_component_name: "SubTaskPlantMOCLeadReview",
        }
    }
    checkIsApproved = (is_approved) =>{
        this.setState({
            is_approved:is_approved
        })

    }
    getAllData = ()=>{
		axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
		axios.get(`${API_HOST}/workflow/moc/subtask/request/${this.props.DocumentNumber}/stage/${this.props.StageName}/?component_name=${this.props.ComponentName}`, )
		.then(response => {
		    if (response.status === 200) {
                this.setState({
		            WorkflowData: response.data.data.workflow_stage_info,
		            WorkflowComponent :response.data.data.workflow_stage_info.components[0],
		            WorkflowSignatures :response.data.data.workflow_stage_info.components[0].workflow_signatures,
		            enable_signing :response.data.data.workflow_stage_info.components[0].enable_signing,
		            enable_approval :response.data.data.workflow_stage_info.components[0].enable_approval,
		            is_approved : response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
		                response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Approved" ? true : false,
		           	userComponentPermissions: response.data.data.workflow_stage_info.components[0].user_component_permissions,
                    ask_for_more_history:response.data.data.ask_for_more_history,
		        }, () => 
                console.log(this.state))
		    }

		}).catch(response => {
		    // ErrorHandling(response)
		});
    }
    componentDidMount(){
        this.getUserPrivileges()
        if(this.props.isCollapased==false && this.state.DocumentNumber){
            this.getAllData()
            $('.card-loader').fadeOut('slow')
        }
    }

    getUserPrivileges = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        let privileges = JSON.stringify(['can_comment', "can_sign", "can_ask_for_more_information", "can_approve"])
        let component = this.state.current_component_name
        let params = {"document_number": this.state.requestID, "from_new": true, "request_type": "moc_subtask"}
        axios.get(`${API_HOST}/user/privileges/${component}/${privileges}/`, {params: params})
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    userPermissions:response.data.data,
                })

            }
        }).catch(response => {
            // ErrorHandling(response)
        });
    }

    handleOfComment(e, stop_loader, method){
        var response_isvalid = this.commentRef.current.isValid()
        if(!response_isvalid.status){
            if(method == "approve"){
                if(response_isvalid.custom_error_code!=1){
                    // if custom_error_code==1  we can process approval without comment saving
                    notify(response_isvalid.reason,'warning')
                    return false
                }
            }else{
                if(response_isvalid.custom_error_code==1){
                    return false
                }
                if(response_isvalid.custom_error_code==2){
                    // if custom_error_code==1  we can process approval without comment saving
                    notify(response_isvalid.reason,'warning')
                    return false
                }
            }
        }else{
            this.commentRef.current.commentSubmitHandler(e, stop_loader)
            return true
        }
        return true
    }

    render(){
        return(
            <div className="row">
                <div className="col-12">
                    <div className='heading-Text font-16'>Plant MoC Lead Review</div>
                    <div className='col-lg-12 col-md-8 col-12 mt-4'>
                        <Comment is_approved={this.state.is_approved} WorkflowData={this.state.WorkflowData} WorkflowComponent={this.state.WorkflowComponent} DocumentNumber={this.state.DocumentNumber} userPermissions={this.state.userPermissions} ref={this.commentRef} RequestType="moc-sub-task"/>
                    </div>
                    {(this.props.isCollapased) ? '':
                    <div className='d-flex w-100 justify-content-end align-items-end mt-4'>
                        <div className='pe-2'>
                            <AskButton 
                                IsApproved={this.state.is_approved}
                                stage_status={this.state.stage_status}
                                key={this.state.unique_combine+'askbtn'}
                                WorkflowData={this.state.WorkflowData} 
                                WorkflowComponent={this.state.WorkflowComponent}  
                                DocumentNumber={this.state.DocumentNumber}
                                current_component_name={this.state.current_component_name}
                                ask_for_more_history={this.state.ask_for_more_history}
                                RequestType="moc-sub-task"
                                userComponentPermissions={this.state.userComponentPermissions}
                                userPermissions={this.state.userPermissions}
                                handleOfComment = {this.handleOfComment}
                                />
                        </div>
                        <div>
                            <ApproveButton
                                stage_status={this.state.stage_status}
                                IsApproved={this.state.is_approved}
                                WorkflowData={this.state.WorkflowData} 
                                WorkflowComponent={this.state.WorkflowComponent}  
                                DocumentNumber={this.state.DocumentNumber}
                                key={this.state.unique_combine+'aprbtn'}
                                unique_combine = {this.state.unique_combine}
                                current_component_name={this.state.current_component_name}
                                RequestType="moc-sub-task"
                                userComponentPermissions={this.state.userComponentPermissions}
                                userPermissions={this.state.userPermissions}
                                ask_for_more_history={this.state.ask_for_more_history}
                                handleOfComment={this.handleOfComment}/>
                        </div>
                        {this.state.WorkflowSignatures.length > 0 ?
                           <Signed SignatureData={this.state.WorkflowSignatures[0]}/> : ""
                        }
                    </div>}
                    <div className='mt-2'>
                        <Sign IsSignatures={this.state.WorkflowSignatures.length > 0?true:false} 
                        IsApproved={this.state.is_approved}
                        WorkflowData={this.state.WorkflowData} WorkflowComponent={this.state.WorkflowComponent}  
                        DocumentNumber={this.state.DocumentNumber} RequestType="moc-sub-task" 
                        userComponentPermissions={this.state.userComponentPermissions}
                        userPermissions={this.state.userPermissions}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default SubTaskPlantMOCLeadReview;