import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import NotificationBlock from '../Notification/NotificationBlock';
import ProfileDetails from '../User/ProfileDetails';
import NotificationIcon from '../../images/notification icon.svg'
import { FaRegBell } from "react-icons/fa";
import NotificationIcon2 from '../../images/notification-icon.png'
import ProfileIcon from '../../images/profileIcon.svg'
import DownArrow from '../../images/downArrow.svg'
import UpArrow from '../../images/upArrow.svg'
import CheckToken from '../CommonComponents/CheckToken';
import Logo from '../../images/RallisLogo-white.png';
import RallisLogo from '../../images/rallis-india-limited-logo.png';
import ComanyName from '../../images/rallis-india-limited-name.png';
import SearchBar from '../Dashboard/SearchBar';
import axios from 'axios';
import { API_HOST } from '../../Settings';
import $ from 'jquery';


class TopNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showNotification: false,
            showProfileDetails: false,
            showAllNotification: this.props.showAllNotification,
            user_data: JSON.parse(localStorage.getItem('user_data')),
            notifications_count: 0,
            notifications: [],
            is_extra_features_required_on_topnav: false,
            can_view_moc: false,
            can_view_manpower: false,
            can_view_ibd: false,
            selected_workflow: "",
            activeTab: '',
        }
        this.wrapperRef = React.createRef();
    }
    componentDidMount() {
        console.log(window.location.pathname, 'Didmount url')
        console.log('top_nav_file_excuted')
        const userLoginType = localStorage.getItem("user_login_type");
        const isDashboardPage = window.location.pathname.includes("/dashboard") || window.location.pathname.includes("/invoices");
        if (isDashboardPage) {
            this.setState({
                activeTab: "REQUESTS",
            });
        }

        window.addEventListener('popstate', () => {
            this.setActiveTabBasedOnURL();
        });

        if (userLoginType === 'rallis') {
            this.getWorkflowRolesData();
            const urlParams = new URLSearchParams(window.location.search);
            const workflow = urlParams.get('workflow');

            if (workflow != null && workflow != "") {
                localStorage.setItem('workflow', workflow)
                this.setState({
                    selected_workflow: workflow,
                });
            } else {
                this.setState({
                    selected_workflow: localStorage.getItem('workflow'),
                });
            }
            if (localStorage.getItem('workflow_count') === '1') {
                this.setState({
                    is_extra_features_required_on_topnav: true
                });
            } else {
                this.setState({
                    is_extra_features_required_on_topnav: window.location.pathname !== "/"
                });
            }
            if (localStorage.getItem('workflow')) {
                this.getUserNotifications();
            }

        } else {
            this.setState({
                is_extra_features_required_on_topnav: false
            });
        }
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    
 
    handleClickOutside = (event) => {
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ showNotification: false });
        }
    };
    
    componentDidUpdate(prevProps) {
        console.log(window.location.pathname, 'DidUpdate url')
        if (this.props.location !== prevProps.location) {
            this.setActiveTabBasedOnURL();
        }
    }

    setActiveTabBasedOnURL() {
        const isDashboardPage = window.location.pathname.includes("/dashboard") || window.location.pathname.includes("/invoices");

        if (isDashboardPage) {
            this.setState({
                activeTab: "REQUESTS",
            });
        } else {
            this.setState({
                activeTab: "",
            });
        }
    }

    getWorkflowRolesData = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/workflow/roles`,)
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        can_view_moc: response.data.data.can_view_moc,
                        can_view_manpower: response.data.data.can_view_manpower,
                        can_view_ibd: response.data.data.can_view_ibd,
                    })
                    $('.loader').fadeOut('slow')
                }
            }).catch(error_response => {

            })
    }


    getUserNotifications = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        var workflow = localStorage.getItem('workflow');
        axios.get(`${API_HOST}/get/notifications?is_unread=yes&workflow=${workflow}`)
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    notifications_count: response.data.notifications.length,
                    notifications: response.data.notifications,
                }, () =>
                    console.log(this.state))
                localStorage.setItem('notifications_count',response.data.notifications_count)

            }
        }).catch(response => {
            // ErrorHandling(response)
        });
    }

    handleWorkflowChange = (e) => {
        const selectedWorkflow = e.target.value;
        if(localStorage.getItem('workflow') !== selectedWorkflow){
            document.querySelectorAll('input[type=checkbox]:checked').forEach(checkbox => {
            checkbox.checked = false;
            })
        }
        localStorage.setItem('workflow', selectedWorkflow)
        this.setState({
            selected_workflow: selectedWorkflow,
        }, () => {
            if (selectedWorkflow == "ibd") {
                this.props.history.push(`/invoices?workflow=${selectedWorkflow}`);
            } else {
                this.props.history.push(`/dashboard?workflow=${selectedWorkflow}`);
            }

        });
        this.getUserNotifications()
    };

    handleNavTabWorkflow = (workflow, SelectedNavTab) => {
        if (SelectedNavTab != 'REQUESTS') {
            this.setState({ activeTab: '' });
        } else {
            this.setState({ activeTab: 'REQUESTS' });
        }

        this.setState({
            selected_workflow: workflow,
        });

        // Check if the current URL contains "/dashboard" and SelectedNavTab is "REQUESTS"
        const isDashboardPage = window.location.pathname.includes("/dashboard");
        if (workflow === "ibd" && !isDashboardPage) {
            this.props.history.push(`/invoices?workflow=${workflow}`);
        } else if (!isDashboardPage) {
            this.props.history.push(`/dashboard?workflow=${workflow}`);
        }
    };


    handelNotification = () => {
        this.getUserNotifications()
        this.setState({
            showNotification: !this.state.showNotification,
            showProfileDetails: false,
        })
    }
    handelProfileDetails = () => {
        this.setState({
            showProfileDetails: !this.state.showProfileDetails,
            showNotification: false,
        })
    }

    handelResponsiveProfileDetails = () => {
        const x = window.matchMedia("(max-width: 500px)")
        if (x.matches == true) {
            this.handelProfileDetails();
        }
    }


    render() {
        const { showNotification, showProfileDetails } = this.state;
        debugger
        return (
            <div className='mainPage'>
                <div className="loader">
                    <div className="h-100 d-flex align-items-center justify-content-center">
                        <div className="spin-loader"></div>
                    </div>
                </div>
                <CheckToken />
                <div className='row background-black text-white TopNavBar fixed-top d-flex align-items-center'>
                    <div className='d-flex col-lg-2 col-md-12 col-12 justify-content-center navLogo'>
                        <NavLink exact className="navTab" to="/">
                            <div className='d-flex align-items-center'>
                                <img className='cursorPointer rallis-logo' src={RallisLogo} alt="rallis-logo" />
                                <img className='cursorPointer rallis-company-name' src={ComanyName} alt="rallis-company-name" />
                            </div>
                        </NavLink>
                    </div>
                    <div className='d-flex col-lg-4 col-md-7 col-8 justify-content-center navTabs'>
                        {this.state.is_extra_features_required_on_topnav ? (
                            <span
                                className={`navTab ${this.state.activeTab === 'REQUESTS' ? 'navActiveTab' : ''}`}
                                onClick={() => {
                                    this.handleNavTabWorkflow(this.state.selected_workflow, 'REQUESTS');
                                    this.setState({ activeTab: 'REQUESTS' });
                                }}
                            >
                                REQUESTS
                            </span>

                        ) : null}

                        {this.state.is_extra_features_required_on_topnav ? this.state.can_view_moc || this.state.can_view_manpower ?<NavLink exact activeClassName='navActiveTab' className="navTab" to={`/reports?workflow=${encodeURIComponent(this.state.selected_workflow)}`} onClick={() => this.handleNavTabWorkflow(this.state.selected_workflow, 'REPORTS')}>REPORTS</NavLink> :null: null}
                        {this.state.is_extra_features_required_on_topnav ? this.state.user_data && this.state.user_data.hasOwnProperty('is_admin') && this.state.user_data.is_admin ?
                            <NavLink exact activeClassName='navActiveTab' className="navTab" to={`/settings?workflow=${encodeURIComponent(this.state.selected_workflow)}`} onClick={() => this.handleNavTabWorkflow(this.state.selected_workflow, 'SETTINGS')}>SETTINGS</NavLink>
                            : null
                            : null}

                        {this.state.is_extra_features_required_on_topnav && this.state.user_data && this.state.user_data.hasOwnProperty('is_admin') && this.state.user_data.is_admin ? <NavLink exact activeClassName='navActiveTab' className="navTab" to={`/vendors?workflow=${encodeURIComponent(this.state.selected_workflow)}`} onClick={() => this.handleNavTabWorkflow(this.state.selected_workflow, 'VENDORS')}>VENDORS</NavLink> : null}

                    </div>
                    {/*<SearchBar />*/}
                    <div className='d-flex col-lg-6 col-md-5 col-4 justify-content-end align-items-center navUserOption'>
                        {this.state.is_extra_features_required_on_topnav ? (
                            this.state.selected_workflow !== null ? (
                                <select
                                    className='font-12 form-select pl-1 pt-1 custom-select'
                                    name='workflow'
                                    id='workflow'
                                    value={this.state.selected_workflow}
                                    onChange={(e) => this.handleWorkflowChange(e)}
                                >
                                    {this.state.can_view_moc ? <option value='moc'>MoC</option> : null}
                                    {this.state.can_view_manpower ? <option value='manpower'>Manpower</option> : null}
                                    {this.state.can_view_ibd ? <option value='ibd'>IBD Vendor Management</option> : null}
                                </select>
                            ) : (
                                null
                            )
                        ) : null}


                        <div className='pe-2 px-2 position-relative'>
                            {/* <img className="cursorPointer" src={this.state.notifications_count>0?NotificationIcon:NotificationIcon} onClick={this.handelNotification}/> */}
                            {this.state.is_extra_features_required_on_topnav && (localStorage.getItem('workflow') === 'moc' || localStorage.getItem('workflow') === 'manpower') ? <div><FaRegBell className="cursorPointer bell-icon" onClick={this.handelNotification} />  {localStorage.getItem('notifications_count') > 0 && <span className="notification-count">{localStorage.getItem('notifications_count')}</span>}</div> : null} {this.state.is_extra_features_required_on_topnav ? showNotification ?  <div ref={this.wrapperRef}>
                                <NotificationBlock notifications={this.state.notifications} />
                            </div> : null : null}
                        </div>

                        <div className='d-flex border-start align-items-center'>
                            <div className='pe-2 px-2 position-relative'>
                                <img className='' src={ProfileIcon} onClick={this.handelResponsiveProfileDetails} />
                            </div>
                            <div className='pe-2 px-2 top-nav-user-name'>{this.state.user_data.name}</div>
                            <div className='pe-2 px-2 position-relative top-nav-arrow'>
                                {!showProfileDetails ? <img className='cursorPointer' src={DownArrow} onClick={this.handelProfileDetails} /> :
                                    <img className='cursorPointer' src={UpArrow} onClick={this.handelProfileDetails} />}
                            </div>
                            {showProfileDetails ? <ProfileDetails /> : null}
                        </div>

                    </div>
                </div>
                {this.props.children}

            </div>
        )
    }
}

export default withRouter(TopNav);