import React, {Component} from "react";
import { withRouter } from 'react-router-dom';
import $ from 'jquery'
import { API_HOST } from '../../Settings.js'
import axios from 'axios';
import notify from "../CommonComponents/Notify.js";

class PlantMocLead extends Component{

    constructor(props) {
        super(props)
        this.state =
        {
            WorkflowData:{},
            WorkflowComponent:{},
            WorkflowSignatures:[],
            enable_signing:false,
            enable_approval:false,
            title: "",
            documentID: this.props.match.params.documentID,
            requestID: this.props.match.params.requestID,
            date_of_moc: '',
            proposal_initiated_by: '',
            assignee: "",
            deadline: '',
            description: '',
            unique_combine: 'st1c1',
            ask_for_more_history: [],
            WorkflowAttachments:[],
            UsersList:[],
            userPermissions: {},
        }
    }

    componentDidMount() {
        this.getUserPrivileges()
        /*$('.datepicker').datepicker({
            autoclose: true,
            format: "dd/mm/yyyy",
            immediateUpdates: true,
            todayBtn: true,
            todayHighlight: true
        })*/
        var userData = JSON.parse(localStorage.getItem('user_data'))
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/all/users/list/`,)
            .then(response => {
                if (response.status === 200 && response.data.success) {
                    var users_data = JSON.parse(response.data.users_data)
                    this.setState({
                        proposal_initiated_by:userData.name,
                        UsersList:users_data
                    })
                }
            }).catch(response => {
                // ErrorHandling(response)
            });        
        this.getAllData()

    }

    getUserPrivileges = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        let privileges = JSON.stringify(['can_update'])
        let component = "PlantMOCLead"
        let params = {"document_number": this.state.requestID, "from_new": true, "request_type": "moc_subtask"}
        axios.get(`${API_HOST}/user/privileges/${component}/${privileges}/`, {params: params})
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    userPermissions:response.data.data,
                })

            }
        }).catch(response => {
            // ErrorHandling(response)
        });
    }

    getAllData = (e)=>{
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        if(this.state.requestID){
            $('.card-loader').fadeIn('slow')
            axios.get(`${API_HOST}/get/moc/subtask/request/${this.state.documentID}/data/${this.state.requestID}`,)
            .then(response => {
                if (response.status === 200 && response.data.success) {
                    var moc_request_data = response.data.moc_request_data
                    moc_request_data['requestID'] = this.state.requestID
                    // $('#dateMoC').datepicker('update', moc_request_data.date_of_moc)
                    // $('#deadline').datepicker('update', moc_request_data.deadline)
                    $('#dateMoC').val(moc_request_data.date_of_moc)
                    $('#deadline').val(moc_request_data.deadline)
                    $("#description").text(moc_request_data.description)
                    $("#title").val(moc_request_data.title)
                    $("#proposal_initiated_by").val(moc_request_data.proposal_initiated_by)
                    this.setState(moc_request_data)

                    axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
                    axios.get(`${API_HOST}/workflow/moc/subtask/request/${this.props.DocumentNumber}/stage/${this.props.StageName}/?component_name=${this.props.ComponentName}`,)
                        .then(response2 => {
                            if(response2.data.data.hasOwnProperty('ask_for_more_history')){
                                // console.log("3", this.state)
                                this.setState({
                                    WorkflowData:response2.data.data.workflow_stage_info,
                                    WorkflowComponent:response2.data.data.workflow_stage_info.components[0],
                                    WorkflowSignatures:response2.data.data.workflow_stage_info.components[0].workflow_signatures,
                                    enable_signing:response2.data.data.workflow_stage_info.components[0].enable_signing,
                                    enable_approval:response2.data.data.workflow_stage_info.components[0].enable_approval,
                                    ask_for_more_history: response2.data.data.ask_for_more_history,
                                    WorkflowAttachments: response2.data.data.workflow_stage_info.components[0].workflow_attachments,
                                }, () => 
                                console.log(this.state))
                                var ask_for_more_history_filtered = response2.data.data.ask_for_more_history.filter(
                                    (each, index)=>{
                                        return each.status.name_slug=="open"
                                })
                                this.props.updateSideMenu(ask_for_more_history_filtered.length>0)
                            }
                        }).catch(error_response=>{})
                    
                        $('.card-loader').fadeOut('slow')

                }
            }).catch(response => {
                // ErrorHandling(response)
            });
        }
    }

    handleLinkClick(e, id) {
        this.setState({ active_link_id: id })
    }
    handleChange(e){
        if(e.currentTarget.value == ""){e.currentTarget.classList.add("error")}
        if(e.currentTarget.value && e.currentTarget.classList.contains("error")){e.currentTarget.classList.remove("error")}
    }

    getCommonMinDate = (event)=>{
        var today_str = new Date().toISOString().split("T")[0]
        var min_date = this.state.date_of_moc
        var common_min_date = today_str
        if(min_date){
            var min_date_obj = new Date(min_date)
            if(min_date_obj!='Invalid Date'){
                common_min_date = min_date_obj.toISOString().split('T')[0]
            }
        }
        return common_min_date
    }

    isValid=()=>{
        var name_regexp = new RegExp("<(?:\"[^\"]*\"['\"]*|'[^']*'['\"]*|[^'\">])+>");
        if(!document.querySelector("#title").value || !document.querySelector("#assignee").value || !document.querySelector("#deadline").value || !document.querySelector("#description").value){
            var scrollDiv = ""
            if(!document.querySelector("#title").value){
                document.querySelector('#title').classList.add("error")
                scrollDiv = "title"
            }
            if(!document.querySelector("#assignee").value){
                document.querySelector('#assignee').classList.add("error")
                scrollDiv = "assignee"
            }
            if(!document.querySelector("#deadline").value){
                document.querySelector('#deadline').classList.add("error")
                scrollDiv = "deadline"
            }
            if(!document.querySelector("#description").value){
                document.querySelector('#description').classList.add("error")
                scrollDiv = "description"
            }
            return {"status":false, "reason": "Please fill required field", "id": scrollDiv}
        }
        if((document.querySelector("#title").value&&name_regexp.test(document.querySelector("#title").value))){
            document.querySelector('#title').classList.add("error")
            var scrollDiv = "title"
            return {"status":false, "reason": "Please provide valid information in input fields.", "id": scrollDiv}
        }
        if((document.querySelector("#description").value&&name_regexp.test(document.querySelector("#description").value))){
            document.querySelector('#description').classList.add("error")
            var scrollDiv = "description"
            return {"status":false, "reason": "Please provide valid information in input fields.", "id": scrollDiv}
        }
        var deadline = document.getElementById('deadline').value
        var date_obj = new Date(deadline)
        if(deadline && date_obj!="Invalid Date"){
            var common_min_date_obj = new Date(this.getCommonMinDate())
            common_min_date_obj.setHours(0,0,0,0)
            if (date_obj<common_min_date_obj){
                document.querySelector('#deadline').classList.add("error")
                scrollDiv = "deadline"
                return {"status":false, "reason": "Invalid date provided", "id": scrollDiv}
            }
        }
        return {"status":true, "reason":""}
    }
    submitMoCSubTaskRequest = (e,status) =>{
        e.preventDefault()
        var response_isvalid = this.isValid()
        if(!response_isvalid.status){
            notify(response_isvalid.reason,'warning')
            setTimeout(function () {
                document.querySelector('#'+response_isvalid.id).scrollIntoView({
                    behavior: 'smooth', block: "center", inline: "nearest" 
                })
            }, 100)
            return false
        }
        if(status == 'open'){
            var msg = "Are you sure, you want submit moc sub task request?"
        }else{
            var msg = "Are you sure, you want to save as draft?"
        }
        if(window.confirm(msg)){
            var moc_data = this.state
            moc_data["title"] = document.getElementById("title").value
            moc_data["description"] = document.getElementById("description").value
            moc_data['deadline'] = document.getElementById('deadline').value
            moc_data['assignee'] = document.querySelector("#assignee").value
            $('.loader').fadeIn()
            var url = `${API_HOST}/moc/subtask/${this.state.requestID}/data/update/`
            const auth_token = localStorage.getItem("token")
            axios.defaults.headers.post['Authorization'] =auth_token      
            axios.post(url, moc_data)
            .then(response => {
                if (response.status === 200 && response.data[0].status == "success") {                    
                    setTimeout(() =>{
                        // $('#approve-as-variant-model').modal('hide');
                        this.props.history.push()
                    },1000)
                    $('.loader').fadeOut('slow')
                    notify('MoC Sub Task updated successfully','success')
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                }else{
                    notify(response.data.message, 'warning')
                    $('.loader').fadeOut('slow')
                }
            }).catch(response => {
                // ErrorHandling(response)
            });
        }

    }
    handleCancel = ()=>{
        window.location.href='/'
    }
    componentWillReceiveProps = (props) =>{
        if(this.props.accessType && this.props.accessType=="create"){
            document.querySelector("#proposal_initiated_by").value = this.props.thisData.proposal_initiated_by
        }
    }
    render(){
        var today_str = new Date().toISOString().split("T")[0]
        var common_min_date = this.getCommonMinDate()

        var access_type = "view"
        if(this.props.thisData){
            access_type = this.props.accessType
        }
        var ask_for_more_history_filtered = []
        if(this.state){
            if(this.state.hasOwnProperty('ask_for_more_history')){
                ask_for_more_history_filtered = this.state.ask_for_more_history.filter((each, index)=>{
                    return each.status.name_slug=="open"
                })
            }
        }
        if(ask_for_more_history_filtered.length>0){
            access_type  ="update"
        }
        let user_data = this.state.UsersList.map((userData,indx)=>{
            if(access_type == "update" || this.state.assignee ){
                return(
                    <option value={userData.email} selected={this.state.assignee == userData.email || this.state.assignee == userData.name?true:false}>{userData.name}</option>
                )
            }else{
                return(
                    <option value={userData.email} >{userData.name}</option>
                )
            }
            
        })

        var document_number = this.state.documentID
        return(
            [
                <div>
                    {false && ask_for_more_history_filtered.length>0?
                        <div className="alert alert-info">
                          More information requested
                        </div>:null
                    }
                </div>,
                <div className="card">
                    <div className="card-body">
                        <h5 className='heading-Text font-16'> <b>MoC Sub Task</b></h5>
                        <div className='row align-items-start form-group mt-3 mb-3'>
                            <div className='col-lg-3 col-md-6 col-12'>
                                <label className="form-label" htmlFor="title">Name *</label>
                                <input className='form-control' name="title" id="title" type="text" placeholder="Please enter title" onChange={(e)=>this.handleChange(e)} required disabled={this.state.userPermissions.can_update && ["create", "update"].includes(access_type) ? false:true}/>
                            </div>                             
                            <div className='col-lg-3 col-md-6 col-12'>
                                <label className="form-label" htmlFor="moc_doc_number">MoC Document #</label>
                                <input className='form-control' name="moc_doc_number" id="moc_doc_number" value={document_number} disabled required/>
                            </div>
                        </div>
                        <div className='row align-items-start form-group mb-3'>
                            <div className='col-lg-3 col-md-6 col-12'>
                                <label className="form-label" htmlFor="proposal_initiated_by">Praposal Initiated By</label>
                                <input className='form-control' name="proposal_initiated_by" id="proposal_initiated_by" type="text" disabled/>
                            </div>
                            <div className='col-lg-3 col-md-6 col-12'>
                                <label className="form-label" htmlFor="assignee">Assignee *</label>
                                <select className="form-select"  id="assignee" name="assignee" onChange={(e)=>this.handleChange(e)} required disabled={this.state.userPermissions.can_update && ["create", "update"].includes(access_type) ? false:true}>
                                    <option disabled={true}>Select Assignee</option>
                                    {user_data}
                                </select>
                            </div>
                        </div>

                        <div className='row align-items-start form-group mb-3'>
                            <div className='col-lg-3 col-md-6 col-12'>
                                <label className="form-label" htmlFor="dateMoC">Date of MoC Sub Task</label>
                                <input className='form-control' autoComplete='off' min={common_min_date} type="date" name="date_of_moc" id="dateMoC" disabled required/>
                            </div>
                            <div className='col-lg-3 col-md-6 col-12'>
                                <label className="form-label" htmlFor="deadline">Deadline *</label>
                                {this.state.userPermissions.can_update && ["create", "update"].includes(access_type)?
                                    <input autoComplete='off' className='form-control' min={common_min_date} type="date" required placeholder="Please select deadline date" name="deadline" onChange={(e)=>this.handleChange(e)} id="deadline" />
                                    :
                                    <input autoComplete='off' disabled={true} className='form-control' min={common_min_date} type="date" name="deadline" id="deadline" value={this.state.deadline} />
                                }
                            </div>
                        </div>    
                        <div className="row align-items-start form-group mb-3">
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label className="form-label" htmlFor="description">Description *</label>
                                <textarea className='form-control' name="description" id="description" type="text" onChange={(e)=>this.handleChange(e)} required disabled={this.state.userPermissions.can_update && ["create", "update"].includes(access_type) ? false:true}></textarea>
                            </div>
                        </div>                       
                    </div>                        
                </div>,
                <div key={this.state.unique_combine+"k"}>
                    {ask_for_more_history_filtered.length>0 && this.state.userPermissions.can_update?
                        <div className='card-body'>
                            <div className="d-flex gap-2 d-md-flex justify-content-md-end justify-content-end">
                            <button className="btn btn-outline-secondary" type="button" onClick={this.handleCancel} id={this.state.unique_combine+"cancel"}>Cancel</button>
                                <button className="btn btn-dark" type="submit" id="submit"
                                    onClick={(e) => this.submitMoCSubTaskRequest(e)}>
                                    Update
                                </button>
                            </div>
                        </div>:null
                    }
                </div>
            ]
        )
    }
}

export default withRouter(PlantMocLead)