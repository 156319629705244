import React from 'react';
import { Component } from 'react';
import '../../Styles/toggle.css'

class ThreeToggle extends Component {
    constructor(props) {
        super(props);
        this.state={
            firstToggleVal: this.props.firstVal,
            secondToggleVal: this.props.secondVal,
            thirdToggleVal: this.props.thirdVal,
            ToggleActive: this.props.Status ? this.props.Status: "N/A",
            DataSubmited:this.props.DataSubmited
        }
    }
    static getDerivedStateFromProps(props, state) {
        if(props.Status){
            if(props.Status !== state.ToggleActive){
                //Change in props
                return{
                    ToggleActive: props.Status?props.Status:state.ToggleActive
                };
            }
        }
        return null; // No change to state
    }
   // handelFirstToggle = () => {
   //  this.setState({
   //      firstToggleActive:true,
   //      secondToggleActive:false,
   //      thirdToggleActive:false,
   //  })
   // }
   // handelsecondToggle = () => {
   //  this.setState({
   //      firstToggleActive:false,
   //      secondToggleActive:true,
   //      thirdToggleActive:false,
   //  })
   // }
   // handelthirdToggle = () => {
   //  this.setState({
   //      firstToggleActive:false,
   //      secondToggleActive:false,
   //      thirdToggleActive:true,
   //  })
   // }

    handelToggle = (e, name, main_index=null, row_index=null) => {
        this.setState({
            ToggleActive:name
        })

        if(this.props.RequestType == "ChangeAuthority"){
            this.props.handleAuthorityChecks(e, name,main_index, row_index)
        }else if(this.props.RequestType == "ChangeInitiator"){
            this.props.handleInitiatorChecks(e, name, row_index)
        }else if(this.props.RequestType == "changeReview"){
            this.props.HandleReviewChecks(e, name, this.props.ToggleName)
        }

    }

    render() {
        return(
                <div className='w-100 d-flex align-items-center three-toggle-btns'>                   
                    <div className='d-flex threes-toggle-block position-relative' >
                        <div className='active-toggle'></div>
                        {this.props.DataSubmited && ["view"].includes(this.props.access_type) ? <div className={this.state.ToggleActive == 'Yes' ?'toggle-btn toggle-btn-active':"toggle-btn cursorPointer"} readOnly>
                            {this.state.firstToggleVal}
                        </div> :
                        <div className={this.state.ToggleActive == 'Yes' ?'toggle-btn toggle-btn-active cursorPointer':"toggle-btn cursorPointer"} onClick={ (e) => this.handelToggle(e, 'Yes', this.props.MainIndex, this.props.RowIndex)}>
                            {this.state.firstToggleVal}
                        </div> }
                        {this.props.DataSubmited && ["view"].includes(this.props.access_type) ?
                        <div className={this.state.ToggleActive == 'No' ?'toggle-btn toggle-btn-active':"toggle-btn cursorPointer"}  readOnly>
                            {this.state.secondToggleVal}
                        </div> : 
                         <div className={this.state.ToggleActive == 'No' ?'toggle-btn toggle-btn-active cursorPointer':"toggle-btn cursorPointer"}  onClick={(e) => this.handelToggle(e, 'No', this.props.MainIndex, this.props.RowIndex)}>
                         {this.state.secondToggleVal}
                     </div> }
                     {this.props.DataSubmited && ["view"].includes(this.props.access_type) ?
                        <div className={this.state.ToggleActive == 'N/A' ?'toggle-btn toggle-btn-active':"toggle-btn cursorPointer"} readOnly>
                           {this.state.thirdToggleVal}
                        </div> :
                        <div className={this.state.ToggleActive == 'N/A' ?'toggle-btn toggle-btn-active cursorPointer':"toggle-btn cursorPointer"} onClick={(e) => this.handelToggle(e, 'N/A', this.props.MainIndex, this.props.RowIndex)}>
                        {this.state.thirdToggleVal}
                     </div>}
                    </div>
                </div>
            )
    }

}
export default ThreeToggle;
