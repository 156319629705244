import { Component } from "react";


class InvoiceListLeftStatus extends Component {
    constructor(props) {
        super(props)
        this.state = {
            status: [
                { status: "Processed" },
                { status: "Pending" },
                { status: "Rejected" },
            ],
            vendor_filter_type: [
                { vendor_filter: "Transporter" },
                { vendor_filter: "CHA" },
                { vendor_filter: "Forwarder" },
                { vendor_filter: "Shipping Line(Direct)" },
                { vendor_filter: "Inspection Agency" },
                { vendor_filter: "Others" },
            ],
        };
    }
    render() {
        var changeStatus = this.state.status.map((status, key) => {
            var label_name;
            if (status.status === "Processed") {
                label_name = "Processed"
            } else if (status.status === "Pending") {
                label_name = "Pending"
            } else if (status.status === "Rejected") {
                label_name = "Rejected"
            }
            return (
                <div key={key} className="d-flex align-items-center dashboard-left-status filter-input-align">
                    <input className="check-box"
                        name="status"
                        type="checkbox"
                        id={status.status}
                        onClick={e => this.props.clickTabHandler(e)}
                        value={status.status} />
                    <label className="ps-1 secoundary-text" htmlFor={label_name} >
                        {label_name}
                    </label>
                </div>
            )

        })

        var changeVendorType = this.state.vendor_filter_type.map((vendorType, key) => {
            return (
                <div key={key} className="d-flex align-items-center dashboard-left-status filter-input-align">
                    <input
                        className="check-box"
                        name="vendorType"
                        type="checkbox"
                        id={vendorType.vendor_filter}
                        onClick={(e) => this.props.clickTabHandler(e)}
                        value={vendorType.vendor_filter}
                    />
                    <label className="ps-1 secoundary-text" htmlFor={vendorType.vendor_filter}>
                        {vendorType.vendor_filter}
                    </label>
                </div>
            );
        });

        return (
            <div className="pt-3 left-filter-checkbox">
                <div className="ms-3 filter-input-checkbox">
                    <div className="ps-3 status-border-bottom">Filter</div>
                    {changeStatus}
                    {changeVendorType}
                </div>
            </div>
        )
    }
}

export default InvoiceListLeftStatus