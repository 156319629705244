import React, { Component, createRef } from "react";
import ApproveButton from "../../ConsentComponents/ApproveButton";
import AskButton from "../../ConsentComponents/AskButton";
import Comment from "../../ConsentComponents/Comment";
import RejectButton from "../../ConsentComponents/RejectButton";
import Sign from "../../ConsentComponents/Sign";
import CategoryofRiskLevelBySiteEHSHeadTabelRow from "./CategoryofRiskLevelBySiteEHSHeadTabelRow";
import { getCategoryofRiskLevelBySiteEHSHeadTabelRowName } from '../../CommonComponents/MiscellaneousObject';
import $ from 'jquery'
import axios from 'axios';
import notify from "../../CommonComponents/Notify";
import { API_HOST } from "../../../Settings";
import Signed from "../../ConsentComponents/Signed";
import RejectComponent from "../../CommonComponents/RejectComponent";
import Attachment from "../../ConsentComponents/Attachment";
import ViewAttachments from "../../ConsentComponents/ViewAttachments";
import { FaPlusCircle } from "react-icons/fa";



class ReviewAndCommentByHeadSafetyDepartment extends Component {
    constructor(props){
        super(props)
        this.commentRef = createRef();
        this.handleOfComment = this.handleOfComment.bind(this)
        this.state = {
            is_approved:false,
            is_rejected:false,
            WorkflowData:this.props.WorkflowData,
            WorkflowComponent:this.props.WorkflowComponent,
            DocumentNumber:this.props.DocumentNumber,
            WorkflowSignatures:this.props.WorkflowComponent ? this.props.WorkflowComponent.workflow_signatures:[],
            enable_signing: false,
            enable_approval: false,
            RiskLevelFormData:{
                CategoryRiskLevelRows:getCategoryofRiskLevelBySiteEHSHeadTabelRowName(),
                statutory_authority:'',
                occupational_hazard:'',
                qra_study:''
            },
            isDataSubmited:false,
            show_plant_moc_opinion_card: false,
            plant_moc_opinion: "",
            unique_stage_id: '4',
            unique_comp_id: '11',
            unique_combine: 's4c11',
            stage_status:this.props.stage_status,
            ask_for_more_history: [],
            current_component_name: "ReviewAndCommentByHeadSafetyDepartment",
            userPermissions: {},
            dependent_component_statuses: [],
            all_depend_comp_approved: false,
            attachments:[
                {
                    attachment: "",
                    filename: "",
                    file_size: 0,
                    signed_url:"",
                    attachment_filename: ""
                }
            ],
            WorkflowAttachments:[],
        }
    }
    checkIsApproved = (is_approved) =>{
        this.setState({
            is_approved:is_approved
        })
    }
    componentDidMount(){
        this.getUserPrivileges()
        if(this.props.isCollapased==false && this.props.DocumentNumber){
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/and/save/safety/department/risk/level/${this.props.DocumentNumber}/data`,)
            .then(response => {
                if (response.status === 200 && response.data.success) {
                    var risk_level_form_data = response.data.risk_level_data
                    var risk_level_data = this.state.RiskLevelFormData
                    risk_level_data.CategoryRiskLevelRows.rows.map((risk_level, index) => {
                        risk_level_form_data.CategoryRiskLevelRows && risk_level_form_data.CategoryRiskLevelRows.rows.map((row, indx) => {
                            if (risk_level.name_slug == row.name_slug) {
                                risk_level_data['CategoryRiskLevelRows']['rows'][index]['is_checked'] = row.is_checked
                                risk_level_data['CategoryRiskLevelRows']['rows'][index]['legal_compliance'] = row.legal_compliance
                                risk_level_data['CategoryRiskLevelRows']['rows'][index]['list_down'] = row.list_down
                                risk_level_data['CategoryRiskLevelRows']['rows'][index]['plan_compliance'] = row.plan_compliance
                            }
                            
                        })                        
                    })
                    risk_level_data['statutory_authority'] = risk_level_form_data.statutory_authority
                    risk_level_data['occupational_hazard'] = risk_level_form_data.occupational_hazard
                    risk_level_data['qra_study'] = risk_level_form_data.qra_study
                    this.setState({
                        RiskLevelFormData:risk_level_data,
                        isDataSubmited:response.data.is_submited
                    },()=>this.getFormData())
                }
            }).catch(response => {
                // ErrorHandling(response)
            });
        }
        this.getFormData()  
    }
    handleUpdateAttachmentList = (attachment_obj, idx)=>{
        var attachments_list = this.state.attachments
        attachments_list[idx] = attachment_obj;
        this.setState({attachments: attachments_list})

    };
    handleAddAttachmentButton = (e) =>{
        let attachment_obj = {
            attachment: "",
            filename: "",
            file_size: 0,
            signed_url:"",
            attachment_filename: ""
        }
        var attachments_list = this.state.attachments
        attachments_list.push(attachment_obj);
        this.setState({attachments: attachments_list})
    };
    handleDeleteAttachmentButton = (e, idx) =>{
        var attachments_list = this.state.attachments
        attachments_list.splice(idx, 1);
        this.setState({attachments: attachments_list})
    }

    attachmentSubmitHandler = (e, attachment_obj, idx) => {
        e.preventDefault()
        var attachment_data = {
            model:"workflow_attachments",
            data:{
                    workflow_definition_id:this.state.WorkflowData.workflow_definition_id,
                    stage_id:this.state.WorkflowData.id,
                    component_id:this.state.WorkflowComponent.id,
                    file_name:attachment_obj.attachment_filename
                }
        }
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
        axios.post(`${API_HOST}/workflow/moc-request/request/${this.props.DocumentNumber}/form/data/save/`, attachment_data)
        .then(response => {
            if (response.status === 200) {
                notify('Attachment saved successfully','success')
                var attachment_data = response.data.data.workflow_attachments
                var attachment_obj = {
                    attachment_filename:attachment_data.filename,
                    signed_url:attachment_data.singed_url
                }
                let attachments = this.state.WorkflowAttachments
                attachments.push(attachment_data)
                this.setState({WorkflowAttachments: attachments})
                this.handleUpdateAttachmentList(attachment_obj, idx)
            }

        }).catch(response => {
            // ErrorHandling(response)
        });
        $('.card-loader').fadeOut('slow')
    }

    removeWfAttachment =(event, id_of_wf_attachment, component_id)=>{
        event.preventDefault()
        var user_resp = window.confirm("Are you sure, you want to delete the attachment?")
        if(user_resp){
            $('.card-loader').fadeIn('')
            var attachment_data = {
                workflow_definition_id:this.state.WorkflowData.workflow_definition_id,
                stage_id:this.state.WorkflowData.id,
                component_id:this.state.WorkflowComponent.id,
                wf_attachment_id:id_of_wf_attachment
            }
            axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
            axios.post(`${API_HOST}/workflow/moc-request/request/${this.props.DocumentNumber}/attachment/delete/`, attachment_data)
            .then(response => {
                if (response.status === 200) {
                    notify('Attachment deleted successfully','success')
                    this.getFormData()
                }

            }).catch(response => {
                // ErrorHandling(response)
                $('.card-loader').fadeOut('slow')
            });
        }
        
    }

    handleSaveAttachmentButton = (event, attachment_obj, idx) =>  {
      event.preventDefault();
      if(attachment_obj.attachment){
        $('.loader').fadeIn()
        let form_data_django = new FormData();
        form_data_django.append('attachment', attachment_obj.attachment);
        form_data_django.append('size', attachment_obj.file_size);
        let url = `${API_HOST}/upload/file/in/azure/blob/storage`;
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')
        axios.post(url,form_data_django,
          ).then(response=>{
              if(response.data.success){
                  // notify("File successfully uploaded",'information')
                $('.loader').fadeOut('slow')
                  var attachment_obj = {
                      attachment_filename:response.data.filename,
                      signed_url:response.data.singed_url
                  }
                  this.attachmentSubmitHandler(event, attachment_obj, idx)
              }else{
                  notify(response.data.message,'error')
                  $('.card-loader').fadeOut('slow')
              }

          }).catch(error_xlsx => {

          })

      }else{
          alert("Invalid files selected")
    }
};


    getUserPrivileges = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        let privileges = JSON.stringify(['can_approve', 'can_reject', 'can_ask_for_more_information', 'can_sign', 'can_comment', 'can_create', 'can_review','can_attach'])
        let component = this.state.current_component_name
        let params = {"document_number": this.state.DocumentNumber, "from_new": true, "request_type": "moc_request"}
        axios.get(`${API_HOST}/user/privileges/${component}/${privileges}/`, {params: params})
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    userPermissions:response.data.data,
                })

            }
        }).catch(response => {
            // ErrorHandling(response)
        });
    }

    onChangeHandler = (e) =>{
        e.preventDefault()
        var risk_level_form_data = {...this.state.RiskLevelFormData}
        risk_level_form_data[e.target.name] = e.target.value
        this.setState({
            RiskLevelFormData:risk_level_form_data
        })
    }


    handleOfComment(e, stop_loader, method){
        var response_isvalid = this.commentRef.current.isValid()
        if(!response_isvalid.status){
            if(method == "approve"){
                if(response_isvalid.custom_error_code!=1){
                    // if custom_error_code==1  we can process approval without comment saving
                    notify(response_isvalid.reason,'warning')
                    return false
                }
            }else{
                if(response_isvalid.custom_error_code==1){
                    return false
                }
                if(response_isvalid.custom_error_code==2){
                    // if custom_error_code==1  we can process approval without comment saving
                    notify(response_isvalid.reason,'warning')
                    return false
                }
            }
        }else{
            this.commentRef.current.commentSubmitHandler(e, stop_loader)
            return true
        }
        return true
    }

    changeActionStatus = (e,status,index) =>{
        var risk_level_form_data = {...this.state.RiskLevelFormData}
        risk_level_form_data['CategoryRiskLevelRows']['rows'][index]['legal_compliance'] = status
        this.setState({
            RiskLevelFormData:risk_level_form_data
        })
    }
    isValid = ()=>{
        var name_regexp = new RegExp("<(?:\"[^\"]*\"['\"]*|'[^']*'['\"]*|[^'\">])+>");
        var risk_level_form_data = this.state.RiskLevelFormData
        if(risk_level_form_data.statutory_authority&&name_regexp.test(risk_level_form_data.statutory_authority)){
            document.querySelector("#statutory_authority").classList.add("error")
            return {"status":false, "reason":"Please provide valid information in input fields.", "id": "statutory_authority"}
        }
        if(risk_level_form_data.occupational_hazard&&name_regexp.test(risk_level_form_data.occupational_hazard)){
            document.querySelector("#occupational_hazard").classList.add("error")
            return {"status":false, "reason":"Please provide valid information in input fields.", 'id': 'occupational_hazard'}
        }
        if(risk_level_form_data.qra_study&&name_regexp.test(risk_level_form_data.qra_study)){
            document.querySelector("#qra_study").classList.add("error")
            return {"status":false, "reason":"Please provide valid information in input fields.", 'id': 'qra_study'}
        }
        var risk_rows = this.state.RiskLevelFormData.CategoryRiskLevelRows.rows
        for(let i=0;i<risk_rows.length;i++){
            var list_down = document.getElementById('listdown_'+i).value
            var plan_compliance = document.getElementById('plancompliance_'+i).value
            if((list_down&&name_regexp.test(list_down)) || (plan_compliance&&name_regexp.test(plan_compliance))){
                return {"status":false, "reason":"Please provide valid information in input fields.", 'id': 'listdown_'+i}
            }
        }
        return {"status": true}
    }
    onSubmitHandler = (e) =>{
        e.preventDefault() 
        var response_isvalid = this.isValid()
        if(!response_isvalid.status){
            notify(response_isvalid.reason,'warning')
            setTimeout(function () {
                document.querySelector('#'+response_isvalid.id).scrollIntoView({
                    behavior: 'smooth', block: "center", inline: "nearest" 
                })
            }, 100)
            return false
        }
        var msg = "Are you sure, you want submit the data?"
        if(window.confirm(msg)){
            $('.loader').fadeIn()
            var risk_level_data = this.state.RiskLevelFormData.CategoryRiskLevelRows.rows.map((action_row,indx)=>{
                const action_items = document.querySelectorAll("#"+action_row.name_slug);
                for (let i = 0; i < action_items.length; i++) {
                    if(action_items[i].type === "checkbox"){
                        var is_checked = action_items[i].checked
                    }
                }
                var list_down = document.getElementById('listdown_'+indx).value
                var plan_compliance = document.getElementById('plancompliance_'+indx).value
                action_row.is_checked = is_checked
                action_row.list_down = list_down
                action_row.plan_compliance = plan_compliance
                return action_row
            })
            var risk_level_form_data = this.state.RiskLevelFormData
            risk_level_form_data.CategoryRiskLevelRows.rows = risk_level_data
            const auth_token = localStorage.getItem("token")
                axios.defaults.headers.post['Authorization'] =auth_token      
                axios.post(`${API_HOST}/get/and/save/safety/department/risk/level/${this.props.DocumentNumber}/data`, risk_level_form_data)
                .then(response => {
                    if (response.status === 200 && response.data.success) {    
                        notify('Data saved successfully!','success')
                        $('.loader').fadeOut('slow')
                        this.setState({
                            isDataSubmited:true
                        })
                    }else{
                        notify('Somethig went wrong!','error')
                    }
                }).catch(response => {
                    // ErrorHandling(response)
                });
        }
    }
    getFormData = () =>{
        if(this.props.isCollapased==false){
            axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
            axios.get(`${API_HOST}/workflow/moc/request/${this.props.DocumentNumber}/stage/${this.props.StageName}/?component_name=${this.props.ComponentName}`, )
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        WorkflowData:response.data.data.workflow_stage_info,
                        WorkflowComponent:response.data.data.workflow_stage_info.components[0],
                        WorkflowSignatures:response.data.data.workflow_stage_info.components[0].workflow_signatures,
                        enable_signing:response.data.data.workflow_stage_info.components[0].enable_signing,
                        enable_approval:response.data.data.workflow_stage_info.components[0].enable_approval,
                        is_approved: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                            response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Approved" ? true : false,
                        is_rejected: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                            response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false,
                        show_plant_moc_opinion_card: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                            response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false,
                        plant_moc_opinion: response.data.data.workflow_stage_info.components[0].wf_plant_moc_lead_review[0] ?
                            response.data.data.workflow_stage_info.components[0].wf_plant_moc_lead_review[0].action.name : "",
                        ask_for_more_history:response.data.data.ask_for_more_history,
                        WorkflowAttachments: response.data.data.workflow_stage_info.components[0].workflow_attachments,
                        // userComponentPermissions: response.data.data.workflow_stage_info.components[0].user_component_permissions
                    })
                    // this.renderDynamicComponent()
                }

            }).catch(response => {
                // ErrorHandling(response)
            });

            axios.get(`${API_HOST}/workflow/moc/request/${this.props.DocumentNumber}/components/statuses/`)
                .then(response=>{
                    var any_com_pending = false
                    var dependent_component_statuses = response.data.data.filter((row, index)=>{
                        var required_comp = !['ReviewAndCommentByHeadQADepartment',
                                'ReviewAndCommentByHeadSafetyDepartment'].includes(row.react_component_name)
                        if(required_comp){
                            if(any_com_pending==false && row['status']!='approved'){
                                any_com_pending = true
                            }
                        }
                        return required_comp

                    })
                    this.setState({
                        "dependent_component_statuses": dependent_component_statuses,
                        "all_depend_comp_approved": !any_com_pending
                    })
                }).catch(response=>{})
        }else{
            let current_stage_info = this.props.WorkflowData
            let is_approved = current_stage_info.components[0].workflow_action_logs[0] && 
                current_stage_info.components[0].workflow_action_logs[0].status == "Approved" ? true : false
            let show_plant_moc_opinion_card = current_stage_info.components[0].workflow_action_logs[0] && 
                current_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false
            let plant_moc_opinion =  current_stage_info.components[0].wf_plant_moc_lead_review[0] ?
                            current_stage_info.components[0].wf_plant_moc_lead_review[0].action.name : ""
            this.setState({is_approved:is_approved, show_plant_moc_opinion_card: show_plant_moc_opinion_card, plant_moc_opinion: plant_moc_opinion,
                // userComponentPermissions: current_stage_info.components[0].user_component_permissions
            })
        }
    }


    render(){
        var access_type = 'view'
        if(!this.state.all_depend_comp_approved || !this.state.isDataSubmited){
            access_type='create'
        }
        var ask_for_more_history_filtered = []
        if(this.state){
            if(this.state.hasOwnProperty('ask_for_more_history')){
                ask_for_more_history_filtered = this.state.ask_for_more_history.filter((each, index)=>{
                    return each.status.name_slug=="open"
                })
            }
        }
        if(this.state.ask_more_stage_name_slug != "" && !(['execution-hod-review', 'hod-of-safety-review', 'hod-of-qa-review', 'closure-by-moc-lead'].includes(this.state.ask_more_stage_name_slug))){
            if(ask_for_more_history_filtered.length>0){
                access_type  ="update"
            }
        }
        if(this.state.userPermissions.can_attach && !this.state.is_approved && !this.state.is_rejected){
            access_type = 'create'
        }
        var dependent_comp_info = {"dependent_comp_required":true, "is_required_dependent_comp_approved": this.state.all_depend_comp_approved}
        var form_submition_info = {"form_submition_required":true, "is_required_forms_data_submitted": this.state.isDataSubmited}
        // is_required_forms_data_submitted true only if all required forms are submitted to enable approval
        return(
            <div className='card mb-3' key={this.state.unique_combine} id="ReviewAndCommentByHeadSafetyDepartment">
                <div className='p-4'>
                    <div className='heading-Text font-16'>
                        Review and Comment by Head Safety Department
                    </div>
                    {false ?
                        <div>
                            state Plant moc opinion {this.state.plant_moc_opinion}
                            <br/>
                            props isCollapsed {this.props.isCollapased ? 'YES': 'NO'}
                            <br/>
                            state isCollapsed {this.state.isCollapased ? 'YES': 'NO'}
                            <br/>
                            props is_approved {this.props.is_approved ? 'YES': 'NO'}
                            <br/>
                            state is_approved {this.state.is_approved ? 'YES': 'NO'}
                            <br/>
                            workflow_action_logs &nbsp;
                            {this.state.WorkflowComponent.workflow_action_logs.map((row, index)=>{
                                return('id:' + row['id']+' -- status:' + row['status'] +', ')
                            })}
                            <br/>
                            wf_plant_moc_lead_review &nbsp;
                            {this.state.WorkflowComponent.wf_plant_moc_lead_review.map((row, index)=>{
                                return ('id:'+row['id']+' -- status:'+ row['action']['name'] +', ')
                            })}
                        </div>
                        :null
                    }
                    <div className="mt-4">
                        <div>Category of Risk Level by Site EHS Head</div>
                        <div className="pt-3 overflow-x-scroll">
                        <table className='table table-bordered'>
                                <thead className='vertical-align-middle'>
                                    <tr>
                                    <th width="20%">
                                        <b>Tick Risk Level </b> 
                                    </th>
                                    <th className='text-center' width="40%"><b>Impact on any legal compliance? (Y/N)</b></th>
                                    <th className='text-center' width="20%"> <b>List Down</b></th>      
                                    <th className='text-center' width="20%"> <b>Plan for taking care the compliance</b></th>
                                    </tr>                             
                                </thead>
                                <tbody className='vertical-align-middle'>
                                    <CategoryofRiskLevelBySiteEHSHeadTabelRow 
                                    CategoryRiskLevelRows={this.state.RiskLevelFormData.CategoryRiskLevelRows} 
                                    changeActionStatus = {this.changeActionStatus}
                                    DataSubmited = {this.state.isDataSubmited}
                                    accessType={access_type}
                                    userPermissions={this.state.userPermissions.can_create}
                                    />
                                </tbody>
                                
                        </table>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className='pt-3 pb-3 d-flex'>
                            <div className="col-6">
                                <label className="form-label" htmlFor="statutory_authority">1)  Does the Change need approval from any statutory authority (CCOE, PESO, Director of factories, etc.)</label>
                            </div>
                            <div className="col-6">
                                <input className='form-control' name="statutory_authority"  id="statutory_authority" type="text" 
                                onChange={e => this.onChangeHandler(e)}
                                value={this.state.RiskLevelFormData.statutory_authority?this.state.RiskLevelFormData.statutory_authority:"" }
                                disabled={this.state.isDataSubmited || !this.state.userPermissions.can_create  ? true : false}/>
                            </div>
                        </div>
                        <div className='pt-3 pb-3 d-flex'>
                            <div className="col-6">
                                <label className="form-label" htmlFor="occupational_hazard"> 2) Does the Change result in any occupational hazard?</label>
                            </div>
                            <div className="col-6">
                                <input className='form-control' name="occupational_hazard"  id="occupational_hazard" type="text"
                                onChange={e => this.onChangeHandler(e)}
                                value={this.state.RiskLevelFormData.occupational_hazard ? this.state.RiskLevelFormData.occupational_hazard : ""}
                                disabled={this.state.isDataSubmited || !this.state.userPermissions.can_create  ? true : false}/>
                            </div>
                        </div>
                        <div className='pt-3 pb-3 d-flex'>
                            <div className="col-6">
                                <label className="form-label" htmlFor="qra_study"> 3) Does QRA Study required for this change? 
                                    (If 1.the Storage of Hazardous material is exceeding the value explained in MSIHC Rules, 1989 then QRA study will be applicable
                                    2.If there is the New facility development for storing/handling hazardous chemicals or shifting of the existing facility)
                                </label>
                            </div>
                            <div className="col-6">
                                <input className='form-control' name="qra_study"  id="qra_study" type="text" 
                                onChange={e => this.onChangeHandler(e)}
                                value={this.state.RiskLevelFormData.qra_study?this.state.RiskLevelFormData.qra_study:""}
                                disabled={this.state.isDataSubmited || !this.state.userPermissions.can_create ? true : false}/>
                            </div>
                        </div>
                        {!this.state.userPermissions.can_create || access_type=="view" || this.state.isDataSubmited || (this.state.show_plant_moc_opinion_card && this.state.plant_moc_opinion != 'Rejected')?'':
                            <div className='col-12 mt-3 mb-3 text-right'>
                                    <button type="button" className="btn btn-success submit-engery-managment-data" onClick={this.onSubmitHandler}>Submit</button>
                            </div>}

                    </div>
                    &nbsp;
                    <div>
                        <p><b>Attachments</b></p>
                        { ask_for_more_history_filtered.length>0 || this.state.WorkflowAttachments.length > 0 ?
                            <ViewAttachments removeWfAttachment={this.removeWfAttachment}
                                key={this.state.unique_combine+'va'}
                                enable_attach_delete={['create'].includes(access_type)}
                                WorkflowAttachments={this.state.WorkflowAttachments} />
                      
                        :((this.state.is_approved === true || this.state.is_rejected === true) ? 'No Attachments' : '') }
                    </div>
                    {this.state.userPermissions.can_attach && !(this.state.is_approved || this.state.is_rejected) && !['approved', 'rejected'].includes(this.state.stage_status)?
                    <div>
                        <div className='heading-Text font-12 mb-3 d-flex'>
                        Upload Attachments
                            <div className="plus-circle"><FaPlusCircle onClick={this.handleAddAttachmentButton}/></div>
                        </div>
                        {this.state.attachments.map((each, index)=>{
                            return <Attachment AttachmentIndex={index}
                                    AttachmentObj={each}
                                    key={this.state.unique_combine+'attach'+index}
                                    handleUpdateAttachmentList={this.handleUpdateAttachmentList}
                                    handleDeleteAttachmentButton={this.handleDeleteAttachmentButton}
                                    handleSaveAttachmentButton={this.handleSaveAttachmentButton}
                                    />
                        })}
                    </div>:null}

                    <div className='mt-4 row mb-3'>
                        <Comment is_rejected={this.state.is_rejected}
                            stage_status={this.state.stage_status}
                            unique_combine={this.state.unique_combine} 
                            PlantMOCOpinion={this.state.plant_moc_opinion}
                              isRejected={this.state.show_plant_moc_opinion_card}
                              is_approved={this.state.is_approved}
                              WorkflowData={this.state.WorkflowData}
                              WorkflowComponent={this.state.WorkflowComponent}
                              DocumentNumber={this.state.DocumentNumber}
                              RequestType="moc-request" 
                              ref={this.commentRef}
                              userPermissions={this.state.userPermissions} />

                        <div className="col-lg-2 col-md-6 col-sm-4 col-4"></div>
                        {this.state.WorkflowSignatures.length > 0 ?
                           <Signed SignatureData={this.state.WorkflowSignatures[0]}/> : ""
                        }
                        {this.state.show_plant_moc_opinion_card ?
                           <RejectComponent
                               CurrentStageStatus={this.state.stage_status}
                               isRejected={this.state.show_plant_moc_opinion_card}
                               WorkflowData={this.state.WorkflowData}
                               WorkflowComponent={this.state.WorkflowComponent}
                               DocumentNumber={this.state.DocumentNumber}
                               current_component_name={this.state.current_component_name}
                               userPermissions={this.state.userPermissions}
                               RequestType="moc-request"
                           />  : null
                        }
                    </div>

                    {(this.props.isCollapased || (this.state.show_plant_moc_opinion_card && this.state.plant_moc_opinion != 'Rejected'))
                        ? '':
                        <div  className='d-flex w-100 justify-content-end align-items-end tri-buttons mt-4'>
                            <div className='pe-2'>
                                <AskButton 
                                    IsApproved={this.state.is_approved}
                                    stage_status={this.state.stage_status}
                                    key={this.state.unique_combine+'askbtn'}
                                    WorkflowData={this.state.WorkflowData} 
                                    WorkflowComponent={this.state.WorkflowComponent}  
                                    DocumentNumber={this.state.DocumentNumber}
                                    current_component_name={this.state.current_component_name}
                                    ask_for_more_history={this.state.ask_for_more_history}
                                    RequestType="moc-request" 
                                    dependent_comp_info = {dependent_comp_info}
                                    userPermissions={this.state.userPermissions} 
                                    handleOfComment = {this.handleOfComment}/>
                            </div>
                            <div className='pe-2'>
                                <RejectButton
                                    stage_status={this.state.stage_status}
                                    PlantMOCOpinion={this.state.plant_moc_opinion}
                                    IsApproved={this.state.is_approved}
                                    WorkflowData={this.state.WorkflowData} 
                                    WorkflowComponent={this.state.WorkflowComponent}  
                                    DocumentNumber={this.state.DocumentNumber}
                                    key={this.state.unique_combine+'aprbtn'}
                                    unique_combine = {this.state.unique_combine}
                                    current_component_name={this.state.current_component_name}
                                    ask_for_more_history={this.state.ask_for_more_history}
                                    RequestType="moc-request"
                                    dependent_comp_info = {dependent_comp_info}
                                    userPermissions={this.state.userPermissions} 
                                    handleOfComment = {this.handleOfComment}/>
                            </div>
                            <div>
                                <ApproveButton
                                    stage_status={this.state.stage_status}
                                    key={this.state.unique_combine+'_approve'}
                                    IsApproved={this.state.is_approved}
                                    WorkflowData={this.state.WorkflowData}
                                    WorkflowComponent={this.state.WorkflowComponent}
                                    DocumentNumber={this.state.DocumentNumber}
                                    key={this.state.unique_combine+'aprbtn'}
                                    unique_combine = {this.state.unique_combine}
                                    current_component_name={this.state.current_component_name}
                                    ask_for_more_history={this.state.ask_for_more_history}
                                    RequestType="moc-request"
                                    form_submition_info={form_submition_info}
                                    dependent_comp_info = {dependent_comp_info}
                                    handleOfComment = {this.handleOfComment}
                                    userPermissions={this.state.userPermissions}/>
                            </div>
                        </div>
                    }
                    <div className='mt-2'>
                        <Sign IsSignatures={this.state.WorkflowSignatures.length > 0?true:false} 
                        IsApproved={this.state.is_approved}
                        WorkflowData={this.state.WorkflowData} WorkflowComponent={this.state.WorkflowComponent}  
                        DocumentNumber={this.state.DocumentNumber} RequestType="moc-request"
                        userPermissions={this.state.userPermissions} 
                        current_component_name={this.state.current_component_name}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default ReviewAndCommentByHeadSafetyDepartment;