import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import UserIcon from "../../images/UserIcon.svg"
import { FaUser, FaLock, FaPowerOff } from "react-icons/fa";
import $ from 'jquery'
import { API_HOST } from '../../Settings.js'
import axios from 'axios';
import Profilepic from "../../images/ProfilePic.svg"



class ProfileDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            first_name: '',
            last_name: '',
            email: ''
        }
    }
    componentDidMount() {
        // var userData = JSON.parse(localStorage.getItem('user_data'))
        // this.setState({
        //     first_name:userData.first_name,
        //     last_name:userData.last_name,
        //     email:userData.email
        // })
        $('.loader').fadeOut('slow')
    }


    signOut = (e) => {
        e.preventDefault();
        $('.loader').fadeIn();

        const auth_token = localStorage.getItem("token");
        const user_login_type = localStorage.getItem("user_login_type");
        const userData = localStorage.getItem("user_data");

        const logoutEndpoint ='/logout/';

        axios.defaults.headers.get['Authorization'] = auth_token;

        axios.get(API_HOST + logoutEndpoint, {
            params: {
                user_data: userData
            }
        }).then(response => {
            if (response.status === 200) {
                localStorage.clear();
                window.location.href = "/login";
                $('.loader').fadeOut('slow');
            }
        });
    };





    render() {
        return (
            <div className='user-profile'>
                <ul className='user-details'>
                    <li className='divider account-details'>
                        <FaUser /><Link to="/profile">Account</Link>
                    </li>
                    <li className='divider'>
                        <FaLock /><Link to="/change/password">Change password</Link>
                    </li>
                    <li className=''>
                        <FaPowerOff /><Link to="#" onClick={this.signOut}>Sign Out</Link>
                    </li>
                </ul>

                {/* <div><FaUserCircle/></div>
                <div className='heading-Text mt-2'>{this.state.first_name} {this.state.last_name}</div>
                <div className='userEmailId mt-2'>                    
                    <Link to="/profile">{this.state.email}</Link>
                </div>
                <div className='changePassword mt-4'>
                                        
                </div> */}
            </div>
        )
    }
}

export default ProfileDetails;