import React, { Component } from 'react';
import axios from 'axios';
import { API_HOST } from '../../Settings';
import $ from 'jquery';
import notify from '../CommonComponents/Notify';

class AskButton extends Component{

    AskMoreInfoHandler = (e) =>{
        e.preventDefault()
        if(window.confirm("Are you sure, You need more information?")){
            var resp = true
            if(this.props.hasOwnProperty('handleOfComment')){
                resp = this.props.handleOfComment(e, false, "ask")
            }
            var view_comments = this.props.WorkflowComponent?this.props.WorkflowComponent.workflow_comments:[]
            if(resp || view_comments.length>0){
                $('.loader').fadeIn()
                var filter_askmore_action = this.props.WorkflowComponent.workflow_actions.filter((actionData,indx)=>{
                    return actionData.action.name_slug == 'asked-for-additional-information'
                })
                var askmore_data = {
                    workflow_actions_id:filter_askmore_action[0].id,
                    action_id:filter_askmore_action[0].action.id,
                }
                axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')
                axios.post(`${API_HOST}/workflow/${this.props.RequestType}/request/${this.props.DocumentNumber}/action/save/`, askmore_data)
                .then(response => {
                    if (response.status === 200) {
                        localStorage.setItem('current_component_name', this.props.current_component_name);
                        notify('Request created successfully','success')
                        setTimeout(()=>{
                            $('.loader').fadeOut('slow')
                            window.location.reload();
                        }, 4000)
                    }

                }).catch(response => {
                    // ErrorHandling(response)
                });
            }else{
                notify('Comments mandatory for ask for more information.','warning')
            }
        }
    }

    render(){
        var show_askm = false
        var reason_msg = ''
        if(['approved', 'rejected'].includes(this.props.stage_status)){
            reason_msg = "This stage already " + this.props.stage_status
        }else if(this.props.IsApproved){
            reason_msg = "This department already approved" 
        }
        var current_comp_ask_for_more_history = []
        if(this.props.ask_for_more_history){
            current_comp_ask_for_more_history = this.props.ask_for_more_history.filter((each, index)=>{
                    if(each.react_component.name==this.props.current_component_name){
                        return each.status.name_slug=="open"
                    }else{
                        return false
                    }
                })
        }
        if(current_comp_ask_for_more_history.length==0){
            show_askm = true
        }else{
            reason_msg = 'More information requested'
        }
        if(this.props.hasOwnProperty('dependent_comp_info')){
            var dependent_comp_info = this.props.dependent_comp_info
            if(dependent_comp_info['dependent_comp_required']){
                if(!dependent_comp_info['is_required_dependent_comp_approved']){
                    show_askm=false
                    reason_msg = "Pending dependent head approvals"
                }
            }
        }

        // console.log("show_askm", show_askm, current_comp_ask_for_more_history, this.props.ask_for_more_history)
        return(
            <div>
                {this.props.userPermissions.can_ask_for_more_information ?
                <div>
                    {(show_askm==false) || this.props.IsApproved || ['approved', 'rejected'].includes(this.props.stage_status) ?
                        ['approved', 'rejected'].includes(this.props.stage_status)||this.props.IsApproved?null
                        :
                        <span title={reason_msg}>
                            {/* <button type="button" className="btn btn-disabled" disabled={true}>
                                Ask for more information
                            </button> */}
                            {/* <a disabled={true} className="ask-information">
                                Ask for more information
                            </a> */}
                             <button class="ask-information btn disable-cmnt-ask-btn" type="button">
                                Ask more info
                            </button>
                        </span>
                    :
                    // <button type="button" onClick={this.AskMoreInfoHandler} className=" ask-information btn btn-info">
                    //     Ask for more information
                    // </button>
                    // <a href="" className="ask-information" onClick={this.AskMoreInfoHandler}>
                    //     Ask for more information
                    // </a>
                    <button class="ask-information btn btn-warning cmnt-ask-btn" type="button" onClick={this.AskMoreInfoHandler}>
                        Ask more info
                    </button>
                    }
                </div>
                :null}
            </div>
        )
    }
}

export default AskButton;
// <<<<<<< HEAD
//                     <span title={reason_msg}>
//                         {/* <button type="button" className="btn btn-disabled" disabled={true}>
//                             Ask for more information
//                         </button> */}
//                         <a href="" className="ask-information">
//                             Ask for more information
//                         </a>
//                     </span>
//                 :
//                 <a href="/Applyleave?tab=leavecalendar" className="ask-information" onClick={this.AskMoreInfoHandler}>
//                     Ask for more information
//                 </a>
//                 // <button type="button" onClick={this.AskMoreInfoHandler} className=" ask-information btn btn-info">
//                 //     Ask for more information
//                 // </button>
//                 }
// =======