import React, { Component } from "react"; 
import JobInitiatorAndExecutorRow from "./JobInitiatorAndExecutorRow";
import UploadDocument from "../../ConsentComponents/UploadDocument";
import ChangeAuthorityGeneral from "./ChangeAuthorityGeneral";
import PlantAndEquipment from "./PlantAndEquipment";
import ProcessAndOperatingCondition from "./ProcessAndOperatingCondition";
import { getMaterialHazardName } from "../../CommonComponents/MiscellaneousObject";
import MaterialHazards from "./MaterialHazards";
import OrganizationalIssues from "./OrganizationalIssues";
import ChangeAuthoritySteps from "../Step7/ChangeAuthoritySteps"
import Attachment from "../../ConsentComponents/Attachment";
import ViewAttachments from "../../ConsentComponents/ViewAttachments";
import { FaPlusCircle } from "react-icons/fa";
import axios from 'axios';
import { API_HOST } from '../../../Settings';
import $ from 'jquery';
import notify from '../../CommonComponents/Notify';


class JobInitiatorAndExecutor extends Component {
    constructor(props){
        super(props)
        this.state = {
            isDataSubmited: false,
            attachments:[
                {
                    attachment: "",
                    filename: "",
                    file_size: 0,
                    signed_url:"",
                    attachment_filename: ""
                }
            ],
            WorkflowAttachments: this.props.WorkflowComponent?this.props.WorkflowComponent.workflow_attachments:[],
            unique_stage_id: '9',
            unique_comp_id: '2',
            unique_combine: 's9c2',
            stage_status:this.props.stage_status,
            iagree_one: false
        }
    }
    static getDerivedStateFromProps(props, state) {
        if(props.isDataSubmited !== state.isDataSubmited){
            //Change in props
            return{
                isDataSubmited: props.isDataSubmited ? props.isDataSubmited:false,
                WorkflowAttachments:props.WorkflowComponent?props.WorkflowComponent.workflow_attachments:[]
            };
        }
        return {
            WorkflowAttachments:props.WorkflowComponent?props.WorkflowComponent.workflow_attachments:[]
        };
    }

    handleUpdateAttachmentList = (attachment_obj, idx)=>{
        var attachments_list = this.state.attachments
        attachments_list[idx] = attachment_obj;
        this.setState({attachments: attachments_list})

    };
    handleAddAttachmentButton = (e) =>{
        let attachment_obj = {
            attachment: "",
            filename: "",
            file_size: 0,
            signed_url:"",
            attachment_filename: ""
        }
        var attachments_list = this.state.attachments
        attachments_list.push(attachment_obj);
        this.setState({attachments: attachments_list})
    };
    handleDeleteAttachmentButton = (e, idx) =>{
        var attachments_list = this.state.attachments
        attachments_list.splice(idx, 1);
        this.setState({attachments: attachments_list})
    }

    attachmentSubmitHandler = (e, attachment_obj, idx) => {
        e.preventDefault()
        var attachment_data = {
            model:"workflow_attachments",
            data:{
                    workflow_definition_id:this.props.WorkflowData.workflow_definition_id,
                    stage_id:this.props.WorkflowData.id,
                    component_id:this.props.WorkflowComponent.id,
                    file_name:attachment_obj.attachment_filename
                }
        }
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
        axios.post(`${API_HOST}/workflow/moc-request/request/${this.props.DocumentNumber}/form/data/save/`, attachment_data)
        .then(response => {
            if (response.status === 200) {
                notify('Attachment saved successfully','success')
                var attachment_data = response.data.data.workflow_attachments
                var attachment_obj = {
                    attachment_filename:attachment_data.filename,
                    signed_url:attachment_data.singed_url
                }
                let attachments = this.state.WorkflowAttachments
                attachments.push(attachment_data)
                this.setState({WorkflowAttachments: attachments})
                this.handleUpdateAttachmentList(attachment_obj, idx)
            }

        }).catch(response => {
            // ErrorHandling(response)
        });
        $('.card-loader').fadeOut('slow')
    }


    handleIagreeOne = (e)=>{
        if(e.target.type=='checkbox'){
            this.setState({"iagree_one": e.target.checked})
        }
    }

    handleSaveAttachmentButton = (event, attachment_obj, idx) =>  {
      event.preventDefault();
      $('.card-loader').fadeIn()
      if(attachment_obj.attachment){
        let form_data_django = new FormData();
        form_data_django.append('attachment', attachment_obj.attachment);
        form_data_django.append('size', attachment_obj.file_size);
        let url = `${API_HOST}/upload/file/in/azure/blob/storage`;
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')
        axios.post(url,form_data_django,
          ).then(response=>{
              if(response.data.success){
                  // notify("File successfully uploaded",'information')
                  var attachment_obj = {
                      attachment_filename:response.data.filename,
                      signed_url:response.data.singed_url
                  }
                  this.attachmentSubmitHandler(event, attachment_obj, idx)
              }else{
                  notify(response.data.message,'error')
                  $('.card-loader').fadeOut('slow')
              }

          }).catch(error_xlsx => {

          })

        }else{alert("Invalid files selected")}
    }    

    render() {
        var access_type = "view"
        if(this.props){
            access_type = this.props.access_type
        }
        return (
            <div className="card p-3 mb-3" key={this.state.unique_combine+'jobinit'}>
                <h5 className="card-title mb-3"><b>Job Initiator & Executor</b></h5>
                <div className="overflow-x-scroll">
                    <table className='table table-bordered'>
                        <thead className='vertical-align-middle'>
                            <tr>
                                <th width="50%">
                                    <b>General Checks</b> 
                                </th>
                                <th className='text-center'><b>Yes</b> <b>No</b> <b>N/A</b></th>  
                            </tr>                       
                        </thead>
                        <tbody className='vertical-align-middle'>
                            <JobInitiatorAndExecutorRow ExecutorGeneralChecksData={this.props.ExecutorGeneralChecksData} handleInitiatorChecks={this.props.handleInitiatorChecks} DataSubmited={this.state.isDataSubmited} access_type={access_type}/>
                        </tbody>
                    </table>
                </div>
            {false ?
                <div className="mb-3">
                    <div className="mb-2">
                        <b>I/We have reviewed the installed modification and agree it is safe for commissioning checkbox
                            Initiator / Executor HOD</b>
                    </div>
                    <div className='col-6'>
                        <label className="form-label" htmlFor="executor_hod_performance_review">Review the Performance</label>
                        <textarea className='form-control' rows="3" name="executor_hod_performance_review" id="executor_hod_performance_review" type="text" value={this.props.ExecutionData.executor_hod_performance_review} onChange={(e)=>this.props.handleOnChange(e)} ></textarea>
                    </div>
                </div>:null
            }
            {false?
                <div className="mb-3">
                    <div className="mb-2">
                        <b>Engineering  / EHS HOD</b>
                    </div>
                    <div className='col-6'>
                        <label className="form-label" htmlFor="ehs_hod_performance_review">Review the Performance</label>
                        <textarea className='form-control' rows="3" name="ehs_hod_performance_review" id="ehs_hod_performance_review" type="text" value={this.props.ExecutionData.ehs_hod_performance_review} onChange={(e)=>this.props.handleOnChange(e)}></textarea>
                    </div>
                </div>:null
            }
            <div className="mb-3">
                <div className="mb-2">
                    <b>MoC Completion Form along with relevant Documents</b>
                </div>
               <div className='row mt-3 mb-3'>
                    <div className='col-12'>
                        <p> <b>Customer Feedback Document </b></p>
                        <div>
                        {this.state.WorkflowAttachments.length > 0 ?
                            <ViewAttachments removeWfAttachment={this.props.removeWfAttachment}
                                key={this.state.unique_combine+'va'}
                                enable_attach_delete={['create', 'update','view'].includes(access_type) && this.props.moc_status == 'Open'}
                                WorkflowAttachments={this.state.WorkflowAttachments} />
                        :(this.props.WorkflowComponent?this.props.WorkflowComponent.workflow_attachments.length>0:false)?
                            <ViewAttachments 
                                key={this.state.unique_combine+'va'}
                                enable_attach_delete={['create', 'update','view'].includes(access_type) && this.props.moc_status == 'Open'}
                                removeWfAttachment={this.props.removeWfAttachment}
                                WorkflowAttachments={this.props.WorkflowComponent.workflow_attachments} />
                            :['view'].includes(access_type)?"No Attachments":""}

                        </div>
                        {this.props.userPermissions.can_attach && 
                            ["create", "update", "view"].includes(access_type) && 
                            this.props.moc_status == 'Open' ? (
                            <div>
                                <div className='heading-Text font-12 mb-3 d-flex'>
                                Upload Attachments
                                <div className="plus-circle">
                                    <FaPlusCircle onClick={this.handleAddAttachmentButton} />
                                </div>
                                </div>
                                {this.state.attachments.map((each, index) => {
                                return (
                                    <Attachment 
                                    AttachmentIndex={index}
                                    AttachmentObj={each}
                                    key={this.state.unique_combine + 'attach' + index}
                                    handleUpdateAttachmentList={this.handleUpdateAttachmentList}
                                    handleDeleteAttachmentButton={this.handleDeleteAttachmentButton}
                                    handleSaveAttachmentButton={this.handleSaveAttachmentButton}
                                    />
                                );
                                })}
                            </div>
                            ) : null}
                    </div>
                </div>
            </div>
            <div className="mb-3">
                <div className="mb-2">
                    <b>Change Authority -  Completion Checklist</b>
                </div>
               <div>
                   <b>To be completed by Project Implementer(initiating department) within 3 months of commissioning the installation</b>
               </div>
            </div>
            <div>
                <ChangeAuthoritySteps ChangeAuthorityStepsData={this.props.ChangeAuthorityStepsData} access_type={access_type}
                handleAuthorityChecks={this.props.handleAuthorityChecks} handleAuthorityComments={this.props.handleAuthorityComments} DataSubmited={this.state.isDataSubmited}/>
                {(this.props.userPermissions.can_update) && (access_type=='update' || this.props.ask_for_more_history_filtered.length>0) ? 
                <div className='card mb-3 execution-btn-card'>
                    <div className='card-body'>
                        <div className="d-grid gap-2 d-md-flex">
                            <div className='form-check'>
                                <input className="form-check-input" onChange={this.handleIagreeOne}
                                    checked={this.state.iagree_one}
                                    type="checkbox" id="reviewed"/>
                                <label className="form-check-label" htmlFor="reviewed">
                                I agree that the change proposal is completed in its entirety
                                </label>
                            </div>
                            {/*<button class="btn btn-outline-secondary" type="button" id="cancel">Cancel</button>
                            <button class="btn btn-light" type="button" id="save-as-draft">Save as Draft</button>*/}
                             {this.state.iagree_one && this.props.userPermissions.can_update?
                                <button className="btn btn-dark" type="submit" id="submit" onClick={(e) => this.props.handleUpdateButtonClick(e)} >Update</button>:
                                <button className="btn btn-dark" type="submit" id="submit" disabled={true} >Update</button>
                            }
                        </div>
                    </div>
                </div>
                :this.state.isDataSubmited || !this.props.userPermissions.can_create ? "":
                <div className='card mb-3 execution-btn-card'>
                    <div className='card-body'>
                        <div className="d-grid gap-2 d-md-flex">
                            <div className='form-check'>
                                <input className="form-check-input" onChange={this.handleIagreeOne}
                                    checked={this.state.iagree_one}
                                    type="checkbox" id="reviewed"/>
                                <label className="form-check-label" htmlFor="reviewed">
                                I agree that the change proposal is completed in its entirety
                                </label>
                            </div>
                            {/* <button class="btn btn-outline-secondary" type="button" id="cancel">Cancel</button>
                            <button class="btn btn-light" type="button" id="save-as-draft">Save as Draft</button> */}
                            {this.state.iagree_one && this.props.userPermissions.can_create?
                                <button className="btn btn-dark" type="submit" id="submit" onClick={(e) => this.props.handleButtonClick(e)} >Submit</button>:
                                <button className="btn btn-dark" type="submit" id="submit" disabled={true} >Submit</button>
                            }
                        </div>
                    </div>
                </div>}
            </div>
            {/*<div>
            <table className='table table-bordered'>
                    <thead className='vertical-align-middle'>
                        <tr>
                            <th width="50%">
                                <b>General Checks</b> 
                            </th>
                            <th className='text-center'><b>Yes</b> <b>No</b> <b>N/A</b></th>     
                            <th>Comments</th>
                        </tr>
                    </thead>
                    <tbody className='vertical-align-middle'>
                        <ChangeAuthorityGeneral />
                    </tbody>
                </table>
            </div>
            <div className="mb-3">
                <div className="mb-2">
                    <b>PROCESS & OPERATING CONDITIONS</b>                
                </div>
                <table className='table table-bordered'>                    
                    <tbody className='vertical-align-middle'>
                        <ProcessAndOperatingCondition />
                    </tbody>
                </table>
            </div>
            <div className="mb-3">
                <div className="mb-2">
                    <b>MATERIAL HAZARDS</b>                
                </div>
                <table className='table table-bordered'>                    
                    <tbody className='vertical-align-middle'>
                        <MaterialHazards />
                    </tbody>
                </table>
            </div>
            <div className="mb-3">
                <div className="mb-2">
                    <b>Organizational Issues</b>                
                </div>
                <table className='table table-bordered'>                    
                    <tbody className='vertical-align-middle'>
                       <OrganizationalIssues />
                    </tbody>
                </table>
            </div>
            <div className="mb-3">
                <div className="mb-2">
                    <b>PLANT & EQUIPMENT</b>                
                </div>
                <table className='table table-bordered mb-2'>                    
                    <tbody className='vertical-align-middle'>
                        <PlantAndEquipment />
                    </tbody>
                </table>
                <div className="mb-3">
                <div className='form-check'>
                    <input className="form-check-input" type="checkbox" id="reviewed" />
                    <label className="form-check-label" htmlFor="reviewed">
                    I agree that the change proposal is completed in its entirety <b className="bg-primary text-white">SUBMIT</b>
                    </label>
                </div>
                </div>
            </div>*/}
            </div>
        )
    }
}

export default JobInitiatorAndExecutor;