import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import ExistingTransferredTable from "./Step01/ExistingTransferredTable";
import { ManpowerChangeRequirement } from "../CommonComponents/MiscellaneousObject";
import $ from "jquery"
import { API_HOST } from "../../Settings";
import axios from 'axios';
import notify from '../CommonComponents/Notify';
import SideMenu from '../CommonComponents/SideMenu'
import ManpowerStageComponents from './AllManpowerStageComponents';
import { Collapse } from 'bootstrap';
import { IoIosArrowDropdown , IoIosArrowDropup} from "react-icons/io";
import SubmitIcon from '../../images/submittedIcon.svg'
import ApprovedIcon from '../../images/approvedIcon.svg'
import PendingIcon from '../../images/pendingIcon.svg'
import RejectedIcon from '../../images/rejectedIcon.svg'
import {BsFileEarmarkPdf} from "react-icons/bs";
import ErrorHandling from '../ErrorHandler'

class ViewManpowerRequest extends Component {  
    constructor(props) {
        super(props)
        this.state =
        {
            requestID: this.props.match.params.requestID,
            toggle:false,
            currentStage:{},
            workflowData:[],
            collapseStags:{},
            dynamicompenents:[],
            unique_stage_id: '1',
            unique_comp_id: '1',
            unique_combine: 'mans1c1',
            has_pending_ask_more_request_for_sidemenu:false
        }
    }

    componentDidMount(){
        document.querySelector(".notification-Block")?.classList.add("d-none")
        document.querySelector(".user-profile")?.classList.add("d-none")
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/workflow/manpower/request/${this.state.requestID}/`, )
        .then(response => {
            if (response.status === 200) {
                // if(response.data.data.access_denied){
                //     notify('Access denied...','warning')
                // }
                var stage = {}
                var current_stage_name_slug = null
                if(response.data.data.current_stage){
                    current_stage_name_slug = response.data.data.current_stage.name_slug
                }
                var collapseStages = response.data.data.workflow_stages_data_statuses.map((stage_data,index)=>{                    
                    // return stage[stage_data.stage_name_slug] = false
                    if(current_stage_name_slug==stage_data.stage_name_slug){
                        return stage[stage_data.stage_name_slug] = false
                    }else{
                        return stage[stage_data.stage_name_slug] = true
                    }
                })
                this.setState({
                    currentStage: response.data.data.current_stage,
                    workflowData:response.data.data.workflow_stages_data_statuses,
                    has_pending_ask_more_request_for_sidemenu:response.data.data.has_pending_ask_more_request,
                    collapseStags:stage
                }, () => this.renderDynamicComponent())
                // this.renderDynamicComponent()
            }
            $('.loader').fadeOut('slow')
        }).catch(response => {
            ErrorHandling(response)
        });
    }

    updateSideMenu = (has_pending_ask_more_request_for_sidemenu)=>{
        this.setState({has_pending_ask_more_request_for_sidemenu:has_pending_ask_more_request_for_sidemenu})
    }

    handleCollapseToggle = (e) =>{
        e.preventDefault()
        var myCollapse = document.getElementById('collapseExample')
        var bsCollapse = new Collapse(myCollapse, {toggle: false})
        if(this.state.toggle){
            var toggle = false
            bsCollapse.hide()
        }else{
            bsCollapse.show();
            var toggle = true
        }
        this.setState({
            toggle:toggle
        })
    }

    handleStageCollapse = (e,stage_name_slug) =>{
        e.preventDefault()
        var myCollapse = document.getElementById(stage_name_slug)
        var bsCollapse = new Collapse(myCollapse, {toggle: false})
        if(this.state.collapseStags[stage_name_slug]){
            var toggle = false
            bsCollapse.hide()
        }else{
            bsCollapse.show();
            var toggle = true
        }
        var Datastate = this.state.collapseStags
        Datastate[stage_name_slug] = toggle
        this.setState({collapseStags:Datastate},()=>this.renderDynamicComponent())    
        // this.renderDynamicComponent()
    }



    renderDynamicComponent = (data) =>{
        var dynamicompenents = this.state.workflowData.map((stageData,index)=>{
            // var ReactComponent = ManpowerStageComponents[stageData.stage_name]
            var stage_status = stageData.status
            var display_status =  stage_status && stage_status[0].toUpperCase() + stage_status.slice(1);
            var ReactComponents = stageData.react_components.map((react_component, idx)=>{
                return ManpowerStageComponents[react_component]
            })
            let button=[];
            if(display_status=="Rejected"){
                button.push(<span className="rejected-btn p-1"><img className='p-2 ps-0' src={RejectedIcon}/>{display_status} </span>)
            }
            else if(display_status=="Approved"){
                button.push(<span className="approved-btn">
                    <img className='' src={ApprovedIcon}/>
                    <span className='position-relative ms-1' style={{top:"1px"}}>{display_status}</span>
                 </span>)
            }
            else if(display_status=="Pending"){
                button.push(<span className="pending-btn p-1"><img className='p-2 ps-0' src={PendingIcon}/>{display_status} </span>)
            }
            if (data == false) {
                this.state.collapseStags[stageData.stage_name_slug] = false
            }
            return(
                <div className="card mb-3 butterfly-card" id={stageData.comp}>
                    <div className="card-header stage-card-header border-bottom" onClick={e => this.handleStageCollapse(e,stageData.stage_name_slug)}>
                        {/* <p className='stage-header'> */}
                            <div className=''>
                                <span className='stage-title'>{stageData.stage_name}</span>
                            </div>
                            <div className='text-right'>
                                {['initiator-of-manpower-change'].includes(stageData.stage_name_slug)  ?
                                        stageData.manpower_request_status=='Save as Draft'?
                                            <span className="submit-btn"><i className="fa fa-edit p-2 fa-lg"></i>Drafted</span>
                                        :<span className="submit-btn"><img className='p-2 ps-0' src={SubmitIcon}/><span className='position-relative' style={{top:"2px"}}>Submitted</span></span>
                                    :button
                                }
                            </div>
                            <div className=''>
                                <span  className='arrow-icon-span'>
                                    {this.state.collapseStags[stageData.stage_name_slug]==false?<IoIosArrowDropup/>:<IoIosArrowDropdown/>}    
                                </span>
                            </div>
                    </div>
                    <div className= {this.state.collapseStags[stageData.stage_name_slug]==false?"card-body collapse show":"card-body collapse"} id={stageData.stage_name_slug}>
                        <div className="card-loader" ><div className="dot-elastic"></div></div>  
                        { this.state.collapseStags[stageData.stage_name_slug]==false?
                            ReactComponents.map((ReactComponent, idd)=>{
                               return <ReactComponent  isCollapased = {false}
                                        DocumentNumber={this.state.requestID}
                                        updateSideMenu={this.updateSideMenu}
                                        StageName={stageData.stage_name_slug}/>
                            })
                        :""}
                    </div>
                </div>
            )
        })

        this.setState({
            dynamicompenents:dynamicompenents
        })

    }
    handelDownloadMocPdf() {
        notify('Loading Preview please wait!','success') 
        this.renderDynamicComponent(false);       
        setTimeout(() => window.print(),7000);
    }

    render() {
        // console.log(this.state)
        var currentStageComponents = []
        if(this.state.currentStage && this.state.currentStage.components){
            currentStageComponents = this.state.currentStage.components.map((component,index)=>{
                var ReactComponent = ManpowerStageComponents[component.react_component.name]
                return(<ReactComponent WorkflowData={this.state.currentStage} WorkflowComponent={component} key={this.state.unique_combine+"current"+index} DocumentNumber={this.state.requestID}/>)
            })
        }
        return (
            <div className="container manpower-container" key={this.state.unique_combine}>
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-12 side-nav-block">
                        <div className="card sticky-card">
                            <div className="card-body sticky-body">
                            {(this.state.currentStage && this.state.currentStage.name_slug) ?
                                <SideMenu key={'rand_'+this.state.currentStage.name_slug}
                                    workflowData={this.state.workflowData}
                                    activeNameSlug={this.state.currentStage.name_slug}
                                    has_pending_ask_more_request_for_sidemenu={this.state.has_pending_ask_more_request_for_sidemenu}
                                    RequestType="manpower-request"/>
                                : <React.Fragment> <SideMenu key={'rand_'}
                                    has_pending_ask_more_request_for_sidemenu={this.state.has_pending_ask_more_request_for_sidemenu}
                                    activeNameSlug=""
                                    workflowData={this.state.workflowData}
                                    RequestType="manpower-request"/>                                    
                                    {this.state.currentStage == "OPEN" ? null : <button className='btn download-btn' onClick={()=>this.handelDownloadMocPdf() }>                                        
                                        <span className='font-14'>Download</span>
                                        <span className='text-danger font-18'><BsFileEarmarkPdf /> </span>
                                    </button> }
                                    </React.Fragment>
                            }
                            </div>
                        </div>
                    </div> 
                    <div className="col-lg-9 col-md-9 col-sm-12 main-contant-block">
                        <div className="mt-3 mb-2">
                            <h5 className="green-title-color">
                                <b># {this.state.requestID}&nbsp;-&nbsp;{this.state.currentStage ? "OPEN": "CLOSED"}</b>
                            </h5>
                        </div>
                        {this.state.dynamicompenents.map((item, index) => {
                                return <div key={this.state.unique_combine+index}>{item}</div>;
                            })}

                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ViewManpowerRequest);