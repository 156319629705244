import React from 'react';

const DashboardPagination = ({ pagination, onPageChange }) => {
    const { currentPage, totalPages } = pagination;
    if (totalPages <= 1) return null;
    let pages = [];
    const maxVisiblePages = 5;

    let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
    let endPage = Math.min(currentPage + Math.floor(maxVisiblePages / 2), totalPages);

    if (endPage - startPage < maxVisiblePages - 1) {
        if (currentPage < totalPages / 2) {
            endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);
        } else {
            startPage = Math.max(endPage - maxVisiblePages + 1, 1);
        }
    }

    if (startPage > 1) {
        pages.push(1);
        if (startPage > 2) pages.push('...');
    }
    for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
    }
    if (endPage < totalPages) {
        if (endPage < totalPages - 1) pages.push('...');
        pages.push(totalPages);
    }

    return (
        <div className="dashboard-pagination">
            <button disabled={currentPage === 1} onClick={() => onPageChange(1)}>
                &lt;&lt;
            </button>
            <button disabled={currentPage === 1} onClick={() => onPageChange(currentPage - 1)}>
                &lt;
            </button>
            {pages.map((page, index) => (
                <button
                    key={index}
                    className={page === currentPage ? "active" : ""}
                    onClick={() => {
                        if (page !== '...') onPageChange(page);
                    }}
                >
                    {page}
                </button>
            ))}
            <button disabled={currentPage === totalPages} onClick={() => onPageChange(currentPage + 1)}>
                &gt;
            </button>
            <button disabled={currentPage === totalPages} onClick={() => onPageChange(totalPages)}>
                &gt;&gt;
            </button>
        </div>
    );
};

export default DashboardPagination;
