import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaSpinner, FaUser, FaUsers, FaClock } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { IoEllipse } from "react-icons/io5";
import {TfiArrowCircleRight} from "react-icons/tfi";
import { API_HOST } from "../../Settings"
import axios from 'axios';
import '../../Styles/brioStyle.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import notify from '../CommonComponents/Notify';
import $ from 'jquery';


class DashboardRequest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isModalOpen: false,
            selectedUser: { documentNumber: '', name: '' ,workflow:'',current_stage_name:'',email:''},
       }
    }
    handleClose = () => {
        this.setState({ isModalOpen: false });
    };
    handleNameClick = (documentNumber, name, workflow,current_stage_name,email) => {
        this.setState({ 
          selectedUser: { documentNumber, name, workflow, current_stage_name,email },
          isModalOpen: true,
        });
      };
    
      handleSendEmail = (document_number,name,workflow,current_stage_info,email) => {
        this.handleClose();
        var Data = {}
        Data["document_number"] = document_number
        Data["name"] = name
        Data["workflow"] = workflow
        Data["current_stage_info"] = current_stage_info
        Data['email'] = email
        const requestData = {
            userData: Data,
        };
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token');
        axios.post( `${API_HOST}/send/email/to/user/`,requestData)
        .then(response => {
          if (response.data.status === 200 ) {
            notify(response.data.message,'success');
          }
        else{
          notify(response.data.message,'error')
        }
        })
        .catch(response => {
            // ErrorHandling(response)
        });

      };
    
      handleCancel = () => {
        this.setState({ isModalOpen: false });
      };
    render(){
        const dashboard_data = this.props.thisData.filterRequestData.map((data,indx)=>{
            var url;
            if(data.type === 'MOC'){            
                url = "/moc/request/"+data.document_number+"/"
            }else{
                url = "/manpower/change/request/"+data.document_number+"/"
            }
            return (
                <div className='border-bottom row align-items-center dashboard-request-list pt-3' key={"dashboard"+indx}>
                <div className='col-lg-8 col-md-6 col-12'>
                    <div className='d-flex'>
                        <div className='me-2' title={data.request}>
                            <Link to={url}>
                            # {data.document_number} {data.current_stage_name? <span className='text-info' style={{"fontSize": "15px"}}> ( {data.current_stage_name} ) </span> :null}
                            </Link>
                        </div>
                        {/*<div className='me-4 lightgray-color font-12'>{data.type}</div>
                        <div className='me-4 lightgray-color font-12 text-capitalize'>{data.status}</div>*/}
                    </div>
                    <div className='secoundary-text pt-2'>
                        <div>
                            <FaUser className='userIcon2'/> <span>{data.user_name}</span><IoEllipse className='ellipseIcon'/> <span>{data.date_of_moc}</span> to <span>{data.date_of_moc}</span>
                        </div>
                    </div>
                    {data.status==="Closed"?
                        <div className='secoundary-text pt-2'>
                            <div>
                            <FaClock className='clockIcon2'/> <span className='text-secondary'>TAT : {data.turn_around_time.total_days} days</span><IoEllipse className='ellipseIcon'/><span className='text-secondary'>Approval Time : {data.turn_around_time.approval_days} days</span><IoEllipse className='ellipseIcon'/><span className='text-secondary'>Execution Time : {data.turn_around_time.execution_days} days</span>
                            </div>
                        </div>
                    :null}
                    {data.status === 'Open' ? (
                        <div className='secoundary-text pt-2'>
                            <FaUsers size={18} className='userIcon23' style={{ marginRight: '2px' }} />
                            <span>
                                {data.stage_approvers && data.stage_approvers.length > 0 ? (
                                    data.stage_approvers.map((approver, index) => (
                                        <React.Fragment key={index}>
                                            {index > 0 && <IoEllipse className='ellipseIcon' style={{ margin: '0 5px' }} />}
                                            <span
                                                onClick={() => this.handleNameClick(
                                                    data.document_number,
                                                    approver.name,
                                                    data.type,
                                                    data.current_stage_name,
                                                    approver.email
                                                )}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {approver.name}
                                            </span>
                                            {(index + 1) % 4 === 0 && (index + 1 !== data.stage_approvers.length) && <br />}
                                        </React.Fragment>
                                    ))
                                ) : (
                                    <span style={{ color: 'lightcoral', marginLeft: '2px' }}>No users</span>
                                )}
                            </span>
                        </div>
                      ) : null}


                <div className='d-flex secoundary-text pt-2 pb-2 secoundary-text-color'>
                        {/*<div className='lightgray-color me-4'>Submitted - {data.date_of_moc}</div>*/}
                        {/* <div className='lightgray-color'>Closed-14 Mar 2022</div> */}
                        <MdLocationOn  className='locIcon'/> <span>{data.site_name}</span><IoEllipse className='ellipseIcon'/> <span>Product - Enzip</span><IoEllipse className='ellipseIcon'/> <span>Department - {data.department_name}</span>
                    </div>
                </div>

                <div className='col-lg-4 col-md-6 col-12 d-flex status-div align-items-center'>
                    <div className='lightgray-color font-12 type-div'>
                       {(data.type) === "MOC" ? <span className="badge rounded-pill bg-light text-dark moc-btn">MoC</span>:null}
                       {(data.type) === "Manpower" ? <span className="badge rounded-pill bg-light text-dark manpower-btn">{data.type}</span>:null}
                    </div>
                    <div className='lightgray-color font-12 text-capitalize'>
                        {(data.status) === "Rejected" ?<span className="open rounded-pill bg-light text-dark reject-btn">{data.status}</span>:null}
                        {(data.status) === "Open" ?<span className="open rounded-pill bg-light text-dark open-btn">{data.status}</span>:null}
                        {!["Open", "Rejected"].includes(data.status)?<span className="open rounded-pill bg-light text-dark open-btn">{data.status}</span>:null}
                    </div>
                    <div className='dashboard-circle float-right'>
                        <Link to={url}>
                            <TfiArrowCircleRight />
                        </Link>
                    </div>
                </div>
                
            </div> 
            )
        })
        return (
            <>
              <div className='dashboard-request'>
                <div className='dashboard-request-block ps-5 mt-3'>
                  {dashboard_data.length > 0 ? dashboard_data : (
                    <div>
                      <span style={{ fontSize: '15px', color: 'lightgray' }}>No requests Found</span>
                    </div>
                  )}
                </div>
      
                <Modal show={this.state.isModalOpen} onHide={this.handleCancel}>
                  <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Are you sure you want to send this stage ({this.state.selectedUser.current_stage_name})  email to {this.state.selectedUser.name}?
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleCancel}>
                      Cancel
                    </Button>
                    <Button variant="danger" onClick={() => this.handleSendEmail(this.state.selectedUser.documentNumber,this.state.selectedUser.name,this.state.selectedUser.workflow,this.state.selectedUser.current_stage_name,this.state.selectedUser.email)}>
                      Send
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </>
          );
    }
}

export default DashboardRequest;

