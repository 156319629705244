import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import DashboardLeftStatus from './DashboardLeftStatus';
import DashboardRequest from './DashboardRequest';
import PageHeader from '../Dashboard/PageHeader.js';
import $ from 'jquery'
import { API_HOST } from "../../Settings"
import axios from 'axios';
import notify from '../CommonComponents/Notify';
import DashboardPagination from './DashboardPagination';



class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mocRequestData: [],
            tab: "",
            filterRequestData: [],
            is_admin: false,
            data_fetched: true,
            current_workflow: "",
            username: '',
            can_view_moc: false,
            can_view_manpower: false,
            can_view_ibd: false,
            status:[
                {status: "opened", "count": 0},
                {status: "rejected", "count": 0},
                {status: "closed", "count": 0},
            ],
            pagination: {
                currentPage: 1,
                totalPages: 1,
                pageSize: 10,
                totalCount: 0
            }
        }
    }

    componentDidMount() {
        this.initializeDashboard();
    }
    
    initializeDashboard = (page=1, shouldUpdateCounts = true) => {
        $('.loader').fadeIn();
        document.querySelector(".notification-Block")?.classList.add("d-none");
        document.querySelector(".user-profile")?.classList.add("d-none");
    
        const urlParams = new URLSearchParams(this.props.location.search);
        let workflow = urlParams.get('workflow') || localStorage.getItem('workflow');
        const { pagination } = this.state;
    
        if (workflow) {
            localStorage.setItem('workflow', workflow);
        } else {
            workflow = '';
        }

        let search_text = document.querySelector("#search_text")?.value || "";
        let status = [];
        document.querySelectorAll('input[type=checkbox]:checked').forEach(checkbox => {
            status.push(checkbox.value);
        });
    
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token');
        axios.get(`${API_HOST}/requests/all/?workflow=${workflow}&status=${status}&search_text=${search_text}&page=${page}&limit=${pagination.pageSize}`)
            .then(response => {
                if (response.status === 200) {
                    console.log(response.data,'dashboard_responses')
                    console.log(response.data.total_count,'dashboard_responses_total_count')
                    this.setState({
                        mocRequestData: response.data.all_requests,
                        filterRequestData: response.data.all_requests,
                        data_fetched: false,
                        tab: workflow?.toLowerCase() || "",
                        current_workflow: workflow,
                        pagination: {
                            ...pagination,
                            totalCount: response.data.total_count,
                            currentPage: page,
                            totalPages: Math.ceil(response.data.total_count / pagination.pageSize),
                        },
                    });
                    if (shouldUpdateCounts) {
                        this.countRequests(workflow.toLowerCase());
                    }
                    // this.props.history.replace({
                    //     pathname: this.props.location.pathname,
                    //     search: '',
                    // });
                    $('.loader').fadeOut('slow');
                }
            }).catch(response => {
                if (response.response.status === 401) {
                    // localStorage.clear()
                }
                // $('.loader').fadeOut('slow');
                // ErrorHandling(response)
            });
    }

    componentDidUpdate(prevProps, prevState) {
        console.log('component excute');
        const workflow = localStorage.getItem('workflow')
        const roles = JSON.parse(localStorage.getItem('roles'));
        if (workflow != null && roles && (roles.length === 0 || !roles.includes(`view-${workflow}`))) {
            const auth_token = localStorage.getItem("token");
            const userData = localStorage.getItem("user_data");
            const logoutEndpoint = '/logout/';
            axios.defaults.headers.common['Authorization'] = auth_token;
            axios.get(API_HOST + logoutEndpoint, {
                params: {
                    user_data: userData
                }
            }).then(response => {
                if (response.status === 200) {
                    localStorage.clear();
                    notify("You don't have permission to view.", 'error');
                    this.props.history.push("/login");
                    return;
                }
            });
        } else {
            debugger
            if (workflow && workflow.toLowerCase() !== this.state.tab) {
                console.log(workflow, this.state.tab)
                this.setState({
                    tab: workflow.toLowerCase(),
                    current_workflow: workflow
                });
                this.changeTabHandler(workflow.toLowerCase())
                this.countRequests(workflow.toLowerCase())
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    search: '',
                });
                $('.loader').fadeOut('slow');
            }
        }
    }


    changeTabHandler = (tab) => {
        this.setState({ tab: tab.toLowerCase() }, () => {
            this.initializeDashboard();
        });
    };
    
   
    handlePageChange = (page) => {
        this.initializeDashboard(page,false)
    };

    countRequests =(workflow) =>{
        const roles = JSON.parse(localStorage.getItem('roles'))
        let matchingRoles = [];
        if (roles && Array.isArray(roles)) {
            if (roles.includes('moc-admin')) {
                matchingRoles.push('moc-admin');
            }
            if (roles.includes('manpower-admin')) {
                matchingRoles.push('manpower-admin');
            }
        }
        const rolesParam = JSON.stringify(matchingRoles);
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token');
        axios.get(`${API_HOST}/requests/count/?roles=${rolesParam}&workflow=${workflow}`,)
        .then(response => {
            if (response.status === 200) {
              var count_data = response.data.data;
              var updatedStatus = this.state.status.map(item => ({
                ...item,
                count: count_data[item.status] || 0
              }));
              this.setState({ status: updatedStatus });
            }
          })
          .catch(error => {
          });
    }
    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            var search_text = document.querySelector("#search_text").value
            if(search_text.length >= 3){
                this.props.clickTabHandler(event);
               
            }
            notify("Minimum three characters are required for search request",'warning')
        }
    }
    clickTabHandler = (e) => {
        e.preventDefault()
        const workflow = localStorage.getItem('workflow');
        var search_text = document.querySelector("#search_text").value
        var status = []
        if(search_text.length < 3 && search_text.length !== 0){
            notify("Minimum three characters are required for search request",'warning')
            return false
           
        }
        $('.loader').fadeIn();
        var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')
        for (var i = 0; i < checkboxes.length; i++) {
            status.push(checkboxes[i].value)
        }
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/requests/all/?workflow=${workflow}&status=${status}&search_text=${search_text}`,)
            .then(response => {
                if (response.status === 200) {
                    const total_count = response.data.total_count;
                    var status = response.data.search_status
                    var filtrequestData = response.data.all_requests
                    if (this.state.tab === 'moc') {
                        let count_data = response.data.workflow_status_counts;
                        let updatedStatus = this.state.status.map(item => ({
                            ...item,
                            count: count_data[item.status] || 0
                        }));
                        var tabfiltrequestDataMoc = filtrequestData.filter((requestObj, index) => {
                            return requestObj.type === 'MOC';
                        })
                        this.setState({
                            mocRequestData: response.data.all_requests,
                            filterRequestData: tabfiltrequestDataMoc,
                            status: updatedStatus,
                            data_fetched: false,
                            tab: workflow?.toLowerCase() || "",
                            current_workflow: workflow,
                            pagination: {
                                ...this.state.pagination,
                                totalCount: total_count,
                                currentPage: 1,
                                totalPages: Math.ceil(total_count / this.state.pagination.pageSize),
                            }

                        })

                    } else if (this.state.tab === 'manpower') {
                        let count_data = response.data.workflow_status_counts;
                        let updatedStatus = this.state.status.map(item => ({
                            ...item,
                            count: count_data[item.status] || 0
                        }));
                        var tabfiltrequestDataManPwr = filtrequestData.filter((requestObj, index) => {
                            return requestObj.type === 'Manpower';
                        })
                        this.setState({
                            mocRequestData: response.data.all_requests,
                            filterRequestData: tabfiltrequestDataManPwr,
                            status: updatedStatus,
                            data_fetched: false,
                            tab: workflow?.toLowerCase() || "",
                            current_workflow: workflow,
                            pagination: {
                                ...this.state.pagination,
                                totalCount: total_count,
                                currentPage: 1,
                                totalPages: Math.ceil(total_count / this.state.pagination.pageSize),
                            }
                        })

                    }
                    $('.loader').fadeOut('slow');
                    var status_list = ["opened", "rejected", "closed"]
                    for (var j = 0; j < status_list.length; j++) {
                        if (status.includes(status_list[j])) {
                            $("#" + status_list[j]).prop("checked", true)
                        } else {
                            $("#" + status_list[j]).prop("checked", false)
                        }
                    }
                }
            }).catch(response => {
                // ErrorHandling(response)
            });
    }

    render() {
        const { data_fetched, tab } = this.state;

        if (data_fetched) {
            return "";
        }
        return (
            <React.Fragment>
                <div className='container dashboard-container'>
                    <PageHeader thisData={tab} clickTabHandler={this.clickTabHandler} />
                    <div className='row margin-top-10px main-dashboard'>
                        <div className='col-lg-2 col-md-2 col-12'>
                            <DashboardLeftStatus thisData={this.state} clickTabHandler={this.clickTabHandler} />
                        </div>
                        <div className='col-lg-10 col-md-10 col-12 mt-2'>
                            <DashboardRequest thisData={this.state} changeTabHandler={this.changeTabHandler} />
                            {this.state.pagination.totalCount > 0 && (
                                <DashboardPagination pagination={this.state.pagination} onPageChange={this.handlePageChange} />
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default withRouter(Dashboard);