import React, { Component } from 'react';
import CommonToggle from '../../CommonComponents/CommonToggle';
import Toggle from '../../CommonComponents/Toggle';
import $ from "jquery";


class ExistingTransferredTable extends Component{
    constructor() {
        super();
        this.state = {
            closedDate:false
        }
    }


    componentDidMount(){   
        /*$('.targetDate').datepicker({
            autoclose: true,
            format: "dd/mm/yyyy",
            immediateUpdates: true,
            todayBtn: true,
            todayHighlight: true
        })*/
    }

    componentDidUpdate(prevProps, prevState){
        /*if(this.props.requestFrom == "view"){
            $('.targetDate').datepicker('update')
        }else{
            $('.targetDate').datepicker({
                autoclose: true,
                format: "dd/mm/yyyy",
                immediateUpdates: true,
                todayBtn: true,
                todayHighlight: true
            })
        }*/
    }
    /*handleTargetDestroy(e, target) {
        var $dates = $(document.getElementById(target)).datepicker();
        $dates.datepicker('setDate', null);
        this.setState({
            closedDate:true
        })
    }*/
    handleTargetDestroy(e, target) {
        $(document.getElementById(target)).val('');
        /*this.setState({
            closedDate:true
        })*/
    }

    handleKeyDownDate = (event)=>{
        event.preventDefault()
    }

    render(){
        var common_min_date_items = this.props.common_min_date_items
        var tr_content = this.props.ManpowerChangeRequirementList.map((row,key) => {
            let user_data = this.props.UsersList.map((userData,indx)=>{
                return(
                    <option key={'man_s1c1_exit_trans_'+indx} value={userData.email} selected={row.responsibility == userData.email?true:false}>{userData.name}</option>
                )
            })
            let targetId= row.name_slug;
         return(
                    <tr key={key}>
                        <td>{key +1}</td>
                        <td>{row.name}</td>
                        <td>
                            <Toggle status={row.rowAction.status}
                             requestFrom="Manpower" index={key} actionType="rowAction"
                            changeActionStatus={this.props.changeActionStatus}
                            accessType={this.props.accessType}
                            /></td>
                        <td>
                        <select className= {"form-select "+ row.name_slug}  id="Responsibility" name="Responsibility" 
                        onChange={(e)=>this.props.changeResponsibility(e,key)} disabled={this.props.accessType == 'view' ? true:false}>
                            <option disabled={true} selected={row.responsibility == ""?true:false}>Open this select user</option>
                            {user_data}
                        </select>
                        </td>
                        {/*<td>
                        {this.props.accessType =="view"?
                            <input className='form-control targetDate' name="targetDate" id={row.name_slug} value={row.target_date} disabled/>:
                            <div className='d-flex position-relative align-items-center targetDate-block'>
                               {this.props.accessType =="create" ? <input className='form-control targetDate' name="targetDate" id={targetId}/> : row.target_date && !this.state.closedDate ? <input className='form-control targetDate' name="targetDate" id={targetId} value={row.target_date}/>  : <input className='form-control targetDate' name="targetDate" id={targetId} />}
                                <span className='position-absolute target-date-close'id={targetId} onClick={(e) =>this.handleTargetDestroy(e,row.name_slug)}>X</span>
                            </div>                            
                            }
                        </td>*/}
                        <td>
                        {this.props.accessType =="view"?
                            <input className='form-control targetDate' name="targetDate"
                                autoComplete='off' type="date"
                                id={row.name_slug} value={row.target_date} disabled={true}/>
                            :
                            <div className='d-flex position-relative align-items-center targetDate-block'>
                               {this.props.accessType =="create" ?
                                    <input className='form-control targetDate' onKeyDown={this.handleKeyDownDate} min={common_min_date_items} type="date" name="targetDate" id={targetId}
                                        autoComplete='off'/>
                                    :
                                    row.target_date && !this.state.closedDate ?
                                        <input className='form-control targetDate' min={common_min_date_items} type="date" name="targetDate" id={targetId}
                                            type="date" onKeyDown={this.handleKeyDownDate}
                                            defaultValue={row.target_date} autoComplete='off' />
                                        :
                                        <input className='form-control targetDate' min={common_min_date_items}
                                            onKeyDown={this.handleKeyDownDate}
                                            type="date" name="targetDate" id={targetId} />
                                }
                                <span className='position-absolute target-date-close'id={targetId} onClick={(e) =>this.handleTargetDestroy(e,row.name_slug)}>X</span>
                            </div>                            
                            }
                        </td>
                        <td>
                        <CommonToggle 
                                firstVal="Open" secondVal="Closed" requestFrom="Manpower"
                                status={row.rowStatus.status} index={key} actionType="rowStatus"
                                changeActionStatus={this.props.changeActionStatus} accessType={this.props.accessType}
                        />
                        </td>
                    </tr>        
            )
        })
        // if(this.props.manPowerState.manpowerStatus.statusName == "Existing"){
        //     var count = 0
        //     var tr_content = this.props.ManpowerChangeRequirementList.map((row,key) => {
        //         if(row.type == "Existing"){
        //             count = count + 1
        //             let user_data = this.props.UsersList.map((userData,indx)=>{
        //                 return(
        //                     <option value={userData.email}>{userData.first_name} {userData.last_name}</option>
        //                 )
        //             })
        //         return(
        //                     <tr key={key}>
        //                         <td>{count}</td>
        //                         <td>{row.name}</td>
        //                         <td>
        //                             <Toggle status={row.rowAction.status} 
        //                                 requestFrom="Manpower" index={key} 
        //                                 actionType="rowAction"
        //                                 changeActionStatus={this.props.changeActionStatus} /></td>
        //                         <td>
        //                         <select className={"form-select "+ row.name_slug}  id="Responsibility" name="Responsibility">
        //                             <option disabled={true} selected>Open this select user</option>
        //                             {user_data}
        //                         </select>
        //                         </td>
        //                         <td>
        //                             <input className='form-control targetDate' name="targetDate" id={row.name_slug}/>
        //                         </td>
        //                         <td>
        //                             <CommonToggle firstVal="Open" secondVal="Closed" requestFrom="Manpower"
        //                             status={row.rowStatus.status} index={key} actionType="rowStatus"
        //                             changeActionStatus={this.props.changeActionStatus}
        //                             />
        //                         </td>
        //                     </tr>        
        //             )

        //         }   
                
        //     })

        // }else{
            

        // }
        return(
            <div className='card'>
                <div className='card-body overflow-x-scroll existing-transferred-print-table'>
                    <table className='table table-bordered caption-top'>
                    <caption>
                        <CommonToggle firstVal="New" secondVal="Existing" 
                            manpowerStatusChange={this.props.manpowerStatusChange} 
                            toggleName="NewExistingChange"
                            status={this.props.manPowerState.manpowerStatus.status}
                            accessType={this.props.accessType}
                        />
                    </caption>

                        <thead className='vertical-align-middle'>
                            <th width="5%">
                                <b>#</b> 
                            </th>
                            <th className='text-center' width="30%"><b>Requirement</b></th>
                            <th className='text-center' width="15%"> <b>Action Required?</b></th>
                            <th className='text-center' width="20%"> <b>Responsibility</b></th>
                            <th className='text-center' width="20%">Target Date</th>  
                            <th className='text-center' width="10%">Status</th>                                     
                        </thead>
                        <tbody className='vertical-align-middle'>
                            {tr_content}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="6">
                                Note : Include any specific training/competence is required for safely run the plant as per Standard procedure
                                </td>
                            </tr>

                        </tfoot>
                    </table>
                </div>
            </div>

        )
    }
}

export default ExistingTransferredTable;