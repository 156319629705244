import React, { Component } from "react";
import ThreeToggle from "../../CommonComponents/ThreeToggle";
import Sign from "../../ConsentComponents/Sign";
import $ from 'jquery'
import axios from 'axios';
import notify from "../../CommonComponents/Notify";
import { API_HOST } from "../../../Settings";

class ReviewByHod extends Component {
    constructor(props) {
        super(props)
        this.state = {
            firstVal : "Yes",
            secondVal :"No",
            thirdVal: "N/A",
            aspect_register_status: "N/A",
            legal_register_status: "N/A",
            hod_review: "",
            isHodFormSubmited:false,
            stage_status:this.props.stage_status,
            iagree_two:false,
        }
    }

    componentDidMount(){
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/and/save/execution/hod/${this.props.DocumentNumber}/data`, )
        .then(response => {
            if (response.status === 200) {
                let execution_hod_data = response.data.execution_hod_data
                this.props.handleHodFormSubmitStatus(response.data.is_submited)
                this.setState({
                    isHodFormSubmited: response.data.is_submited,
                    aspect_register_status: execution_hod_data.aspect_register_status,
                    legal_register_status: execution_hod_data.legal_register_status,
                    hod_review: execution_hod_data.hod_review,
                })
            }
        }).catch(response => {
            // ErrorHandling(response)
        });
        $('.card-loader').fadeOut('slow')
    }

    handlesetState = () => {
        
    }

    handleReviewChecks = (e, name, toggle_name) => {
        this.setState({
            [toggle_name]:name
        })
    }
    handleOnChange = (e) =>{
        this.setState({[e.target.name]:e.target.value})
    }

    handleIagreeTwo = (e)=>{
        if(e.target.type=='checkbox'){
            this.setState({"iagree_two": e.target.checked})
        }
        
    }

    isValid = ()=>{
        var name_regexp = new RegExp("<(?:\"[^\"]*\"['\"]*|'[^']*'['\"]*|[^'\">])+>");
        if((this.state.hod_review&&name_regexp.test(this.state.hod_review))){
            return {"status": false, "reason": "Please enter valid information in input fields.", 'id': "hod_review"}
        }
        return {"status": true}
    }

    submitHODReview = (e)=>{
        e.preventDefault()
        var is_valid_response = this.isValid()
        if(!is_valid_response.status){
            notify(is_valid_response.reason, 'warning')
            return false
        }
        var msg = "Are you sure, you want submit request?"
        if(window.confirm(msg)){
            var execution_hod_data = this.state
            $('.loader').fadeIn()
            const auth_token = localStorage.getItem("token")
            axios.defaults.headers.post['Authorization'] = auth_token
            axios.post(`${API_HOST}/get/and/save/execution/hod/${this.props.DocumentNumber}/data`, execution_hod_data)
            .then(response => {
                if (response.status === 200 && response.data.success) {
                    notify(response.data.message,'success')
                    this.props.handleHodFormSubmitStatus(true)
                    this.setState({isHodFormSubmited:true})
                    // setTimeout(() =>{
                    //     // $('#approve-as-variant-model').modal('hide');
                    //     this.props.history.push('/moc/request/'+response.data.document_number+'/')
                    // },1000)
                }else{

                }
            }).catch(response => {
                // ErrorHandling(response)
            });
            $('.loader').fadeOut('slow')
        }
    }
    render() {
        return (
            <div>
            <div className="">
                <h5 className="card-title mb-3"><b>Review by HOD – Initiator / EHS / QA</b></h5>
                    <div className='mt-3'>
                        <div className='pb-2'>Activity Included in Aspect Register</div>
                        <ThreeToggle Status={this.state.aspect_register_status == '' ? 'N/A' :  this.state.aspect_register_status} 
                            firstVal={this.state.firstVal} 
                            secondVal={this.state.secondVal} thirdVal={this.state.thirdVal} 
                            HandleReviewChecks={this.handleReviewChecks} RequestType="changeReview" ToggleName="aspect_register_status" DataSubmited={this.state.isHodFormSubmited}/>
                    </div>
                    <div className='mt-3'>
                        <div className='pb-2'>Activity Included in HIRA/HAZOP/Legal Register</div>
                        <ThreeToggle Status={this.state.legal_register_status == '' ? 'N/A' :  this.state.legal_register_status} firstVal={this.state.firstVal} 
                            secondVal={this.state.secondVal} thirdVal={this.state.thirdVal} 
                            HandleReviewChecks={this.handleReviewChecks} RequestType="changeReview" ToggleName="legal_register_status" DataSubmited={this.state.isHodFormSubmited} />
                    </div>
                    <div className="mb-3 mt-3">
                        <div className="mb-2">
                            <b>I/We have reviewed the installed modification and agree it is safe for commissioning checkbox
                                Initiator / Executor HOD</b>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <h5 className='pb-2'><b>Initiator / Executor HOD </b></h5>
                        <textarea className='form-control' rows="3" name="hod_review" id="hod_review" type="text" value={this.state.hod_review} onChange={(e)=> this.handleOnChange(e)} disabled={this.state.isHodFormSubmited ? true:false}></textarea>
                        <div className="pt-2">Review the Performance (Ensure completion of recommendations given by Team)</div>
                    </div>
            </div>
            {this.props.isDataSubmited && !this.state.isHodFormSubmited && !this.props.isRejected && this.props.userPermissions.can_create?
            <div className='card mb-3 execution-btn-card'>
                <div className='card-body'>
                    <div className="d-grid gap-2 d-md-flex">
                        <div className='form-check'>
                            <input className="form-check-input" type="checkbox" id="reviewed"
                                checked={this.state.iagree_two}
                                onChange={this.handleIagreeTwo} />
                            <label className="form-check-label" htmlFor="reviewed">
                            I agree that the change proposal is completed in its entirety
                            </label>
                        </div>
                        {this.state.iagree_two && this.props.userPermissions.can_create?
                            <button className="btn btn-dark" type="submit" id="submit"
                                onClick={(e) => this.submitHODReview(e)}>Submit
                            </button>:
                            <button className="btn btn-dark" type="submit" id="submit"
                                disabled={true}>Submit
                            </button>
                        }
                    </div>
                </div>
            </div> : ""}
            </div>
        )
    }
}

export default ReviewByHod;