import axios from 'axios';
import React, { Component } from 'react';
import { API_HOST } from '../../Settings';
import $ from 'jquery';
import notify from '../CommonComponents/Notify';


class ApproveButton extends Component{

    approveSubmitHandler = (e) =>{
        e.preventDefault()
        var user_resp = window.confirm("Are you sure, you want approve this request?")
        if(user_resp){
            var resp = true
            if(this.props.hasOwnProperty('handleOfComment')){
                resp = this.props.handleOfComment(e, false, "approve")
            }
            if(resp){
                $('.loader').fadeIn()
                var filter_approve_action = this.props.WorkflowComponent.workflow_actions.filter((actionData,indx)=>{
                    return actionData.action.name == 'Approved'
                })
                var approve_data = {            
                    workflow_actions_id:filter_approve_action[0].id,
                    action_id:filter_approve_action[0].action.id,
                        
                }        
                axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
                axios.post(`${API_HOST}/workflow/${this.props.RequestType}/request/${this.props.DocumentNumber}/action/save/`, approve_data)
                .then(response => {
                    if (response.status === 200) {
                        localStorage.setItem('current_component_name', this.props.current_component_name);
                        notify('Approved action completed, Please do sign also','success')
                        setTimeout(()=>{
                            $('.loader').fadeOut('slow')
                            window.location.reload();
                        }, 4000)
                    }

                }).catch(response => {
                    // ErrorHandling(response)
                });
            }
        }
    }

    render(){
        var pending_ask_more = false
        var reason_msg = ''
        if(['approved', 'rejected'].includes(this.props.stage_status)){
            reason_msg = "This stage already "+this.props.stage_status
        }
        else if(this.props.IsApproved){
            reason_msg = "This department already approved" 
        }
        var current_comp_ask_for_more_history = []
        if(this.props.ask_for_more_history){
            current_comp_ask_for_more_history = this.props.ask_for_more_history.filter((each, index)=>{
                    if(each.react_component.name==this.props.current_component_name){
                        return each.status.name_slug=="open"
                    }else{
                        return false
                    }
                })
        }
        if(current_comp_ask_for_more_history.length>0){
            pending_ask_more = true
            reason_msg = 'More information requested'
        }
        var pending_form_submission = true
        if(this.props.hasOwnProperty("form_submition_info")){
            var form_submition_info = this.props.form_submition_info
            if(form_submition_info.form_submition_required){
                pending_form_submission=true
                if(form_submition_info.is_required_forms_data_submitted){
                    pending_form_submission = false
                }else{
                    reason_msg = "Submit the form to enable approve"
                }

            }else{
                pending_form_submission=false
            }
        }else{
            pending_form_submission = false
        }

        var is_required_dependent_comp_approved = true
        if(this.props.hasOwnProperty('dependent_comp_info')){
            var dependent_comp_info = this.props.dependent_comp_info
            if(dependent_comp_info['dependent_comp_required']){
                if(!dependent_comp_info['is_required_dependent_comp_approved']){
                    is_required_dependent_comp_approved=false
                    reason_msg = "Pending dependent head approvals"
                }
            }
        }

        return(
            <div>
                {['approved', 'rejected'].includes(this.props.stage_status) || is_required_dependent_comp_approved==false || pending_form_submission==true || pending_ask_more==true || this.props.IsApproved ?
                        !this.props.userPermissions.can_approve || ['approved', 'rejected'].includes(this.props.stage_status) || this.props.IsApproved?null
                        :
                        <span title={reason_msg}>
                            <button className="btn btn-disabled"  type="button" disabled={true}>Approve</button>
                        </span>
                    :
                    this.props.userPermissions.can_approve ? 
                    <button className="btn btn-primary cmnt-approve-btn"  type="button" onClick={this.approveSubmitHandler}>Approve</button> : null
                }
                <div style={{"display":"none"}}>
                    IsApproved {this.props.IsApproved ? 'YES': 'NO'}
                </div>
            </div>
        )
    }
}

export default ApproveButton;