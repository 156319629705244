import ReviewAndCommentsHODReview from './ReviewAndCommentsHODReview';
import SelectCrossFunctionalTeams from './SelectCrossFunctionalTeams';
import ReviewandCommentByHeadProductionDepartment from './4A Pages/ReviewandCommentByHeadProductionDepartment';
import ReviewAndCommentByHeadElectricalDepartment from './4A Pages/ReviewAndCommentByHeadElectricalDepartment';
import ReviewAndCommentByHeadInstrumentDepartment from './4A Pages/ReviewAndCommentByHeadInstrumentDepartment';
import ReviewAndCommentByHeadMechanicalDepartment from './4A Pages/ReviewandCommentByHeadMechanicalDepartment';
import ReviewAndCommentByHeadEngineeringDepartment from './4A Pages/ReviewAndCommentByHeadEngineeringDepartment';
import ReviewAndCommentByHeadStoresAndPurchaseDepartment from './4A Pages/ReviewAndCommentByHeadStoresAndPurchaseDepartment';
import ReviewAndCommentByHeadPDDepartment from './4A Pages/ReviewAndCommentByHeadPDDepartment';
import ReviewAndCommentByHeadProjectsDepartment from './4A Pages/ReviewAndCommentByHeadProjectsDepartment';
import ReviewAndCommentByHeadSafetyDepartment from './4A Pages/ReviewAndCommentByHeadSafetyDepartment';
import ReviewAndCommentByHeadEnvironmentDepartment from './4A Pages/ReviewAndCommentByHeadEnvironmentDepartment';
import ReviewAndCommentByHeadQADepartment from './4A Pages/ReviewAndCommentByHeadQADepartment';
import ApprovalBySiteHead from './5 Page/ApprovalBySiteHead'
import AfterSiteApprovelFlow from './6 Pages/AfterSiteApprovalFlow-6'
import ApprovalBySynthesis from './6A Pages/ApprovalBySynthesis'
import ApprovalByFormulationDevelopment from './6A Pages/ApprovalByFormulationDevelopment'
import ApprovalByAnalyticalDevelopment from './6A Pages/ApprovalByAnalyticalDevelopment'
import ApprovalByRegulatoryDomestic from './6A Pages/ApprovalByRegulatoryDomestic';
import ApprovalByRegulatoryInternational from './6A Pages/ApprovalByRegulatoryInternational';
import ApprovalByResearchandDevelopmentHead from './6A Pages/ApprovalByResearchandDevelopmentHead'
import ApprovalByTechnologyTransferServicesHead from './6A Pages/ApprovalByTechnologyTransferServicesHead'
import ApprovalBySustainabilityHead from './6A Pages/ApprovalBySustainabilityHead'
import ApprovalByManufacturingHead from './6A Pages/ApprovalByManufacturingHead'
import ApprovalByCQALHead from './6A Pages/ApprovalByCQALHead'
import ApprovalByIBDHead from './6A Pages/ApprovalByIBDHead'
import ApprovalByDFHead from './6A Pages/ApprovalByDFHead'
import ApprovalBySiteQAHead from './6A Pages/ApprovalBySiteQAHead'
import ApprovalByLegalHead from './6A Pages/ApprovalByLegalHead'
import PlantMOCLeadFinalApproval from './6B Pages/PlantMOCLeadFinalApproval'
import ExecutionofApprovedMOC from './Step7/ExecutionofApprovedMOC'
import CommonExecutionAndHODReviewComponent from './Step7/CommonExecutionAndHODReviewComponent'
import ReviewbyHOD from './Step7/ReviewbyHOD'
import JobInitiatorAndExecutor from './Step7A/JobInitiatorAndExecutor'
import ReviewBySafetyHead from './Step 8/ReviewBySafetyHead'
import MRQAMOCLead from './Step 9/MRQAMOCLead'
import FinalClosureByPlantMOCLead from './Step 10/FinalClosureByPlantMOCLead'
import MOCRequestStage from './MOCRequestStage';
import SelectConcernedDepartmentHeads from './6 Pages/SelectConcernedDepartmentHeads';


const StageComponents = {
    "MOCRequestStage":MOCRequestStage,
    'HOD review':ReviewAndCommentsHODReview,
    'ReviewAndCommentsHODReview': ReviewAndCommentsHODReview,
    'SelectCrossFunctionalTeams': SelectCrossFunctionalTeams,
    'ReviewandCommentByHeadProductionDepartment':ReviewandCommentByHeadProductionDepartment,
    "ReviewAndCommentByHeadElectricalDepartment":ReviewAndCommentByHeadElectricalDepartment,
    "ReviewAndCommentByHeadInstrumentDepartment":ReviewAndCommentByHeadInstrumentDepartment,
    "ReviewAndCommentByHeadMechanicalDepartment":ReviewAndCommentByHeadMechanicalDepartment,
    "ReviewAndCommentByHeadEngineeringDepartment":ReviewAndCommentByHeadEngineeringDepartment,
    "ReviewAndCommentByHeadStoresAndPurchaseDepartment":ReviewAndCommentByHeadStoresAndPurchaseDepartment,
    "ReviewAndCommentByHeadPDDepartment":ReviewAndCommentByHeadPDDepartment,
    "ReviewAndCommentByHeadProjectsDepartment":ReviewAndCommentByHeadProjectsDepartment,
    "ReviewAndCommentByHeadSafetyDepartment":ReviewAndCommentByHeadSafetyDepartment,
    "ReviewAndCommentByHeadEnvironmentDepartment":ReviewAndCommentByHeadEnvironmentDepartment,
    "ReviewAndCommentByHeadQADepartment":ReviewAndCommentByHeadQADepartment,
    "SelectConcernedDepartmentHeads":SelectConcernedDepartmentHeads,
    "ApprovalBySiteHead":ApprovalBySiteHead,
    "AfterSiteApprovelFlow":AfterSiteApprovelFlow,
    "ApprovalBySynthesis":ApprovalBySynthesis,
    "ApprovalByFormulationDevelopment":ApprovalByFormulationDevelopment,
    "ApprovalByAnalyticalDevelopment":ApprovalByAnalyticalDevelopment,
    "ApprovalByRegulatoryDomestic":ApprovalByRegulatoryDomestic,
    "ApprovalByRegulatoryInternational":ApprovalByRegulatoryInternational,
    "ApprovalByResearchandDevelopmentHead":ApprovalByResearchandDevelopmentHead,
    "ApprovalByTechnologyTransferServicesHead":ApprovalByTechnologyTransferServicesHead,
    "ApprovalBySustainabilityHead":ApprovalBySustainabilityHead,
    "ApprovalByManufacturingHead":ApprovalByManufacturingHead,
    "ApprovalByCQALHead":ApprovalByCQALHead,
    "ApprovalByIBDHead":ApprovalByIBDHead,
    "ApprovalByDFHead":ApprovalByDFHead,
    "ApprovalBySiteQAHead":ApprovalBySiteQAHead,
    "ApprovalByLegalHead":ApprovalByLegalHead,
    "PlantMOCLeadFinalApproval":PlantMOCLeadFinalApproval,
    "ExecutionofApprovedMOC":CommonExecutionAndHODReviewComponent,
    "ReviewbyHOD":ReviewbyHOD,
    "JobInitiatorAndExecutor":JobInitiatorAndExecutor,
    "ReviewBySafetyHead":ReviewBySafetyHead,
    "MRQAMOCLead":MRQAMOCLead,
    "FinalClosureByPlantMOCLead":FinalClosureByPlantMOCLead
}

export default StageComponents;