import axios from 'axios';
import React, { Component } from 'react';
import ViewComments from './ViewComments';
import { API_HOST } from '../../Settings';
import $ from 'jquery';
import notify from '../CommonComponents/Notify';



class Comment extends Component {
    constructor(props){
        super(props)
        this.state = {
            message:'',
            viewComments:this.props.WorkflowComponent?this.props.WorkflowComponent.workflow_comments:[],
            unique_combine: this.props.unique_combine ? this.props.unique_combine : 'comment',
            show_comment_double_tap: true
        }
    }

    
    handleChange = (e) =>{
        this.setState({ 
            message:e.target.value
         })
    }

    static getDerivedStateFromProps(props, state) {
        if(props.WorkflowComponent !== state.viewComments){
            //Change in props
            return{
                viewComments: props.WorkflowComponent?props.WorkflowComponent.workflow_comments:[]
            };
        }
        return null; // No change to state
    }

    isValid=()=>{
        var name_regexp = new RegExp("<(?:\"[^\"]*\"['\"]*|'[^']*'['\"]*|[^'\">])+>");
        if(!this.state.message){
            return {"status":false, "reason":"Comment should not be empty", "custom_error_code":1}
        }
        if((this.state.message&&name_regexp.test(this.state.message))){
            return {"status":false, "reason": "Please provide valid information in comment field.", "custom_error_code":2}
        }
        return {"status":true, "reason":""}
    }

    handleDoubleClick = (e)=>{
    }

    commentSubmitHandler = (e, stop_loader) =>{
        e.preventDefault()
        // this.setState({"show_comment_double_tap": false})
        var response_isvalid = this.isValid()
        if(!response_isvalid.status){
            notify(response_isvalid.reason,'warning')
            // this.setState({"show_comment_double_tap": true})
            return false
        }
        $('.loader').fadeIn()
        var comments_data = {
            model:"workflow_comments",
            data:{
                    workflow_definition_id:this.props.WorkflowData.workflow_definition_id,
                    stage_id:this.props.WorkflowData.id,
                    component_id:this.props.WorkflowComponent.id,
                    message:this.state.message
                }
        }
        
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
        axios.post(`${API_HOST}/workflow/${this.props.RequestType}/request/${this.props.DocumentNumber}/form/data/save/`, comments_data)
		.then(response => {
			if (response.status === 200) {
                if(response.data.status == "warning"){
                    notify(response.data.reason, response.data.status)
                    $('.loader').fadeOut('slow')
                    return false
                }
                notify('Comment saved successfully','success')
                if(stop_loader){
                    $('.loader').fadeOut('slow')
                }
                var comment_data = response.data.data.workflow_comments
                this.state.viewComments.push(comment_data)
				this.setState({
                    viewComments:this.state.viewComments,
                    message:''
				})
                document.getElementById('comments').value=null;

			}

		}).catch(response => {
            // ErrorHandling(response)
        });
    }
    
    render(){
        if(this.props.RequestType=='moc-request'){
            var ui_action_controls = {'comment_btn': 'show'}
            if(['approved', 'rejected'].includes(this.props.stage_status)){
                ui_action_controls['comment_btn'] = 'hide'
            }
            else if(this.props.force_show==true){
                ui_action_controls['comment_btn'] = 'show'
            }
            else if(this.props.is_approved){
                // component approved hiding comment button
                ui_action_controls['comment_btn'] = 'hide'
            }else if(this.props.is_rejected && this.props.PlantMOCOpinion != 'Rejected'){
                // component rejected case
                ui_action_controls['comment_btn'] = 'hide'
            }
            if(this.props.userPermissions.can_comment==false){
                ui_action_controls['comment_btn'] = 'hide'
            }
            // console.log("this.props.WorkflowComponent", this.props.WorkflowComponent? this.props.WorkflowComponent.id:'', this.props, ui_action_controls)
            return(
                    <div className='col-lg-7 col-md-12 col-sm-12 col-12 mb-3'>
                        <div className='d-flex justify-content-between position-relative mb-2'>
                            <label className="form-label position-absolute" htmlFor={this.state.unique_combine+"_new_comment"}>
                                {ui_action_controls['comment_btn']=='hide'? this.state.viewComments.length>0?'Comments':'No Comments':'Comments'}
                            </label>
                        </div>
                        <ViewComments key={this.state.unique_combine+'viewcom'}
                            unique_combine={this.state.unique_combine}
                            WorkflowComents={this.state.viewComments} />
                        {this.props.userPermissions.can_comment ? 
                        <div>
                        { ui_action_controls['comment_btn']=='show' ?
                            [
                                <textarea  key={this.state.unique_combine+'textarea'}
                                    className='form-control brio-form-control mt-4'
                                    rows="3" name={this.state.unique_combine+"_new_comment"} id={this.state.unique_combine+"_new_comment"} type="text"
                                    onChange={e=>this.handleChange(e)} value={this.state.message}>
                                </textarea>,
                                <div key={this.state.unique_combine+'scom'} className='pe-2 mt-3'>
                                    <button type="submit" className="btn btn-success btn-sm"
                                        onClick={(e)=>{this.commentSubmitHandler(e, true)}} onDoubleClick={this.handleDoubleClick}>
                                            Comment
                                    </button>
                                </div>
                            ]:null
                        }
                        </div>:null
                        }
                    </div>
                )
        }
        else{
            return(

                <div className='col-lg-7 col-md-12 col-sm-12 col-12 mb-3'>
                    {(this.props.is_approved && this.state.viewComments.length == 0)  || (this.props.isRejected && this.state.viewComments.length == 0) ?
                        <div className='d-flex justify-content-between position-relative mb-2'>
                            <label className="form-label position-absolute" htmlFor="comments">
                            No Comments
                            </label>
                        </div>
                        :
                        <div className='d-flex justify-content-between position-relative mb-2'>
                            <label className="form-label position-absolute" htmlFor="comments">
                            Comments
                            </label>
                        </div>
                    }
                    <ViewComments key={this.state.unique_combine+'viewcom'}
                        unique_combine={this.state.unique_combine}
                        WorkflowComents={this.state.viewComments} />
                    {this.props.userPermissions.can_comment ? 
                    <div>
                    {(this.props.is_approved || (this.props.isRejected && this.props.PlantMOCOpinion != 'Rejected')) ? '':
                        [
                            <textarea key={this.state.unique_combine+'textarea'}
                                className='form-control brio-form-control mt-4'
                                rows="3" name="comments" id="comments"
                                value={this.state.message}
                                type="text" onChange={e=>this.handleChange(e)}>
                            </textarea>,
                            <div key={this.state.unique_combine+'scom'} className='pe-2 mt-3'>
                                <button type="submit" className="btn btn-success btn-sm" onClick={(e)=>this.commentSubmitHandler(e, true)}>
                                    Comment
                                </button>
                            </div>
                        ]
                    }
                    </div>
                    : null}
                </div>
            )
        }
    }
}

export default Comment;