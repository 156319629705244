import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Toggle from '../CommonComponents/Toggle';
import $ from 'jquery'
import { API_HOST } from '../../Settings.js'
import axios from 'axios';
import SideMenu from '../CommonComponents/SideMenu';
import PlantMOCLead from '../MOCSubTaskRequest/PlantMocLead';
import notify from '../CommonComponents/Notify';

class CreateSubTaskRequest extends Component {
    constructor(props){
        super(props)
        this.state = 
        {   
            documentID: this.props.match.params.documentID,
            date_of_moc:new Date().toISOString().split('T')[0],
            proposal_initiated_by:'',
            assignee:'',
            deadline: '',
            title: '',
            workflow_data:{},
            userPermissions: {},
            unique_combine: 'st1c1',
            current_component_name: "PlantMOCLead",
        }
    }

    componentDidMount(){
        this.getUserPrivileges()
        /*$('.datepicker').datepicker({
            autoclose: true,
            format: "dd/mm/yyyy",
            immediateUpdates: true,
            todayBtn: true,
            todayHighlight: true
        })
        $('#dateMoC').datepicker("setDate", "0");*/
        var today_str = this.state.date_of_moc
        $('#dateMoC').val(today_str);
        var userData = JSON.parse(localStorage.getItem('user_data'))
        $('.loader').fadeIn();
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/all/users/list/`,)
        .then(response => {
            if (response.status === 200 && response.data.success) {
                var users_data = JSON.parse(response.data.users_data)
                this.setState({
                    proposal_initiated_by:userData.name,
                    UsersList:users_data
                })
                this.getWorkflowData()
                $('.loader').fadeOut('slow')

            }
        }).catch(response => {
            // ErrorHandling(response)
        });
    }

    getUserPrivileges = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        let privileges = JSON.stringify(['can_create'])
        let component = this.state.current_component_name
        axios.get(`${API_HOST}/user/privileges/${component}/${privileges}/`, )
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    userPermissions:response.data.data,
                })

            }
        }).catch(response => {
            // ErrorHandling(response)
        });
    }

    getWorkflowData = () =>{
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')  
    	axios.get(`${API_HOST}/workflow/moc-sub-task/request/initiate/`, )
    	.then(response => {
	        if (response.status === 200) { 
                this.setState({
                    workflow_data:response.data.data
                })           
	        }
        }).catch(response => {
            // ErrorHandling(response)
        });
    }

    handleCancel = ()=>{
        window.location.href='/'
    }

    handleUpdateAttachment = (attachment_obj) =>{
        //attachment=null,filename=null,file_size=null,signed_url=null
        var enclosures = this.state.enclosures
        enclosures['attachment_url'] = attachment_obj.filename?attachment_obj.filename:enclosures.attachment_url
        enclosures['attachment'] = attachment_obj.attachment?attachment_obj.attachment:enclosures.attachment
        enclosures['file_size'] = attachment_obj.file_size?attachment_obj.file_size:enclosures.file_size
        enclosures['signed_url'] = attachment_obj.signed_url?attachment_obj.signed_url:enclosures.signed_url        
        this.setState({
            enclosures:enclosures
        })
    }

    getCommonMinDate = (event)=>{
        var today_str = new Date().toISOString().split("T")[0]
        var min_date = this.state.date_of_moc
        var common_min_date = today_str
        if(min_date){
            var min_date_obj = new Date(min_date)
            if(min_date_obj!='Invalid Date'){
                common_min_date = min_date_obj.toISOString().split('T')[0]
            }
        }
        return common_min_date
    }

    handleChange(e){
        if(e.currentTarget.value == ""){e.currentTarget.classList.add("error")}
        if(e.currentTarget.value && e.currentTarget.classList.contains("error")){e.currentTarget.classList.remove("error")}
    }
    isValid=()=>{
        var name_regexp = new RegExp("<(?:\"[^\"]*\"['\"]*|'[^']*'['\"]*|[^'\">])+>");
        if(!document.querySelector("#title").value || !document.querySelector("#assignee").value || !document.querySelector("#deadline").value || !document.querySelector("#description").value){
            var scrollDiv = ""
            if(!document.querySelector("#title").value){
                document.querySelector('#title').classList.add("error")
                scrollDiv = "title"
            }
            if(!document.querySelector("#assignee").value){
                document.querySelector('#assignee').classList.add("error")
                scrollDiv = "assignee"
            }
            if(!document.querySelector("#deadline").value){
                document.querySelector('#deadline').classList.add("error")
                scrollDiv = "deadline"
            }
            if(!document.querySelector("#description").value){
                document.querySelector('#description').classList.add("error")
                scrollDiv = "description"
            }
            return {"status":false, "reason": "Please fill required field", "id": scrollDiv}
        }
        if((document.querySelector("#title").value&&name_regexp.test(document.querySelector("#title").value))){
            document.querySelector('#title').classList.add("error")
            var scrollDiv = "title"
            return {"status":false, "reason": "Please provide valid information in input fields.", "id": scrollDiv}
        }
        if((document.querySelector("#description").value&&name_regexp.test(document.querySelector("#description").value))){
            document.querySelector('#description').classList.add("error")
            var scrollDiv = "description"
            return {"status":false, "reason": "Please provide valid information in input fields.", "id": scrollDiv}
        }
        var deadline = document.getElementById('deadline').value
        var date_obj = new Date(deadline)
        if(deadline && date_obj!="Invalid Date"){
            var common_min_date_obj = new Date(this.getCommonMinDate())
            common_min_date_obj.setHours(0,0,0,0)
            if (date_obj<common_min_date_obj){
                document.querySelector('#deadline').classList.add("error")
                scrollDiv = "deadline"
                return {"status":false, "reason": "Invalid date provided", "id": scrollDiv}
            }
        }
        return {"status":true, "reason":""}
    }

    submitMoCSubTaskRequest = (e,status) =>{
        e.preventDefault()
        var response_isvalid = this.isValid()
        if(!response_isvalid.status){
            notify(response_isvalid.reason,'warning')
            setTimeout(function () {
                document.querySelector('#'+response_isvalid.id).scrollIntoView({
                    behavior: 'smooth', block: "center", inline: "nearest" 
                })
            }, 100)
            return false
        }
        if(status == 'open'){
            var msg = "Are you sure, you want submit moc sub task request?"
        }else{
            var msg = "Are you sure, you want to save as draft?"
        }
        if(window.confirm(msg)){
            var moc_data = this.state
            moc_data["title"] = document.getElementById("title").value
            moc_data["description"] = document.getElementById("description").value
            moc_data['date_of_moc'] = document.getElementById('dateMoC').value
            moc_data['deadline'] = document.getElementById('deadline').value
            moc_data['assignee'] = document.querySelector("#assignee").value
            moc_data['status'] = status
            $('.loader').fadeIn()
            var url = `${API_HOST}/save/moc/subtask/request/data`
            const auth_token = localStorage.getItem("token")
            axios.defaults.headers.post['Authorization'] =auth_token      
            axios.post(url, moc_data)
            .then(response => {
                if (response.status === 200 && response.data.success) {                    
                    setTimeout(() =>{
                        // $('#approve-as-variant-model').modal('hide');
                        this.props.history.push('/moc/subtask/request/'+response.data.document_number+'/'+response.data.sub_task_document_number+'/')
                    },1000)
                    $('.loader').fadeOut('slow')
                    notify('MoC Sub Task created successfully','success')
                }else{
                    notify(response.data.message, 'warning')
                    $('.loader').fadeOut('slow')
                }
            }).catch(response => {
                // ErrorHandling(response)
            });
        }

    }
   
    render(){
        return(
            <div className="container sub-task-container">
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-12 side-nav-block">
                        <div className="card sticky-card">
                            <div className="card-body sticky-body">
                            {this.state.workflow_data.name_slug?
                                <SideMenu activeNameSlug={this.state.workflow_data.name_slug} RequestType="moc-sub-task" />
                                :""}
                            </div>
                        </div>
                    </div>                
                    <div className="col-lg-9 col-md-9 col-sm-12">
                        <div className="card mb-3">     
                            <PlantMOCLead thisData={this.state} accessType={"create"}/>
                        </div>

                        <div className='card mb-3'>
                            <div className='card-body'>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end tri-buttons">
                                <button className="btn btn-outline-secondary" type="button" onClick={this.handleCancel} id={this.state.unique_combine+"cancel"}>Cancel</button>
                                {this.state.userPermissions.can_create?
                                [<button className="btn btn-dark" type="submit" id="submit" onClick={(e) => this.submitMoCSubTaskRequest(e,'open')} >Submit</button>]
                                :null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(CreateSubTaskRequest)