import React, { Component } from "react";
import { API_HOST } from "../../Settings";
import axios from 'axios';
import NotificationIcon from '../../images/notification icon.svg';
import { Collapse } from 'bootstrap'

class SideMenu extends Component {
	constructor(props){
		super(props)
		this.state = {
			active_name_slug: "",
			stages: [],
			activeMenu:"",
			ask_more_stage_index: 0
		}
	}

	static getDerivedStateFromProps(props, state) {
	    if(props.activeNameSlug !== state.active_name_slug){
	        //Change in props
	        var stage_index = 0
	        if(['closure-of-moc-by-initiator-hod', 'executors-s-hod-reviews-performance', 'review-and-close'].includes(props.activeNameSlug)){
				stage_index = 7
			}
			if(['execution-hod-review', 'hod-of-safety-review', 'hod-of-qa-review', 'closure-by-moc-lead'].includes(props.activeNameSlug)){
				stage_index = 8
			}
	        return{
	            active_name_slug: props.activeNameSlug?props.activeNameSlug:state.active_name_slug,
	            ask_more_stage_index: stage_index
	        };
	    }
	    return null; // No change to state
	}

	componentDidMount() {
		axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
		axios.get(`${API_HOST}/workflow/${this.props.RequestType}/`, )
		.then(response => {
			if (response.status === 200) {
				this.setState({
				    stages: response.data.data.stages,
				})
			}

		}).catch(response => {
            // ErrorHandling(response)
        });
	}

	handleStageClick(stageName){
		this.setState({
			active_name_slug:'hod-review'
		});
	}

	render(){
		var workflowData = []
		var stage_status_mapper = {}
		if(this.props.workflowData){
			workflowData = this.props.workflowData
			stage_status_mapper = this.props.workflowData.reduce((acc, cur) => ({ ...acc, [cur.stage_name_slug]: cur.status }), {})
		}
		var has_pending_ask_more_request_for_sidemenu = false
		if(this.props.hasOwnProperty("has_pending_ask_more_request_for_sidemenu")){
			if(this.props.has_pending_ask_more_request_for_sidemenu==true){
				has_pending_ask_more_request_for_sidemenu = true
			}
		}

		const menu_stages = this.state.stages.map((data,indx)=>{
			var each_stage_status = ''
			if(stage_status_mapper.hasOwnProperty(data.name_slug)){
				each_stage_status = stage_status_mapper[data.name_slug]
			}
			if(data.name_slug==this.state.active_name_slug && !['approved', 'rejected'].includes(each_stage_status)){
				return(
					<li key={'stage_'+indx} className={ data.name_slug == this.state.active_name_slug ? 'active':'' } >
						<div className="d-flex justify-content-between">
							<div><span></span></div>
							<div className="d-flex justify-content-between" style={{"paddingLeft":"20px"}}>
								<div>
									{data.name}
									{ data.name_slug == this.state.active_name_slug ?<p className="dot"></p>:null }
								</div>
							</div>
						</div>
					</li>
				)
			}else {
				return(
					<li key={'stage_'+indx}>
						<div className="d-flex justify-content-between" onClick={()=>this.handleStageClick(data.name_slug)}>
							<div>
								{ each_stage_status=='approved' ?
									indx==this.state.ask_more_stage_index
								  	?
									  	has_pending_ask_more_request_for_sidemenu==true
									  	?
										  	<i className="fa fa-clock-o" style={{color: "red"}}></i>
										:
										 <i className="fa fa-check-circle" style={{color: "green"}}></i>

									:<i className="fa fa-check-circle" style={{color: "green"}}></i>
								  	:null
								}
								{	each_stage_status=='rejected'?
									<i className="fa fa-times-circle" style={{color: "red"}}></i>:null
								}
							</div>
							{each_stage_status?
								<div style={{"paddingLeft":"8px"}}>{data.name}</div>:
								<div style={{"paddingLeft":"20px"}}>{data.name}</div>
							}
							
						</div>
					</li>
				)
			}
		});
		return(
			<div>
			<ul className="menu-list-item">
				{menu_stages}
			</ul>
			</div>   
		)
	}
}

export default SideMenu;