import React, { Component, createRef } from "react";
import ExecutionofApprovedMOC from "./ExecutionofApprovedMOC";
import ReviewByHod from "./ReviewbyHOD";
import JobInitiatorAndExecutor from "../Step7A/JobInitiatorAndExecutor";
import { getChangeAuthorityStepsData } from '../../CommonComponents/MiscellaneousObject';
import { getExecutorGeneralChecksData } from '../../CommonComponents/MiscellaneousObject';
import $ from 'jquery'
import axios from 'axios';
import notify from "../../CommonComponents/Notify";
import { API_HOST } from "../../../Settings";
import Sign from "../../ConsentComponents/Sign";
import Comment from "../../ConsentComponents/Comment";
import Signed from "../../ConsentComponents/Signed";
import ApproveButton from "../../ConsentComponents/ApproveButton";
import AskButton from "../../ConsentComponents/AskButton";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { BiTrash } from "react-icons/bi";
import { FaRegCheckCircle } from "react-icons/fa";
import Subtask from "../6B Pages/Subtask"
import { isTypeAliasDeclaration } from "typescript";
// import RejectButton from "../../ConsentComponents/RejectButton";
// import RejectComponent from "../../CommonComponents/RejectComponent";



class CommonExecutionAndHODReviewComponent extends Component {
	constructor(props){
		super(props)
		this.commentRef = createRef();
		this.handleOfComment = this.handleOfComment.bind(this)
		this.state = {
			ChangeAuthorityStepsData: getChangeAuthorityStepsData(),
			ExecutorGeneralChecksData: getExecutorGeneralChecksData(),
			product_name: "",
			job_order_department:[],
			engg_department:[],
			activities:"",
			review_detailed_engg_Work:"",
			review_hira_recommendations:"",
			review_qra_recommendations:"",
			execution_of_the_job:"",
			executor_hod_performance_review:"",
			ehs_hod_performance_review:"",
			is_approved:false,
            WorkflowData:this.props.WorkflowData,
            WorkflowComponent:this.props.WorkflowComponent,
            DocumentNumber:this.props.DocumentNumber,
            WorkflowSignatures:[],
            isDataSubmited:false,
            is_rejected:false,
            show_plant_moc_opinion_card: false,
            plant_moc_opinion: "",
            unique_stage_id: '9',
            unique_comp_id: '1',
            unique_combine: 's9c1',
            stage_status:this.props.stage_status,
            isHodFormSubmited: false,
            userPermissions: {},
			initiatorUserPermissions: {},
			current_component_name: "ExecutionofApprovedMOC",
			ask_for_more_history: [],
			executionID: '',
			isButtonClicked:false,
			isBlurTriggered:false,
			submissionType:'auto_save',
			tasks :[],
			is_there_pending_tasks:false,
			isTaskSubmited:false,
			moc_status:'',
		}
	}

	handleOfComment(e, stop_loader, method){
        var response_isvalid = this.commentRef.current.isValid()
        if(!response_isvalid.status){
            if(method == "approve"){
                if(response_isvalid.custom_error_code!=1){
                    // if custom_error_code==1  we can process approval without comment saving
                    notify(response_isvalid.reason,'warning')
                    return false
                }
            }else{
                if(response_isvalid.custom_error_code==1){
                    return false
                }
				if(response_isvalid.custom_error_code==2){
                    // if custom_error_code==1  we can process approval without comment saving
                    notify(response_isvalid.reason,'warning')
                    return false
                }
            }
        }else{
            this.commentRef.current.commentSubmitHandler(e, stop_loader)
            return true
        }
        return true
    }


	getAllData = ()=>{
		$('.card-loader').fadeIn()
		axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
		axios.get(`${API_HOST}/workflow/moc/request/${this.props.DocumentNumber}/stage/${this.props.StageName}/?component_name=${this.props.ComponentName}`, )
		.then(response => {
		    if (response.status === 200) {
		    	this.setState({
		            WorkflowData: response.data.data.workflow_stage_info,
		            WorkflowComponent :response.data.data.workflow_stage_info.components[0],
		            WorkflowSignatures :response.data.data.workflow_stage_info.components[0].workflow_signatures,
		            enable_signing :response.data.data.workflow_stage_info.components[0].enable_signing,
		            enable_approval :response.data.data.workflow_stage_info.components[0].enable_approval,
		            is_approved : response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
		                response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Approved" ? true : false,
		            is_rejected : response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
		                response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false,
		            show_plant_moc_opinion_card : response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
		                response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false,
		            plant_moc_opinion : response.data.data.workflow_stage_info.components[0].wf_plant_moc_lead_review[0] ?
		                response.data.data.workflow_stage_info.components[0].wf_plant_moc_lead_review[0].action.name : "",
					ask_for_more_history:response.data.data.ask_for_more_history,
					moc_status:response.data.data.moc_status
		        })
		    }

		}).catch(response => {
		    // ErrorHandling(response)
		});

		axios.get(`${API_HOST}/get/and/save/moc/execution/${this.state.DocumentNumber}/data`, )
	        .then(response => {
	            if (response.status === 200) {
	            	let execution_data = response.data.execution_data
	            	let change_authority_steps_data = this.state.ChangeAuthorityStepsData
	            	let executor_general_checks_data = this.state.ExecutorGeneralChecksData
	            	execution_data["ExecutorGeneralChecksData"].map((each, index)=>{
	            		executor_general_checks_data.filter((obj)=>{return obj.name == each.name})[0].status = each.status
	            		// executor_general_checks_data[]
	            	})
	            	execution_data["ChangeAuthorityStepsData"].map((each, index)=>{
	            		let each_ = change_authority_steps_data.filter((obj)=>{return obj.name == each.header_name})[0]["rows"].filter((obj)=>{return obj.name == each.name})[0]
	            		each_.status = each.status
	            		each_.comment = each.comment
	            	})
					const created_by = !(execution_data.created_by === 'system')
	                this.setState({
	                	ExecutorGeneralChecksData:executor_general_checks_data,
	                	ChangeAuthorityStepsData:change_authority_steps_data,
	                	product_name: execution_data.product_name,
						job_order_department: execution_data.job_order_department,
						engg_department: execution_data.engg_department,
						activities: execution_data.activities,
						review_detailed_engg_Work: execution_data.review_detailed_engg_Work,
						review_hira_recommendations: execution_data.review_hira_recommendations,
						review_qra_recommendations: execution_data.review_qra_recommendations,
						execution_of_the_job: execution_data.execution_of_the_job,
						executor_hod_performance_review: execution_data.executor_hod_performance_review,
						ehs_hod_performance_review: execution_data.ehs_hod_performance_review,
						isDataSubmited: created_by,
						executionID: execution_data.execution_id
						// is_approved: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                        //     response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Approved" ? true : false
						// is_approved: true
	                })
	            }

	        }).catch(response => {
	            // ErrorHandling(response)
	        });
	    $('.card-loader').fadeOut('slow')
	}

	handleHodFormSubmitStatus=(value)=>{
		this.setState({"isHodFormSubmited": value})
	}

	componentDidMount(){
		this.getUserPrivileges()
		this.getInitiatorUserPrivileges()
		if(this.props.isCollapased==false && this.props.DocumentNumber){
			this.getAllData()
			this.getTaskData();
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.state.is_there_pending_tasks !== prevState.is_there_pending_tasks) {
			this.getTaskData();
		}
		if(this.state.isDataSubmited  !== prevState.isDataSubmited && !this.state.is_there_pending_tasks){
			this.setState({isTaskSubmited:true})
		}
		
	}


	handleAuthorityChecks = (e, name, main_index, row_index)=>{
		this.setState({isBlurTriggered:true})
		this.setState({submissionType:'auto_save'})
		let change_authority_steps_data = this.state.ChangeAuthorityStepsData
		change_authority_steps_data[main_index]["rows"][row_index].status = name
		this.setState({
			ChangeAuthorityStepsData:change_authority_steps_data
		})
		this.submitTimeout = setTimeout(() => {
            this.submitExecutionReview(e,'authorityChecks');
        }, 1000);

	}

	handleInitiatorChecks = (e, name, row_index) =>{
		this.setState({isBlurTriggered:true})
		this.setState({submissionType:'auto_save'})
		let ExecutorGeneralChecksData = this.state.ExecutorGeneralChecksData
		ExecutorGeneralChecksData[row_index].status = name
		this.setState({
			ExecutorGeneralChecksData:ExecutorGeneralChecksData
		})
		this.submitTimeout = setTimeout(() => {
            this.submitExecutionReview(e,'generalChecks');
        }, 1000);
	}

	handleAuthorityComments = (e, main_index, row_index) => {
		this.setState({isBlurTriggered:true})
		this.setState({submissionType:'auto_save'})
        let change_authority_steps_data = this.state.ChangeAuthorityStepsData
		change_authority_steps_data[main_index]["rows"][row_index].comment = e.target.value
		this.setState({
			ChangeAuthorityStepsData:change_authority_steps_data
		})
		this.submitTimeout = setTimeout(() => {
            this.submitExecutionReview(e,'authorityComments');
        }, 1000);
    }

	handleOnChange = (e) =>{
		this.setState({isBlurTriggered:true})
		this.setState({submissionType:'auto_save'})
		if(e.currentTarget.value == ""){e.currentTarget.classList.add("error")}
        if(e.currentTarget.value && e.currentTarget.classList.contains("error")){e.currentTarget.classList.remove("error")}
		this.setState({[e.target.name]:e.target.value})
		this.submitTimeout = setTimeout(() => {
            this.submitExecutionReview(e,'onChange');
        }, 1000);
	}
	handleOnSelectDepartment = (e, id) =>{
		this.setState({isBlurTriggered:true})
		// this.setState({isButtonClicked:false})
		this.setState({submissionType:'auto_save'})
		var selected_department_list = e.map((row, indx)=>{
			return {"value": row.value, "label": row.label}
		})
		if(id==="job_order_department"){
			this.setState({
				job_order_department:selected_department_list
			})
		}else{
			this.setState({
				engg_department:selected_department_list
			})
		}
		this.submitTimeout = setTimeout(() => {
            this.submitExecutionReview(e,'select');
        }, 1000);
	}

	isValid = ()=>{
		if(this.state.job_order_department.length===0){
			// document.querySelector("#job_order_department").classList.add("error")
	        return {"status":false, "reason":"Select Department and Issue job order", "id": "initiatorDiv"}
	    }
	    if(!this.state.engg_department.length===0){
			// document.querySelector("#engg_department").classList.add("error")
	    	return {"status":false, "reason":"Select Department Detailed Engg. Department", "id": "initiatorDiv"}
	    }
		var name_regexp = new RegExp("<(?:\"[^\"]*\"['\"]*|'[^']*'['\"]*|[^'\">])+>");
        if((this.state.activities&&name_regexp.test(this.state.activities)) || (this.state.review_detailed_engg_Work&&name_regexp.test(this.state.review_detailed_engg_Work)) || (this.state.review_hira_recommendations&&name_regexp.test(this.state.review_hira_recommendations)) || (this.state.review_qra_recommendations&&name_regexp.test(this.state.review_qra_recommendations)) || (this.state.execution_of_the_job&&name_regexp.test(this.state.execution_of_the_job))){
            return {"status":false, "reason":"Please provide valid information in input fields.", 'id': 'initiatorDiv'}
        }
		for(let i=0;i<this.state.ChangeAuthorityStepsData.length;i++){
			var rows = this.state.ChangeAuthorityStepsData[i].rows
			for(let j=0;j<rows.length;j++){
				if(rows[j].comment&&name_regexp.test(rows[j].comment)){
					return {"status":false, "reason":"Please provide valid information in input fields."}
				}
			} 
		}
	    return {"status": true}
	}


	handleButtonClick = (e) => {
        e.preventDefault();
		this.setState({submissionType:'moc_submit'})
        this.setState({ isButtonClicked: true }, () => {
            if (!this.state.isBlurTriggered) {
				console.log('inside_handle_button_click')
                this.submitExecutionReview(e,'moc_submit');
            }
        });
    }

	handleUpdateButtonClick = (e) => {
		e.preventDefault();
		this.setState({isBlurTriggered:false})
		this.setState({isButtonClicked:false})
		this.setState({submissionType:'update'})
		this.submitTimeout = setTimeout(() => {
            this.updateExecutionReview(e);
        }, 1000);
	};

	submitExecutionReview = (e,type) => {
		if (type != 'select'){
			e.preventDefault()
		}
		const buttonClicked = this.state.isButtonClicked
		if(buttonClicked){
			this.setState({ isButtonClicked: false, isBlurTriggered: false});
			var is_valid_response = this.isValid()
			if(!is_valid_response.status){
			    notify(is_valid_response.reason, 'warning')
				setTimeout(function () {
			        document.querySelector('#'+is_valid_response.id).scrollIntoView({
			            behavior: 'smooth', block: "center", inline: "nearest" 
			        })
			    }, 100)
			    return false
			}
			var msg = "Are you sure, you want submit request?"
			if(window.confirm(msg)){
			    var execution_data = this.state
			    $('.loader').fadeIn()
			    const auth_token = localStorage.getItem("token")
			    axios.defaults.headers.post['Authorization'] = auth_token
			    axios.post(`${API_HOST}/get/and/save/moc/execution/${this.state.DocumentNumber}/data`, execution_data)
			    .then(response => {
			        if (response.status === 200 && response.data.success) {
			            notify(response.data.message,'success')
			            $('.loader').fadeOut('slow')
			            this.setState({isDataSubmited:true})
						this.setState({isTaskSubmited:true})
			            // setTimeout(() =>{
			            //     // $('#approve-as-variant-model').modal('hide');
			            //     this.props.history.push('/moc/request/'+response.data.document_number+'/')
			            // },1000)
			        }else{
						notify(response.data.message,'warning')
			            $('.loader').fadeOut('slow')
						setTimeout(function () {
							document.querySelector('#initiatorDiv').scrollIntoView({
								behavior: 'smooth', block: "center", inline: "nearest" 
							})
						}, 100)
			        }
			    }).catch(response => {
			        // ErrorHandling(response)
			    });
			}
		}else if (this.state.submissionType == 'auto_save'){
			var execution_data = this.state
			this.setState({ isButtonClicked: false, isBlurTriggered: false});
			const auth_token = localStorage.getItem("token")
			axios.defaults.headers.post['Authorization'] = auth_token
			axios.post(`${API_HOST}/get/and/save/moc/execution/${this.state.DocumentNumber}/data`, execution_data)
			.then(response => {
				if (response.status === 200 && response.data.success) {
					notify(response.data.message,'success')
					$('.loader').fadeOut('slow')
					this.setState({isDataSubmited:true})
					this.setState({isTaskSubmited:true})
					// setTimeout(() =>{
					//     // $('#approve-as-variant-model').modal('hide');
					//     this.props.history.push('/moc/request/'+response.data.document_number+'/')
					// },1000)
				}else{
					// notify(response.data.message,'warning')
					// setTimeout(function () {
					// 	document.querySelector('#initiatorDiv').scrollIntoView({
					// 		behavior: 'smooth', block: "center", inline: "nearest" 
					// 	})
					// }, 100)
				}
			}).catch(response => {
				// ErrorHandling(response)
			});
			
			
		}
       
	}

	updateExecutionReview = (e) => {
        e.preventDefault()
        var is_valid_response = this.isValid()
        if(!is_valid_response.status){
            notify(is_valid_response.reason, 'warning')
			setTimeout(function () {
                document.querySelector('#'+is_valid_response.id).scrollIntoView({
                    behavior: 'smooth', block: "center", inline: "nearest" 
                })
            }, 100)
            return false
        }
        var msg = "Are you sure, you want update request?"
        if(window.confirm(msg)){
            var execution_data = this.state
            $('.loader').fadeIn()
            const auth_token = localStorage.getItem("token")
            axios.defaults.headers.post['Authorization'] = auth_token
            axios.post(`${API_HOST}/update/moc/execution/${this.state.executionID}/data`, execution_data)
            .then(response => {
                if (response.status === 200 && response.data.success) {
                    notify(response.data.message,'success')
                    $('.loader').fadeOut('slow')
                    this.setState({isDataSubmited:true})
					this.setState({isTaskSubmited:true})
					window.location.reload()
                    // setTimeout(() =>{
                    //     // $('#approve-as-variant-model').modal('hide');
                    //     this.props.history.push('/moc/request/'+response.data.document_number+'/')
                    // },1000)
                }else{
					notify(response.data.message,'warning')
                    $('.loader').fadeOut('slow')
					setTimeout(function () {
						document.querySelector('#initiatorDiv').scrollIntoView({
							behavior: 'smooth', block: "center", inline: "nearest" 
						})
					}, 100)
                }
            }).catch(response => {
                // ErrorHandling(response)
            });
        }
	}

	getUserPrivileges = () => {
	    axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
	    let privileges = JSON.stringify(['can_create', 'can_attach', 'can_comment', "can_approve", 'can_sign', 'can_ask_for_more_information'])
		let component = this.state.current_component_name
		let params = {"document_number": this.state.DocumentNumber, "from_new": true, "request_type": "moc_request"}
	    axios.get(`${API_HOST}/user/privileges/${component}/${privileges}/`, {params: params})
	    .then(response => {
	        if (response.status === 200) {
	            this.setState({
	                userPermissions:response.data.data,
	            })

	        }
	    }).catch(response => {
	        // ErrorHandling(response)
	    });
	}

	removeWfAttachment =(event, id_of_wf_attachment, component_id)=>{
        event.preventDefault()
        var user_resp = window.confirm("Are you sure, you want to delete the attachment?")
        if(user_resp){
            $('.card-loader').fadeIn('')
            var attachment_data = {
                workflow_definition_id:this.state.WorkflowData.workflow_definition_id,
                stage_id:this.state.WorkflowData.id,
                component_id:this.state.WorkflowComponent.id,
                wf_attachment_id:id_of_wf_attachment
            }
            axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
            axios.post(`${API_HOST}/workflow/moc-request/request/${this.props.DocumentNumber}/attachment/delete/`, attachment_data)
            .then(response => {
                if (response.status === 200) {
                    notify('Attachment deleted successfully','success')
                    this.getAllData()
                }

            }).catch(response => {
                // ErrorHandling(response)
                $('.card-loader').fadeOut('slow')
            });
        }
        
    }

	getInitiatorUserPrivileges = () => {
	    axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
	    let privileges = JSON.stringify(['can_create', 'can_attach', "can_update","can_approve"])
		let component = 'MOCRequestStage'
		let params = {"document_number": this.state.DocumentNumber, "from_initiator": true, "request_type": "moc_request"}
	    axios.get(`${API_HOST}/user/privileges/${component}/${privileges}/`, {params: params})
	    .then(response => {
	        if (response.status === 200) {
	            this.setState({
	                initiatorUserPermissions:response.data.data,
	            })

	        }
	    }).catch(response => {
	        // ErrorHandling(response)
	    });
	}
	getTaskData = () => {
		axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
		axios.get(`${API_HOST}/execution/sub/tasks/?document_number=${this.props.DocumentNumber}`,)
			.then(response => {
				var data = response.data.execution_tasks;
				this.setState({ 'tasks': data, 'is_there_pending_tasks': response.data.is_there_pending_tasks });
				if (response.data.is_there_pending_tasks && response.data.pending_tasks_count >= 0) {
					this.setState({ "isDataSubmited": true })
				}
			})
	}
	render() {
		var form_submition_info = { "form_submition_required": true, "is_required_forms_data_submitted": this.state.isDataSubmited && this.state.isHodFormSubmited }
		var ask_for_more_history_filtered = []
        if(this.state){
            if(this.state.hasOwnProperty('ask_for_more_history')){
                ask_for_more_history_filtered = this.state.ask_for_more_history.filter((each, index)=>{
                    return each.status.name_slug=="open"
                })
            }
        }
		var access_type = "view"
		if(!this.state.isDataSubmited){
			access_type = "create"
		}
        if(ask_for_more_history_filtered.length>0){
            access_type  ="update"
        }
		return(
			<div id="ExecutionofApprovedMOC">
				<div className="card mb-3" id="initiatorDiv">
					<div className="card-body">
						<div className="overflow-x-scroll px-3">
							{this.state.tasks.length > 0 ? (
								<table className="table table-bordered">
									<thead className="vertical-align-middle">
										<tr>
											<th width="20%"><b>Task Name</b></th>
											<th className="text-center"><b>Description</b></th>
											<th className="text-center"><b>Assignee</b></th>
										</tr>
									</thead>
									<tbody className="vertical-align-middle">
										{this.state.tasks.map((task, index) => (
											<tr key={index}>
												<td>
													<div className="view-task-name">
														{task.status === 'pending' ? (
															<FaRegCheckCircle className="task-icon" />
														) : task.status === 'completed' ? (
															<FaCheckCircle className="task-icon-fa" />
														) : null}
														<Link to={`/moc/request/subtask/${this.props.DocumentNumber}/${task.id}`}>Task {index + 1}</Link>
													</div>
												</td>
												<td width="50%">
													<div className="view-task-name">
														<p>
															{task.description.length > 80
																? `${task.description.slice(0, 80)}...`
																: task.description}
														</p>
													</div>
												</td>
												<td width="20%">
													<div className="view-task-name">
														<p>{task.assignee}</p>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							) : (
								<div className='d-flex justify-content-center align-items-center' style={{ fontSize: '20px' }}>
								<p><b>No execution tasks are available</b></p>
							</div>
							
							)}
							
							<div className="d-flex justify-content-end mb-3 mt-4">
								<Subtask
									document_number={this.state.DocumentNumber}
									userPermissions={this.state.initiatorUserPermissions}
									is_rejected={this.state.is_rejected}
									is_approved={this.state.is_approved}
									component_name={this.state.current_component_name}
									isTaskSubmited={this.state.isTaskSubmited}
								/>
							</div>
							{this.state.is_there_pending_tasks && (
							<div>
							<p><b>Note: Initiator component will be enable only if all tasks are closed by respective assignees. </b></p>
							</div>
						)}
						</div>
					</div>
				</div>

				<ExecutionofApprovedMOC
					ExecutionData={this.state}
					unique_combine={this.state.unique_combine}
					stage_status={this.state.stage_status}
					handleOnChange={this.handleOnChange} 
					handleOnSelectDepartment={this.handleOnSelectDepartment}
					DataSubmited={this.state.isDataSubmited } 
					userPermissions={this.state.initiatorUserPermissions}
					ask_for_more_history_filtered={ask_for_more_history_filtered}
					access_type={access_type}/>
				<JobInitiatorAndExecutor
					stage_status={this.state.stage_status}
					unique_combine={this.state.unique_combine}
					ExecutionData={this.state}
					key={this.state.unique_combine+'jobinitobj'}
					ExecutorGeneralChecksData={this.state.ExecutorGeneralChecksData}
					ChangeAuthorityStepsData={this.state.ChangeAuthorityStepsData}
					handleAuthorityChecks={this.handleAuthorityChecks}
					handleInitiatorChecks={this.handleInitiatorChecks}
					submitExecutionReview={this.submitExecutionReview}
					handleButtonClick={this.handleButtonClick}
					handleUpdateButtonClick={this.handleUpdateButtonClick}
					updateExecutionReview={this.updateExecutionReview}
					isDataSubmited={this.state.isDataSubmited}
					handleAuthorityComments={this.handleAuthorityComments}
					handleOnChange={this.handleOnChange}
					WorkflowData={this.state.WorkflowData}
					WorkflowComponent={this.state.WorkflowComponent}
					DocumentNumber={this.state.DocumentNumber}
					userPermissions={this.state.initiatorUserPermissions}
					ask_for_more_history_filtered={ask_for_more_history_filtered}
					removeWfAttachment={this.removeWfAttachment}
					getOnlyWfData={this.getOnlyWfData}
					access_type={access_type}
					moc_status={this.state.moc_status}
					/>
				<div>
					<div className='card mb-3'>
					    <div className='p-4'>
					    	<div>
					    		{false ?
					    		    <div>
					    		        state Plant MoC opinion {this.state.plant_moc_opinion}
					    		        <br/>
					    		        props isCollapsed {this.props.isCollapased ? 'YES': 'NO'}
					    		        <br/>
					    		        state isCollapsed {this.state.isCollapased ? 'YES': 'NO'}
					    		        <br/>
					    		        props is_approved {this.props.is_approved ? 'YES': 'NO'}
					    		        <br/>
					    		        state is_approved {this.state.is_approved ? 'YES': 'NO'}
					    		        <br/>
										show_plant_moc_opinion_card {this.state.show_plant_moc_opinion_card ? 'yes': 'no'}
                                		<br/>
					    		        {this.state.WorkflowComponent?
						    		        <div>
						    		        workflow_action_logs &nbsp;
						    		        {this.state.WorkflowComponent.workflow_action_logs.map((row, index)=>{
						    		            return('id:' + row['id']+' -- status:' + row['status'] +', ')
						    		        })}
						    		        <br/>
						    		        wf_plant_moc_lead_review &nbsp;
						    		        {this.state.WorkflowComponent.wf_plant_moc_lead_review.map((row, index)=>{
						    		            return ('id:'+row['id']+' -- status:'+ row['action']['name'] +', ')
						    		        })}
						    		        </div>:null
						    		     }
					    		    </div>
					    		    :null
					    		}
					    	</div>
					    	<ReviewByHod
					    		unique_combine={this.state.unique_combine}
					    		stage_status={this.state.stage_status}
					    		isDataSubmited={this.state.isTaskSubmited}
					    		handleHodFormSubmitStatus={this.handleHodFormSubmitStatus}
					    		userPermissions={this.state.userPermissions}
					    		DocumentNumber={this.state.DocumentNumber}
								isRejected={this.state.show_plant_moc_opinion_card}
								/>
					        <div className='mt-4 row'>
								{this.state.isDataSubmited &&this.state.isTaskSubmited?
					            <Comment is_rejected={this.state.is_rejected}
					            	stage_status={this.state.stage_status}
					            	unique_combine={this.state.unique_combine}
					            	PlantMOCOpinion={this.state.plant_moc_opinion}
					                  isRejected={this.state.show_plant_moc_opinion_card}
					                  is_approved={this.state.is_approved}
					                  WorkflowData={this.state.WorkflowData}
					                  WorkflowComponent={this.state.WorkflowComponent}
					                  DocumentNumber={this.state.DocumentNumber}
					                  RequestType="moc-request" 
					                  ref={this.commentRef}
					                  userPermissions={this.state.userPermissions}/>
									  :null}
					            <div className="col-lg-2 col-md-6 col-sm-4 col-4"></div>
					            {this.state.WorkflowSignatures.length > 0 ?
					               <Signed SignatureData={this.state.WorkflowSignatures[0]}/> : ""
					            }
								{/* {this.state.show_plant_moc_opinion_card ?
									<RejectComponent
										CurrentStageStatus={this.state.stage_status}
										isRejected={this.state.show_plant_moc_opinion_card}
										WorkflowData={this.state.WorkflowData}
										WorkflowComponent={this.state.WorkflowComponent}
										DocumentNumber={this.state.DocumentNumber}
										userPermissions={this.state.userPermissions}
										RequestType="moc-request"
									/>  : null
								} */}
					        </div>
					        {(this.props.isCollapased || (this.state.show_plant_moc_opinion_card && this.state.plant_moc_opinion != 'Rejected'))
					            ? '':this.state.isDataSubmited && this.state.isTaskSubmited ?
					            <div  className='d-flex w-100 justify-content-end align-items-end tri-buttons mt-4'>
					                <div className='pe-2'>
										<AskButton 
											IsApproved={this.state.is_approved}
											stage_status={this.state.stage_status}
											key={this.state.unique_combine+'askbtn'}
											WorkflowData={this.state.WorkflowData} 
											WorkflowComponent={this.state.WorkflowComponent}  
											DocumentNumber={this.state.DocumentNumber}
											current_component_name={this.state.current_component_name}
											ask_for_more_history={this.state.ask_for_more_history}
											RequestType="moc-request"
											userPermissions={this.state.userPermissions}
											handleOfComment = {this.handleOfComment}
										/>
									</div>
									{/* <div className='pe-2'>
					                    <RejectButton
					                    	stage_status={this.state.stage_status}
					                        PlantMOCOpinion={this.state.plant_moc_opinion}
					                        IsApproved={this.state.is_approved}
					                        WorkflowData={this.state.WorkflowData} 
					                        WorkflowComponent={this.state.WorkflowComponent}  
					                        DocumentNumber={this.state.DocumentNumber}
					                        key={this.state.unique_combine+'rejbtn'}
					                        unique_combine = {this.state.unique_combine}
					                        ask_for_more_history={this.state.ask_for_more_history}
					                        current_component_name={this.state.current_component_name}
					                        RequestType="moc-request"
					                        userPermissions={this.state.userPermissions}
											handleOfComment = {this.handleOfComment}/>
					                </div> */}
					                <div>
					                    <ApproveButton
					                    	stage_status={this.state.stage_status}
					                        key={this.state.unique_combine+'_approve'}
					                        IsApproved={this.state.is_approved}
					                        WorkflowData={this.state.WorkflowData}
					                        WorkflowComponent={this.state.WorkflowComponent}
					                        DocumentNumber={this.state.DocumentNumber}
					                        key={this.state.unique_combine+'rejbtn'}
					                        unique_combine = {this.state.unique_combine}
					                        ask_for_more_history={this.state.ask_for_more_history}
					                        current_component_name={this.state.current_component_name}
					                        RequestType="moc-request"
					                        form_submition_info={form_submition_info}
					                        handleOfComment = {this.handleOfComment}
					                        userPermissions={this.state.userPermissions}
					                    />
					                </div>
					            </div>:null
					        }
					        <div className='mt-2'>
					            <Sign IsSignatures={this.state.WorkflowSignatures.length > 0?true:false} 
					            IsApproved={this.state.is_approved}
					            WorkflowData={this.state.WorkflowData} WorkflowComponent={this.state.WorkflowComponent}  
					            DocumentNumber={this.state.DocumentNumber} RequestType="moc-request" 
					            userPermissions={this.state.userPermissions}
								current_component_name={this.state.current_component_name}
					            />
					        </div>
					    </div>
					</div>
				</div>
			</div>
		)
	}
}

export default CommonExecutionAndHODReviewComponent