import axios from 'axios';
import React, { Component } from 'react';
import { API_HOST } from '../../Settings';
import $ from 'jquery';
import notify from '../CommonComponents/Notify';


class Sign extends Component {
    constructor(props){
        super(props)
        this.state = {
            is_checked : false,
            siginData:{}
        }
    }
    // componentDidMount(){
    //     this.setState({
    //         is_checked: true
    //     })
    // }
    // changeHandler = (e) =>{
    //     this.setState({
    //         is_checked: e.currentTarget.checked
    //     })        
    // }
    // componentDidUpdate(){
    //     document.getElementById("sign_checkbox").checked = this.state.is_checked;
    // }

    handleSignCheckBox = (event)=>{
        if(event.target.type=="checkbox"){
            this.setState({
                "is_checked": event.target.checked
            })
        }
    }

    signSubmitHandler = (e) =>{
        e.preventDefault()
        $('.loader').fadeIn()
        var comments_data = {
            model:"workflow_signatures",
            data:{
                    workflow_definition_id:this.props.WorkflowData.workflow_definition_id,
                    stage_id:this.props.WorkflowData.id,
                    component_id:this.props.WorkflowComponent.id,
                }
        }
        
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
        axios.post(`${API_HOST}/workflow/${this.props.RequestType}/request/${this.props.DocumentNumber}/form/data/save/`, comments_data)
		.then(response => {
			if (response.status === 200) {
                if( this.props.current_component_name){
                    localStorage.setItem('current_component_name', this.props.current_component_name);
                }
                notify('Signed successfully','success')
                this.setState({
                    siginData:response.data.data.workflow_signatures
                })
                setTimeout(()=>{
                    $('.loader').fadeOut('slow')
                    window.location.reload();
                }, 3000)
			}

		}).catch(response => {
            // ErrorHandling(response)
        });
    }
    render(){
        return(
            <div>
            {(this.props.userPermissions.can_sign) && this.props.IsApproved ? 
                <div>
                    {this.props.IsSignatures ? null:
                    <div className='row sign-row'>
                        <div className='col-lg-10 col-md-8 col-6 review-row'>
                            <div className='form-check float-right'>
                                <input className="form-check-input" onChange={this.handleSignCheckBox}
                                    type="checkbox"  checked={this.state.is_checked}/>
                                <label className="form-check-label">
                                        I agree to sign this request
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-6'>
                            {this.state.is_checked==false?
                                <button className="btn btn-dark float-right" type="button" id="sign" disabled={true}>Sign</button>
                                :
                                <button className="btn btn-dark float-right" type="button" id="sign" onClick={this.signSubmitHandler}>Sign</button>
                            }
                        </div>
                    </div>}
                </div>
            : null}
            </div>
        )
    }
}

export default Sign;