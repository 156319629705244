import React, { Component } from 'react';
import "../../Styles/brioStyle.css"
import { API_HOST } from '../../Settings.js'
import notify from '../CommonComponents/Notify';
import $ from 'jquery'
import axios from 'axios';

class Reports extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedvalue: "",
            site: [],
            workflow: '',
            plant_names: [],
            siteName: '',
            sites_of_user: [],
            sites_of_user_cummulative:[]
        }
    }
    componentDidMount() {
        this.getSiteList();
        const urlParams = new URLSearchParams(window.location.search);
        const workflow = localStorage.getItem("workflow");
        this.setState({ workflow: workflow })
        if (workflow === 'moc') {
            this.setState({ selectedvalue: 'moc_report', })
        } else if (workflow === 'manpower') {
            this.setState({ selectedvalue: 'manpower_report', })
        }
        $('.loader').fadeOut('slow')
        document.querySelector(".notification-Block")?.classList.add("d-none")
        document.querySelector(".user-profile")?.classList.add("d-none")
    }

    componentDidUpdate(prevProps, prevState) {
        const urlParams = new URLSearchParams(window.location.search);
        const workflow = localStorage.getItem("workflow");
        if (workflow) {
            window.history.replaceState({}, document.title, window.location.pathname);
        }
        if(this.state.selectedvalue === 'cumulative_report'&& prevState.selectedvalue !== 'cumulative_report'){
            this.site_of_users_data_cumulative();
            }
    }

    moc_report = (event) => {
        event.preventDefault();
        const from_date_of_implementation = document.getElementById("from_date_of_implementation").value;
        const to_date_of_implementation = document.getElementById("to_date_of_implementation").value;
        const site_name = document.getElementById("site_name").value;
        if (from_date_of_implementation && to_date_of_implementation && site_name) {
            $('.loader').fadeIn();
            var url = document.querySelector("#selected_option").value === "moc_report" ? `${API_HOST}/mocreport/` : `${API_HOST}/manpower/report/`
            const form_data = 'from_date_of_implementation=' + from_date_of_implementation + '&to_date_of_implementation=' + to_date_of_implementation + '&site_name=' + site_name;
            axios({
                method: 'post',
                url: url,
                data: form_data,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": localStorage.getItem('token')
                }
            }).then((response) => {
                if (response.data !== "No Records") {
                    const saveData = (function () {
                        const a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        return function (data, fileName) {
                            const blob = new Blob([data], { type: "text/csv" }),
                                url = window.URL.createObjectURL(blob);
                                a.href = url;
                                a.download = fileName;
                                a.click();
                                window.URL.revokeObjectURL(url);
                        };
                    }());
                    const fileName = document.querySelector("#selected_option").value === "moc_report" ? "Moc-Logsheet" : "Manpower-Logsheet";
                    saveData(response.data, fileName);
                    $('.loader').fadeOut();
                }
                else {
                    notify('No Records', 'warning')
                    $('.loader').fadeOut();
                }
            })
        }
        else {
            notify('Please select site id', 'error');
            $('.loader').fadeOut();
        }
    }
    handleDateChange = (event) => {
       this.checkDates();
    };
    checkDates = () => {
        const from_date_of_implementation = document.getElementById("from_date_of_implementation").value;
        const to_date_of_implementation = document.getElementById("to_date_of_implementation").value;

        if (from_date_of_implementation && to_date_of_implementation) {
            const fromDate = new Date(from_date_of_implementation);
            const toDate = new Date(to_date_of_implementation);

            if (fromDate.getFullYear() !== toDate.getFullYear()) {
                notify("Please select the dates within the same year",'warning');
            }
        }
    };

    cumulative_report = (event) => {
        event.preventDefault();
        const from_date_of_implementation =  document.getElementById("from_date_of_implementation").value;
        const to_date_of_implementation =  document.getElementById("to_date_of_implementation").value;
        const site_name = document.getElementById("site_name_user").value
        const sites = this.state.sites_of_user_cummulative;
        const workflow = this.state.workflow;
        if (from_date_of_implementation !== "" &&  to_date_of_implementation !== '')  {
          $('.loader').fadeIn();
            const form_data = {}
            form_data['from_date'] = from_date_of_implementation
            form_data['to_date'] = to_date_of_implementation
            form_data['site_name'] = site_name;
            form_data['workflow'] = workflow;
            form_data['sites'] = sites;
            const auth_token = localStorage.getItem("token")
            const requestData = {
                form_data: form_data,
            };
            axios.defaults.headers.post['Authorization'] = auth_token
            axios.post(`${API_HOST}/cumulativereport/`, requestData)
                .then((response) => {
                    if (response.data !== "No Records" && response.data !== 'year') {
                        const saveData = (function () {
                            const a = document.createElement("a");
                            document.body.appendChild(a);
                            a.style = "display: none";
                            return function (data, fileName) {
                                const blob = new Blob([data], { type: "text/csv" }),
                                    url = window.URL.createObjectURL(blob);
                                a.href = url;
                                a.download = fileName;
                                a.click();
                                window.URL.revokeObjectURL(url);
                            };
                        }());
                        const filename = workflow.charAt(0).toUpperCase() + workflow.slice(1) + " " + "Cumulative Report" +"_"+site_name+"_(" + from_date_of_implementation + "To" + to_date_of_implementation + ")";
                        const fileName = filename;
                        saveData(response.data, fileName);
                        $('.loader').fadeOut();
                    }
                    else if( response.data === 'year'){
                        notify('Please select the dates within the same year', 'warning')
                        $('.loader').fadeOut();
                    }
                    else {
                        notify('No Records', 'warning')
                        $('.loader').fadeOut();
                    }
                })
        }
        else {
            notify('Please select Dates', 'error');
            $('.loader').fadeOut();
        }
    }

    site_of_users_data = () => {
        var user_email = JSON.parse(localStorage.getItem('user_data')).email
        const form_data = {}
        form_data['user_email'] = user_email;
        const auth_token = localStorage.getItem("token")
        const requestData = {
            form_data: form_data,
        };
        axios.defaults.headers.post['Authorization'] = auth_token
        axios.post(`${API_HOST}/get/sites/by/user/roles/`, requestData)
            .then((response) => {
                var site_names = response.data.data;
                if (!site_names || site_names.length === 0) {
                    document.getElementById('site_names_user').style.display = 'none';
                } else {
                    this.setState({ sites_of_user: site_names });
                }
            }).catch((error) => {
            });
    }
    site_of_users_data_cumulative = () => {
        var user_email = JSON.parse(localStorage.getItem('user_data')).email
        const form_data = {}
        form_data['user_email'] = user_email;
        const auth_token = localStorage.getItem("token")
        const requestData = {
            form_data: form_data,
        };
        axios.defaults.headers.post['Authorization'] = auth_token
        axios.post(`${API_HOST}/get/sites/by/user/roles/cummulative/`, requestData)
            .then((response) => {
                var site_names = response.data.data;
                    this.setState({ sites_of_user_cummulative: site_names })
            }).catch((error) => {
            });
    }

    site_users_data = (event) => {
        event.preventDefault();
        const site_name = document.getElementById("site_name").value;
        if (site_name !== "") {
            $('.loader').fadeIn();
            const form_data = {}
            form_data['site_name'] = site_name;
            const auth_token = localStorage.getItem("token")
            const requestData = {
                form_data: form_data,
            };
            axios.defaults.headers.post['Authorization'] = auth_token
            axios.post(`${API_HOST}/site/users/download/`, requestData)
                .then((response) => {
                    if (response.data !== "No Records") {
                        const saveData = (function () {
                            const a = document.createElement("a");
                            document.body.appendChild(a);
                            a.style = "display: none";
                            return function (data, fileName) {
                                const blob = new Blob([data], { type: "text/csv" }),
                                    url = window.URL.createObjectURL(blob);
                                a.href = url;
                                a.download = fileName;
                                a.click();
                                window.URL.revokeObjectURL(url);
                            };
                        }());
                        const fileName = response.data.filename;
                        saveData(response.data.data, fileName);
                        $('.loader').fadeOut();
                    }
                    else {
                        notify('No Users', 'warning')
                        $('.loader').fadeOut();
                    }

                })

        }
        else {
            notify('Please select Site', 'error');
            $('.loader').fadeOut();
        }
    }
    handleSiteChange = (event) => {
        event.preventDefault();
        const site_name = event.target.value;
        this.setState({ siteName: site_name });
        if (site_name !== "") {
            $('.loader').fadeIn();
            const form_data = { site_name };
            const auth_token = localStorage.getItem("token")
            const requestData = { form_data };

            axios.defaults.headers.post['Authorization'] = auth_token
            axios.post(`${API_HOST}/get/plant/names/`, requestData)
                .then((response) => {
                    const plant_names = response.data.plant_names;
                    if (!plant_names || plant_names.length === 0) {
                        const message = response.data.message;
                        notify(message, 'warning');
                        this.setState({ siteName: '' });
                    } else {
                        this.setState({ plant_names: plant_names });
                    }
                    $('.loader').fadeOut();
                })
                .catch((error) => {
                    console.error('Error fetching plant names:', error);
                    notify('Error fetching plant names', 'error');
                    $('.loader').fadeOut();
                });
        } else {
            notify('Please Select Site', 'warning')
            $('.loader').fadeOut();
        }
    }
    
    plant_users_data = (event) => {
        event.preventDefault();
        const site_name = document.getElementById("site_name").value;
        if (site_name !== "") {
            $('.loader').fadeIn();
            const plant_name = document.getElementById("plant_name").value;
            const form_data = {}
            form_data['plant_name'] = plant_name;
            form_data['site_name'] = site_name;
            const auth_token = localStorage.getItem("token")
            const requestData = {
                form_data: form_data,
            };
            axios.defaults.headers.post['Authorization'] = auth_token
            axios.post(`${API_HOST}/plant/users/download/`, requestData)
                .then((response) => {
                    if (response.data !== "No Records") {
                        const saveData = (function () {
                            const a = document.createElement("a");
                            document.body.appendChild(a);
                            a.style = "display: none";
                            return function (data, fileName) {
                                const blob = new Blob([data], { type: "text/csv" }),
                                    url = window.URL.createObjectURL(blob);
                                a.href = url;
                                a.download = fileName;
                                a.click();
                                window.URL.revokeObjectURL(url);
                            };
                        }());
                        const fileName = response.data.filename;
                        saveData(response.data.data, fileName);
                        $('.loader').fadeOut();
                    }
                    else {
                        notify('No Records', 'warning')
                        $('.loader').fadeOut();
                    }

                })
        }
        else {
            notify('Please select Site', 'error');
            $('.loader').fadeOut();
        }
    }


    handle_select_change = (event) => {
        this.setState({ selectedvalue: event.target.value });
        this.setState({ siteName: '' });
    }
    getSiteList = () => {
        axios({
            method: 'get',
            url: `${API_HOST}/sitenames/`,
            headers: {
                "Content-Type": "text",
                "Authorization": localStorage.getItem('token')
            }
        }).then((response) => {

            this.setState({ site: response.data });

        })
    }

    getCurrentDateInput = () => {
        const dateObj = new Date();
        const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
        const day = ("0" + dateObj.getDate()).slice(-2);
        const year = dateObj.getFullYear();
        const shortDate = `${year}-${month}-${day}`;
        return shortDate;
    }

    getDropList = () => {
        const year = new Date().getFullYear();
        return (
            Array.from(new Array(5), (v, i) =>
                <option key={i} value={year - i}>{year - i}</option>
            )
        )
    }

    render() {
        var site_names = this.state.site.map((items, key) => {
            return (
                <option key={key} value={this.items}>{items}</option>
            )

        }
        )
        var site_names_of_user = this.state.sites_of_user.map((items, key) => {
            return (
                <option key={key} value={this.items}>{items}</option>
            )

        }
        )
        var site_names_of_user_cummulative = this.state.sites_of_user_cummulative.map((items, key) => {
            return (
                <option key={key} value={this.items}>{items}</option>
            )

        }
        )
        var plant_site_names_ = this.state.plant_names.map((items, key) => {
            return (
                <option key={key} value={this.items}>{items}</option>
            )

        }
        )
        var is_admin = JSON.parse(localStorage.getItem('user_data')).is_admin

        return (
            <form>
                <div className='container'>
                    <div className='card'>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-lg-2 col-md-2 col-sm-6 col-4 mt-1'>
                                    <h5 className="card-title mb-3"> <b> Reports</b></h5>
                                </div>
                                <div className='col-lg-3 col-md-3 col-sm-6 col-8 mt-1'>
                                    <select className="form-select" id="selected_option" value={this.state.selectedvalue} onChange={this.handle_select_change}>
                                        {(this.state.workflow === "moc" && <option value="moc_report">MoC Logs</option>) || (this.state.selectedvalue === "moc_report" && <option value="moc_report">MoC Logs</option>) || (this.state.workflow === 'moc' && this.state.selectedvalue === 'cumulative_report' && <option value="moc_report">MoC Logs</option>) || (this.state.workflow === 'moc' && this.state.selectedvalue === 'site_users' && <option value="moc_report">MoC Logs</option>) || (this.state.workflow === 'moc' && this.state.selectedvalue === 'plant_users' && <option value="moc_report">MoC Logs</option>)}
                                        {(this.state.workflow === "manpower" && <option value="manpower_report">Man Power Logs</option>) || (this.state.selectedvalue === "manpower_report" && <option value="manpower_report">Man Power Logs</option>) || (this.state.workflow === 'manpower' && this.state.selectedvalue === 'cumulative_report' && <option value="manpower_report">Man Power Logs</option>) || (this.state.workflow === 'manpower' && this.state.selectedvalue === 'site_users' && <option value="manpower_report">Man Power Logs</option>) || (this.state.workflow === 'manpower' && this.state.selectedvalue === 'plant_users' && <option value="manpower_report">Man Power Logs</option>)}
                                        <option value="cumulative_report">Cumulative Report</option>
                                        {(this.state.workflow !== 'ibd' && is_admin && <option value="site_users">Site Users</option>)}
                                        {(this.state.workflow !== 'ibd' && is_admin && <option value="plant_users">Plant Users</option>)}
                                    </select>
                                </div>
                            </div>
                            {this.state.selectedvalue === "moc_report" || this.state.selectedvalue === "manpower_report" ? <div className='row mt-2'>
                                <div className="col-lg-3 col-md-6 col-sm-12 col-12"> <label className="form-label" htmlFor="fromdateImplementation">From Date</label>
                                    <input autoComplete='on' defaultValue={this.getCurrentDateInput()} className='form-control from_date_of_implementation' name="from_dateImplementation" id="from_date_of_implementation" type="date" />
                                </div>

                                <div className="col-lg-3 col-md-6 col-sm-12 col-12"> <label className="form-label" htmlFor="todateImplementation">To Date</label>
                                    <input autoComplete='on' defaultValue={this.getCurrentDateInput()} className='form-control to_date_of_implementation' name="to_dateImplementation" id="to_date_of_implementation" type="date" />
                                </div>

                                <div className='col-lg-3 col-md-6 col-sm-6 col-6' >
                                    <label className="form-label" htmlFor="plantarea">Site</label>
                                    <select className="form-select" id="site_name">
                                        {site_names}
                                    </select>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 col-12 text-center align-self-flex-end pt-2'>
                                    <button className="btn btn-dark float-left" type="button" id="submit_moc" onClick={this.moc_report}  >Download</button>
                                </div>
                            </div>
                                : null}
                            {this.state.selectedvalue === "cumulative_report" ?
                                <div className='row mt-2'>
                                     <div className="col-lg-3 col-md-6 col-sm-12 col-12"> <label className="form-label" htmlFor="fromdateImplementation">From Date</label>
                                        <input autoComplete='on' defaultValue={this.getCurrentDateInput()}   onChange={this.handleDateChange}className='form-control from_date_of_implementation' name="from_dateImplementation" id="from_date_of_implementation" type="date"/>
                                        </div>

                                        <div className="col-lg-3 col-md-6 col-sm-12 col-12"> <label className="form-label" htmlFor="todateImplementation">To Date</label>
                                        <input autoComplete='on' defaultValue={this.getCurrentDateInput()} onChange={this.handleDateChange} className='form-control to_date_of_implementation' name="to_dateImplementation" id="to_date_of_implementation" type="date"/>
                                        </div>
                                    <div className='col-lg-3 col-md-6 col-sm-6 col-6' id="site_names_user" >
                                        <label className="form-label" htmlFor="plantarea">Site</label>
                                        <select className="form-select" id="site_name_user">
                                        <option value="all">All of the below</option>
                                            {site_names_of_user_cummulative}
                                        </select>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 text-center align-self-flex-end pt-2'>
                                        <button className="btn btn-dark float-left" type="button" id="submit_cumulative" onClick={this.cumulative_report} >Download</button>
                                    </div>
                                </div> : null}
                            {/* {this.state.selectedvalue === "site_wise_cumulative_report" ?
                                <div className='row mt-2'>
                                    <div className='col-lg-3 col-md-6 col-sm-12 col-12'>
                                        <label className="form-label" htmlFor="dateImfplementation">Year of Moc/ManPower</label>
                                        <select className="form-select year_of_moc" id="year_of_moc" onChange={this.onChange}>
                                            {this.getDropList()}
                                        </select>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-6 col-6' id="site_name_user" >
                                        <label className="form-label" htmlFor="plantarea">Site</label>
                                        <select className="form-select" id="site_name_user">
                                            {site_names_of_user}
                                        </select>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 text-center align-self-flex-end pt-2'>
                                        <button className="btn btn-dark float-left" type="button" id="submit_cumulative" onClick={this.cumulative_report} >Download</button>
                                    </div>
                                </div> : null} */}
                            {this.state.selectedvalue === 'site_users' ?
                                <div className='row mt-2'>
                                    <div className='col-lg-3 col-md-6 col-sm-12 col-12' >
                                        <label className="form-label" htmlFor="plantarea">Site</label>
                                        <select className="form-select" id="site_name">
                                        <option value="all">All</option>
                                            {site_names}
                                        </select>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 text-center align-self-flex-end pt-2'>
                                        <button className="btn btn-dark float-left" type="button" id="submit_site_users" onClick={this.site_users_data} >Download</button>
                                    </div>
                                </div> : null}
                            {this.state.selectedvalue === 'plant_users' ?
                                <div className='row mt-2'>
                                    <div className='col-lg-3 col-md-6 col-sm-6 col-6' >
                                        <label className="form-label" htmlFor="plantarea">Site</label>
                                        <select className="form-select" id="site_name" onChange={this.handleSiteChange}>
                                            <option value=''>Select Site</option>
                                            {site_names}
                                        </select>
                                    </div>
                                    {this.state.siteName !== '' && (
                                        <div className='col-lg-3 col-md-6 col-sm-6 col-6'>
                                            <label className="form-label" htmlFor="plantarea">Plant</label>
                                            <select className="form-select" id="plant_name">
                                            <option value="all">All</option>
                                                {plant_site_names_}  
                                            </select>
                                        </div>
                                    )}
                                    {this.state.siteName !== '' && (
                                        <div className='col-lg-3 col-md-6 col-sm-12 col-12 text-center align-self-flex-end pt-2'>
                                            <button className="btn btn-dark float-left" type="button" id="submit_site_users" onClick={this.plant_users_data} >Download</button>
                                        </div>
                                    )}
                                </div> : null}
                        </div>
                    </div>
                </div>
            </form>
        )
    }

}


export default Reports;