import React, { Component } from 'react';
import AssessmentTableRow from './AssessmentTableRow';

class AssessmentTable extends Component {

    render(){
        return(
            <table className='table table-bordered'>
                <thead className='vertical-align-middle'>
                    <tr>
                    <th width="35%">
                        <b>Does the change proposal introduce or alter?</b>
                        <br/>
                        <span className='fontWeight-100 font-14'> (All below options are mandatory)</span>
                    </th>
                    <th className='text-center'><b>Yes or No</b></th>
                    <th className='text-center' width="50%">
                        <b>If yes, What problem has been created and what action is recommended to fix it?</b>
                    </th>
                    </tr>
                </thead>
                <tbody className='vertical-align-middle'>
                    <AssessmentTableRow unique_combine={this.props.unique_combine} onChangeHandler = {this.props.onChangeHandler} assessmentData = {this.props.assessmentData} accessType={this.props.accessType}/>
                </tbody>
            </table>
        )
    }
}
export default AssessmentTable;