import React, { Component } from 'react';
import ItemTableRow from './ItemTableRows';


class ItemTable extends Component{
    render(){
        return(
            <table className='table table-bordered'>
                <thead className='vertical-align-middle'>
                    <tr>
                        <th width="40%"><b>Item</b></th>
                        <th className='text-center'><b>Yes or No</b></th>
                        <th className="text-center"><b>To be updated by whom</b></th>
                        <th className='text-center' width="30%"> <b>Date Complete</b></th>
                    </tr>
                </thead>
                <tbody className='vertical-align-middle'>
                    <ItemTableRow unique_combine={this.props.unique_combine} ItemOnChange={this.props.ItemOnChange} onChangeHandler = {this.props.onChangeHandler} itemData = {this.props.itemData} common_min_date_items={this.props.common_min_date_items} accessType={this.props.accessType} />
                </tbody>
            </table>
        )
    }
}

export default ItemTable;