import React,{ Component } from "react";
import ThreeToggle from '../../CommonComponents/ThreeToggle';
import { getPlantAndEquipmentName } from '../../CommonComponents/MiscellaneousObject';

class PlantAndEquipment extends Component {
    constructor() {
        super();
        this.state = {
            firstVal : "Yes",
            secondVal :"No",
            thirdVal: " N/A",
        }
    }
    render() {
        var tr_content = getPlantAndEquipmentName().map((PlantAndEquipment,key) => {
            let tr_row = PlantAndEquipment.rows.map((names,key)=>{
                return(
                        <tr key={key}>
                            <td width="30%">{names}</td>
                            <td className='three-toggle-align-center'>
                            <ThreeToggle firstVal={this.state.firstVal} secondVal={this.state.secondVal} thirdVal={this.state.thirdVal}/>
                            </td>
                            <td width="50%">
                            <textarea className='form-control brio-form-control' rows="3" name="MOC" id="MOC" type="text"></textarea>
                            </td>
                        </tr>        
                )
            })
            return(
                [
                    tr_row
                ]
            )
        })
        return(
            tr_content
        )
    }
}

export default PlantAndEquipment;