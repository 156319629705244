import React, { Component, createRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import AssessmentTable from './AssessmentTable';
import Enclosures from './EnclosureComponent';
import BriefDescription from './BriefDescription';
import ChangeSought from './ChangeSought';
import TypeOfChange from './TypeOfChange';
import Comment from "../ConsentComponents/Comment";
import ItemTable from './ItemTable';
import Toggle from '../CommonComponents/Toggle';
import { getItemRowNames, getAssessmentRowNames } from '../CommonComponents/MiscellaneousObject';
import $ from 'jquery'
import { API_HOST } from '../../Settings.js'
import notify from '../CommonComponents/Notify';
import axios from 'axios';
import Signed from '../ConsentComponents/Signed';
import {getCommonMinDateItems} from '../CommonFunctions'

class MOCRequestStage extends Component {
    constructor(props) {
        super(props)
        this.commentRef = createRef();
        this.state =
        {
            "WorkflowData":{},
            "WorkflowComponent":{},
            "WorkflowSignatures":[],
            "enable_signing":false,
            "enable_approval":false,
            "enable_comments":false,
            "ask_for_more_history": [],
            "departments_data": [],
            "plant_data": [],
            is_approved:false,
            is_rejected:false,
            DocumentNumber:this.props.DocumentNumber,
            requestID: this.props.match.params.requestID,
            proposed_change: '',
            sitename: '',
            site_name_slug:'',
            plant: '',
            date_of_moc: '',
            proposal_initiated_by: '',
            department: '',
            proposal_implementation_period: '',
            change_sought: [],
            brief_description: {
                name_of_product: '',
                sap_material_code: '',
                customer_applicable: '',
                product_manger: '',
                existing_status: '',
                proposed_change: '',
                probable_cause: '',
                expected_benefits: ''
            },
            enclosures: {
                operation_analysis: '',
                attachment_url: '',
                quality_of_product: false,
                describe_in_detail: '',
                signed_url:'',
                file_size:"",
                attachment:''
            },
            attachment_name:"",
            attachment_url:"",
            assessment_management: getAssessmentRowNames(),
            item: getItemRowNames(),
            // active_link_id : '1',
            unique_stage_id: '1',
            unique_comp_id: '1',
            unique_combine: 's1c1',
            current_component_name: "MOCRequestStage",
            attachments:[
                {
                    attachment: "",
                    filename: "",
                    file_size: 0,
                    signed_url:"",
                    attachment_filename: ""
                }
            ],
            moc_status: "",
            WorkflowAttachments:[],
            attachments_save_in_db:[],
            userPermissions:{},
            WorkflowSignatures:[],
            ask_more_stage_name_slug: "",
            deletable_wf_attachments:[],
            enable_upload_attachment:true,
        }
    }


    getUserPrivileges = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        let privileges = JSON.stringify(['can_view', 'can_update', 'can_create', 'can_attach', 'can_comment', 'can_approve', 'can_reject', 'can_ask_for_more_information', 'can_sign'])
        let component = this.state.current_component_name
        let params = {"document_number": this.state.requestID, "from_new": true, "request_type": "moc_request"}
        axios.get(`${API_HOST}/user/privileges/${component}/${privileges}/`,{params: params} )
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    userPermissions:response.data.data,
                })

            }
        }).catch(response => {
            // ErrorHandling(response)
        });
    }

    getDepartmentData = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/all/departments/data/`, )
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    departments_data:response.data.departments_data,
                })
                this.getPlantData()
            }

        }).catch(response => {
            // ErrorHandling(response)
        });
        $('.card-loader').fadeOut('slow')
    }
    getPlantData = () => {        
        if(this.state.site_name_slug){
            axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
            axios.get(`${API_HOST}/get/plants/in/site/${this.state.site_name_slug}/`, )
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        plant_data: response.data.plant_data,
                    })
                }

            }).catch(response => {
                // ErrorHandling(response)
            });
        }
        $('.card-loader').fadeOut('slow')
    }


    handleChange = (e, state_obj) => {
        if(e.currentTarget.value == ""){e.currentTarget.classList.add("error")}
        if(e.currentTarget.value && e.currentTarget.classList.contains("error")){e.currentTarget.classList.remove("error")}
        const prevstate = this.state
        const sitename_cached = prevstate.sitename
        // Get the existing cached data from localStorage
        const cachedData = JSON.parse(localStorage.getItem('cachedData')) || {};
        if (state_obj == 'dateImplementation') {
            this.setState({proposal_implementation_period: e.target.value})
            cachedData[state_obj] = { ...cachedData[state_obj], [e.target.name]: e.target.value };
            cachedData['sitename'] = sitename_cached;
        }
        else if (state_obj == 'type_of_change') {
            prevstate[e.target.name] = e.target.value
            cachedData[state_obj] = { ...cachedData[state_obj], [e.target.name]: e.target.value };
            cachedData['sitename'] = sitename_cached;
        } else if (state_obj == 'brief_description') {
            prevstate['brief_description'][e.target.name] = e.target.value
            cachedData[state_obj] = { ...cachedData[state_obj], [e.target.name]: e.target.value };
        } else if (state_obj == 'enclosures') {
            prevstate['enclosures'][e.target.name] = e.target.value
            cachedData[state_obj] = { ...cachedData[state_obj], [e.target.name]: e.target.value };
        } else if (state_obj == 'change_sought') {
            if(e.target.type=='checkbox'){
                if(e.target.checked){
                    prevstate['change_sought'].push(e.target.value)
                }else{
                    var result = prevstate['change_sought'].filter(function(elem){
                        return elem != e.target.value; 
                    });
                    prevstate["change_sought"] = result
                }
                cachedData[state_obj] = this.state.change_sought; // Cache the entire array
            }
        } else {
            prevstate[e.target.name] = e.target.value
        }

        // Save the updated cached data back to localStorage
        cachedData['document_number'] = this.props.DocumentNumber
        localStorage.setItem('cachedData', JSON.stringify(cachedData));
        this.setState({ prevstate })
    
    }

    handleTypeOfChangeDate = (e, for_attribute)=>{
        if(for_attribute==='dateImplementation'){
            this.setState({proposal_implementation_period: e.target.value})
        }else if(for_attribute==='date_of_moc'){
            this.setState({date_of_moc: e.target.value})
        }
    }

    getUniqueIdOfWhomId =(mainIndex, stepIndex)=>{
        var unique_whom_id = this.state.unique_combine+'_item_'+mainIndex+'_ir_'+stepIndex+'_whom_id'
        return unique_whom_id
    }

    getUniqueIdOfDateId =(mainIndex, stepIndex)=>{
        var unique_date_id = this.state.unique_combine+'_item_'+mainIndex+'_ir_'+stepIndex+'_date_id'
        return unique_date_id
    }

    getUniqueIdOfAssesId=(mainIndex, stepIndex)=>{
        var unique_fixit = this.state.unique_combine+'_asses_'+mainIndex+'_it_'+stepIndex+'_fixit'
        return unique_fixit
    }

    ItemOnChange = (e, category_type, category, step, status, category_index, step_index) => {
        const cachedData = JSON.parse(localStorage.getItem('cachedData')) || {};
        const date_of_complete = e.target.value
        const category_name = category.name
        const entryKey = `${category_name}_${step}_${category_type}`;

        
        cachedData.itemDate = { ...cachedData.itemDate, [entryKey]: { category_type, category_name, step, status, date_of_complete } };
        cachedData['document_number'] = this.props.DocumentNumber
        localStorage.setItem('cachedData', JSON.stringify(cachedData));

    }

    assessmentItemOnChange = (e, category_type, category, step, status, category_index, step_index, remarks) => {
        // Store data in cache under the key 'assessmentItem'
        const cachedData = JSON.parse(localStorage.getItem('cachedData')) || {};
        const categoryName = e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA' ? category.name : category;

        // Use a unique key for each category entry
        const entryKey = `${categoryName}_${step}_${category_type}`;
       
        const prevstate = this.state
        if (category_type == 'assessment_management') {
            if (status) {
                prevstate['assessment_management'][category_index]['rows'][step_index]['status'] = status
                prevstate['assessment_management'][category_index]['rows'][step_index]['remarks'] = remarks
            } else {
                prevstate['assessment_management'][category_index]['rows'][step_index]['status'] = status
                prevstate['assessment_management'][category_index]['rows'][step_index]['remarks'] = ''
            }
            
            cachedData.assessmentItem = { ...cachedData.assessmentItem, [entryKey]: { category_type, categoryName, step, status, remarks } };
            cachedData['document_number'] = this.props.DocumentNumber
            localStorage.setItem('cachedData', JSON.stringify(cachedData));
            
        } else if (category_type == 'item') {
            // prevstate['item'][category_index]['rows'][step_index]['status'] = status
            var remarks_data = remarks['remarks']
            cachedData.item = { ...cachedData.item, [entryKey]: { category_type, categoryName, step, status, remarks_data } };
            cachedData['document_number'] = this.props.DocumentNumber
            localStorage.setItem('cachedData', JSON.stringify(cachedData));
             
          
            var value_dict = remarks
            if(status){
                prevstate['item'][category_index]['rows'][step_index]['status'] = status
                if(value_dict.hasOwnProperty('remarks')){
                    prevstate['item'][category_index]['rows'][step_index]['remarks'] = value_dict['remarks']
                }
            }else{
                prevstate['item'][category_index]['rows'][step_index]['status'] = status
                prevstate['item'][category_index]['rows'][step_index]['remarks'] = ''
            }
            if (status == false) {
                // document.getElementsByName(prevstate['item'][category_index]['rows'][step_index]['name_slug'])[0].value = ''
                // $('.datepicker').datepicker("update")
                // document.getElementsByName(prevstate['item'][category_index]['rows'][step_index]['name_slug']+"_whom")[0].value = ''

                var item_whom_id = this.getUniqueIdOfWhomId(category_index, step_index)
                var item_date_id = this.getUniqueIdOfDateId(category_index, step_index)

                $('#'+item_whom_id).val('')
                $('#'+item_whom_id).removeClass('error')

                $('#'+item_date_id).val('')
                $('#'+item_date_id).val('').removeClass('error')

            }
        }
        this.setState({ prevstate })


    }

    componentDidMount() {
        /*$('.datepicker').datepicker({
            autoclose: true,
            format: "dd/mm/yyyy",
            immediateUpdates: true,
            todayBtn: true,
            todayHighlight: true
        })*/
        // $('#dateMoC').datepicker("setDate", "0");
        // var userData = JSON.parse(localStorage.getItem('user_data'))
        // this.setState({
        //     sitename:userData.site.name,
        //     plant:userData.plant.name,
        //     proposal_initiated_by:userData.first_name + ' ' +userData.last_name,
        //     department:userData.department.name,
        //     assessment_management:AssessmentRowNames,
        //     item:ItemRowNames
        // })
        this.getUserPrivileges()
        this.getAllData()
        this.cacheClearInterval = setInterval(this.clearCache, 5000);
    }

    componentWillUnmount() {
        // Clear the interval when the component is unmounted to avoid memory leaks
        clearInterval(this.cacheClearInterval);
    }
    
    clearCache = () => {
        const cachedData = JSON.parse(localStorage.getItem('cachedData')) || {};
        if (Object.keys(cachedData).length > 0) {
          const form_data = new FormData();
          // Include moc_data in form_data
          const mocData = {
            brief_description: cachedData.brief_description || {},
            enclosures: cachedData.enclosures || {},
            assessmentItem: cachedData.assessmentItem || {},
            itemDate: cachedData.itemDate || {},
            item: cachedData.item || {},
            change_sought: cachedData.change_sought || {},
            type_of_change: cachedData.type_of_change || {},
            sitename: cachedData.sitename || {},
            dateImplementation: cachedData.dateImplementation || {},
            proposed_change: cachedData.proposed_change || {},
            document_number:cachedData.document_number || "",
          };
          form_data.append('moc_data', JSON.stringify(mocData));
          const auth_token = localStorage.getItem("token");
          axios.defaults.headers.post['Authorization'] = auth_token;
          const document_number = this.props.DocumentNumber;
          if(document_number !== mocData.document_number){
            return false
          }
          axios.post(`${API_HOST}/moc/request/${document_number}/auto/save/`, form_data, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          .then(response => {
            if(response.status === 240){
                return false
            }
            localStorage.removeItem('cachedData');
          })
          .catch(error => {
          });
        } else {
        }
    };



    getAllData = (e)=>{
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/moc/request/${this.state.requestID}/data/`,)
            .then(response => {
                if (response.status === 200 && response.data.success) {
                    var moc_request_data = response.data.moc_request_data
                    var assessment_management = this.state.assessment_management
                    var item = this.state.item
                    assessment_management.map((assessment, index) => {
                        assessment.rows.map((row, rowIndex) => {
                            var filter_assesment_row = []
                            moc_request_data.assessment_managment.map((assess_obj, indx) => {
                                if (row.name_slug == assess_obj.step && assessment.header.name_slug == assess_obj.category) {

                                    filter_assesment_row.push(assess_obj)
                                }
                                if (filter_assesment_row.length > 0) {
                                    assessment_management[index]['rows'][rowIndex]['status'] = filter_assesment_row[0].status
                                    assessment_management[index]['rows'][rowIndex]['remarks'] = filter_assesment_row[0].remarks
                                }
                            })

                        })
                    })
                    item.map((item_cat, index) => {
                        item_cat.rows.map((row, rowIndex) => {
                            var filter_assesment_row = []
                            moc_request_data.items.map((item_obj, indx) => {
                                if (row.name_slug == item_obj.step && item_cat.header.name_slug == item_obj.category) {
                                    filter_assesment_row.push(item_obj)
                                }
                                if (filter_assesment_row.length > 0) {
                                    item[index]['rows'][rowIndex]['status'] = filter_assesment_row[0].status
                                    item[index]['rows'][rowIndex]['date_of_complete'] = filter_assesment_row[0].completed_datetime
                                    item[index]['rows'][rowIndex]['remarks'] = filter_assesment_row[0].to_be_updated_by_whom
                                }
                            })

                        })
                    })
                    moc_request_data['assessment_management'] = assessment_management
                    moc_request_data['item'] = item
                    moc_request_data['requestID'] = this.state.requestID
                    // $('#dateMoC').datepicker('update', moc_request_data.date_of_moc)
                    // console.log("date_of_moc",moc_request_data.date_of_moc)
                    $('#dateMoC').val(moc_request_data.date_of_moc)
                    // $('#dateImplementation').datepicker('update', moc_request_data.proposal_implementation_period)
                    // $('#dateImplementation').val(moc_request_data.proposal_implementation_period)
                    // $('#dateImplementation').val(convertDate2(moc_request_data.proposal_implementation_period))
                    // document.querySelectorAll('input, select, button, textarea').forEach(elem => elem.disabled = true);
                    this.setState(moc_request_data)

                    axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
                    axios.get(`${API_HOST}/workflow/moc/request/${this.props.DocumentNumber}/stage/${this.props.StageName}/?component_name=${this.props.ComponentName}`,)
                        .then(response2 => {
                            if(response2.data.data.hasOwnProperty('ask_for_more_history')){
                                // console.log("3", this.state)
                                this.setState({
                                    "WorkflowData":response2.data.data.workflow_stage_info,
                                    "WorkflowComponent":response2.data.data.workflow_stage_info.components[0],
                                    "WorkflowSignatures":response2.data.data.workflow_stage_info.components[0].workflow_signatures,
                                    "enable_signing":response2.data.data.workflow_stage_info.components[0].enable_signing,
                                    "enable_approval":response2.data.data.workflow_stage_info.components[0].enable_approval,
                                    "enable_comments":response2.data.data.workflow_stage_info.components[0].enable_comments,
                                    "ask_for_more_history": response2.data.data.ask_for_more_history,
                                    "WorkflowAttachments": response2.data.data.workflow_stage_info.components[0].workflow_attachments,
                                    enable_upload_attachment:response2.data.data.enable_upload_attachment,
                                })

                                var ask_for_more_history_filtered = response2.data.data.ask_for_more_history.filter(
                                            (each, index)=>{
                                                return each.status.name_slug=="open"
                                        })
                                this.props.updateSideMenu(ask_for_more_history_filtered.length>0)
                                if (ask_for_more_history_filtered.length > 0){
                                    this.setState({
                                        ask_more_stage_name_slug:ask_for_more_history_filtered[0].stage.name_slug
                                    })
                                    // console.log(ask_for_more_history_react_component)
                                }
                                if(response2.data.data.workflow_stage_info && response2.data.data.workflow_stage_info.components && response2.data.data.workflow_stage_info.components[0].workflow_signatures){
                                    this.setState({
                                        WorkflowSignatures: response2.data.data.workflow_stage_info.components[0].workflow_signatures
                                    })
                                }

                                this.getDepartmentData()

                            }
                        }).catch(error_response=>{})
                    
                        $('.card-loader').fadeOut('slow')

                }
            }).catch(response => {
                // ErrorHandling(response)
            });

    }

    isOneChecked=()=>{
        var chx = document.querySelectorAll(".changesought");
        for (var i=0; i<chx.length; i++) {
          if (chx[i].type == 'checkbox' && chx[i].checked) {
            return true;
          } 
        }
        return false;
    }

    getCommonMinDate = (event)=>{
        var today_str = new Date().toISOString().split("T")[0]
        var min_date = this.state.date_of_moc
        var common_min_date = today_str
        if(min_date){
            var min_date_obj = new Date(min_date)
            if(min_date_obj!='Invalid Date'){
                common_min_date = min_date_obj.toISOString().split('T')[0]
            }
        }
        return common_min_date
    }

    isValid=()=>{
        var name_regexp = new RegExp("<(?:\"[^\"]*\"['\"]*|'[^']*'['\"]*|[^'\">])+>");
        if(document.getElementById("permanentChange").checked == false && document.getElementById("temporaryChange").checked == false){
            return {"status":false, "reason": "Please select type of change", "id": "typeofchangeDiv"}
        }
        var dateImplementation = document.getElementById('dateImplementation').value
        if(!dateImplementation || !this.isOneChecked() || !document.querySelector("#existingStatus").value || !document.querySelector("#proposedChange").value || !document.querySelector("#probableCase").value || !document.querySelector("#proposalBenefits").value){
            var scrollDiv = ""
            if(!document.querySelector("#existingStatus").value){
                document.querySelector('#existingStatus').classList.add("error")
                scrollDiv = "briefdescriptionDiv"
            }
            if(!document.querySelector("#proposedChange").value){
                document.querySelector('#proposedChange').classList.add("error")
                scrollDiv = "briefdescriptionDiv"
            }
            if(!document.querySelector("#probableCase").value){
                document.querySelector('#probableCase').classList.add("error")
                scrollDiv = "briefdescriptionDiv"
            }
            if(!document.querySelector("#proposalBenefits").value){
                document.querySelector('#proposalBenefits').classList.add("error")
                scrollDiv = "briefdescriptionDiv"
            }
            if(!this.isOneChecked()){
                scrollDiv = "typeofchangeDiv"
            }
            if(!dateImplementation){
                document.querySelector('#dateImplementation').classList.add("error")
                scrollDiv = "typeofchangeDiv"
            }
            return {"status":false, "reason": "Please fill required field", "id": scrollDiv}
        }

        if((document.querySelector("#nameOfProduct").value&&name_regexp.test(document.querySelector("#nameOfProduct").value)) || (document.querySelector("#sapMaterialCode").value&&name_regexp.test(document.querySelector("#sapMaterialCode").value)) || (document.querySelector("#sapMaterialCode").value&&name_regexp.test(document.querySelector("#customerApplicable").value)) || (document.querySelector("#requestedByProductManger").value&&name_regexp.test(document.querySelector("#requestedByProductManger").value)) || (document.querySelector("#existingStatus").value&&name_regexp.test(document.querySelector("#existingStatus").value)) || (document.querySelector("#proposedChange").value&&name_regexp.test(document.querySelector("#proposedChange").value)) || (document.querySelector("#probableCase").value&&name_regexp.test(document.querySelector("#probableCase").value)) || (document.querySelector("#proposalBenefits").value&&name_regexp.test(document.querySelector("#proposalBenefits").value))){
            var scrollDiv = "briefdescriptionDiv"
            return {"status":false, "reason": "Please provide valid information in input fields.", "id": scrollDiv}
        }

        var date_obj = new Date(dateImplementation)
        if(dateImplementation && date_obj!="Invalid Date"){
            var common_min_date_obj = new Date(this.getCommonMinDate())
            common_min_date_obj.setHours(0,0,0,0)
            if (date_obj<common_min_date_obj){
                document.querySelector('#dateImplementation').classList.add("error")
                scrollDiv = "dateImplementation"
                return {"status": false, "reason": "Date of Implementation should not be past date", "id": scrollDiv}
            }
        }
        
        var proposed_change = this.state.proposed_change
        if(!proposed_change){
            return {"status": false, "reason": "Please fill in required field", "id": "typeofchangeDiv"}
        }
        var impact_qty_status = this.state.enclosures.quality_of_product
        if(document.querySelector('#describe_in_detail')&&!document.querySelector('#describe_in_detail')?.value){
            if(impact_qty_status){
                document.querySelector('#describe_in_detail')?.classList.add("error")
                return {"status": false, "reason": "Please enter product details", "id": "describe_in_detail"}
            }
        }
        if(document.querySelector('#describe_in_detail')&&name_regexp.test(document.querySelector('#describe_in_detail')?.value)){
            if(impact_qty_status){
                document.querySelector('#describe_in_detail')?.classList.add("error")
                return {"status": false, "reason": "Please provide valid information in input fields.", "id": "describe_in_detail"}
            }
        }
        var response_isvalid = {"status":true, "reason":""}
        var assessment_management = this.state.assessment_management
        for (var i = 0; i<assessment_management.length; i++) {
            var rows = assessment_management[i]['rows']
            for (var j = 0; j<rows.length; j++) {
                if(rows[j].status){
                     if(!rows[j].remarks){
                        var unique_fixit = this.getUniqueIdOfAssesId(i, j)
                        response_isvalid['status'] = false
                        response_isvalid['reason'] = rows[j].name + " should not be empty. And also selected as Yes fields are mandatory."
                        response_isvalid['id'] = unique_fixit
                        document.querySelector("#"+unique_fixit)?.classList.add("error")
                        break
                     }
                }
                if(rows[j].remarks&&name_regexp.test(rows[j].remarks)){
                    var unique_fixit = this.getUniqueIdOfAssesId(i, j)
                    response_isvalid['status'] = false
                    response_isvalid['reason'] = "Please provide valid information in input fields."
                    response_isvalid['id'] = unique_fixit
                    document.querySelector("#"+unique_fixit)?.classList.add("error")
                    break
                }
            }
            if(!response_isvalid['status']){
                break
            }
        }
        if (!response_isvalid['status']){
            return response_isvalid
        }

        var common_min_date_items = getCommonMinDateItems(this.state.date_of_moc)
        var common_min_date_items_obj = new Date(common_min_date_items)
        common_min_date_items_obj.setHours(0,0,0,0)

        var items = this.state.item
        for (var i = 0; i<items.length; i++) {
            var item_rows = items[i]['rows']
            for (var j = 0; j<item_rows.length; j++) {
                if(item_rows[j].status){
                   if(!item_rows[j].remarks){
                      var item_whom_id = this.getUniqueIdOfWhomId(i, j)
                      response_isvalid['status'] = false
                      response_isvalid['reason'] = item_rows[j].name + " should not be empty. And also selected as Yes fields are mandatory."
                      response_isvalid['id'] = item_whom_id
                      /*document.querySelector("#"+item_rows[j].name_slug).classList.add("error")
                      var k = document.querySelector("#"+item_rows[j].name_slug).parentElement.parentElement.children[2].children[0].id
                      document.querySelector("#"+k)?.classList.add("error")*/
                      document.querySelector("#"+item_whom_id)?.classList.add("error")
                      break
                   }else{
                        var item_whom_id = this.getUniqueIdOfWhomId(i, j)
                        $('#'+item_whom_id).removeClass('error')
                   }
                    if(item_rows[j].remarks&&name_regexp.test(item_rows[j].remarks)){
                        var item_whom_id = this.getUniqueIdOfWhomId(i, j)
                        response_isvalid['status'] = false
                        response_isvalid['reason'] = "Please provide valid information in input fields."
                        response_isvalid['id'] = item_whom_id
                        document.querySelector("#"+item_whom_id)?.classList.add("error")
                        break
                    }else{
                        var item_whom_id = this.getUniqueIdOfWhomId(i, j)
                        $('#'+item_whom_id).removeClass('error')
                    }
                   var item_date_id = this.getUniqueIdOfDateId(i, j)
                   var each_date = document.getElementById(item_date_id).value
                   var has_error_in_date_items = false
                   if(each_date){
                        var each_date_obj = new Date(each_date)
                        if(each_date_obj=='Invalid Date'){
                            has_error_in_date_items=true
                        }else{
                            each_date_obj.setHours(0,0,0,0)
                            if(each_date_obj<common_min_date_items_obj){
                                has_error_in_date_items=true
                            }
                        }
                   }else{
                        has_error_in_date_items = true
                   }
                   if(has_error_in_date_items){
                        response_isvalid['status'] = false
                        response_isvalid['reason'] = "empty or invalid date selected for "+item_rows[j].name
                        response_isvalid['id'] =item_date_id
                        document.querySelector("#"+item_date_id).classList.add("error")
                        break
                   }else{
                        $('#'+item_date_id).removeClass('error')
                   }
                }
            }
            if(!response_isvalid['status']){
                break
            }
        }
        if (!response_isvalid['status']){
            return response_isvalid
        }
        return {"status":true, "reason":""}
    }


    handleUpdateRequest = (e) => {
        e.preventDefault()
        var response_isvalid = this.isValid()
        if(!response_isvalid.status){
            notify(response_isvalid.reason,'warning')
            setTimeout(function () {
                document.querySelector('#'+response_isvalid.id).scrollIntoView({
                    behavior: 'smooth', block: "center", inline: "nearest" 
                })
            }, 100)
            return false
        }
        var msg = "Are you sure, you want to save changes?"
        if (window.confirm(msg)) {
            $('.loader').fadeIn()
            var moc_data = this.state
            var moc_data_item = moc_data.item.map((item, index) => {
                var itemRow = item.rows.map((row, ind) => {
                    var item_date_id = this.getUniqueIdOfDateId(index, ind)
                    var input_val = document.getElementById(item_date_id).value
                    // var input_val = document.getElementsByName(String(row.name_slug))[0].value
                    row['date_of_complete'] = input_val ? input_val : ''
                    row['to_be_updated_by_whom'] = row['remarks']
                    return row

                })
                item['rows'] = itemRow
                return item
            })
            moc_data['item'] = moc_data_item
            // moc_data['date_of_moc'] = document.getElementById('dateMoC').value
            moc_data['date_of_moc'] = this.state.date_of_moc
            // var dateImplementation = convertDate(document.getElementById('dateImplementation').value)
            moc_data['proposal_implementation_period'] = document.getElementById('dateImplementation').value
            /*const auth_token = "Token "+localStorage.malbar_token*/

            moc_data['departments_data'] = undefined //departments_data, plant_data not required to send to backend
            moc_data['plant_data'] = undefined // as this not required to send to backend
            var form_data = new FormData();
            // moc_data.attachments.map((item, index)=>{
            //     form_data.append('attachments', item.attachment)
            // })
            form_data.append('moc_data', JSON.stringify(moc_data))
            // delete moc_data.attachments
            const auth_token = localStorage.getItem("token")
            axios.defaults.headers.post['Authorization'] = auth_token
            var document_number = this.props.DocumentNumber
            axios.post(`${API_HOST}/moc/request/${document_number}/data/update/`, form_data, {
                            headers: {'Content-Type': 'multipart/form-data'}
                        }
                )
                .then(response => {
                    notify('Changes saved successfully','success')
                    // this.getAllData()

                    setTimeout(() => {
                        $('.loader').fadeOut('slow')
                        window.location.reload()
                    }, 1000)
                }).catch(response => {
                    notify(response.response.data.reason, "warning")
                    setTimeout(() => {
                        $('.loader').fadeOut('slow')
                    }, 1000)
                    // ErrorHandling(response)
                });
        }

    }

    handleLinkClick(e, id) {
      this.setState({ active_link_id: id })
    }

    handleUpdateAttachmentList = (attachment_obj, idx)=>{
        var attachments_list = this.state.attachments
        attachments_list[idx] = attachment_obj;
        this.setState({attachments: attachments_list})

    };

    handleDeleteAttachmentButton = (e, idx) =>{
        var attachments_list = this.state.attachments
        attachments_list.splice(idx, 1);
        this.setState({attachments: attachments_list})
    }

    attachmentSubmitHandler = (e, attachment_obj, idx) => {
        e.preventDefault()
        var attachment_data = {
            model:"workflow_attachments",
            data:{
                    workflow_definition_id:this.state.WorkflowData.workflow_definition_id,
                    stage_id:this.state.WorkflowData.id,
                    component_id:this.state.WorkflowComponent.id,
                    file_name:attachment_obj.attachment_filename
                }
        }
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
        axios.post(`${API_HOST}/workflow/moc-request/request/${this.props.DocumentNumber}/form/data/save/`, attachment_data)
        .then(response => {
            if (response.status === 200) {
                notify('Attachment saved successfully','success')
                var attachment_data = response.data.data.workflow_attachments
                var attachment_obj = {
                    attachment_filename:attachment_data.filename,
                    signed_url:attachment_data.singed_url
                }
                let attachments = this.state.WorkflowAttachments
                attachments.push(attachment_data)
                this.setState({WorkflowAttachments: attachments})
                this.handleUpdateAttachmentList(attachment_obj, idx)
            }

        }).catch(response => {
            // ErrorHandling(response)
        });
        $('.card-loader').fadeOut('slow')
    }

    handleAddAttachmentButton = (e) =>{
        let attachment_obj = {
            attachment: "",
            filename: "",
            file_size: 0,
            signed_url:"",
            attachment_filename: ""
        }
        var attachments_list = this.state.attachments
        attachments_list.push(attachment_obj);
        this.setState({attachments: attachments_list})
    };

    handleUpdateAttachment = (attachment_obj) =>{
        //attachment=null,filename=null,file_size=null,signed_url=null
        var enclosures = this.state.enclosures
        enclosures['attachment_url'] = attachment_obj.filename?attachment_obj.filename:enclosures.attachment_url
        enclosures['attachment'] = attachment_obj.attachment?attachment_obj.attachment:enclosures.attachment
        enclosures['file_size'] = attachment_obj.file_size?attachment_obj.file_size:enclosures.file_size
        enclosures['signed_url'] = attachment_obj.signed_url?attachment_obj.signed_url:enclosures.signed_url        
        this.setState({
            enclosures:enclosures
        })
    }

    handleMarkAttachmentAsDelete = (event, id_of_wf_attachment, delete_or_cancel)=>{
        var deletable_wf_attachments = this.state.deletable_wf_attachments
        const index = deletable_wf_attachments.indexOf(id_of_wf_attachment);
        if (index > -1) {
            if(delete_or_cancel=='cancel'){
                deletable_wf_attachments.splice(index, 1)
            }
        }else{
            if(delete_or_cancel=='delete'){
                deletable_wf_attachments.push(id_of_wf_attachment)
            }
            
        }
        this.setState({"deletable_wf_attachments": deletable_wf_attachments})
    }


    handleSaveAttachmentButton = (event, attachment_obj, idx) =>  {
      event.preventDefault();
      $('.card-loader').fadeIn()
      if(attachment_obj.attachment){
        let form_data = new FormData();
        form_data.append('attachment', attachment_obj.attachment);
        form_data.append('size', attachment_obj.file_size);
        let url = `${API_HOST}/upload/file/in/azure/blob/storage`;
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')
        axios.post(url,form_data,
          ).then(response=>{
              if(response.data.success){
                  // notify("File successfully uploaded",'information')
                  var attachment_obj = {
                      attachment_filename:response.data.filename,
                      signed_url:response.data.singed_url
                  }
                  this.attachmentSubmitHandler(event, attachment_obj, idx)
              }else{
                  notify(response.data.message,'error')
              }

          }).catch(error_xlsx => {

          })

        }else{alert("Invalid files selected")}
    } 

    handleQualityProduct = (e,quality_of_product) => {
        const cachedData = JSON.parse(localStorage.getItem('cachedData')) || {};

        this.setState((prevState) => ({
            enclosures: {
                ...prevState.enclosures,
                quality_of_product: quality_of_product,
            },
        }));

        cachedData.enclosures = {
            ...cachedData.enclosures,
            quality_of_product: quality_of_product,
        };
        cachedData['document_number'] = this.props.DocumentNumber
        localStorage.setItem('cachedData', JSON.stringify(cachedData));
    };
    
    

    handleCancel = ()=>{
        window.location.href='/'
    }
    removeWfAttachment =(event, id_of_wf_attachment, component_id)=>{
        event.preventDefault()
        var user_resp = window.confirm("Are you sure, you want to delete the attachment?")
        if(user_resp){
            $('.card-loader').fadeIn('')
            var attachment_data = {
                workflow_definition_id:this.state.WorkflowData.workflow_definition_id,
                stage_id:this.state.WorkflowData.id,
                component_id:this.state.WorkflowComponent.id,
                wf_attachment_id:id_of_wf_attachment
            }
            axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
            axios.post(`${API_HOST}/workflow/moc-request/request/${this.props.DocumentNumber}/attachment/delete/`, attachment_data)
            .then(response => {
                if (response.status === 200) {
                    notify('Attachment deleted successfully','success')
                    this.getAllData()
                }

            }).catch(response => {
                // ErrorHandling(response)
                $('.card-loader').fadeOut('slow')
            });
        }
        
    }
    /*getCommonMinDateItems = ()=>{
        var today_str = new Date().toISOString().split("T")[0]
        var min_date = this.state.date_of_moc
        var common_min_date_items = today_str
        if(min_date){
            var min_date_obj = new Date(min_date)
            if(min_date_obj!='Invalid Date'){
                common_min_date_items = min_date_obj.toISOString().split('T')[0]
            }
        }
        return common_min_date_items
    }*/

    render() {
        const { WorkflowComponent } = this.state;
        const workflowComponentProp = (WorkflowComponent && Object.keys(WorkflowComponent).length === 0) ? "" : WorkflowComponent;
        var access_type = "view"
        var data_for_change_sought = {
            'change_sought': this.state.change_sought
        }
        var common_min_date_items = getCommonMinDateItems(this.state.date_of_moc)
        var ask_for_more_history_filtered = []
        if(this.state){
            if(this.state.hasOwnProperty('ask_for_more_history')){
                ask_for_more_history_filtered = this.state.ask_for_more_history.filter((each, index)=>{
                    return each.status.name_slug=="open"
                })
            }
        }
        if(this.state.ask_more_stage_name_slug != "" && !(['execution-hod-review', 'hod-of-safety-review', 'hod-of-qa-review', 'closure-by-moc-lead'].includes(this.state.ask_more_stage_name_slug))){
            if(ask_for_more_history_filtered.length>0){
                access_type  ="update"
            }
        }
        if(this.state.moc_status=='Save as Draft'){
            access_type = 'update'
        }
        return (
                [
                    <div>
                        {false && ask_for_more_history_filtered.length>0?
                            <div className="alert alert-info">
                              More information requested
                            </div>:null
                        }
                    </div>,
                    <TypeOfChange key={this.state.unique_combine+'toc'}
                        handleTypeOfChangeDate = {this.handleTypeOfChangeDate}
                        onChangeHandler={this.handleChange} thisData={this.state} accessType={access_type} />,
                    <ChangeSought key={this.state.unique_combine+'cs'} onChangeHandler={this.handleChange} thisData={data_for_change_sought} accessType={access_type} />,
                    <BriefDescription key={this.state.unique_combine+'bd'}  updateButtonClicked={this.state.updateButtonClicked} handleUpdateRequest={this.handleUpdateRequest}  onChangeHandler={this.handleChange} thisData={this.state.brief_description} accessType={access_type} />,
                    <Enclosures key={this.state.unique_combine+'en'}
                        thisData={this.state.enclosures}
                        handleUpdateAttachment={this.handleUpdateAttachment}
                        onChangeHandler = {this.handleChange}
                        handleAddAttachmentButton={this.handleAddAttachmentButton}
                        removeWfAttachment = {this.removeWfAttachment}
                        WorkflowAttachments={this.state.WorkflowAttachments}
                        handleUpdateAttachmentList={this.handleUpdateAttachmentList}
                        attachments_save_in_db={this.state.attachments_save_in_db}
                        handleDeleteAttachmentButton={this.handleDeleteAttachmentButton}
                        handleSaveAttachmentButton={this.handleSaveAttachmentButton}
                        handleMarkAttachmentAsDelete={this.handleMarkAttachmentAsDelete}
                        deletable_wf_attachments={this.state.deletable_wf_attachments}
                        unique_combine={this.state.unique_combine}
                        ask_for_more_history_filtered={ask_for_more_history_filtered}
                        attachments={this.state.attachments}
                        userPermissions={this.state.userPermissions}
                        accessType={access_type} 
                        ask_more_stage_name_slug={this.state.ask_more_stage_name_slug}
                        moc_status = {this.state.moc_status}
                        enable_upload_attachment = {this.state.enable_upload_attachment}/>,
                    <div key={this.state.unique_combine+'ca'} className="card mb-3">
                        <div className="card-body">
                            <div className='display-flex'>
                                <p> <b>Impact on quality of product? *</b></p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Toggle status={this.state.enclosures.quality_of_product} handleQualityProduct = {this.handleQualityProduct} requestFrom="QualityOfProduct" accessType={access_type} />
                            </div>
                            <div className='row mb-3'>
                                <div className='col-12'>
                                    <label className="form-label" htmlFor="describe">If yes, describe in detail</label>
                                    {["create", "update"].includes(access_type)?
                                        <textarea className='form-control'
                                            value={this.state.enclosures.describe_in_detail} rows="3"
                                            disabled={this.state.enclosures.quality_of_product?false:true}
                                            onChange={(e)=>this.handleChange(e,'enclosures')}
                                            name="describe_in_detail" id="describe" type="text">
                                        </textarea>:
                                        <textarea className='form-control' disabled={true}
                                            value={this.state.enclosures.describe_in_detail} rows="3"
                                            name="describe_in_detail" id="describe" type="text">
                                        </textarea>
                                    }
                                </div>
                            </div>
                            <div className='display-flex'>
                                <p><b>Assessment for management of change * </b></p>&nbsp;&nbsp;<span> (Operation Analysis Checklist To be filled in by the Proposer/Reviewer)</span>
                            </div>
                            <div className='overflow-x-scroll'>
                                <AssessmentTable unique_combine={this.state.unique_combine} onChangeHandler={this.assessmentItemOnChange} assessmentData={this.state.assessment_management} accessType={access_type} />                               
                                <ItemTable unique_combine={this.state.unique_combine} ItemOnChange={this.ItemOnChange} onChangeHandler={this.assessmentItemOnChange} itemData={this.state.item} common_min_date_items={common_min_date_items} accessType={access_type} />
                            </div>
                            <div className='print-mb'>
                                <p>
                                    <span>If relevant, initiator to submit a change proposal to Site Engineer for assessment prior to formal sign off by Site Manager. </span>  
                                    <br />                   
                                    <b>NEW EQUIPMENT IS TO BE TAGGED “DO NOT OPERATE” UNTIL EHS ACCEPTANCE ISPASSED</b>  
                                    <br />
                                </p>  
                                <div className='mt-4 mb-3 row'>
                                    <Comment 
                                        key={this.state.unique_combine+'cobj'}
                                        is_rejected={this.state.is_rejected}
                                        stage_status={access_type === "update" ? "pending" : "approved"}
                                        unique_combine={this.state.unique_combine} 
                                        required_moc_lead={false}
                                        is_approved={this.state.is_approved}
                                        WorkflowData={this.state.WorkflowData}
                                        WorkflowComponent={workflowComponentProp}
                                        DocumentNumber={this.state.DocumentNumber}
                                        RequestType="moc-request" 
                                        ref={this.commentRef}
                                        userPermissions={this.state.userPermissions} 
                                    />
                                </div>                                                                                    
                            </div>   
                        </div>
                    </div>,
                    <div key={this.state.unique_combine+"k"}>
                    {this.state.userPermissions.can_update && (access_type=='update' || (this.state.ask_more_stage_name_slug != "" && !(['execution-hod-review', 'hod-of-safety-review', 'hod-of-qa-review', 'closure-by-moc-lead'].includes(this.state.ask_more_stage_name_slug)) && ask_for_more_history_filtered.length>0)) ?
                        <div className='card-body'>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                <button className="btn btn-outline-secondary" type="button" onClick={this.handleCancel} id={this.state.unique_combine+"cancel"}>Cancel</button>
                                <button
                                    className="btn btn-dark"
                                    type="submit"
                                    id="submit"
                                    onClick={(e) => {
                                        this.handleUpdateRequest(e, 'update');
                                    }}
                                >
                                    Update
                                </button>
                            </div>
                        </div>:<div className='card-body'>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                {this.state.WorkflowSignatures.length > 0?<Signed SignatureData={this.state.WorkflowSignatures[0]}/>:""}
                            </div>
                        </div>
                    }
                </div>
                ]


        )
    }
}

export default withRouter(MOCRequestStage)