import React, { Component } from 'react';
import { FiEdit } from "react-icons/fi";
import { API_HOST } from '../../Settings.js'
import axios from 'axios';
import notify from '../CommonComponents/Notify';
import '../../Styles/userRoles.css';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import $ from 'jquery'


class Pagination extends Component {
    render() {
        const { currentPage, totalPages, itemsPerPage, totalItems, onPageChange } = this.props;

        // Calculate the range of items being displayed
        const indexOfLastItem = Math.min(currentPage * itemsPerPage, totalItems);
        const indexOfFirstItem = Math.max(0, indexOfLastItem - itemsPerPage);

        const displayRange = `${indexOfFirstItem + 1} - ${indexOfLastItem} of ${totalItems} records`;


        return (
            <div className="pagination">
                {currentPage > 1 && (
                    <span onClick={() => onPageChange(currentPage - 1)} className="arrow">
                        {'<'}
                    </span>
                )}

                {[...Array(totalPages).keys()].map((index) => (
                    <span
                        key={index}
                        onClick={() => onPageChange(index + 1)}
                        className={`page-number ${currentPage === index + 1 ? 'selected' : ''}`}
                    >
                        {index + 1}
                    </span>
                ))}

                {currentPage < totalPages && (
                    <span onClick={() => onPageChange(currentPage + 1)} className="arrow">
                        {'>'}
                    </span>
                )}

                <span className="page-info">{displayRange}</span>
            </div>
        );
    }

}



// VendorListRequest component
class VendorListRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            itemsPerPage: 10, // Adjust as needed
            showModal: false,
            deleteUserId: null,
        };
    }



    onPageChange = (pageNumber) => {
        this.setState({ currentPage: pageNumber });
    };

    handleUpdateVendorClick = (vendorId) => {
        this.props.history.push({
            pathname: `/vendor/${vendorId}`,
        });

    };


    deleteVendor = (id) => {
        this.setState({ deleteUserId: id });
        this.handleShowModal();
    };

    handleDeleteVendorConfirmed = (id) => {
        this.handleCloseModal();
        const url = `${API_HOST}/delete/vendor/${id}`;
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token');
        axios.post(url)
            .then(response => {
                if (response.status === 200 && response.data.success) {
                    notify(response.data.message, 'success');
                    setTimeout(() => {
                        window.location.href = '/vendors?workflow=ibd';
                    }, 2000)
                } else {
                    notify(response.data.message, 'error');
                }
                $('.loader').fadeOut('slow');
            })
            .catch(response => {
                $('.loader').fadeOut('slow');
                // ErrorHandling(response)
            });
    };

    handleShowModal = () => {
        this.setState({ showModal: true });
    };

    handleCloseModal = () => {
        this.setState({ showModal: false });
    };



    handleCreateVendorClick = () => {
        window.location.href = '/vendor?workflow=ibd';
    };

    render() {
        const { vendorListData } = this.props.thisData;
        const { currentPage, itemsPerPage } = this.state;

        // Calculate pagination
        const totalRecords = vendorListData ? vendorListData.length : 0;
        const indexOfLastItem = Math.min(currentPage * itemsPerPage, totalRecords);
        const indexOfFirstItem = Math.max(0, indexOfLastItem - itemsPerPage);
        const currentItems = vendorListData.slice(indexOfFirstItem, indexOfLastItem);

        console.log(currentItems.length, 'currentItems....')

        const dashboard_data = currentItems.map((data, index) => (
            <tr key={index} style={{ border: 'none', color: '#898989', fontSize: '14px' }}>
                <td style={{ border: 'none', width: '30%' }}>{data.Name}</td>
                <td style={{ border: 'none' }}>{data.VendorType}</td>
                <td style={{ border: 'none', width: '25%' }}>{data.Email}</td>
                <td style={{ border: 'none' }}>
                    {data.Status ? <button className="active-status" type="button">Active</button> : <button className="Inactive-status" type="button">Inactive</button>}
                </td>
                <td className='m-0 p-0 d-flex' style={{ border: 'none' }}>
                    <button className="edit-icon m-0 p-0" type="button" onClick={() => this.handleUpdateVendorClick(data.Id)}><FiEdit /></button>
                    {/* <button className="delete-icon m-0 p-0" type="button" onClick={() => this.deleteVendor(data.Id)}><RiDeleteBin6Line /></button> */}
                </td>
            </tr>
        ));

        return (
            <>
                <div className="dashboard-request">
                    <div className='nav-tab w-100'></div>
                    <div className="dashboard-request-block ps-5 mt-3">
                        <div className="row align-items-center dashboard-request-list pe-1">
                            {currentItems.length > 0 ?
                                <div className="col-lg-12 col-md-12 col-12" style={{ padding: 0, margin: 0 }}>
                                    <div className="table-container">
                                        <table className="table">
                                            <thead >
                                                <tr >
                                                    <th style={{ border: 'none', textAlign: 'left' }}>Vendor</th>
                                                    <th style={{ border: 'none', textAlign: 'left' }}>Vendor Type</th>
                                                    <th style={{ border: 'none', textAlign: 'left' }}>Email</th>
                                                    <th style={{ border: 'none', textAlign: 'left' }}>Status</th>
                                                </tr>
                                                <tr>
                                                    <td colSpan="5" style={{ border: 'none', marginTop: 0, paddingTop: 0 }}>
                                                        <div className='nav-tab w-100' style={{ margin: 0, padding: 0 }}></div>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody style={{ border: 'none' }}>
                                                {dashboard_data}
                                            </tbody>
                                        </table>
                                    </div>

                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={Math.ceil(vendorListData.length / itemsPerPage)}
                                        totalItems={vendorListData.length}
                                        itemsPerPage={itemsPerPage}
                                        onPageChange={this.onPageChange}
                                    />

                                </div>
                                : <div style={{ color: 'gray' }}>No records found</div>
                            }
                        </div>
                    </div>



                </div>
                <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this vendor?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCloseModal}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={() => this.handleDeleteVendorConfirmed(this.state.deleteUserId)}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

            </>
        );
    }
}

export default withRouter(VendorListRequest);
