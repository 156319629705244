import React,{ Component } from "react";
import ThreeToggle from '../../CommonComponents/ThreeToggle';

class ChangeAuthoritySteps extends Component {
	constructor(props) {
	    super(props)
	    this.state = {
	        firstVal : "Yes",
	        secondVal :"No",
	        thirdVal: " N/A",
			DataSubmited: this.props.DataSubmited,
	    }
	}
	componentWillReceiveProps = (props) =>{
        this.setState ({
            DataSubmited: props.DataSubmited
        })
    }
	render(){
		return(
			<div>
				{this.props.ChangeAuthorityStepsData.map((each, idx)=>{
					return(<div className="mb-3" key={idx}>
					    <div className="mb-2">
					        <b>{each.name}</b>
					    </div>
						<div className="overflow-x-scroll">
							<table className='table table-bordered'>
								<thead>
									<tr>
										<th width="50%">
										Checks
										</th>
										<th className='text-center'><b>Yes</b> <b>No</b> <b>N/A</b></th>
										<th width="30%">Comments</th>
									</tr>
								</thead>
								<tbody className='vertical-align-middle'>
									{each.rows.map((step, id)=>{
										return(
											<tr key={id}>
												<td width="50%">{step.name}</td>
												<td className='three-toggle-align-center'>
												<ThreeToggle Status={step.status == '' ? 'N/A' :  step.status} firstVal={this.state.firstVal}
													secondVal={this.state.secondVal} thirdVal={this.state.thirdVal} 
													MainIndex={idx} RowIndex={id} handleAuthorityChecks={this.props.handleAuthorityChecks} RequestType="ChangeAuthority" DataSubmited={this.state.DataSubmited}  access_type={this.props.access_type}/>
												</td>
												<td width="30%">
												<textarea className='form-control brio-form-control' rows="3" name="MOC" id="MOC" type="text" defaultValue={step.comment} readOnly={this.state.DataSubmited && ["view"].includes(this.props.access_type) ? true:false} onBlur={(e)=>this.props.handleAuthorityComments(e, idx, id)}></textarea>
												</td>
											</tr>
										)
									})}
								</tbody>
							</table>
						</div>
					</div>)
				})}
			</div>
		)
	// 	var category = ChangeAuthorityStepsData.map((each, idx)=>{
	// 		var step = each.rows.map((row, id)=>{
	// 			return (
	// 				<div className="mb-3" key={idx}>
	// 				    <div className="mb-2">
	// 				        <b>{each.name}</b>
	// 				    </div>
	// 				    <table className='table table-bordered'>
	// 				    	<thead>
	// 				    	    <th width="50%">
	// 				    	       Checks
	// 				    	    </th>
	// 				    	    <th className='text-center'><b>Yes</b> <b>No</b> <b>N/A</b></th>
	// 				    	    <th width="30%">Comments</th>
	// 				    	</thead>
	// 				        <tbody className='vertical-align-middle'>
	// 				            <tr>
	// 				                <td width="50%">{row.name}</td>
	// 				                <td className='three-toggle-align-center'>
	// 				                <ThreeToggle Status={row.status == '' ? 'N/A' :  row.status} firstVal={this.state.firstVal} secondVal={this.state.secondVal} thirdVal={this.state.thirdVal}/>
	// 				                </td>
	// 				                <td width="30%">
	// 				                <textarea className='form-control brio-form-control' rows="3" name="MOC" id="MOC" type="text"></textarea>
	// 				                </td>
	// 				            </tr>
	// 				        </tbody>
	// 				    </table>
	// 				</div>
	// 			)
	// 		})
	// 		return step
	// 	})

	// 	return category
	}
}

export default ChangeAuthoritySteps