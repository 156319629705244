import React, { Component } from 'react';
import { FiLink } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";

class ViewAttachments extends Component {
	constructor(props){
		super(props)
		this.state = {
			view_attachments: []
		}
	}
	static getDerivedStateFromProps(props, state) {
	    if(props.WorkflowAttachments !== state.view_attachments){
	        //Change in props
	        return{
	            view_attachments: props.WorkflowAttachments?props.WorkflowAttachments:state.view_attachments
	        };
	    }
	    return null; // No change to state
	}


    render() {
    	var enable_attach_delete = false
    	if(this.props.enable_attach_delete){
    		enable_attach_delete = this.props.enable_attach_delete
    	}
        var attachments = this.state.view_attachments && this.state.view_attachments.map((each_data,indx)=>{
           return (<div className='mb-2' key={"va"+indx}>
                    <div className="col-12">
                    	<div className='d-flex mb-3 col-lg-8 col-md-8 col-12'>
	                    	<div className='col-md-6 col-10 p-0'>
	                    		<input type="text"  readOnly={true} className="form-control upload-input" placeholder="name" value={each_data.file_name} />
	                    	</div>
	                    	<div className='col-md-2 col-2 view-link' title="View Attachment">
	                    		<a  href={each_data.signed_url} target="_blank"><FiLink /></a>
	                    	</div>
	                    	{enable_attach_delete?
		                    	<div className="col-md-2 delete-button" title="Delete Attachment">
		                    		<AiOutlineDelete onClick={e => this.props.removeWfAttachment(e, each_data.id, each_data.component_id)} />
		                    	</div>:null
		                    }
	                    </div>
                    </div>
                </div>)
        })
        return(
            <div>
                {attachments}
            </div>
        )
    }
}

export default ViewAttachments;