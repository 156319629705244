import React, { Component } from "react";
import axios from 'axios';

class SubTaskSideMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			active_subtask_id: "", // Use ID for tracking active subtask
		};
	}

	componentDidMount() {
		axios.defaults.headers.get['Authorization'] = localStorage.getItem('token');
	}

	handleSubtaskClick = (subtaskId) => {
		this.setState({ active_subtask_id: subtaskId });
	}

	render() {
		const { subtasks, maintask } = this.props;

		const combinedTasks = [...maintask, ...subtasks];
		const menu_subtasks = combinedTasks.map((task, index) => {
			const taskStatus = task.status;
			const isActive = task.id === this.state.active_subtask_id;
			const isCompleted = taskStatus === 'completed';

			const icon = isCompleted
				? <i className="fa fa-check-circle" style={{ color: "green", fontSize: "17px" }}></i>
				: <i className="fa fa-check-circle-o" style={{ color: "gray", fontSize: "17px" }}></i>;

			const taskItemStyle = {
				display: "flex",
				margin: "4px",
			};

			const taskNameStyle = {
				paddingLeft: "10px",
			};

			if (index === 0) {
				return (
					<p key={'main_task_' + index} style={{marginLeft:'9px'}} className={isActive ? 'active main-task' : 'main-task'}>
						<div style={taskItemStyle} onClick={() => this.handleSubtaskClick(task.id)}>
							<div>{icon}</div>
							<div style={taskNameStyle}>
								{task.document_number}
								{isActive ? <p className="dot"></p> : null}
							</div>
						</div>
					</p>
				);
			}
			return (
				<li key={'subtask_' + index} className={isActive ? 'active subtask' : 'subtask'}>
					<div style={taskItemStyle} onClick={() => this.handleSubtaskClick(task.id)}>
						<div>{icon}</div>
						<div style={taskNameStyle}>
							{task.document_number}
							{isActive ? <p className="dot"></p> : null}
						</div>
					</div>
				</li>
			);
		});

		return (
			<div>
				<ul className="subtask-menu-list-item">
					{menu_subtasks}
				</ul>
			</div>
		);
	}
}

export default SubTaskSideMenu;
