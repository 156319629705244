import React from 'react';
import { Component } from 'react';
import '../../Styles/toggle.css'

class CommonToggle extends Component {
    constructor(props) {
        super(props);
        this.state= {
            activeToggle:false,
            firstVal: this.props.firstVal,
            secondVal:this.props.secondVal,
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ activeToggle: nextProps.status });  
      }

    handelExpandNotification = (e,name, index=null, actionType=null)=>{
        this.setState({
            activeToggle:!this.state.activeToggle,
        })
        if(this.props.toggleName && this.props.toggleName == "NewExistingChange"){
            this.props.manpowerStatusChange(e,!this.state.activeToggle,name)
        }
        if(this.props.requestFrom && this.props.requestFrom == "Manpower"){
            this.props.changeActionStatus(e,!this.state.activeToggle,index,name,actionType)
        }

    }
   
    render() {
        const {activeToggle} =this.state;
        return(
                <div>
                   
                    <div className='d-flex  align-items-center common-toggle-block position-relative' >
                    {/* <div className='active-toggle'></div> */}
                        <div className='common-toggle'>
                        {this.props.accessType !="view" ? <div className={activeToggle == false ?'toggle-btn toggle-btn-active cursorPointer':"toggle-btn cursorPointer"} 
                        onClick={(e) => this.handelExpandNotification(e,this.state.firstVal,this.props.index,this.props.actionType)}>
                            {this.state.firstVal}
                        </div> : <div className={activeToggle == false ?'toggle-btn toggle-btn-active cursorPointer':"toggle-btn cursorPointer"}>
                            {this.state.firstVal}
                        </div>}
                        {this.props.accessType !="view" ? <div className={activeToggle == true ?'toggle-btn toggle-btn-active cursorPointer':"toggle-btn cursorPointer"} 
                        onClick={(e) => this.handelExpandNotification(e,this.state.secondVal,this.props.index,this.props.actionType)}>
                            {this.state.secondVal}
                        </div>:
                        <div className={activeToggle == true ?'toggle-btn toggle-btn-active cursorPointer':"toggle-btn cursorPointer"}>
                            {this.state.secondVal}
                        </div>}
                        </div>
                    </div>
                </div>
            )
    }
       
   
}
export default CommonToggle;
