import React, { Component } from 'react';
import $ from 'jquery'
import { API_HOST } from '../../Settings.js'
import axios from 'axios';
import notify from '../CommonComponents/Notify';
import '../../Styles/userRoles.css';
import { FaArrowLeft, FaTimes } from 'react-icons/fa';

class VendorCreation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user_data: localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : {},
            users_data: [],
            selected_user: {},
            users_list: [],

            // vendor data variables
            isChecked: false,
            type_of_vendor_data: [],
            type_of_vendor_list: [],
            showPassword: false,
            otherEmails: [],
            otherEmail: '',

        }
    }


    isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        console.log(emailRegex.test(email), 'check email format.....')
        return emailRegex.test(email);
    };


    handleOtherEmailChange = (e) => {
        const emailValue = e.target.value;
        this.setState({ otherEmail: emailValue });
        const inputElement = e.currentTarget;
        if (inputElement.value === "") {
            inputElement.classList.add("error");
            inputElement.closest('.login-text-box').style.border = 'none';
        } else {
            inputElement.classList.remove("error");
            inputElement.closest('.login-text-box').style.border = '1px solid #C6D9EB';
        }
    };

    addOtherEmail = () => {
        const { otherEmails, otherEmail } = this.state;
        if (otherEmail && !this.isValidEmail(otherEmail)) {
            notify('Please enter valid email.', 'warning')
        } else {
            if (otherEmail.trim() !== '') {
                this.setState({ otherEmails: [...otherEmails, otherEmail], otherEmail: '' });
            }

        }
    };

    removeOtherEmail = (index) => {
        const { otherEmails } = this.state;
        const updatedEmails = [...otherEmails];
        updatedEmails.splice(index, 1);
        this.setState({ otherEmails: updatedEmails });
    };

    handleOtherEmailKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.addOtherEmail();
        }
    };

    handleOtherEmailBlur = () => {
        this.addOtherEmail();
    };

    handleCheckboxChange = () => {
        this.setState(prevState => ({
            isChecked: !prevState.isChecked
        }));
    };

    componentDidMount() {
        this.getTypeOfVendorData()
    }

    componentDidUpdate() {
        const urlParams = new URLSearchParams(window.location.search);
        const workflow = urlParams.get('workflow');
        if (workflow) {
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    }

    getAllData = () => {
        this.getUsersData()
    }

    getTypeOfVendorData = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/vendor`,)
            .then(response => {
                if (response.status === 200) {
                    var type_of_vendor_data = response.data.vendor_type
                    this.setState({ "type_of_vendor_list": type_of_vendor_data })
                    $('.loader').fadeOut('slow')
                }
            }).catch(error_response => {
                console.log(error_response)

            })
    }


    handleChange = (e, state_obj) => {
        const inputElement = e.currentTarget;

        if (inputElement.value === "") {
            inputElement.classList.add("error");
            inputElement.closest('.login-text-box').style.border = 'none';
        } else {
            inputElement.classList.remove("error");
            inputElement.closest('.login-text-box').style.border = '1px solid #C6D9EB';
        }
    }


    handleDescripChange = (e, state_obj) => {
        const inputElement = e.currentTarget;
        if (inputElement.value === "") {
            inputElement.classList.add("error");
        } else {
            inputElement.classList.remove("error");
        }
    }


    isValid = () => {
        var name_regexp = new RegExp("<(?:\"[^\"]*\"['\"]*|'[^']*'['\"]*|[^'\">])+>");
        if (!document.querySelector("#vendor_name").value || !document.querySelector("#vendor_email").value || !document.querySelector("#type_of_vendor").value) {
            var scrollDiv = ""
            if (!document.querySelector("#vendor_name").value) {
                const invoiceIncludeField = document.querySelector('#vendor_name');
                invoiceIncludeField.closest('.login-text-box').style.border = 'none';
                document.querySelector('#vendor_name').classList.add("error")
                scrollDiv = "vendor_name"
            }
            if (!document.querySelector("#vendor_email").value) {
                const invoicExcludeField = document.querySelector('#vendor_email');
                invoicExcludeField.closest('.login-text-box').style.border = 'none';
                document.querySelector('#vendor_email').classList.add("error")
                scrollDiv = "vendor_email"
            }
            if (!document.querySelector("#type_of_vendor").value) {
                const invoiceNumberField = document.querySelector('#type_of_vendor');
                invoiceNumberField.closest('.login-text-box').style.border = 'none';
                document.querySelector('#type_of_vendor').classList.add("error")
                scrollDiv = "type_of_vendor"
            }
            return { "status": false, "reason": "Please fill required field", "id": scrollDiv }
        }
        if (!this.isValidEmail(document.querySelector("#vendor_email").value)) {
            document.querySelector('#vendor_email').classList.add("error")
            var scrollDiv = "vendor_email"
            return { "status": false, "reason": "Please enter valid email.", "id": scrollDiv }
        }
        if (document.querySelector("#other_email").value  && !this.isValidEmail(document.querySelector("#other_email").value)) {
            document.querySelector('#other_email').classList.add("error")
            var scrollDiv = "other_email"
            return { "status": false, "reason": "Please enter valid email.", "id": scrollDiv }
        }
        if ((document.querySelector("#vendor_name").value && name_regexp.test(document.querySelector("#vendor_name").value))) {
            document.querySelector('#vendor_name').classList.add("error")
            var scrollDiv = "vendor_name"
            return { "status": false, "reason": "Please provide valid information in input fields.", "id": scrollDiv }
        }
        if ((document.querySelector("#vendor_password").value && name_regexp.test(document.querySelector("#vendor_password").value))) {
            document.querySelector('#vendor_password').classList.add("error")
            var scrollDiv = "vendor_password"
            return { "status": false, "reason": "Please provide valid information in input fields.", "id": scrollDiv }
        }
        if ((document.querySelector("#vendor_email").value && name_regexp.test(document.querySelector("#vendor_email").value))) {
            document.querySelector('#vendor_email').classList.add("error")
            var scrollDiv = "vendor_email"
            return { "status": false, "reason": "Please provide valid information in input fields.", "id": scrollDiv }
        }
        if ((document.querySelector("#vendor_description").value && name_regexp.test(document.querySelector("#vendor_description").value))) {
            document.querySelector('#vendor_description').classList.add("error")
            var scrollDiv = "vendor_description"
            return { "status": false, "reason": "Please provide valid information in input fields.", "id": scrollDiv }
        }

        if ((document.querySelector("#other_email").value && name_regexp.test(document.querySelector("#other_email").value))) {
            document.querySelector('#other_email').classList.add("error")
            var scrollDiv = "other_email"
            return { "status": false, "reason": "Please provide valid information in input fields.", "id": scrollDiv }
        }

        return { "status": true, "reason": "" }
    }

    submitVendorData = (e) => {
        e.preventDefault()
        const vendor_name = document.getElementById("vendor_name").value
        const vendor_email = document.getElementById("vendor_email").value
        const vendor_password = document.getElementById("vendor_password").value
        const type_of_vendor = document.getElementById("type_of_vendor").value
        const vendor_description = document.getElementById("vendor_description").value
        const other_emails = this.state.otherEmails
        var vendorData = {}
        vendorData["vendor_name"] = vendor_name
        vendorData["vendor_email"] = vendor_email
        vendorData["other_emails"] = other_emails
        vendorData["vendor_password"] = vendor_password
        vendorData["type_of_vendor"] = type_of_vendor
        vendorData["vendor_description"] = vendor_description
        console.log(other_emails, 'other_emails')
        var response_isvalid = this.isValid()
        if (!response_isvalid.status) {
            notify(response_isvalid.reason, 'warning')
            setTimeout(function () {
                document.querySelector('#' + response_isvalid.id).scrollIntoView({
                    behavior: 'smooth', block: "center", inline: "nearest"
                })
            }, 100)
            return false
        }
        else {
            $(".loader").css("display", "flex");
            const auth_token = localStorage.getItem("token")
            const requestData = {
                vendorData: vendorData,
                userData: this.state.user_data
            };
            axios.defaults.headers.post['Authorization'] = auth_token
            axios.post(`${API_HOST}/vendor`, requestData)
                .then(response => {
                    if (response.status === 200 && response.data.success) {
                        notify(response.data.message, 'success');
                        setTimeout(() => {
                            window.location.href = '/vendors?workflow=ibd';
                        }, 2000)
                    } else {
                        notify(response.data.message, 'error');
                    }
                    $('.loader').fadeOut('slow');
                }).catch(response => {
                    $('.loader').fadeOut('slow')
                    // ErrorHandling(response)
                });
        }

    }

    render() {
        const { isChecked, showPassword, otherEmails, otherEmail } = this.state;
        return (
            <div className='container' style={{ "paddingBottom": "250px", "paddingTop": "25px" }}>
                <div className='col-12'>
                    <div className="card vendor-card pb-3">
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-sm-12 col-12 d-flex justify-content-between'>
                                    <h5 className="vendor-card-title" > <span className="mr-3 arrow-left" style={{ fontSize: '1.0rem', marginRight: '17px' }} onClick={() => { window.location.href = '/vendors?workflow=ibd' }}><FaArrowLeft /></span><b>New Vendor</b></h5>
                                </div>
                            </div>
                            <div className="row mb-3 mt-4">
                                <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                    <div className='form-label-text font-12'>Vendor Name <span style={{ color: 'red', fontSize: '15px' }}>*</span></div>
                                    <div className='login-text-box'>
                                        <input className='ps-3 border-0 w-100 font-14 form-control' name="vendor_name" id="vendor_name" type="text" onChange={(e) => this.handleChange(e, '')} />
                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                    <div className='form-label-text font-12'>Type Of Vendor <span style={{ color: 'red', fontSize: '15px' }}>*</span></div>
                                    <div className='login-text-box'>
                                        <select className='border-0 w-100 font-14 form-select pl-1 pt-1' name="type_of_vendor" id="type_of_vendor" onChange={(e) => this.handleChange(e, '')}>
                                            <option value="" hidden></option>
                                            {this.state.type_of_vendor_list && this.state.type_of_vendor_list.map((option, index) => (
                                                <option key={index} value={option.id}>{option.vendor_type}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                    <div className='form-label-text font-12'>Vendor E-mail <span style={{ color: 'red', fontSize: '15px' }}>*</span></div>
                                    <div className='login-text-box'>
                                        <input className='ps-3 border-0 w-100 font-14 form-control' name="vendor_email" id="vendor_email" type="text" onChange={(e) => this.handleChange(e, '')} />
                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                    <div className='form-label-text font-12'>Password</div>
                                    <div className='login-text-box'>
                                        <input
                                            className='ps-3 border-0 w-100 font-14 form-control'
                                            name="vendor_password"
                                            id="vendor_password"
                                            type="password"
                                            onChange={(e) => this.handleChange(e, '')}
                                        />  
                                    </div>
                                </div>


                                <div className='col-lg-12 col-md-12 col-sm-12 col-12 mt-3'>
                                    <div className="form-group">
                                        <div className='form-label-text font-12'>Vendor Description/Notes</div>
                                        <textarea className="form-control input-text-description" name="type_of_vendor" style={{ borderRadius: '5px' }} id="vendor_description" rows="3" onChange={(e) => this.handleDescripChange(e, '')}></textarea>
                                    </div>
                                </div>

                                <div className='col-12 col-md-12 col-sm-12 col-12 mt-3'>
                                    <div className='form-label-text font-12'>Other E-mails</div>
                                    <div className='login-text-box d-flex'>
                                        <input
                                            className='ps-3 border-0 w-100 font-14 form-control'
                                            name='other_email'
                                            id='other_email'
                                            type='text'
                                            value={otherEmail}
                                            onChange={this.handleOtherEmailChange}
                                            onKeyDown={this.handleOtherEmailKeyDown}
                                            onBlur={this.handleOtherEmailBlur}
                                        />
                                    </div>
                                </div>

                                <div className='col-12 col-md-12 col-sm-12 col-12 mt-2 d-flex'>
                                    {otherEmails.map((email, index) => (
                                        <div key={index} className='other-email-item d-flex align-items-center me-2 mb-2' >
                                            <span>{email}</span>
                                            <span className='email-remove-icon' onClick={() => this.removeOtherEmail(index)}>
                                                <FaTimes />
                                            </span>
                                        </div>
                                    ))}
                                </div>

                            </div>


                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <div id='vendor-button-container' className="d-flex d-md-flex justify-content-md-end">
                                    <button className="cancel-button" type="button" onClick={() => { window.location.href = '/vendors?workflow=ibd' }}>Cancel</button>
                                    <button className="submit-button" type="button" onClick={(e) => { this.submitVendorData(e) }}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default VendorCreation;

