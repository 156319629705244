import React, { Component } from 'react';
import { FiLink } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import Toggle from '../CommonComponents/Toggle';

class ViewAttachmentsDependOfForm extends Component {
    constructor(props){
        super(props)
        this.state = {
            view_attachments: [],
            deletable_wf_attachments:[]
        }
    }
    static getDerivedStateFromProps(props, state) {
        if(props.WorkflowAttachments !== state.view_attachments){
            //Change in props
            return{
                view_attachments: props.WorkflowAttachments?props.WorkflowAttachments:state.view_attachments
            };
        }
        return null; // No change to state
    }

    handleMarkAttachmentAsDelete=(e, is_toggle_active, id_of_wf_attachment)=>{
        var delete_or_cancel = 'cancel'
        if(is_toggle_active){
            delete_or_cancel='delete'
        }
        this.props.handleMarkAttachmentAsDelete(e, id_of_wf_attachment, delete_or_cancel)
    }

    render() {
        var access_type = "view"
        if (this.props.access_type){
            access_type = this.props.access_type
        }
        var deletable_wf_attachments = this.props.deletable_wf_attachments
        var attachments = this.state.view_attachments && this.state.view_attachments.map((each_data,indx)=>{
           return (<div className='mb-2' key={"va"+indx}>
                    <div className="col-12">
                        <div className='d-flex mb-3 col-lg-8 col-md-8 col-12'>
                            <div className='col-md-6 col-10 p-0'>
                                <input type="text"  readOnly={true} className="form-control upload-input" placeholder="name" value={each_data.file_name} />
                            </div>
                            <div className='col-md-2 col-2 view-link' title="View Attachment">
                                <a  href={each_data.signed_url} target="_blank"><FiLink /></a>
                            </div>
                            {['update'].includes(access_type) ?
                                <div className="d-flex justify-content-between align-items-center " title="Mark for delete">
                                    <Toggle requestFrom="ViewAttachments" accessType={access_type}
                                        status={deletable_wf_attachments.includes(each_data.id)}
                                        index={each_data.id}
                                        handleMarkAttachmentAsDelete={this.handleMarkAttachmentAsDelete}
                                         />
                                </div>:null
                            }
                        </div>
                    </div>
                </div>)
        })
        return(
            <div>
                {['update'].includes(access_type)?
                    <div className="col-12">
                        <div className="d-flex col-lg-8 col-md-8 col-12 d-flex">
                            <div className="col-7">
                            </div>
                            <div className="col-2">
                                <div className="delete-button">
                                    <AiOutlineDelete  disabled={true} />
                                </div>
                            </div>
                        </div>
                    </div>:null
                }
                {attachments}
            </div>
        )
    }
}

export default ViewAttachmentsDependOfForm;