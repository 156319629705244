import React, { Component } from 'react';
import ThreeToggle from '../../CommonComponents/ThreeToggle';
// import { JobInitiatorAndExecutorName } from '../../CommonComponents/MiscellaneousObject';

class JobInitiatorAndExecutorRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstVal : "Yes",
            secondVal :"No",
            thirdVal: " N/A",
            DataSubmited: this.props.DataSubmited,
        }
    }
    componentWillReceiveProps = (props) => {
        this.setState ({
            DataSubmited: props.DataSubmited,
        })
    }
    render() {
        var tr_content = this.props.ExecutorGeneralChecksData.map((row,key)=>{
                return(
                        <tr key={key}>
                            <td width="90%">{row.name}</td>
                            <td className='three-toggle-align-center'>
                            <ThreeToggle Status={row.status == '' ? 'N/A' :  row.status} 
                            firstVal={this.state.firstVal} secondVal={this.state.secondVal} 
                            thirdVal={this.state.thirdVal} handleInitiatorChecks={this.props.handleInitiatorChecks} 
                            MainIndex="" RowIndex={key} RequestType="ChangeInitiator"  DataSubmited={this.state.DataSubmited} access_type={this.props.access_type}/>
                            </td>
                        </tr>        
                )
            })
        return(
            tr_content
        )
    }
}

export default JobInitiatorAndExecutorRow;