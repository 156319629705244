import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { TfiArrowCircleRight } from "react-icons/tfi"
import { TbCurrencyRupee } from "react-icons/tb";

class Pagination extends Component {
    render() {
        const { currentPage, totalPages, itemsPerPage, totalItems, onPageChange } = this.props;

        const indexOfFirstItem =  Math.min((currentPage - 1) * itemsPerPage, totalItems - 1);;
        const indexOfLastItem = Math.min(currentPage * itemsPerPage, totalItems);
        const displayRange = `${indexOfFirstItem + 1} - ${indexOfLastItem} of ${totalItems} records`;

        const maxPageLinks = 10;
        const startPage = Math.min(1, currentPage - Math.floor(maxPageLinks / 2));
        const endPage = Math.min(totalPages, startPage + maxPageLinks - 1);

        const adjustedStartPage = Math.max(1, endPage - maxPageLinks + 1);

        return (
            <div className="pagination-container">
                <div className="pagination">
                    {currentPage > 1 && (
                        <span onClick={() => onPageChange(currentPage - 1)} className="arrow">
                            {'<'}
                        </span>
                    )}

                    {adjustedStartPage > 1 && (
                        <>
                            <span onClick={() => onPageChange(1)} className="page-number">
                                1
                            </span>
                            {adjustedStartPage > 2 && <span className="ellipsis">...</span>}
                        </>
                    )}

                    {[...Array(endPage - adjustedStartPage + 1).keys()].map((index) => {
                        const pageNumber = adjustedStartPage + index;
                        return (
                            <span
                                key={pageNumber}
                                onClick={() => onPageChange(pageNumber)}
                                className={`page-number ${currentPage === pageNumber ? 'selected' : ''}`}
                            >
                                {pageNumber}
                            </span>
                        );
                    })}

                    {endPage < totalPages && (
                        <>
                            {endPage < totalPages - 1 && <span className="ellipsis">...</span>}
                            <span onClick={() => onPageChange(totalPages)} className="page-number">
                                {totalPages}
                            </span>
                        </>
                    )}

                    {currentPage < totalPages && (
                        <span onClick={() => onPageChange(currentPage + 1)} className="arrow">
                            {'>'}
                        </span>
                    )} 
                </div>
                <div className="page-info">{displayRange}</div>
            </div>
        );
    }
}


class InvoiceListRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: this.props.thisData.page,
            itemsPerPage: this.props.thisData.limit, 
            showModal: false,
            deleteUserId: null,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.thisData.page !== this.props.thisData.page) {
            this.setState({ currentPage: this.props.thisData.page });
        }
    }
    onPageChange = (pageNumber) => {
        const { updatePagination } = this.props;
        this.setState({ currentPage: pageNumber });
        updatePagination(pageNumber, this.state.itemsPerPage);
    };

    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        const page = parseInt(queryParams.get('page'), 10);
    
        if (!isNaN(page)) {
            this.setState({ currentPage: page });
        }
    }
    


    render() {

        const { filterRequestData } = this.props.thisData;
        // const { total_exclude_gst_amount } = this.props.thisData;
        const { total_include_gst_amount } = this.props.thisData;
        const { currentPage, itemsPerPage } = this.state;
        // Calculate pagination
    
        // Calculate pagination
        const totalRecords = this.props.thisData.total_records;
        // const indexOfLastItem = Math.min(currentPage * itemsPerPage, totalRecords);
        // const indexOfFirstItem = Math.max(0, indexOfLastItem - itemsPerPage);
        // const currentItems = filterRequestData ? filterRequestData.slice(indexOfFirstItem, indexOfLastItem) : []
        // const indexOfFirstItem =  Math.min((currentPage - 1) * itemsPerPage, totalRecords - 1);;
        // const indexOfLastItem = Math.min(currentPage * itemsPerPage, totalRecords);
        // const currentItems = filterRequestData ? filterRequestData.slice(indexOfFirstItem, indexOfLastItem): [];
        const currentItems = filterRequestData || [];
        const formatCurrency = (amount) => new Intl.NumberFormat('en-IN', { currency: 'INR' }).format(amount);
        const emptyRow = (
            <><tr key="empty-row" style={{ border: 'none', color: '#898989', fontSize: '14px', padding: '12px' }}>
                <td style={{ border: 'none' }}></td>
                <td style={{ border: 'none' }}></td>
                <td style={{ border: 'none' }}></td>
                <td style={{ border: 'none' }}></td>
                <td style={{ border: 'none' }}></td>
                <td style={{ border: 'none' }}></td>
            </tr>
            </>
        );

        const totalRow = (
            <tr key="total-row">
              <td colSpan="4" style={{ border: 'none' }}>
              </td>
              <td style={{ border: 'none', color: '#898989', fontSize: '14px' }}>
                <span>
                  <TbCurrencyRupee />
                </span>{' '}
                <strong>{formatCurrency(total_include_gst_amount)}</strong>
              </td>
              {/* <td style={{ border: 'none', color: '#898989', fontSize: '14px' }}>
                <span>
                  <TbCurrencyRupee />
                </span>{' '}
                <strong>{formatCurrency(total_exclude_gst_amount)}</strong>
              </td>
              <td colSpan="2" style={{ border: 'none', color: '#898989', fontSize: '14px'}}></td> */}
            </tr>
        );
        
        const dashboard_data = [(currentPage === 1 && currentItems.length === 0 ? [] : [emptyRow]), currentItems.map((data, index) => (
            <tr key={index} style={{ border: 'none', color: '#898989', fontSize: '14px', padding: '12px' }}>
                <td style={{ border: 'none' }}>{data.vendor}</td>
                <td style={{ border: 'none' }}>{data.vendor_type}</td>
                <td style={{ border: 'none' }}>{data.invoice_number}</td>
                <td style={{ border: 'none' }}>{data.invoice_date}</td>
                <td style={{ border: 'none' }}><span><TbCurrencyRupee /></span> {formatCurrency(data.invoice_total_value_include_gst)}</td>
                <td style={{ border: 'none' }}>{data.reference_number}</td>
                <td style={{ border: 'none' }}>{data.current_stage}</td>
                <td style={{ border: 'none' }}>
                    <div className='d-flex'>
                        {data.status.toLowerCase() === "processed" ? (
                            <button className="processed-status" type="button">Processed</button>
                        ) : data.status.toLowerCase() === "rejected" ? (
                            <button className="rejected-status" type="button">Rejected</button>
                        ) : (
                            <button className="pending-status" type="button">Pending</button>
                        )}
                        <div className='dashboard-circle'>
                            <Link to={`/invoice/${data.id}?page=${currentPage}`}>
                                <TfiArrowCircleRight />
                            </Link>
                        </div>
                    </div>
                </td>
                <td className='m-0 p-0 d-flex' style={{ border: 'none' }}>
                </td>
            </tr>
        ))];
        return (
            <div>

                <div>
                    <div className='d-flex nav-tab w-100' style={{ marginLeft: '8px' }}>
                        <div className={this.props.thisData.tab === "all" ? 'nav-tab-item nav-tab-item-active' : 'nav-tab-item'} onClick={(e) => this.props.changeTabHandler(e, 'all')}>All</div>
                        <div className={this.props.thisData.tab === "pending" ? 'nav-tab-item nav-tab-item-active' : 'nav-tab-item'} onClick={(e) => this.props.changeTabHandler(e, 'pending')}>Pending</div>
                        <div className={this.props.thisData.tab === "rejected" ? 'nav-tab-item nav-tab-item-active' : 'nav-tab-item'} onClick={(e) => this.props.changeTabHandler(e, 'rejected')}>Rejected</div>
                        <div className={this.props.thisData.tab === "processed" ? 'nav-tab-item nav-tab-item-active' : 'nav-tab-item'} onClick={(e) => this.props.changeTabHandler(e, 'processed')}>Processed</div>
                    </div>
                    <div className="dashboard-request-block mt-3" style={{ paddingLeft: '20px' }}>
                        <div className="row align-items-center dashboard-request-list pe-1">
                            {currentItems.length > 0 ?
                                <div className="col-lg-12 col-md-12 col-12" style={{ padding: 0, margin: 0 }}>
                                    <div className="table-container">
                                        <table className="table" style={{ margin: 0 }}>
                                            <thead >
                                                <tr>
                                                    <th style={{ border: 'none', textAlign: 'left' }}>Vendor</th>
                                                    <th style={{ border: 'none', textAlign: 'left' }}>Vendor Type</th>
                                                    <th style={{ border: 'none', textAlign: 'left' }}>Invoice ID</th>
                                                    <th style={{ border: 'none', textAlign: 'left' }}>Invoice Date</th>
                                                    <th style={{ border: 'none', textAlign: 'left' }}>Invoice (Including GST)</th>
                                                    <th style={{ border: 'none', textAlign: 'left' }}>Rallis Invoice Number</th>
                                                    <th style={{ border: 'none', textAlign: 'left' }}>Current Stage</th>
                                                    <th style={{ border: 'none', textAlign: 'left' }}>Status</th>
                                                </tr>
                                                <tr>
                                                    <td colSpan="8" style={{ border: 'none', marginTop: 0, paddingTop: 0 }}>
                                                        <div className='nav-tab w-100' style={{ margin: 0, padding: 0 }}></div>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody style={{ border: 'none' }}>
                                                {totalRow}
                                                {dashboard_data}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Pagination
                                        currentPage={parseInt(currentPage)}
                                        totalPages={Math.ceil(totalRecords / itemsPerPage)}
                                        totalItems={totalRecords}
                                        itemsPerPage={itemsPerPage}
                                        onPageChange={this.onPageChange}
                                    />
                                </div>
                                : <div style={{ color: 'gray' }}>No records found</div>
                            }
                        </div>
                    </div>



                </div>
            </div>
        )
    }
}

export default InvoiceListRequest;