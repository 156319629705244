import React, { Component } from 'react';
import $ from "jquery";


class EnergyManagementActionPlanTabelRow extends Component {

    componentDidMount(){
        if(this.props.isDataSubmited == false){
            $('.targetDate').datepicker({
                autoclose: true,
                format: "dd/mm/yyyy",
                immediateUpdates: true,
                todayBtn: true,
                todayHighlight: true
            })
        }
        
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.isDataSubmited == false){
            $('.targetDate').datepicker({
                autoclose: true,
                format: "dd/mm/yyyy",
                immediateUpdates: true,
                todayBtn: true,
                todayHighlight: true
            })
            // $('.targetDate').datepicker('setDate', null);
        }else{
            $('.targetDate').datepicker('update'); 
        }
    }
    render(){        
        var tr_content = this.props.EnergyManagementActionRow && this.props.EnergyManagementActionRow.rows.map((row,key) => {
            return(
                <tr key={"eng-man"+key}>
                    <td>{key + 1}</td>
                    <td>{row.name}</td>
                    <td>
                        {this.props.DataSubmited || !this.props.userPermissions ?
                        <input className='form-control' name="action_by" id={row.name_slug+'-actionBy'} type="text" value={row.action_by} disabled/>:
                        <input className='form-control' name="action_by" id={row.name_slug+'-actionBy'} type="text" />}
                    </td>
                    <td>{this.props.DataSubmited || !this.props.userPermissions ?
                        <input className='form-control targetDate' name="target_date" id={row.name_slug+'-targetDate'} value={row.target_date} disabled/>
                        :
                        <input className='form-control targetDate' name="target_date" id={row.name_slug+'-targetDate'}  />
                    }
                    </td>
                    <td>
                        {this.props.DataSubmited || !this.props.userPermissions ?
                        <input className='form-control targetDate' name="completion_date" id={row.name_slug+'-completionDate'} value={row.completion_date} disabled/>
                        :
                        <input className='form-control targetDate' name="completion_date" id={row.name_slug+'-completionDate'} />
                        }
                    </td>
                </tr>        
            )
        })
        return(
            tr_content
        )
    }
}

export default EnergyManagementActionPlanTabelRow;