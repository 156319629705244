import React from 'react';
import { Component } from 'react';
import '../../Styles/toggle.css'

class Toggle extends Component {
    constructor() {
        super();
        this.state= {
            activeToggle:false,
        }
    }
    // componentDidMount(){
    //     if(this.props.status){
    //         this.setState({
    //             activeToggle:this.props.status
    //         })
    //     }
    // }
    componentWillReceiveProps(nextProps) {
        this.setState({ activeToggle: nextProps.status });  
      }
    handelExpandNotification = (e,name, index=null, actonType=null) =>{
        this.setState({
            activeToggle:!this.state.activeToggle,
        })
        if(this.props.category && this.props.step){
            this.props.onChangeHandler(e,this.props.category_type, this.props.category, this.props.step, !this.state.activeToggle, this.props.categoryIndex, this.props.stepIndex, '')
        }
        if(this.props.requestFrom && this.props.requestFrom == "Manpower"){
            this.props.changeActionStatus(e,!this.state.activeToggle,index,name,actonType)
        }
        if(this.props.requestFrom && this.props.requestFrom == "QualityOfProduct"){
            this.props.handleQualityProduct(e,!this.state.activeToggle)
        }
        if(this.props.requestFrom && this.props.requestFrom == "RiskLevel"){
            this.props.changeActionStatus(e,!this.state.activeToggle,index)
        }
        if(this.props.requestFrom && this.props.requestFrom=='ViewAttachments'){
            this.props.handleMarkAttachmentAsDelete(e, !this.state.activeToggle, index)
            // here index is the wf_attachment_row_id
        }
    }
   
    render() {
        const {activeToggle} =this.state;
        var access_type = "view"
        if(this.props){
            access_type = this.props.accessType
        }
        return(
                <div>
                   
                    <div className='d-flex justify-content-between align-items-center toggle-block position-relative' >
                    <div className='active-toggle'></div>
                        <div className={activeToggle == false ?'toggle-btn toggle-btn-active':"toggle-btn"}>
                            {["create", "update"].includes(access_type) ?
                                <span className='cursorPointer'
                                    onClick={(e) => this.handelExpandNotification(e,'No',this.props.index,this.props.actionType )}>
                                    No
                                </span>:
                                <span className='cursorPointer' disabled={true}>
                                    No
                                </span>
                            }
                        </div>
                        <div className={activeToggle == true ?'toggle-btn toggle-btn-active':"toggle-btn"}>
                            {["create", "update"].includes(access_type) ?
                                <span className='cursorPointer'
                                    onClick={(e) => this.handelExpandNotification(e,'Yes',this.props.index, this.props.actionType )}>
                                    Yes
                                </span>:
                                <span className='cursorPointer' disabled={true}>
                                    Yes
                                </span>
                            }
                        </div>
                    </div>
                </div>
            )
    }
       
   
}
export default Toggle;
