import axios from 'axios';
import React, { Component } from 'react';
import { API_HOST } from '../../Settings';
import $ from 'jquery';
import notify from '../CommonComponents/Notify';





class RejectButton extends Component{

    rejectSubmitHandler = (e) =>{
        e.preventDefault()
            if(window.confirm("Are you sure, you want reject this request?")){
                var resp = true
                if(this.props.hasOwnProperty('handleOfComment')){
                    resp = this.props.handleOfComment(e, false, "reject")
                }
                var view_comments = this.props.WorkflowComponent?this.props.WorkflowComponent.workflow_comments:[]
                if(resp || view_comments.length>0){
                    $('.loader').fadeIn()
                    var filter_reject_action = this.props.WorkflowComponent.workflow_actions.filter((actionData,indx)=>{
                        return actionData.action.name == 'Rejected'
                    })
                    var reject_data = {
                        workflow_actions_id:filter_reject_action[0].id,
                        action_id:filter_reject_action[0].action.id,
                    }
                    axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')
                    axios.post(`${API_HOST}/workflow/${this.props.RequestType}/request/${this.props.DocumentNumber}/action/save/`, reject_data)
                    .then(response => {
                        if (response.status === 200) {
                            notify('Request Rejected successfully','success')
                            setTimeout(()=>{
                                $('.loader').fadeOut('slow')
                                window.location.reload();
                            }, 4000)
                        }
                    }).catch(response => {
                        // ErrorHandling(response)
                    });
                }else{
                    notify('Comments mandatory for reject.','warning')
                }
            }
    }

    render(){
        var pending_ask_more = false
        var reason_msg = ''
        var current_comp_ask_for_more_history = []
        if(['approved', 'rejected'].includes(this.props.stage_status)){
            reason_msg = "This stage already "+this.props.stage_status
        }
        else if(this.props.IsApproved){
            reason_msg = "This department already approved" 
        }
        if(this.props.ask_for_more_history){
            current_comp_ask_for_more_history = this.props.ask_for_more_history.filter((each, index)=>{
                    if(each.react_component.name==this.props.current_component_name){
                        return each.status.name_slug=="open"
                    }else{
                        return false
                    }
                })
        }
        if(current_comp_ask_for_more_history.length>0){
            pending_ask_more = true
            reason_msg = 'More information requested'
        }
        if(this.props.PlantMOCOpinion=="Rejected"){
            reason_msg = "Plant MOC not accepted rejection"
        }

        var is_required_dependent_comp_approved = true
        if(this.props.hasOwnProperty('dependent_comp_info')){
            var dependent_comp_info = this.props.dependent_comp_info
            if(dependent_comp_info['dependent_comp_required']){
                if(!dependent_comp_info['is_required_dependent_comp_approved']){
                    is_required_dependent_comp_approved=false
                    reason_msg = "Pending dependent head approvals"
                }
            }
        }

        return(
            <div>
                {['approved', 'rejected'].includes(this.props.stage_status) || is_required_dependent_comp_approved==false || pending_ask_more==true || this.props.IsApproved || this.props.PlantMOCOpinion == "Rejected" ?
                        !this.props.userPermissions.can_reject || ['approved', 'rejected'].includes(this.props.stage_status)|| this.props.IsApproved?null
                        :
                        <span title={reason_msg}>
                            <button className="btn btn-disabled cmnt-reject-btn" disabled={true} type="button">Reject
                            </button>
                        </span>
                    :
                    this.props.userPermissions.can_reject ? 
                    <button className="btn btn-secondary cmnt-reject-btn" type="button"  onClick={this.rejectSubmitHandler}>Reject</button>: null
                }
            </div>
        )
    }
}

export default RejectButton;