

function getAssessmentRowNames() {
   var AssessmentRowNames = [
   {
      header: {
         name: "Process Considerations",
         name_slug: "process-considerations"
      },
      rows: [
         {
            name: "Introduction of hazardous Chemicals to non-hazardous area",
            name_slug: "introduction-of-hazardous-chemicals-to-non-hazardous-area",
            status: false,
            remarks: ""
         },
         {
            name: "Potential for adverse or runaway reactions",
            name_slug: "potential-for-adverse-or-runaway-reactions",
            status: false,
            remarks: ""
         },
         {
            name: "Temperature",
            name_slug: "temperature",
            status: false,
            remarks: ""
         },
         {
            name: "Pressure",
            name_slug: "pressure",
            status: false,
            remarks: ""
         },
         {
            name: "Flow",
            name_slug: "flow",
            status: false,
            remarks: ""
         },
         {
            name: "Level",
            name_slug: "level",
            status: false,
            remarks: ""
         },
         {
            name: "Usage  (% space of Designed Capacity)",
            name_slug: "usage--(%-space-of-designed-capacity)",
            status: false,
            remarks: ""
         },
         {
            name: "Composition",
            name_slug: "composition",
            status: false,
            remarks: ""
         },
         {
            name: "Toxicity",
            name_slug: "toxicity",
            status: false,
            remarks: ""
         },
         {
            name: "Flash point",
            name_slug: "flash-point",
            status: false,
            remarks: ""
         },
         {
            name: "Alteration of process",
            name_slug: "alteration-of-process",
            status: false,
            remarks: ""
         },
         {
            name: "Material properties",
            name_slug: "material-properties",
            status: false,
            remarks: ""
         }
      ]
   },
   {
      header: {
         name: "Operation Methods:",
         name_slug: "operation-methods:"
      },
      rows: [
         {
            name: "Start up",
            name_slug: "start-up",
            status: false,
            remarks: ""
         },
         {
            name: "Routine operation",
            name_slug: "routine-operation",
            status: false,
            remarks: ""
         },
         {
            name: "Shutdown",
            name_slug: "shutdown",
            status: false,
            remarks: ""
         },
         {
            name: "Power Failure (elec or air)",
            name_slug: "power-failure-(elec-or-air)",
            status: false,
            remarks: ""

         },
         {
            name: "Emergency stop locations",
            name_slug: "emergency-stop-locations",
            status: false,
            remarks: ""
         },
         {
            name: "Layout & positioning of  Control & instruments",
            name_slug: "layout-&-positioning-of--control-&-instruments",
            status: false,
            remarks: ""
         },
         {
            name: "Use of handtools and portable equipment",
            name_slug: "use-of-handtools-and-portable-equipment",
            status: false,
            remarks: ""
         },
         {
            name: "Maintenance requirements, frequencies, procedures and/or required skills.",
            name_slug: "maintenance-requirements,-frequencies,-procedures-and/or-required-skills.",
            status: false,
            remarks: ""
         },
         {
            name: "Requirements for new/ Modified procedures",
            name_slug: "requirements-for-new/-modified-procedures",
            status: false,
            remarks: ""
         },
         {
            name: "Ergonomics/manual handling (no lifting above shoulder height or below knee height)",
            name_slug: "ergonomics/manual-handling-(no-lifting-above-shoulder-height-or-below-knee-height)",
            status: false,
            remarks: ""
         },
         {
            name: "Ergonomics/manual handling (Strenuous lifting or repetitive tasks)",
            name_slug: "ergonomics/manual-handling-(strenuous-lifting-or-repetitive-tasks)",
            status: false,
            remarks: ""
         },
         {
            name: "Segregation from forklifts?",
            name_slug: "segregation-from-forklifts?",
            status: false,
            remarks: ""
         },
         {
            name: "Ease of cleaning change- over parts",
            name_slug: "ease-of-cleaning-change--over-parts",
            status: false,
            remarks: ""
         },
         {
            name: "Additional training required",
            name_slug: "additional-training-required",
            status: false,
            remarks: ""
         }
      ]
   },
   {
      header: {
         name: "Engineering Methods:",
         name_slug: "engineering-methods:"
      },
      rows: [
         {
            name: "Preparation & isolation",
            name_slug: "preparation-&-isolation",
            status: false,
            remarks: ""
         },
         {
            name: "Ability for maintenance (mechanical & electrical)",
            name_slug: "ability-for-maintenance-(mechanical-&-electrical)",
            status: false,
            remarks: ""
         },
         {
            name: "Demountability",
            name_slug: "demountability",
            status: false,
            remarks: ""
         },
         {
            name: "Lighting",
            name_slug: "lighting",
            status: false,
            remarks: ""
         },
         {
            name: "Emergency stop strategy",
            name_slug: "emergency-stop-strategy",
            status: false,
            remarks: ""
         },
         {
            name: "Maintenance access",
            name_slug: "maintenance-access",
            status: false,
            remarks: ""
         },
         {
            name: "Inspectability",
            name_slug: "inspectability",
            status: false,
            remarks: ""
         }
      ]
   },
   {
      header: {
         name: "Safety Equipment:",
         name_slug: "safety-equipment:"
      },
      rows: [
         {
            name: "PPE requirements",
            name_slug: "ppe-requirements",
            status: false,
            remarks: ""
         },
         {
            name: "Means of access & egrees",
            name_slug: "means-of-access-&-egrees",
            status: false,
            remarks: ""
         },
         {
            name: "Fire fighting systems",
            name_slug: "fire-fighting-systems",
            status: false,
            remarks: ""
         },
         {
            name: "Fire detection systems",
            name_slug: "fire-detection-systems",
            status: false,
            remarks: ""
         },
         {
            name: "First aid facilities / requirements",
            name_slug: "first-aid-facilities-/-requirements",
            status: false,
            remarks: ""
         },
         {
            name: "Access to or the performance of fire fighting / rescue / first aid equipment",
            name_slug: "access-to-or-the-performance-of-fire-fighting-/-rescue-/-first-aid-equipment",
            status: false,
            remarks: ""
         },
         {
            name: "Fire protection of cables, pipes, plant structures & equipment, escape routes or other features",
            name_slug: "fire-protection-of-cables,-pipes,-plant-structures-&-equipment,-escape-routes-or-other-features",
            status: false,
            remarks: ""
         }
      ]
   },
   {
      header: {
         name: "Security:",
         name_slug: "security:"
      },
      rows: [
         {
            name: "Effect on intrusion security",
            name_slug: "effect-on-intrusion-security",
            status: false,
            remarks: ""
         },
         {
            name: "Equipment/Personal",
            name_slug: "equipment/personal",
            status: false,
            remarks: ""
         },
         {
            name: "Is guarding adequate?",
            name_slug: "is-guarding-adequate?",
            status: false,
            remarks: ""
         },
         {
            name: "Are ALL guards interlocked?",
            name_slug: "are-all-guards-interlocked?",
            status: false,
            remarks: ""
         },
         {
            name: "Ladders/platforms/walkways",
            name_slug: "ladders/platforms/walkways",
            status: false,
            remarks: ""
         }
      ]
   },
   {
      header: {
         name: "Engineering Design:",
         name_slug: "engineering-design:"
      },
      rows: [
         {
            name: "Pressure suitability & protection",
            name_slug: "pressure-suitability-&-protection",
            status: false,
            remarks: ""
         },
         {
            name: "Temperature suitability & protection",
            name_slug: "temperature-suitability-&-protection",
            status: false,
            remarks: ""
         },
         {
            name: "Materials compatibility",
            name_slug: "materials-compatibility",
            status: false,
            remarks: ""
         },
         {
            name: "Corrosion considerations",
            name_slug: "corrosion-considerations",
            status: false,
            remarks: ""
         },
         {
            name: "Erosion considerations",
            name_slug: "erosion-considerations",
            status: false,
            remarks: ""
         },
         {
            name: "New isolations/alarms required",
            name_slug: "new-isolations/alarms-required",
            status: false,
            remarks: ""
         },
         {
            name: "Effects on other equipment Up/down stream",
            name_slug: "effects-on-other-equipment-up/down-stream",
            status: false,
            remarks: ""
         },
         {
            name: "Confined space considerations",
            name_slug: "confined-space-considerations",
            status: false,
            remarks: ""
         },
         {
            name: "Height & roof access considerations",
            name_slug: "height-&-roof-access-considerations",
            status: false,
            remarks: ""
         },
         {
            name: "Does lifting equipment comply with applicable lifting standards?",
            name_slug: "does-lifting-equipment-comply-with-applicable-lifting-standards?",
            status: false,
            remarks: ""
         },
         {
            name: "New racking to have racking configuration drawings completed",
            name_slug: "new-racking-to-have-racking-configuration-drawings-completed",
            status: false,
            remarks: ""
         }
      ]
   },
   {
      header: {
         name: "Loads on/Strength of:",
         name_slug: "loads-on/strength-of:"
      },
      rows: [
         {
            name: "Floors",
            name_slug: "floors",
            status: false,
            remarks: ""
         },
         {
            name: "Structures",
            name_slug: "structures",
            status: false,
            remarks: ""
         },
         {
            name: "Vessels",
            name_slug: "vessels",
            status: false,
            remarks: ""
         },
         {
            name: "Pipe works/support/bellows",
            name_slug: "pipe-works/support/bellows",
            status: false,
            remarks: ""
         }
      ]
   },
   {
      header: {
         name: "Suitability of Permanent Or Temporary:",
         name_slug: "suitability-of-permanent-or-or-temporary:"
      },
      rows: [
         {
            name: "Pipe work/support/bellows",
            name_slug: "pipe-work/support/bellows",
            status: false,
            remarks: ""
         },
         {
            name: "Valves",
            name_slug: "valves",
            status: false,
            remarks: ""
         },
         {
            name: "Filters",
            name_slug: "filters",
            status: false,
            remarks: ""
         },
         {
            name: "Special fittings",
            name_slug: "special-fittings",
            status: false,
            remarks: ""
         },
         {
            name: "Instrumentation & control system",
            name_slug: "instrumentation-&-control-system",
            status: false,
            remarks: ""
         },
         {
            name: "Fire/gas/smoke detection",
            name_slug: "fire/gas/smoke-detection",
            status: false,
            remarks: ""
         },
         {
            name: "Ventilation & drainage systems",
            name_slug: "ventilation-&-drainage-systems",
            status: false,
            remarks: ""
         }
      ]
   },
   {
      header: {
         name: "Electrical Equipment:",
         name_slug: "electrical-equipment:"
      },
      rows: [
         {
            name: "Flame proofing requirement",
            name_slug: "flame-proofing-requirement",
            status: false,
            remarks: ""
         },
         {
            name: "Static protection –Generation of static",
            name_slug: "static-protection-–generation-of-static",
            status: false,
            remarks: ""
         },
         {
            name: "Areas in which flammable or combustible substances may be present, including from leaks (hazardous area classification)",
            name_slug: "areas-in-which-flammable-or-combustible-substances-may-be-present,-including-from-leaks-(hazardous-area-classification)",
            status: false,
            remarks: ""
         },
         {
            name: "Areas in which combustible dusts or flyings may be present  (hazardous area classification)",
            name_slug: "areas-in-which-combustible-dusts-or-flyings-may-be-present--(hazardous-area-classification)",
            status: false,
            remarks: ""
         },
         {
            name: "Electrical equipment in Hazardous Areas",
            name_slug: "electrical-equipment-in-hazardous-areas",
            status: false,
            remarks: ""
         },
         {
            name: "Proximity to water",
            name_slug: "proximity-to-water",
            status: false,
            remarks: ""
         }
      ]
   },
   {
      header: {
         name: "Energy Design Review:",
         name_slug: "energy-design-review:"
      },
      rows: [
         {
            name: "Select duty point at Best Efficiency Point",
            name_slug: "select-duty-point-at-best-efficiency-point",
            status: false,
            remarks: ""
         },
         {
            name: "Selection should be based on Life Cycle Concept",
            name_slug: "selection-should-be-based-on-life-cycle-concept",
            status: false,
            remarks: ""
         },
         {
            name: "For multy pump operation consider VFD for 1 Pump with Pressure control",
            name_slug: "for-multy-pump-operation-consider-vfd-for-1-pump-with-pressure-control",
            status: false,
            remarks: ""
         },
         {
            name: "Consider VFD for Batch / Multi utility load pumps and for blowers",
            name_slug: "consider-vfd-for-batch-/-multi-utility-load-pumps-and-for-blowers",
            status: false,
            remarks: ""
         },
         {
            name: "Install Flow meter in 'header' line for Process application",
            name_slug: "install-flow-meter-in-'header'-line-for-process-application",
            status: false,
            remarks: ""
         },
         {
            name: "Consider Mechanical Seal",
            name_slug: "consider-mechanical-seal",
            status: false,
            remarks: ""
         },
         {
            name: "Install Pressure Monitoring point at after discharge valve",
            name_slug: "install-pressure-monitoring-point-at-after-discharge-valve",
            status: false,
            remarks: ""
         },
         {
            name: "All motors to be VFD compatible",
            name_slug: "all-motors-to-be-vfd-compatible",
            status: false,
            remarks: ""
         },
         {
            name: "Energy Meter with communication facility to be provided for 50 HP and above motors",
            name_slug: "energy-meter-with-communication-facility-to-be-provided-for-50-hp-and-above-motors",
            status: false,
            remarks: ""
         },
         {
            name: "Consider Energy Efficient Class IE2/ IE-3 Motors or Higher Class",
            name_slug: "consider-energy-efficient-class-ie2/-ie-3-motors-or-higher-class",
            status: false,
            remarks: ""
         },
         {
            name: "Capacitor banks to be placed in all MCC's to maintain PF 0.99",
            name_slug: "capacitor-banks-to-be-placed-in-all-mcc's-to-maintain-pf-0.99",
            status: false,
            remarks: ""
         },
         {
            name: "Reactors with Planetary Gear Box",
            name_slug: "reactors-with-planetary-gear-box",
            status: false,
            remarks: ""
         },
         {
            name: "LED Lighting for vessels, building, plant & street lighting",
            name_slug: "led-lighting-for-vessels,-building,-plant-&-street-lighting",
            status: false,
            remarks: ""
         },
         {
            name: "Star Delta Converter is required. As applicable.",
            name_slug: "star-delta-converter-is-required.-as-applicable.",
            status: false,
            remarks: ""
         },
         {
            name: "VFD's to be procured with built-in Harmonic filters and Input output chocks",
            name_slug: "vfd's-to-be-procured-with-built-in-harmonic-filters-and-input-output-chocks",
            status: false,
            remarks: ""
         },
         {
            name: "Cooling water as utility for all HE's which is having process temperature more than 40 oC",
            name_slug: "cooling-water-as-utility-for-all-he's-which-is-having-process-temperature-more-than-40-oc",
            status: false,
            remarks: ""
         },
         {
            name: "Chilled water as utility for all HE's which is having process temperature in between 15 to 40 oC",
            name_slug: "chilled-water-as-utility-for-all-he's-which-is-having-process-temperature-in-between-15-to-40-oc",
            status: false,
            remarks: ""
         },
         {
            name: "Recuperates / Regenerators / Heat Pipes for Waste Heat Recovery",
            name_slug: "recuperates-/-regenerators-/-heat-pipes-for-waste-heat-recovery",
            status: false,
            remarks: ""
         },
         {
            name: "Natural day light harvesting",
            name_slug: "natural-day-light-harvesting",
            status: false,
            remarks: ""
         },
         {
            name: "Eco ventilators",
            name_slug: "eco-ventilators",
            status: false,
            remarks: ""
         },
         {
            name: "PV Solar for Buildings, Canteen, Godowns, etc.",
            name_slug: "pv-solar-for-buildings,-canteen,-godowns,-etc.",
            status: false,
            remarks: ""
         },
         {
            name: "Submit PFD for process to Electrical Department",
            name_slug: "submit-pfd-for-process-to-electrical-department",
            status: false,
            remarks: ""
         },
         {
            name: "Select duty point at Best Efficiency Point",
            name_slug: "select-duty-point-at-best-efficiency-point",
            status: false,
            remarks: ""
         }
      ]
   },
   {
      header: {
         name: "Underground/Overhead:",
         name_slug: "underground/overhead:"
      },
      rows: [
         {
            name: "Service",
            name_slug: "service",
            status: false,
            remarks: ""
         },
         {
            name: "Equipment",
            name_slug: "equipment",
            status: false,
            remarks: ""
         }
      ]
   },
   {
      header: {
         name: "Material Hazards:",
         name_slug: "material-hazards:"
      },
      rows: [
         {
            name: "Localised fumes/dust",
            name_slug: "localised-fumes/dust",
            status: false,
            remarks: ""
         },
         {
            name: "Ventilation discharges to  atmosphere",
            name_slug: "ventilation-discharges-to--atmosphere",
            status: false,
            remarks: ""
         },
         {
            name: "Surface temperatures",
            name_slug: "surface-temperatures",
            status: false,
            remarks: ""
         },
         {
            name: "Noise >85 db",
            name_slug: "noise->85-db",
            status: false,
            remarks: ""
         },
         {
            name: "Cleaning solution requirements",
            name_slug: "cleaning-solution-requirements",
            status: false,
            remarks: ""
         },
         {
            name: "Waste management options investigated",
            name_slug: "waste-management-options-investigated",
            status: false,
            remarks: ""
         },
         {
            name: "Requirements for bunding, secondary containment or surface sealing ?",
            name_slug: "requirements-for-bunding,-secondary-containment-or-surface-sealing-?",
            status: false,
            remarks: ""
         },
         {
            name: "Inventories of Dangerous Goods in buildings / bunded areas",
            name_slug: "inventories-of-dangerous-goods-in-buildings-/-bunded-areas",
            status: false,
            remarks: ""
         },
         {
            name: "Storage or handling of incompatible materials (including wastes)",
            name_slug: "storage-or-handling-of-incompatible-materials-(including-wastes)",
            status: false,
            remarks: ""
         },
         {
            name: "The location or condition of Asbestos or Ceramic Mineral Fibres",
            name_slug: "the-location-or-condition-of-asbestos-or-ceramic-mineral-fibres",
            status: false,
            remarks: ""
         }
      ]
   },
   {
      header: {
         name: "Additional Checks",
         name_slug: "additional-checks"
      },
      rows: [
         {
            name: "The job functions performed by individuals ?",
            name_slug: "the-job-functions-performed-by-individuals-?",
            status: false,
            remarks: ""
         },
         {
            name: "The number of people required to safely perform tasks and conduct safety inspections, plant logs, etc. ?",
            name_slug: "the-number-of-people-required-to-safely-perform-tasks-and-conduct-safety-inspections,-plant-logs,-etc.-?",
            status: false,
            remarks: ""
         },
         {
            name: "The level and availability of supervisory and other resources to verify satisfactory conduct of critical activities ?",
            name_slug: "the-level-and-availability-of-supervisory-and-other-resources-to-verify-satisfactory-conduct-of-critical-activities-?",
            status: false,
            remarks: ""
         },
         {
            name: "Access to technical advice (including proposals for removal or re-location of positions) ?",
            name_slug: "access-to-technical-advice-(including-proposals-for-removal-or-re-location-of-positions)-?",
            status: false,
            remarks: ""
         },
         {
            name: "Access to technical and other resources required to respond to abnormal situations & emergencies ?",
            name_slug: "access-to-technical-and-other-resources-required-to-respond-to-abnormal-situations-&-emergencies-?",
            status: false,
            remarks: ""
         },
         {
            name: "Access to people required to authorise or review critical activities ?",
            name_slug: "access-to-people-required-to-authorise-or-review-critical-activities-?",
            status: false,
            remarks: ""
         },
         {
            name: "Work hours and shift arrangements ?",
            name_slug: "work-hours-and-shift-arrangements-?",
            status: false,
            remarks: ""
         },
         {
            name: "Physical, emotional or intellectual work demands",
            name_slug: "physical,-emotional-or-intellectual-work-demands",
            status: false,
            remarks: ""
         },
         {
            name: "Likelihood of critical errors (eg  decision complexity, time to respond, access to information, 'error proofing' of controls, etc) ?",
            name_slug: "likelihood-of-critical-errors-(eg--decision-complexity,-time-to-respond,-access-to-information,-'error-proofing'-of-controls,-etc)-?",
            status: false,
            remarks: ""
         },
         {
            name: "Likelihood of violation of procedures & accepted practices ? (eg  increased work pressures, reduced supervision, complexity of required procedures, etc) ?",
            name_slug: "likelihood-of-violation-of-procedures-&-accepted-practices-?-(eg--increased-work-pressures,-reduced-supervision,-complexity-of-required-procedures,-etc)-?",
            status: false,
            remarks: ""
         },
         {
            name: "The competencies required to safely perform tasks ?",
            name_slug: "the-competencies-required-to-safely-perform-tasks-?",
            status: false,
            remarks: ""
         },
         {
            name: "Selection criteria for new recruits and/or transfers ?",
            name_slug: "selection-criteria-for-new-recruits-and/or-transfers-?",
            status: false,
            remarks: ""
         },
         {
            name: "Communication between individuals (eg shift handover), workgroups and/or Facilities ?",
            name_slug: "communication-between-individuals-(eg-shift-handover),-workgroups-and/or-facilities-?",
            status: false,
            remarks: ""
         },
         {
            name: "Vertical communication between individuals and management ?",
            name_slug: "vertical-communication-between-individuals-and-management-?",
            status: false,
            remarks: ""
         },
         {
            name: "The rate of staff turnover or retention of critical staff (eg re-location or re-organisation of a facility) ?",
            name_slug: "the-rate-of-staff-turnover-or-retention-of-critical-staff-(eg-re-location-or-re-organisation-of-a-facility)-?",
            status: false,
            remarks: ""
         },
         {
            name: "Industrial relations, workplace agreements, etc ?",
            name_slug: "industrial-relations,-workplace-agreements,-etc-?",
            status: false,
            remarks: ""
         },
         {
            name: "Workplace moral ?",
            name_slug: "workplace-moral-?",
            status: false,
            remarks: ""
         },
         {
            name: "Exposure to personal security threats ?",
            name_slug: "exposure-to-personal-security-threats-?",
            status: false,
            remarks: ""
         }
      ]
   }
   ]
   // return JSON.parse(JSON.stringify(AssessmentRowNames))
   return AssessmentRowNames
}

function getItemRowNames() {
   var ItemRowNames = [
   {
      header: {
         name: "Registers",
         name_slug: "registers"
      },
      rows: [
         {
            name: "Maintenance Register (MEX)",
            name_slug: "maintenance-register-(mex)",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "Pressure Vessels Register",
            name_slug: "pressure-vessels-register",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "Static Earth Register",
            name_slug: "static-earth-register",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "Lifting Equipment Register",
            name_slug: "lifting-equipment-register",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "PSV Register",
            name_slug: "psv-register",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "Machine Guards Register",
            name_slug: "machine-guards-register",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "Hose Register",
            name_slug: "hose-register",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "Confined Space Register",
            name_slug: "confined-space-register",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "Hazardous Area Dossier",
            name_slug: "hazardous-area-dossier",
            status: false,
            date_of_complete: "",
            remarks: ""
         }
      ]
   },
   {
      header: {
         name: "Chemical Documentation",
         name_slug: "chemical-documentation"
      },
      rows: [
         {
            name: "Hazardous Materials/DG Manifest",
            name_slug: "hazardous-materials/dg-manifest",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "MSDS catalogue",
            name_slug: "msds-catalogue",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "Waste Emissions Register",
            name_slug: "waste-emissions-register",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "Other",
            name_slug: "other",
            status: false,
            date_of_complete: "",
            remarks: ""
         }
      ]
   },
   {
      header: {
         name: "Plant documents",
         name_slug: "plant-documents"
      },
      rows: [
         {
            name: "Drawings",
            name_slug: "drawings",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "Site plan/layout",
            name_slug: "site-plan/layout",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "P&ID",
            name_slug: "p&id",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "Drawing/Equip/Inst Numbering",
            name_slug: "drawing/equip/inst-numbering",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "Equipment Drawings",
            name_slug: "equipment-drawings",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "Electrical schematic/SLD",
            name_slug: "electrical-schematic/sld",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "Logic Diagrams",
            name_slug: "logic-diagrams",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "Hazardous Area Drawing",
            name_slug: "hazardous-area-drawing",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "PLC Functional Description",
            name_slug: "plc-functional-description",
            status: false,
            date_of_complete: "",
            remarks: ""
         }
      ]
   },
   {
      header: {
         name: "Maintenance / Certifications",
         name_slug: "maintenance-/-certifications"
      },
      rows: [
         {
            name: "Equipment Files",
            name_slug: "equipment-files",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "Test Certificates",
            name_slug: "test-certificates",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "Vendor Manual",
            name_slug: "vendor-manual",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "Statutory Approvals",
            name_slug: "statutory-approvals",
            status: false,
            date_of_complete: "",
            remarks: ""
         }
      ]
   },
   {
      header: {
         name: "Site Systems/Procedures",
         name_slug: "site-systems/procedures"
      },
      rows: [
         {
            name: "Basis of Safety",
            name_slug: "basis-of-safety",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "Occupational Health & Environment Statement",
            name_slug: "occupational-health-&-environment-statement",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "Safe Operating Procedure (e.g. SOP, WI or PPM)",
            name_slug: "safe-operating-procedure-(e.g.-sop,-wi-or-ppm)",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "Confined Space Entry Data Sheet",
            name_slug: "confined-space-entry-data-sheet",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "Emergency Plans",
            name_slug: "emergency-plans",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: " Risk Assessments",
            name_slug: "-risk-assessments",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "Other",
            name_slug: "other",
            status: false,
            date_of_complete: "",
            remarks: ""
         }
      ]
   },
   {
      header: {
         name: "Training Needs",
         name_slug: "training-needs"
      },
      rows: [
         {
            name: "Operator",
            name_slug: "operator",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "Maintenance",
            name_slug: "maintenance",
            status: false,
            date_of_complete: "",
            remarks: ""
         },
         {
            name: "Lab",
            name_slug: "lab",
            status: false,
            date_of_complete: "",
            remarks: ""
         }
      ]
   }
   ]
   // return JSON.parse(JSON.stringify(ItemRowNames))
   return ItemRowNames
}

export const Reviews = [
   ""
]


function getExecutorGeneralChecksData() {
   var ExecutorGeneralChecksData = [
   {
      'name': 'A copy of the approved Modification Form, with all attachments reviewed and the modified equipment inspected',
      'name_slug': 'a-copy-of-the-approved-modification-form-with-all-attachments-reviewed-and-the-modified-equipment-inspected',
      'status': ''
   },
   {
      'name': 'Is the implementation in accordance with the approved proposal?',
      'name_slug': 'is-the-implementation-in-accordance-with-the-approved-proposal',
      'status': ''
   },
   {
      'name': 'Have ergonomics and manual handling issues been adequately addressed?',
      'name_slug': 'have-ergonomics-and-manual-handling-issues-been-adequately-addressed',
      'status': ''
   },
   {
      'name': 'Are there potential, previously overlooked, hazard or operability problems?',
      'name_slug': 'are-there-potential-previously-overlooked-hazard-or-operability-problems',
      'status': ''
   },
   {
      'name': 'Are fire risks acceptable?',
      'name_slug': 'are-fire-risks-acceptable',
      'status': ''
   },
   {
      'name': 'Has the modified equipment been tested as required? (Including completion of electrical "Ready for Test" Certificate).',
      'name_slug': 'has-the-modified-equipment-been-tested-as-required-including-completion-of-electrical-ready-for-test-certificate',
      'status': ''
   },
   {
      'name': 'Has all relevant Plant documentation, operating instructions, etc, been updated?',
      'name_slug': 'has-all-relevant-plant-documentation-operating-instructions-etc-been-updated',
      'status': ''
   },
   {
      'name': 'Has electrical test and tag been checked?',
      'name_slug': 'has-electrical-test-and-tag-been-checked',
      'status': ''
   },
   {
      'name': 'Has a check for sharp edges been conducted?',
      'name_slug': 'has-a-check-for-sharp-edges-been-conducted',
      'status': ''
   },
   {
      'name': 'Has all modified/new equipment, instruments and piping been labeled?',
      'name_slug': 'has-all-modified-new-equipment-instruments-and-piping-been-labeled',
      'status': ''
   },
   {
      'name': 'Has a note with information about the modification been issued on the Daily Bulletin or to Production and other affected personnel?',
      'name_slug': 'has-a-note-with-information-about-the-modification-been-issued-on-the-daily-bulletin-or-to-production-and-other-affected-personnel',
      'status': ''
   },
   {
      'name': 'Has sufficient training of Production been completed?',
      'name_slug': 'has-sufficient-training-of-production-been-completed',
      'status': ''
   },
   {
      'name': 'Have the requirements of relevant regulations, codes, etc been met?',
      'name_slug': 'have-the-requirements-of-relevant-regulations-codes-etc-been-met',
      'status': ''
   },
   {
      'name': 'Is there adequate first aid and fire protection readily available?',
      'name_slug': 'is-there-adequate-first-aid-and-fire-protection-readily-available',
      'status': ''
   },
   {
      'name': 'Is the modification, in its present state, approved for commissioning?',
      'name_slug': 'is-the-modification-in-its-present-state-approved-for-commissioning',
      'status': ''
   },
   {
      'name': 'Are there any actions outstanding from Hazard Studies? Refer DuluxGroup SHE Risk Register',
      'name_slug': 'are-there-any-actions-outstanding-from-hazard-studies-refer-duluxgroup-she-risk-register',
      'status': ''
   },
   {
      'name': 'Have all new equipment details been loaded to Mex as per Mex Loading Sheet?',
      'name_slug': 'have-all-new-equipment-details-been-loaded-to-mex-as-per-mex-loading-sheet',
      'status': ''
   }
   ]
   // return JSON.parse(JSON.stringify(ExecutorGeneralChecksData))
   return ExecutorGeneralChecksData
}


function getChangeAuthorityName() {
   var ChangeAuthorityName = [
   {
      header: "GENERAL",
      rows: [
         "Safety Acceptance Check actions completed",
         "Operating instructions training material updated",
         "Position Descriptions and organisation chart updated",
         "Regulatory Authorities notified (where applicable",
         "Planning and Building Permit conditions met",
         "Dangerous Goods, Poisons, Environmental waste and other licenses updated and met",
         "SH&E Hazard Register, Basis of Safety and Safety Case (as applicable) updated ",
         "Occ. Health and Environmental statements updated ",
         "Changes to Emergency & Fire Risk Plans completed",
         "Performance of the new arrangements assessed and accepted",
         "New arrangements comply with the SH&E model procedures and local requirements",
         "Controls introduced to manage any unforeseen hazards",
         "The intentions of the Modification proposal have been met",
      ]
   },
   ]
   return ChangeAuthorityName
}

function getPlantAndEquipmentName() {
   var PlantAndEquipmentName = [
   {
      header: "GENERAL",
      rows: [
         "Any new / changed equipment registered and asset register updated",
         "Electrical certificate of compliance obtained and filed ( for all new or significant changes to existing low or extra low voltage equipment )",
         "Plant risk assessments obtained from suppliers / designers and reflected in operating instructions, etc (refer below)",
         "Spare parts list/stock level/stores records updated",
         "Hazards adequately guarded / protected",
         "Adequate access provided",
         "Tools provided adequate and functional",
         "Plant documentation and the Plant Dossier updated (eg P&ID's, electrical drawings, datasheets, etc - refer MP-SG-010)",
      ]
   },
   ]
   return PlantAndEquipmentName
}


function getProcessAndOperatingConditionName() {
   var ProcessAndOperatingConditionName = [
   {
      header: "",
      rows: [
         "Systems operating within design limits / loads",
         "Process temperature / pressure / flows etc. as specified",
         "Noise / plant emissions within specified limits",
         "Services and upstream / downstream processes adequate",
         "Instrumentation adequate",
         "Scheduled inspection, testing and audit processes & routines updated & performed",
         "Planned and unplanned maintenance arrangements in place and effective",
         "Software backup's made and effective",
         "Exposure to workplace stressors (refer MP-OH-020) is below exposure standards",
         "Safe manual handling and ergonomic practices adopted",
         "First aid and emergency response measures adequate"
      ]
   },
   ]
   return ProcessAndOperatingConditionName
}


function getMaterialHazardName() {
   var MaterialHazardName = [
   {
      header: "",
      rows: [
         "MSDS prepared, available and issued to contractors / customers",
         "Dangerous Goods and Hazardous Substances correctly packaged, labeled, stored & handled",
         "Biological controls in place and acceptable bacteriological levels in cooling towers, air/water handling systems, etc",
         "Radiation levels below specified limits",
         "Ventilation systems adequately capture contaminants and/or are operating as specified",
         "Specified PPE being used and is effective",
         "Ignition sources in new/changed Hazardous Areas are adequately controlled ",
         "Discharges from emission points within design limits",
         "Waste materials correctly collected, labeled, treated and/or disposed of",
      ]
   },
   ]
   return MaterialHazardName
}

function getOrganizationalIssuesName() {
   var OrganizationalIssuesName = [
   {
      header: "",
      rows: [
         "Adequate resources available to perform and supervise critical functions",
         "Adequate access to resources for technical advice and authorisation of critical activities (including during emergencies)",
         "Sufficient freedom to act in emergency situations",
         "Adequate retention of critical staff",
         "People performing critical functions have required competencies",
         "Risk of / from critical human errors is acceptable",
         "Likelihood / consequence from violation of critical procedures is acceptable",
         "Adequate communication between individuals, workgroups and/or Facilities",
         "Sufficient vertical communication between individuals and management ?",
         "Hours of work and shift rosters meet company requirements",
         "Acceptable exposure to personal security threats"
      ]
   },
   ]
   return OrganizationalIssuesName
}

function getEnergyManagementActionPlanTabelRowName() {
   var EnergyManagementActionPlanTabelRowName = {
   'rows': [
      {
         'name': 'Objective',
         'name_slug': 'objective',
         'action_by': '',
         'target_date': '',
         'completion_date': ''
      },
      {
         'name': 'Present Status',
         'name_slug': 'present-status',
         'action_by': '',
         'target_date': '',
         'completion_date': ''
      },
      {
         'name': 'Targeted Status',
         'name_slug': 'targeted-status',
         'action_by': '',
         'target_date': '',
         'completion_date': ''
      },
      {
         'name': 'Potential Energy Saving',
         'name_slug': 'potential-energy-saving',
         'action_by': '',
         'target_date': '',
         'completion_date': ''
      },
      {
         'name': 'Detailed Action Plan:',
         'name_slug': 'detailed-action-plan',
         'action_by': '',
         'target_date': '',
         'completion_date': ''
      },
      {
         'name': 'Expected improvement from base level/Benefits',
         'name_slug': 'expected-improvement-from-base-level-benefits',
         'action_by': '',
         'target_date': '',
         'completion_date': ''
      },
      {
         'name': 'Overall responsibility for implementation',
         'name_slug': 'overall-responsibility-for-implementation',
         'action_by': '',
         'target_date': '',
         'completion_date': ''
      },
      {
         'name': 'Resources / Funds Requirements',
         'name_slug': 'resources-funds-requirements',
         'action_by': '',
         'target_date': '',
         'completion_date': ''
      },
      {
         'name': 'Pay Back Period',
         'name_slug': 'pay-back-period',
         'action_by': '',
         'target_date': '',
         'completion_date': ''
      },
      {
         'name': 'Training requirement if any',
         'name_slug': 'training-requirement-if-any',
         'action_by': '',
         'target_date': '',
         'completion_date': ''
      },
      {
         'name': 'Actual Status after Action Plan',
         'name_slug': 'actual-status-after-action-plan',
         'action_by': '',
         'target_date': '',
         'completion_date': ''
      },
      {
         'name': 'Actual Energy Saving',
         'name_slug': 'actual-energy-saving',
         'action_by': '',
         'target_date': '',
         'completion_date': ''
      },
      {
         'name': 'Achieved/ Not Achieved',
         'name_slug': 'achieved-not-achieved',
         'action_by': '',
         'target_date': '',
         'completion_date': ''
      },
      {
         'name': 'Corrective Action(If Not achieved)',
         'name_slug': 'corrective-action-if-not-achieved',
         'action_by': '',
         'target_date': '',
         'completion_date': ''
      }
   ]
   }
   return EnergyManagementActionPlanTabelRowName
}


function getCategoryofRiskLevelBySiteEHSHeadTabelRowName() {
   var  CategoryofRiskLevelBySiteEHSHeadTabelRowName = 
   {
      rows: [
         {name:"Trivial",name_slug:"trivial",legal_compliance:false,list_down:"",plan_compliance:"", is_checked:false},
         {name:"Moderate",name_slug:"moderate",legal_compliance:false,list_down:"",plan_compliance:"",  is_checked:false},
         {name:"High & Above",name_slug:"high-above",legal_compliance:false,list_down:"",plan_compliance:"", is_checked:false}

      ]
   }
   return CategoryofRiskLevelBySiteEHSHeadTabelRowName
}

function getManpowerChangeRequirement() {
   var  ManpowerChangeRequirement = [
   {
      name: 'Induction training including EHS and test',
      name_slug: 'induction-training-including-ehs-and-test',
      type: 'New',
      rowAction:{status:false,buttonName:"No"},
      responsibility:"",
      target_date:"",
      rowStatus:{status:false,buttonName:'Open'}
   
   },
   {
      name: 'Behavior Safety commitment',
      name_slug: 'behavior-safety-commitment',
      type: 'New',
      rowAction:{status:false,buttonName:"No"},
      responsibility:"",
      target_date:"",
      rowStatus:{status:false,buttonName:'Open'}
   
   },
{
   name: 'Information about chemical handled-MSDS',
   name_slug: 'information-about-chemical-handled-msds',
   type: 'Existing',
   rowAction:{status:false,buttonName:"No"},
   responsibility:"",
   target_date:"",
   rowStatus:{status:false,buttonName:'Open'}
},
{
   name: 'Unit Process of Plant : Reaction Chemistry, Process Conditions',
   name_slug: 'unit-process-of-plant-:-reaction-chemistry,-process-conditions',
   type: 'Existing',
   rowAction:{status:false,buttonName:"No"},
   responsibility:"",
   target_date:"",
   rowStatus:{status:false,buttonName:'Open'}
},
{
   name: 'HIRA of activities to be handled',
   name_slug: 'hira-of-activities-to-be-handled',
   type: 'Existing',
   rowAction:{status:false,buttonName:"No"},
   responsibility:"",
   target_date:"",
   rowStatus:{status:false,buttonName:'Open'}
},
{
   name: 'SOP of Process/Unit operations',
   name_slug: 'sop-of-process/unit-operations',
   type: 'Existing',
   rowAction:{status:false,buttonName:"No"},
   responsibility:"",
   target_date:"",
   rowStatus:{status:false,buttonName:'Open'}
},
{
   name: 'Trouble Shooting',
   name_slug: 'trouble-shooting',
   type: 'Existing',
   rowAction:{status:false,buttonName:"No"},
   responsibility:"",
   target_date:"",
   rowStatus:{status:false,buttonName:'Open'}
},
{
   name: 'Safe Startup & Shut Down',
   name_slug: 'safe-startup-&-shut-down',
   type: 'Existing',
   rowAction:{status:false,buttonName:"No"},
   responsibility:"",
   target_date:"",
   rowStatus:{status:false,buttonName:'Open'}
},
{
   name: 'Maintenance practices',
   name_slug: 'maintenance-practices',
   type: 'Existing',
   rowAction:{status:false,buttonName:"No"},
   responsibility:"",
   target_date:"",
   rowStatus:{status:false,buttonName:'Open'}
},
{
   name: 'Function related training',
   name_slug: 'function-related-training',
   type: 'Existing',
   rowAction:{status:false,buttonName:"No"},
   responsibility:"",
   target_date:"",
   rowStatus:{status:false,buttonName:'Open'}
},
{
   name: 'Emergency Response Plan',
   name_slug: 'emergency-response-plan',
   type: 'Existing',
   rowAction:{status:false,buttonName:"No"},
   responsibility:"",
   target_date:"",
   rowStatus:{status:false,buttonName:'Open'}
},
{
    name: 'Any other', 
    name_slug: 'any-other', 
    type: 'Existing',
    rowAction:{status:false,buttonName:"No"},
    responsibility:"",
    target_date:"",
    rowStatus:{status:false,buttonName:'Open'}
  
},

   ]
   return ManpowerChangeRequirement
}

function getChangeAuthorityStepsData() {
   var  ChangeAuthorityStepsData = [
   {
      'name': 'General Checks',
      'name_slug': 'general-checks',
      'rows': [{
            'name': 'Safety Acceptance Check actions completed',
            'name_slug': 'safety-acceptance-check-actions-completed',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Operating instructions training material updated',
            'name_slug': 'operating-instructions-training-material-updated',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Position Descriptions and organisation chart updated',
            'name_slug': 'position-descriptions-and-organisation-chart-updated',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Regulatory Authorities notified (where applicable',
            'name_slug': 'regulatory-authorities-notified-where-applicable',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Planning and Building Permit conditions met',
            'name_slug': 'planning-and-building-permit-conditions-met',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Dangerous Goods, Poisons, Environmental waste and other licenses updated and met',
            'name_slug': 'dangerous-goods-poisons-environmental-waste-and-other-licenses-updated-and-met',
            'status': '',
            'comment': ''
         },
         {
            'name': 'SH&E Hazard Register, Basis of Safety and Safety Case (as applicable) updated ',
            'name_slug': 'sh-e-hazard-register-basis-of-safety-and-safety-case-as-applicable-updated',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Occ. Health and Environmental statements updated ',
            'name_slug': 'occ-health-and-environmental-statements-updated',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Changes to Emergency & Fire Risk Plans completed',
            'name_slug': 'changes-to-emergency-fire-risk-plans-completed',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Performance of the new arrangements assessed and accepted',
            'name_slug': 'performance-of-the-new-arrangements-assessed-and-accepted',
            'status': '',
            'comment': ''
         },
         {
            'name': 'New arrangements comply with the SH&E model procedures and local requirements',
            'name_slug': 'new-arrangements-comply-with-the-sh-e-model-procedures-and-local-requirements',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Controls introduced to manage any unforeseen hazards',
            'name_slug': 'controls-introduced-to-manage-any-unforeseen-hazards',
            'status': '',
            'comment': ''
         },
         {
            'name': 'The intentions of the Modification proposal have been met',
            'name_slug': 'the-intentions-of-the-modification-proposal-have-been-met',
            'status': '',
            'comment': ''
         }
      ]
   },
   {
      'name': 'Process and Operating Conditions',
      'name_slug': 'process-and-operating-conditions',
      'rows': [{
            'name': 'Systems operating within design limits / loads',
            'name_slug': 'systems-operating-within-design-limits-loads',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Process temperature / pressure / flows etc. as specified',
            'name_slug': 'process-temperature-pressure-flows-etc-as-specified',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Noise / plant emissions within specified limits',
            'name_slug': 'noise-plant-emissions-within-specified-limits',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Services and upstream / downstream processes adequate',
            'name_slug': 'services-and-upstream-downstream-processes-adequate',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Instrumentation adequate',
            'name_slug': 'instrumentation-adequate',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Scheduled inspection, testing and audit processes & routines updated & performed',
            'name_slug': 'scheduled-inspection-testing-and-audit-processes-routines-updated-performed',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Planned and unplanned maintenance arrangements in place and effective',
            'name_slug': 'planned-and-unplanned-maintenance-arrangements-in-place-and-effective',
            'status': '',
            'comment': ''
         },
         {
            'name': "Software backup's made and effective",
            'name_slug': 'software-backup-s-made-and-effective',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Exposure to workplace stressors (refer MP-OH-020) is below exposure standards',
            'name_slug': 'exposure-to-workplace-stressors-refer-mp-oh-020-is-below-exposure-standards',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Safe manual handling and ergonomic practices adopted',
            'name_slug': 'safe-manual-handling-and-ergonomic-practices-adopted',
            'status': '',
            'comment': ''
         },
         {
            'name': 'First aid and emergency response measures adequate',
            'name_slug': 'first-aid-and-emergency-response-measures-adequate',
            'status': '',
            'comment': ''
         }
      ]
   },
   {
      'name': 'Material Hazards',
      'name_slug': 'material-hazards',
      'rows': [{
            'name': 'MSDS prepared, available and issued to contractors / customers',
            'name_slug': 'msds-prepared-available-and-issued-to-contractors-customers',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Dangerous Goods and Hazardous Substances correctly packaged, labeled, stored & handled',
            'name_slug': 'dangerous-goods-and-hazardous-substances-correctly-packaged-labeled-stored-handled',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Biological controls in place and acceptable bacteriological levels in cooling towers, air/water handling systems, etc',
            'name_slug': 'biological-controls-in-place-and-acceptable-bacteriological-levels-in-cooling-towers-air-water-handling-systems-etc',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Radiation levels below specified limits',
            'name_slug': 'radiation-levels-below-specified-limits',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Ventilation systems adequately capture contaminants and/or are operating as specified',
            'name_slug': 'ventilation-systems-adequately-capture-contaminants-and-or-are-operating-as-specified',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Specified PPE being used and is effective',
            'name_slug': 'specified-ppe-being-used-and-is-effective',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Ignition sources in new/changed Hazardous Areas are adequately controlled ',
            'name_slug': 'ignition-sources-in-new-changed-hazardous-areas-are-adequately-controlled',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Discharges from emission points within design limits',
            'name_slug': 'discharges-from-emission-points-within-design-limits',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Waste materials correctly collected, labeled, treated and/or disposed of',
            'name_slug': 'waste-materials-correctly-collected-labeled-treated-and-or-disposed-of',
            'status': '',
            'comment': ''
         }
      ]
   },
   {
      'name': 'Organizational Issues',
      'name_slug': 'organizational-issues',
      'rows': [{
            'name': 'Adequate resources available to perform and supervise critical functions',
            'name_slug': 'adequate-resources-available-to-perform-and-supervise-critical-functions',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Adequate access to resources for technical advice and authorisation of critical activities (including during emergencies)',
            'name_slug': 'adequate-access-to-resources-for-technical-advice-and-authorisation-of-critical-activities-including-during-emergencies',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Sufficient freedom to act in emergency situations',
            'name_slug': 'sufficient-freedom-to-act-in-emergency-situations',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Adequate retention of critical staff',
            'name_slug': 'adequate-retention-of-critical-staff',
            'status': '',
            'comment': ''
         },
         {
            'name': 'People performing critical functions have required competencies',
            'name_slug': 'people-performing-critical-functions-have-required-competencies',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Risk of / from critical human errors is acceptable',
            'name_slug': 'risk-of-from-critical-human-errors-is-acceptable',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Likelihood / consequence from violation of critical procedures is acceptable',
            'name_slug': 'likelihood-consequence-from-violation-of-critical-procedures-is-acceptable',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Adequate communication between individuals, workgroups and/or Facilities',
            'name_slug': 'adequate-communication-between-individuals-workgroups-and-or-facilities',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Sufficient vertical communication between individuals and management ?',
            'name_slug': 'sufficient-vertical-communication-between-individuals-and-management',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Hours of work and shift rosters meet company requirements',
            'name_slug': 'hours-of-work-and-shift-rosters-meet-company-requirements',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Acceptable exposure to personal security threats',
            'name_slug': 'acceptable-exposure-to-personal-security-threats',
            'status': '',
            'comment': ''
         }
      ]
   },
   {
      'name': 'Plant and Equipment',
      'name_slug': 'plant-and-equipment',
      'rows': [{
            'name': 'Any new / changed equipment registered and asset register updated',
            'name_slug': 'any-new-changed-equipment-registered-and-asset-register-updated',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Electrical certificate of compliance obtained and filed ( for all new or significant changes to existing low or extra low voltage equipment )',
            'name_slug': 'electrical-certificate-of-compliance-obtained-and-filed-for-all-new-or-significant-changes-to-existing-low-or-extra-low-voltage-equipment',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Plant risk assessments obtained from suppliers / designers and reflected in operating instructions, etc (refer below)',
            'name_slug': 'plant-risk-assessments-obtained-from-suppliers-designers-and-reflected-in-operating-instructions-etc-refer-below',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Spare parts list/stock level/stores records updated',
            'name_slug': 'spare-parts-list-stock-level-stores-records-updated',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Hazards adequately guarded / protected',
            'name_slug': 'hazards-adequately-guarded-protected',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Adequate access provided',
            'name_slug': 'adequate-access-provided',
            'status': '',
            'comment': ''
         },
         {
            'name': 'Tools provided adequate and functional',
            'name_slug': 'tools-provided-adequate-and-functional',
            'status': '',
            'comment': ''
         },
         {
            'name': "Plant documentation and the Plant Dossier updated (eg P&ID's, electrical drawings, datasheets, etc - refer MP-SG-010)",
            'name_slug': 'plant-documentation-and-the-plant-dossier-updated-eg-p-id-s-electrical-drawings-datasheets-etc-refer-mp-sg-010',
            'status': '',
            'comment': ''
         }
      ]
   }
   ]
   return ChangeAuthorityStepsData
}





export {
   getAssessmentRowNames, getItemRowNames, getExecutorGeneralChecksData, getChangeAuthorityName,
   getPlantAndEquipmentName, getProcessAndOperatingConditionName, getMaterialHazardName, 
   getOrganizationalIssuesName, getEnergyManagementActionPlanTabelRowName,
   getCategoryofRiskLevelBySiteEHSHeadTabelRowName, getManpowerChangeRequirement,
   getChangeAuthorityStepsData
}