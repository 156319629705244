import React, { Component } from "react";
import Comment from "../../ConsentComponents/Comment";
import notify from '../../CommonComponents/Notify';
import $ from 'jquery'
import { API_HOST } from '../../../Settings.js'
import axios from 'axios';
import { MultiSelect } from "react-multi-select-component";

class ExecutionofApprovedMOC extends Component {
    constructor(props){
        super(props)
        this.state = {
            departments_data: [],
            stage_status:this.props.stage_status, 
            job_order_department: this.props.ExecutionData.job_order_department?this.props.ExecutionData.job_order_department:[],
            engg_department: this.props.ExecutionData.engg_department?this.props.ExecutionData.engg_department:[],
        }
    }
    componentDidMount(){
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/all/departments/data/`, )
        .then(response => {            
            if (response.status === 200) {
                this.setState({
                    departments_data:response.data.departments_data,
                    
                })
            }

        }).catch(response => {
            // ErrorHandling(response)
        });
        $('.card-loader').fadeOut('slow')
    }
    getConversionJobDepartment=(departments)=>{
        var output = departments.map((each_deparment, indx)=>{
            return { value: each_deparment.id, label: each_deparment.name}
        })
        return output
    }

    render() {

        let job_deparment_options = this.getConversionJobDepartment(this.state.departments_data)

        let engg_deparment_options = this.getConversionJobDepartment(this.state.departments_data)
        
        const customValueRendererJob = (selected, _options) => {
            var length = selected.length
          return selected.length
            ? selected.map(({ label }, indx) =>{
                    if(indx<length-1){
                        return label+", "
                    }else{
                        return label
                    }
            })
            : "Select Job Departments";
        };
        const customValueRendererEngg = (selected, _options) => {
            var length = selected.length
          return selected.length
            ? selected.map(({ label }, indx) =>{
                    if(indx<length-1){
                        return label+", "
                    }else{
                        return label
                    }
            })
            : "Select Engg Departments";
        };
        
        return(
            <div className="card mb-3" id="initiatorDiv">
                <div className="card-body">
                <h5 className="mb-3"><b>Initiator </b></h5>
                <div className="row mt-3 mb-3">                     
                    <div className='col-lg-6 col-md-6 col-12'>
                        <label className="form-label" htmlFor="plantarea">Select Department and Issue job order *</label>
                        <MultiSelect
                            id="job_order_department"
                            name="job_order_department"
                            options={job_deparment_options}
                            value={this.props.ExecutionData.job_order_department}
                            onChange={ (e) => this.props.handleOnSelectDepartment(e, "job_order_department")}
                            labelledBy="Select"
                            valueRenderer={customValueRendererJob}
                            disabled={this.props.DataSubmited && ["view"].includes(this.props.access_type) ? true : false}
                        />
                    </div>
                    <div className='col-lg-6 col-md-6 col-12'>
                        <label className="form-label" htmlFor="plantarea">Select Department Detailed Engg. Department *</label>
                        <MultiSelect
                            id="engg_department"
                            name="engg_department"
                            options={engg_deparment_options}
                            value={this.props.ExecutionData.engg_department}
                            onChange={ (e) => this.props.handleOnSelectDepartment(e, "engg_department")}
                            labelledBy="Select"
                            valueRenderer={customValueRendererEngg}
                            disabled={this.props.DataSubmited && ["view"].includes(this.props.access_type) ? true : false}
                        />
                    </div>    
                </div>
                <h5><b>Project Coordinator</b></h5>
                <div className="row mb-3">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="form-label" htmlFor="activities">Activities</label>
                        <textarea className='form-control' rows="3" name="activities" id="activities" type="text" defaultValue={this.props.ExecutionData.activities} onBlur={(e)=>this.props.handleOnChange(e)} readOnly={this.props.DataSubmited && ["view"].includes(this.props.access_type) ? true:false}></textarea>
                    </div>                      
                    <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                        <label className="form-label" htmlFor="review_detailed_engg_Work">Review Detailed Engg. Work (Drawing / P&ID & Layout)</label>
                        <textarea className='form-control' rows="3" name="review_detailed_engg_Work" id="review_detailed_engg_Work" type="text" defaultValue={this.props.ExecutionData.review_detailed_engg_Work} onBlur={(e)=>this.props.handleOnChange(e)} readOnly={this.props.DataSubmited && ["view"].includes(this.props.access_type) ? true:false}></textarea>
                    </div>
                </div> 
                <div className="row mb-3">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="form-label" htmlFor="review_hira_recommendations">Review HIRA/HAZOP  recommendations</label>
                        <textarea className='form-control' rows="3" name="review_hira_recommendations" id="review_hira_recommendations" type="text" defaultValue={this.props.ExecutionData.review_hira_recommendations} onBlur={(e)=>this.props.handleOnChange(e)} readOnly={this.props.DataSubmited && ["view"].includes(this.props.access_type) ? true:false}></textarea>
                     </div>                      
                    <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                        <label className="form-label" htmlFor="review_qra_recommendations">Review QRA recommendations</label>
                        <textarea className='form-control' rows="3" name="review_qra_recommendations" id="review_qra_recommendations" type="text" defaultValue={this.props.ExecutionData.review_qra_recommendations} onBlur={(e)=>this.props.handleOnChange(e)} readOnly={this.props.DataSubmited && ["view"].includes(this.props.access_type) ? true:false}></textarea>
                    </div>
                </div> 
                <h5><b>Job Executor</b></h5>
                <div className="row mb-3">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="form-label" htmlFor="execution_of_the_job">Execution of the job as per schedule - close job order</label>
                        <textarea className='form-control' rows="3" name="execution_of_the_job" id="execution_of_the_job" type="text" defaultValue={this.props.ExecutionData.execution_of_the_job} onBlur={(e)=>this.props.handleOnChange(e)} readOnly={this.props.DataSubmited && ["view"].includes(this.props.access_type) ? true:false}></textarea>
                     </div>   
                </div> 
                </div>
            </div>
        )
    }
}

export default ExecutionofApprovedMOC;