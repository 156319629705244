//  Common useful functions
import $ from 'jquery'

export function convertDate(date) {
    // 2023-03-27 to 27/03/2023
    var d = new Date(date)
    return `${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`
}

export function convertDate2(date) {
    // 27/03/2023 to 2023-03-27
    var words = date.split("/")
    return `${words[2]}-${words[1]}-${words[0]}`
}


export function getCommonMinDateItems(date_of_moc){
    var today_str = new Date().toISOString().split("T")[0]
    var min_date = date_of_moc //this.state.date_of_moc
    var common_min_date_items = today_str
    if(min_date){
        var min_date_obj = new Date(min_date)
        if(min_date_obj!='Invalid Date'){
            common_min_date_items = min_date_obj.toISOString().split('T')[0]
        }
    }
    return common_min_date_items
}