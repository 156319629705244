import React, { Component } from 'react';

class ViewComments extends Component {
    constructor(props){
        super(props)
        this.state = {
            unique_combine: this.props.unique_combine ? this.props.unique_combine : 'vc'
        }
    }
    render() {
        var comments = this.props.WorkflowComents && this.props.WorkflowComents.map((comment_data,indx)=>{
           return (<div className='mb-2 mt-20' key={this.state.unique_combine+"vc"+indx}>
                    <div className='text-black font-12 text-end mb-2'>{comment_data.created_date_formated}</div>
                    <div className='brio-form-control' key={this.state.unique_combine+'vc'+indx} id={this.state.unique_combine+'vcid'+indx} type="text" readOnly>
                        {comment_data.message}
                    </div>
                </div>)
        })
        return(
            <div>
                {comments}
            </div>
        )
    }
}

export default ViewComments;