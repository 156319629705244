import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import AssessmentTable from './AssessmentTable';
import Enclosures from './EnclosureComponent';
import BriefDescription from './BriefDescription';
import ChangeSought from './ChangeSought';
import TypeOfChange from './TypeOfChange';
import ItemTable from './ItemTable';
import Toggle from '../CommonComponents/Toggle';
import { getItemRowNames, getAssessmentRowNames } from '../CommonComponents/MiscellaneousObject';
import $ from 'jquery'
import { API_HOST } from '../../Settings.js'
import axios from 'axios';
import SideMenu from '../CommonComponents/SideMenu';
import notify from '../CommonComponents/Notify';
import {getCommonMinDateItems} from '../CommonFunctions'

class CreateRequest extends Component {
    constructor(props){
        super(props)
        this.state = 
        {   
            proposed_change:'',
            sitename:'',
            site_name_slug:'',
            departments_data: [],
            plant_data: [],
            plant:'',
            date_of_moc:new Date().toISOString().split('T')[0],
            proposal_initiated_by:'',
            department:'',
            proposal_implementation_period:'',
            change_sought:[],
            brief_description:{ 
                name_of_product:'', 
                sap_material_code:'', 
                customer_applicable:'', 
                product_manger:'', 
                existing_status:'',
                proposed_change:'',
                probable_cause:'',
                expected_benefits:''
            },
            enclosures:{
                operation_analysis:'',
                quality_of_product:false,
                describe_in_detail:'',
                attachment_url:"",
                signed_url:"",
                file_size:"",
                attachment:''
            },
            assessment_management:[],
            item:[],
            workflow_data:{},
            WorkflowAttachments:[],
            attachments:[
                {
                    attachment: "",
                    filename: "",
                    file_size: 0,
                    signed_url:"",
                    attachment_filename: ""
                }
            ],
            attachments_save_in_db:[],
            unique_stage_id: '1',
            unique_comp_id: '1',
            unique_combine: 's1c1',
            current_component_name: "MOCRequestStage",
            userPermissions: {},
            deletable_wf_attachments: [],
            moc_status :'Open',
            enable_upload_attachment : true,
        }
    }

    componentDidMount(){
        $('.loader').fadeIn()
        var roles = JSON.parse(localStorage.roles)
        if(roles.length==0 || !roles.includes("initiator")){
            window.location.href = "/dashboard"
        }
        this.getUserPrivileges()
        /*$('.datepicker').datepicker({
            autoclose: true,
            format: "dd/mm/yyyy",
            immediateUpdates: true,
            todayBtn: true,
            todayHighlight: true
        })*/
        var today_str = this.state.date_of_moc
        $('#dateMoC').val(today_str)
        var userData = JSON.parse(localStorage.getItem('user_data'))
        this.setState({
            sitename:userData.user_designation[0].site.name,
            site_name_slug: userData.user_designation[0].site.name_slug,
            plant:userData.user_designation[0].plant.name,
            proposal_initiated_by:userData.name,
            department:userData.user_designation[0].department.name,
            assessment_management:getAssessmentRowNames(),
            item:getItemRowNames()
        })
        this.getWorkflowData()
    }

    componentDidUpdate() {
        const urlParams = new URLSearchParams(window.location.search);
        const workflow = urlParams.get('workflow');
        if (workflow) {
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    }

    getUserPrivileges = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        let privileges = JSON.stringify(['can_view', 'can_update', 'can_create', 'can_attach'])
        let component = this.state.current_component_name
        axios.get(`${API_HOST}/user/privileges/${component}/${privileges}/`, )
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    userPermissions:response.data.data,
                })

            }
        }).catch(response => {
            // ErrorHandling(response)
        });
    }


    getDepartmentData = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/all/departments/data/`, )
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    departments_data:response.data.departments_data,
                })
                this.getPlantData()
            }

        }).catch(response => {
            // ErrorHandling(response)
        });
        $('.card-loader').fadeOut('slow')
    }
    getPlantData = () => {        
        if(this.state.site_name_slug){
            axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
            axios.get(`${API_HOST}/get/plants/in/site/${this.state.site_name_slug}/`, )
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        plant_data: response.data.plant_data,
                    })
                }

            }).catch(response => {
                // ErrorHandling(response)
            });
        }
        $('.card-loader').fadeOut('slow')
    }

    getWorkflowData = () =>{
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')  
    	axios.get(`${API_HOST}/workflow/moc-request/request/initiate/`, )
    	.then(response => {
	        if (response.status === 200) { 
                this.setState({
                    workflow_data:response.data.data
                })
                this.getDepartmentData()
                $('.loader').fadeOut('slow')
	        }
        }).catch(response => {
            // ErrorHandling(response)
        });
    }

    handleChange = (e, state_obj) =>{
        const urlParams = window.location.pathname;
        if(e.currentTarget.value == ""){e.currentTarget.classList.add("error")}
        if(e.currentTarget.value && e.currentTarget.classList.contains("error")){e.currentTarget.classList.remove("error")}
        const prevstate = this.state
        if(state_obj == 'brief_description'){
            prevstate['brief_description'][e.target.name] = e.target.value            
        }else if(state_obj == 'enclosures'){
            prevstate['enclosures'][e.target.name] = e.target.value
        }else if (state_obj == 'change_sought'){
            if(e.target.type=='checkbox'){
                if(e.target.checked){
                    prevstate['change_sought'].push(e.target.value)
                }else{
                    var result = prevstate['change_sought'].filter(function(elem){
                        return elem != e.target.value; 
                    });
                    prevstate["change_sought"] = result
                }
            }
        }else{
            prevstate[e.target.name] = e.target.value
        }
        this.setState({ prevstate }, () => {
        this.submitTimeout = setTimeout(() => {
            this.submitMoCRequest(e, 'Save as Draft');
        }, 100);
    });
    }

    handleTypeOfChangeDate = (e, for_attribute)=>{
        if(for_attribute==='dateImplementation'){
            this.setState({proposal_implementation_period: e.target.value})
            this.submitTimeout = setTimeout(() => {
                this.submitMoCRequest(e, 'Save as Draft');
            }, 100);
        }else if(for_attribute==='date_of_moc'){
            this.setState({date_of_moc: e.target.value})
        }
    }

    getUniqueIdOfWhomId =(mainIndex, stepIndex)=>{
        var unique_whom_id = this.state.unique_combine+'_item_'+mainIndex+'_ir_'+stepIndex+'_whom_id'
        return unique_whom_id
    }

    getUniqueIdOfDateId =(mainIndex, stepIndex)=>{
        var unique_date_id = this.state.unique_combine+'_item_'+mainIndex+'_ir_'+stepIndex+'_date_id'
        return unique_date_id
    }

    getUniqueIdOfAssesId=(mainIndex, stepIndex)=>{
        var unique_fixit = this.state.unique_combine+'_asses_'+mainIndex+'_it_'+stepIndex+'_fixit'
        return unique_fixit
    }

    assessmentItemOnChange = (e, category_type, category, step, status, category_index, step_index,remarks) =>{
        const prevstate = this.state
        if(e.currentTarget.value == ""){e.currentTarget.classList.add("error")}
        if(e.currentTarget.value && e.currentTarget.classList.contains("error")){e.currentTarget.classList.remove("error")}
        if (category_type == 'assessment_management'){
            if(status){
                prevstate['assessment_management'][category_index]['rows'][step_index]['status'] = status
                prevstate['assessment_management'][category_index]['rows'][step_index]['remarks'] = remarks
            }else{
                prevstate['assessment_management'][category_index]['rows'][step_index]['status'] = status
                prevstate['assessment_management'][category_index]['rows'][step_index]['remarks'] = ''
            }
            // if(prevstate['assessment_management'][category_index]){                
            //     prevstate['assessment_management'][category]={step:step, remarks:e.target.value}
            //     if(toggle == false){
            //         delete prevstate['assessment_management'][category]
            //         document.getElementById(step).value = "";
            //     }
            // }else if (toggle){
            //     prevstate['assessment_management'][category]={step:step, toggle:toggle, remarks:''}
            // }else{
            //     delete prevstate['assessment_management'][category]
            // }

        }else if(category_type == 'item'){
            // if(prevstate['item'][category]){                
            //     prevstate['item'][category]={step:step, completed_date:e.target.value}
            //     if(toggle == false){
            //         delete prevstate['item'][category]
            //         $('#'+step).val("").datepicker('update')
            //     }
            // }else if (toggle){
            //     prevstate['item'][category]={step:step, toggle:toggle, completed_date:''}
            // }else{
            //     delete prevstate['item'][category]
            // }
            // prevstate['item'][category_index]['rows'][step_index]['status'] = status
            var value_dict = remarks
            if(status){
                prevstate['item'][category_index]['rows'][step_index]['status'] = status
                if(value_dict.hasOwnProperty('remarks')){
                    prevstate['item'][category_index]['rows'][step_index]['remarks'] = value_dict['remarks']
                }
            }else{
                prevstate['item'][category_index]['rows'][step_index]['status'] = status
                prevstate['item'][category_index]['rows'][step_index]['remarks'] = ''
            }
            if(status == false){
                // document.getElementsByName(prevstate['item'][category_index]['rows'][step_index]['name_slug'])[0].value = ''
                // $('.datepicker').datepicker("update")
                // document.getElementsByName(prevstate['item'][category_index]['rows'][step_index]['name_slug']+"_whom")[0].value = ''

                var item_whom_id = this.getUniqueIdOfWhomId(category_index, step_index)
                var item_date_id = this.getUniqueIdOfDateId(category_index, step_index)
                $('#'+item_whom_id).val('')
                $('#'+item_whom_id).removeClass('error')

                $('#'+item_date_id).val('')
                $('#'+item_date_id).val('').removeClass('error')
            }
        }
        this.setState({ prevstate })
        this.submitTimeout = setTimeout(() => {
            this.submitMoCRequest(e, 'Save as Draft');
        }, 100);
    }

    handleUpdateAttachment = (attachment_obj) =>{
        //attachment=null,filename=null,file_size=null,signed_url=null
        var enclosures = this.state.enclosures
        enclosures['attachment_url'] = attachment_obj.filename?attachment_obj.filename:enclosures.attachment_url
        enclosures['attachment'] = attachment_obj.attachment?attachment_obj.attachment:enclosures.attachment
        enclosures['file_size'] = attachment_obj.file_size?attachment_obj.file_size:enclosures.file_size
        enclosures['signed_url'] = attachment_obj.signed_url?attachment_obj.signed_url:enclosures.signed_url        
        this.setState({
            enclosures:enclosures
        })
    }
    handleQualityProduct = (e,quality_of_product) =>{
        var enclosures = this.state.enclosures
        enclosures['quality_of_product'] = quality_of_product
        this.setState({
            enclosures:enclosures
        })
        this.submitTimeout = setTimeout(() => {
            this.submitMoCRequest(e, 'Save as Draft');
        }, 100);
    }

    handleUpdateAttachmentList = (attachment_obj, idx)=>{
        var attachments_list = this.state.attachments
        attachments_list[idx] = attachment_obj;
        this.setState({attachments: attachments_list})

    };

    handleDeleteAttachmentButton = (e, idx) =>{
        var attachments_list = this.state.attachments
        attachments_list.splice(idx, 1);
        this.setState({attachments: attachments_list})
    }

    handleMarkAttachmentAsDelete = (event, id_of_wf_attachment, delete_or_cancel)=>{
        event.preventDefault()
        var deletable_wf_attachments = this.state.deletable_wf_attachments
        const index = deletable_wf_attachments.indexOf(id_of_wf_attachment);
        if (index > -1) {
            if(delete_or_cancel=='cancel'){
                deletable_wf_attachments.splice(index, 1)
            }
        }else{
            if(delete_or_cancel=='delete'){
                deletable_wf_attachments.push(id_of_wf_attachment)
            }
            
        }
        this.setState({"deletable_wf_attachments": deletable_wf_attachments})
    }

    handleSaveAttachmentButton = (event, attachment_obj, idx) =>  {
        event.preventDefault();
        var currentPath = window.location.pathname;
        if(attachment_obj.attachment){
          $('.loader').fadeIn()
          let form_data_django = new FormData();
          form_data_django.append('attachment', attachment_obj.attachment);
          form_data_django.append('size', attachment_obj.file_size);
          let url = `${API_HOST}/upload/file/in/azure/blob/storage`;
          axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')
          axios.post(url,form_data_django,
            ).then(response=>{
                if(response.data.success){
                    // notify("File successfully uploaded",'information')
                  $('.loader').fadeOut('slow')
                    var attachment_obj = {
                        attachment_filename:response.data.filename,
                        signed_url:response.data.singed_url
                    }
                    if(currentPath == "/create/request"){
                        notify("Please create MOC request before attaching file.",'warning')
                        return false
                    }
                    this.attachmentSubmitHandler(event, attachment_obj, idx)
                }else{
                    notify(response.data.message,'error')
                    $('.card-loader').fadeOut('slow')
                }
  
            }).catch(error_xlsx => {
  
            })
  
        }else{
            notify("Invalid files selected", "error")
      }
  };

  attachmentSubmitHandler = (e, attachment_obj, idx) => {
    e.preventDefault()
    var attachment_data = {
        model:"workflow_attachments",
        data:{
                workflow_definition_id:this.state.workflow_data.workflow_definition_id,
                stage_id:this.state.workflow_data.id,
                component_id:this.state.current_component_name.id,
                file_name:attachment_obj.attachment_filename
            }
    }
    axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
    axios.post(`${API_HOST}/workflow/moc-request/request/${this.props.DocumentNumber}/form/data/save/`, attachment_data)
    .then(response => {
        if (response.status === 200) {
            notify('Attachment saved successfully','success')
            var attachment_data = response.data.data.workflow_attachments
            var attachment_obj = {
                attachment_filename:attachment_data.filename,
                signed_url:attachment_data.singed_url
            }
            let attachments = this.state.WorkflowAttachments
            attachments.push(attachment_data)
            this.setState({WorkflowAttachments: attachments})
            this.handleUpdateAttachmentList(attachment_obj, idx)
        }

    }).catch(response => {
        // ErrorHandling(response)
    });
    $('.card-loader').fadeOut('slow')
}
    handleAddAttachmentButton = (e) =>{
        let attachment_obj = {
            attachment: "",
            filename: "",
            file_size: 0,
            signed_url:"",
            attachment_filename: ""
        }
        var attachments_list = this.state.attachments
        attachments_list.push(attachment_obj);
        this.setState({attachments: attachments_list})
    };


    isOneChecked=()=>{
        var chx = document.querySelectorAll(".changesought");
        for (var i=0; i<chx.length; i++) {
          if (chx[i].type == 'checkbox' && chx[i].checked) {
            return true;
          } 
        }
        return false;
    }
    isValid=()=>{
        var name_regexp = new RegExp("<(?:\"[^\"]*\"['\"]*|'[^']*'['\"]*|[^'\">])+>");
        if(document.getElementById("permanentChange").checked == false && document.getElementById("temporaryChange").checked == false){
            return {"status":false, "reason": "Please select type of change", "id": "typeofchangeDiv"}
        }
        var dateImplementation = document.getElementById('dateImplementation').value
        if(!dateImplementation || !this.isOneChecked() || !document.querySelector("#existingStatus").value || !document.querySelector("#proposedChange").value || !document.querySelector("#probableCase").value || !document.querySelector("#proposalBenefits").value){
            var scrollDiv = ""
            if(!document.querySelector("#existingStatus").value){
                document.querySelector('#existingStatus').classList.add("error")
                scrollDiv = "briefdescriptionDiv"
            }
            if(!document.querySelector("#proposedChange").value){
                document.querySelector('#proposedChange').classList.add("error")
                scrollDiv = "briefdescriptionDiv"
            }
            if(!document.querySelector("#probableCase").value){
                document.querySelector('#probableCase').classList.add("error")
                scrollDiv = "briefdescriptionDiv"
            }
            if(!document.querySelector("#proposalBenefits").value){
                document.querySelector('#proposalBenefits').classList.add("error")
                scrollDiv = "briefdescriptionDiv"
            }
            if(!this.isOneChecked()){
                scrollDiv = "typeofchangeDiv"
            }
            if(!dateImplementation){
                document.querySelector('#dateImplementation').classList.add("error")
                scrollDiv = "typeofchangeDiv"
            }
            return {"status":false, "reason": "Please fill required field", "id": scrollDiv}
        }
        if((document.querySelector("#nameOfProduct").value&&name_regexp.test(document.querySelector("#nameOfProduct").value)) || (document.querySelector("#sapMaterialCode").value&&name_regexp.test(document.querySelector("#sapMaterialCode").value)) || (document.querySelector("#sapMaterialCode").value&&name_regexp.test(document.querySelector("#customerApplicable").value)) || (document.querySelector("#requestedByProductManger").value&&name_regexp.test(document.querySelector("#requestedByProductManger").value)) || (document.querySelector("#existingStatus").value&&name_regexp.test(document.querySelector("#existingStatus").value)) || (document.querySelector("#proposedChange").value&&name_regexp.test(document.querySelector("#proposedChange").value)) || (document.querySelector("#probableCase").value&&name_regexp.test(document.querySelector("#probableCase").value)) || (document.querySelector("#proposalBenefits").value&&name_regexp.test(document.querySelector("#proposalBenefits").value))){
            var scrollDiv = "briefdescriptionDiv"
            return {"status":false, "reason": "Please provide valid information in input fields.", "id": scrollDiv}
        }
        var date_obj = new Date(dateImplementation)
        if(dateImplementation && date_obj!="Invalid Date"){
            var today = new Date()
            today.setHours(0,0,0,0)
            if (date_obj<today){
                document.querySelector('#dateImplementation').classList.add("error")
                scrollDiv = "dateImplementation"
                return {"status": false, "reason": "Date of Implementation should not be past date", "id": scrollDiv}
            }
        }
        
        var proposed_change = this.state.proposed_change
        if(!proposed_change){
            return {"status": false, "reason": "Please fill in required field", "id": "typeofchangeDiv"}
        }
        var impact_qty_status = this.state.enclosures.quality_of_product
        if(document.querySelector('#describe_in_detail')&&!document.querySelector('#describe_in_detail')?.value){
            if(impact_qty_status){
                document.querySelector('#describe_in_detail')?.classList.add("error")
                return {"status": false, "reason": "Please enter product details", "id": "describe_in_detail"}
            }
        }
        if(document.querySelector('#describe_in_detail')&&document.querySelector('#describe_in_detail')?.value&&name_regexp.test(document.querySelector('#describe_in_detail')?.value)){
            if(impact_qty_status){
                document.querySelector('#describe_in_detail')?.classList.add("error")
                return {"status": false, "reason": "Please provide valid information in input fields.", "id": "describe_in_detail"}
            }
        }
        var response_isvalid = {"status":true, "reason":""}
        var assessment_management = this.state.assessment_management
        for (var i = 0; i<assessment_management.length; i++) {
            var rows = assessment_management[i]['rows']
            for (var j = 0; j<rows.length; j++) {
                if(rows[j].status){
                     if(!rows[j].remarks){
                        var unique_fixit = this.getUniqueIdOfAssesId(i, j)
                        response_isvalid['status'] = false
                        response_isvalid['reason'] = rows[j].name + " should not be empty. And also selected as Yes fields are mandatory."
                        // response_isvalid['id'] = rows[j].name_slug
                        response_isvalid['id'] = unique_fixit
                        // document.querySelector("#"+rows[j].name_slug)?.classList.add("error")
                        document.querySelector("#"+unique_fixit)?.classList.add("error")
                        break
                     }
                     if(rows[j].remarks&&name_regexp.test(rows[j].remarks)){
                        var unique_fixit = this.getUniqueIdOfAssesId(i, j)
                        response_isvalid['status'] = false
                        response_isvalid['reason'] = "Please provide valid information in input fields."
                        response_isvalid['id'] = unique_fixit
                        document.querySelector("#"+unique_fixit)?.classList.add("error")
                        break
                     }
                }
            }
            if(!response_isvalid['status']){
                break
            }
        }
        if (!response_isvalid['status']){
            return response_isvalid
        }

        var common_min_date_items = getCommonMinDateItems(this.state.date_of_moc)
        var common_min_date_items_obj = new Date(common_min_date_items)
        common_min_date_items_obj.setHours(0,0,0,0)

        var items = this.state.item
        for (var i = 0; i<items.length; i++) {
            var item_rows = items[i]['rows']
            for (var j = 0; j<item_rows.length; j++) {
                if(item_rows[j].status){
                   if(!item_rows[j].remarks){
                      var item_whom_id = this.getUniqueIdOfWhomId(i, j)
                      response_isvalid['status'] = false
                      response_isvalid['reason'] = item_rows[j].name + " should not be empty."
                      // response_isvalid['id'] = item_rows[j].name_slug
                      response_isvalid['id'] = item_whom_id
                      // document.querySelector("#"+item_rows[j].name_slug).classList.add("error")
                      // var k = document.querySelector("#"+item_rows[j].name_slug).parentElement.parentElement.children[2].children[0].id
                      // document.querySelector("#"+k)?.classList.add("error")
                      // document.querySelector("#"+item_rows[j].name_slug+'_whomid')?.classList.add("error")
                      document.querySelector("#"+item_whom_id)?.classList.add("error")
                      break
                   }else{
                        /*var ech_ele = item_rows[j].name_slug
                        $('#'+ech_ele+"_whomid").removeClass('error')*/
                        var item_whom_id = this.getUniqueIdOfWhomId(i, j)
                        $('#'+item_whom_id).removeClass('error')
                   }
                    if(item_rows[j].remarks&&name_regexp.test(item_rows[j].remarks)){
                        var item_whom_id = this.getUniqueIdOfWhomId(i, j)
                        response_isvalid['status'] = false
                        response_isvalid['reason'] = "Please provide valid information in input fields."
                        response_isvalid['id'] = item_whom_id
                        document.querySelector("#"+item_whom_id)?.classList.add("error")
                        break
                    }else{
                        var item_whom_id = this.getUniqueIdOfWhomId(i, j)
                        $('#'+item_whom_id).removeClass('error')
                    }
                   // var each_date = document.getElementsByName(String(item_rows[j].name_slug))[0].value
                   var item_date_id = this.getUniqueIdOfDateId(i, j)
                   var each_date = document.getElementById(item_date_id).value
                   var has_error_in_date_items = false
                   if(each_date){
                        var each_date_obj = new Date(each_date)
                        if(each_date_obj=='Invalid Date'){
                            has_error_in_date_items=true
                        }else{
                            each_date_obj.setHours(0,0,0,0)
                            if(each_date_obj<common_min_date_items_obj){
                                has_error_in_date_items=true
                            }
                        }
                   }else{
                        has_error_in_date_items = true
                   }
                   if(has_error_in_date_items){
                        response_isvalid['status'] = false
                        response_isvalid['reason'] = "empty or invalid date selected for "+item_rows[j].name
                        // response_isvalid['id'] = item_rows[j].name_slug
                        response_isvalid['id'] = item_date_id
                        // document.querySelector("#"+item_rows[j].name_slug).classList.add("error")
                        document.querySelector("#"+item_date_id).classList.add("error")
                        break
                   }else{
                        /*var ech_ele = item_rows[j].name_slug
                        $('#'+ech_ele).removeClass('error')*/
                        $('#'+item_date_id).removeClass('error')
                   }
                }
            }
            if(!response_isvalid['status']){
                break
            }
        }

        if (!response_isvalid['status']){
            return response_isvalid
        }

        return {"status":true, "reason":""}
    }

    handleCancel = ()=>{
        window.location.href='/'
    }


    submitMoCRequest = (e,status) =>{
        e.preventDefault();
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        var site_name = this.state.sitename
        var plant_name = this.state.plant
		axios.get(`${API_HOST}/can/initiate/request/?site_name=${site_name}&plant_name=${plant_name}`,)
		.then(response => {
			if(!response.data.status) {
                notify("User doesn't have initator role for site ("+site_name+") and plant ("+plant_name+")",'warning')
                setTimeout(function () {
                    document.querySelector('#typeofchangeDiv').scrollIntoView({
                        behavior: 'smooth', block: "center", inline: "nearest" 
                    })
                }, 100)
                return false
			}else{
                if(status === 'open'){
                    var response_isvalid = this.isValid()
                    if(!response_isvalid.status){
                        notify(response_isvalid.reason,'warning')
                        setTimeout(function () {
                            document.querySelector('#'+response_isvalid.id).scrollIntoView({
                                behavior: 'smooth', block: "center", inline: "nearest" 
                            })
                        }, 100)
                        return false
                    }
                    if(status == 'open'){
                        var msg = "Are you sure, you want submit moc request?"
                    }else{
                        var msg = "Are you sure, you want to save as draft?"
                    }
                }
               
                $('.loader').fadeIn()
                var moc_data = this.state
                var moc_data_item = moc_data.item.map((item,index)=>{
                    var itemRow = item.rows.map((row,ind)=>{
                        // var input_val = document.getElementsByName(String(row.name_slug))[0].value
                        var item_date_id = this.getUniqueIdOfDateId(index, ind)
                        var input_val = document.getElementById(item_date_id).value
                        row['date_of_complete'] = input_val?input_val:''
                        // var element_to_be_updated_by_whom = document.getElementsByName(String(row.name_slug)+"_whom")

                        var item_whom_id = this.getUniqueIdOfWhomId(index, ind)
                        var element_to_be_updated_by_whom = document.getElementById(item_whom_id)
                        var to_be_updated_by_whom = ""
                        if(element_to_be_updated_by_whom){
                        to_be_updated_by_whom = element_to_be_updated_by_whom.value
                        }
                        row['to_be_updated_by_whom'] = to_be_updated_by_whom?to_be_updated_by_whom:''
                        return row
                        
                    })
                    item['rows'] = itemRow
                    return item
                })
                moc_data['item'] = moc_data_item
                // moc_data['date_of_moc'] = document.getElementById('dateMoC').value
                moc_data['date_of_moc'] = this.state.date_of_moc
                var dateImplementation = document.getElementById('dateImplementation').value
                // var dateImplementation_conv = convertDate(dateImplementation)
                moc_data['proposal_implementation_period'] = dateImplementation
                moc_data['status'] = status
                moc_data['departments_data'] = undefined //departments_data, plant_data not required to send to backend
                moc_data['plant_data'] = undefined // as this not required to send to backend
                var form_data = new FormData();
                moc_data.attachments.map((item, index)=>{
                    form_data.append('attachments', item.attachment)
                })
                form_data.append('moc_data', JSON.stringify(moc_data))
                delete moc_data.attachments
                const auth_token = localStorage.getItem("token")
                    axios.defaults.headers.post['Authorization'] =auth_token      
                    axios.post(`${API_HOST}/save/moc/request/data`, form_data,{
                                headers: {'Content-Type': 'multipart/form-data'}
                            }
                        )
                    .then(response => {
                        if (response.status === 200 && response.data.success) {  
                            notify('Request created successfully','success')                  
                            setTimeout(() =>{
                                $('.loader').fadeOut('slow')
                                this.props.history.push('/moc/request/'+response.data.document_number+'/')
                            },3000)
                        }else{
                            notify(response.data.message, 'warning')   
                            $('.loader').fadeOut('slow')
                        }
                    }).catch(response => {
                        // ErrorHandling(response)
                    });
            }
		})

    }

    /*getCommonMinDateItems = ()=>{
        var today_str = new Date().toISOString().split("T")[0]
        var min_date = this.state.date_of_moc
        var common_min_date_items = today_str
        if(min_date){
            var min_date_obj = new Date(min_date)
            if(min_date_obj!='Invalid Date'){
                common_min_date_items = min_date_obj.toISOString().split('T')[0]
            }
        }
        return common_min_date_items
    }*/
   
    render(){
        var access_type = 'create'
        var data_for_change_sought = {
            'change_sought': this.state.change_sought
        }
        var common_min_date_items = getCommonMinDateItems(this.state.date_of_moc)
        return(
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-12 side-nav-block">
                        <div className="card sticky-card">
                            <div className="card-body sticky-body">
                            {this.state.workflow_data.name_slug?
                                <SideMenu activeNameSlug={this.state.workflow_data.name_slug} RequestType="moc-request" />
                                :""}
                            </div>
                        </div>
                    </div>                
                    <div className="col-lg-9 col-md-9 col-sm-12">
                        <TypeOfChange onChangeHandler = {this.handleChange}
                            handleTypeOfChangeDate = {this.handleTypeOfChangeDate}
                            thisData = {this.state} accessType={access_type}/>
                        <ChangeSought  onChangeHandler = {this.handleChange} thisData = {data_for_change_sought} accessType={access_type}/>
                        <BriefDescription onChangeHandler = {this.handleChange} thisData = {this.state.brief_description} accessType={access_type} />
                        <Enclosures onChangeHandler = {this.handleChange}
                            handleUpdateAttachment={this.handleUpdateAttachment}
                            thisData={this.state.enclosures}    
                            handleAddAttachmentButton={this.handleAddAttachmentButton}
                            WorkflowAttachments={this.state.WorkflowAttachments}
                            handleUpdateAttachmentList={this.handleUpdateAttachmentList}
                            attachments_save_in_db={this.state.attachments_save_in_db}
                            handleDeleteAttachmentButton={this.handleDeleteAttachmentButton}
                            handleSaveAttachmentButton={this.handleSaveAttachmentButton}
                            handleMarkAttachmentAsDelete={this.handleMarkAttachmentAsDelete}
                            unique_combine={this.state.unique_combine}
                            ask_for_more_history_filtered={[]}
                            attachments={this.state.attachments}
                            userPermissions={this.state.userPermissions}
                            accessType={access_type}
                            moc_status ={this.state.moc_status} 
                            enable_upload_attachment={this.state.enable_upload_attachment}/>                  
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className='display-flex'>
                                    <p> <b>Impact on quality of product? *</b></p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Toggle status={this.state.enclosures.quality_of_product} handleQualityProduct = {this.handleQualityProduct} accessType={access_type} requestFrom="QualityOfProduct" />
                                </div>
                                <div className='row mb-3'>
                                    <div className='col-12'>
                                        <label className="form-label" htmlFor="describe">If yes, describe in detail</label>
                                        <textarea className='form-control' rows="3" name="describe_in_detail" id="describe_in_detail" type="text" disabled={this.state.enclosures.quality_of_product?false:true} onChange={(e)=>this.handleChange(e,'enclosures')}></textarea>
                                    </div>                                                             
                                </div>
                                <div className='display-flex'>
                                    <p><b>Assessment for management of change * </b></p>&nbsp;&nbsp;<span> (Operation Analysis Checklist To be filled in by the Proposer/Reviewer)</span>
                                </div>
                                <div className='row overflow-x-scroll'>
                                    <AssessmentTable unique_combine={this.state.unique_combine} onChangeHandler = {this.assessmentItemOnChange} assessmentData = {this.state.assessment_management} accessType={access_type} />
                                    <ItemTable unique_combine={this.state.unique_combine} onChangeHandler = {this.assessmentItemOnChange} itemData = {this.state.item} common_min_date_items={common_min_date_items} accessType={access_type} />
                                </div>
                                <div className='row'>
                                    <p>If relevant, initiator to submit a change proposal to Site Engineer for assessment prior to formal sign off by Site Manager.</p>
                                    <br/>
                                    <span>
                                        <b>NEW EQUIPMENT IS TO BE TAGGED “DO NOT OPERATE” UNTIL EHS ACCEPTANCE ISPASSED</b>
                                    </span>
                                </div>                                                
                            </div>    
                            <div className='row'>
                                <div className='p-4'>
                                    <div className="d-flex gap-2 d-md-flex justify-content-md-end">
                                        <button className="btn btn-outline-secondary" type="button" id={this.state.unique_combine+"cancel"} onClick={this.handleCancel}>Cancel</button>
                                        {/* {this.state.userPermissions.can_create ?
                                        [<button key={this.state.unique_combine+'savedraft'} className="btn btn-light" type="button" id="save-as-draft" onClick={(e) => this.submitMoCRequest(e,'Save as Draft')} >Save Draft</button>,
                                        <button key={this.state.unique_combine+'submit'} className="btn btn-dark" type="submit" id="submit" onClick={(e) => this.submitMoCRequest(e,'open')} >Submit</button>]
                                        :null
                                        }   */}
                                        {this.state.userPermissions.can_create ?
                                        <button key={this.state.unique_combine+'submit'} className="btn btn-dark" type="submit" id="submit" onClick={(e) => this.submitMoCRequest(e,'open')} >Submit</button>
                                        :null
                                        }      
                                    </div>
                                </div>
                            </div>                    
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(CreateRequest)