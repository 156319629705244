import React, { Component } from 'react';
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { FaUserCheck } from "react-icons/fa";


class Signed extends Component {

    render(){
        return(
            <div className='col-lg-3 col-md-6 col-sm-12'>
                <div className='card signed-info'>
                    <div className='card-body'>
                        <p><span className="signed-icon"><IoIosCheckmarkCircleOutline/></span> Signed</p>
                        <p className='no-margin'><span className="signed-icon"><FaUserCheck/></span> {this.props.SignatureData.user_info.first_name} {this.props.SignatureData.user_info.last_name} </p>
                        <p className='no-margin'>{this.props.SignatureData.user_info.user_department} Department</p>
                        <p className='no-margin'><i>{this.props.SignatureData.created_date_formated}</i></p>
                    </div>
                </div>
        </div>
        )
    }
}

export default Signed;