import React, { Component, createRef } from "react";
import RejectButton from "../../ConsentComponents/RejectButton";
import ApproveButton from "../../ConsentComponents/ApproveButton";
import Sign from "../../ConsentComponents/Sign";
import axios from 'axios';
import { API_HOST } from '../../../Settings';
import $ from 'jquery';
import Signed from "../../ConsentComponents/Signed";
import Comment from '../../ConsentComponents/Comment';
import notify from "../../CommonComponents/Notify";
import Attachment from "../../ConsentComponents/Attachment";
import ViewAttachments from "../../ConsentComponents/ViewAttachments";
import { FaPlusCircle } from "react-icons/fa";
import { BiChevronRightCircle } from "react-icons/bi";
import { BiTrash } from "react-icons/bi";
import { FaRegCheckCircle } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import Subtask from "./Subtask"
import EditSubtask from "./EditSubtask";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from 'react-router-dom';

class PlantMOCLeadFinalApproval extends Component {
    constructor(props) {
        super(props)
        this.commentRef = createRef();
        this.handleOfComment = this.handleOfComment.bind(this)
        this.state = {
            is_approved: false,
            is_rejected: false,
            WorkflowData: this.props.WorkflowData,
            WorkflowComponent: this.props.WorkflowComponent,
            DocumentNumber: this.props.DocumentNumber,
            WorkflowSignatures: [],
            unique_stage_id: '8',
            unique_comp_id: '1',
            unique_combine: 's8c1',
            stage_status: this.props.stage_status,
            isEditPopupOpen: false,
            selectedTaskId: null,
            userPermissions: {},
            current_component_name: "PlantMOCLeadFinalApproval",
            attachments: [
                {
                    attachment: "",
                    filename: "",
                    file_size: 0,
                    signed_url: "",
                    attachment_filename: ""
                }
            ],
            WorkflowAttachments: [],
            moc_status: '',
            tasks: [],
        }
    }
    componentDidMount() {
        this.getUserPrivileges()
        this.closeEditPopup()
        if (this.props.isCollapased == false && this.props.DocumentNumber) {
            axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
            axios.get(`${API_HOST}/workflow/moc/request/${this.props.DocumentNumber}/stage/${this.props.StageName}/`,)
                .then(response => {
                    if (response.status === 200) {
                        this.setState({
                            WorkflowData: response.data.data.workflow_stage_info,
                            WorkflowComponent: response.data.data.workflow_stage_info.components[0],
                            WorkflowSignatures: response.data.data.workflow_stage_info.components[0].workflow_signatures,
                            is_approved: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] &&
                                response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Approved" ? true : false,
                            is_rejected: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] &&
                                response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false,
                            // userComponentPermissions: response.data.data.workflow_stage_info.components[0].user_component_permissions
                            moc_status: response.data.data.moc_status,
                        })
                        // this.renderDynamicComponent()
                    }

                }).catch(response => {
                    // ErrorHandling(response)
                });
            $('.card-loader').fadeOut('slow')
        }
        this.getAllData()
        this.getTaskData()
    }
    getTaskData = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/execution/sub/tasks/?document_number=${this.props.DocumentNumber}`,)
            .then(response => {
                var data = response.data.execution_tasks;
                console.log(data.is_plant_moc_lead, 'dataaaaaa...')
                this.setState({ 'tasks': data });

            })
    }
    getAllData = () => {
        $('.card-loader').fadeIn()
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/workflow/moc/request/${this.props.DocumentNumber}/stage/${this.props.StageName}/?component_name=${this.props.ComponentName}`,)
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        WorkflowData: response.data.data.workflow_stage_info,
                        WorkflowComponent: response.data.data.workflow_stage_info.components[0],
                        WorkflowSignatures: response.data.data.workflow_stage_info.components[0].workflow_signatures,
                        is_approved: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] &&
                            response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Approved" ? true : false,
                        WorkflowAttachments: response.data.data.workflow_stage_info.components[0].workflow_attachments,
                        is_rejected: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] &&
                            response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false,
                    })
                }

            }).catch(response => {
                // ErrorHandling(response)
            });
        $('.card-loader').fadeOut('slow')
    }
    handleUpdateAttachmentList = (attachment_obj, idx) => {
        var attachments_list = this.state.attachments
        attachments_list[idx] = attachment_obj;
        this.setState({ attachments: attachments_list })
    };
    handleAddAttachmentButton = (e) => {
        let attachment_obj = {
            attachment: "",
            filename: "",
            file_size: 0,
            signed_url: "",
            attachment_filename: ""
        }
        var attachments_list = this.state.attachments
        attachments_list.push(attachment_obj);
        this.setState({ attachments: attachments_list })
    };
    handleDeleteAttachmentButton = (e, idx) => {
        var attachments_list = this.state.attachments
        attachments_list.splice(idx, 1);
        this.setState({ attachments: attachments_list })
    }

    attachmentSubmitHandler = (e, attachment_obj, idx) => {
        e.preventDefault()
        var attachment_data = {
            model: "workflow_attachments",
            data: {
                workflow_definition_id: this.state.WorkflowData.workflow_definition_id,
                stage_id: this.state.WorkflowData.id,
                component_id: this.state.WorkflowComponent.id,
                file_name: attachment_obj.attachment_filename
            }
        }
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')
        axios.post(`${API_HOST}/workflow/moc-request/request/${this.props.DocumentNumber}/form/data/save/`, attachment_data)
            .then(response => {
                if (response.status === 200) {
                    notify('Attachment saved successfully', 'success')
                    var attachment_data = response.data.data.workflow_attachments
                    console.log(attachment_data, 'attachment_dataa........')
                    var attachment_obj = {
                        attachment_filename: attachment_data.filename,
                        signed_url: attachment_data.singed_url
                    }
                    let attachments = this.state.WorkflowAttachments
                    attachments.push(attachment_data)
                    this.setState({ WorkflowAttachments: attachments })
                    this.handleUpdateAttachmentList(attachment_obj, idx)
                }

            }).catch(response => {
                // ErrorHandling(response)
            });
        $('.card-loader').fadeOut('slow')
    }

    removeWfAttachment = (event, id_of_wf_attachment, component_id) => {
        event.preventDefault()
        var user_resp = window.confirm("Are you sure, you want to delete the attachment?")
        if (user_resp) {
            $('.card-loader').fadeIn('')
            var attachment_data = {
                workflow_definition_id: this.state.WorkflowData.workflow_definition_id,
                stage_id: this.state.WorkflowData.id,
                component_id: this.state.WorkflowComponent.id,
                wf_attachment_id: id_of_wf_attachment
            }
            axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')
            axios.post(`${API_HOST}/workflow/moc-request/request/${this.props.DocumentNumber}/attachment/delete/`, attachment_data)
                .then(response => {
                    if (response.status === 200) {
                        notify('Attachment deleted successfully', 'success')
                        this.getAllData()
                    }

                }).catch(response => {
                    // ErrorHandling(response)
                    $('.card-loader').fadeOut('slow')
                });
        }

    }

    handleSaveAttachmentButton = (event, attachment_obj, idx) => {
        event.preventDefault();
        if (attachment_obj.attachment) {
            $('.loader').fadeIn()
            let form_data_django = new FormData();
            form_data_django.append('attachment', attachment_obj.attachment);
            form_data_django.append('size', attachment_obj.file_size);
            let url = `${API_HOST}/upload/file/in/azure/blob/storage`;
            axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')
            axios.post(url, form_data_django,
            ).then(response => {
                if (response.data.success) {
                    // notify("File successfully uploaded",'information')
                    $('.loader').fadeOut('slow')
                    var attachment_obj = {
                        attachment_filename: response.data.filename,
                        signed_url: response.data.singed_url
                    }
                    this.attachmentSubmitHandler(event, attachment_obj, idx)
                } else {
                    notify(response.data.message, 'error')
                    $('.card-loader').fadeOut('slow')
                }

            }).catch(error_xlsx => {

            })

        } else {
            alert("Invalid files selected")
        }
    };

    handleOfComment(e, stop_loader, method) {
        var response_isvalid = this.commentRef.current.isValid()
        if (!response_isvalid.status) {
            if (method == "approve") {
                if (response_isvalid.custom_error_code != 1) {
                    // if custom_error_code==1  we can process approval without comment saving
                    notify(response_isvalid.reason, 'warning')
                    return false
                }
            } else {
                if (response_isvalid.custom_error_code == 1) {
                    return false
                }
                if (response_isvalid.custom_error_code == 2) {
                    // if custom_error_code==1  we can process approval without comment saving
                    notify(response_isvalid.reason, 'warning')
                    return false
                }
            }
        } else {
            this.commentRef.current.commentSubmitHandler(e, stop_loader)
            return true
        }
        return true
    }

    getUserPrivileges = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        let privileges = JSON.stringify(['can_approve', 'can_reject', 'can_sign', 'can_comment', 'can_attach'])
        let component = this.state.current_component_name
        let params = { "document_number": this.state.DocumentNumber, "from_new": true, "request_type": "moc_request" }
        axios.get(`${API_HOST}/user/privileges/${component}/${privileges}/`, { params: params })
            .then(response => {
                if (response.status === 200) {
                    console.log(response.data.data, 'permissions')
                    this.setState({
                        userPermissions: response.data.data,
                    })

                }
            }).catch(response => {
                // ErrorHandling(response)
            });
    }


    handleInputChange = (e, index, field) => {
        const updatedTasks = [...this.state.tasks];
        updatedTasks[index][field] = e.target.value;
        this.setState({ tasks: updatedTasks });
    };

    addTaskRow = () => {
        const newTask = { title: "", department: "", action: "Test" };
        this.setState((prevState) => ({
            tasks: [...prevState.tasks, newTask],
        }));
    };

    handleDeleteTask = (index, id) => {
        debugger;
        var user_resp = window.confirm("Are you sure, you want to delete this task?")
        if (user_resp) {
            $('.card-loader').fadeIn('')
            var attachment_data = {
                task_id: id,
            }
            axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')
            axios.post(`${API_HOST}/execution/task/delete/`, attachment_data)
                .then(response => {
                    if (response.status === 200) {
                        setTimeout(() => {
                            $('.loader').fadeOut('slow')
                            window.location.reload();
                        }, 1000)
                    }
                    notify(response.data.message, 'success')

                }).catch(response => {
                    // ErrorHandling(response)
                    $('.card-loader').fadeOut('slow')
                });
        }
        const updatedTasks = [...this.state.tasks];
        updatedTasks.splice(index, 1);
        this.setState({ tasks: updatedTasks });


    };


    handleEditClick = (taskId) => {
        console.log('Editing task ID:', taskId);
        this.setState({
            selectedTaskId: taskId,
            isEditPopupOpen: true,
        });
    };


    closeEditPopup = () => {
        console.log('edit-close-pop-up-plant')
        this.setState({
            isEditPopupOpen: false,
            selectedTaskId: null,
        });
    };

    render() {
        var enable_attach_delete = false
        if (this.state.moc_status == 'Open') {
            enable_attach_delete = true
        }
        const { tasks, isEditPopupOpen, selectedTaskId } = this.state;
        return (
            <div className="card mb-3" id="PlantMOCLeadFinalApproval">
                <div className='p-4'>
                    <div className='heading-Text font-16'>
                        MoC-Lead Final Approval
                    </div>
                    <div className='mt-4 row mb-3'>
                        <div>
                            <p><b>Attachments</b></p>
                            {this.state.WorkflowAttachments.length > 0 ?
                                <ViewAttachments removeWfAttachment={this.removeWfAttachment}
                                    key={this.state.unique_combine + 'va'}
                                    enable_attach_delete={enable_attach_delete}
                                    WorkflowAttachments={this.state.WorkflowAttachments} />

                                :
                                <div style={{ marginBottom: '20px' }}>No Attachments</div>
                            }
                        </div>
                        {this.state.userPermissions.can_attach && this.state.moc_status == 'Open' ?
                            <div style={{ 'marginTop': '12px' }}>
                                <div className='heading-Text font-12 mb-3 d-flex'>
                                    Upload Attachments
                                    <div className="plus-circle"><FaPlusCircle onClick={this.handleAddAttachmentButton} /></div>
                                </div>
                                {this.state.attachments.map((each, index) => {
                                    return <Attachment AttachmentIndex={index}
                                        AttachmentObj={each}
                                        key={this.state.unique_combine + 'attach' + index}
                                        handleUpdateAttachmentList={this.handleUpdateAttachmentList}
                                        handleDeleteAttachmentButton={this.handleDeleteAttachmentButton}
                                        handleSaveAttachmentButton={this.handleSaveAttachmentButton}
                                    />
                                })}
                            </div>
                            : null}
                        <Comment is_rejected={this.state.is_rejected}
                            stage_status={this.state.stage_status}
                            unique_combine={this.state.unique_combine}
                            PlantMOCOpinion={this.state.plant_moc_opinion}
                            isRejected={this.state.show_plant_moc_opinion_card}
                            is_approved={this.state.is_approved}
                            WorkflowData={this.state.WorkflowData}
                            WorkflowComponent={this.state.WorkflowComponent}
                            DocumentNumber={this.state.DocumentNumber}
                            RequestType="moc-request"
                            ref={this.commentRef}
                            userPermissions={this.state.userPermissions} />
                        <div className="col-lg-2 col-md-6 col-sm-4 col-4"></div>
                        {this.state.WorkflowSignatures.length > 0 ?
                            <Signed SignatureData={this.state.WorkflowSignatures[0]} /> : ""
                        }
                    </div>
                    <div className='d-flex w-100 justify-content-end align-items-end tri-buttons mt-4'>

                        <Subtask
                            document_number={this.props.DocumentNumber}
                            userPermissions={this.state.userPermissions}
                            is_rejected={this.state.is_rejected}
                            is_approved={this.state.is_approved}
                            component_name={this.state.current_component_name}
                          
                        />

                        <div className='pe-2'>
                            <RejectButton
                                stage_status={this.state.stage_status}
                                PlantMOCOpinion={this.state.plant_moc_opinion}
                                IsApproved={this.state.is_approved}
                                WorkflowData={this.state.WorkflowData}
                                WorkflowComponent={this.state.WorkflowComponent}
                                DocumentNumber={this.state.DocumentNumber}
                                key={this.state.unique_combine + 'rejbtn'}
                                unique_combine={this.state.unique_combine}
                                ask_for_more_history={this.state.ask_for_more_history}
                                current_component_name={this.state.current_component_name}
                                RequestType="moc-request"
                                userPermissions={this.state.userPermissions}
                                handleOfComment={this.handleOfComment} />
                        </div>
                        <div>
                            <ApproveButton
                                stage_status={this.state.stage_status}
                                key={this.state.unique_combine + '_approve'}
                                IsApproved={this.state.is_approved}
                                WorkflowData={this.state.WorkflowData}
                                WorkflowComponent={this.state.WorkflowComponent}
                                DocumentNumber={this.state.DocumentNumber}
                                key={this.state.unique_combine + 'rejbtn'}
                                unique_combine={this.state.unique_combine}
                                ask_for_more_history={this.state.ask_for_more_history}
                                current_component_name={this.state.current_component_name}
                                RequestType="moc-request"
                                handleOfComment={this.handleOfComment}
                                userPermissions={this.state.userPermissions} />
                        </div>
                        {/* <div className='d-flex w-100 justify-content-end align-items-end tri-buttons mt-4'> */}

                        {/* </div> */}

                    </div>
                </div>
                <div className='mt-2 pe-3'>
                    <Sign IsSignatures={this.state.WorkflowSignatures.length > 0 ? true : false}
                        IsApproved={this.state.is_approved}
                        WorkflowData={this.state.WorkflowData} WorkflowComponent={this.state.WorkflowComponent}
                        DocumentNumber={this.state.DocumentNumber} RequestType="moc-request"
                        userPermissions={this.state.userPermissions}
                        current_component_name={this.state.current_component_name}
                    />
                </div>

                &nbsp;&nbsp;
                <div className="overflow-x-scroll px-3">
                    {this.state.tasks.length > 0 && (this.state.stage_status === 'pending'|| this.state.stage_status === 'rejected') ? (
                        <table className="table table-bordered">
                            <thead className="vertical-align-middle">
                                <tr>
                                    <th width="20%"><b>Task Name</b></th>
                                    <th className="text-center"><b>Description</b></th>
                                    <th className="text-center"><b>Assignee</b></th>
                                    {(this.state.userPermissions.can_reject && this.state.is_rejected === false) &&
                                        (this.state.userPermissions.can_approve && this.state.is_approved === false) ? (
                                        <th className="text-center"><b>Delete</b></th>
                                    ) : null}
                                </tr>
                            </thead>
                            <tbody className="vertical-align-middle">
                                {this.state.tasks.map((task, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div className="view-task-name">
                                                {task.status === 'pending' ? (
                                                    <FaRegCheckCircle className="task-icon" />
                                                ) : task.status === 'completed' ? (
                                                    <FaCheckCircle className="task-icon-fa" />
                                                ) : null}
                                                <Link to={`/moc/request/subtask/${this.props.DocumentNumber}/${task.id}`}>Task {index + 1}</Link>
                                            </div>
                                        </td>
                                        <td width="50%">
                                            <div className="view-task-name">
                                                <p>{task.description.length > 20
                                                    ? `${task.description.slice(0, 80)}...`
                                                    : task.description}</p>
                                            </div>
                                        </td>
                                        <td width="20%">
                                            <div className="view-task-name">
                                                <p>{task.assignee}</p>
                                            </div>
                                        </td>
                                        {(this.state.userPermissions.can_reject && this.state.is_rejected === false) &&
                                            (this.state.userPermissions.can_approve && this.state.is_approved === false) ? (
                                            <td className="text-center task-icon">
                                                <BiTrash onClick={() => this.handleDeleteTask(index, task.id)} style={{ cursor: 'pointer' }} />
                                            </td>
                                        ) : null}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        )
    }
}

export default PlantMOCLeadFinalApproval;