import React, { Component, createRef } from "react";
import { FaPlusCircle } from "react-icons/fa";
import Attachment from "../ConsentComponents/Attachment";
import Comment from "../ConsentComponents/Comment";
import Sign from "../ConsentComponents/Sign";
import ViewAttachments from "../ConsentComponents/ViewAttachments";
import axios from "axios";
import { API_HOST } from "../../Settings";
import notify from "../CommonComponents/Notify";
import ApproveButton from "../ConsentComponents/ApproveButton";
import $ from 'jquery';
import Signed from "../ConsentComponents/Signed";

class AssigneeReview extends Component{
    constructor(props){
        super(props)
        this.commentRef = createRef();
        this.handleOfComment = this.handleOfComment.bind(this)
        this.state = {
            attachments:[
                {
                    attachment: "",
                    filename: "",
                    file_size: 0,
                    signed_url:"",
                    attachment_filename: ""
                }
            ],
            WorkflowAttachments: this.props.WorkflowComponent?this.props.WorkflowComponent.workflow_attachments:[],
            unique_combine: 'st2c1',
            is_approved:false,
            stage_status:this.props.stage_status,
            DocumentNumber:this.props.DocumentNumber,
            WorkflowSignatures:this.props.WorkflowComponent ? this.props.WorkflowComponent.workflow_signatures:[],
            userComponentPermissions:[],
            userPermissions: {},
            current_component_name: "AssigneeReview",
        }
    }
    checkIsApproved = (is_approved) =>{
        this.setState({
            is_approved:is_approved
        })

    }
    getAllData = ()=>{
        $('.card-loader').fadeIn()
		axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
		axios.get(`${API_HOST}/workflow/moc/subtask/request/${this.props.DocumentNumber}/stage/${this.props.StageName}/?component_name=${this.props.ComponentName}`, )
		.then(response => {
		    if (response.status === 200) {
                this.setState({
		            WorkflowData: response.data.data.workflow_stage_info,
		            WorkflowComponent :response.data.data.workflow_stage_info.components[0],
		            WorkflowSignatures :response.data.data.workflow_stage_info.components[0].workflow_signatures,
                    WorkflowAttachments:response.data.data.workflow_stage_info.components[0].workflow_attachments,
		            enable_signing :response.data.data.workflow_stage_info.components[0].enable_signing,
		            enable_approval :response.data.data.workflow_stage_info.components[0].enable_approval,
		            is_approved : response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
		                response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Approved" ? true : false,
		           	userComponentPermissions: response.data.data.workflow_stage_info.components[0].user_component_permissions,
		        })
		    }

		}).catch(response => {
		    // ErrorHandling(response)
		});
        $('.card-loader').fadeOut('slow')
    }
    componentDidMount(){
        this.getUserPrivileges()
        if(this.props.isCollapased==false && this.state.DocumentNumber){
            this.getAllData()
        }
    }

    getUserPrivileges = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        let privileges = JSON.stringify(['can_comment', "can_sign", "can_approve", "can_attach"])
        let component = this.state.current_component_name
        axios.get(`${API_HOST}/user/privileges/${component}/${privileges}/?subtask_document_number=${this.state.DocumentNumber}`, )
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    userPermissions:response.data.data,
                })

            }
        }).catch(response => {
            // ErrorHandling(response)
        });
    }

    handleUpdateAttachmentList = (attachment_obj, idx)=>{
        var attachments_list = this.state.attachments
        attachments_list[idx] = attachment_obj;
        this.setState({attachments: attachments_list})

    };
    handleAddAttachmentButton = (e) =>{
        let attachment_obj = {
            attachment: "",
            filename: "",
            file_size: 0,
            signed_url:"",
            attachment_filename: ""
        }
        var attachments_list = this.state.attachments
        attachments_list.push(attachment_obj);
        this.setState({attachments: attachments_list})
    };
    handleDeleteAttachmentButton = (e, idx) =>{
        var attachments_list = this.state.attachments
        attachments_list.splice(idx, 1);
        this.setState({attachments: attachments_list})
    }

    attachmentSubmitHandler = (e, attachment_obj, idx) => {
        e.preventDefault()
        var url = `${API_HOST}/workflow/moc-sub-task/request/${this.props.DocumentNumber}/form/data/save/`
        if(attachment_obj.attachment_filename == ""){
            notify("Please select attachment file.", "danger")
            return false
        }
        var attachment_data = {
            model:"workflow_attachments",
            data:{
                    workflow_definition_id:this.state.WorkflowData.workflow_definition_id,
                    stage_id:this.state.WorkflowData.id,
                    component_id:this.state.WorkflowComponent.id,
                    file_name:attachment_obj.attachment_filename
                }
        }
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
        axios.post(url, attachment_data)
        .then(response => {
            if (response.status === 200) {
                notify('Attachment saved successfully','success')
                var attachment_data = response.data.data.workflow_attachments
                var attachment_obj = {
                    attachment_filename:attachment_data.filename,
                    signed_url:attachment_data.singed_url
                }
                let attachments = this.state.WorkflowAttachments
                attachments.push(attachment_data)
                this.setState({WorkflowAttachments: attachments})
                this.handleUpdateAttachmentList(attachment_obj, idx)
            }

        }).catch(response => {
            // ErrorHandling(response)
        });
        $('.card-loader').fadeOut('slow')
    }

    removeWfAttachment =(event, id_of_wf_attachment, component_id)=>{
        event.preventDefault()
        var user_resp = window.confirm("Are you sure, you want to delete the attachment?")
        if(user_resp){
            $('.card-loader').fadeIn('')
            var attachment_data = {
                workflow_definition_id:this.state.WorkflowData.workflow_definition_id,
                stage_id:this.state.WorkflowData.id,
                component_id:this.state.WorkflowComponent.id,
                wf_attachment_id:id_of_wf_attachment
            }
            axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
            axios.post(`${API_HOST}/workflow/moc-sub-task/request/${this.props.DocumentNumber}/attachment/delete/`, attachment_data)
            .then(response => {
                if (response.status === 200) {
                    notify('Attachment deleted successfully','success')
                    this.getAllData()
                }

            }).catch(response => {
                // ErrorHandling(response)
                $('.card-loader').fadeOut('slow')
            });
        }
        
    }

    handleSaveAttachmentButton = (event, attachment_obj, idx) =>  {
      event.preventDefault();
      $('.card-loader').fadeIn()
      if(attachment_obj.attachment){
        let form_data_django = new FormData();
        form_data_django.append('attachment', attachment_obj.attachment);
        form_data_django.append('size', attachment_obj.file_size);
        let url = `${API_HOST}/upload/file/in/azure/blob/storage`;
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')
        axios.post(url,form_data_django,
          ).then(response=>{
              if(response.data.success){
                  // notify("File successfully uploaded",'information')
                  var attachment_obj = {
                      attachment_filename:response.data.filename,
                      signed_url:response.data.singed_url
                  }
                  this.attachmentSubmitHandler(event, attachment_obj, idx)
              }else{
                  notify(response.data.message,'error')
                  $('.card-loader').fadeOut('slow')
              }

          }).catch(error_xlsx => {

          })

        }else{alert("Invalid files selected")}
    }  

    handleOfComment(e, stop_loader, method){
        var response_isvalid = this.commentRef.current.isValid()
        if(!response_isvalid.status){
            if(method == "approve"){
                if(response_isvalid.custom_error_code!=1){
                    // if custom_error_code==1  we can process approval without comment saving
                    notify(response_isvalid.reason,'warning')
                    return false
                }
            }else{
                if(response_isvalid.custom_error_code==1){
                    return false
                }
                if(response_isvalid.custom_error_code==2){
                    // if custom_error_code==1  we can process approval without comment saving
                    notify(response_isvalid.reason,'warning')
                    return false
                }
            }
        }else{
            this.commentRef.current.commentSubmitHandler(e, stop_loader)
            return true
        }
        return true
    }

    render(){
        var access_type='view'
        if(this.state.userPermissions.can_attach && !this.state.is_approved){
            access_type = 'create'
        }
        return(
            <div className="row">
                <div className="col-12">
                    <div className='heading-Text font-16'>Assignee</div>
                    <div className='col-lg-6 col-md-8 col-12 mt-4'>
                        <Comment is_approved={this.state.is_approved} WorkflowData={this.state.WorkflowData} WorkflowComponent={this.state.WorkflowComponent} DocumentNumber={this.state.DocumentNumber} RequestType="moc-sub-task" userPermissions={this.state.userPermissions} ref={this.commentRef}/>
                    </div>
                    <div className='row mt-3 mb-3'>
                        <div className='heading-Text font-16 mt-5'>
                            <p> <b>Attachments</b></p>
                            <div>
                            {this.state.WorkflowAttachments.length > 0 ?
                                <ViewAttachments removeWfAttachment={this.removeWfAttachment}
                                    key={this.state.unique_combine+'va'}
                                    enable_attach_delete={['create', 'update'].includes(access_type)}
                                    WorkflowAttachments={this.state.WorkflowAttachments} />
                            :""}
                            </div>
                            {this.state.userPermissions.can_attach?this.state.is_approved?'':
                        <div>
                        <div className='heading-Text font-12 mb-3 d-flex'>
                            Upload Attachments
                            <div className="plus-circle"><FaPlusCircle onClick={this.handleAddAttachmentButton}/></div>
                        </div>
                        {this.state.attachments.map((each, index)=>{
                            return <Attachment AttachmentIndex={index}
                                    AttachmentObj={each}
                                    key={this.state.unique_combine+'attach'+index}
                                    handleUpdateAttachmentList={this.handleUpdateAttachmentList}
                                    handleDeleteAttachmentButton={this.handleDeleteAttachmentButton}
                                    handleSaveAttachmentButton={this.handleSaveAttachmentButton}
                                    />
                        })}
                        </div>:null}
                        </div>
                    </div>
                    {(this.props.isCollapased) ? '':
                    <div className='d-flex w-100 justify-content-end align-items-end mt-4'>
                        <div>
                            <ApproveButton
                                stage_status={this.state.stage_status}
                                IsApproved={this.state.is_approved}
                                WorkflowData={this.state.WorkflowData} 
                                WorkflowComponent={this.state.WorkflowComponent}  
                                DocumentNumber={this.state.DocumentNumber}
                                key={this.state.unique_combine+'aprbtn'}
                                unique_combine = {this.state.unique_combine}
                                current_component_name={this.state.current_component_name}
                                RequestType="moc-sub-task"
                                userComponentPermissions={this.state.userComponentPermissions}
                                userPermissions={this.state.userPermissions}
                                handleOfComment = {this.handleOfComment}/>
                        </div>
                        {this.state.WorkflowSignatures.length > 0 ?
                           <Signed SignatureData={this.state.WorkflowSignatures[0]}/> : ""
                        }
                    </div>}
                    <div className='mt-2'>
                        <Sign IsSignatures={this.state.WorkflowSignatures.length > 0?true:false} 
                        IsApproved={this.state.is_approved}
                        WorkflowData={this.state.WorkflowData} WorkflowComponent={this.state.WorkflowComponent}  
                        DocumentNumber={this.state.DocumentNumber} RequestType="moc-sub-task" 
                        userComponentPermissions={this.state.userComponentPermissions}
                        userPermissions={this.state.userPermissions}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
export default AssigneeReview;