import React, { Component } from 'react';
import "../../Styles/brioStyle.css"
import { API_HOST } from '../../Settings.js'
import axios from 'axios';
import $ from 'jquery';
import notify from '../CommonComponents/Notify';


class TurnAroundTime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            site: [],
            site_name: '',
            document_numbers: [],
            startDate: new Date(),
            fromDate: '2024-01-01',
            toDate: this.getCurrentDateInput(),
        }
    }

    componentDidMount() {
        this.getSiteList();
        this.getDocumentNumbers();
    }



    componentDidUpdate(prevProps, prevState) {
        if (this.state.site_name !== prevState.site_name) {
            this.getDocumentNumbers();
        }
        if (this.state.startDate !== prevState.startDate) {
            this.getDocumentNumbers();
        }
    }
    handleFromDateChange = (event) => {
        const fromDate = event.target.value;
        this.setState({ fromDate }, () => {
            this.getDocumentNumbers();
        });
    };

    handleToDateChange = (event) => {
        const toDate = event.target.value;
        this.setState({ toDate }, () => {
            this.getDocumentNumbers();
        });
    };


    getDocumentNumbers = () => {
        $('.loader').fadeIn()
        var workflow = localStorage.getItem('workflow')
        var site_name = this.state.site_name;
        const from_date_of_implementation = this.state.fromDate;
        const to_date_of_implementation = this.state.toDate;
        axios({
            method: 'get',
            url: `${API_HOST}/document/numbers/?workflow=${workflow}&site_name=${site_name}&from_date_of_implementation=${from_date_of_implementation}&to_date_of_implementation=${to_date_of_implementation}`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem('token')
            }
        }).then((response) => {
            this.setState({ document_numbers: response.data.data });
            if (response.data.lenght > 0) {
                document.querySelector(".document_number").classList.remove('d-none');
                document.querySelector("#submit_turn_around_report").classList.remove('d-none');
            }
            else {
                document.querySelector(".document_number").classList.add('d-none');
                document.querySelector("#submit_turn_around_report").classList.add('d-none');
                notify('No records', 'warning');
            }
            $('.loader').fadeOut('slow')
        }).catch((error) => {
            $('.loader').fadeOut('slow')
            console.error("There was an error fetching the site list!", error);
        });

    }
    handleSiteChange = (e) => {
        this.setState({ "site_name": e.target.value })
    }
    getCurrentDateInput = () => {

        const dateObj = new Date();

        // get the month in this format of 04, the same for months
        const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
        const day = ("0" + dateObj.getDate()).slice(-2);
        const year = dateObj.getFullYear();

        const shortDate = `${year}-${month}-${day}`;

        return shortDate;
    }


    getSiteList = () => {
        axios({
            method: 'get',
            url: `${API_HOST}/sitenames/`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem('token')
            }
        }).then((response) => {
            this.setState({ site: response.data });
        }).catch((error) => {
            console.error("There was an error fetching the site list!", error);
        });
    }

    turn_around_time_report = (event) => {
        event.preventDefault();
        $('.loader').fadeIn('slow');
        if (document.getElementById("document_number").value == '') {
            var document_number = this.state.document_numbers;
        }
        else {
            var document_number = document.getElementById('document_number').value;
        }
        var workflow = localStorage.getItem('workflow')
        const from_date_of_implementation = document.getElementById("from_date_of_implementation").value;
        const to_date_of_implementation = document.getElementById("to_date_of_implementation").value;
        var site_name = this.state.site_name;
        var TatData = {}
        TatData['workflow'] = workflow
        TatData['document_number'] = document_number
        TatData['from_date_of_implementation'] = from_date_of_implementation
        TatData['to_date_of_implementation'] = to_date_of_implementation
        TatData['site_name'] = site_name
        const auth_token = localStorage.getItem("token")
        const requestData = {
            TatData: TatData
        };
        axios.defaults.headers.post['Authorization'] = auth_token
        axios.post(`${API_HOST}/turn/around/time/report/`, requestData)
            .then(response => {
                const blob = new Blob([response.data], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${workflow.toUpperCase()}_${site_name}_(${from_date_of_implementation}"To"${to_date_of_implementation})_turn_around_time_report.csv`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                $('.loader').fadeOut();
            })
            .catch(error => {
                console.error('Error downloading the CSV file', error);
                $('.loader').fadeOut();
            });
    };
    render() {
        const site_names = this.state.site.map((item, key) => {
            return (
                <option key={key} value={item}>{item}</option>
            );
        });
        console.log(site_names)
        const document_numbers = this.state.document_numbers.map((item, key) => {
            return (
                <option key={key} value={item}>{item}</option>
            );
        });
        return (
            <div className='container'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-lg-2 col-md-2 col-sm-6 col-4 mt-1'>
                                <h5 className="card-title mb-3"><b>Turn Around Time</b></h5>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-lg-10'>
                                    <div className='row'>
                                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                                            <label className="form-label" htmlFor="site_name">Site</label>
                                            <select className="form-select" id="site_name" onChange={this.handleSiteChange}>
                                                <option value=''>All</option>
                                                {site_names}
                                            </select>
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                                            <label className="form-label" htmlFor="fromdateImplementation">Date From</label>
                                            <input
                                                autoComplete='on'
                                                defaultValue={this.state.fromDate}
                                                className='form-control'
                                                name="from_date_of_implementation"
                                                id="from_date_of_implementation"
                                                onChange={this.handleFromDateChange}
                                                type="date"
                                            />
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                                            <label className="form-label" htmlFor="todateImplementation">Date To</label>
                                            <input
                                                autoComplete='on'
                                                defaultValue={this.state.toDate}
                                                className='form-control'
                                                name="to_dateImplementation"
                                                id="to_date_of_implementation"
                                                onChange={this.handleToDateChange}
                                                type="date"
                                            />
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-sm-6 col-12 document_number'>
                                            <label className="form-label" htmlFor="document_numbers">Document Number</label>
                                            <select className="form-select" id="document_number">
                                                <option value=''>All</option>
                                                {document_numbers}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-2'>
                                    <div className='row'>
                                        <div className='col-lg-3 col-md-3 col-sm-6 col-12 mt-4'>
                                            <button className="btn btn-dark float-start" type="button" id="submit_turn_around_report" onClick={this.turn_around_time_report}>Download</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TurnAroundTime;