import React, { Component } from 'react';
import $ from 'jquery'
import { API_HOST } from '../../Settings.js'
import axios from 'axios';
import notify from '../CommonComponents/Notify.js';
import '../../Styles/userRoles.css';
import { TfiArrowCircleRight } from "react-icons/tfi";
import { withRouter } from 'react-router-dom';


class WorkFlows extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            can_view_moc: false,
            can_view_manpower: false,
            can_view_ibd: false,
        };
    }
    componentDidMount() {
        this.getWorkflowRolesData()
        var login_user = localStorage.getItem('user_login_type');
        if (login_user === "vendor") {
            this.props.history.push('/invoices')
        } else {
            const hasSinglePermission = localStorage.getItem('workflow_count')
            console.log(hasSinglePermission, 'hasSinglePermission')
            if (hasSinglePermission === 0) {
                const auth_token = localStorage.getItem("token");
                const userData = localStorage.getItem("user_data");
                const logoutEndpoint = '/logout/';
                axios.defaults.headers.get['Authorization'] = auth_token;
                axios.get(API_HOST + logoutEndpoint, {
                    params: {
                        user_data: userData
                    }
                }).then(response => {
                    if (response.status === 200) {
                        localStorage.clear();
                        notify("You don't have any permission, at least one workflow permission is required.", 'error');
                        this.props.history.push("/login")
                        return;
                    }
                });
            }
            debugger
            if (hasSinglePermission === "1") {
                if (localStorage.getItem('can_view_moc')==='true') {
                    this.props.history.push("/dashboard?workflow=moc")
                } else if (localStorage.getItem('can_view_manpower')==='true') {
                    this.props.history.push("/dashboard?workflow=manpower")
                } else if (localStorage.getItem('can_view_ibd')==='true') {
                    this.props.history.push("/invoices?workflow=ibd")
                }
            } else {
                this.props.history.push('/')
            }

            $('.loader').fadeOut('slow');
        }


    }


    getWorkflowRolesData = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token');
        axios
            .get(`${API_HOST}/workflow/roles`)
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        username: response.data.data.user_name,
                        can_view_moc: response.data.data.can_view_moc,
                        can_view_manpower: response.data.data.can_view_manpower,
                        can_view_ibd: response.data.data.can_view_ibd,
                    });

                    $('.loader').fadeOut('slow');
                }
            })
            .catch((error_response) => { });
    };

    render() {
        const {
            can_view_moc,
            can_view_manpower,
            can_view_ibd,
            username,
        } = this.state;


        return (
            <div className="container" style={{ paddingBottom: '250px', paddingTop: '20px' }}>
                <div className="card vendor-card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-6 mt-1">
                                <h5 className="mb-3 pt-3 pb-3 vendor-card-title">
                                    <b>Hello, {username}</b>
                                </h5>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12 d-flex flex-wrap">
                                {can_view_moc ? (
                                    <a href="/dashboard?workflow=moc" className="m-2">
                                        <button className="vendor-management-btn p-0">
                                            <span className="vendor-management-btn-text">MOC</span>
                                            <span className="vendor-management-btn-icon">
                                                <TfiArrowCircleRight />
                                            </span>
                                        </button>
                                    </a>
                                ) : null}
                                {can_view_manpower ? (
                                    <a href="/dashboard?workflow=manpower" className="m-2">
                                        <button className="vendor-management-btn p-0">
                                            <span className="vendor-management-btn-text">Manpower Change</span>
                                            <span className="vendor-management-btn-icon">
                                                <TfiArrowCircleRight />
                                            </span>
                                        </button>
                                    </a>
                                ) : null}

                                {can_view_ibd ? (
                                    <a href="/invoices?workflow=ibd" className="m-2">
                                        <button className="vendor-management-btn p-0">
                                            <span className="vendor-management-btn-text">IBD Vendor Management</span>
                                            <span className="vendor-management-btn-icon">
                                                <TfiArrowCircleRight />
                                            </span>
                                        </button>
                                    </a>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(WorkFlows);
