import React, { Component } from 'react';
import Toggle from '../CommonComponents/Toggle';
// import { AssessmentRowNames } from './MiscellaneousObject';

class AssessmentTableRow extends Component{
    constructor(props){
        super(props)
    }
    render(){
        var unique_combine = this.props.unique_combine
        var tr_content = this.props.assessmentData.map((assessmentRow,mainIndex) => {
            let tr_row = assessmentRow.rows.map((step,stepIndex)=>{ 
                var unique_fixit = unique_combine+'_asses_'+mainIndex+'_it_'+stepIndex+'_fixit'
                return(
                        <tr key={stepIndex}>
                            <td>{step.name}</td>
                            <td>
                                <Toggle onChangeHandler = {this.props.onChangeHandler} 
                                    category={assessmentRow.header.name} 
                                    step={step.name_slug} category_type="assessment_management" 
                                    categoryIndex={mainIndex} stepIndex={stepIndex}
                                    accessType={this.props.accessType}
                                    status={step.status}/>
                            </td>
                            <td>
                                <textarea className='form-control' rows="2" 
                                    name={step.name_slug}
                                    // id={step.name_slug}
                                    id={unique_fixit}
                                    type="text" placeholder='type here....' 
                                    readOnly={this.props.accessType == "view" ? true:false}
                                    onChange={(e) => this.props.onChangeHandler(e,'assessment_management', assessmentRow.header, step.name_slug, step.status, mainIndex, stepIndex, e.target.value)}
                                    value={step.remarks}>
                                {step.remarks}
                                </textarea>
                            </td>
                        </tr>        
                )
            })
            return(
                [
                    <tr span="3" key={mainIndex}>
                        <td><b>{assessmentRow.header.name}</b></td>
                    </tr>,
                    tr_row
                ]
            )
        })
        return(
            tr_content
        )
    }
}

export default AssessmentTableRow;