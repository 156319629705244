import React, { Component, createRef } from "react";
import ApproveButton from "../ConsentComponents/ApproveButton";
import AskButton from "../ConsentComponents/AskButton";
import Comment from "../ConsentComponents/Comment";
import RejectButton from "../ConsentComponents/RejectButton";
import Sign from "../ConsentComponents/Sign";
import axios from 'axios';
import { API_HOST } from '../../Settings';
import $ from 'jquery';
import notify from '../CommonComponents/Notify';
import Signed from "../ConsentComponents/Signed";
import Attachment from "../ConsentComponents/Attachment";
import ViewAttachments from "../ConsentComponents/ViewAttachments";
import { FaPlusCircle } from "react-icons/fa";
class ReviewAndCommentsHODReview extends Component {
    constructor(props){
        super(props)
        this.commentRef = createRef();
        this.handleOfComment = this.handleOfComment.bind(this)
        this.state = {
            is_approved:false,
            is_rejected:false,
            WorkflowData:this.props.WorkflowData,
            WorkflowComponent:this.props.WorkflowComponent,
            DocumentNumber:this.props.DocumentNumber,
            WorkflowSignatures:[],
            unique_stage_id: '2',
            unique_comp_id: '1',
            unique_combine: 's2c1',
            stage_status:this.props.stage_status,
            ask_for_more_history: [],
            current_component_name: "ReviewAndCommentsHODReview",
            userPermissions: {},
            form_data: {
                "have_you_review":'',
                "approximate_cost":null
            },
            isDataSubmited: true,
            attachments:[
                {
                    attachment: "",
                    filename: "",
                    file_size: 0,
                    signed_url:"",
                    attachment_filename: ""
                }
            ],
            WorkflowAttachments:[],
        }
    }

    componentDidMount(){
        this.getUserPrivileges()
        if(this.props.isCollapased==false){
            axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
            axios.get(`${API_HOST}/workflow/moc/request/${this.props.DocumentNumber}/stage/${this.props.StageName}/`, )
            .then(response => {            
                if (response.status === 200) {
                    this.setState({
                        WorkflowData:response.data.data.workflow_stage_info,
                        WorkflowComponent:response.data.data.workflow_stage_info.components[0],
                        enable_signing:response.data.data.workflow_stage_info.components[0].enable_signing,
                        enable_approval:response.data.data.workflow_stage_info.components[0].enable_approval,
                        WorkflowSignatures:response.data.data.workflow_stage_info.components[0].workflow_signatures,
                        is_approved:response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                            response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Approved" ? true : false,
                        is_rejected:response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                            response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false,
                        ask_for_more_history:response.data.data.ask_for_more_history,
                        // userComponentPermissions: response.data.data.workflow_stage_info.components[0].user_component_permissions
                    })
                    this.getFormData()
                }

            }).catch(response => {
                // ErrorHandling(response)
            });
            $('.card-loader').fadeOut('slow')
        }
        this.getAllData()  
    }
    getAllData=()=>{
        $('.card-loader').fadeIn()
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/workflow/moc/request/${this.props.DocumentNumber}/stage/${this.props.StageName}/?component_name=${this.props.ComponentName}`, )
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    WorkflowData:response.data.data.workflow_stage_info,
                    WorkflowComponent:response.data.data.workflow_stage_info.components[0],
                    WorkflowSignatures:response.data.data.workflow_stage_info.components[0].workflow_signatures,
                    // enable_signing:response.data.data.workflow_stage_info.components[0].enable_signing,
                    // enable_approval:response.data.data.workflow_stage_info.components[0].enable_approval,
                    is_approved: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                        response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Approved" ? true : false,
                    WorkflowAttachments: response.data.data.workflow_stage_info.components[0].workflow_attachments,
                    is_rejected: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                        response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false,
                    // show_plant_moc_opinion_card: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                    //     response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false,
                    // plant_moc_opinion: response.data.data.workflow_stage_info.components[0].wf_plant_moc_lead_review[0] ?
                    //     response.data.data.workflow_stage_info.components[0].wf_plant_moc_lead_review[0].action.name : "",
                    ask_for_more_history:response.data.data.ask_for_more_history,
                    // userComponentPermissions: response.data.data.workflow_stage_info.components[0].user_component_permissions
                })
                // this.renderDynamicComponent()
            }

        }).catch(response => {
            // ErrorHandling(response)
        });
        $('.card-loader').fadeOut('slow')
    }
    handleUpdateAttachmentList = (attachment_obj, idx)=>{
        var attachments_list = this.state.attachments
        attachments_list[idx] = attachment_obj;
        this.setState({attachments: attachments_list})

    };
    handleAddAttachmentButton = (e) =>{
        let attachment_obj = {
            attachment: "",
            filename: "",
            file_size: 0,
            signed_url:"",
            attachment_filename: ""
        }
        var attachments_list = this.state.attachments
        attachments_list.push(attachment_obj);
        this.setState({attachments: attachments_list})
    };
    handleDeleteAttachmentButton = (e, idx) =>{
        var attachments_list = this.state.attachments
        attachments_list.splice(idx, 1);
        this.setState({attachments: attachments_list})
    }

    attachmentSubmitHandler = (e, attachment_obj, idx) => {
        e.preventDefault()
        var attachment_data = {
            model:"workflow_attachments",
            data:{
                    workflow_definition_id:this.state.WorkflowData.workflow_definition_id,
                    stage_id:this.state.WorkflowData.id,
                    component_id:this.state.WorkflowComponent.id,
                    file_name:attachment_obj.attachment_filename
                }
        }
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
        axios.post(`${API_HOST}/workflow/moc-request/request/${this.props.DocumentNumber}/form/data/save/`, attachment_data)
        .then(response => {
            if (response.status === 200) {
                notify('Attachment saved successfully','success')
                var attachment_data = response.data.data.workflow_attachments
                var attachment_obj = {
                    attachment_filename:attachment_data.filename,
                    signed_url:attachment_data.singed_url
                }
                let attachments = this.state.WorkflowAttachments
                attachments.push(attachment_data)
                this.setState({WorkflowAttachments: attachments})
                this.handleUpdateAttachmentList(attachment_obj, idx)
            }

        }).catch(response => {
            // ErrorHandling(response)
        });
        $('.card-loader').fadeOut('slow')
    }

    removeWfAttachment =(event, id_of_wf_attachment, component_id)=>{
        event.preventDefault()
        var user_resp = window.confirm("Are you sure, you want to delete the attachment?")
        if(user_resp){
            $('.card-loader').fadeIn('')
            var attachment_data = {
                workflow_definition_id:this.state.WorkflowData.workflow_definition_id,
                stage_id:this.state.WorkflowData.id,
                component_id:this.state.WorkflowComponent.id,
                wf_attachment_id:id_of_wf_attachment
            }
            axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
            axios.post(`${API_HOST}/workflow/moc-request/request/${this.props.DocumentNumber}/attachment/delete/`, attachment_data)
            .then(response => {
                if (response.status === 200) {
                    notify('Attachment deleted successfully','success')
                    this.getAllData()
                }
            }).catch(response => {
                // ErrorHandling(response)
                $('.card-loader').fadeOut('slow')
            });
        }
        
    }

    handleSaveAttachmentButton = (event, attachment_obj, idx) =>  {
      event.preventDefault();
      if(attachment_obj.attachment){
        $('.loader').fadeIn()
        let form_data_django = new FormData();
        form_data_django.append('attachment', attachment_obj.attachment);
        form_data_django.append('size', attachment_obj.file_size);
        let url = `${API_HOST}/upload/file/in/azure/blob/storage`;
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')
        axios.post(url,form_data_django,
          ).then(response=>{
              if(response.data.success){
                  // notify("File successfully uploaded",'information')
                $('.loader').fadeOut('slow')
                  var attachment_obj = {
                      attachment_filename:response.data.filename,
                      signed_url:response.data.singed_url
                  }
                  this.attachmentSubmitHandler(event, attachment_obj, idx)
              }else{
                  notify(response.data.message,'error')
                  $('.card-loader').fadeOut('slow')
              }

          }).catch(error_xlsx => {

          })

      }else{
          alert("Invalid files selected")
    }
};


    getFormData = ()=>{
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/and/save/moc/stage2/${this.props.DocumentNumber}/data/`,)
            .then(response => {
                if (response.status === 200 ) {
                    if(response.data.is_submited){
                        this.setState({
                            "isDataSubmited": true,
                            "form_data":{"have_you_review":response.data.have_you_review,
                                "approximate_cost":response.data.approximate_cost
                            }
                        })
                    }else{
                        this.setState({"isDataSubmited": false})
                    }
                }
            }).catch(response => {
                // ErrorHandling(response)
            });
    }

    getUserPrivileges = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        let privileges = JSON.stringify(['can_approve', 'can_reject', 'can_ask_for_more_information', 'can_sign', 'can_comment', 'can_create','can_attach'])
        let component = this.state.current_component_name
        let params = {"document_number": this.state.DocumentNumber, "from_new": true, "request_type": "moc_request"}
        axios.get(`${API_HOST}/user/privileges/${component}/${privileges}/`, {params: params} )
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    userPermissions:response.data.data,
                })

            }
        }).catch(response => {
            // ErrorHandling(response)
        });
    }

    checkIsApproved = (is_approved) =>{
        this.setState({
            is_approved:is_approved
        })
    }

    checkIsRejected = (is_rejected) =>{
        this.setState({
            is_rejected:is_rejected
        })

    }

    handleHaveYouReview = (e, value)=>{
        var form_data = this.state.form_data
        if(['YES', 'NO'].includes(value)){
            form_data['have_you_review'] = value=='YES'? true : false
            this.setState({form_data})
        }
    }

    handleAppoximateCost = (e)=>{
        if(e.currentTarget.value == ""){e.currentTarget.classList.add("error")}
        if(e.currentTarget.value && e.currentTarget.classList.contains("error")){e.currentTarget.classList.remove("error")}
        var form_data = this.state.form_data
        form_data['approximate_cost'] = e.target.value
        this.setState(form_data)
    }

    isValid = ()=>{
        var name_regexp = new RegExp('^[0-9]+$');
        if(![true, false].includes(this.state.form_data.have_you_review)){
            return {"status":false, "reason":"Please select have you review field"}
        }
        var approximate_cost = this.state.form_data.approximate_cost
        if(!approximate_cost){
            document.querySelector("#approximate_cost").classList.add("error")
            return {"status":false, "reason":"Please fill approximate cost of change"}
        }
        return {"status": true}
    }

    handleOfComment(e, stop_loader, method){
        var response_isvalid = this.commentRef.current.isValid()
        if(!response_isvalid.status){
            if(method == "approve"){
                if(response_isvalid.custom_error_code!=1){
                    // if custom_error_code==1  we can process approval without comment saving
                    notify(response_isvalid.reason,'warning')
                    return false
                }
            }else{
                if(response_isvalid.custom_error_code==1){
                    return false
                }
                if(response_isvalid.custom_error_code==2){
                    // if custom_error_code==1  we can process approval without comment saving
                    notify(response_isvalid.reason,'warning')
                    return false
                }
            }
        }else{
            this.commentRef.current.commentSubmitHandler(e, stop_loader)
            return true
        }
        return true
    }
    handleKeyPressForNumbersOnly = (event) => {
        const charCode = event.charCode;
        const value = event.target.value;
        if ((charCode < 48 || charCode > 57) &&charCode != 46) {
            event.preventDefault();
        }
        if (charCode === 46 && value.includes('.')) {
            event.preventDefault();
        }
        const dotIndex = value.indexOf('.');
        if (dotIndex !== -1 && value.substring(dotIndex + 1).length >= 2 && charCode !== 8) {
            event.preventDefault();
        }
    }
    onSubmitHandler = (e) =>{
        e.preventDefault()
        var is_valid_response = this.isValid()
        if(!is_valid_response.status){
            notify(is_valid_response.reason, 'warning')
            return false
        }
        var form_data = {}
        var have_you_review = this.state.form_data.have_you_review
        var approximate_cost = this.state.form_data.approximate_cost
        form_data['have_you_review'] = have_you_review
        form_data['approximate_cost'] = approximate_cost
        if (true) {
            $('.loader').fadeIn()
            const auth_token = localStorage.getItem("token")
            axios.defaults.headers.post['Authorization'] = auth_token
            axios.post(`${API_HOST}/get/and/save/moc/stage2/${this.state.DocumentNumber}/data/`, form_data)
            .then(response => {
                if (response.status === 200) {
                    notify(response.data.message,'success')
                    $('.loader').fadeOut('slow')
                    this.setState({isDataSubmited:true})
                }else{

                }
            }).catch(response => {
                if(response.response.status == 404){
                    notify(response.response.data.message,'warning')
                    $('.loader').fadeOut('slow')
                    this.setState({isDataSubmited:false})
                }
            });
        }
    }

    render(){
        var access_type = "view"
        var ask_for_more_history_filtered = []
        if(this.state){
            if(this.state.hasOwnProperty('ask_for_more_history')){
                ask_for_more_history_filtered = this.state.ask_for_more_history.filter((each, index)=>{
                    return each.status.name_slug=="open"
                })
            }
        }
        if(this.state.ask_more_stage_name_slug != "" && !(['execution-hod-review', 'hod-of-safety-review', 'hod-of-qa-review', 'closure-by-moc-lead'].includes(this.state.ask_more_stage_name_slug))){
            if(ask_for_more_history_filtered.length>0){
                access_type  ="update"
            }
        }
        if(this.state.userPermissions.can_attach && !this.state.is_approved && !this.state.is_rejected){
            access_type = 'create'
        }
        var form_submition_info = {"form_submition_required":true, "is_required_forms_data_submitted": this.state.isDataSubmited}
        return(
            <div className='card mb-3' id="ReviewAndCommentsHODReview">
            <div className='p-4'>
                <div className='heading-Text font-16'> Initiator HOD Approval</div>
                {true ?
                    <div className="mt-4 row">
                        <div>
                            <p><b>Have you review all the information regarding changes?</b></p>
                            <div className="form-check form-check-inline">
                                {["create", "update"].includes(access_type) && (!this.state.isDataSubmited && this.state.userPermissions.can_create) ?
                                   <input className="form-check-input" type="radio"
                                        name="have_you_review" id="have_you_review_yes"
                                        checked={[true, false].includes(this.state.form_data.have_you_review)?this.state.form_data.have_you_review==true:false}
                                        value={true} onChange={(e) => this.handleHaveYouReview(e, "YES")}/>:
                                    <input className="form-check-input" type="radio"
                                        name="have_you_review" id="have_you_review_yes"
                                        checked={[true, false].includes(this.state.form_data.have_you_review)?this.state.form_data.have_you_review==true:false}
                                        disabled={true}
                                        value={true} />
                                }
                                <label className="form-check-label" htmlFor="have_you_review_yes">Yes</label>
                            </div>
                            <div className="form-check form-check-inline">
                                {["create", "update"].includes(access_type) && (!this.state.isDataSubmited && this.state.userPermissions.can_create) ?
                                    <input className="form-check-input" type="radio"
                                        name="have_you_review" id="have_you_review_no"
                                        checked={[true, false].includes(this.state.form_data.have_you_review)?this.state.form_data.have_you_review==false:false}
                                        value={false} onChange={(e) => this.handleHaveYouReview(e, "NO")} />:
                                    <input className="form-check-input" type="radio"
                                        name="have_you_review" id="have_you_review_no"
                                        disabled={true}
                                        checked={[true, false].includes(this.state.form_data.have_you_review)?this.state.form_data.have_you_review==false:false}
                                        value={false} />
                                }
                                <label className="form-check-label" htmlFor="have_you_review_no">No</label>
                            </div>
                            <div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <label className="form-label" htmlFor="approximate_cost">Approximate cost of change *</label>
                                {["create", "update"].includes(access_type) && !this.state.isDataSubmited ?
                                    <div className='form-text-box'>
                                        <span className='input-group-text' id='rupeeSymbol'>
                                            ₹
                                        </span>
                                        <input className='form-control' name="approximate_cost"
                                            id="approximate_cost" type="text"
                                            onChange={this.handleAppoximateCost}
                                            value={this.state.form_data.approximate_cost}
                                            onKeyPress={this.handleKeyPressForNumbersOnly}/>
                                    </div> :
                                       <div className='form-text-box'>
                                            <span className='input-group-text' id='rupeeSymbol'>
                                                ₹
                                            </span>
                                            <input className='form-control' name="approximate_cost"
                                                id="approximate_cost" type="text"
                                                value={this.state.form_data.approximate_cost}/>
                                        </div>
                                }
                            </div>
                            </div>
                            {!this.state.userPermissions.can_create || this.state.isDataSubmited||['approved', 'rejected'].includes(this.state.stage_status)||this.state.is_approved?"":
                            <div className='row'>
                                <div className='col-12 mt-2 text-right'>
                                    <button type="button" className="btn btn-primary" onClick={this.onSubmitHandler} >Submit</button>
                                </div>
                            </div>}
                        </div>
                    </div>:null
                }
                &nbsp;
                <div>
                    <p><b>Attachments</b></p>
                    { ask_for_more_history_filtered.length>0 || this.state.WorkflowAttachments.length > 0 ?
                        <ViewAttachments removeWfAttachment={this.removeWfAttachment}
                        key={this.state.unique_combine+'va'}
                        enable_attach_delete={['create'].includes(access_type)}
                         WorkflowAttachments={this.state.WorkflowAttachments} />
                        :((this.state.is_approved === true || this.state.is_rejected === true)  ? 'No Attachments' : '') }
                </div>
                {this.state.userPermissions.can_attach && !(this.state.is_approved || this.state.is_rejected) && !['approved', 'rejected'].includes(this.state.stage_status)?
                <div>
                    <div className='heading-Text font-12 mb-3 d-flex'>
                    Upload Attachments
                        <div className="plus-circle"><FaPlusCircle onClick={this.handleAddAttachmentButton}/></div>
                    </div>
                    {this.state.attachments.map((each, index)=>{
                        return <Attachment AttachmentIndex={index}
                                AttachmentObj={each}
                                key={this.state.unique_combine+'attach'+index}
                                handleUpdateAttachmentList={this.handleUpdateAttachmentList}
                                handleDeleteAttachmentButton={this.handleDeleteAttachmentButton}
                                handleSaveAttachmentButton={this.handleSaveAttachmentButton}
                                />
                    })}
                </div>:null}
                <div className='mt-4 mb-3 row'>
                    <Comment key={this.state.unique_combine+'cobj'}
                        stage_status={this.state.stage_status}
                        unique_combine={this.state.unique_combine}
                        is_rejected={this.state.is_rejected}
                        required_moc_lead={false}
                        is_approved={this.state.is_approved}
                        ref={this.commentRef}
                        WorkflowData={this.state.WorkflowData}
                        WorkflowComponent={this.state.WorkflowComponent}  
                        DocumentNumber={this.state.DocumentNumber} RequestType="moc-request" userPermissions={this.state.userPermissions}/>
                    <div className="col-lg-2 col-md-6 col-sm-4 col-4"></div>
                    {this.state.WorkflowSignatures.length > 0?<Signed SignatureData={this.state.WorkflowSignatures[0]}/>:""}
                </div>

                
                <div className='d-flex w-100 justify-content-end align-items-end tri-buttons mt-4'>
                    <div className='pe-2'>
                        <AskButton 
                            IsApproved={this.state.is_approved}
                            stage_status={this.state.stage_status}
                            key={this.state.unique_combine+'askbtn'}
                            unique_combine = {this.state.unique_combine}
                            WorkflowData={this.state.WorkflowData} 
                            WorkflowComponent={this.state.WorkflowComponent}  
                            DocumentNumber={this.state.DocumentNumber}
                            current_component_name={this.state.current_component_name}
                            ask_for_more_history={this.state.ask_for_more_history}
                            RequestType="moc-request" userPermissions={this.state.userPermissions}
                            handleOfComment = {this.handleOfComment}/>
                    </div>
                    <div className='pe-2'>
                        <RejectButton
                            stage_status={this.state.stage_status}
                            IsApproved={this.state.is_approved}
                            WorkflowData={this.state.WorkflowData} 
                            WorkflowComponent={this.state.WorkflowComponent}  
                            DocumentNumber={this.state.DocumentNumber}
                            key={this.state.unique_combine+'rejbtn'}
                            unique_combine = {this.state.unique_combine}
                            checkIsRejected = {this.checkIsRejected}
                            current_component_name={this.state.current_component_name}
                            ask_for_more_history={this.state.ask_for_more_history}
                            RequestType="moc-request"
                            userPermissions={this.state.userPermissions}
                            handleOfComment = {this.handleOfComment}
                        />
                    </div>
                    <div>
                        <ApproveButton
                            stage_status={this.state.stage_status}
                            IsApproved={this.state.is_approved}
                            WorkflowData={this.state.WorkflowData} 
                            WorkflowComponent={this.state.WorkflowComponent}  
                            DocumentNumber={this.state.DocumentNumber}
                            key={this.state.unique_combine+'aprbtn'}
                            unique_combine = {this.state.unique_combine}
                            current_component_name={this.state.current_component_name}
                            ask_for_more_history={this.state.ask_for_more_history}
                            RequestType="moc-request"
                            handleOfComment = {this.handleOfComment}
                            form_submition_info={form_submition_info}
                            userPermissions={this.state.userPermissions}/>
                    </div>
                </div>
                <div className='mt-2'>
                    <Sign IsSignatures={this.state.WorkflowSignatures.length > 0?true:false} 
                    IsApproved={this.state.is_approved}
                    key={this.state.unique_combine+'signbtn'}
                    unique_combine = {this.state.unique_combine}
                    WorkflowData={this.state.WorkflowData} WorkflowComponent={this.state.WorkflowComponent}  
                    DocumentNumber={this.state.DocumentNumber} RequestType="moc-request" 
                    userPermissions={this.state.userPermissions}
                    current_component_name={this.state.current_component_name}
                    />
                </div>
            </div>
        </div>
        )
    }
}

export default ReviewAndCommentsHODReview;