import React, { Component } from "react";
import axios from 'axios';
import { API_HOST } from '../../Settings';
import $ from 'jquery';
import notify from './Notify';


class RejectComponent extends Component{
	constructor(props){
		super(props)
		this.state = {
			isRejected: this.props.isRejected ? this.props.isRejected:false
		}
	}

	static getDerivedStateFromProps(props, state) {
        if(props.isRejected !== state.isRejected){
            //Change in props
            return{
                isRejected: props.isRejected?props.isRejected:false
            };
        }
        return null; // No change to state
    }

	// componentDidMount(){
	// 	if(this.props.isCollapased){
    //         axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
    //         axios.get(`${API_HOST}/workflow/moc/request/${this.props.DocumentNumber}/stage/${this.props.StageName}/?component_name=${this.props.ComponentName}`, )
    //         .then(response => {
    //             if (response.status === 200) {
    //                 var workflow_action_logs = response.data.data.workflow_stage_info.components[0].workflow_action_logs
    //                 var wf_plant_moc_lead_review = response.data.data.workflow_stage_info.components[0].wf_plant_moc_lead_review
    //                 var ui_action_controls = {
    //                     'ask_for_more': 'show', 'approve': 'show', 'reject': 'show',
    //                     "temp_comp_action_log_status": ''
    //                 }
    //                 if(workflow_action_logs.length>0){
    //                     ui_action_controls = {
    //                             'ask_for_more': 'hide', 'approve': 'hide', 'reject': 'hide',
    //                             "temp_comp_action_log_status": workflow_action_logs[0].status
    //                         }
    //                 }
    //                 var show_plant_moc_actions = false
    //                 var show_plan_moc_opinion_card = false
    //                 var plant_moc_action_controls = {'iagree': 'hide', 'dontagree': 'hide'}
    //                 if( workflow_action_logs.length>0 &&
    //                     workflow_action_logs[0].status=='Rejected' &&
    //                     wf_plant_moc_lead_review.length==0
    //                     ){
    //                     show_plant_moc_actions = true
    //                     plant_moc_action_controls = {'iagree': 'show', 'dontagree': 'show'}
    //                     show_plan_moc_opinion_card = true
    //                 }

    //                 var plant_moc_opinion_status = ''
    //                 if(wf_plant_moc_lead_review.length>0){
    //                     plant_moc_opinion_status = wf_plant_moc_lead_review[0]['action']['name']
    //                     show_plan_moc_opinion_card = true

    //                 }
    //                 this.setState({
    //                     ui_action_controls: ui_action_controls,
    //                     show_plant_moc_actions: show_plant_moc_actions,
    //                     plant_moc_action_controls: plant_moc_action_controls,
    //                     wf_plant_moc_lead_review: wf_plant_moc_lead_review,
    //                     plant_moc_opinion_status: plant_moc_opinion_status,
    //                     show_plan_moc_opinion_card: show_plan_moc_opinion_card
    //                 })
    //                 // this.renderDynamicComponent()
    //             }

    //         }).catch(response => {
    //             // ErrorHandling(response)
    //         });
    //         $('.card-loader').fadeOut('slow')
    //     }
	// }

	handlePlantLeadSubmit = (event, status_value)=>{
        event.preventDefault()
        var messgae = ''
        if(status_value=='reject'){
            messgae = "Are you sure, you want reject this request?"
        }
        else if(status_value=='approve'){
            messgae = 'Are you sure, you want approve this request?'
        }
        if(window.confirm(messgae)){
            $('.loader').fadeIn()
            var wf_action_obj = this.props.WorkflowComponent.workflow_actions.filter((wf_action, indx)=>{
                if(status_value=='approve'){
                    return wf_action.action.name_slug == 'approved'
                }else if(status_value=='reject'){
                    return wf_action.action.name_slug == 'rejected'
                }
                
            })
            var wf_action_log_obj = this.props.WorkflowComponent.workflow_action_logs.filter((wf_action_log, indx)=>{
                
                 return wf_action_log.status == 'Rejected'
            })
            var form_data = {            
                workflow_action_log_id:wf_action_log_obj[0].id,
                action_id:wf_action_obj[0].action.id 
            }        
            axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
            axios.post(`${API_HOST}/workflow/${this.props.RequestType}/request/${this.props.DocumentNumber}/plant-moc-lead-opinion/action/save/`, form_data)
            .then(response => {
                if (response.status === 200){
                    localStorage.setItem('current_component_name', this.props.current_component_name);
                    notify('Action completed successfully','success')
                    setTimeout(()=>{
                        $('.loader').fadeOut('slow')
                        window.location.reload();
                    }, 4000)
                }else{notify('Somethig wrong', 'warning')}
            })
            .catch(response => {
            })
        }
    }

	render(){
		return(
			<div>
				{this.props.userPermissions.can_review?this.state.isRejected && this.props.CurrentStageStatus != 'rejected' && !this.props.WorkflowComponent.wf_plant_moc_lead_review.length > 0 ?
				    <div className="card p-3 mt-4">
                        <div className='p-4'>
                            <div className='mt-4 row'>
        				    	<div>Department Head Review <b>Rejected</b></div>
        				        <div>
        				            <b>Plant MoC-Lead Opinion {this.state.plant_moc_opinion_status}</b>
        				        </div>
        			            <div className='mt-4'>
        			                <div className='d-flex w-100 justify-content-start mt-2'>
        		                        <div className='pe-2'>
        		                            <button className="btn btn-secondary" type="button" onClick={event =>this.handlePlantLeadSubmit(event, 'approve')} >
        		                                I agree(Approve)
        		                            </button>
        		                        </div>
        		                        <div>
        		                            <button className="btn btn-primary" type="button" onClick={event =>this.handlePlantLeadSubmit(event, 'reject')}>
        		                                Not agree (Reject)
        		                            </button>
        		                        </div>
        			                </div>
        			            </div>
        				    </div>
                        </div>
                    </div>
				    :null
                    :null
				}
                {this.props.CurrentStageStatus == 'rejected' ?
                    <div>
                        <div className='mt-4 row'>
                            <div>Plant MoC-Lead <b>Rejected</b></div>
                        </div>
                    </div>
                 : null}
			</div>
		)
	}
}

export default RejectComponent