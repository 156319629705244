import React, { Component } from 'react';

class UploadDocument extends Component {
    render() {
        return (
            <div className='row'>
                                <div className='col-12'>
                                    <p>Customer Feedback Document</p>
                                    <div className="file-upload-wrapper d-flex justify-content-center align-items-center">                
                                        <div className="form-group files" data-title="Drag & Drop Files to Upload" id="drag-drop-file-div-xlsx">
                                            <label id="drag-drop-file-label">
                                            <input type="file"  name="new_batch_file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" className="form-0 custom-file-input" id="drag-drop-file-upload-xlsx"  required/>
                                            </label>
                                            <p>Supported Files: .pdf,.docx, .xlsx, .dwg, .jpg, .png</p>
                                        </div>
                                    </div>
                                </div>                              
                    </div>
        )
    }
}

export default UploadDocument;