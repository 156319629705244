import React, { Component } from 'react';
import $ from 'jquery'
import { API_HOST } from "../../Settings"
import axios from 'axios';
import VendorPageHeader from './VendorPageHeader.js';
import InvoiceLeftStatus from './InvoiceListLeftStatus.js';
import InvoiceListRequest from './InvoiceListRequest.js';
import notify from '../CommonComponents/Notify';
import { withRouter } from 'react-router-dom';



class InvoiceList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tab: "all",
            filterRequestData: [],
            invoiceListData: [],
            is_admin: false,
            invoice_url: '',
            reference_number: '',
            total_include_gst_amount: '',
            page:'1',
            limit:'10',
            total_records:'',

        }
    }
    componentDidMount() {
        const notificationBlock = document.querySelector(".notification-Block");
        const userProfile = document.querySelector(".user-profile");

        if (notificationBlock) {
            notificationBlock.classList.add("d-none");
        }

        if (userProfile) {
            userProfile.classList.add("d-none");
        }

        axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        const roles = JSON.parse(localStorage.getItem('roles'));
        const userLoginType = localStorage.getItem('user_login_type');

        if (userLoginType === 'rallis') {
            if (roles && (roles.length === 0 || !roles.includes("view-ibd"))) {
                const auth_token = localStorage.getItem("token");
                const userData = localStorage.getItem("user_data");
                const logoutEndpoint = '/logout/';

                axios.defaults.headers.common['Authorization'] = auth_token;

                axios.get(API_HOST + logoutEndpoint, {
                    params: {
                        user_data: userData
                    }
                }).then(response => {
                    if (response.status === 200) {
                        localStorage.clear();
                        notify("You don't have permission to view.", 'error');
                        this.props.history.push("/login");
                        return;
                    }
                });
            } else {
                this.setState({ invoice_url: 'invoices' }, () => {
                    const urlParams = new URLSearchParams(window.location.search);
                    const filter = urlParams.get('filter');
                    const page = urlParams.get('page')
                    if (filter === 'true') {
                        this.setState({ page:page}, () => {
                            this.handleInvoiceFilterData();
                        })
                        urlParams.delete('page');
                        urlParams.delete('filter');
                        const newUrl = urlParams.toString() ? `${window.location.pathname}?${urlParams.toString()}` : window.location.pathname;
                        window.history.replaceState(null, '', newUrl);
                       
                    } else {
                        localStorage.removeItem('filterKeys');
                        this.fetchInvoiceData(); 
                    }
                });
            }

        }
        else {

            this.setState({ invoice_url: 'invoices' }, () => {
                const urlParams = new URLSearchParams(window.location.search);
                const filter = urlParams.get('filter');
                const page = urlParams.get('page')
                if (filter === 'true') {
                    this.setState({ page:page}, () => {
                        this.handleInvoiceFilterData();
                    })
                    urlParams.delete('page');
                    urlParams.delete('filter');
                        const newUrl = urlParams.toString() ? `${window.location.pathname}?${urlParams.toString()}` : window.location.pathname;
                        window.history.replaceState(null, '', newUrl);
                } else {
                    localStorage.removeItem('filterKeys');
                    this.fetchInvoiceData(); 
                }
            });

        }

    }

    updatePagination = (page, limit) => {
        this.setState({ page, limit }, () => {
            this.handleFilterData();
        })
    };

    componentDidUpdate() {
        const urlParams = new URLSearchParams(window.location.search);
        const workflow = urlParams.get('workflow');
        if (workflow) {
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    }

    fetchInvoiceData() {
        var userData = localStorage.getItem('user_data');
        axios.get(`${API_HOST}/${this.state.invoice_url}`, {
            params: {
                user_data: userData
            }
        })
            .then(response => {
                console.log(response.data.invoice_data, 'invoice dataa..', response.data.invoice_total_exclude_gst_amount, 'invoice_total_exclude_gst_amount', response.data.invoice_total_include_gst_amount, 'invoice_total_include_gst_amount');
                if (response.status === 200) {
                    this.setState({
                        invoiceListData: response.data.invoice_data,
                        filterRequestData: response.data.invoice_data,
                        reference_number:response.data.reference_number,
                        total_include_gst_amount: response.data.invoice_total_include_gst_amount,
                        page:response.data.page,
                        limit:response.data.limit,
                        total_records:response.data.total_records,

                    });
                    $('.loader').fadeOut('slow');
                }
            })
            .catch(response => {
                if (response.response.status === 401) {
                    localStorage.clear();
                }
                $('.loader').fadeOut('slow');
                // ErrorHandling(response)
            });
    }

    calInvoiceTotalValue(calTotalValue) {
        // let invoice_total_exclude_gst_amount = 0;
        let invoice_total_include_gst_amount = 0;
    
        calTotalValue.forEach((count, index) => {
            // const excludeValue = parseFloat(count.invoice_total_value_exclude_gst, 10);
            const includeValue = parseFloat(count.invoice_total_value_include_gst, 10);
            // if (!isNaN(excludeValue)) {
            //     invoice_total_exclude_gst_amount += excludeValue;
            // }
            if (!isNaN(includeValue)) {
                invoice_total_include_gst_amount += includeValue;
            }
        });
            this.setState({
            // total_exclude_gst_amount: parseFloat(invoice_total_exclude_gst_amount),
            total_include_gst_amount: parseFloat(invoice_total_include_gst_amount)
        });
    }
    

    changeTabHandler = (e, tab) => {
        e.preventDefault();
        if (tab === "all") {
            this.setState({
                filterRequestData: this.state.invoiceListData,
                tab: "all"
            });
            this.calInvoiceTotalValue(this.state.invoiceListData)
        } else if (tab === 'pending') {
            var requestData = this.state.invoiceListData.filter((requestObj, index) => {
                return requestObj.status.toLowerCase() === "pending";
            });
            this.calInvoiceTotalValue(requestData)
            this.setState({
                filterRequestData: requestData,
                tab: "pending"
            });

        } else if (tab === 'rejected') {
            var requestDataRej = this.state.invoiceListData.filter((requestObj, index) => {
                return requestObj.status.toLowerCase() === "rejected";
            });
            this.calInvoiceTotalValue(requestDataRej)
            this.setState({
                filterRequestData: requestDataRej,
                tab: "rejected"
            });
        } else if (tab === 'processed') {
            var requestDataPrcd = this.state.invoiceListData.filter((requestObj, index) => {
                return requestObj.status.toLowerCase() === "processed";
            });
            this.calInvoiceTotalValue(requestDataPrcd)
            this.setState({
                filterRequestData: requestDataPrcd,
                tab: "processed"
            });
        }
    }
   handleFilterData = (isFromLocalStorage = false) => {
        $('.loader').fadeIn();

        let filterKeys = {};

        if (isFromLocalStorage) {
            filterKeys = JSON.parse(localStorage.getItem('filterKeys')) || {};
            this.setState({invoiceCurrentPage:false});
        } else {
            const urlParams = new URLSearchParams(window.location.search);
            const page = urlParams.get('page');
            if (page) {
                urlParams.delete('page');
                const newQueryString = urlParams.toString();
                const newUrl = newQueryString 
                    ? `${window.location.pathname}?${newQueryString}` 
                    : window.location.pathname;
                window.history.replaceState(null, '', newUrl);
            }
            this.setState({invoiceCurrentPage:true});
            var search_text = document.querySelector("#search_text").value;
            var status = [];
            var vendorTypes = [];
            var selectedStages = [];

            // Loop through checked checkboxes for status
            document.querySelectorAll('input[name="status"]:checked').forEach((checkbox) => {
                status.push(checkbox.value);
            });

            // Loop through checked checkboxes for vendor type
            document.querySelectorAll('input[name="vendorType"]:checked').forEach((checkbox) => {
                vendorTypes.push(encodeURIComponent(checkbox.value));
            });

            document.querySelectorAll('input[name="selectedStage"]:checked').forEach((checkbox) => {
                selectedStages.push(checkbox.value);
            });

            filterKeys = {status,vendorTypes,selectedStages,search_text};
            localStorage.setItem('filterKeys', JSON.stringify(filterKeys));
        }
        var { status = [], vendorTypes = [], selectedStages = [], search_text = '' } = filterKeys;
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token');
        var userData = localStorage.getItem('user_data')
        const userLoginType = localStorage.getItem('user_login_type');
        console.log(userLoginType, 'userLoginType')

        let invoiceUrl = `/all/invoices`;

        axios.get(`${API_HOST}${invoiceUrl}?page=${this.state.page}&limit=${this.state.limit}`, {
            params: {
                status: status.join(','),
                vendorTypes: vendorTypes.join(','),
                selectedStages: selectedStages.join(','),
                search_text: search_text,
                user_data: userData
            }
        })
            .then(response => {
                if (response.status === 200) {
                    console.log('excuted')
                    var status = response.data.search_status;
                    var vendorTypes = response.data.search_vendor_types;
                    var selectedStagesResponse = response.data.search_selected_stages;
                    var invoiceData = response.data.invoice_data;
                    var page = response.data.page;
                    var limit = response.data.limit;
                    var total_records = response.data.total_records;
                    console.log('filter_invoice', invoiceData)
                    this.setState({
                        filterRequestData: invoiceData,
                        invoiceListData:invoiceData,
                        reference_number:response.data.reference_number,
                        total_include_gst_amount: response.data.invoice_total_include_gst_amount,
                        tab: "all",
                        page:page,
                        limit:limit,
                        total_records:total_records,
                    }, () => {
                    console.log(this.state.filterRequestData);
                    // console.log(this.state.total_exclude_gst_amount)
                    ;console.log(this.state.total_include_gst_amount)
                    });
                    $('.loader').fadeOut('slow');
                    // Update status checkboxes
                    var status_list = ["Processed", "Pending", "Rejected"];
                    for (var j = 0; j < status_list.length; j++) {
                        if (status.includes(status_list[j])) {
                            $("#" + status_list[j]).prop("checked", true);
                        } else {
                            $("#" + status_list[j]).prop("checked", false);
                        }
                    }

                    // Update vendor type checkboxes
                    var vendor_type_list = ['Transporter', 'CHA', 'Forwarder', 'Shipping Line(Direct)', 'Inspection Agency', 'Others'];
                    for (var k = 0; k < vendor_type_list.length; k++) {
                        (function () {
                            var currentVendor = vendor_type_list[k].trim();
                            var isVendorMatch = vendorTypes.some(function (vendor) {
                                return vendor.trim() === currentVendor;
                            });

                            // Use attribute selector to find the checkbox by ID
                            var $checkbox = $('[id="' + currentVendor + '"]');

                            // Check or uncheck the checkbox based on the result
                            $checkbox.prop("checked", isVendorMatch);
                        })();
                    }

                   

                    var stage_list = ['Approver 1', 'Approver 2', 'Approver 3', 'Approver 4', 'Approver 5', 'Final Approver'];
                    for (var m = 0; m < stage_list.length; m++) {
                        (function () {
                            var currentStage = stage_list[m].trim();
                            var isStageSelected = selectedStagesResponse.some(function (stage) {
                                return stage.trim() === currentStage;
                            });
                    
                            var $checkbox = $('[id="' + currentStage + '"]');
                    
                            $checkbox.prop("checked", isStageSelected);
                        })();
                    }

                }
            })
            .catch(response => {
                // ErrorHandling(response)
            });
    };

    handleInvoiceFilterData = () => {
        this.handleFilterData(true);
    };
    
    clickTabHandler = (e) => {
        e.preventDefault();
        this.setState({ page:'1'}, () => {
            this.handleFilterData(false);
        })
    };
    render() {
        return (
            <div className='container vendor-dashboard-container'>
                <VendorPageHeader currentPage="invoice" clickTabHandler={this.clickTabHandler} invoiceListData={this.state.invoiceListData} />
                <div className='row main-dashboard vendor-main-dashboard mb-3'>
                    <div className='col-lg-2 col-md-2 col-12'>
                        <InvoiceLeftStatus thisData={this.state} clickTabHandler={this.clickTabHandler} />
                    </div>
                    <div className='col-lg-10 col-md-10 col-12 mt-2'>
                        <InvoiceListRequest thisData={this.state} changeTabHandler={this.changeTabHandler} updatePagination={this.updatePagination} />
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(InvoiceList);
