import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import $ from 'jquery'
import { API_HOST } from "../../Settings"
import axios from 'axios';
import VendorPageHeader from './VendorPageHeader.js';
import VendorListLeftStatus from './VendorListLeftStatus.js';
import VendorListRequest from './VendorListRequest.js';
import notify from '../CommonComponents/Notify';


class VendorList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user_data: JSON.parse(localStorage.getItem('user_data')),
            mocRequestData: [],
            tab: "all",
            filterRequestData: [],
            vendorListData: [],
            is_admin: false
        }
    }

    componentDidMount() {
        $('.loader').fadeIn();
        document.querySelector(".notification-Block")?.classList.add("d-none")
        document.querySelector(".user-profile")?.classList.add("d-none")
        if (this.state.user_data.is_admin) {
            axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
            axios.get(`${API_HOST}/vendors`,)
                .then(response => {
                    console.log(response.data.vendor_data)
                    if (response.status === 200) {
                        this.setState({
                            vendorListData: response.data.vendor_data,
                        })

                        $('.loader').fadeOut('slow');

                    }
                }).catch(response => {
                    if (response.response.status === 401) {
                        localStorage.clear()
                    }
                    $('.loader').fadeOut('slow');
                    // ErrorHandling(response)
                });

        } else {
            const auth_token = localStorage.getItem("token");
            const userData = localStorage.getItem("user_data");
            const logoutEndpoint = '/logout/';
            axios.defaults.headers.get['Authorization'] = auth_token;
            axios.get(API_HOST + logoutEndpoint, {
                params: {
                    user_data: userData
                }
            }).then(response => {
                if (response.status === 200) {
                    localStorage.clear();
                    notify("You don't have permission to view.", 'error')
                    this.props.history.push("/login")
                    return;
                }
            });
        }
    }

    componentDidUpdate() {
        const urlParams = new URLSearchParams(window.location.search);
        const workflow = urlParams.get('workflow');
        if (workflow) {
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    }


    clickTabHandler = (e) => {
        e.preventDefault();
        $('.loader').fadeIn();
        var search_text = document.querySelector("#search_text").value;
        var status = [];
        var vendorTypes = [];

        // Loop through checked checkboxes for status
        document.querySelectorAll('input[name="status"]:checked').forEach((checkbox) => {
            status.push(checkbox.value);
        });

        // Loop through checked checkboxes for vendor type
        document.querySelectorAll('input[name="vendorType"]:checked').forEach((checkbox) => {
            vendorTypes.push(encodeURIComponent(checkbox.value));
        });

        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token');
        axios.get(`${API_HOST}/all/vendors`, {
            params: {
                status: status.join(','),
                vendorTypes: vendorTypes.join(','),
                search_text: search_text
            }
        })
            .then(response => {
                if (response.status === 200) {
                    var status = response.data.search_status;
                    var vendorTypes = response.data.search_vendor_types;
                    var vendorData = response.data.vendor_data;
                    console.log(status, 'backend search status')
                    console.log(vendorTypes, 'backend vendor search status')
                    console.log(vendorData, 'backend vendor data')
                    this.setState({
                        vendorListData: response.data.vendor_data
                    });
                    $('.loader').fadeOut('slow');
                    this.setState({ tab: "all" });

                    // Update status checkboxes
                    var status_list = ["Active", "Inactive"];
                    for (var j = 0; j < status_list.length; j++) {
                        if (status.includes(status_list[j])) {
                            $("#" + status_list[j]).prop("checked", true);
                        } else {
                            $("#" + status_list[j]).prop("checked", false);
                        }
                    }

                    // Update vendor type checkboxes
                    var vendor_type_list = ['Transporter', 'CHA', 'Forwarder', 'Shipping Line(Direct)', 'Inspection Agency', 'Others'];
                    for (let k = 0; k < vendor_type_list.length; k++) {
                        let currentVendor = vendor_type_list[k].trim();
                        let isVendorMatch = vendorTypes.some(function (vendor) {
                            return vendor.trim() === currentVendor;
                        });
                    
                        // Use attribute selector to find the checkbox by ID
                        let $checkbox = $('[id="' + currentVendor + '"]');
                    
                        // Check or uncheck the checkbox based on the result
                        $checkbox.prop("checked", isVendorMatch);
                    }
                }
            })
            .catch(response => {
                // ErrorHandling(response)
            });
    };


    render() {
        return (
            <div className='container vendor-dashboard-container'>
                <VendorPageHeader currentPage="vendor" clickTabHandler={this.clickTabHandler} />
                <div className='row main-dashboard vendor-main-dashboard mb-3'>
                    <div className='col-lg-2 col-md-2 col-12'>
                        <VendorListLeftStatus thisData={this.state} clickTabHandler={this.clickTabHandler} />
                    </div>
                    <div className='col-lg-10 col-md-10 col-12 mt-1'>
                        <VendorListRequest thisData={this.state} changeTabHandler={this.changeTabHandler} />
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(VendorList);



