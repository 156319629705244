import axios from 'axios';
import React, { Component } from 'react';
import ViewComments from './ViewComments';
import { API_HOST } from '../../Settings';
import $ from 'jquery';
import notify from '../CommonComponents/Notify';



class Remark extends Component {
    constructor(props){
        super(props)
        this.state = {
            message:'',
            viewRemarks:this.props.WorkflowComponent?this.props.WorkflowComponent.workflow_remarks:[],
            unique_combine:this.props.unique_combine?this.props.unique_combine:"unique_combine"
        }
    }

    
    handleChange = (e) =>{
        this.setState({ 
            message:e.target.value
         })
    }

    static getDerivedStateFromProps(props, state) {
        if(props.WorkflowComponent !== state.viewRemarks){
            //Change in props
            return{
                viewRemarks: props.WorkflowComponent?props.WorkflowComponent.workflow_remarks:[]
            };
        }
        return null; // No change to state
    }

    commentSubmitHandler = (e) =>{
        e.preventDefault()
        if(this.state.message){
            var comments_data = {
                model:"workflow_remarks",
                data:{
                        workflow_definition_id:this.props.WorkflowData.workflow_definition_id,
                        stage_id:this.props.WorkflowData.id,
                        component_id:this.props.WorkflowComponent.id,
                        message:this.state.message
                    }
            }
            
            axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
            axios.post(`${API_HOST}/workflow/${this.props.RequestType}/request/${this.props.DocumentNumber}/form/data/save/`, comments_data)
            .then(response => {
                if (response.status === 200) {
                    if(response.data.status == "warning"){
                        notify(response.data.reason, response.data.status)
                        $('.loader').fadeOut('slow')
                        return false
                    }
                    notify('Remark saved successfully','success')
                    var comment_data = response.data.data.workflow_remarks
                    this.state.viewRemarks.push(comment_data)
                    this.setState({
                        viewRemarks:this.state.viewRemarks,
                        message:''
                    })
                    document.getElementById('comments').value=null;
                }
    
            }).catch(response => {
                // ErrorHandling(response)
            });
            $('.loader').fadeOut('slow')
        }else{
            notify('Please fill in remarks','warning')
        }
        
    }
    
    render(){
        return(
                
            <div className='col-lg-7 col-md-7 col-sm-12 col-12' key={this.state.unique_combine+'remark'}>
            <div className='d-flex justify-content-between position-relative mb-2'>
                <label className="form-label position-absolute" htmlFor="comments">Remarks</label>               
            </div>            
            <ViewComments WorkflowComents={this.state.viewRemarks} />
            {this.props.userPermissions.can_remark?this.props.is_approved?'':
            [<textarea key={this.state.unique_combine+'tar'} className='form-control brio-form-control mt-4' rows="3" name="comments" id="comments" type="text" onChange={e=>this.handleChange(e)} defaultValue={this.state.message}></textarea>,
            <div key={this.state.unique_combine+'rmd'} className='pe-2 mt-3'><button type="submit" className="btn btn-success btn-sm" onClick={this.commentSubmitHandler}>Remark</button></div>]:null}
            </div>
        )
    }
}

export default Remark;