import ApprovalofMOCbyInitiatorHOD from './Step02/ApprovalofMOCbyInitiatorHOD'
import PlantMOCLeadReview from './Step03/PlantMOCLeadReview'
import ApprovalByHeadHR from './Step04/ApprovalByHeadHR'
import ApprovalByHeadEHS from './Step04/ApprovalByHeadEHS'
import ApprovalByHeadQA from './Step04/ApprovalByHeadQA'
import ApprovalBySiteHeadFactory from './Step04/ApprovalBySiteHeadFactory'
import PlantMOCLeadReviewTwo from './Step05/PlantMOCLeadReviewTwo'
import TrainingPeriod from './Step06/TrainingPeriod'
import SubmitForClosureByInitiator from './Step07/SubmitForClosureByInitiator'
import ClosureofMOCByInitiatorHOD from './Step08/ClosureofMOCByInitiatorHOD'
import HeadHumanResourcesHead from './Step09/HeadHumanResourcesHead'
import HeadEHS from './Step09/HeadEHS'
import HeadQA from './Step09/HeadQA'
import ReviewAndClosurePlantMOCLead from './Step10/ReviewAndClosurePlantMOCLead'
import ManpowerRequest from './ManpowerRequest'


const ManpowerStageComponents = {
	"ManpowerRequest": ManpowerRequest,
	"ApprovalofMOCbyInitiatorHOD": ApprovalofMOCbyInitiatorHOD,
	"PlantMOCLeadReview": PlantMOCLeadReview,
	"ApprovalByHeadHR": ApprovalByHeadHR,
	"ApprovalByHeadEHS": ApprovalByHeadEHS,
	"ApprovalByHeadQA": ApprovalByHeadQA,
	"ApprovalBySiteHeadFactory": ApprovalBySiteHeadFactory,
	"PlantMOCLeadReviewTwo": PlantMOCLeadReviewTwo,
	"TrainingPeriod": TrainingPeriod,
	"SubmitForClosureByInitiator": SubmitForClosureByInitiator,
	"ClosureofMOCByInitiatorHOD": ClosureofMOCByInitiatorHOD,
	"HeadHumanResourcesHead": HeadHumanResourcesHead,
	"HeadEHS": HeadEHS,
	"HeadQA": HeadQA,
	"ReviewAndClosurePlantMOCLead": ReviewAndClosurePlantMOCLead
}

export default ManpowerStageComponents;