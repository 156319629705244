import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_HOST } from '../../Settings';
import { FaUser } from "react-icons/fa";
import $ from 'jquery';

class NotificationBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: [],
            notification_ids: [],
            loading : true
        }
    }

    getUserNotifications = () => {
        // $('.loader').fadeIn();
        console.log('get-notifications-functions')
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        var workflow = localStorage.getItem('workflow');
        axios.get(`${API_HOST}/get/notifications?is_unread=yes&workflow=${workflow}`)
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    notifications:response.data.notifications,
                    notification_ids: response.data.user_notification_ids,
                    loading:false
                }, () => 
                console.log(this.state))
                // $('.loader').fadeOut();
            }
        }).catch(response => {
            this.setState({loading:false})
            // ErrorHandling(response)
        });
    }
    componentDidMount(){
        this.getUserNotifications()
    }

    // updateUserNotifications = () => {
    //     var data = {
    //         user_notification_ids: this.state.notification_ids,
    //     }
    //     var url  = `${API_HOST}/update/notifications`
    //     axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
    //     axios.post(url, data)
    //     .then(response => {
    //         console.log(response.data.status)
    //     })
    // }
    // componentDidUpdate(){
    //     this.updateUserNotifications()
    // }
    render() {
        const { loading } = this.state;
        var view_notifications = <div className='notification-Block-row text-center p-3' style={{"color": "lightgray"}}>No pending task found.</div>
        if(this.state.notifications && this.state.notifications.length>0){
            view_notifications = this.state.notifications.map((notification,key)=>{
                return(
                    <div className="notification-Block-row">
                        <div className='d-flex justify-content-between'>
                            <div><FaUser className='userIcon2'/> {notification.user_name} ({notification.document_number})</div>
                            <div>{notification.created_date}</div>
                        </div>
                        <div className='grayColor'>
                            {notification.message}
                        </div>
                    </div>
                )
            })
        }
        return(
            <div className='notification-Block'>
               <div>
                    {loading ? (
                        <div className="loader-container">
                            <div className='notification-loader'>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className='scrollable-container'>
                                {view_notifications}
                            </div>
                            <div className='text-center p-2 lightgray-color cursorPointer'>
                                <Link to="/notifications">See all</Link>
                            </div>
                        </>
                        
                    )}
                </div>
            </div>
           
        )
    }
}
export default NotificationBlock;