import React, { Component } from 'react';
import ApproveButton from '../../ConsentComponents/ApproveButton';
import AskButton from '../../ConsentComponents/AskButton';
import Comment from '../../ConsentComponents/Comment';
import RejectButton from '../../ConsentComponents/RejectButton';
import Sign from '../../ConsentComponents/Sign';

class AfterSiteApprovelFlow extends Component {
    render() {
        return (
            <div className='card mb-3'>
                <div className='p-4'>
                    <div className='heading-Text font-16'> After Site Approval Flow shall come to MoC Lead (MR/QA)</div>
                    <div className='mt-4 w-60'>
                        <Comment />
                    </div>
                    <div className='form-check mt-2'>
                        <input className="form-check-input" type="checkbox" id="reviewed" />
                        <label className="form-check-label" htmlFor="reviewed">
                            Check for impact of change on Export/Domestic Registration & Customer Agreements
                        </label>
                    </div>
                    <div className='d-flex w-100 justify-content-end align-items-end tri-buttons mt-4'>
                        <div className='pe-2'><AskButton /></div>
                        <div className='pe-2'><RejectButton /></div>
                        <div><ApproveButton /></div>               
                    </div>
                    <div className='mt-2'>
                        <Sign />
                    </div>
                </div>
            </div>
        )
    }
}

export default AfterSiteApprovelFlow;