import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery'
import { API_HOST } from '../../Settings.js'
import axios from 'axios';
import Select, {components as rsComponents, ValueContainerProps} from 'react-select'
import notify from '../CommonComponents/Notify';
import { MultiSelect } from "react-multi-select-component";
// import makeAnimated from 'react-select/animated';
import Badge from 'react-bootstrap/Badge';
import '../../Styles/userRoles.css'


class ManageUserSettings extends Component {
    constructor(props){
        super(props)
        this.state = {
            user_data: localStorage.getItem('user_data')?JSON.parse(localStorage.getItem('user_data')):{},
            user_access_rows: [],
            users_data: [],
            selected_user:{}, // {'value':'email@brio.co.in', 'label': ''}
            users_list:[],
            selected_user_roles__ids_to_remove : [], 
            selected_user_roles_ids_ui: [], // {value:1, label:1}
            roles_data: [],
            roles_data_ui: [],
            user_roles_data: [],
            selected_roles_ids: [],
            selected_roles_ids_ui: [],
            sites_data: [],
            sites_data_ui: [],
            selected_site: {},

            plants_of_location: [],
            plants_of_location_ui: [],
            plants_selected_ids: [],
            plants_selected_ids_ui: [],
            possible_location_selected_roles: [],

            departments_data: [],
            departments_data_ui: [],
            department_selected_ids: [],
            department_selected_ids_ui: [],

            user_roles_list: [], // {'name': '', 'name_slug':''} for_relivers
            possible_relievers : [], // {'email':"", 'first_name':"", 'last_name': ""} for_relivers
            selected_roles_for_rel: [], //[{'value': 'electrical-head', 'label': 'Head EEE'}] for_relivers
            selected_relievers_for_rel : [], //[{'value':'email@brio.co.in', 'label': ''}] for_relivers

        }
    }

    componentDidMount(){
        if((this.state.user_data && this.state.user_data.hasOwnProperty('is_admin') &&
                        this.state.user_data.is_admin)){
            this.getAllData()
        }else{
            notify("You are not allowed", 'warning')
            setTimeout(()=>{
                $('.loader').fadeOut('slow')
                window.location.href='/'
            }, 3000)
        }
    }


    componentDidUpdate() {
        const urlParams = new URLSearchParams(window.location.search);
        const workflow = urlParams.get('workflow');
        if (workflow) {
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    }


    getAllData = ()=>{
        this.getUsersData()
        this.getRolesList()
        this.getAllSitesList()
        this.getAllDepartments()
    }

    getRolesList = ()=>{
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/admin/workflow/roles/list/`,)
        .then(response => {
            if (response.status === 200 ){
                var roles_data = response.data.data.roles_data
                var roles_data_ui = roles_data.map((item, index)=>{
                    return {label:item.name, value: item.id}
                })
                this.setState({"roles_data": roles_data, "roles_data_ui": roles_data_ui})
                $('.loader').fadeOut('slow')
            }
        }).catch(error_response=>{

        })
    }

    getAllSitesList = ()=>{
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/admin/get/all/sites/list/`,)
        .then(response => {
            if (response.status === 200 ){
                var sites_data = response.data.data.sites_data
                var sites_data_ui = sites_data.map((item, index)=>{
                    return {label:item.name, value: item.name_slug}
                })
                this.setState({"sites_data": sites_data, "sites_data_ui": sites_data_ui})
                $('.loader').fadeOut('slow')
            }
        }).catch(error_response=>{

        })
    }


    getAllDepartments = ()=>{
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/all/departments/data/`,)
        .then(response => {
            if (response.status === 200 ){
                var departments_data = response.data.departments_data
                var departments_data_ui = departments_data.map((item, index)=>{
                    return {label:item.name, value: item.id}
                })
                this.setState({"departments_data": departments_data, "departments_data_ui": departments_data_ui})
                $('.loader').fadeOut('slow')
            }
        }).catch(error_response=>{

        })
    }


    getUsersData=()=>{
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/all/users/list/`,)
        .then(response => {
            if (response.status === 200 ){
                var users_data = JSON.parse(response.data.users_data)
                var current_user = users_data.filter((row,indx)=>{
                    if(row.email==this.state.user_data.email){
                        return row.is_admin
                    }else{
                        return false
                    }
                })
                if(current_user.length==0){
                    notify("You are not allowed", 'warning')
                    setTimeout(()=>{
                        $('.loader').fadeOut('slow')
                        window.location.href='/'
                    }, 3000)
                }
                var users_list = users_data.map((row, index)=>{
                    return {"email": row.email, "first_name": row.first_name, "last_name": row.last_name}
                })
                this.setState({"users_data": users_data, "users_list": users_list})
                $('.loader').fadeOut('slow')
            }
        }).catch(error_response=>{

        })
    }


    getUserRoles=(user_obj)=>{
        $('.loader').fadeIn()
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/admin/get/user/roles/assigned/?email=${user_obj.value}`,)
        .then(response => {
            if (response.status === 200 ){
                this.setState({
                    "user_access_rows": response.data.data.user_roles_list,
                    "selected_user_roles__ids_to_remove" : [], 
                    "selected_user_roles_ids_ui": []
                })
                $('.loader').fadeOut('slow')
            }
        }).catch(error_response=>{

        })
    }

    handleUserSelect = (value) =>{
        this.setState({
            "selected_user": value,
            "selected_site": {},
            "plants_of_location": [],
            "plants_of_location_ui": [],
            "plants_selected_ids": [],
            "plants_selected_ids_ui": [],
            "possible_location_selected_roles": [],
            "department_selected_ids_ui": [],
            "selected_roles_ids_ui": [],
            "user_roles_list": [],
            "possible_relievers": [],
            "selected_roles_for_rel": [],
            "selected_relievers_for_rel": []
        })

        this.fetchUserData(value)
        
    }


    handleMultiSelect=(event)=>{
        var selected_user_roles_ids_ui = []
        var selected_user_roles__ids_to_remove = []
        if(event.target.checked){
            selected_user_roles_ids_ui = this.state.user_access_rows.map((item,index)=>{
                return {label:item.id, value:item.id}
            })
            selected_user_roles__ids_to_remove = selected_user_roles_ids_ui.map((item, index)=>{
                return item.value
            })
        }
        
        this.setState({
            "selected_user_roles_ids_ui": selected_user_roles_ids_ui,
            "selected_user_roles__ids_to_remove":selected_user_roles__ids_to_remove
        })
    }


    handleMultiSelectUserRole =(value)=>{
        var selected_roles_ids = value.map((item, index)=>{
            return item.value
        })
        this.setState({
            "selected_roles_ids_ui": value,
            "selected_roles_ids":selected_roles_ids
        })
    }

    handleMultiSelectUserDept =(value)=>{
        var department_selected_ids = value.map((item, index)=>{
            return item.value
        })
        this.setState({
            "department_selected_ids": department_selected_ids,
            "department_selected_ids_ui":value
        })

    }

    handleMultiSelectPlants = (value)=>{
        var plants_selected_ids = value.map((item, index)=>{
            return item.value
        })
        this.setState({
            "plants_selected_ids_ui": value,
            "plants_selected_ids":plants_selected_ids
        })
    }


    
    handleUserAccessControlSelected = (event, value) =>{
        var selected_user_roles__ids_to_remove = this.state.selected_user_roles__ids_to_remove
        if (event.target.checked){
            selected_user_roles__ids_to_remove.push(value)
        }else{
            let index = selected_user_roles__ids_to_remove.indexOf(value)
            if (index>-1){
                selected_user_roles__ids_to_remove.splice(index, 1)
            }
        }
        var selected_user_roles_ids_ui = selected_user_roles__ids_to_remove.map((item, index)=>{
            return {value: item, label:item}
        })
        this.setState({
            "selected_user_roles__ids_to_remove": selected_user_roles__ids_to_remove,
            "selected_user_roles_ids_ui": selected_user_roles_ids_ui
        })
        
    }

    handleRemove = (event)=>{
        event.preventDefault()
        var user_roles_ids = this.state.selected_user_roles__ids_to_remove?this.state.selected_user_roles__ids_to_remove:[]
        if(user_roles_ids.length<1){
            notify("Please select User roles id.", "warning")
            return false
        }
        var selected_user = this.state.selected_user
        var msg = "Are you sure, you want to delete ?"
        var form_data = {
            'user_roles_ids': user_roles_ids
        }
        if(window.confirm(msg)){
            $('.loader').fadeIn()
            axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')
            axios.post(`${API_HOST}/admin/user/roles/selected/delete/`, form_data)
            .then(response => {
                if (response.status === 200 ){
                    notify('User roles deleted successfully','success')
                    this.fetchUserData(selected_user)
                    /*setTimeout(()=>{
                         $('.loader').fadeOut('slow')
                     }, 3000)*/
                }
            }).catch(error_response=>{

            })
        }
    }

    handleDownload = (event) => {
        event.preventDefault();
        var user_ids = this.state.selected_user_roles__ids_to_remove;
        var user_name= this.state.selected_user['label']
        $('.loader').fadeIn();
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token');
        axios.post(`${API_HOST}/user/roles/download/xlsx/`,{user_ids : user_ids}, { responseType: 'blob' })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.href = url;
            a.download = `${user_name}_roles.xlsx`;
            document.body.appendChild(a);
            a.click();
            
            window.URL.revokeObjectURL(url);
            $('.loader').fadeOut('slow');
        })
        .catch(error_response => {
            // Handle error if needed
        });
    }


    handleSiteSelect = (value) =>{
        this.setState({
            "selected_site": value
        })
        this.getPlantsOfLocation(value.value)
    }

    getPlantsOfLocation = (site_name_slug)=>{
        $('.loader').fadeIn()
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/admin/get/plants/of/location/?site_name_slug=${site_name_slug}`,)
        .then(response => {
            if (response.status === 200 ){
                var plants_of_location = response.data.data.plants_of_location
                var plants_of_location_ui = plants_of_location.map((item, index)=>{
                    return {label:item.plant.name, value: item.plant.id}
                })
                this.setState({
                    "plants_of_location": plants_of_location,
                    "plants_of_location_ui": plants_of_location_ui,
                    "plants_selected_ids_ui": [],
                    'plants_selected_ids': [],
                    'department_selected_ids': [],
                    'department_selected_ids_ui': [],
                    "selected_roles_ids": [],
                    'selected_roles_ids_ui': [],

                })
                $('.loader').fadeOut('slow')
            }
        }).catch(error_response=>{

        })
    }


    fetchUserData = (selected_user)=>{
        this.getUserRoles(selected_user)
        this.getSelectedUserInfo(selected_user)
    }

    getConversionRoles=(roles)=>{
        var output = roles.map((row, indx)=>{
            return { value: row.name_slug, label: row.name}
        })
        return output
    }

    getConversionRelievers=(my_relievers)=>{
        var output = my_relievers.map((row, indx)=>{
            return {value: row.email, label: row.first_name + ' '+ row.last_name}
        })
        return output
    }

    handleRelieversSelect = (event)=>{
        // var value = Array.from(event, option=>option.value);
        // this.setState({"selected_relievers": event.length>1?event[1]:event[0]})
        this.setState({"selected_relievers_for_rel": event})
    }

    handleRolesSelect=(event)=>{
        // var value = Array.from(event, option => option.value);
        this.setState({
            "selected_roles_for_rel": event
        })
    }

    handleRemoveReliever = (email)=>{
        var selected_relievers_for_rel = this.state.selected_relievers_for_rel.filter((item, index)=>{
            if (item.value==email){
                return false
            }
            return true
        })
        this.setState({'selected_relievers_for_rel':selected_relievers_for_rel})
    }

    getSelectedUserInfo =(user_obj)=>{
        $('.loader').fadeIn()
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/user/relievers?email=${user_obj.value}`,)
        .then(response => {
            if (response.status === 200 && response.data.message===undefined){
                var selected_relievers_for_rel = response.data.data.selected_relievers
                var converted_relievers_for_rel = this.getConversionRelievers(selected_relievers_for_rel)
                var selected_roles_for_rel = response.data.data.selected_roles
                var converted_roles_for_rel = this.getConversionRoles(selected_roles_for_rel)
                var possible_relievers = response.data.data.possible_relievers
                var user_roles_list = response.data.data.user_roles
                this.setState({
                     "selected_relievers_for_rel":converted_relievers_for_rel, "selected_roles_for_rel": converted_roles_for_rel,
                    "user_roles_list": user_roles_list, "possible_relievers": possible_relievers
                })
                if(selected_relievers_for_rel.length==0){
                    var roles = this.getConversionRoles(user_roles_list)
                    this.setState({
                        "selected_roles_for_rel": roles
                    })
                }
            }else{
                notify(response.data.message, "warning")
            }
            $('.loader').fadeOut('slow')
        })
    }


    handleSave = (event)=>{
        event.preventDefault()
        var selected_relievers_for_rel = this.state.selected_relievers_for_rel?this.state.selected_relievers_for_rel:[]
        var selected_relievers_list = selected_relievers_for_rel.map((item, index)=>item.value)
        var selected_roles_list = this.state.selected_roles_for_rel.map((row, indx)=>{
            return row.value
        })
        var selected_user = this.state.selected_user
        var selected_user_email = this.state.selected_user['value']
        var form_data = {
            'email': selected_user_email,
            "relievers": selected_relievers_list,
            'roles': selected_roles_list
        }
        if(!selected_user_email){
            notify("Please select user.", "warning")
            return false
        }
        var msg = selected_relievers_list.length===0?"Are you sure, you want to remove reliver for selected roles?":"Are you sure, you want to save reliever for selected roles?"
        if(window.confirm(msg)){
            $('.loader').fadeIn()
            axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')
            axios.post(`${API_HOST}/user/relievers`, form_data)
            .then(response => {
                if (response.status === 200 ){
                    notify('Relievers saved successfully','success')
                    this.handleUserSelect(selected_user)
                    /*setTimeout(()=>{
                         $('.loader').fadeOut('slow')
                     }, 3000)*/
                }
            }).catch(error_response=>{

            })
        }
    }


    handleAssign = (event)=>{
        event.preventDefault()
        var selected_site = this.state.selected_site.value
        var plants_selected_ids = this.state.plants_selected_ids
        

        var department_selected_ids = this.state.department_selected_ids

        var selected_roles_ids = this.state.selected_roles_ids
        var selected_user = this.state.selected_user
        if(! selected_user.value){
            notify('Please select user', 'warning')
            return false
        }
        var msg = "Are you sure, you want to assign?"
        var form_data = {
            'selected_site': selected_site,
            'plants_selected_ids': plants_selected_ids,
            'department_selected_ids': department_selected_ids,
            'email': selected_user.value,
            "selected_roles_ids": selected_roles_ids
        }
        if(window.confirm(msg)){
            $('.loader').fadeIn()
            axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')
            axios.post(`${API_HOST}/admin/assign/user/roles/`, form_data)
            .then(response => {
                if (response.status === 200 ){
                    notify(response.data.reason, response.data.status)
                    this.handleUserSelect(selected_user)
                }
            }).catch(error_response=>{
            })
        }
    }

    render() {

        let users_options = this.state.users_list.map((each_user,indx)=>{
            return (
                { value: each_user.email, label: each_user.first_name+' '+ each_user.last_name}
            )
        })

        var user_roles_data_ui_for_add_uac = this.state.user_roles_data.map((item, index)=>{
            return {label:item.roles_name, value: item.roles_id}
        })

        var selected_uac_ids_to_remove = []

        var table_rows = this.state.user_access_rows.map((item, index)=>{
            selected_uac_ids_to_remove.push({ value: item.id, label: item.id})

            return <tr key={"uac_"+index}>
                <td>
                    
                    <div >
                        <input type="checkbox" className=""
                            checked = {this.state.selected_user_roles__ids_to_remove.includes(item.id)}
                            onChange={event => this.handleUserAccessControlSelected(event, item.id)} />
                    </div>
                </td>
                <td  style={{"textAlign":"right"}}>{index+1}</td>
                <td  >{item.location.name}</td>
                <td  >{item.site.name}</td>
                <td  >{item.plant.name ? item.plant.name : '-'}</td>
                <td  >{item.department.name ? item.department.name: '-'}</td>
                <td  colSpan="2" >{item.wf_role.name}</td>
            </tr>

        })

        let user_roles_options = this.getConversionRoles(this.state.user_roles_list)
        let possible_relievers_options = this.getConversionRelievers(this.state.possible_relievers)
        let selected_relievers_disply = this.state.selected_relievers_for_rel.map((item, index)=>{
            return (
                <Badge pill key={'relivers_sel_'+index}  bg="light" text="dark" className="m-1">
                    <div  className="d-flex align-items-center justify-content-between">
                        <div className="p-1">
                            <span>{item.label}</span>
                        </div>
                        <div>
                            <button type="button" onClick={(e)=>this.handleRemoveReliever(item.value)} style={{"backgroundColor":"blue", "color":"white"}}><i className="fa fa-times"></i></button>
                        </div>
                        &nbsp;
                    </div>
                </Badge>
            )
        })

        const ValueContainer = ({
                children,
                ...props
                }: ValueContainerProps<Option>
            ) => {
                    let [values, input] = children;
                    var cust_values = ''
                    var show_placeholder = true
                    if (Array.isArray(values)) {
                        const plural = values.length === 1 ? "" : "s";
                        cust_values = `${values.length} reliever${plural} selected`;
                        show_placeholder = false
                    }
                    return (
                        <rsComponents.ValueContainer {...props}>
                            <rsComponents.Placeholder {...props}>
                                {show_placeholder? props.selectProps.placeholder:null}
                            </rsComponents.Placeholder>
                          {cust_values}
                          {input}
                        </rsComponents.ValueContainer>
                    );
        };

        const customValueRendererRelievers = (selected, _options) => {
            var length = selected.length
          return selected.length
            ? selected.map(({ label }, indx) =>{
                    if(indx<length-1){
                        return label+", "
                    }else{
                        return label
                    }
            })
            : "Select Relievers";
        };

        const customValueRendererRoles = (selected, _options) => {
            var length = selected.length
          return selected.length
            ? selected.map(({ label }, indx) =>{
                    if(indx<length-1){
                        return label+", "
                    }else{
                        return label
                    }
            })
            : "Select Roles";
        };

        
        return (
            <div className='container' style={{"paddingBottom": "250px"}}>
                <div className="card">
                    <div className="card-body">
                        <div className='row'>
                            <div className='col-lg-12'>
                                <span className="d-flex justify-content-center"><b>Manage Settings</b></span>
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-2 col-sm-6 col-4 mt-1'>
                           <h5 className="card-title mb-3"> <b>User</b></h5> 
                        </div>
                        <div className="row mb-3">
                            <div className='col-lg-3 col-md-5 col-sm-12 col-12'>
                                {/*<label className="form-label" htmlFor="user">User</label>*/}
                                <Select
                                    id="user"
                                    onChange={value => this.handleUserSelect(value)}
                                    options={users_options}
                                    defaultValue={this.state.selected_user.hasOwnProperty('value') || 'Select'}
                                    name="user"
                                    isMulti={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.selected_user.hasOwnProperty('value')?
                    <div className="card mt-4">
                        <div className="card-body">
                            <div className='col-lg-2 col-md-2 col-sm-6 col-4 mt-1'>
                               <h5 className="card-title mb-3"> <b>User Relievers</b></h5> 
                            </div>
                            <div className="row mb-3">
                                <div className='col-lg-3 col-md-5 col-sm-12 col-12'>
                                    <label className="form-label" htmlFor="rel_usr_rol">Roles</label>
                                    <MultiSelect
                                        id="rel_usr_rol"
                                        name="rel_usr_rol"
                                        options={user_roles_options}
                                        value={this.state.selected_roles_for_rel}
                                        onChange={this.handleRolesSelect}
                                        labelledBy="Select"
                                        valueRenderer={customValueRendererRoles}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label" htmlFor="rel_usr">Relievers</label>
                                    <Select
                                        closeMenuOnSelect={true}
                                        id="rel_usr"
                                        name="rel_usr"
                                        value={this.state.selected_relievers_for_rel}
                                        onChange={this.handleRelieversSelect}
                                        isMulti
                                        isSearchable
                                        options={possible_relievers_options}
                                        components={{ValueContainer}}
                                        placeholder='Select Relievers'
                                      />
                                </div>
                                <div className='col-lg-3 col-md-5 col-sm-12 col-12 pt-4'>
                                    <button type="button" className="btn btn-primary" onClick={this.handleSave}>Assign</button>
                                </div>
                            </div>

                            {selected_relievers_disply.length>0?
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <h6>Relievers Selected</h6>
                                        {selected_relievers_disply}
                                    </div>
                                </div>:null
                            }
                        </div>
                    </div>:null
                }

                {this.state.selected_user.hasOwnProperty('value')?
                    <div className="card mt-4">
                        <div className="card-body">
                            <div className='col-lg-2 col-md-2 col-sm-6 col-4 mt-1'>
                               <h5 className="card-title mb-3"> <b>User Roles</b></h5> 
                            </div>
                            <div style={{"marginTop":"20px"}}>
                                <div className="row mb-3">
                                    <div className="">
                                    {this.state.selected_user_roles__ids_to_remove.length > 0 ? (
                                        <div className="d-flex justify-content-between align-items-center" style={{ marginBottom: "2px" }}>
                                            <div className="d-flex align-items-center">
                                                <span>{this.state.selected_user_roles__ids_to_remove.length} role(s) selected</span>
                                                {this.state.selected_user.hasOwnProperty('value') ? (
                                                    <a href="#" onClick={this.handleRemove} style={{ marginLeft: '10px' }}>Delete</a>
                                                ) : null}
                                            </div>
                                            <a href="#" onClick={this.handleDownload} className="ml-auto">Download</a>
                                        </div>
                                    ) : null}
                                        <table className="table table-hover" id="user_roles_table">
                                            <thead>
                                                <tr style={{"borderTop":"1px solid #dee2e6"}}>
                                                    <th style={{"width":"2%"}} >
                                                        {this.state.user_access_rows.length>0?
                                                            <input type="checkbox" onChange={event=>this.handleMultiSelect(event)} />
                                                            :<input type="checkbox" disabled={true}/>
                                                        }
                                                    </th>
                                                    <th style={{"width":"2%"}}>#</th>
                                                    <th style={{"width":"16%"}}>Location</th>
                                                    <th style={{"width":"16%"}}>Site</th>
                                                    <th style={{"width":"16%"}}>Plant</th>
                                                    <th style={{"width":"18%"}}>Department</th>
                                                    <th colSpan="2" style={{ "width":"20%"}} >Workflow Role</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {table_rows}
                                                <tr>
                                                    <td >-</td>
                                                    <td >-</td>
                                                    <td >-</td>
                                                    <td>
                                                        <div>
                                                            <Select
                                                                id="add_usr_access_site_id"
                                                                name="add_usr_access_site_id"
                                                                options={this.state.sites_data_ui}
                                                                onChange={value => this.handleSiteSelect(value)}
                                                                value={this.state.selected_site.hasOwnProperty('value') ? this.state.selected_site : 'Select'}
                                                                isMulti={false}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td style={{"maxWidth":"200px"}}>
                                                        <div>
                                                            <MultiSelect
                                                                id="usr_sel_loc_comb_id"
                                                                name="usr_sel_loc_comb_id"
                                                                options={this.state.plants_of_location_ui}
                                                                onChange={this.handleMultiSelectPlants}
                                                                value={this.state.plants_selected_ids_ui}
                                                                labelledBy="Select"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td style={{"maxWidth":"200px"}}>
                                                        <div>
                                                            <MultiSelect
                                                                style={{"maxWidth":"100%"}}
                                                                id="usr_dept_id"
                                                                name="usr_dept_id"
                                                                options={this.state.departments_data_ui}
                                                                onChange={this.handleMultiSelectUserDept}
                                                                value={this.state.department_selected_ids_ui}
                                                                labelledBy="Select"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td colSpan="2">
                                                        <div className="d-flex justify-content-between">
                                                        <div style={{"width":"200px"}}>
                                                                <MultiSelect
                                                                    id="usr_sel_id"
                                                                    name="usr_sel_id"
                                                                    options={this.state.roles_data_ui}
                                                                    onChange={this.handleMultiSelectUserRole}
                                                                    value={this.state.selected_roles_ids_ui}
                                                                    labelledBy="Select"
                                                                />
                                                        </div>
                                                        <div>
                                                            {this.state.selected_site.hasOwnProperty('value') && this.state.selected_roles_ids.length>0 ?

                                                                <button type="button" className="btn btn-primary" onClick={this.handleAssign}>Assign</button>:
                                                                <button type="button" className="btn btn-disabled" disabled={true}>Assign</button>
                                                            }
                                                        </div>
                                                        </div>
                                                    </td>
                                                    {false ?
                                                        <td>
                                                            <div>
                                                                {this.state.selected_site.hasOwnProperty('value') && this.state.selected_roles_ids.length>0 ?

                                                                    <button type="button" className="btn btn-primary" onClick={this.handleAssign}>Assign</button>:
                                                                    <button type="button" className="btn btn-disabled" disabled={true}>Assign</button>
                                                                }
                                                            </div>
                                                        </td>
                                                        :null
                                                    }
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>:null
                }
            </div>
        )
    }
}


 export default ManageUserSettings;

