import React, { Component, createRef } from "react";
import ApproveButton from "../../ConsentComponents/ApproveButton";
import RejectButton from "../../ConsentComponents/RejectButton";
import Sign from "../../ConsentComponents/Sign";
import Comment from "../../ConsentComponents/Comment";
import axios from 'axios';
import { API_HOST } from '../../../Settings';
import $ from 'jquery';
import notify from '../../CommonComponents/Notify';
import Signed from "../../ConsentComponents/Signed";
import AskButton from "../../ConsentComponents/AskButton";
import RejectComponent from "../../CommonComponents/RejectComponent";


class PlantMOCLeadReviewTwo extends Component {
    constructor(props){
        super(props)
        this.commentRef = createRef();
        this.handleOfComment = this.handleOfComment.bind(this)
        this.state = {
            is_approved:false,
            WorkflowData:this.props.WorkflowData,
            WorkflowComponent:this.props.WorkflowComponent,
            DocumentNumber:this.props.DocumentNumber,
            WorkflowSignatures: this.props.WorkflowData ? this.props.WorkflowData.components[0].workflow_signatures:[],
            enable_signing: false,
            enable_approval: false,
            show_plant_moc_opinion_card: false,
            current_stage_status: "",
            plant_moc_opinion: "",
            userPermissions: {},
            unique_stage_id: '5',
            unique_comp_id: '1',
            unique_combine: 'mans5c1',
            current_component_name: "PlantMOCLeadReviewTwo",
            ask_for_more_history:[]
        }
    }
    componentDidMount(){
        this.getUserPrivileges()
        if(this.props.isCollapased==false){
            axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
            axios.get(`${API_HOST}/workflow/manpower/request/${this.props.DocumentNumber}/stage/${this.props.StageName}/`, )
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        WorkflowData:response.data.data.workflow_stage_info,
                        WorkflowComponent:response.data.data.workflow_stage_info.components[0],
                        WorkflowSignatures:response.data.data.workflow_stage_info.components[0].workflow_signatures,
                        enable_signing:response.data.data.workflow_stage_info.components[0].enable_signing,
                        enable_approval:response.data.data.workflow_stage_info.components[0].enable_approval,
                        is_approved: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                            response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Approved" ? true : false,
                        show_plant_moc_opinion_card: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
                            response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false,
                        plant_moc_opinion: response.data.data.workflow_stage_info.components[0].wf_plant_moc_lead_review[0] ?
                            response.data.data.workflow_stage_info.components[0].wf_plant_moc_lead_review[0].action.name : "",
                        current_stage_status: response.data.data.current_stage_status,
                        // userComponentPermissions: response.data.data.workflow_stage_info.components[0].user_component_permissions,
                        ask_for_more_history:response.data.data.ask_for_more_history,
                    })
                    // this.renderDynamicComponent()
                }

            }).catch(response => {
                // ErrorHandling(response)
            });
            $('.card-loader').fadeOut('slow')
        }else{
            let current_stage_info = this.props.WorkflowData
            let is_approved = current_stage_info.components[0].workflow_action_logs[0] && 
                current_stage_info.components[0].workflow_action_logs[0].status == "Approved" ? true : false
            let show_plant_moc_opinion_card = current_stage_info.components[0].workflow_action_logs[0] && 
                current_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false
            let plant_moc_opinion =  current_stage_info.components[0].wf_plant_moc_lead_review[0] ?
                            current_stage_info.components[0].wf_plant_moc_lead_review[0].action.name : ""
            this.setState({is_approved:is_approved, show_plant_moc_opinion_card: show_plant_moc_opinion_card, plant_moc_opinion: plant_moc_opinion,
                // userComponentPermissions: current_stage_info.components[0].user_component_permissions
            })
        }
    }
    handleOfComment(e, stop_loader, method){
        var response_isvalid = this.commentRef.current.isValid()
        if(!response_isvalid.status){
            if(method == "approve"){
                if(response_isvalid.custom_error_code!=1){
                    // if custom_error_code==1  we can process approval without comment saving
                    notify(response_isvalid.reason,'warning')
                    return false
                }
            }else{
                if(response_isvalid.custom_error_code==1){
                    return false
                }
                if(response_isvalid.custom_error_code==2){
                    // if custom_error_code==1  we can process approval without comment saving
                    notify(response_isvalid.reason,'warning')
                    return false
                }
            }
        }else{
            this.commentRef.current.commentSubmitHandler(e, stop_loader)
            return true
        }
        return true
    }
    getUserPrivileges = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        let privileges = JSON.stringify(['can_approve', 'can_reject', 'can_ask_for_more_information', 'can_sign', 'can_comment', 'can_review'])
        let component = this.state.current_component_name
        let params = {"document_number": this.state.DocumentNumber, "from_new": true, "request_type": "manpower_request"}
        axios.get(`${API_HOST}/user/privileges/${component}/${privileges}/`, {params: params})
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    userPermissions:response.data.data,
                })

            }
        }).catch(response => {
            // ErrorHandling(response)
        });
    }
    render() {
        return (
            <div className="card p-3">
                <div className='p-4'>
                    <h5 className="card-title mb-3"><b>Plant MoC Lead Approval</b></h5>
                    <div className='mt-4 row'>
                        <Comment PlantMOCOpinion={this.state.plant_moc_opinion} isRejected={this.state.show_plant_moc_opinion_card} is_approved={this.state.is_approved} WorkflowData={this.state.WorkflowData} WorkflowComponent={this.state.WorkflowComponent}  
                        DocumentNumber={this.state.DocumentNumber} RequestType="manpower-request" userPermissions={this.state.userPermissions} ref={this.commentRef}/>
                        <div className="col-lg-2 col-md-6 col-sm-4 col-4"></div>
                        {this.state.WorkflowSignatures.length > 0?<Signed SignatureData={this.state.WorkflowSignatures[0]}/>:""}
                        <div>
                            {this.state.show_plant_moc_opinion_card ?
                                <RejectComponent
                                    CurrentStageStatus={this.state.current_stage_status}
                                    isRejected={this.state.show_plant_moc_opinion_card}
                                    WorkflowData={this.state.WorkflowData}
                                    WorkflowComponent={this.state.WorkflowComponent}
                                    DocumentNumber={this.state.DocumentNumber}
                                    userPermissions={this.state.userPermissions}
                                    RequestType="manpower-request"
                                />
                            :null}
                        </div>
                    </div>
                    {(this.props.isCollapased || (this.state.show_plant_moc_opinion_card && this.state.plant_moc_opinion != 'Rejected')) ? '':
                    <div className='d-flex w-100 justify-content-end align-items-end tri-buttons mt-4'>
                        <div className='pe-2'>
                            <AskButton 
                                IsApproved={this.state.is_approved}
                                stage_status={this.state.stage_status}
                                key={this.state.unique_combine+'askbtn'}
                                WorkflowData={this.state.WorkflowData} 
                                WorkflowComponent={this.state.WorkflowComponent}  
                                DocumentNumber={this.state.DocumentNumber}
                                current_component_name={this.state.current_component_name}
                                ask_for_more_history={this.state.ask_for_more_history}
                                RequestType="manpower-request"
                                userPermissions={this.state.userPermissions}
                                handleOfComment = {this.handleOfComment}
                            />
                        </div>
                        <div className='pe-2'>
                            <RejectButton
                                key={this.state.unique_combine+'rejbtn'}
                                PlantMOCOpinion={this.state.plant_moc_opinion}
                                IsApproved={this.state.is_approved}
                                WorkflowData={this.state.WorkflowData} 
                                WorkflowComponent={this.state.WorkflowComponent}  
                                DocumentNumber={this.state.DocumentNumber}
                                RequestType="manpower-request"
                                userPermissions={this.state.userPermissions}
                                current_component_name={this.state.current_component_name}
                                unique_combine = {this.state.unique_combine}
                                ask_for_more_history={this.state.ask_for_more_history}
                                stage_status={this.state.current_stage_status}
                                handleOfComment = {this.handleOfComment}
                            />
                        </div>
                        <div>
                            <ApproveButton
                                IsApproved={this.state.is_approved}
                                WorkflowData={this.state.WorkflowData}
                                WorkflowComponent={this.state.WorkflowComponent}
                                DocumentNumber={this.state.DocumentNumber}
                                RequestType="manpower-request"
                                userPermissions={this.state.userPermissions}
                                current_component_name={this.state.current_component_name}
                                unique_combine = {this.state.unique_combine}
                                ask_for_more_history={this.state.ask_for_more_history}
                                stage_status={this.state.current_stage_status}
                                handleOfComment = {this.handleOfComment}
                            />
                        </div>
                    </div>}
                    <div className='mt-2'>
                        <Sign IsSignatures={this.state.WorkflowSignatures.length > 0?true:false} 
                        IsApproved={this.state.is_approved}
                        WorkflowData={this.state.WorkflowData} WorkflowComponent={this.state.WorkflowComponent}  
                        DocumentNumber={this.state.DocumentNumber} RequestType="manpower-request"
                        userPermissions={this.state.userPermissions}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default PlantMOCLeadReviewTwo;