import React, { Component } from 'react';


class BriefDescription extends Component{
    constructor(props) {
        super(props);
    }

    render(){
        var access_type = "view"
        if(this.props){
            access_type = this.props.accessType
        }
        return(
            <div className="card mb-3" id='briefdescriptionDiv'>
                <div className="card-body">
                    <h5 className="card-title mb-3"> <b>Brief Description of Change</b></h5>                            
                    <div className='row mt-3 mb-3'>
                        <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                            <label className="form-label" htmlFor="nameOfProduct">Name of Product</label>
                            {["create", "update"].includes(access_type)?
                                <input className='form-control' name="name_of_product"
                                    id="nameOfProduct" type="text"
                                    onBlur={(e) => this.props.onChangeHandler(e,'brief_description')}
                                    defaultValue={this.props.thisData.name_of_product?this.props.thisData.name_of_product:null}/>:
                                <input className='form-control' name="name_of_product"
                                    id="nameOfProduct" type="text" disabled={true}
                                    value={this.props.thisData.name_of_product}/>
                            }
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                            <label className="form-label" htmlFor="sapMaterialCode">SAP Material Code</label>
                            {["create", "update"].includes(access_type)?
                                <input className='form-control' name="sap_material_code"
                                    id="sapMaterialCode" type="text"
                                    onBlur={(e) => this.props.onChangeHandler(e,'brief_description')}
                                    defaultValue={this.props.thisData.sap_material_code?this.props.thisData.sap_material_code:null}/>:
                                <input className='form-control' name="sap_material_code"
                                    id="sapMaterialCode" type="text" disabled={true}
                                    value={this.props.thisData.sap_material_code}/>
                            }

                        </div>                             
                    </div>
                    <div className='row mb-3'>
                        <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                            <label className="form-label" htmlFor="customerApplicable">Customer Applicable</label>
                            {["create", "update"].includes(access_type)?
                                <input className='form-control' name="customer_applicable"
                                    id="customerApplicable" type="text"
                                    onBlur={(e) => this.props.onChangeHandler(e,'brief_description')}
                                    defaultValue={this.props.thisData.customer_applicable?this.props.thisData.customer_applicable:null}/>:
                                <input className='form-control' name="customer_applicable"
                                    id="customerApplicable" type="text" disabled={true}
                                    value={this.props.thisData.customer_applicable}/>
                            }

                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                            <label className="form-label" htmlFor="requestedByProductManger">Requested by Product Manager (IBD/DF)</label>
                            {["create", "update"].includes(access_type)?
                                <input className='form-control' name="product_manger"
                                    id="requestedByProductManger" type="text"
                                    onBlur={(e) => this.props.onChangeHandler(e,'brief_description')}
                                    defaultValue={this.props.thisData.product_manger?this.props.thisData.product_manger:null}/>:
                                <input className='form-control' name="product_manger"
                                    id="requestedByProductManger" type="text" disabled={true}
                                    value={this.props.thisData.product_manger}/>
                            }
                        </div>                             
                    </div>
                    <div className='row mb-3'>
                        <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                            <label className="form-label" htmlFor="existingStatus">Existing Status *</label>
                            {["create", "update"].includes(access_type)?
                                <textarea className='form-control' rows="3" name="existing_status"
                                    id="existingStatus" type="text"
                                    onBlur={(e) => this.props.onChangeHandler(e,'brief_description')}
                                    defaultValue={this.props.thisData.existing_status?this.props.thisData.existing_status:null}>
                                </textarea>:
                                <textarea className='form-control' rows="3" name="existing_status"
                                    id="existingStatus" type="text" disabled={true}
                                    value={this.props.thisData.existing_status}>
                                </textarea>
                            }
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                            <label className="form-label" htmlFor="proposedChange">Proposed Change *</label>
                            {["create", "update"].includes(access_type)?
                                <textarea className='form-control' rows="3"
                                    name="proposed_change" id="proposedChange" type="text"
                                    onBlur={(e) => this.props.onChangeHandler(e,'brief_description')}
                                    defaultValue={this.props.thisData.proposed_change?this.props.thisData.proposed_change:null}>
                                </textarea>:
                                <textarea className='form-control' rows="3"
                                    name="proposed_change" id="proposedChange" type="text"
                                    disabled={true}
                                    value={this.props.thisData.proposed_change}>
                                </textarea>
                            }
                            <span className='float-right'>(Note: Attach additional sheet if required)</span>
                        </div>                             
                    </div>
                    <div className='row mb-3'>
                        <div className='col-12'>
                            <label className="form-label" htmlFor="probableCase">Problem/ Improvement with Probable Cause *</label>
                            {["create", "update"].includes(access_type)?
                                <textarea className='form-control' rows="3"
                                    name="probable_cause" id="probableCase" type="text"
                                    onBlur={(e) => this.props.onChangeHandler(e,'brief_description')}
                                    defaultValue={this.props.thisData.probable_cause?this.props.thisData.probable_cause:null}>
                                </textarea>:
                                <textarea className='form-control' rows="3"
                                    name="probable_cause" id="probableCase" type="text"
                                    disabled={true}
                                    value={this.props.thisData.probable_cause}>
                                </textarea>
                            }
                        </div>                                                             
                    </div>
                    <div className='row mb-3'>
                        <div className='col-12'>
                            <label className="form-label" htmlFor="proposalBenefits">Proposal with expected benefits *</label>
                            {["create", "update"].includes(access_type)?
                                <textarea className='form-control' rows="3" name="expected_benefits"
                                    id="proposalBenefits" type="text"
                                    onBlur={(e) => this.props.onChangeHandler(e,'brief_description')}
                                    defaultValue={this.props.thisData.expected_benefits?this.props.thisData.expected_benefits:null}>
                                </textarea>:
                                <textarea className='form-control' rows="3" name="expected_benefits"
                                    id="proposalBenefits" type="text" disabled={true}
                                    value={this.props.thisData.expected_benefits}>
                                </textarea>
                            }
                        </div>                                                             
                    </div>
                     
                </div>                        
            </div>
        )
    }
}

export default BriefDescription;