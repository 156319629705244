import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import $ from 'jquery'
import { API_HOST } from '../../Settings.js'
import axios from 'axios';
import { IoIosArrowDropdown , IoIosArrowDropup} from "react-icons/io";
import { Collapse } from 'bootstrap';
import SideMenu from '../CommonComponents/SideMenu'
import SubTaskStageComponents from './AllSubtaskStageComponents.js';






class MOCSubTaskRequest extends Component {
    constructor(props) {
        super(props)
        this.state =
        {
            documentID: this.props.match.params.documentID,
            requestID: this.props.match.params.requestID,
            toggle:false,
            currentStage:{},
            workflowData:[],
            collapseStags:{},
            dynamicompenents:[],
            has_pending_ask_more_request_for_sidemenu: false,
        }
    }

    componentDidMount(){
        document.querySelector(".notification-Block")?.classList.add("d-none")
        document.querySelector(".user-profile")?.classList.add("d-none")
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
		axios.get(`${API_HOST}/workflow/moc/subtask/request/${this.state.requestID}/`, )
		.then(response => {            
            if (response.status === 200) {
                var stage = {}
                var current_stage_name_slug = null
                if(response.data.data.current_stage){
                    current_stage_name_slug = response.data.data.current_stage.name_slug
                }
                var collapseStages = response.data.data.workflow_stages_data_statuses.map((stage_data,index)=>{
                    if(current_stage_name_slug==stage_data.stage_name_slug){
                        return stage[stage_data.stage_name_slug] = false
                    }else{
                        return stage[stage_data.stage_name_slug] = true
                    }
                })
				this.setState({
				    currentStage: response.data.data.current_stage,
                    workflowData:response.data.data.workflow_stages_data_statuses,
                    has_pending_ask_more_request_for_sidemenu:response.data.data.has_pending_ask_more_request,
                    has_pending_ask_more_request: response.data.data.has_pending_ask_more_request,
                    collapseStags:stage
				}, () => this.renderDynamicComponent())
                // this.renderDynamicComponent()
			}

		}).catch(response => {
            // ErrorHandling(response)
        });
        $('.loader').fadeOut('slow')
        this.getAllData()
    }

    updateSideMenu = (has_pending_ask_more_request_for_sidemenu)=>{
        this.setState({has_pending_ask_more_request_for_sidemenu:has_pending_ask_more_request_for_sidemenu})
    }

    getAllData = ()=>{
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
		axios.get(`${API_HOST}/workflow/moc/subtask/request/${this.props.DocumentNumber}/stage/${this.props.StageName}/?component_name=${this.props.ComponentName}`, )
		.then(response => {
		    if (response.status === 200) {
                this.setState({
		            WorkflowData: response.data.data.workflow_stage_info,
		            WorkflowComponent :response.data.data.workflow_stage_info.components[0],
		            WorkflowSignatures :response.data.data.workflow_stage_info.components[0].workflow_signatures,
		            enable_signing :response.data.data.workflow_stage_info.components[0].enable_signing,
		            enable_approval :response.data.data.workflow_stage_info.components[0].enable_approval,
		            is_approved : response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] && 
		                response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Approved" ? true : false,
		           	userComponentPermissions: response.data.data.workflow_stage_info.components[0].user_component_permissions,
		        }, () => 
                console.log(this.state)
                )
		    }

		}).catch(response => {
		    // ErrorHandling(response)
		});
    }

    /*handleCollapseToggle = (e) =>{
        e.preventDefault()
        var myCollapse = document.getElementById('collapseExample')
        var bsCollapse = new Collapse(myCollapse, {toggle: false})
        if(this.state.toggle){
            var toggle = false
            bsCollapse.hide()
        }else{
            bsCollapse.show();
            var toggle = true
        }
        this.setState({
            toggle:toggle
        })    
    }*/

    handleStageCollapse = (e,stage_name_slug) =>{
        e.preventDefault()
        console.log('handleStageCollapse this.state.collapseStags-1', this.state.collapseStags)
        var myCollapse = document.getElementById(stage_name_slug)
        var bsCollapse = new Collapse(myCollapse, {toggle: false})
        if(this.state.collapseStags[stage_name_slug]){
            var toggle = false
            bsCollapse.hide()
        }else{
            bsCollapse.show();
            var toggle = true
        }
        var Datastate = this.state.collapseStags
        Datastate[stage_name_slug] = toggle
        this.setState({collapseStags:Datastate},()=>this.renderDynamicComponent())    
        // this.renderDynamicComponent()
    }
    



    renderDynamicComponent = () =>{
        var dynamicompenents = this.state.workflowData.map((stageData,index)=>{
            var ReactComponents = []
            var stage_status = stageData.status
            var display_status =  stage_status && stage_status[0].toUpperCase() + stage_status.slice(1);
            stageData.react_components.map((react_component,indx)=>{
               var react_comp =   SubTaskStageComponents[react_component]
               if(react_comp){
                    ReactComponents.push({'name':react_component,'component':react_comp})
               }
            })
            return(
                <div className="card mb-3 butterfly-card" id={stageData.comp} key={stageData.comp+index}>
                    <div className="card-header stage-card-header" onClick={e => this.handleStageCollapse(e,stageData.stage_name_slug)}>
                        {/* <p className='stage-header'> */}
                            <div className=''>
                                <span className='stage-title'>{stageData.stage_name}</span>
                            </div>
                            <div className='text-right'>
                                {['moc-subtask-plant-moc-lead'].includes(stageData.stage_name_slug) ?
                                    <span>Submitted</span>:
                                    <span>{display_status}</span>
                                }
                            </div>
                            <div className=''>
                                <span  className='arrow-icon-span'>
                                    {this.state.collapseStags[stageData.stage_name_slug]==false?<IoIosArrowDropup/>:<IoIosArrowDropdown/>}    
                                </span>
                            </div>
                    </div>
                    <div className= {this.state.collapseStags[stageData.stage_name_slug]==false?"card-body collapse show":"card-body collapse"} id={stageData.stage_name_slug}>
                        <div className="card-loader" ><div className="dot-elastic"></div></div>
                        { this.state.collapseStags[stageData.stage_name_slug]==false?
                            ReactComponents.map((ComponentObj,ind)=>{
                                var ReactComponent = ComponentObj.component
                                var Name = ComponentObj.name
                                return <ReactComponent  
                                key={ReactComponent+ind} 
                                isCollapased = {false}
                                 DocumentNumber={this.state.requestID} 
                                 stage_status={stageData.status}
                                 StageName={stageData.stage_name_slug}
                                 updateSideMenu={this.updateSideMenu}
                                 ComponentName = {Name}/>
                            })
                        :""}
                        
                    </div>    
                </div>
            )
        })
        this.setState({
            dynamicompenents:dynamicompenents
        })

    }


    render() {
        
        return (
            <div className='container sub-task-container'>
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-12 side-nav-block">
                        <div className="card sticky-card">
                            <div className="card-body sticky-body">
                            {this.state.currentStage && this.state.currentStage.name_slug?
                                <SideMenu key={'rand_'+this.state.currentStage.name_slug}  workflowData={this.state.workflowData} activeNameSlug={this.state.currentStage.name_slug} has_pending_ask_more_request_for_sidemenu={this.state.has_pending_ask_more_request_for_sidemenu} RequestType="moc-sub-task"/>
                                :<SideMenu key={'rand_'} workflowData={this.state.workflowData} activeNameSlug="" has_pending_ask_more_request_for_sidemenu={this.state.has_pending_ask_more_request_for_sidemenu} RequestType="moc-sub-task"/>}
                            </div>
                        </div>
                    </div> 
                    <div className="col-lg-9 col-md-9 col-sm-12">
                        <div className='col-6 text-align-left row col-12 mb-2 mt-2'>
                            <h5>
                                <b># {this.state.requestID}&nbsp;-&nbsp;{this.state.currentStage ? "OPEN": "CLOSED"}</b>
                            </h5>
                        </div>
                        {this.state.dynamicompenents.map((item, index) => {
                                return <div key={index}>{item}</div>;
                            })}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(MOCSubTaskRequest)