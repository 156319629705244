import React, { Component } from 'react';
import Profilepic from "../../images/ProfilePic.svg";
import $ from 'jquery';
import axios from 'axios';
import { API_HOST } from "../../Settings"

class UserProfile extends Component {
    constructor() {
        super();
        this.state ={
            successMessage:false,
            user_data:{}
        }
    }
    onClickShowSuccessMessage = () =>{
        this.setState({
            successMessage:true
        })
    }
    componentDidMount(){
        document.querySelector(".notification-Block")?.classList.add("d-none")
        document.querySelector(".user-profile")?.classList.add("d-none")
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/change-password/`, )
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    user_data: response.data.user_data
                })
                $('.loader').fadeOut('slow');

            }
        }).catch(response => {
            // ErrorHandling(response)
        });
    }
    render() {

        return(
         //    <div className='edit-user-profile'>            
         //     <div className='signup-block pt-2 pe-5 ps-5 pb-4 position-relative'>
         //     {this.state.successMessage ?  <div className='d-flex success-messgage position-absolute' style={{top:"-22px",left:"16%"}}>
         //         <div className='black-tickmark'>&#x2713;</div>
         //         <div className='secoundary-text ps-1'>Password changed successfully.</div>
         //     </div> : null}
         //                 <div className='text-black text-center fontWeight-bold font-18'>Profile</div>
         //                 <div className='text-center mt-2'>
         //                    <img className='profile-img' src={Profilepic} />
         //                </div>
         //                 <div className='mt-2 login-details'>
         //                     <div>
         //                         <div className='secoundary-text font-12'>First Name</div>                                    
         //                         <div className='position-relative w-100 login-text-box'>
         //                             <input className='ps-3 border-0 w-100 font-14' type="text" placeholder='Sahithi' />
         //                         </div>
         //                     </div>
         //                     <div className='mt-2'>
         //                         <div className='secoundary-text font-12'>Last Name</div>
         //                         <div className='position-relative w-100 login-text-box'>
         //                             <input className='ps-3 border-0 w-100 font-14' type="text" placeholder='Gindam' />
         //                         </div>
         //                     </div>
         //                     <div className='mt-2'>
         //                         <div className='secoundary-text font-12'>Email</div>
         //                         <div className='position-relative w-100 login-text-box'>
         //                             <input className='ps-3 border-0 secoundary-text w-100 font-14' type="email" placeholder='sahithigindam@briotech.com' />
         //                         </div>
         //                     </div>
         //                     <div className='mt-2'>
         //                         <div className='secoundary-text font-12'>Current Password</div>
         //                         <div className='position-relative w-100 login-text-box'>
         //                             <input className='ps-3 border-0 secoundary-text w-100 font-14' type="password" placeholder='' />
         //                         </div>
         //                     </div>
         //                     <div className='mt-2'>
         //                         <div className='secoundary-text font-12'>New Password</div>
         //                         <div className='position-relative w-100 login-text-box'>
         //                             <input className='ps-3 border-0 secoundary-text w-100 font-14' type="password" placeholder='' />
         //                         </div>
         //                     </div>
         //                     <div className='mt-2'>
         //                         <div className='secoundary-text font-12'>Confirm Password</div>
         //                         <div className='position-relative w-100 login-text-box'>
         //                             <input className='ps-3 border-0 secoundary-text w-100 font-14' type="password" placeholder='' />
         //                         </div>
         //                     </div>
         //                 </div>                                
         //                 <div className='text-center mt-3'>
         //                     <button className='cancel-btn mt-2'>Cancel</button>
         //                     <button className='cancel-btn mt-2 save-btn' onClick={this.onClickShowSuccessMessage}>Save</button>
         //                 </div>
         //     </div>
         // </div>
            <div className='container'>
                <div className='row col-12 user-profile-details'>
                    <div className='signup-block pt-2 pe-5 ps-5 pb-4 position-relative'>
                        <div className='fontWeight-bold font-24 title-color'>PROFILE</div>
                        <div className="row">
                            <div className='col-12 row'>
                                <div className='row col-lg-4 col-md-4 col-sm-6 col-12 p-3'>
                                    <div className='col-md-6 col-6'>First Name:</div>
                                    <div className='col-md-6 col-6'><b>{this.state.user_data.first_name}</b></div>
                                </div>
                                <div className='row col-lg-4 col-md-4 col-sm-6 col-12 p-3'>
                                    <div className='col-md-6 col-6'>Last Name: </div>
                                    <div className='col-md-6 col-6'> <b>{this.state.user_data.last_name}</b></div>
                                </div>
                                <div className='row col-lg-4 col-md-4 col-sm-6 col-12 p-3'>
                                    <div className='col-md-6 col-6'>Email:</div>
                                    <div className='col-md-6 col-6'><b>{this.state.user_data.email}</b></div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-12 row'>
                                <div className='row col-lg-4 col-md-4 col-sm-6 col-12 p-3'>
                                    <div className='col-md-6 col-6'>Designation: </div>
                                    <div className='col-md-6 col-6'><b>{this.state.user_data.designation}</b></div>
                                </div>
                                <div className='row col-lg-4 col-md-4 col-sm-6 col-12 p-3'>
                                    <div className='col-md-6 col-6'>Department: </div>
                                    <div className='col-md-6 col-6'><b>{this.state.user_data.department}</b></div>
                                </div>
                                <div className='row col-lg-4 col-md-4 col-sm-6 col-12 p-3'>
                                    <div className='col-md-6 col-6'>Role: </div>
                                    <div className='col-md-6 col-6'><b>{this.state.user_data.role}</b></div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-12 row'>
                                <div className='row col-lg-4 col-md-4 col-sm-6 col-12 p-3'>
                                    <div className='col-md-6 col-6'>Plant: </div>
                                    <div className='col-md-6 col-6'><b>{this.state.user_data.plant ? this.state.user_data.plant: "-"}</b></div>
                                </div>
                                <div className='row col-lg-4 col-md-4 col-sm-6 col-12 p-3'>
                                    <div className='col-md-6 col-6'>Site: </div>
                                    <div className='col-md-6 col-6'><b>{this.state.user_data.site}</b></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default UserProfile;