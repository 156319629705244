import React, { Component } from 'react';
import $ from 'jquery'
import { API_HOST } from '../../Settings.js'
import axios from 'axios';
import notify from '../CommonComponents/Notify';
import '../../Styles/userRoles.css';
import { FaArrowLeft } from "react-icons/fa";
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import { HiOutlineUpload } from "react-icons/hi";
import { withRouter } from 'react-router-dom';
import { RiDeleteBin6Line } from "react-icons/ri";
import history from '../../history.js';



class InvoiceCreation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user_data: localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : {},
            users_data: [],
            selected_user: {},
            users_list: [],

            // vendor data variables
            isChecked: false,
            type_of_vendor_data: [],
            type_of_vendor_list: [],
            showPassword: false,
            selectedVendor: "",
            selectedFiles: [],
            empty: '',
        }
    }

    handleCheckboxChange = () => {
        this.setState(prevState => ({
            isChecked: !prevState.isChecked
        }));
    };


    handleFileDrop = (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            const unsupportedFiles = rejectedFiles.map(file => file.name).join(', ');
            notify(`Unsupported file types: ${unsupportedFiles}. Please upload only PDF, DOC, XLSX, PPT, JPG, XLS, PNG, or DOCX files.`, 'error');
            return;
        }

        const supportedTypes = ['.pdf', '.doc', '.xlsx', '.ppt', '.jpg', '.xls', '.docx', '.png'];
        const isValidFileType = acceptedFiles.every(file => supportedTypes.includes(`.${file.name.split('.').pop().toLowerCase()}`));

        if (isValidFileType) {
            // Create File objects for each accepted file
            const formattedFiles = acceptedFiles.map(file => new File([file], file.name, { type: file.type }));

            // Concatenate existing files with the new ones
            this.setState(prevState => ({
                selectedFiles: [...prevState.selectedFiles, ...formattedFiles],
            }));
        } else {
            notify('Unsupported file types. Please upload only PDF, DOC, XLSX, PPT, JPG, XLS, PNG, or DOCX files.', 'error');
        }

        console.log(this.state.selectedFiles, 'selectedFiles');
    };



    handleVendorChange = (selectedVendor) => {
        console.log(selectedVendor)
        this.setState({ selectedVendor });
        if (selectedVendor === "") {
            document.querySelector('#select_vendor').classList.add("error");
        } else {
            document.querySelector('#select_vendor').classList.remove("error");
        }

    };

    handleInvoiceClick = () => {
        var userData = localStorage.getItem("user_data")
        const userLoginType = localStorage.getItem('user_login_type');
        if (userLoginType === 'vendor') {
            window.location.href = "/invoices"
        } else if (userLoginType === 'rallis') {
            window.location.href = "/invoices?workflow=ibd"
        } else {
            console.error('Invalid user login type');
            return;
        }
    };

    getColorBasedOnVendorType(vendorType) {
        switch (vendorType) {
            case 'Transporter':
                return ' #003A98';
            case 'Forwarder':
                return '#00ABB2';
            case 'CHA':
                return '#009511';
            case 'Shipping Line(Direct)':
                return '#A30000';
            case 'Inspection Agency':
                return '#A8068F';
            default:
                return '#000000';
        }
    }

    componentDidMount() {
        this.getTypeOfVendorData()
        const roles = JSON.parse(localStorage.getItem('roles'));
        const userLoginType = localStorage.getItem('user_login_type');
        if (userLoginType === 'rallis' && !roles.includes("view-ibd")) {
            const auth_token = localStorage.getItem("token");
            const userData = localStorage.getItem("user_data");
            const logoutEndpoint = '/logout/';
            axios.defaults.headers.get['Authorization'] = auth_token;
            axios.get(API_HOST + logoutEndpoint, {
                params: {
                    user_data: userData
                }
            }).then(response => {
                if (response.status === 200) {
                    localStorage.clear();
                    notify("You don't have permission to view.", 'error');
                    this.props.history.push("/login")
                    return;
                }
            });
        }
    }


    componentDidUpdate(prevProps, prevState) {
        console.log('Component did update');
        const urlParams = new URLSearchParams(window.location.search);
        const workflow = urlParams.get('workflow');
        console.log(workflow, 'invoice work flow')

        // Check if the 'workflow' parameter has changed
        if (workflow && workflow !== prevState.workflow) {
            window.history.replaceState({}, document.title, window.location.pathname);
            // Update the state with the new 'workflow' value
            this.setState({ "workflow": workflow });
        }
    }

    removeSelectedFile(index) {
        const { selectedFiles } = this.state;
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        this.setState({ selectedFiles: updatedFiles });
    }


    getTypeOfVendorData = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        var userData = localStorage.getItem("user_data")
        const userLoginType = localStorage.getItem('user_login_type');
        let invoiceDataUrl;
        invoiceDataUrl = `/invoice`;
        console.log(invoiceDataUrl, 'invoicedata')
        axios.get(`${API_HOST}${invoiceDataUrl}`, {
            params: {
                user_data: userData
            }
        })
            .then(response => {
                if (response.status === 200) {
                    var type_of_vendor_data = response.data.vendors
                    this.setState({ "type_of_vendor_list": type_of_vendor_data })
                    if (type_of_vendor_data.length === 1) {
                        const singleVendor = type_of_vendor_data[0];
                        const selectedVendor = {
                            value: singleVendor.id,
                            vendorType: singleVendor.vendor_type,
                            name: singleVendor.name,
                        };
                        this.setState({
                            selectedVendor,
                            readOnly: true,
                        });
                    }
                    $('.loader').fadeOut('slow')
                }
            }).catch(error_response => {
                console.log(error_response)

            })
    }

    handleKeyPress(event) {
        const charCode = event.which || event.keyCode;
        const charStr = String.fromCharCode(charCode);
        const currentValue = event.target.value;
        if (!/^\d+(\.\d{0,2})?$/.test(currentValue + charStr)) {
            event.preventDefault();
        }
    }


    handleChange = (e, state_obj) => {
        const inputElement = e.currentTarget;

        if (inputElement.value === "") {
            inputElement.classList.add("error");
            inputElement.closest('.form-text-box').style.border = 'none';
        } else {
            inputElement.classList.remove("error");
            inputElement.closest('.form-text-box').style.border = '1px solid #C6D9EB';
        }
    }

    handleInvoiceChange = (e, state_obj) => {
        const inputElement = e.currentTarget;
        let inputValue = inputElement.value;
    
        const alphanumericRegex = /^[a-zA-Z0-9]*$/;
        inputValue = inputValue.replace(/[^a-zA-Z0-9]/g, '');
        inputElement.value = inputValue;
    
        if (inputValue === "" || !alphanumericRegex.test(inputValue)) {
            inputElement.classList.add("error");
            inputElement.closest('.form-text-box').style.border = 'none';
        } else {
            inputElement.classList.remove("error");
            inputElement.closest('.form-text-box').style.border = '1px solid #C6D9EB';
        }
    }



    handleDescripChange = (e, state_obj) => {
        const inputElement = e.currentTarget;
        if (inputElement.value === "") {
            inputElement.classList.add("error");
        } else {
            inputElement.classList.remove("error");
        }
    }



    isValid = () => {
        var intFloatRegex = /^[+-]?\d+(\.\d+)?$/;
        var name_regexp = new RegExp("<(?:\"[^\"]*\"['\"]*|'[^']*'['\"]*|[^'\">])+>");
        var selectedVendorOption = this.state.selectedVendor;
        var dateImplementation = document.getElementById('invoice_date').value


        if (!selectedVendorOption || !dateImplementation || !document.querySelector("#invoice_number").value || !document.querySelector("#invoice_include_gst").value || !document.querySelector("#invoice_exclude_gst").value || !document.querySelector("#reference_number").value || !document.getElementById("service_description").value) {
            var scrollDiv = ""
            if (!selectedVendorOption) {
                document.querySelector('#select_vendor').classList.add("error");
                scrollDiv = "select_vendor";
            }

            if (!document.querySelector("#invoice_include_gst").value) {
                const invoiceIncludeField = document.querySelector('#invoice_include_gst');
                invoiceIncludeField.closest('.form-text-box').style.border = 'none';
                document.querySelector('#invoice_include_gst').classList.add("error")
                scrollDiv = "invoice_include_gst"
            }
            if (!document.querySelector("#invoice_exclude_gst").value) {
                const invoicExcludeField = document.querySelector('#invoice_exclude_gst');
                invoicExcludeField.closest('.form-text-box').style.border = 'none';
                document.querySelector('#invoice_exclude_gst').classList.add("error")
                scrollDiv = "invoice_exclude_gst"
            }
            if (!document.querySelector("#invoice_number").value) {
                const invoiceNumberField = document.querySelector('#invoice_number');
                invoiceNumberField.closest('.form-text-box').style.border = 'none';
                document.querySelector('#invoice_number').classList.add("error")
                scrollDiv = "invoice_number"
            }
            if (!document.querySelector("#reference_number").value) {
                const referenceNumberField = document.querySelector('#reference_number');
                referenceNumberField.closest('.form-text-box').style.border = 'none';
                document.querySelector('#reference_number').classList.add("error")
                scrollDiv = "reference_number"
            }
            if (!dateImplementation) {
                const invoiceDateField = document.querySelector('#invoice_date');
                invoiceDateField.closest('.form-text-box').style.border = 'none';
                document.querySelector('#invoice_date').classList.add("error")
                scrollDiv = "invoice_date"
            }
            if (!document.querySelector("#service_description").value) {
                document.querySelector('#service_description').classList.add("error")
                scrollDiv = "service_description"
            }

            return { "status": false, "reason": "Please fill required field", "id": scrollDiv }
        }
        if ((document.querySelector("#invoice_include_gst").value && name_regexp.test(document.querySelector("#invoice_include_gst").value))) {
            document.querySelector('#invoice_include_gst').classList.add("error")
            var scrollDiv = "invoice_include_gst"
            return { "status": false, "reason": "Please provide valid information in input fields.", "id": scrollDiv }
        }
        if ((document.querySelector("#invoice_exclude_gst").value && name_regexp.test(document.querySelector("#invoice_exclude_gst").value))) {
            document.querySelector('#invoice_exclude_gst').classList.add("error")
            var scrollDiv = "invoice_exclude_gst"
            return { "status": false, "reason": "Please provide valid information in input fields.", "id": scrollDiv }
        }
        if ((document.querySelector("#invoice_number").value && name_regexp.test(document.querySelector("#invoice_number").value))) {
            document.querySelector('#invoice_number').classList.add("error")
            var scrollDiv = "invoice_number"
            return { "status": false, "reason": "Please provide valid information in input fields.", "id": scrollDiv }
        }
        if ((document.querySelector("#reference_number").value && name_regexp.test(document.querySelector("#reference_number").value))) {
            document.querySelector('#reference_number').classList.add("error")
            var scrollDiv = "reference_number"
            return { "status": false, "reason": "Please provide valid information in input fields.", "id": scrollDiv }
        }
        if ((document.querySelector("#service_description").value && name_regexp.test(document.querySelector("#service_description").value))) {
            document.querySelector('#service_description').classList.add("error")
            var scrollDiv = "service_description"
            return { "status": false, "reason": "Please provide valid information in input fields.", "id": scrollDiv }
        }
        if ((!intFloatRegex.test(document.querySelector("#invoice_include_gst").value))) {
            document.querySelector('#invoice_include_gst').classList.add("error")
            var scrollDiv = "invoice_include_gst"
            return { "status": false, "reason": "Special characters are not allowed, only integer or float format allowed in invoice value.", "id": scrollDiv }
        }
        if ((!intFloatRegex.test(document.querySelector("#invoice_exclude_gst").value))) {
            document.querySelector('#invoice_exclude_gst').classList.add("error")
            var scrollDiv = "invoice_exclude_gst"
            return { "status": false, "reason": "Special characters are not allowed, only integer or float format allowed in invoice value.", "id": scrollDiv }
        }
        return { "status": true, "reason": "" }
    }
    submitInvoiceData = (e) => {
        e.preventDefault();

        const formData = new FormData();
        const vendor = this.state.selectedVendor['value'];
        const invoice_date = document.getElementById("invoice_date").value;
        const invoice_number = document.getElementById("invoice_number").value;
        const invoice_include_gst = document.getElementById("invoice_include_gst").value;
        const invoice_exclude_gst = document.getElementById("invoice_exclude_gst").value;
        const service_description = document.getElementById("service_description").value;
        const reference_number = document.getElementById("reference_number").value;
        const attachments = this.state.selectedFiles;
        var response_isvalid = this.isValid()
        if (!response_isvalid.status) {
            notify(response_isvalid.reason, 'warning')
            setTimeout(function () {
                document.querySelector('#' + response_isvalid.id).scrollIntoView({
                    behavior: 'smooth', block: "center", inline: "nearest"
                })
            }, 100)
            return false
        }

        if (this.state.selectedFiles.length === 0) {
            notify('Atleast one attachment is required.', 'warning');
            setTimeout(function () {
                document.querySelector('.file-upload-box').scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest'
                });
            }, 100);
            return false;
        } 

        attachments.forEach(file => {
            const fileObj = new File([file], file.name, { type: file.type });
            const imgName = file.name.toLowerCase();
            const size = file.size;
            const file_size = (size / (1024 * 1024)).toFixed(2);
            formData.append('attachments', fileObj);
            formData.append('file_size', file_size);
            formData.append('size', size);
        });


        formData.append('invoiceData', JSON.stringify({
            vendor,
            date: invoice_date,
            invoice_number,
            invoice_total_include_gst: invoice_include_gst,
            invoice_total_exclude_gst: invoice_exclude_gst,
            description: service_description,
            reference_number,
            user_data: localStorage.getItem("user_data")
        }));

        // Add other required fields to the FormData if needed
        $(".loader").css("display", "flex");
        const auth_token = localStorage.getItem("token");
        const userLoginType = localStorage.getItem('user_login_type');
        let invoiceCreateUrl;


        invoiceCreateUrl = `/invoice`;


        axios.post(`${API_HOST}${invoiceCreateUrl}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': auth_token,
            },
        })
            .then(response => {
                if (response.status === 200 && response.data.success) {
                    notify(response.data.message, 'success');
                    setTimeout(() => {
                        this.handleInvoiceClick();
                    }, 2000);
                } else {
                    notify(response.data.message, 'error');
                }
                $('.loader').fadeOut('slow');
            })
            .catch(response => {
                $('.loader').fadeOut('slow');
            });
    }


    render() {
        const { isChecked, showPassword, type_of_vendor_list, selectedVendor, selectedFiles, readOnly } = this.state;
        const vendorOptions = type_of_vendor_list.map((option) => ({
            value: option.id,
            vendorType: option.vendor_type,
            name: option.name,
        }));

        return (
            <div className='container' style={{ "paddingBottom": "250px", "paddingTop": "25px" }}>
                <div className='col-12'>
                    <div className="card vendor-card pb-3">
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-sm-12 col-12 d-flex justify-content-between'>
                                    <h5 className="vendor-card-title" > <span className="mr-3 arrow-left" style={{ fontSize: '1.0rem', marginRight: '17px' }} onClick={() => { window.location.href = '/invoices?workflow=ibd' }}><FaArrowLeft /></span><b>New Invoice</b></h5>
                                </div>
                            </div>
                            <div className="row mb-3 mt-4">
                                <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                    <div className='form-label-text font-12'>Vendor <span style={{ color: 'red', fontSize: '15px' }}>*</span></div>
                                    <div>
                                        <Select
                                            options={vendorOptions}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    width: '100%',
                                                    border: '1px solid #C6D9EB',
                                                    '&:hover': {
                                                        border: '1px solid #C6D9EB',
                                                        boxShadow: '0 0 6px rgb(173, 216, 230)',
                                                    },
                                                }),
                                            }}
                                            value={selectedVendor}
                                            onChange={this.handleVendorChange}
                                            id="select_vendor"
                                            name="select_vendor"
                                            placeholder="Select a vendor"
                                            getOptionLabel={(option) => `${option.name} - ${option.vendorType}`}
                                            getOptionValue={(option) => option.value}
                                            formatOptionLabel={({ vendorType, name }) => (
                                                <div>
                                                    <span style={{ color: 'gray' }}>{name}</span>
                                                    <span style={{ color: this.getColorBasedOnVendorType(vendorType), marginLeft: '5px' }}>{vendorType}</span>
                                                </div>
                                            )}
                                            isDisabled={readOnly}
                                        />
                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                    <div className='form-label-text font-12'>Date of Shipment<span style={{ color: 'red', fontSize: '15px' }}>*</span></div>
                                    <div className='form-text-box'>
                                        <input
                                            className='ps-3 border-0 w-100 font-14 form-control'
                                            name="invoice_date"
                                            id="invoice_date"
                                            type="date"
                                            onChange={(e) => this.handleChange(e, '')}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                    <div className='form-label-text font-12'>Invoice Number <span style={{ color: 'red', fontSize: '15px' }}>*</span></div>
                                    <div className='form-text-box'>
                                        <input className='ps-3 border-0 w-100 font-14 form-control' 
                                        name="invoice_number" 
                                        id="invoice_number" type="text" onChange={(e) => this.handleInvoiceChange(e, '')} />
                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                    <div className='form-label-text font-12'>Rallis Invoice number / Shipment reference number / BL number <span style={{ color: 'red', fontSize: '15px' }}>*</span></div>
                                    <div className='form-text-box'>
                                        <input className='ps-3 border-0 w-100 font-14 form-control' name="reference_number" id="reference_number" type="text"  onChange={(e) => this.handleChange(e, '')}/>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                    <div className='form-label-text font-12'>Invoice Value (Include GST) <span style={{ color: 'red', fontSize: '15px' }}>*</span></div>
                                    <div className='form-text-box'>
                                        <span className='input-group-text' id='rupeeSymbol'>
                                            ₹
                                        </span>
                                        <input
                                            className='ps-3 border-0 w-100 font-14 form-control'
                                            name="invoice_include_gst"
                                            id="invoice_include_gst"
                                            type="text"
                                            onKeyPress={(e) => this.handleKeyPress(e)}
                                            onChange={(e) => this.handleChange(e, '')}
                                        />
                                    </div>
                                </div>


                                <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                    <div className='form-label-text font-12'>Invoice Value (Exclude GST) <span style={{ color: 'red', fontSize: '15px' }}>*</span></div>
                                    <div className='form-text-box'>
                                        <span className='input-group-text' id='rupeeSymbol'>
                                            ₹
                                        </span>
                                        <input
                                            className='ps-3 border-0 w-100 font-14 form-control'
                                            name="invoice_exclude_gst"
                                            id="invoice_exclude_gst"
                                            type="text"
                                            onKeyPress={(e) => this.handleKeyPress(e)}
                                            onChange={(e) => this.handleChange(e, '')}
                                        />
                                    </div>
                                </div>

                                <div className='col-lg-12 col-md-12 col-sm-12 col-12 mt-3'>
                                    <div className="form-group">
                                        <div className='form-label-text font-12'>Service Description <span style={{ color: 'red', fontSize: '15px' }}>*</span></div>
                                        <textarea className="form-control input-text-description" style={{ borderRadius: '5px' }} id="service_description" onChange={(e) => this.handleDescripChange(e, '')} rows="3"></textarea>
                                    </div>
                                </div>

                                <div className='col-lg-12 col-md-12 col-sm-12 col-12 mt-3 mb-2'>
                                    <div className='form-label-text font-12'>Attachment <span style={{ color: 'red', fontSize: '15px' }}>*</span></div>
                                    <Dropzone onDrop={this.handleFileDrop} style={{ border: '1px solid green' }}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()} className="file-upload-box">
                                                <input {...getInputProps()} />
                                                <div className='dropzone'>
                                                    <h1 className='drag-file-text'>
                                                        <span style={{ marginRight: '12px', color: 'black', fontSize: '22px' }}><HiOutlineUpload /></span>
                                                        Drag & Drop Files to Upload
                                                    </h1>
                                                    <h1 className='drag-file-text'>Or</h1>
                                                    <button className="upload-files-button" type="button">Browse files</button>
                                                    <p className='files-supported'>Supported files .pdf,.doc,.xlsx,.ppt,.jpg,.xls,.docx,.png </p>
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                    <div className="selected-files">
                                        {selectedFiles.map((file, index) => (
                                            <div key={index} className="selected-file" style={{ 'display': 'flex', 'alignItems': 'center' }}>
                                                <p style={{ 'marginRight': '10px', 'color': 'gray', 'fontSize': '13px' }}>{file.name}</p>
                                                <p className="invoice-file-delete-icon" onClick={() => this.removeSelectedFile(index)}>X</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <div id='vendor-button-container' className="d-flex d-md-flex justify-content-md-end">
                                    <button className="cancel-button" type="button" onClick={() => { window.location.href = '/invoices' }}>Cancel</button>
                                    <button className="submit-button" type="button" onClick={(e) => { this.submitInvoiceData(e) }}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
export default withRouter(InvoiceCreation);


