import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import AssessmentTable from './AssessmentTable';
// import Enclosures from './EnclosureComponent';
// import BriefDescription from './BriefDescription';
// import ChangeSought from './ChangeSought';
// import TypeOfChange from './TypeOfChange';
// import ItemTable from './ItemTable';
// import Toggle from '../CommonComponents/Toggle';
// import { ItemRowNames, AssessmentRowNames } from '../CommonComponents/MiscellaneousObject';
import $ from 'jquery'
import { API_HOST } from '../../Settings.js'
import axios from 'axios';
import { IoIosArrowDropdown , IoIosArrowDropup} from "react-icons/io";
import { Collapse } from 'bootstrap';
import SideMenu from '../CommonComponents/SideMenu'
import StageComponents from './AllStageComponents';
import ApprovedIcon from '../../images/approvedIcon.svg'
import PendingIcon from '../../images/pendingIcon.svg'
import RejectedIcon from '../../images/rejectedIcon.svg'
import SubmitIcon from '../../images/submittedIcon.svg'
import {BsFileEarmarkPdf} from "react-icons/bs";
import notify from '../CommonComponents/Notify.js';
import ErrorHandling from '../ErrorHandler'







class MOCRequest extends Component {
    constructor(props) {
        super(props)
        this.state =
        {
            requestID: this.props.match.params.requestID,
            toggle:false,
            currentStage:{},
            workflowData:[],
            collapseStags:{},
            dynamicompenents:[],
            has_pending_ask_more_request_for_sidemenu:false,
            has_pending_ask_more_request: false,
            subtask_document_number:"",
            is_skipped: false,
            user_roles: {},
            selectedDepartments: []
        }
    }

    componentDidMount(){      
        document.querySelector(".notification-Block")?.classList.add("d-none")
        document.querySelector(".user-profile")?.classList.add("d-none")
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
		axios.get(`${API_HOST}/workflow/moc/request/${this.state.requestID}/`, )
		.then(response => {
			if (response.status === 200) {
                var stage = {}
                var current_stage_name_slug = null
                if(response.data.data.current_stage){
                    current_stage_name_slug = response.data.data.current_stage.name_slug
                }
                var collapseStages = response.data.data.workflow_stages_data_statuses.map((stage_data,index)=>{
                    if(current_stage_name_slug==stage_data.stage_name_slug){
                        return stage[stage_data.stage_name_slug] = false
                    }else{
                        return stage[stage_data.stage_name_slug] = true
                    }
                })
				this.setState({
				    currentStage: response.data.data.current_stage,
                    workflowData:response.data.data.workflow_stages_data_statuses,
                    has_pending_ask_more_request_for_sidemenu:response.data.data.has_pending_ask_more_request,
                    has_pending_ask_more_request: response.data.data.has_pending_ask_more_request,
                    collapseStags:stage,
                    status: response.data.data.status,
                    subtask_document_number:response.data.data.subtask_document_number,
                    is_skipped: response.data.data.is_skipped,
                    user_roles: response.data.data.ConcernedDepartmentRoles,
				}, () => this.renderDynamicComponent())
                // this.renderDynamicComponent()
			}
            $('.loader').fadeOut('slow')
		}).catch(response => {
            
            ErrorHandling(response)
        });
        
    }

    componentDidUpdate() {
        let current_component_name_scroll = localStorage.getItem('current_component_name');
    
        if (current_component_name_scroll) {
            setTimeout(function () {
                let elementToScroll = document.querySelector('#' + current_component_name_scroll);
    
                if (elementToScroll) {
                    elementToScroll.scrollIntoView({
                        behavior: 'smooth', block: "center", inline: "nearest"
                    });
    
                    // Remove current_component_name from local storage
                    localStorage.removeItem('current_component_name');
                }
            }, 1000);
        }
    }
    

    /*handleCollapseToggle = (e) =>{
        e.preventDefault()
        var myCollapse = document.getElementById('collapseExample')
        var bsCollapse = new Collapse(myCollapse, {toggle: false})
        if(this.state.toggle){
            var toggle = false
            bsCollapse.hide()
        }else{
            bsCollapse.show();
            var toggle = true
        }
        this.setState({
            toggle:toggle
        })    
    }*/


    updateSideMenu = (has_pending_ask_more_request_for_sidemenu)=>{
        this.setState({has_pending_ask_more_request_for_sidemenu:has_pending_ask_more_request_for_sidemenu})
    }

    handleStageCollapse = (e,stage_name_slug) =>{
        e.preventDefault()
        // console.log('handleStageCollapse this.state.collapseStags-1', this.state.collapseStags)
        var myCollapse = document.getElementById(stage_name_slug)
        var bsCollapse = new Collapse(myCollapse, {toggle: false})
        if(this.state.collapseStags[stage_name_slug]){
            var toggle = false
            bsCollapse.hide()
        }else{
            bsCollapse.show();
            var toggle = true
        }
        var Datastate = this.state.collapseStags
        Datastate[stage_name_slug] = toggle
        this.setState({collapseStags:Datastate},()=>this.renderDynamicComponent())
        // this.renderDynamicComponent()
        // console.log('handleStageCollapse this.state.collapseStags-2', this.state.collapseStags) 
    }
    
    handleDepartmentSelect = (department) => {
        const { selectedDepartments } = this.state;
        const updatedDepartments = selectedDepartments.includes(department) 
            ? selectedDepartments.filter(dep => dep !== department)
            : [...selectedDepartments, department];
        this.setState({ selectedDepartments: updatedDepartments });
    };
    


    handleSubmit = () => {
        const { requestID, selectedDepartments } = this.state;
        if (selectedDepartments.length === 0) {
            notify('Please Select Departments', 'warning');
            return;
        }
        const message = 'Are you sure you want to add these components?';
        if (window.confirm(message)) {
            axios.post(`${API_HOST}/workflow/moc/request/${requestID}/update/`,
                { departments: selectedDepartments },
                { headers: { 'Authorization': localStorage.getItem('token') } }
            )
            .then(response => {
                if (response.data.status === 'success') {
                    notify('Departments updated successfully!', 'success');
                    setTimeout(() => {
                        window.location.reload();
                        localStorage.setItem('current_component_name', selectedDepartments.join(', '));
                    }, 500);
                } else {
                    console.error(response.data.reason);
                }
            })
            .catch(error => console.error('Error:', error));
        }
    };
    
    
    

    renderDynamicComponent = (data) =>{
        const concernedDepartmentsData = [
            'ApprovalByRegulatoryDomestic',
            'ApprovalByRegulatoryInternational',
            'ApprovalByIBDHead',
            'ApprovalByDFHead'
        ];
        let selectedConcernedDepartments = [];
        let unSelectedConcernedDepartments = [];
        if (this.state.status == "opened") {
            selectedConcernedDepartments = this.state.currentStage.selected_concerned_departments || [];
            unSelectedConcernedDepartments = concernedDepartmentsData.filter(dept => !selectedConcernedDepartments.includes(dept));
        } else {
            unSelectedConcernedDepartments = [];
        }
        var dynamicompenents = this.state.workflowData.map((stageData,index)=>{
            var ReactComponents = []
            var stage_status = stageData.status
            if(stageData.stage_name_slug == 'plant-moc-lead-qa-production-designer' && this.state.is_skipped){
                var display_status = "Skipped"
            }else{
                var display_status =  stage_status && stage_status[0].toUpperCase() + stage_status.slice(1);
            }
            stageData.react_components.map((react_component,indx)=>{
               var react_comp =   StageComponents[react_component]
               if(react_comp){
                    ReactComponents.push({'name':react_component,'component':react_comp})
               }
            })
               
            var status_asked_more = <span className="pending-btn p-1"><img className='p-2 ps-0' src={PendingIcon}/>Asked more</span>
            let button=[];
            if(display_status=="Rejected"){
                button.push(<span className="rejected-btn p-1"><img className='p-2 ps-0' src={RejectedIcon}/>{display_status} </span>)
            }
            else if(display_status=="Approved"){
                button.push(<span className="approved-btn">
                    <img className='' src={ApprovedIcon}/>
                    <span className='position-relative ms-1' style={{top:"1px"}}>{display_status} </span>
                    </span>)
            }
            else if(display_status=="Skipped"){
                button.push(<button className="submit-btn border-0 p-2">
                        <img src={ApprovedIcon}/>
                        <span className='position-relative ms-1' style={{top:"1px"}}>{display_status}</span>
                     </button>)
            }
            else if(display_status=="Pending"){
                button.push(<span className="pending-btn p-1"><img className='p-2 ps-0' src={PendingIcon}/>{display_status} </span>)
            }        
            if (data == false) {
                this.state.collapseStags[stageData.stage_name_slug] = false
            }
            return(
                <div className="card mb-3 butterfly-card" id={stageData.comp} key={stageData.comp+index}>
                    <div className="card-header stage-card-header border-bottom" onClick={e => this.handleStageCollapse(e,stageData.stage_name_slug)}>
                        {/* <p className='stage-header'> */}
                            <div className=''>
                                <span className='stage-title'>{stageData.stage_name}</span>
                            </div>
                            <div className='text-right'>
                                {['initiator-s-moc-request'].includes(stageData.stage_name_slug)  ?
                                        stageData.moc_request_status=='Save as Draft'?
                                            <span className="submit-btn"><i className="fa fa-edit p-2 fa-lg"></i>Drafted</span>
                                        :<span className="submit-btn">
                                            <img className='p-2 ps-0' src={SubmitIcon}/><span className='position-relative' style={{top:"2px"}}>Submitted</span></span>
                                    :button
                                }
                            </div>
                            <div className=''>
                                <span  className='arrow-icon-span'>
                                {this.state.collapseStags[stageData.stage_name_slug]==false?<IoIosArrowDropup/>:<IoIosArrowDropdown/>}    
                                </span>
                            </div>
                    </div>
                    <div className= {this.state.collapseStags[stageData.stage_name_slug]==false?"card-body collapse show":"card-body collapse"} id={stageData.stage_name_slug}>
                        <div className="card-loader" ><div className="dot-elastic"></div></div>
                        { this.state.collapseStags[stageData.stage_name_slug]==false?
                         ReactComponents.map((ComponentObj,ind)=>{
                                var ReactComponent = ComponentObj.component
                                var Name = ComponentObj.name
                                const additionalProps = 
                                Name === 'ApprovalByManufacturingHead' || 
                                Name === 'ApprovalByCQALHead' ? {
                                  unSelectedConcernedDepartmentsData: unSelectedConcernedDepartments,
                                  currentStageData: this.state.currentStage,
                                  userRoles: this.state.user_roles,
                                } : {};
                                return <ReactComponent  
                                key={ReactComponent+ind} 
                                isCollapased = {false}
                                DocumentNumber={this.state.requestID} 
                                stage_status={stageData.status}
                                StageName={stageData.stage_name_slug}
                                updateSideMenu={this.updateSideMenu}
                                ComponentName = {Name}
                                {...additionalProps}
                                />

                            })
                            :""}
                        
                    </div>    
                </div>          
            )
        })
        this.setState({
            dynamicompenents:dynamicompenents
        })

    }
    handelDownloadMocPdf() {
        notify('Loading Preview please wait!','success') 
        this.renderDynamicComponent(false);             
        setTimeout(() => window.print(),5000);
        
    }

    render() {
        if(this.state.subtask_document_number){
            var url = "/moc/subtask/request/"+this.state.requestID+"/"+this.state.subtask_document_number
        }else{
            var url = "/create/subtask/request/"+this.state.requestID
        }
        return (
            <div className='container sub-task-container'>
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-12 side-nav-block">
                        <div className="card sticky-card ">
                            <div className="card-body sticky-body">
                            {this.state.currentStage && this.state.currentStage.name_slug?
                                <SideMenu key={'rand_'+this.state.currentStage.name_slug}
                                    workflowData={this.state.workflowData}
                                    activeNameSlug={this.state.currentStage.name_slug}
                                    has_pending_ask_more_request_for_sidemenu={this.state.has_pending_ask_more_request_for_sidemenu}
                                    RequestType="moc-request"/>
                                :<React.Fragment><SideMenu key={'rand_'} workflowData={this.state.workflowData}
                                    has_pending_ask_more_request_for_sidemenu={this.state.has_pending_ask_more_request_for_sidemenu}
                                    activeNameSlug="" RequestType="moc-request"/>
                                    {this.state.currentStage == "OPEN" ? null :  <button className='btn download-btn' onClick={()=>this.handelDownloadMocPdf() }>                                        
                                        <span className='font-14'>Download</span>
                                        <span className='ps-2 text-danger font-18'><BsFileEarmarkPdf /> </span>
                                    </button>} 
                                    
                                       
                                    </React.Fragment>}  

                            </div>
                        </div>
                    </div> 
                    <div className="col-lg-9 col-md-9 col-sm-12 main-contant-block">
                        <div className='mt-2 mb-2 d-flex justify-content-between align-items-center'>
                            <div className=''>
                                <h5 className='green-title-color'>
                                    <b># {this.state.requestID}&nbsp;-&nbsp;{this.state.currentStage ? "OPEN": "CLOSED"}</b>
                                </h5>
                            </div>
                            <div className=''>
                                {this.state.status=="closed"?this.state.subtask_document_number?<a href={url} className="btn btn-dark ms-4" style={{paddingTop : "12px"}}>Go to Sub Task</a>
                                :<a href={url} className="btn btn-dark btn-w-110" style={{paddingTop : "12px"}}>Create Sub Task</a>:null}
                            </div>
                        </div>
                        {this.state.dynamicompenents.map((item, index) => {
                                return <div key={index}>{item}</div>;
                            })}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(MOCRequest)