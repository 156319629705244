import React, { Component } from 'react';
import { API_HOST } from '../../Settings';
import notify from '../CommonComponents/Notify';
import axios from 'axios';
import { FaPlusCircle } from "react-icons/fa";
import AttachmentDependOfForm from "../ConsentComponents/AttachmentDependOfForm";
import ViewAttachmentsDependOfForm from "../ConsentComponents/ViewAttachmentsDependOfForm";
import Attachment from '../ConsentComponents/Attachment';
import ViewAttachments from '../ConsentComponents/ViewAttachments';
class Enclosures extends Component{
    constructor(props){
        super(props)
        // this.state = {
        //     attachment : "",
        //     attachment_url:this.props.thisData.signed_url,
        //     attachment_name:this.props.thisData.attachment_url,
        //     file_size:''
        // }
    }

    componentDidMount(){
        
    }


    getFileInputDetails = (event, file_type) =>{
        var div_element = document.getElementById('drag-drop-file-div-xlsx');     
        if (event.target.files && event.target.files[0]) {
            let imgName = event.target.files[0].name;
            var temp_name = imgName.toLowerCase();
            var size = event.target.files[0].size
            var file_size = (size / (1024*1024)).toFixed(2)
            div_element.setAttribute('data-title',imgName);
            if(this.props.accessType == "create"){
                var attachment_obj = { 
                    attachment:event.target.files[0],
                    filename:temp_name,
                    file_size:file_size,
                    signed_url:""
                }
                this.props.handleUpdateAttachment(attachment_obj)
            }
                        
        }
    };

    isvalidFiles(event){
        /*returns true, true (isvalid , has_zip file)*/
       var xlsx_file_name = this.state.batch_json_file.name
       var zip_file_name = this.state.batch_zip_file
       if(this.state.batch_zip_file){
            zip_file_name = this.state.batch_zip_file.name 
       }

        if(!xlsx_file_name){
            /*notify('missing xlsx file','warning')*/
            return [false, false]
        }else if(!(xlsx_file_name.toLowerCase().endsWith('.xlsx'))){
            /*notify('invalid xlsx file', 'warning')*/
            return [false, false]
        }else if(zip_file_name){
            if(zip_file_name.toLowerCase().endsWith('.zip')){
                /*notify('zip file available', 'information')*/
                return [true, true]
            }else{
                /*notify('Invalid zip file', 'information')*/
                return [false, false]
            }
        }else{
            /*notify('Continue without zip file', 'information')*/
            return [true, false]
        }
       
    }

    handleSubmit = (event) =>  {
        event.preventDefault();
        if(this.props.thisData.attachment){
          let form_data_django = new FormData();
          form_data_django.append('attachment', this.props.thisData.attachment, this.props.thisData.attachment_url);
          form_data_django.append('size', this.props.thisData.file_size);
          let url = `${API_HOST}/upload/file/in/azure/blob/storage`;
          axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')
          axios.post(url,form_data_django,
            // {   headers: {'content-type': 'multipart/form-data'} } 
            ).then(response=>{
                if(response.data.success){
                    notify("File successfully uploaded",'information')
                    if(this.props.accessType == "create"){
                        var attachment_obj = { 
                            filename:response.data.filename,
                            signed_url:response.data.singed_url
                        }
                        this.props.handleUpdateAttachment(attachment_obj)
                    }
                }else{
                    notify(response.data.message,'error')
                }
                                

            }).catch(error_xlsx => {
                
            })
          
        }else{
            alert("Invalid files selected")
        }
  };

    render(){
        var access_type = "view"
        var proposed_change = ''
        if((this.props.moc_status == 'Open' || this.props.moc_status == 'Save as Draft') && this.props.enable_upload_attachment){
            var enable_attach_delete = true
        }
        if(this.props){
            access_type = this.props.accessType
        }
        return(
            <div className="card mb-3">
                <div className="card-body">
                    <div className='display-flex sm-display-block'>
                    <h5 className="card-title mb-3"><b>Enclosures </b></h5>&nbsp;&nbsp;<span> (e.g. related change controls, investigations, customer request & Annexure if needed)</span>
                    </div>
                    <div className=''>
                        <p> <b>Operation Analysis Checklist & Schematic of the Proposal</b></p>
                    </div>
                    <div className="form-check form-check-inline">
                        {["create", "update"].includes(access_type)?
                            <input className="form-check-input" type="radio"
                                name="operation_analysis" id="yes" value="Yes"
                                onChange={(e)=>this.props.onChangeHandler(e,"enclosures")}
                                checked={this.props.thisData.operation_analysis == "Yes"?true:false}/>:
                            <input className="form-check-input" type="radio"
                                name="operation_analysis" id="yes" value="Yes"
                                disabled={this.props.thisData.operation_analysis == "Yes"?false:true}
                                checked={this.props.thisData.operation_analysis == "Yes"?true:false}/>
                        }
                        <label className="form-check-label" htmlFor="yes">Yes</label>
                    </div>
                    <div className="form-check form-check-inline">
                        {["create", "update"].includes(access_type) ?
                            <input className="form-check-input" type="radio"
                                name="operation_analysis" id="no" value="No"
                                onChange={(e)=>this.props.onChangeHandler(e,"enclosures")}
                                checked={this.props.thisData.operation_analysis == "No"?true:false}/>:
                            <input className="form-check-input" type="radio"
                                name="operation_analysis" id="no" value="No"
                                disabled={this.props.thisData.operation_analysis == "No"?false:true}
                                checked={this.props.thisData.operation_analysis == "No"?true:false}/>
                        }
                        <label className="form-check-label" htmlFor="no">No</label>
                    </div>
                    <div className="form-check form-check-inline">
                        {["create", "update"].includes(access_type) ?
                            <input className="form-check-input" type="radio"
                                name="operation_analysis" id="notApplicable"
                                value="NotApplicable"
                                onChange={(e)=>this.props.onChangeHandler(e,"enclosures")}
                                checked={this.props.thisData.operation_analysis == "NotApplicable"?true:false}/>:
                            <input className="form-check-input" type="radio"
                                name="operation_analysis" id="notApplicable" value="NotApplicable"
                                disabled={this.props.thisData.operation_analysis == "NotApplicable"?false:true}
                                checked={this.props.thisData.operation_analysis == "NotApplicable"?true:false}/>
                        }
                        <label className="form-check-label" htmlFor="notApplicable">Not Applicable</label>
                    </div>
                    <div className='row mt-3 mb-3' key={"enclosure-att"}>
                        <div>
                            <p> <b>Attachments</b></p>
                            {this.props.ask_for_more_history_filtered.length>0 || this.props.WorkflowAttachments.length>0 ?
                            <div>
                                {this.props.WorkflowAttachments.length > 0 ?
                                    <ViewAttachments removeWfAttachment={this.props.removeWfAttachment}
                                    key={this.props.unique_combine+'va'}
                                    enable_attach_delete={enable_attach_delete}
                                    WorkflowAttachments={this.props.WorkflowAttachments} />
                                    :["create", "update"].includes(access_type)?"":"No Attachments"
                                }
                            </div>
                            :<span>No attachments</span>
                            }
                        </div>
                        &nbsp;
                        {this.props.userPermissions.can_attach && (this.props.moc_status == 'Open' || this.props.moc_status =='Save as Draft') && this.props.enable_upload_attachment  ?
                            (this.props.ask_more_stage_name_slug != "" && !(['execution-hod-review', 'hod-of-safety-review', 'hod-of-qa-review', 'closure-by-moc-lead'].includes(this.props.ask_more_stage_name_slug)) && this.props.ask_for_more_history_filtered.length>0) || ["create","update",'view'].includes(access_type)?
                            <div className="pl-2">
                                <div className='heading-Text font-12 mb-3 d-flex'>
                                Upload Attachments
                                    <div className="plus-circle"><FaPlusCircle onClick={this.props.handleAddAttachmentButton}/></div>
                                </div>
                                {this.props.attachments.map((each, index)=>{
                                    return <Attachment AttachmentIndex={index}
                                    AttachmentObj={each}
                                    key={this.props.unique_combine+'attach'+index}
                                    handleUpdateAttachmentList={this.props.handleUpdateAttachmentList}
                                    handleDeleteAttachmentButton={this.props.handleDeleteAttachmentButton}
                                    handleSaveAttachmentButton={this.props.handleSaveAttachmentButton}
                                    />
                                    })
                                }
                            </div>
                            :null
                        :null
                        }
                    </div>                      
                </div>                        
            </div>
        )
    }
}

export default Enclosures;