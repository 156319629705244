import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import $ from 'jquery'
import { API_HOST } from "../../Settings"
import axios from 'axios';
import UserPageHeader from './UserPageHeader.js';
import UserListLeftStatus from './UserListLeftStatus.js';
import UserListRequest from './UserListRequest.js';
import notify from '../CommonComponents/Notify';


class UserList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user_data: JSON.parse(localStorage.getItem('user_data')),
            usersListData: [],
            is_admin: false
        }
    }

    componentDidMount() {
        $('.loader').fadeIn();
        document.querySelector(".notification-Block")?.classList.add("d-none")
        document.querySelector(".user-profile")?.classList.add("d-none")
        if (this.state.user_data.is_admin) {
            this.clickTabHandler()
        //     axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        //     axios.get(`${API_HOST}/users`,)
        //         .then(response => {
        //             if (response.status === 200) {
        //                 console.log(response.data.users_data, 'backend_dataa......')
        //                 this.setState({
        //                     usersListData: response.data.users_data,
        //                 })

        //                 $('.loader').fadeOut('slow');

        //             }
        //         }).catch(response => {
        //             if (response.response.status === 401) {
        //                 localStorage.clear()
        //             }
        //             $('.loader').fadeOut('slow');
        //             // ErrorHandling(response)
        //         });

        } else {
            const auth_token = localStorage.getItem("token");
            const userData = localStorage.getItem("user_data");
            const logoutEndpoint = '/logout/';
            axios.defaults.headers.get['Authorization'] = auth_token;
            axios.get(API_HOST + logoutEndpoint, {
                params: {
                    user_data: userData
                }
            }).then(response => {
                if (response.status === 200) {
                    localStorage.clear();
                    notify("You don't have permission to view.", 'error')
                    this.props.history.push("/login")
                    return;
                }
            });
        }
    }

    componentDidUpdate() {
        const urlParams = new URLSearchParams(window.location.search);
        const workflow = urlParams.get('workflow');
        if (workflow) {
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    }


    clickTabHandler = (e) => {
        // e.preventDefault();
        debugger;
        $('.loader').fadeIn();
        var search_text = document.querySelector("#search_text").value;
        var status = [];


        // Loop through checked checkboxes for status
        document.querySelectorAll('input[name="status"]:checked').forEach((checkbox) => {
            status.push(checkbox.value);
        });
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token');
        axios.get(`${API_HOST}/all/users`, {
            params: {
                status: status.join(','),
                search_text: search_text
            }
        })
        .then(response => {
            if (response.status === 200) {
                var status = response.data.search_status;
                var users_data = response.data.user_data;
                console.log(status, 'backend search status')
                console.log(users_data, 'backend User data')
                this.setState({
                    usersListData: response.data.user_data
                });
                $('.loader').fadeOut('slow');

                // Update status checkboxes
                var status_list = ["Active", "Inactive"];
                for (var j = 0; j < status_list.length; j++) {
                    if (status.includes(status_list[j])) {
                        $("#" + status_list[j]).prop("checked", true);
                    } else {
                        $("#" + status_list[j]).prop("checked", false);
                    }
                }


            }
        })
        .catch(response => {
            // ErrorHandling(response)
        });

    
    };

    render() {
        return (
            <div className='container user-dashboard-container'>
                <UserPageHeader currentPage="user" clickTabHandler={this.clickTabHandler} />
                <div className='row main-dashboard user-main-dashboard mb-3'>
                    <div className='col-lg-2 col-md-2 col-12'>
                        <UserListLeftStatus thisData={this.state} clickTabHandler={this.clickTabHandler} />
                    </div>
                    <div className='col-lg-10 col-md-10 col-12 mt-1'>
                        <UserListRequest thisData={this.state} />

                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(UserList);



