import React, { Component } from 'react';
import $ from 'jquery';
import { API_HOST } from '../../Settings';
import axios from 'axios';



class TypeOfChange extends Component{
    constructor(props){
        super(props)
        /*this.state = {
            departments_data: [],
            plant_data: [],
        } */
    }

    handleChange = (e) =>{
        this.props.onChangeHandler(e,'')
    }

    handleTypeOfChangeDate = (e, for_attribute, access_type)=>{
        if (access_type === 'create') {
            this.props.handleTypeOfChangeDate(e, for_attribute);
        } else if (access_type === 'update') {
            this.props.onChangeHandler(e, for_attribute);
        }
    }


    /*componentDidMount(){
        this.getDepartmentData();
        this.getPlantData();
    }*/

    /*getDepartmentData = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/all/departments/data/`, )
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    departments_data:response.data.departments_data,
                })
                this.getPlantData()
            }

        }).catch(response => {
            // ErrorHandling(response)
        });
        $('.card-loader').fadeOut('slow')
    }*/
    
    /*getPlantData = () => {        
        if(this.props.thisData.site_name_slug){
            axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
            axios.get(`${API_HOST}/get/plants/in/site/${this.props.thisData.site_name_slug}/`, )
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        plant_data: response.data.plant_data,
                    })
                }

            }).catch(response => {
                // ErrorHandling(response)
            });
        }
        $('.card-loader').fadeOut('slow')
    }*/

    render(){
        var today_str = new Date().toISOString().split("T")[0]
        var min_date = this.props.thisData.date_of_moc
        var common_min_date = today_str
        if(min_date){
            var min_date_obj = new Date(min_date)
            if(min_date_obj!='Invalid Date'){
                common_min_date = min_date_obj.toISOString().split('T')[0]
            }
        }
        
        let deparment_options = this.props.thisData.departments_data.map((each_deparment,indx)=>{
            return(
                <option key={"dop"+indx} value={each_deparment.name} selected={this.props.thisData.department == each_deparment.name?true:false}>{each_deparment.name}</option>
            )
        })
        let plant_options = this.props.thisData.plant_data.map((each_plant,indx)=>{
            return(
                <option key={"pop"+indx} value={each_plant.name} selected={this.props.thisData.plant == each_plant.name?true:false}>{each_plant.name}</option>
            )
        })
        var access_type = "view"
        var proposed_change = ''
        var ask_for_more_history_filtered = []
        if(this.props.thisData){
            access_type = this.props.accessType
            proposed_change = this.props.thisData.proposed_change
            if(this.props.thisData.hasOwnProperty('ask_for_more_history')){
                ask_for_more_history_filtered = this.props.thisData.ask_for_more_history.filter((each, index)=>{
                    return each.status.name_slug=="open"
                })
            }
        }
        return(
            <div className="card" id='typeofchangeDiv'>
                {false?
                    <div>
                        {access_type} {proposed_change}
                    </div>:null
                }
                <div className="card-body">
                    {this.props.thisData.name?
                        <h5 className="card-title mb-3 green-title-color user-title">
                            <b># {this.props.thisData.name}</b>
                        </h5>:
                        <h5 className="card-title mb-3"> <b>Type of Change</b></h5>
                    }
                    <div className="form-check form-check-inline">
                        {["create", "update"].includes(access_type)? 
                            <input className="form-check-input" checked={proposed_change=="temporary_change"} type="radio" name="proposed_change" id="temporaryChange" value="temporary_change" onChange={(e) => this.props.onChangeHandler(e,'type_of_change')} />:
                            <input className="form-check-input" disabled={proposed_change!="temporary_change"} checked={proposed_change=='temporary_change'} type="radio" name="proposed_change" id="temporaryChange" value="temporary_change"/>
                        }
                        <label className="form-check-label" htmlFor="temporaryChange">Temporary change</label>
                    </div>
                    <div className="form-check form-check-inline">
                        {["create", "update"].includes(access_type)?
                            <input className="form-check-input" checked={proposed_change=="permanent_change"} type="radio" name="proposed_change" id="permanentChange" value="permanent_change" onChange={(e) => this.props.onChangeHandler(e,'type_of_change')} />:
                            <input className="form-check-input" disabled={proposed_change!="permanent_change"} checked={proposed_change=="permanent_change"} type="radio" name="proposed_change" id="permanentChange" value="permanent_change"/> 
                        }
                        <label className="form-check-label" htmlFor="permanentChange">Permanent change</label>
                    </div>
                    <div className='row align-items-start form-group mt-3 mb-3'>
                        <div className='col-lg-3 col-md-5 col-sm-6 col-12'>
                            <label className="form-label" htmlFor="sitename">Site Name</label>
                            <input className='form-control' name="sitename" id="sitename" type="text" value={this.props.thisData.sitename} disabled/>
                        </div>
                        <div className='col-lg-3 col-md-5 col-sm-6 col-12 offset-md-1'>
                            <label className="form-label" htmlFor="plantarea">Plant Area</label>
                            <select className="form-select"  id="plant" name="plant"   onChange={(e) => this.props.onChangeHandler(e,'type_of_change')} disabled={["create", "update"].includes(access_type) ? false:true}>
                                {plant_options}
                            </select>
                            {/* <select className="form-select"  id="plantarea" name="plant">
                                <option selected>Open this select menu</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select> */}
                        </div>                                

                    </div>
                    <div className='row align-items-start form-group mb-3'>
                        <div className='col-lg-3 col-md-5 col-sm-6 col-12'>
                            <label className="form-label" htmlFor="dateMoC">Date of MoC</label>
                            {/*<input className='form-control datepicker' name="date_of_moc" id="dateMoC" data-date-format="dd/mm/yyyy" disabled/>*/}
                            <input autoComplete='off' type="date"  className='form-control'  value={this.props.thisData.date_of_moc} name="date_of_moc" id="dateMoC" disabled={true} />
                        </div>
                        <div className='col-lg-3 col-md-5 col-sm-6 col-12 offset-md-1'>
                            <label className="form-label" htmlFor="initiatedBy">Proposal initiated by</label>
                            <input className='form-control' name="initiatedBy" id="initiatedBy" type="text" value={this.props.thisData.proposal_initiated_by} disabled/>
                        </div>

                    </div>

                    <div className='row align-items-start form-group mb-3'>                                
                        <div className='col-lg-3 col-md-5 col-sm-6 col-12'>
                            <label className="form-label" htmlFor="department">Department</label>
                            <select className="form-select"  id="department" name="department"  onChange={(e) => this.props.onChangeHandler(e,'type_of_change')} disabled={["create", "update"].includes(access_type) ? false:true}>
                                {deparment_options}
                            </select>
                        </div>                            
                        <div className='col-lg-3 col-md-5 col-sm-6 col-12 offset-md-1' style={{paddingRight:"0px"}}>
                            <label className="form-label" htmlFor="dateImplementation">Date of Implementation *</label>
                            {/*<input autoComplete='off' className='form-control datepicker' name="dateImplementation" onChange={(e) => this.handleChange(e,'')} id="dateImplementation" />*/}
                            {["create", "update"].includes(access_type)?
                                <input autoComplete='off' value={this.props.thisData.proposal_implementation_period} onChange={(e)=> this.handleTypeOfChangeDate(e,'dateImplementation',access_type)} type="date" min={common_min_date} className='form-control' name="dateImplementation" id="dateImplementation" />
                                :
                                <input autoComplete='off' value={this.props.thisData.proposal_implementation_period} type="date" disabled={true} className='form-control' name="dateImplementation" id="dateImplementation" />
                            }
                        </div>

                    </div>                           
                </div>                        
            </div>
        )
    }
}

export default TypeOfChange;