import React, { Component } from 'react';
import Toggle from '../CommonComponents/Toggle';
// import { ItemRowNames } from './MiscellaneousObject';
import $ from 'jquery';

class ItemTableRow extends Component {
    componentDidMount() {
        /*$('.datepicker').datepicker({
            autoclose: true,
            format: "dd/mm/yyyy",
            immediateUpdates: true,
            todayBtn: true,
            todayHighlight: true
        })  */      
    }
    componentDidUpdate() {
        /*if(this.props.accessType == "view"){
            $('.datepicker').datepicker('update')
        }else{
            $('.datepicker').datepicker({
                autoclose: true,
                format: "dd/mm/yyyy",
                immediateUpdates: true,
                todayBtn: true,
                todayHighlight: true
            })
        }*/

    }

    handleTargetDestroy = (e, targetId) => {
        $(document.getElementById(targetId)).val('');
    };


    ItemOnChange = (e, category_type, category, step, status, category_index, step_index) => {
        console.log('items data check...', e.target.tagName, e.target.value, category_type, category, step, status, category_index, step_index, 'ItemOnChange');
        const cachedData = JSON.parse(localStorage.getItem('cachedData')) || {};
        const date_of_complete = e.target.value
        const category_name = category.name
        const entryKey = `${category_name}_${step}_${category_type}`;


        cachedData.itemDate = { ...cachedData.itemDate, [entryKey]: { category_type, category_name, step, status, date_of_complete } };

        localStorage.setItem('cachedData', JSON.stringify(cachedData));

    }



    handleKeyDownDate = (event) => {
        event.preventDefault()
    }

    render() {
        var unique_combine = this.props.unique_combine
        var tr_content = this.props.itemData.map((itemRow, mainIndex) => {
            let tr_row = itemRow.rows.map((step, stepIndex) => {
                var unique_whom_id = unique_combine + '_item_' + mainIndex + '_ir_' + stepIndex + '_whom_id'
                var unique_date_id = unique_combine + '_item_' + mainIndex + '_ir_' + stepIndex + '_date_id'
                return (
                    <tr key={stepIndex}>
                        <td>{step.name}</td>
                        <td>
                            <Toggle onChangeHandler={this.props.onChangeHandler}
                                category={itemRow.header.name_slug}
                                step={step.name_slug} category_type="item"
                                accessType={this.props.accessType}
                                categoryIndex={mainIndex} stepIndex={stepIndex}
                                status={step.status} />
                        </td>
                        <td>
                            { this.props.accessType == 'view' || step.status == false ?
                                <input className='form-control'
                                    name={step.name_slug + "_whom"}
                                    // id={step.name_slug+"_whomid"}
                                    id={unique_whom_id}
                                    disabled={true} value={step.status == true ? step.remarks : null} /> :
                                <input className='form-control'
                                    name={step.name_slug + "_whom"}
                                    // id={step.name_slug+"_whomid"}
                                    id={unique_whom_id}
                                    value={this.props.accessType == 'update' && step.status == true ? step.remarks : null}
                                    onChange={(e) => this.props.onChangeHandler(e, 'item', itemRow.header,
                                        step.name_slug, step.status, mainIndex, stepIndex,
                                        { "remarks": e.target.value }
                                    )} />
                            }
                        </td>
                        <td>
                            {this.props.accessType === 'view' || step.status === false ? (
                                <div className='d-flex position-relative align-items-center targetDate-block'>
                                    <input
                                        className='form-control'
                                        type='date'
                                        name={step.name_slug}
                                        id={unique_date_id}
                                        autoComplete='off'
                                        onKeyDown={this.handleKeyDownDate}
                                        min={this.props.common_min_date_items}
                                        disabled={true}
                                        defaultValue={step.status === true ? step.date_of_complete : null}
                                    />
                                    <span
                                        className='position-absolute target-date-close'
                                        id={unique_date_id}
                                        onClick={(e) => {
                                            this.handleTargetDestroy(e, unique_date_id);
                                            this.props.ItemOnChange(e, 'item', itemRow.header, step.name_slug, step.status, mainIndex, stepIndex);
                                        }}
                                    >
                                        X
                                    </span>
                                </div>
                            ) : (
                                <div className='d-flex position-relative align-items-center targetDate-block'>
                                    <input
                                        className='form-control'
                                        type='date'
                                        name={step.name_slug}
                                        id={unique_date_id}
                                        onKeyDown={this.handleKeyDownDate}
                                        min={this.props.common_min_date_items}
                                        defaultValue={
                                            this.props.accessType === 'update' && step.status === true
                                                ? step.date_of_complete
                                                : null
                                        }
                                        onChange={(e) => {
                                            if (this.props.accessType === 'update') {
                                                this.props.ItemOnChange(e, 'item', itemRow.header, step.name_slug, step.status, mainIndex, stepIndex);
                                            }
                                        }}
                                        autoComplete='off'
                                    />
                                    <span
                                        className='position-absolute target-date-close'
                                        id={unique_date_id}
                                        onClick={(e) => {
                                            this.handleTargetDestroy(e, unique_date_id);
                                            if (this.props.accessType === 'update') {
                                                this.props.ItemOnChange(e, 'item', itemRow.header, step.name_slug, step.status, mainIndex, stepIndex);
                                            }
                                        }}
                                    >
                                        X
                                    </span>
                                </div>
                            )}
                        </td>
                    </tr>
                )
            })
            return (
                [
                    <tr span="3" key={mainIndex}><td><b>{itemRow.header.name}</b></td></tr>,
                    tr_row
                ]
            )
        })
        return (
            tr_content
        )
    }
}

export default ItemTableRow;