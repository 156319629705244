import React, { Component, createRef } from "react";
import { withRouter } from 'react-router-dom';
import ExistingTransferredTable from "./Step01/ExistingTransferredTable";
import { getManpowerChangeRequirement } from "../CommonComponents/MiscellaneousObject";
import $ from "jquery";
import { API_HOST } from "../../Settings";
import axios from 'axios';
import notify from '../CommonComponents/Notify';
import SideMenu from '../CommonComponents/SideMenu'
import {getCommonMinDateItems} from '../CommonFunctions'

class CreateManpowerRequest extends Component {
    constructor(props){
        super(props)
        this.state = {
            proposed_change:'',
            sitename:'',
            site_name_slug:'',
            plant:'',
            date_of_moc:new Date().toISOString().split('T')[0],
            proposal_initiated_by:'',
            department:'',
            proposal_implementation_period:'',
            existing_status:'',
            proposed_change:'',
            ManpowerChangeRequirementList:[],
            UsersList:[],
            manpowerStatus:{status:false,statusName:"New"},
            workflow_data:{},
            userPermissions: {},
            current_component_name: "ManpowerRequest",
            departments_data: [],
            plant_data: [],
        }
    }
    componentDidMount(){
        var roles = JSON.parse(localStorage.roles)
        if(roles.length==0 || !roles.includes("initiator")){
            window.location.href = "/dashboard"
        }
        this.getUserPrivileges()
        /*$('.datepicker').datepicker({
            autoclose: true,
            format: "dd/mm/yyyy",
            immediateUpdates: true,
            todayBtn: true,
            todayHighlight: true,
            minDate: 0,
        })*/
        // $('#dateMoC').datepicker("setDate", "0");
        var today_str = this.state.date_of_moc
        $('#dateMoC').val(today_str)
        var userData = JSON.parse(localStorage.getItem('user_data'))
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/all/users/list/`,)
            .then(response => {
                if (response.status === 200 && response.data.success) {
                    var users_data = JSON.parse(response.data.users_data)
                    this.setState({
                        sitename:userData.user_designation[0].site.name,
                        site_name_slug: userData.user_designation[0].site.name_slug,
                        plant:userData.user_designation[0].plant.name,
                        proposal_initiated_by:userData.name,
                        department:userData.user_designation[0].department.name,
                        ManpowerChangeRequirementList:getManpowerChangeRequirement(),
                        UsersList:users_data
                    })
                    this.getWorkflowData()
                    this.getDepartmentData()
                    $('.loader').fadeOut('slow')

                }
            }).catch(response => {
                // ErrorHandling(response)
            });        

    }


    componentDidUpdate() {
        const urlParams = new URLSearchParams(window.location.search);
        const workflow = urlParams.get('workflow');
        if (workflow) {
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    }
    
    getUserPrivileges = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        let privileges = JSON.stringify(['can_view', 'can_create', 'can_update'])
        let component = this.state.current_component_name
        axios.get(`${API_HOST}/user/privileges/${component}/${privileges}/`, )
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    userPermissions:response.data.data,
                })

            }
        }).catch(response => {
            // ErrorHandling(response)
        });
    }

    getWorkflowData = () =>{
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')  
        axios.get(`${API_HOST}//workflow/manpower-request/request/initiate/`, )
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    workflow_data:response.data.data
                })
            }
        }).catch(response => {
            // ErrorHandling(response)
        });
    }

    getDepartmentData = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/all/departments/data/`, )
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    departments_data:response.data.departments_data,
                })
                this.getPlantData()
            }

        }).catch(response => {
            // ErrorHandling(response)
        });
        $('.card-loader').fadeOut('slow')
    }
    getPlantData = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/plants/in/site/${this.state.site_name_slug}/`, )
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    plant_data: response.data.plant_data,
                })
            }

        }).catch(response => {
            // ErrorHandling(response)
        });
        $('.card-loader').fadeOut('slow')
    }

    manpowerStatusChange = (e,status,name) =>{
        e.preventDefault()
        /*$('.targetDate').datepicker({
            autoclose: true,
            format: "dd/mm/yyyy",
            immediateUpdates: true,
            todayBtn: true,
            todayHighlight: true,
            minDate: 0,
        })
        $('.targetDate').datepicker('setDate', null);*/
        $('.targetDate').val('')
        $('#Responsibility').prop('selectedIndex',0);
        if(name == "Existing"){
            var filterRequirmentList = getManpowerChangeRequirement().filter((obj,indx)=>{
                if(obj.type == "Existing"){
                    obj['rowAction']={status:false,buttonName:"No"}
                    obj['rowStatus'] = {status:false,buttonName:'Open'}
                    obj['responsibility'] = ""
                    return obj
                }
            })
        }else{
            var filterRequirmentList = getManpowerChangeRequirement().map((obj,indx)=>{
                obj['rowAction']={status:false,buttonName:"No"}
                obj['rowStatus'] = {status:false,buttonName:'Open'}
                obj['responsibility'] = ""
                return obj
            })
        }

        this.setState({
            manpowerStatus:{status:status,statusName:name},
            ManpowerChangeRequirementList:filterRequirmentList
        })        
    }

    changeActionStatus = (e, status, index, name, actionType) =>{
        var ManpowerData = this.state
        if(actionType == 'rowAction'){
            ManpowerData.ManpowerChangeRequirementList[index]['rowAction'] = {status:status,buttonName:name}
        }else if (actionType == "rowStatus"){
            ManpowerData.ManpowerChangeRequirementList[index]['rowStatus'] = {status:status,buttonName:name}
        }
        this.setState({
            ManpowerData
        })
    }

    changeResponsibility = (e, index) =>{
        var ManpowerChangeRequirementList = this.state.ManpowerChangeRequirementList
        ManpowerChangeRequirementList[index]['responsibility'] = e.currentTarget.value
        this.setState({
            ManpowerChangeRequirementList:ManpowerChangeRequirementList
        }) 

    }

    handleChange = (e, state_obj) =>{
        if(e.currentTarget.value == ""){e.currentTarget.classList.add("error")}
        if(e.currentTarget.value && e.currentTarget.classList.contains("error")){e.currentTarget.classList.remove("error")}
    }

    cancelRequest = (e) => {
        var msg = "Are you sure, you want to cancel request ?"
        if(window.confirm(msg)){
            this.props.history.push('/')
        }
    }

    isValid=()=>{
        var name_regexp = new RegExp("<(?:\"[^\"]*\"['\"]*|'[^']*'['\"]*|[^'\">])+>");
        var dateImplementation = document.getElementById('dateImplementation').value
        if(!dateImplementation || !document.querySelector("#Employee").value || document.querySelector("#Employee").value == "Open this select Employee" || !document.querySelector("#existingStatus").value || !document.querySelector("#proposedChange").value){
            var scrollDiv = ""
            if(!document.querySelector("#existingStatus").value){
                document.querySelector('#existingStatus').classList.add("error")
                scrollDiv = "existingStatus"
            }
            if(!document.querySelector("#proposedChange").value){
                document.querySelector('#proposedChange').classList.add("error")
                scrollDiv = "proposedChange"
            }
            if(document.querySelector("#Employee").options.selectedIndex == 0){
                document.querySelector('#Employee').classList.add("error")
                scrollDiv = "Employee"
            }
            if(!dateImplementation){
                document.querySelector('#dateImplementation').classList.add("error")
                scrollDiv = "dateImplementation"
            }
            return {"status":false, "reason": "Please fill required field", "id": scrollDiv}
        }
        if((document.querySelector("#proposedChange").value&&name_regexp.test(document.querySelector("#proposedChange").value))){
            document.querySelector('#proposedChange').classList.add("error")
            var scrollDiv = "proposedChange"
            return {"status":false, "reason": "Please provide valid information in input fields.", "id": scrollDiv}
        }
        if((document.querySelector("#existingStatus").value&&name_regexp.test(document.querySelector("#existingStatus").value))){
            document.querySelector('#existingStatus').classList.add("error")
            var scrollDiv = "existingStatus"
            return {"status":false, "reason": "Please provide valid information in input fields.", "id": scrollDiv}
        }
        var date_obj = new Date(dateImplementation)
        if(dateImplementation && date_obj!="Invalid Date"){
            var today = new Date()
            today.setHours(0,0,0,0)
            if (date_obj<today){
                document.querySelector('#dateImplementation').classList.add("error")
                scrollDiv = "dateImplementation"
                return {"status": false, "reason": "Proposed Implementation Period should not be past date", "id": scrollDiv}
            }
        }
        return {"status":true, "reason":""}
    }

    submitManpowerRequest = (e, requestFrom) =>{
        e.preventDefault()
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        var site_name = this.state.sitename
        var plant_name = this.state.plant
		axios.get(`${API_HOST}/can/initiate/request/?site_name=${site_name}&plant_name=${plant_name}`,)
		.then(response => {
			if(!response.data.status) {
                notify("User doesn't have initator role for site ("+site_name+") and plant ("+plant_name+")",'warning')
                setTimeout(function () {
                    document.querySelector('#sitename').scrollIntoView({
                        behavior: 'smooth', block: "center", inline: "nearest" 
                    })
                }, 100)
                return false
			}else{
                var response_isvalid = this.isValid()
                if(!response_isvalid.status){
                    notify(response_isvalid.reason,'warning')
                    setTimeout(function () {
                        document.querySelector('#'+response_isvalid.id).scrollIntoView({
                            behavior: 'smooth', block: "center", inline: "nearest" 
                        })
                    }, 100)
                    return false
                }
                if(requestFrom == 'Open'){
                    var msg = "Are you sure, you want submit manpower change request?"
                }else{
                    var msg = "Are you sure, you want to save as draft?"
                }
                if(window.confirm(msg)){
                    // $(".loader").css("display", "flex");
                    $(".loader").fadeIn()
                    var ManpowerData = this.state
                    // ManpowerData['date_of_moc'] = document.getElementById('dateMoC').value
                    ManpowerData['date_of_moc'] = this.state.date_of_moc
                    var dateImplementation  = document.getElementById('dateImplementation').value
                    ManpowerData['proposal_implementation_period'] = dateImplementation
                    ManpowerData['employee'] = document.getElementById('Employee').value
                    ManpowerData['existing_status'] = document.getElementById('existingStatus').value
                    ManpowerData['proposed_change'] = document.getElementById('proposedChange').value
                    ManpowerData['status'] = requestFrom
                    ManpowerData.ManpowerChangeRequirementList.map((manpowerRequest,indx)=>{
                        let target_date = document.getElementById(manpowerRequest.name_slug).value
                        ManpowerData.ManpowerChangeRequirementList[indx]['target_date'] = target_date
                        return manpowerRequest
                    })   
                    const auth_token = localStorage.getItem("token")
                        axios.defaults.headers.post['Authorization'] =auth_token      
                        axios.post(`${API_HOST}/save/manpower/change/request/data/`, ManpowerData)
                        .then(response => {
                            if (response.status === 200 && response.data.success) {                    
                                notify(response.data.message,'success')
                                setTimeout(() =>{
                                    $(".loader").fadeOut('slow')
                                    // $('#approve-as-variant-model').modal('hide');
                                    this.props.history.push('/manpower/change/request/'+response.data.document_number+'/')
                                },1000)
                            }else{
                                notify(response.data.message,'warning')
                                $('.loader').fadeOut('slow')
                            }
                            // $('.loader').fadeOut('slow')
                        }).catch(response => {
                            // $('.loader').fadeOut('slow')
                            // ErrorHandling(response)
                        });
        
                }
            }
        })

    }

    handleChangeDropdown = (e) => {
        this.setState({[e.target.name]:e.target.value})
    }

    render () {
        // var today_str = new Date().toISOString().split("T")[0]

        var common_min_date_items = getCommonMinDateItems(this.state.date_of_moc)

        let user_data = this.state.UsersList.map((userData,indx)=>{
            return(
                <option value={userData.email}>{userData.name}</option>
            )
        })
        var access_type = "create"
        let deparment_options = this.state.departments_data.map((each_deparment,indx)=>{
            return(
                <option key={"dop"+indx} value={each_deparment.name} selected={this.state.department == each_deparment.name?true:false}>{each_deparment.name}</option>
            )
        })
        let plant_options = this.state.plant_data.map((each_plant,indx)=>{
            return(
                <option key={"pop"+indx} value={each_plant.name} selected={this.state.plant == each_plant.name?true:false}>{each_plant.name}</option>
            )
        })


        return (
        <div className="container manpower-container">
            <div className="row">
               <div className="col-lg-3 col-md-3 col-sm-12 side-nav-block">
                    <div className="card sticky-card">
                        <div className="card-body sticky-body">
                            {this.state.workflow_data.name_slug ?
                                <SideMenu activeNameSlug="initiator-of-manpower-change" RequestType="manpower-request" />
                            : ""}
                        </div>
                    </div>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-12">
                    <div className="card mb-3">
                        <div className="card-body">
                        <h5 className="card-title mb-3"> <b>Manpower Change Request</b></h5>
                            <div className='row align-items-start form-group mt-3 mb-3'>
                                <div className='col-lg-3 col-md-3 col-6'>
                                    <label className="form-label" htmlFor="sitename">Site Name</label>
                                    <input className='form-control' name="sitename" id="sitename" type="text" value={this.state.sitename} disabled/>
                                </div>
                                <div className='col-lg-3 col-md-3 col-6 offset-md-1'>
                                    <label className="form-label" htmlFor="plantarea">Plant Area</label>
                                    {/*<input className='form-control' name="plant" id="plant" type="text" value={this.state.plant} disabled/>*/}
                                    <select className="form-select"  id="plant" name="plant" onChange={(e)=>this.handleChangeDropdown(e)}>
                                        {plant_options}
                                    </select>
                                </div>

                            </div>
                            <div className='row align-items-start form-group mb-3'>
                                <div className='col-lg-3 col-md-3 col-6'>
                                    <label className="form-label" htmlFor="dateMoC">Date of MoC Initiation</label>
                                    {/*<input className='form-control datepicker' name="date_of_moc" id="dateMoC"/>*/}
                                    <input autoComplete='off' type="date"  className='form-control' name="date_of_moc" id="dateMoC" disabled={true} />
                                </div>
                                <div className='col-lg-3 col-md-3 col-6 offset-md-1'>
                                    <label className="form-label" htmlFor="initiatedBy">Proposal Initiated by</label>
                                    <input className='form-control' name="initiatedBy" id="initiatedBy" type="text" value={this.state.proposal_initiated_by} disabled/>
                                </div>

                            </div>

                            <div className='row align-items-start form-group mb-3'>
                                <div className='col-lg-3 col-md-3 col-6'>
                                    <label className="form-label" htmlFor="deparment">Department</label>
                                    {/*<input className='form-control' name="deparment" id="deparment" type="text" value={this.state.department} disabled/>*/}
                                    <select className="form-select"  id="department" name="department" onChange={(e)=>this.handleChangeDropdown(e)}>
                                        {deparment_options}
                                    </select>
                                </div>
                                <div className='col-lg-3 col-md-3 col-6 offset-md-1'>
                                    <label className="form-label" htmlFor="dateImplementation">Proposed Implementation Period *</label>
                                    {/*<input autoComplete='off' className='form-control datepicker' name="dateImplementation" id="dateImplementation" />*/}
                                    <input autoComplete='off' type="date" min={common_min_date_items} className='form-control' name="dateImplementation" id="dateImplementation" />
                                </div>

                            </div>   
                            <div className='row align-items-start form-group mb-3'>
                                <div className='col-lg-3 col-md-3 col-12'>
                                    <label className="form-label" htmlFor="dateMoC">Employee *</label>
                                    <select className="form-select"  id="Employee" name="Employee" onChange={(e) => this.handleChange(e,'')}>
                                        <option disabled={true} selected>Open this select Employee</option>
                                        {user_data}
                                    </select>
                                </div>
                                <div className='col-lg-8 col-md-8 col-12 offset-md-1'>
                                    <label className="form-label" htmlFor="existingStatus">Existing Status *</label>
                                    <textarea className='form-control' rows="2" name="existing_status" id="existingStatus" type="text" onChange={(e) => this.handleChange(e,'')}></textarea>
                                </div>
                            </div>  

                            <div className='row align-items-start form-group mb-3'>
                                <div className='col-12'>
                                    <label className="form-label" htmlFor="proposedChange">Proposed Change *</label>
                                    <textarea className='form-control' rows="3" name="proposed_change" id="proposedChange" type="text" onChange={(e) => this.handleChange(e,'')}></textarea>
                                    <span className='float-right'>(Note: Attach additional sheet if required)</span>
                                </div>
                            </div> 
                        </div>                        
                    </div>
                    <ExistingTransferredTable 
                        UsersList={this.state.UsersList} 
                        ManpowerChangeRequirementList={this.state.ManpowerChangeRequirementList} 
                        manpowerStatusChange={this.manpowerStatusChange}
                        manPowerState={this.state}
                        requestFrom="Create"
                        changeActionStatus = {this.changeActionStatus}
                        common_min_date_items = {common_min_date_items}
                        changeResponsibility = {this.changeResponsibility}
                        accessType={access_type}
                    />
                    <div className='card mt-3 mb-3'>
                        <div className='card-body'>
                            <div class="d-flex justify-content-end gap-2 d-md-flex justify-content-md-end">
                                <button className="btn btn-outline-secondary cancel-btn" type="button" id="cancel" onClick={(e) => this.cancelRequest(e,'Cancel')}>Cancel</button>
                                {this.state.userPermissions.can_create ?
                                [<button className="btn btn-light" type="button" id="save-as-draft" onClick={(e) => this.submitManpowerRequest(e, 'Save as Draft')} >Save Draft</button>,
                                <button className="btn btn-dark save-btn" type="submit" id="submit" onClick={(e) => this.submitManpowerRequest(e,'Open')} >Submit</button>]
                                :null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            
        )
    }
}

export default withRouter(CreateManpowerRequest);