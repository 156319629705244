import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import ExistingTransferredTable from "./Step01/ExistingTransferredTable";
import { getManpowerChangeRequirement } from "../CommonComponents/MiscellaneousObject";
import $ from "jquery"
import { API_HOST } from "../../Settings";
import axios from 'axios';
import notify from '../CommonComponents/Notify';
import SideMenu from '../CommonComponents/SideMenu'
import ManpowerStageComponents from './AllManpowerStageComponents';
import Signed from "../ConsentComponents/Signed";
import {getCommonMinDateItems} from '../CommonFunctions'

class ManpowerRequest extends Component {  
    constructor(props){
        super(props)
        this.state = {
            requestID: this.props.match.params.requestID,
            proposed_change:'',
            sitename:'',
            site_name_slug: '',
            plant:'',
            date_of_moc:'',
            proposal_initiated_by:'',
            department:'',
            proposal_implementation_period:'',
            existing_status:'',
            proposed_change:'',
            ManpowerChangeRequirementList:getManpowerChangeRequirement(),
            UsersList:[],
            manpowerStatus:{status:false,statusName:"New"},
            ask_for_more_history:[],
            manpower_change_status: "",
            userPermissions: {},
            current_component_name: "ManpowerRequest",
            departments_data: [],
            plant_data: [],
            ask_more_stage_name_slug : "",
            WorkflowSignatures:[],

        }
    }
    componentDidMount(){
        this.getUserPrivileges()
        /*$('.datepicker').datepicker({
            autoclose: true,
            format: "dd/mm/yyyy",
            immediateUpdates: true,
            todayBtn: true,
            todayHighlight: true
        })*/
        // $('#dateMoC').datepicker("setDate", "0");
        // var userData = JSON.parse(localStorage.getItem('user_data'))
        this.getAllData()
        this.getDepartmentData()
    }

    getUserPrivileges = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        let privileges = JSON.stringify(['can_view', 'can_create', 'can_update'])
        let component = this.state.current_component_name
        let params = {"document_number": this.state.requestID, "from_new": true, "request_type": "manpower_request"}
        axios.get(`${API_HOST}/user/privileges/${component}/${privileges}/`, {params: params})
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    userPermissions:response.data.data,
                })

            }
        }).catch(response => {
            // ErrorHandling(response)
        });
    }

    getAllData =(e)=>{
        $('.card-loader').fadeIn()
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/manpower/change/request/${this.state.requestID}/data/`,)
            .then(response => {
                if (response.status === 200 && response.data.success) {
                    var moc_request_data = response.data.moc_request_data
                    var requirement_list = this.getFilterRequirmentList(moc_request_data.manpower_change_type) //this.state.ManpowerChangeRequirementList
                    var actual_requirement_list = moc_request_data.manpower_requirement_list
                    requirement_list.map((req_obj, index) => {
                        actual_requirement_list.map((actualReqObj, rowIndex) => {
                            if(req_obj.name_slug == actualReqObj.name_slug){
                                requirement_list[index] = actualReqObj
                            }
                        })
                    })
                    moc_request_data['requestID'] = this.state.requestID
                    moc_request_data['ManpowerChangeRequirementList'] = requirement_list
                    // $('#dateMoC').datepicker('update', moc_request_data.date_of_moc)
                    $('#dateMoC').val(moc_request_data.date_of_moc)
                    // $('#dateImplementation').datepicker('update', moc_request_data.proposal_implementation_period)
                    $('#dateImplementation').val(moc_request_data.proposal_implementation_period)
                    // document.querySelectorAll('input, select, button, textarea').forEach(elem => elem.disabled = true);
                    this.setState(moc_request_data)
                    if(moc_request_data.manpower_change_type == "New"){
                        this.setState({manpowerStatus:{status:false, statusName:moc_request_data.manpower_change_type}})
                    }else{
                        this.setState({manpowerStatus:{status:true, statusName:moc_request_data.manpower_change_type}})
                    }

                    axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
                    axios.get(`${API_HOST}/workflow/manpower/request/${this.props.DocumentNumber}/stage/initiator-of-manpower-change/`,)
                        .then(response2 => {
                            if(response2.data.data.hasOwnProperty('ask_for_more_history')){
                                this.setState({
                                    "ask_for_more_history": response2.data.data.ask_for_more_history,
                                })
                                var ask_for_more_history_react_component = []
                                var ask_for_more_history_filtered = response2.data.data.ask_for_more_history.filter(
                                            (each, index)=>{
                                                return each.status.name_slug=="open"
                                        })
                                this.props.updateSideMenu(ask_for_more_history_filtered.length>0)
                                if (ask_for_more_history_filtered.length > 0){
                                    this.setState({
                                        ask_more_stage_name_slug:ask_for_more_history_filtered[0].stage.name_slug
                                    })
                                    // console.log(ask_for_more_history_react_component)
                                }
                                if(response2.data.data.workflow_stage_info && response2.data.data.workflow_stage_info.components && response2.data.data.workflow_stage_info.components[0].workflow_signatures){
                                    this.setState({
                                        WorkflowSignatures: response2.data.data.workflow_stage_info.components[0].workflow_signatures
                                    })
                                }


                            }
                        }).catch(error_response=>{})

                    this.getPlantData(moc_request_data.site_name_slug)

                    $('.card-loader').fadeOut('slow')
                    $('.loader').fadeOut('slow')

                }else{
                    notify(response.data.message,'error')
                }
            }).catch(response => {
                // ErrorHandling(response)
            });
    }

    getDepartmentData = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/all/departments/data/`, )
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    departments_data:response.data.departments_data,
                })
                // this.getPlantData()
            }

        }).catch(response => {
            // ErrorHandling(response)
        });
        $('.card-loader').fadeOut('slow')
    }

    getPlantData = (site_name_slug) => {
        if(site_name_slug){
            axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
            axios.get(`${API_HOST}/get/plants/in/site/${site_name_slug}/`, )
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        plant_data: response.data.plant_data,
                    })
                }

            }).catch(response => {
                // ErrorHandling(response)
            });
        }
        $('.card-loader').fadeOut('slow')
    }

    getFilterRequirmentList = (name) => {
        if(name == "Existing"){
            var filterRequirmentList = getManpowerChangeRequirement().filter((obj,indx)=>{
                if(obj.type == "Existing"){
                    obj['rowAction']={status:false,buttonName:"No"}
                    obj['rowStatus'] = {status:false,buttonName:'Open'}
                    obj['responsibility'] = ""
                    return obj
                }
            })
        }else{
            var filterRequirmentList = getManpowerChangeRequirement().map((obj,indx)=>{
                obj['rowAction']={status:false,buttonName:"No"}
                obj['rowStatus'] = {status:false,buttonName:'Open'}
                obj['responsibility'] = ""
                return obj
            })
        }
        this.setState({
            ManpowerChangeRequirementList:filterRequirmentList
        })

        return filterRequirmentList
    }


    manpowerStatusChange = (e,status,name) =>{
        e.preventDefault()
        /*$('.targetDate').datepicker({
            autoclose: true,
            format: "dd/mm/yyyy",
            immediateUpdates: true,
            todayBtn: true,
            todayHighlight: true
        })
        $('.targetDate').datepicker('setDate', null);*/
        $('.targetDate').val('')
        $('#Responsibility').prop('selectedIndex',0);
        if(name == "Existing"){
            var filterRequirmentList = getManpowerChangeRequirement().filter((obj,indx)=>{
                if(obj.type == "Existing"){
                    obj['rowAction']={status:false,buttonName:"No"}
                    obj['rowStatus'] = {status:false,buttonName:'Open'}
                    obj['responsibility'] = ""
                    return obj
                }
            })
        }else{
            var filterRequirmentList = getManpowerChangeRequirement().map((obj,indx)=>{
                obj['rowAction']={status:false,buttonName:"No"}
                obj['rowStatus'] = {status:false,buttonName:'Open'}
                obj['responsibility'] = ""
                return obj
            })
        }

        this.setState({
            manpowerStatus:{status:status,statusName:name},
            ManpowerChangeRequirementList:filterRequirmentList
        })        
    }

    changeActionStatus = (e, status, index, name, actionType) =>{
        var ManpowerData = this.state
        if(actionType == 'rowAction'){
            ManpowerData.ManpowerChangeRequirementList[index]['rowAction'] = {status:status,buttonName:name}
        }else if (actionType == "rowStatus"){
            ManpowerData.ManpowerChangeRequirementList[index]['rowStatus'] = {status:status,buttonName:name}
        }
        this.setState({
            ManpowerData
        })
    }

    changeResponsibility = (e, index) =>{
        var ManpowerChangeRequirementList = this.state.ManpowerChangeRequirementList
        ManpowerChangeRequirementList[index]['responsibility'] = e.currentTarget.value
        this.setState({
            ManpowerChangeRequirementList:ManpowerChangeRequirementList
        }) 

    }
    
    isValid=()=>{
        var name_regexp = new RegExp("<(?:\"[^\"]*\"['\"]*|'[^']*'['\"]*|[^'\">])+>");
        var dateImplementation = document.getElementById('dateImplementation').value
        if(!dateImplementation || !document.querySelector("#Employee").value || !document.querySelector("#existingStatus").value || !document.querySelector("#proposedChange").value){
            var scrollDiv = ""
            if(!document.querySelector("#existingStatus").value){
                document.querySelector('#existingStatus').classList.add("error")
                scrollDiv = "existingStatus"
            }
            if(!document.querySelector("#proposedChange").value){
                document.querySelector('#proposedChange').classList.add("error")
                scrollDiv = "proposedChange"
            }
            if(document.querySelector("#Employee").options.selectedIndex == 0){
                document.querySelector('#Employee').classList.add("error")
                scrollDiv = "Employee"
            }
            if(!dateImplementation){
                document.querySelector('#dateImplementation').classList.add("error")
                scrollDiv = "dateImplementation"
            }
            return {"status":false, "reason": "Please fill required field", "id": scrollDiv}
        }
        if((document.querySelector("#proposedChange").value&&name_regexp.test(document.querySelector("#proposedChange").value))){
            document.querySelector('#proposedChange').classList.add("error")
            var scrollDiv = "proposedChange"
            return {"status":false, "reason": "Please provide valid information in input fields.", "id": scrollDiv}
        }
        if((document.querySelector("#existingStatus").value&&name_regexp.test(document.querySelector("#existingStatus").value))){
            document.querySelector('#existingStatus').classList.add("error")
            var scrollDiv = "existingStatus"
            return {"status":false, "reason": "Please provide valid information in input fields.", "id": scrollDiv}
        }
        var date_obj = new Date(dateImplementation)
        if(dateImplementation && date_obj!="Invalid Date"){
            var common_min_date_obj = new Date(this.getCommonMinDate())
            common_min_date_obj.setHours(0,0,0,0)
            if (date_obj<common_min_date_obj){
                document.querySelector('#dateImplementation').classList.add("error")
                scrollDiv = "dateImplementation"
                return {"status": false, "reason": "Date of Implementation should not be past date", "id": scrollDiv}
            }
        }
        
        return {"status":true, "reason":""}
    }
    submitManpowerRequest = (e) =>{
        e.preventDefault()
        var response_isvalid = this.isValid()
        if(!response_isvalid.status){
            notify(response_isvalid.reason,'warning')
            setTimeout(function () {
                document.querySelector('#'+response_isvalid.id).scrollIntoView({
                    behavior: 'smooth', block: "center", inline: "nearest" 
                })
            }, 100)
            return false
        }
        var msg = "Are you sure, you want to save changes?"
        if(window.confirm(msg)){
            $('.loader').fadeIn()
            var ManpowerData = this.state
            // ManpowerData['date_of_moc'] = document.getElementById('dateMoC').value
            ManpowerData['date_of_moc'] = this.state.date_of_moc
            ManpowerData['proposal_implementation_period'] = document.getElementById('dateImplementation').value
            ManpowerData['employee'] = document.getElementById('Employee').value
            ManpowerData['existing_status'] = document.getElementById('existingStatus').value
            ManpowerData['proposed_change'] = document.getElementById('proposedChange').value
            ManpowerData['status'] = ''
            ManpowerData.ManpowerChangeRequirementList.map((manpowerRequest,indx)=>{
                let target_date = document.getElementById(manpowerRequest.name_slug).value
                ManpowerData.ManpowerChangeRequirementList[indx]['target_date'] = target_date
                return manpowerRequest
            })
            
            const auth_token = localStorage.getItem("token")
            axios.defaults.headers.post['Authorization'] = auth_token
            var document_number = this.props.DocumentNumber
            axios.post(`${API_HOST}/manpower/change/request/${document_number}/data/update/`, ManpowerData)
                .then(response => {
                    notify('Changes saved successfully','success')
                    // this.getAllData()
                   setTimeout(()=>{
                        $('.loader').fadeOut('slow')
                        window.location.reload();
                    }, 3000)
                }).catch(response => {
                    if(response.response.status == 404){
                        notify(response.response.data.reason,'warning')
                        $('.loader').fadeOut('slow')
                    }
                    // ErrorHandling(response)
                });

        }

    }

    cancelRequest = (e) => {
        var msg = "Are you sure, you want to cancel request ?"
        if(window.confirm(msg)){
            this.props.history.push('/')
        }
    }

    handleChangeDropdown = (e) => {
        this.setState({[e.target.name]:e.target.value})
    }

    handleChange = (e, state_obj) =>{
        if(e.currentTarget.value == ""){e.currentTarget.classList.add("error")}
        if(e.currentTarget.value && e.currentTarget.classList.contains("error")){e.currentTarget.classList.remove("error")}
        this.setState({[e.target.name]:e.target.value})
    }

    getCommonMinDate = (event)=>{
        var today_str = new Date().toISOString().split("T")[0]
        var min_date = this.state.date_of_moc
        var common_min_date = today_str
        if(min_date){
            var min_date_obj = new Date(min_date)
            if(min_date_obj!='Invalid Date'){
                common_min_date = min_date_obj.toISOString().split('T')[0]
            }
        }
        return common_min_date
    }


    render () {
        var access_type = "view"
        var common_min_date = this.getCommonMinDate()
        var common_min_date_items = getCommonMinDateItems(this.state.date_of_moc)

        var ask_for_more_history_filtered = []
        if(this.state){
            if(this.state.hasOwnProperty('ask_for_more_history')){
                ask_for_more_history_filtered = this.state.ask_for_more_history.filter((each, index)=>{
                    return each.status.name_slug=="open"
                })
            }
        }
        if(this.state.ask_more_stage_name_slug != "" && !(['closure-of-moc-by-initiator-hod', 'executors-s-hod-reviews-performance', 'review-and-close'].includes(this.state.ask_more_stage_name_slug))){
            if(ask_for_more_history_filtered.length>0){
                access_type  ="update"
            }
        }
        if(this.state.manpower_change_status=='Save as Draft'){
            access_type = 'update'
        }

        let user_data = this.state.UsersList.map((userData,indx)=>{
            return(
                <option value={userData.email} selected={this.state.employee == userData.email?true:false} >{userData.first_name} {userData.last_name}</option>
            )
        })
        let deparment_options = this.state.departments_data.map((each_deparment,indx)=>{
            return(
                <option key={"jobop"+indx} value={each_deparment.name} selected={this.state.department == each_deparment.name?true:false}>{each_deparment.name}</option>
            )
        })
        let plant_options = this.state.plant_data.map((each_plant,indx)=>{
            return(
                <option key={"pop"+indx} value={each_plant.name} selected={this.state.plant == each_plant.name?true:false}>{each_plant.name}</option>
            )
        })
        // let user_data = this.state.UsersList.map((userData,indx)=>{
        //     return(
        //         <option value={userData.email}>{userData.name}</option>
        //     )
        // })

        
        return (
                <div>
                    {false && ask_for_more_history_filtered.length>0?
                        <div className="alert alert-info">
                          More information requested
                        </div>:null
                    }
                    <h5 className="card-title mb-3 user-title"> <b># {this.state.document_number}</b></h5>
                        <div className='row align-items-start form-group mt-3 mb-3'>
                            <div className='col-lg-3 col-md-5 col-6'>
                                <label className="form-label" htmlFor="sitename">Site Name</label>
                                <input className='form-control' name="sitename" id="sitename" type="text" value={this.state.sitename} disabled/>
                            </div>
                            <div className='col-lg-3 col-md-5 col-6 offset-md-1'>
                                <label className="form-label" htmlFor="plantarea">Plant Area</label>
                                {/*<input className='form-control' name="plant" id="plant" type="text" value={this.state.plant} disabled/>*/}
                                <select className="form-select"  id="plant" name="plant" onChange={(e)=>this.handleChangeDropdown(e)} disabled={access_type == "view" ? true:false}>
                                    {plant_options}
                                </select>
                            </div>                               

                        </div>
                        <div className='row align-items-start form-group mb-3'>
                            <div className='col-lg-3 col-md-5 col-6'>
                                <label className="form-label" htmlFor="dateMoC">Date of MOC Initiation</label>
                                {/*<input className='form-control datepicker' name="date_of_moc" id="dateMoC" disabled={access_type == "view" ? true:false}/>*/}
                                <input autoComplete='off' type="date"  className='form-control' name="date_of_moc" id="dateMoC" disabled={true} />
                            </div>
                            <div className='col-lg-3 col-md-5 col-6 offset-md-1'>
                                <label className="form-label" htmlFor="initiatedBy">Proposal Initiated by</label>
                                <input className='form-control' name="initiatedBy" id="initiatedBy" type="text" value={this.state.proposal_initiated_by} disabled/>
                            </div>

                        </div>

                        <div className='row align-items-start form-group mb-3'>                                
                            <div className='col-lg-3 col-md-5 col-6'>
                                <label className="form-label" htmlFor="deparment">Department</label>
                                {/*<input className='form-control' name="deparment" id="deparment" type="text" value={this.state.department} disabled/>*/}
                                <select className="form-select"  id="department" name="department" onChange={(e)=>this.handleChangeDropdown(e)} disabled={access_type == "view" ? true:false}>
                                    {deparment_options}
                                </select>
                            </div>                            
                            <div className='col-lg-3 col-md-5 col-6 offset-md-1'>
                                <label className="form-label" htmlFor="dateImplementation">Proposed Implementation Period *</label>
                                {/*<input autoComplete='off' className='form-control datepicker' name="dateImplementation" id="dateImplementation" disabled={access_type == "view" ? true:false} />*/}
                                <input autoComplete='off' type="date" min={common_min_date} className='form-control' name="dateImplementation" id="dateImplementation" disabled={access_type == "view" ? true:false} />
                            </div>

                        </div>   
                        <div className='row align-items-start form-group mb-3'>
                            <div className='col-lg-3 col-md-5 col-12'>
                                <label className="form-label" htmlFor="dateMoC">Employee *</label>
                                <select className="form-select"  id="Employee" name="Employee" disabled={access_type == "view" ? true:false}>
                                    <option disabled={true} selected>Open this select Employee</option>
                                    {user_data}
                                </select>
                            </div>
                            <div className='col-lg-8 col-md-8 col-12 offset-md-1'>
                                <label className="form-label" htmlFor="existingStatus">Existing Status *</label>
                                <textarea className='form-control' rows="2" name="existing_status" id="existingStatus" type="text" value={this.state.existing_status} onChange={(e) => this.handleChange(e,'')} disabled={access_type == "view" ? true:false}></textarea>
                            </div>
                        </div>  

                        <div className='row align-items-start form-group mb-3'>
                            <div className='col-12'>
                                <label className="form-label" htmlFor="proposedChange">Proposed Change *</label>
                                <textarea className='form-control' rows="3" name="proposed_change" id="proposedChange" type="text" value={this.state.proposed_change} onChange={(e) => this.handleChange(e,'')} disabled={access_type == "view" ? true:false}></textarea>
                                <span className='float-right'>(Note: Attach additional sheet if required)</span>
                            </div>
                        </div>
                    <ExistingTransferredTable 
                        UsersList={this.state.UsersList} 
                        ManpowerChangeRequirementList={this.state.ManpowerChangeRequirementList} 
                        manpowerStatusChange={this.manpowerStatusChange}
                        manPowerState={this.state}
                        requestFrom="View"
                        changeActionStatus = {this.changeActionStatus}
                        common_min_date_items = {common_min_date_items}
                        changeResponsibility = {this.changeResponsibility}
                        accessType={access_type}
                    />

                    <div className='card-body'>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                            {this.state.WorkflowSignatures.length > 0?<Signed SignatureData={this.state.WorkflowSignatures[0]}/>:""}
                        </div>
                    </div>

                    <div key={this.state.unique_combine+"k"}>
                        {this.state.userPermissions.can_update && (access_type=='update') ?
                            <div className='card-body'>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <button className="btn btn-outline-secondary" type="button" id="cancel" onClick={(e) => this.cancelRequest(e,'Cancel')}>Cancel</button>
                                    <button className="btn btn-dark" type="submit" id="submit"
                                        onClick={(e) => this.submitManpowerRequest(e)}>
                                        Update
                                    </button>
                                </div>
                            </div>:null
                        }
                    </div>

                </div>
        )
    }
}

export default withRouter(ManpowerRequest);