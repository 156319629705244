import React, { Component } from 'react';
import './TaskPopup.css';
import { FaRegCheckCircle } from 'react-icons/fa';
import { BiTrash } from 'react-icons/bi';
import { BsPersonCircle } from 'react-icons/bs';
import { FiPlus } from 'react-icons/fi';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { API_HOST } from '../../../Settings';
import notify from '../../CommonComponents/Notify';
import $ from 'jquery';
class TaskPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            taskName: '',
            description: '',
            tasks: [],
        };
        this.newTaskRef = React.createRef();
    }

    togglePopup = () => {
        this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleTaskInputChange = (index, event) => {
        const { name, value } = event.target;
        const tasks = [...this.state.tasks];
        tasks[index][name] = value;
        this.setState({ tasks });
    };

    handleDateChange = (date) => {
        this.setState({ dueDate: date });
    };

    formatDueDate = (date) => {
        if (!date) return "No due date";
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Date(date).toLocaleDateString('en-GB', options);
    };

    addTask = () => {
        const { tasks } = this.state;
        const emptyTaskIndex = tasks.findIndex(task => task.subtaskName === '');

        if (emptyTaskIndex !== -1) {
            const emptyTaskRef = this[`taskRef${emptyTaskIndex}`];
            if (emptyTaskRef && emptyTaskRef.current) {
                emptyTaskRef.current.focus();

                setTimeout(() => {
                    emptyTaskRef.current.blur();
                    setTimeout(() => {
                        emptyTaskRef.current.focus();
                    }, 200);
                }, 200);
            }
            return;
        }

        const newTask = { subtaskName: '', assignee: this.props.assignee_id };

        this.setState(
            (prevState) => ({
                tasks: [...prevState.tasks, newTask],
            }),
            () => {
                const newIndex = this.state.tasks.length - 1;
                const newTaskRef = this[`taskRef${newIndex}`];
                if (newTaskRef && newTaskRef.current) {
                    newTaskRef.current.focus();

                    setTimeout(() => {
                        newTaskRef.current.blur();
                        setTimeout(() => {
                            newTaskRef.current.focus();
                        }, 200);
                    }, 200);
                }
            }
        );
    };

    deleteTask = (index) => {
        this.setState((prevState) => {
            const tasks = [...prevState.tasks];
            tasks.splice(index, 1);
            return { tasks };
        });
    };
    addSubTasks = () =>{
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')
         var task_data = {
            'main_task_id':this.props.main_task_id,
            'tasks':this.state.tasks,
        }
        axios.post(`${API_HOST}/add/sub/tasks/`,task_data )
            .then(response => {
                if (response.status === 200) {
                    notify(response.data.message,'success')
                    setTimeout(()=>{
                        $('.loader').fadeOut('slow')
                        window.location.reload();
                    }, 3000)
                }

            }).catch(response => {
                // ErrorHandling(response)
            });
    };
    
    // submitData = async () => {
    //     const { taskName, description, tasks, assignee, dueDate } = this.state;
    //     console.log(taskName, 'taskName');
    //     console.log(description, 'description');
    //     console.log(tasks, 'tasks');
    //     console.log(assignee, 'assignee');

    //     try {
    //         const response = await axios.post('/moc/subtask', {
    //             taskName,
    //             description,
    //             tasks
    //         });
    //         console.log('Data submitted successfully:', response.data);
    //         this.togglePopup();
    //     } catch (error) {
    //         console.error('Error submitting data:', error);
    //         this.togglePopup();
    //     }
    // };

    render() {
        return (
            <div>
                <div style={{marginRight:'5px'}}>
                    {this.props.main_task_status === 'pending' && this.props.is_plant_moc_lead == true  &&(
                    <button className="btn btn-success btn-sm" onClick={this.togglePopup}>Add subtask</button>
                    )}
                </div>
                {this.state.isOpen && (
                    <div className="popup">
                        <div className="popup-content">
                            <div className="popup-header">
                                <label className="task-main-heading">
                                    Add Subtask <span style={{ color: 'gray' }}>({this.props.document_number})</span>
                                </label>
                                <span className="close-btn" onClick={this.togglePopup}>
                                    &times;
                                </span>
                            </div>

                            <div className="popup-body">
                              
                                <div>
                                    <button className="add-task-button" onClick={this.addTask}>
                                        <span>
                                            <FiPlus className="add-task-icon" />
                                        </span>
                                        <span className="add-task-text">
                                            Add subtask
                                        </span>
                                    </button>
                                </div>

                                {this.state.tasks.length > 0 && (
                                    <div className="overflow-x-scroll mt-3">
                                        <table className="table table-bordered">
                                            <thead className="vertical-align-middle">
                                                <tr>
                                                    <th width="50%"><b>SubTask</b></th>
                                                    <th className="text-center"><b>Action</b></th>
                                                </tr>
                                            </thead>
                                            <tbody className="vertical-align-middle">
                                                {this.state.tasks.map((task, index) => (
                                                    <tr key={index}>
                                                        <td width="90%">
                                                            <div className="icon-text-wrapper">
                                                                <FaRegCheckCircle className="task-icon" />
                                                                <input
                                                                    type="text"
                                                                    name="subtaskName"
                                                                    placeholder="Write subtask name here"
                                                                    className="hidden-input"
                                                                    value={task.subtaskName}
                                                                    onChange={(e) => this.handleTaskInputChange(index, e)}
                                                                    ref={this[`taskRef${index}`] = this[`taskRef${index}`] || React.createRef()}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className="text-center">
                                                            <BiTrash
                                                                className="delete-task-icon"
                                                                onClick={() => this.deleteTask(index)}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>

                            <div className="popup-footer">
                                <button className="task-submit-btn" onClick={this.addSubTasks}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default TaskPopup;
